/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { Col, Row, Input, Tooltip, Button, message, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import { useHookIDO } from "../Ido/StoreIdo";
import { useHookACC } from "./StoreAcc";
import { POOL_WEIGHT, WEIGHT_TIER } from "../Ido/index.d";
import Synaps from "@synaps-io/react-verify";
import "./style.css";
import { isMobile } from "react-device-detect";
import { KycStatus } from "./index.d";
import { useAccount } from "wagmi";

const MyAccount = () => {
  const [state, actions]: any = useHookIDO();
  const [stateAcc, actionsAcc]: any = useHookACC();
  const { address } = useAccount();

  let account: any = address

  const [tierBr, setTierBr] = useState("");
  const [tierAg, setTierAg] = useState("");
  const [tierAu, setTierAu] = useState("");
  const [tierPt, setTierPt] = useState("");
  const [tierC, setTierC] = useState("");
  const [tierD, setTierD] = useState("");
  //------------------------------------------------------
  const [addAPT, setAddApt] = useState("");
  const [valueAPT, setvalueApt] = useState("");
  const [sttEdit, setSttEdit] = useState(false);
  const [sttAdd, setSttAdd] = useState(false);
  const [sttUpdate, setSttUpdate] = useState(false);
  //------------------------------------------------------
  const [addSOL, setAddSol] = useState("");
  const [valueSOL, setvalueSol] = useState("");
  const [sttEditSOL, setSttEditSOL] = useState(false);
  const [sttAddSOL, setSttAddSOL] = useState(false);
  const [sttUpdateSOL, setSttUpdateSOL] = useState(false);

  //------------------------------------------------------
  const [addDOT, setAddDot] = useState("");
  const [valueDOT, setvalueDot] = useState("");
  const [sttEditDOT, setSttEditDOT] = useState(false);
  const [sttAddDOT, setSttAddDOT] = useState(false);
  const [sttUpdateDOT, setSttUpdateDOT] = useState(false);
  //------------------------------------------------------
  const [addSUI, setAddSui] = useState("");
  const [valueSUI, setvalueSui] = useState("");
  const [sttEditSUI, setSttEditSUI] = useState(false);
  const [sttAddSUI, setSttAddSUI] = useState(false);
  const [sttUpdateSUI, setSttUpdateSUI] = useState(false);
  //------------------------------------------------------
  //------------------------------------------------------
  const [addUNISAT, setAddUNISAT] = useState("");
  const [valueUNISAT, setvalueUNISAT] = useState("");
  const [sttEditUNISAT, setSttEditUNISAT] = useState(false);
  const [sttAddUNISAT, setSttAddUNISAT] = useState(false);
  const [sttUpdateUNISAT, setSttUpdateUNISAT] = useState(false);
  //------------------------------------------------------
  const [addTON, setAddTon] = useState("");
  const [valueTON, setvalueTon] = useState("");
  const [sttEditTON, setSttEditTON] = useState(false);
  const [sttAddTON, setSttAddTON] = useState(false);
  const [sttUpdateTON, setSttUpdateTON] = useState(false);
  //------------------------------------------------------
  let amountStake = state.owner?.currentTier;
  const tierText = (() => {
    switch (amountStake) {
      case WEIGHT_TIER.Bronze:
        return WEIGHT_TIER[WEIGHT_TIER.Bronze];
      case WEIGHT_TIER.Diamond:
        return WEIGHT_TIER[WEIGHT_TIER.Diamond];
      case WEIGHT_TIER.Gold:
        return WEIGHT_TIER[WEIGHT_TIER.Gold];
      case WEIGHT_TIER.Platinum:
        return WEIGHT_TIER[WEIGHT_TIER.Platinum];
      case WEIGHT_TIER.Silver:
        return WEIGHT_TIER[WEIGHT_TIER.Silver];
      case WEIGHT_TIER.SupDiamond:
        return "Super Diamond";
      default:
        return "N/A";
    }
  })();
  useEffect(() => {
    if (account !== undefined || amountStake !== undefined) {
      if (amountStake <= 0) {
        setTierBr("");
        setTierAg("");
        setTierAu("");
        setTierPt("");
        setTierC("");
        setTierD("");
      } else if (amountStake === 15) {
        setTierBr("brTier");
      } else if (amountStake === 35) {
        setTierAg("siTier");
      } else if (amountStake === 60) {
        setTierAu("auTier");
      } else if (amountStake === 190) {
        setTierPt("pTier");
      } else if (amountStake === 390) {
        setTierC("diaTier");
      } else if (amountStake === 750) {
        setTierD("suTier");
      }
    }
  }, [account, amountStake]);
  useEffect(() => {
    actions.getTVL();
  }, []);

  useEffect(() => {
    actionsAcc.getAddress(account).then((res: any) => {
      let x = res.find((i: any) => i.network === "apt");
      let y = res.find((i: any) => i.network === "sol");
      let z = res.find((i: any) => i.network === "dot");
      let s = res.find((i: any) => i.network === "sui");
      let b = res.find((i: any) => i.network === "brc");
      let t = res.find((i: any) => i.network === "ton");
      setvalueApt(x?.address);
      setvalueSol(y?.address);
      setvalueDot(z?.address);
      setvalueSui(s?.address);
      setvalueUNISAT(b?.address);
      setvalueTon(t?.address);
      if (x) {
        setSttAdd(true);
      } else {
        setSttAdd(false);
      }
      if (y) {
        setSttAddSOL(true);
      } else {
        setSttAddSOL(false);
      }
      if (z) {
        setSttAddDOT(true);
      } else {
        setSttAddDOT(false);
      }
      if (s) {
        setSttAddSUI(true);
      } else {
        setSttAddSUI(false);
      }
      if (b) {
        setSttAddUNISAT(true);
      } else {
        setSttAddUNISAT(false);
      }
      if (t) {
        setSttAddTON(true);
      } else {
        setSttAddTON(false);
      }
    });
  }, [
    account,
    sttUpdate,
    sttAdd,
    sttUpdateSOL,
    sttAddSOL,
    sttUpdateDOT,
    sttAddDOT,
    sttAddSUI,
    sttUpdateSUI,
    sttAddUNISAT,
    sttUpdateUNISAT,
    sttUpdateTON,
    sttAddTON
  ]);

  const TVL = state.totalVL.data && state.totalVL.data.toLocaleString();

  useEffect(() => {
    if (account) {
      actions.getOwner({ account });
    }
  }, [account, actions]);
  let navigate = useNavigate();

  const gotoStake = () => {
    navigate(`${"/LaunchPool"}`);
  };
  const gotoIdoPool = () => {
    navigate(`${"/IDOPools"}`);
  };
  //------------------------------------------------------
  const onChange = (e: any) => {
    setAddApt(e.target.value);
    setvalueApt(e.target.value);
  };
  const onChangeSOL = (e: any) => {
    setAddSol(e.target.value);
    setvalueSol(e.target.value);
  };
  const onChangeDOT = (e: any) => {
    setAddDot(e.target.value);
    setvalueDot(e.target.value);
  };
  const onChangeSUI = (e: any) => {
    setAddSui(e.target.value);
    setvalueSui(e.target.value);
  };
  const onChangeUNISAT = (e: any) => {
    setAddUNISAT(e.target.value);
    setvalueUNISAT(e.target.value);
  };
  const onChangeTON = (e: any) => {
    setAddTon(e.target.value);
    setvalueTon(e.target.value);
  };
  //------------------------------------------------------
  const btnAdd = () => {
    const obj = {
      className: `btn-add-acc`,
      disabled: addAPT === "",
    };
    return obj;
  };
  const btnAddSOL = () => {
    const obj = {
      className: `btn-add-acc`,
      disabled: addSOL === "",
    };
    return obj;
  };
  const btnAddDOT = () => {
    const obj = {
      className: `btn-add-acc`,
      disabled: addDOT === "",
    };
    return obj;
  };
  const btnAddSUI = () => {
    const obj = {
      className: `btn-add-acc`,
      disabled: addSUI === "",
    };
    return obj;
  };
  const btnAddUNISAT = () => {
    const obj = {
      className: `btn-add-acc`,
      disabled: addUNISAT === "",
    };
    return obj;
  };
  const btnAddTON = () => {
    const obj = {
      className: `btn-add-acc`,
      disabled: addTON === "",
    };
    return obj;
  };
  //------------------------------------------------------
  const btnInputAdd = () => {
    const obj = {
      className: `input-content-link`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueAPT && !sttEdit && sttAdd),
    };
    return obj;
  };
  const btnInputAddSOL = () => {
    const obj = {
      className: `input-content-link`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueSOL && !sttEditSOL && sttAddSOL),
    };
    return obj;
  };
  const btnInputAddDOT = () => {
    const obj = {
      className: `input-content-link`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueDOT && !sttEditDOT && sttAddDOT),
    };
    return obj;
  };
  const btnInputAddSUI = () => {
    const obj = {
      className: `input-content-link`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueSUI && !sttEditSUI && sttAddSUI),
    };
    return obj;
  };
  const btnInputAddUNISAT = () => {
    const obj = {
      className: `input-content-link`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueUNISAT && !sttEditUNISAT && sttAddUNISAT),
    };
    return obj;
  };
  const btnInputAddTON = () => {
    const obj = {
      className: `input-content-link`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueTON && !sttEditTON && sttAddTON),
    };
    return obj;
  };
  //------------------------------------------------------
  const btnInputAddMobie = () => {
    const obj = {
      className: `input-content-link-mb`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueAPT && !sttEdit && sttAdd),
    };
    return obj;
  };
  const btnInputAddMobieSOL = () => {
    const obj = {
      className: `input-content-link-mb`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueSOL && !sttEditSOL && sttAddSOL),
    };
    return obj;
  };
  const btnInputAddMobieDOT = () => {
    const obj = {
      className: `input-content-link-mb`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueDOT && !sttEditDOT && sttAddDOT),
    };
    return obj;
  };
  const btnInputAddMobieSUI = () => {
    const obj = {
      className: `input-content-link-mb`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueSUI && !sttEditSUI && sttAddSUI),
    };
    return obj;
  };
  const btnInputAddMobieUNISAT = () => {
    const obj = {
      className: `input-content-link-mb`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueUNISAT && !sttEditUNISAT && sttAddUNISAT),
    };
    return obj;
  };
  const btnInputAddMobieTON = () => {
    const obj = {
      className: `input-content-link-mb`,
      disabled:
        account === "" ||
        account === undefined ||
        (valueTON && !sttEditTON && sttAddTON),
    };
    return obj;
  };
  //------------------------------------------------------
  let paramsAdd = {
    ownerAddress: address,
    network: "apt",
    linkAddress: addAPT,
  };
  let paramsAddSOL = {
    ownerAddress: address,
    network: "sol",
    linkAddress: addSOL,
  };
  let paramsAddDOT = {
    ownerAddress: address,
    network: "dot",
    linkAddress: addDOT,
  };
  let paramsAddSUI = {
    ownerAddress: address,
    network: "sui",
    linkAddress: addSUI,
  };
  let paramsAddUNISAT = {
    ownerAddress: address,
    network: "brc",
    linkAddress: addUNISAT,
  };
  let paramsAddTON = {
    ownerAddress: account,
    network: "ton",
    linkAddress: addTON,
  };
  //------------------------------------------------------

  const handleAddAcc = () => {
    setSttAdd(true);
    actionsAcc.addAddress(paramsAdd).then((res: any) => {
      if (res.data.succeeded) {
        message.success({
          type: "success",
          content: "Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };

  const handleAddAccSOL = () => {
    setSttAddSOL(true);
    actionsAcc.addAddress(paramsAddSOL).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };

  const handleAddAccDOT = () => {
    setSttAddDOT(true);
    actionsAcc.addAddress(paramsAddDOT).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleAddAccSUI = () => {
    setSttAddSUI(true);
    actionsAcc.addAddress(paramsAddSUI).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleAddAccUNISAT = () => {
    setSttAddUNISAT(true);
    actionsAcc.addAddress(paramsAddUNISAT).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleAddAccTON = () => {
    setSttAddTON(true);
    actionsAcc.addAddress(paramsAddTON).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  // ------------------------------------------------------
  const handleEdit = () => {
    setSttEdit(true);
  };
  const handleEditSOL = () => {
    setSttEditSOL(true);
  };
  const handleEditDOT = () => {
    setSttEditDOT(true);
  };
  const handleEditSUI = () => {
    setSttEditSUI(true);
  };
  const handleEditUNISAT = () => {
    setSttEditUNISAT(true);
  };
  const handleEditTON = () => {
    setSttEditTON(true);
  };
  // ------------------------------------------------------
  const handleCancel = () => {
    setSttEdit(false);
    setvalueApt(stateAcc.accountApt);
  };
  const handleCancelSOL = () => {
    setSttEditSOL(false);
    setvalueSol(stateAcc.accountSol);
  };
  const handleCancelDOT = () => {
    setSttEditDOT(false);
    setvalueDot(stateAcc.accountDot);
  };
  const handleCancelSUI = () => {
    setSttEditSUI(false);
    setvalueSui(stateAcc.accountSui);
  };
  const handleCancelUNISAT = () => {
    setSttEditUNISAT(false);
    setvalueUNISAT(stateAcc.accountUNISAT);
  };
  const handleCancelTON = () => {
    setSttEditTON(false);
    setvalueTon(stateAcc.accountUNISAT);
  };
  //------------------------------------------------------
  const handleSubmit = () => {
    paramsAdd.linkAddress = valueAPT;
    actionsAcc.addAddress(paramsAdd).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Update Successfully!!!",
          className: "custom-class",
          duration: 5,
        });

        setSttEdit(false);
        setSttUpdate(true);
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleSubmitSOL = () => {
    paramsAddSOL.linkAddress = valueSOL;

    actionsAcc.addAddress(paramsAddSOL).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Update Successfully!!!",
          className: "custom-class",
          duration: 2,
        });

        setSttEditSOL(false);
        setSttUpdateSOL(true);
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleSubmitDOT = () => {
    paramsAddDOT.linkAddress = valueDOT;
    actionsAcc.addAddress(paramsAddDOT).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Update Successfully!!!",
          className: "custom-class",
          duration: 5,
        });

        setSttEditDOT(false);
        setSttUpdateDOT(true);
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleSubmitSUI = () => {
    paramsAddSUI.linkAddress = valueSUI;
    actionsAcc.addAddress(paramsAddSUI).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Update Successfully!!!",
          className: "custom-class",
          duration: 5,
        });

        setSttEditSUI(false);
        setSttUpdateSUI(true);
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleSubmitUNISAT = () => {
    paramsAddUNISAT.linkAddress = valueUNISAT;
    actionsAcc.addAddress(paramsAddUNISAT).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Update Successfully!!!",
          className: "custom-class",
          duration: 5,
        });

        setSttEditUNISAT(false);
        setSttUpdateUNISAT(true);
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  const handleSubmitTON = () => {
    paramsAddTON.linkAddress = valueTON;
    actionsAcc.addAddress(paramsAddTON).then((res: any) => {
      if (res.data.data) {
        message.success({
          type: "success",
          content: "Update Successfully!!!",
          className: "custom-class",
          duration: 5,
        });
        setSttEditTON(false);
        setSttUpdateTON(true);
        actionsAcc.getAddress(account);
      } else {
        message.error({
          type: "error",
          content: res?.data?.message,
          className: "custom-class",
          duration: 5,
        });
      }
    });
  };
  //------------------------------------------------------

  useEffect(() => {
    if (account) {
      actionsAcc.getListKyc({
        ownerAddress: account,
      });
    }
  }, [actionsAcc, account]);

  useEffect(() => {
    if (
      (account && stateAcc.listKyc.status === KycStatus.INIT) ||
      stateAcc.listKyc.status === KycStatus.CANCELLED
    ) {
      actionsAcc.getListKycNew({
        ownerAddress: account,
      });
    }
  }, [actionsAcc, account, stateAcc.listKyc]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelKYC = () => {
    setIsModalOpen(false);
  };

  const [isFinish, setIsFinish] = useState(false);

  const handleFinish = () => {
    setIsFinish(true);
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   handleFinish()
  // }, []);

  return (
    <>
      <AccountBanner>
        <Row className="content">
          <Col className="content-r" md={9} sm={24} xs={24}>
            <div className="main-tier acc-tier">
              <div className="top-tier">
                <div className="logo-tier-bscs">
                  <img src="/images/logo-brand-v3.svg" alt="" />
                </div>
                <div className="logo-tier-current">
                  {amountStake > 0 ? (
                    amountStake === 750 ? (
                      <>
                        <img alt="" src={`/images/SUPDIAMOND.svg`} />
                      </>
                    ) : (
                      <>
                        <img
                          alt=""
                          src={`/images/${tierText.toUpperCase()}.svg`}
                        />
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="body-tier">
                <div className="line-tier"></div>

                <ul className="list-tier">
                  <li className={tierBr}>
                    <span className="number-stake">15.000</span>
                    <div className="img-tier">
                      <img src="/images/BRONZE.svg" alt="" />
                    </div>
                    <span className="name-tier">Bronze</span>
                  </li>

                  <li className={tierAg}>
                    <span className="number-stake">30.000</span>
                    <div className="img-tier">
                      <img src="/images/SILVER.svg" alt="" />
                    </div>
                    <span className="name-tier">Silver</span>
                  </li>
                  <li className={tierAu}>
                    <span className="number-stake">50.000</span>
                    <div className="img-tier">
                      <img src="/images/GOLD.svg" alt="" />
                    </div>
                    <span className="name-tier">Gold</span>
                  </li>
                  <li className={tierPt}>
                    <span className="number-stake">150.000</span>
                    <div className="img-tier">
                      <img src="/images/PLATINUM.svg" alt="" />
                    </div>
                    <span className="name-tier">PLATINUM</span>
                  </li>
                  <li className={tierC}>
                    <span className="number-stake">300.000</span>
                    <div className="img-tier">
                      <img src="/images/DIAMOND.svg" alt="" />
                    </div>
                    <span className="name-tier">DIAMOND</span>
                  </li>
                  <li className={tierD}>
                    <span className="number-stake">500.000</span>
                    <div className="img-tier">
                      <img src="/images/SUPDIAMOND.svg" alt="" />
                    </div>
                    <span className="name-tier">SUPER DIAMOND</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="content-ido-banner gr-btn-acc">
              <ul className="list-info-stake list-stake-acc">
                <li>
                  <span className="text-top">Current Tier</span>
                  <span className="text-bottom">{tierText}</span>
                </li>
                <li>
                  <span className="text-top">Your Stake</span>
                  {account ? (
                    <span className="text-bottom">{state.owner?.staking}</span>
                  ) : (
                    <span className="text-bottom">N/A</span>
                  )}
                </li>
                <li>
                  <span className="text-top">Total value BSCS lock</span>
                  <span className="text-bottom">${TVL || "0"}</span>
                </li>
              </ul>
              <div className="button-ido-stake">
                <button
                  type="button"
                  className="btn btn-stake"
                  onClick={() => {
                    gotoStake();
                  }}
                >
                  Stake
                </button>
                <button
                  type="button"
                  className="btn btn-stake-pool"
                  onClick={() => {
                    gotoIdoPool();
                  }}
                >
                  IDO pool
                </button>
              </div>
            </div>
          </Col>

          <Col className="col-acc" md={15} sm={24} xs={24}>
            {account ? (
              <>
                <div className="wallet cus">
                  <div className="title-acc">My Account</div>
                  <div className="columns">
                    <div className="colum w-8-acc">
                      <div className="title-name">
                        <img
                          className="img-wl"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/d97238cc78bb73e67d02b4b7f96594ca8880be3a8a3ce2bbde.png"
                          alt=""
                        />
                        <span className="text-wl">Wallet address</span>
                      </div>
                      <div className="input-wl">
                        {isMobile ? (
                          <Input
                            disabled
                            className="input-content"
                            value={
                              account &&
                              `${account.substring(
                                0,
                                10
                              )}...${account.substring(30, account.length)}`
                            }
                          />
                        ) : (
                          <Input
                            disabled
                            className="input-content"
                            value={
                              account &&
                              `${account.substring(
                                0,
                                15
                              )}...${account.substring(30, account.length)}`
                            }
                          />
                        )}

                        <CopyToClipboard
                          text={`${account}`}
                          onCopy={() => {
                            message.success({
                              type: "success",
                              content: "Copied",
                              className: "custom-class",
                              duration: 0.5,
                            });
                          }}
                        >
                          <img
                            alt=""
                            className="img-cp"
                            src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                          />
                        </CopyToClipboard>
                      </div>
                    </div>
                    {state?.owner?.currentTier > 0 &&
                      stateAcc.listKyc?.data !== null ? (
                      <>
                        <div className="colum w-4-acc">
                          <div className="title-name">
                            <img
                              className="img-wl"
                              src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/8eae7da43c0455c2a1b2ef2fdb2fc385c29449ae76d4478daf.png"
                              alt=""
                            />
                            <span className="text-wl">KYC status</span>
                          </div>
                          <div className="kyc-round">
                            <div className="kyc-l">
                              {stateAcc.listKyc.status === 2 ? (
                                <>
                                  <img
                                    src="./images/Fingerprint-1.png"
                                    alt=""
                                  />
                                </>
                              ) : (
                                <>
                                  <img src="./images/Fingerprint.png" alt="" />
                                </>
                              )}
                            </div>
                            <div className="kyc-right">
                              <div className="txt">
                                {stateAcc.listKyc.status === 2 ? (
                                  <>Approved</>
                                ) : (
                                  <>Unverified</>
                                )}
                              </div>
                              <div className="start-kyc">
                                {stateAcc.listKyc.status === 2 ? (
                                  <>
                                    <div className="finish-text">
                                      Your wallet has been successfully verified
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="btn-kyc"
                                      onClick={showModal}
                                    >
                                      Start verification
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                            {/* @ts-ignore this lib is incompatible with react18  */}
                            <Modal
                              title="BSCS KYC"
                              visible={isModalOpen}
                              onCancel={handleCancelKYC}
                              wrapClassName="modal-apt-connect cus"
                              footer={false}
                            >
                              <Synaps
                                sessionId={stateAcc.listKyc.sessionId}
                                service={"individual"}
                                lang={"en"}
                                onReady={() => console.log("component ready")}
                                onFinish={() => handleFinish()}
                                color={{
                                  primary: "212b39",
                                  secondary: "000",
                                }}
                                withFinishButton={true}
                              />
                            </Modal>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="colum w-4-acc">
                          <div className="title-name">
                            <img
                              className="img-wl"
                              src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/8eae7da43c0455c2a1b2ef2fdb2fc385c29449ae76d4478daf.png"
                              alt=""
                            />
                            <span className="text-wl">KYC status</span>
                          </div>
                          <div className="kyc-round">
                            <div className="kyc-l">
                              <img src="./images/Fingerprint.png" alt="" />
                            </div>
                            <div className="kyc-right">
                              <div className="txt">Unverified</div>
                              <div className="start-kyc">
                                <button
                                  type="button"
                                  className="btn-kyc"
                                  disabled={true}
                                >
                                  Start verification
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="wallet">
                  <div className="title-name">
                    <img
                      className="img-link"
                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/b096963b0548c54fda26c2aa32e85a8245050a9e464c840be6.png"
                      alt=""
                    />
                    <span className="text-wl">Linked Addresses</span>
                  </div>

                  {/* ------------------------APT-------------------- */}
                  {isMobile ? (
                    <>
                      <div className="top-mb">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/bc946a75e14d2ddefb4a08550ec09ab105e4e654417655becf.png"
                          alt=""
                        />
                        <span className="text-wl">Aptos</span>
                      </div>
                      <div className="input-link">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddMobie()}
                          // className="input-content-link"
                          onChange={onChange}
                          value={valueAPT}
                        />

                        {/* {valueAPT === "" || valueAPT === undefined ? ( */}
                        {stateAcc.accountApt === "" ||
                          stateAcc.accountApt === undefined ? (
                          <Button {...btnAdd()} onClick={() => handleAddAcc()}>
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEdit ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancel();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link mb"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEdit();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueAPT}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp mb"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-link">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/bc946a75e14d2ddefb4a08550ec09ab105e4e654417655becf.png"
                          alt=""
                        />
                        <span className="text-wl">Aptos</span>
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAdd()}
                          // className="input-content-link"
                          onChange={onChange}
                          value={valueAPT}
                        />

                        {stateAcc.accountApt === "" ||
                          stateAcc.accountApt === undefined ? (
                          <Button {...btnAdd()} onClick={() => handleAddAcc()}>
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEdit ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancel();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEdit();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueAPT}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {/* ------------------------TON-------------------- */}
                  {isMobile ? (
                    <>
                      <div className="top-mb">
                        <img
                          className="img-link"
                          src="/images/ton.png"
                          alt=""
                        />
                        <span className="text-wl">Ton</span>
                      </div>
                      <div className="input-link">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddMobieTON()}
                          // className="input-content-link"
                          onChange={onChangeTON}
                          value={valueTON}
                        />

                        {/* {stateAcc.listLinkAcc.length === 0 ? ( */}
                        {stateAcc.accountTON === "" ||
                          stateAcc.accountTON === undefined ? (
                          <Button
                            {...btnAddTON()}
                            onClick={() => handleAddAccTON()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditTON ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelTON();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link mb"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitTON();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditTON();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueTON}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp mb"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-link">
                        <img
                          className="img-link"
                          src="/images/ton.png"
                          alt=""
                        />
                        <span className="text-wl">Ton</span>
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddTON()}
                          // className="input-content-link"
                          onChange={onChangeTON}
                          value={valueTON}
                        />

                        {stateAcc.accountTON === "" ||
                          stateAcc.accountTON === undefined ? (
                          <Button
                            {...btnAddTON()}
                            onClick={() => handleAddAccTON()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditTON ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelTON();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitTON();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditTON();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueTON}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {/* ------------------------UNISAT-------------------- */}
                  {isMobile ? (
                    <>
                      <div className="top-mb">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/09afd390c644ed2335fdd9d402ac9d69eb685aaba86b6a4345.png"
                          alt=""
                        />
                        <span className="text-wl">BTC</span>
                      </div>
                      <div className="input-link">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddMobieUNISAT()}
                          // className="input-content-link"
                          onChange={onChangeUNISAT}
                          value={valueUNISAT}
                        />

                        {/* {stateAcc.listLinkAcc.length === 0 ? ( */}
                        {stateAcc.accountUNISAT === "" ||
                          stateAcc.accountUNISAT === undefined ? (
                          <Button
                            {...btnAddUNISAT()}
                            onClick={() => handleAddAccUNISAT()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditUNISAT ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelUNISAT();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link mb"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitUNISAT();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditUNISAT();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueUNISAT}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp mb"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-link">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/09afd390c644ed2335fdd9d402ac9d69eb685aaba86b6a4345.png"
                          alt=""
                        />
                        <span className="text-wl">BTC</span>
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddUNISAT()}
                          // className="input-content-link"
                          onChange={onChangeUNISAT}
                          value={valueUNISAT}
                        />

                        {stateAcc.accountUNISAT === "" ||
                          stateAcc.accountUNISAT === undefined ? (
                          <Button
                            {...btnAddUNISAT()}
                            onClick={() => handleAddAccUNISAT()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditUNISAT ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelUNISAT();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitUNISAT();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditUNISAT();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueUNISAT}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* ------------------------SOLANA-------------------- */}
                  {isMobile ? (
                    <>
                      <div className="top-mb">
                        <img
                          className="img-link"
                          src="/images/sol.png"
                          alt=""
                        />
                        <span className="text-wl">Solana</span>
                      </div>
                      <div className="input-link">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddMobieSOL()}
                          // className="input-content-link"
                          onChange={onChangeSOL}
                          value={valueSOL}
                        />

                        {/* {stateAcc.listLinkAcc.length === 0 ? ( */}
                        {stateAcc.accountSol === "" ||
                          stateAcc.accountSol === undefined ? (
                          <Button
                            {...btnAddSOL()}
                            onClick={() => handleAddAccSOL()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditSOL ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelSOL();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link mb"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitSOL();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditSOL();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueSOL}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp mb"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-link">
                        <img
                          className="img-link"
                          src="/images/sol.png"
                          alt=""
                        />
                        <span className="text-wl">Solana</span>
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddSOL()}
                          // className="input-content-link"
                          onChange={onChangeSOL}
                          value={valueSOL}
                        />

                        {stateAcc.accountSol === "" ||
                          stateAcc.accountSol === undefined ? (
                          <Button
                            {...btnAddSOL()}
                            onClick={() => handleAddAccSOL()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditSOL ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelSOL();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitSOL();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditSOL();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueSOL}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* ------------------------POLKADOT-------------------- */}
                  {isMobile ? (
                    <>
                      <div className="top-mb">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/ecce2ddc93b885d0cfa20fa449a2d6ea807f62d86799ddd313.png"
                          alt=""
                        />
                        <span className="text-wl">PolkaDot</span>
                      </div>
                      <div className="input-link">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddMobieDOT()}
                          // className="input-content-link"
                          onChange={onChangeDOT}
                          value={valueDOT}
                        />

                        {/* {stateAcc.listLinkAcc.length === 0 ? ( */}
                        {stateAcc.accountDot === "" ||
                          stateAcc.accountDot === undefined ? (
                          <Button
                            {...btnAddDOT()}
                            onClick={() => handleAddAccDOT()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditDOT ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelDOT();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link mb"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitDOT();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditDOT();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueDOT}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp mb"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-link">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/ecce2ddc93b885d0cfa20fa449a2d6ea807f62d86799ddd313.png"
                          alt=""
                        />
                        <span className="text-wl">PolkaDot</span>
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddDOT()}
                          // className="input-content-link"
                          onChange={onChangeDOT}
                          value={valueDOT}
                        />

                        {stateAcc.accountDot === "" ||
                          stateAcc.accountDot === undefined ? (
                          <Button
                            {...btnAddDOT()}
                            onClick={() => handleAddAccDOT()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditDOT ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelDOT();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitDOT();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditDOT();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueDOT}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* ------------------------SUI-------------------- */}
                  {isMobile ? (
                    <>
                      <div className="top-mb">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/e7be7deef547438af7e461230be77862f00b99e4fc8ead9aed.png"
                          alt=""
                        />
                        <span className="text-wl">SUI</span>
                      </div>
                      <div className="input-link">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddMobieSUI()}
                          // className="input-content-link"
                          onChange={onChangeSUI}
                          value={valueSUI}
                        />

                        {/* {stateAcc.listLinkAcc.length === 0 ? ( */}
                        {stateAcc.accountSui === "" ||
                          stateAcc.accountSui === undefined ? (
                          <Button
                            {...btnAddSUI()}
                            onClick={() => handleAddAccSUI()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditSUI ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelSUI();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link mb"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitSUI();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditSUI();
                                  }}
                                  className="btn-link ok mb"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueSUI}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp mb"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-link">
                        <img
                          className="img-link"
                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/e7be7deef547438af7e461230be77862f00b99e4fc8ead9aed.png"
                          alt=""
                        />
                        <span className="text-wl">SUI</span>
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Input
                          {...btnInputAddSUI()}
                          // className="input-content-link"
                          onChange={onChangeSUI}
                          value={valueSUI}
                        />

                        {stateAcc.accountSui === "" ||
                          stateAcc.accountSui === undefined ? (
                          <Button
                            {...btnAddSUI()}
                            onClick={() => handleAddAccSUI()}
                          >
                            Add
                          </Button>
                        ) : (
                          <>
                            {sttEditSUI ? (
                              <>
                                <Button
                                  onClick={() => {
                                    handleCancelSUI();
                                  }}
                                  className="btn-link cancel"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/225bd010669f3763c58cfba4d51b1ff5edbda7fee776b0871e.png"
                                    />
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handleSubmitSUI();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/28a7c1de2b453709b091bf4e7f9cf723516c9021e276e77ee7.png"
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    handleEditSUI();
                                  }}
                                  className="btn-link ok"
                                  icon={
                                    <img
                                      className="icon-link"
                                      alt=""
                                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/728912cd5a4698e6adac2f30909c2aef13fa35a927ed14f50f.png"
                                    />
                                  }
                                />
                                <CopyToClipboard
                                  text={`${valueSUI}`}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <Button
                                    className="btn-link cp"
                                    icon={
                                      <img
                                        className="icon-link"
                                        alt=""
                                        src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/10f529d74ddecfb0932e53fe3d1d242603c4c6b2fc1435f8b7.png"
                                      />
                                    }
                                  />
                                </CopyToClipboard>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                </div>
              </>
            ) : (
              <>
                <div className="wallet-not">
                  <div className="title-acc">My Account</div>
                  {/* <div className="title-name">
                    <img
                      className="img-wl"
                      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/d97238cc78bb73e67d02b4b7f96594ca8880be3a8a3ce2bbde.png"
                      alt=""
                    />
                    <span className="text-wl">Wallet address</span>
                  </div> */}

                  <div className="input-wl text">
                    You haven't connected wallet EVM.
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </AccountBanner>
    </>
  );
};

export default MyAccount;

const AccountBanner = styled.div`
  margin-bottom: 90px;
  background-repeat: no-repeat;
  background-position: top !important;
  background-size: cover !important;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
