import web3 from 'web3'

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), 'ether')
  if (decimal !== 18) {
    const pw = 18 - decimal
    amount /= (10 ** pw)
  }
  return amount
}

export const convertWeiToToken = (value, decimal) => {
  return value.toString() / (10 ** decimal)
}

export const claimTokens = async (idoTkenClaimSC, item, tokenDecimals) => {
  const amount = convertTokenToWei(item.claimToken, tokenDecimals)
  let args = [amount, item.claimRound, item.signToken]
  if(item.claimRound === 100) {
    args= []
  }
  const estimatedGas = await idoTkenClaimSC.estimateGas.claimTokens(...args)
  return idoTkenClaimSC.claimTokens(...args, {
    gasLimit: estimatedGas,
  })
}

export const isClaimed = async (idoTkenClaimSC, account, item) => {
  if(item.claimRound === 100) {
    const result = await idoTkenClaimSC.claimAble(account)
    return  (result.toString() / 1e18) > 0
  }
  return idoTkenClaimSC.userClaim(...[account, item.claimRound])
}

export const isRefundedRC = async (idoTkenClaimSC, account) => {
  return idoTkenClaimSC.userRefund(account)
}

export const totalClaimAble = async (idoTkenClaimSC, account, tokenDecimals) => {
  const result = await idoTkenClaimSC.claimAble(account)
  return convertWeiToToken(result, tokenDecimals)
}