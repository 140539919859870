import React from 'react'
import {
    BrandAssets,
    BrandContainer,
    ContentBanner,
    FirstContent,
    FirstTitle,
    FistDesc,
    SecondContent,
    SecondTitle,
    SecondDesc
} from './BrandStyles'

export const BrandBanner = () => {
  return (
    <BrandAssets>
        <BrandContainer>
            <ContentBanner>
                <FirstContent>
                    <FirstTitle>
                        BSCS Brand Assets
                    </FirstTitle>
                    <FistDesc>
                        Thank you for your support and loving our work. Below, we <br/> have provided a few guidelines for using our brand assets.
                    </FistDesc>
                </FirstContent>
                <SecondContent>
                    <SecondTitle>
                        Our Brand Name
                    </SecondTitle>
                    <SecondDesc>
                        BSCS - The fully decentralized protocol for launching <br/> new ideas. An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks
                    </SecondDesc>
                </SecondContent>
            </ContentBanner>
        </BrandContainer>
    </BrandAssets>
  )
}




