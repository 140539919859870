import React, { useState, useEffect } from "react";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { Select, Tabs, Collapse, Modal, DatePicker, Popover, message } from 'antd';
import { useLocation, Link, useNavigate } from "react-router-dom";
import ReferralList from "./ReferralList";
import LaunchpadList from "./LaunchpadList"
import StakingList from "./StakingList"
import QRCode from 'react-qr-code';
import { useHookACC } from "./StoreAcc";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAccount } from "wagmi";

const MyReferrals = () => {
    const { Panel } = Collapse;
    const location = useLocation();
    const pathSymbol = location.search.split("?");
    const pathHash = pathSymbol[1];

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [stateAcc, actionsAcc]: any = useHookACC();
    const { address } = useAccount();

    let account = address

    const params = {
        ownerAddress: account
    }
    useEffect(() => {
        if (account) {
            actionsAcc.getListDashboard(params);
        }
    }, [actionsAcc, account]);

    const paramsAccount = {
        ownerAddress: account,
        referralAddress: pathHash
    }

    useEffect(() => {
        if (account && pathHash) {
            actionsAcc.getListSignref(paramsAccount);
        }
    }, [actionsAcc, account]);


    const content = (
        <>
            <div className="popover-row">
                <a className="item" href={`https://twitter.com/intent/tweet?hashtags=https://bscs.finance${stateAcc?.listDashboard?.affiliateLink}`} >
                    <div className="txt-l">
                        Twiter
                    </div>
                    <div className="txt-r">
                        <img src="./images/imgido/twi-ref.png" alt="" />
                    </div>
                </a>
                <a className="item" href={`https://telegram.me/share/url?text=&url=https://bscs.finance${stateAcc?.listDashboard?.affiliateLink}`}>
                    <div className="txt-l">
                        Telegram
                    </div>
                    <div className="txt-r">
                        <img src="./images/imgido/tele-ref.png" alt="" />
                    </div>
                </a>
            </div>
        </>
    );
    return (
        <>
            <div className="main-referrals">
                <div className="section-link">
                    <div className="columns">
                        <div className="colum w-6">
                            <div className="content-link">
                                <div className="title">
                                    Invite your friends
                                    Earn <span className="main-color">cryptocurrency</span> together
                                </div>
                                <div className="desc">
                                    Earn up to 3% from friends’ commits on BSCS Launchpads and 5% from their stake commission on BSCS Startpools
                                </div>
                            </div>
                        </div>
                        <div className="colum w-6">
                            <div className="content-ref">
                                <div className="box-ref">
                                    <div className="title">
                                        My Referral Link
                                    </div>
                                    <div className="input-address">
                                        <div className="input">
                                            https://bscs.finance...
                                            <CopyToClipboard
                                                text={`https://bscs.finance${stateAcc?.listDashboard?.affiliateLink}`}
                                                onCopy={() => {
                                                    message.success({
                                                        type: "success",
                                                        content: "Copied!!",
                                                        className: "custom-class",
                                                        duration: 2,
                                                    });
                                                }}
                                            >
                                                <img src="./images/imgido/copy-ref.png" alt="" />
                                            </CopyToClipboard>
                                        </div>
                                        <Popover content={content} className="popover-share" placement="bottom" trigger="click">
                                            <div className="share">
                                                <img src="./images/imgido/share-alt.png" alt="" />
                                            </div>
                                        </Popover>
                                        <div className="share" onClick={showModal}>
                                            <img src="./images/imgido/qr.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="lobo-bscs">
                                        <img src="/images/logo-brand-v3.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-work">
                    <div className="wrap-work">
                        <div className="title">
                            <span>How it work</span><span className="read-more">Reward Rule {">>"}</span>
                        </div>
                        <div className="columns">
                            <div className="colum w-4">
                                <div className="content-work">
                                    <div className="number">
                                        1
                                    </div>
                                    <div className="txt">
                                        Get a referral link
                                    </div>
                                    <div className="desc">
                                        Connect a wallet and generate your referral link in the Referral section.
                                    </div>
                                    <div className="img-work">
                                        <img src="./images/imgido/w-1.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-4">
                                <div className="content-work">
                                    <div className="number">
                                        2
                                    </div>
                                    <div className="txt">
                                        Invite friends
                                    </div>
                                    <div className="desc">
                                        Invite your friends to register via your referral link
                                    </div>
                                    <div className="img-work">
                                        <img src="./images/imgido/w-2.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-4">
                                <div className="content-work">
                                    <div className="number">
                                        3
                                    </div>
                                    <div className="txt">
                                        Earn crypto
                                    </div>
                                    <div className="desc">
                                        Receive referral reward from your friends’ Launchpad Commit
                                    </div>
                                    <div className="img-work">
                                        <img src="./images/imgido/w-3.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-dashboard">
                    <div className="wrap-dashboard">
                        <div className="title">
                            <span>Dashboard</span>
                        </div>
                        <div className="columns">
                            <div className="colum w-25">
                                <div className="item-row-3">
                                    <div className="icon">
                                        <img src="./images/imgido/people.png" alt="" />
                                    </div>
                                    <div className="txt">
                                        Total friends <span className="number">{stateAcc?.listDashboard?.totalFriend}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-75">
                                <div className="item-row-9">
                                    <div className="row-9">
                                        <div className="icon">
                                            <img src="./images/imgido/fixed.png" alt="" />
                                        </div>
                                        <div className="txt">
                                            Total Reward ≈ <span className="number">{stateAcc?.listDashboard?.totalReward} USDT</span>
                                        </div>
                                    </div>
                                    <div className="row-9">
                                        <div className="txt">
                                            Launchpad (USDT) <span className="number">{stateAcc?.listDashboard?.commitFund} USDT</span>
                                        </div>
                                    </div>
                                    <div className="row-9">
                                        <div className="txt">
                                            Staking (BSCS) <span className="number">{stateAcc?.listDashboard?.stakeBscs} BSCS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-tabs-referral">
                    <div className="tabs-list-ref">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Referral List" key="1">
                                <ReferralList />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Launchpad" key="2">
                                <LaunchpadList />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Staking IDO Pools" key="3">
                                <StakingList />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
                <div className="section-rules">
                    <div className="collapes-rules">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Collapse defaultActiveKey={['1']}>
                            {/* @ts-ignore this lib is incompatible with react18  */}
                            <Panel header="Referral Rewards Rules" key="1">
                                1. When a friend connect the wallet and signed the message with a referral link, the referrer will receive a reward that is a corresponding percentage of committed volume in IDO Pools made by the friend. One Wallet Address could use only one ref link from others.<br /><br />
                                2. Total rewards will be shared for users who brought in volume to the IDO Sale. The more buy volume you bring to the IDO, the higher your reward will be.<br />
                                For example, if the affiliate pool is 3%, the final raised is 1000 USDT. Affiliate Reward Pool = 1000 USDT x 3% = 30 USDT.<br />
                                If there are three users participating in the affiliate program:<br />
                                A refers a user to buy 100 USDT<br />
                                B refers a user to buy 60 USDT<br />
                                C refers a user to buy 40 USDT<br />
                                So the total ARB referred is A + B + C = 200 USDT.<br />
                                User A will receive 50% of the affiliate reward pool = 15 USDT<br />
                                User B will receive 30% of the affiliate reward pool = 9 USDT<br />
                                User C will receive 20% of the affiliate reward pool = 6 USDT<br />
                                As we have 2 Rule for IDO so the Affiliate Reward Should be based on the applied Rule on each IDO.<br /><br />

                                3. Reward based on Refund Rules applied to each IDO:<br />
                                a. Riskless<br />
                                The total Referral Rewards will be calculated 12 hours after TGE<br />
                                If the user signs up with a referral code refund, the amount will be deducted from the total Referral Reward of the referral person<br /><br />

                                b. Shielded<br />
                                The total Referral Rewards will be calculated 12 hours after TGE<br />
                                The Referral Rewards distribution will base on the project vesting schedule<br />
                                Before each vesting, we will monitor and judge the situation to decide whether we will let the user claim or refund the token<br />
                                If the user signs up with a referral code refund, the amount will be deducted from the total Referral Reward of the referral person<br />
                                <b className="bold">Situation 1</b>: Refund the current vesting batch<br />
                                BSCS will observe and evaluate the project's performance before each vesting and can trigger the refund option for our users.<br />
                                If BSCS decides to refund the current vesting batch, there will be no Referral Rewards for this vesting batch.<br />
                                <b className="bold">Situation 2</b>: Refund all the remaining vesting batches<br />
                                If BSCS decides to refund all the remaining vesting batches, the Referral Rewards will only be counted toward the previous vesting.<br /><br />

                                *Important note: Kindly refer to the latest updates on BSCS platform for any amendments to the program. Due to reasons such as changes of market conditions and risks of fraud, BSCS reserves the right to amend the terms and conditions at any point of time without any prior notice. BSCS reserves the right of final interpretation to the terms and conditions here in above.
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal title="QR Code" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal-qr-code">
                <QRCode value={`https://bscs.finance${stateAcc?.listDashboard?.affiliateLink}`} />
                <div className="button-modal-qr-code">
                    <button className="btn-code-save" type="button" onClick={handleOk}>
                        Save
                    </button>
                    <button className="btn-code-cancel" type="button" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </>
    )
}
export default MyReferrals