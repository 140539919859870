import React, { createContext, useCallback, useState } from "react";
import styled from "styled-components";
export const Context = createContext({
  onPresent: (content, key) => {},
  onDismiss: () => {},
});
const Modals = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState();
  const [modalKey, setModalKey] = useState();
  const handlePresent = useCallback(
    (modalContent, key) => {
      setModalKey(key);
      setContent(modalContent);
      setIsOpen(true);
    },
    [setContent, setIsOpen, setModalKey]
  );
  const handleDismiss = useCallback(() => {
    setContent(undefined);
    setIsOpen(false);
  }, [setContent, setIsOpen, modalKey]);
  let partialDetails = { isOpen: false };
  let details = partialDetails; // error
  return React.createElement(
    Context.Provider,
    {
      value: {
        content,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      },
    },
    children,
    isOpen &&
      React.createElement(
        StyledModalWrapper,
        null,
        React.createElement(StyledModalBackdrop, { onClick: handleDismiss }),
        React.isValidElement(content) &&
          React.cloneElement(content, {
            onDismiss: handleDismiss,
          })
      )
  );
};
const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;
const StyledModalBackdrop = styled.div`
  background-color: rgb(44, 44, 44);
  position: absolute;
  opacity: 0.6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export default Modals;
