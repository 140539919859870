/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAccount, useDisconnect, useSwitchChain, useBalance, createConfig, useChainId } from "wagmi";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { Modal, Dropdown, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { ICON_BNB_URL, ICON_ETH_URL, ICON_POLY_URL, ICON_ARB, ICON_AVAX, ICON_OP, ICON_DOGE, ICON_FMT, ICON_BASE, ICON_OPBNB_URL, ICON_ZK, ICON_BLAST } from "../../constants/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useConnectWalletApt } from "../../pages/APT/ConnectAPT/index";
import Rank from "../SVG/Rank";
import Rocket from "../SVG/Rocket";
import MyNFT from "../SVG/MyNFT";
import Launchpool from "../SVG/Launchpool";
import web3 from "web3";
import { Locales, useTonConnectUI, TonConnectButton, TonConnectUI } from '@tonconnect/ui-react';
import "../Connect-wallet/style.css"
import { useConnectWalletSol } from "../../pages/Solana/index";
import { useConnectWalletTon } from "../../pages/TON/index";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { isMobile } from "react-device-detect";
import { useContract } from "../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS } from "../../constants";

import abiBSCS from "../../abi/abiBSCS.json";
import "./style.css"
import { formatNumber } from "pages/LauchPool-v2/utils";
import { useHookPrice } from "../common/Store";

declare const window: Window & typeof globalThis & { ethereum: any };

const WagmiConnect = () => {
    const { switchChainAsync } = useSwitchChain()
    const { address, isConnected } = useAccount();
    const [state, actions] = useHookPrice();
    const chainId = useChainId()
    // const { selectedNetworkId }: any = useWeb3ModalState()
    // console.log('selectedNetworkId', selectedNetworkId)

    let account: any = address
    const { open } = useWeb3Modal()
    const { disconnect } = useDisconnect();

    let navigate = useNavigate();
    const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [amountBscs, setAmountBscs] = useState(0);
    const [bnbBalance, setBnbBalance] = useState(0);
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [initialValue, setInitialValue]: any = useState("bep");

    const {
        curentAddress,
        balance,
        accountEllipsisMobieSol,
        accountEllipsisSol,
        menuSol,
    } = useConnectWalletSol();

    const { menuTon,
        userFriendlyAddress,
        accountEllipsisTon,
        accountEllipsisMobieTon,
        balanceTon
    } = useConnectWalletTon()

    const {
        connectAptos,
        disConnectAptos,
        menuApt,
        accountEllipsisApt,
        isAcc,
        isBalanceAPT,
    } = useConnectWalletApt();


    const handleConnectWagmi = () => {
        open();
    }

    const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(account);
            setAmountBscs(amount.toString() / 1e18);
        }
    };

    useEffect(() => {
        if (account && chainId === 56) {
            getBscs();
        }
    }, [account, bscsContract, chainId]);



    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account, chainId]);

    const [chain, setChain]: any = useState()

    useEffect(() => {
        setChain(localStorage.getItem("chain") || 'bep')
    }, [])
    // let chain = localStorage.getItem("chain");


    const handleDisconnect = () => {
        if (chain === "apt") {
            disConnectAptos()
        }
        disconnect();
        setIsModalVisible(false);
    };

    async function handleChain(value: any, e: any) {
        if (value !== "apt" && value !== "sol" && value !== "ton") {
            const chainList = (value: any) => {
                switch (value) {
                    case "erc":
                        return 1;
                    case "bep":
                        return 56;
                    case "poly":
                        return 137;
                    case "arb":
                        return 42161;
                    case "avax":
                        return 43114;
                    case "op":
                        return 10;
                    case "doge":
                        return 2000;
                    case "fmt":
                        return 250;
                    case "base":
                        return 8453;
                    case "opbnb":
                        return 204;
                    default:
                        return "---";
                }
            };
            await switchChainAsync({ chainId: Number(chainList(value)) }).then((res: any) => {
                if (res) {
                    setInitialValue(value);
                    setChain(value)
                    localStorage.setItem("chain", value);
                }
            })
        } else {
            setInitialValue(value);
            localStorage.setItem("chain", value);
            setChain(value)
            disconnect();
        }
    }

    const chainHeader = (value: any) => {
        switch (value) {
            case 1:
                return "erc";
            case 56:
                return "bep";
            case 137:
                return "poly";
            case 42161:
                return "arb";
            case 43114:
                return "avax";
            case 10:
                return "op";
            case 2000:
                return "doge";
            case 250:
                return "fmt";
            case 8453:
                return "base";
            case 204:
                return "opbnb";
            default:
                return "---";
        }
    };

    useEffect(() => {
        if (initialValue === "sol" || initialValue === "apt" || initialValue === "ton") {
            setInitialValue(chain)
        }
        else {
            setInitialValue(chainHeader(chainId))
        }
    }, [chainId, chain]);

    useEffect(() => {
        if (chain === 'apt') {
            localStorage.setItem("chain", 'apt')
        } else {
            if (chain === 'sol') {
                localStorage.setItem("chain", 'sol')
            } else
                if (chain === 'ton') {
                    localStorage.setItem("chain", 'ton')
                }
                else {
                    if (chain !== undefined && chain !== chainHeader(chainId)) {
                        localStorage.setItem("chain", chainHeader(chainId))
                    }
                }
        }

    }, [chainId, chain])

    useEffect(() => {
        setInitialValue(chain)
    }, [chain]);

    const handleConnectPontem = async () => {
        if (initialValue === "apt") {
            try {
                await connectAptos();
                setIsModalVisible(false);
                setIsModalVisible(false);
                localStorage.setItem("chain", "apt");
                setInitialValue("apt");
            } catch (e) {
                console.error("Login failed");
            }
        } else {
            await connectAptos();
            setIsModalVisible(false);
            localStorage.setItem("chain", "apt");
            setInitialValue("apt");
        }
    };

    const icon_network = (chainId: any) => {
        switch (chainId) {
            case 1:
                return ICON_ETH_URL;
                break;
            case 137:
                return ICON_POLY_URL;
                break;
            case 43114:
                return ICON_AVAX;
                break;
            case 10:
                return ICON_OP;
                break;
            case 2000:
                return ICON_DOGE;
                break;
            case 250:
                return ICON_FMT;
                break;
            case 42161:
                return ICON_ARB;
                break;
            case 204:
                return ICON_OPBNB_URL;
                break;
            case 8453:
                return ICON_BASE;
                break;
            case 13371:
                return ICON_ZK;
                break;
            case 81457:
                return ICON_BLAST;
                break;
            default:
                return ICON_BNB_URL;
        }
    };

    const token_network = (chainId: any) => {
        switch (chainId) {
            case 1:
                return "ETH";
                break;
            case 137:
                return "MATIC";
                break;
            case 42161:
                return "ETH";
                break;
            case 204:
                return "BNB";
                break;
            case 8453:
                return "ETH";
                break;
            case 43114:
                return "AVAX";
                break;
            case 10:
                return "OP";
                break;
            case 2000:
                return "DOGE";
                break;
            case 250:
                return "FMT";
                break;
            case 13371:
                return "IMX";
                break;
            case 81457:
                return "ETH";
                break;
            default:
                return "BNB";
        }
    };

    const gotoIdoPool = () => {
        navigate("/IDOPools");
    };
    const gotoLaunchPool = () => {
        navigate("/LaunchPool");
    };
    const gotoMyNft = () => {
        navigate("/MyArtwork");
    };
    const gotoMyAccount = () => {
        navigate("/my-account");
    };

    const menu = (
        <>
            <div className="main-dropdown-wl">
                <div className="top-dropdown">
                    <div className="box-balance">
                        <div className="icon">
                            <img src="/images/icon-bscs-menu.svg" alt="" />
                        </div>
                        <div className="balance">
                            <span className="name-bl">BSCS Balance</span>
                            <span className="number-bl">
                                {amountBscs.toFixed(2) ? amountBscs : "0.00"} BSCS
                            </span>
                        </div>
                    </div>
                    <div className="box-address">
                        <div className="address-name">Wallet address :</div>
                        <div className="account-name">
                            {address?.substring(0, 4)}...{address?.substring(address.length - 4)}
                            <CopyToClipboard
                                text={`${account}`}
                                onCopy={() => {
                                    message.success("Copied", 0.4);
                                }}
                            >
                                <span className="img-copy">
                                    <img src="/images/imgido/copy.png" alt="" />
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="bottom-dropdown">
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoMyAccount()}>
                            <Rank />
                            <span className="name-rs">My Account</span>
                        </div>
                    </div>
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoIdoPool()}>
                            <Launchpool />
                            <span className="name-rs">IDO Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoLaunchPool()}>
                        <div className="icon-rs">
                            <Rocket />
                            <span className="name-rs">Launch Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoMyNft()}>
                        <div className="icon-rs">
                            <MyNFT />
                            <span className="name-rs">My NFT</span>
                        </div>
                    </div>
                </div>
                <div className="footer-dropdown">
                    <button type="button" className="btn-dis" onClick={handleDisconnect}>
                        Disconnect Wallet
                    </button>
                </div>
            </div>
        </>
    );


    useEffect(() => {
        actions.updateChain(initialValue)
    }, [initialValue])

    return (
        <>
            <div className="filter-chain">
                <Select
                    value={initialValue}
                    onChange={handleChain}
                    style={{ width: 220, marginRight: 10 }}
                    placeholder="Select network"
                >
                    <Option key="bep" value="bep">
                        <img src="/images/bnb.png" alt="" />
                        BNB Chain
                    </Option>
                    <Option key="erc" value="erc">
                        <img src="/images/ethe.png" alt="" />
                        Ethereum
                    </Option>
                    <Option key="opbnb" value="opbnb">
                        <img src="/images/bnb.png" alt="" />
                        opBNB
                    </Option>
                    <Option key="avax" value="avax">
                        <img src="/images/avax.png" alt="" /> Avax Chain
                    </Option>
                    <Option key="op" value="op">
                        <img src="/images/op.png" alt="" /> Optimism
                    </Option>
                    <Option key="poly" value="poly">
                        <img src="/images/poly.png" alt="" /> Polygon
                    </Option>
                    <Option key="doge" value="doge">
                        <img src="/images/doge.png" alt="" /> Doge Chain
                    </Option>
                    <Option key="fmt" value="fmt">
                        <img src="/images/fmt.png" alt="" /> Fantom
                    </Option>
                    <Option key="arb" value="arb">
                        <img src="/images/arb.png" alt="" /> Arbitrum
                    </Option>
                    <Option key="base" value="base">
                        <img src="/images/base.png" alt="" /> Base
                    </Option>
                    <Option key="apt" value="apt">
                        <img src="/images/apt.png" alt="" /> Aptos
                    </Option>
                    <Option key="sol" value="sol">
                        <img src="/images/sol.png" alt="" /> Solana
                    </Option>
                    <Option key="ton" value="ton">
                        <img src="/images/ton.png" alt="" /> TON
                    </Option>
                </Select>
            </div>
            {isConnected ? (
                <>
                    <Dropdown overlay={menu} placement="bottom">
                        <button className="btn-account" type="button">
                            <div className="img-bnb">
                                <img src={icon_network(chainId)} alt="" />{" "}
                                <div className="price">
                                    {bnbBalance} {token_network(chainId)}
                                </div>
                            </div>

                            <span className="account-style">
                                <img src="/images/meta-wl.png" alt="" />
                                {address?.substring(0, 4)}...{address?.substring(address.length - 4)}
                            </span>
                        </button>
                    </Dropdown>
                </>
            ) : curentAddress !== "" && chain === "sol" ? (
                <>
                    <Dropdown overlay={menuSol} placement="bottom">
                        <button className="btn-account" type="button">
                            <div className="img-bnb">
                                <img
                                    src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/6d4d28c5bcad090ae08e30b8e993bd942b4b71bc15b2885b05.png"
                                    alt=""
                                />{" "}
                                <div className="price">{balance.toFixed(2)} SOL</div>
                            </div>
                            <span className="account-style">
                                <img src="/images/phantom-logo.png" alt="" />
                                {isMobile ? accountEllipsisMobieSol : accountEllipsisSol}
                            </span>
                        </button>
                    </Dropdown>
                </>
            )
                : chain === "ton" && userFriendlyAddress !== '' && userFriendlyAddress ? (
                    <>
                        <Dropdown overlay={menuTon} placement="bottom">
                            <button className="btn-account" type="button">
                                <div className="img-bnb">
                                    <img
                                        src="/images/ton.png"
                                        alt=""
                                    />{" "}
                                    <div className="price">{formatNumber(balanceTon, 4)} TON</div>
                                </div>
                                <span className="account-style">
                                    <img src="/images/ton.png" alt="" />
                                    {isMobile ? accountEllipsisMobieTon : accountEllipsisTon}
                                </span>
                            </button>

                        </Dropdown>
                    </>
                )
                    : (
                        isAcc && chain === "apt" ?
                            <>
                                <Dropdown overlay={menuApt} placement="bottom">
                                    <button className="btn-account" type="button">
                                        <div className="img-bnb">
                                            <img src="/images/apt.png" alt="" />{" "}
                                            <div className="price">{isBalanceAPT} APT</div>
                                        </div>
                                        <span className="account-style">
                                            <img
                                                src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/2bf2c69b6ee7f607e8ea256f1ed2c7ba5f442d85a7b8c301f6.png"
                                                alt=""
                                            />
                                            {accountEllipsisApt}
                                        </span>
                                    </button>
                                </Dropdown>
                            </>
                            :
                            <>
                                <>
                                    {chain === "sol" ?
                                        <>
                                            <WalletMultiButton />
                                        </>
                                        :
                                        chain === "apt" ?
                                            <>
                                                <button className="btn-connect" onClick={handleConnectPontem}>
                                                    Connect Wallet <img src="/images/arrow.png" alt="" />
                                                </button>
                                            </>
                                            :
                                            chain === 'ton' ?
                                                <>
                                                    <TonConnectButton className="btn-connectTon" />
                                                </>
                                                :
                                                <>
                                                    <button className="btn-connect" onClick={handleConnectWagmi}>
                                                        Connect Wallet <img src="/images/arrow.png" alt="" />
                                                    </button>
                                                </>
                                    }
                                </>
                            </>
                    )}
        </>
    )
}
export default WagmiConnect