import React from 'react'
import styled from 'styled-components'

export const BodyWrapper1 = styled.div`
  position: relative;
  max-width: 736px;
  width: 100%;
  z-index: 5;
  margin: 0 auto;
  padding: 36px 46px;
  background: #fff;
  border: 0.9px solid #EEF0F7;
  box-shadow: 0px 10px 30px rgba(145, 153, 162, 0.16);
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function FormBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper1>{children}</BodyWrapper1>
}
