/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from "bignumber.js";
import React, { useEffect, useState, useCallback } from "react";
import { Tabs, Collapse, Button as ButtonConnect, message, Modal } from "antd";
import Value from "../../componentsST/Value";
import "./style.css";
import useSousApprove from "../../hooksST/useSousApprove";
import useSousHarvest from "../../hooksST/useSousHarvest";
import useSousHarvestV3 from "../../hooksST/useSousHarvestV3";
import useSousHarvestUserLock from "../../hooksST/useSousHarvestUserLock";
import { fetchPoolsUserDataAsync } from "../../store/pools/index";
import useModal from "../../hooksST/useModal";
import DepositModal from "./DepositModal";
import UnstakingFeeCountdownRow from "./UnstakingFeeCountdownRow";
import { OverlayTrigger } from "react-bootstrap";
import { getBalanceNumber } from "../../utilsST/formatBalance";
import { BIG_ZERO } from "../../utilsST/bigNumber";
import { formatNumber } from "../../utilsST/formatBalance";
import Button from "../../componentsST/ButtonV2";
import { useDispatch } from "react-redux";
import WithdrawModal from "./WithdrawModal";
import ApyCalculatorModal from "../../componentsST/ApyCalculatorModal";
import WithdrawUserLockModal from "./WithdrawUserLockModal";
import BlockCountdown from "./BlockCountdown";
import BlockCountdownDetail from "./BlockCountdownDetail";
import sousChefV3ABI from "../../config/abi/sousChefV3.json";
import { getContract } from "../../utilsST/contractHelpers";
import ConnectWallet from "./../../components/Connect-wallet/ConnectWallet";
import { useCurrentBlock } from "../../store/hooks";
import { getTokenStake, getRewards } from "../../utilsST/callHelpers";
import sousChefAbi from "../../abi/stakeFinal.json";
import { useContract } from "../../hooks/useContract";
import getBalanceReward from "../../hooks/getBalanceReward";
import { useAccount } from "wagmi";

const StartPools = ({ pool, userDataLoaded }) => {
    const [isView, setIsView] = useState(false);
    const { address } = useAccount();

    let account = address
    const dispatch = useDispatch();
    const [requestedApproval, setRequestedApproval] = useState(false);
    const [isHiden, setHiden] = useState(false);
    const [isBalanceStake, setBalanceStake] = useState(0);
    const [isPendingRewards, setPendingRewards] = useState(0);
    const { userData } = pool;
    const { onReward } = useSousHarvest(pool.sousId);
    const { onRewardV3 } = useSousHarvestV3(pool.sousId);
    const { onRewardUserLock } = useSousHarvestUserLock(pool.sousId);
    const [pendingTx, setPendingTx] = useState(false);
    const [listSchedule, setListSchedule] = useState([]);
    const stakings = userData?.stakedBalance
        ? new BigNumber(userData.stakedBalance)
        : BIG_ZERO;

    let stakingTokenDollarBalance = getBalanceNumber(
        stakings.multipliedBy(pool.stakingTokenPrice),
        pool.stakingToken.decimals
    );

    const earnings = userData?.pendingReward
        ? new BigNumber(userData.pendingReward)
        : BIG_ZERO;
    let earningTokenDollarBalance = getBalanceNumber(
        earnings.multipliedBy(pool.earningTokenPrice),
        pool.earningToken.decimals
    );

    const allowance = userData.allowance;
    const { onApprove } = useSousApprove(
        pool.stakingToken.address,
        pool.contractAddress
    );
    const isApproved = account && allowance && allowance.isGreaterThan(0);
    const handleApprove = useCallback(async () => {
        try {
            setRequestedApproval(true);
            await onApprove()
                .then((res) => {
                    setRequestedApproval(true);
                    res.wait().then((res2) => {
                        if (res2 != null) {
                            setRequestedApproval(false);
                            setHiden(true);
                            message.success({
                                type: "success",
                                content: "Approve success!",
                                className: "custom-class",
                                duration: 3,
                            });
                        }
                    });
                })
                .catch((error) => {
                    setRequestedApproval(false);
                });
            dispatch(fetchPoolsUserDataAsync(account));
        } catch (e) {
            message.error({
                type: "error",
                content: e?.message,
                className: "custom-class",
                duration: 3,
            });
            setRequestedApproval(false);
        }
    }, [onApprove, dispatch, account, pool]);

    const handleReward = useCallback(async () => {
        try {
            const balanceReward = await getBalanceReward(
                pool.earningToken.address,
                pool.contractAddress
            );
            if (parseFloat(balanceReward) > isPendingRewards) {
                setPendingTx(true);
                await onReward()
                    .then((res) => {
                        setPendingTx(true);
                        res.wait().then((res2) => {
                            if (res2 != null) {
                                setPendingTx(false);
                                message.success({
                                    type: "success",
                                    content: "Harvest success!",
                                    className: "custom-class",
                                    duration: 3,
                                });
                            }
                        });
                    })
                    .catch((error) => {
                        setPendingTx(false);
                    });
                dispatch(fetchPoolsUserDataAsync(account));
            } else {
                message.error({
                    type: "error",
                    content: "The pool is temporarily out of tokens.",
                    className: "custom-class",
                    duration: 5,
                });
            }
        } catch (e) {
            setPendingTx(false);
        }
    }, [account, dispatch, onReward, pool]);

    const handleRewardV3 = useCallback(async () => {
        try {
            const balanceReward = await getBalanceReward(
                pool.earningToken.address,
                pool.contractAddress
            );
            if (parseFloat(balanceReward) > isPendingRewards) {
                setPendingTx(true);
                await onRewardV3()
                    .then((res) => {
                        setPendingTx(true);
                        res.wait().then((res2) => {
                            if (res2 != null) {
                                setPendingTx(false);
                                message.success({
                                    type: "success",
                                    content: "Harvest success!",
                                    className: "custom-class",
                                    duration: 3,
                                });
                            }
                        });
                    })
                    .catch((error) => {
                        setPendingTx(false);
                    });
                dispatch(fetchPoolsUserDataAsync(account));
            } else {
                message.error({
                    type: "error",
                    content: "The pool is temporarily out of tokens.",
                    className: "custom-class",
                    duration: 5,
                });
            }
        } catch (e) {
            setPendingTx(false);
        }
    }, [account, dispatch, onRewardV3, pool]);

    const handleRewardUserLock = useCallback(async () => {
        try {
            const balanceReward = await getBalanceReward(
                pool.earningToken.address,
                pool.contractAddress
            );
            if (parseFloat(balanceReward) > isPendingRewards) {
                setPendingTx(true);
                await onRewardUserLock()
                    .then((res) => {
                        setPendingTx(true);
                        res.wait().then((res2) => {
                            if (res2 != null) {
                                setPendingTx(false);
                                message.success({
                                    type: "success",
                                    content: "Harvest success!",
                                    className: "custom-class",
                                    duration: 3,
                                });
                            }
                        });
                    })
                    .catch((error) => {
                        setPendingTx(false);
                    });
                dispatch(fetchPoolsUserDataAsync(account));
            } else {
                message.error({
                    type: "error",
                    content: "The pool is temporarily out of tokens.",
                    className: "custom-class",
                    duration: 5,
                });
            }
        } catch (e) {
            setPendingTx(false);
        }
    }, [account, dispatch, onRewardUserLock, pool]);

    const [onPresentStakeInPoolModal] = useModal(
        <DepositModal
            sousId={pool.sousId}
            decimals={pool.stakingToken.decimals}
            stakingToken={pool.stakingToken}
            addressToken={pool.stakingToken.address}
            max={userData.stakingTokenBalance}
            // onConfirm={handleStake}
            isModalOpen={true}
        />
    );

    const [onPresentWithdrawModal] = useModal(
        <WithdrawModal
            sousId={pool.sousId}
            decimals={pool.stakingToken.decimals}
            fees={pool.fees}
            lastStakingBlock={userData.lastStakingBlock}
            blockPeriod={pool.blockPeriod}
            stakingToken={pool.stakingToken}
            max={userData.stakedBalance}
        // onConfirm={handleUnstake}
        />
    );

    const [onPresentWithdrawUserLockModal] = useModal(
        <WithdrawUserLockModal
            sousId={pool.sousId}
            decimals={pool.stakingToken.decimals}
            versionSC={pool.versionSC}
            fees={pool.fees}
            lastStakingBlock={userData.lastStakingBlock}
            blockPeriod={pool.blockPeriod}
            stakingToken={pool.stakingToken}
            max={userData.unstakeAmount}
            balance={userData.stakedBalance}
        // onConfirm={handleUnstakeUserLock}
        />
    );
    const [onPresentWithdrawUserLockModalV3] = useModal(
        <WithdrawUserLockModal
            sousId={pool.sousId}
            decimals={pool.stakingToken.decimals}
            versionSC={pool.versionSC}
            fees={pool.fees}
            lastStakingBlock={userData.lastStakingBlock}
            blockPeriod={pool.blockPeriod}
            stakingToken={pool.stakingToken}
            max={userData.unstakeAmount}
            balance={userData.stakedBalance}
        // onConfirm={handleUnstakeV3}
        />
    );
    const [onPresentApyModal] = useModal(
        <ApyCalculatorModal
            tokenPrice={pool.earningTokenPrice}
            apr={pool.apr}
            earningTokenSymbol={pool.earningToken.symbol}
            performanceFee={pool.fees}
            isModalOpen={true}
        />
    );
    const { Panel } = Collapse;

    const onChangeCollapse = (key) => {
        console.log(key);
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const contractAddressPool = getContract(sousChefV3ABI, pool.contractAddress);
    useEffect(() => {
        if (account && contractAddressPool) {
            contractAddressPool.methods
                .getUserStakedCount(account)
                .call()
                .then(async (resStakedCount) => {
                    if (resStakedCount !== "0") {
                        let arrData = [];
                        for (let i = 0; i < parseInt(resStakedCount); i++) {
                            arrData.push(
                                contractAddressPool.methods.stakeDetails(account, i).call()
                            );
                        }
                        let listData = [];
                        // get result
                        await Promise.all([arrData]).then((resAll) => {
                            for (let i = 0; i < parseInt(resStakedCount); i++) {
                                resAll[0][i].then((data) => {
                                    listData.push(data);
                                });
                            }
                        });
                        setTimeout(() => {
                            setListSchedule(listData);
                        }, 1000);
                    }
                });
        }
    }, [account]);

    const contract = useContract(pool.contractAddress, sousChefAbi);
    const getBalance = async () => {
        if (contract) {
            const balance = await getTokenStake(contract, pool.sousId, account);
            setBalanceStake(balance.amount / 1e18);
        }
    };
    const getPendingRewards = async () => {
        if (contract) {
            const rewards = await getRewards(contract, pool.sousId, account);
            setPendingRewards(rewards[0] / 1e18);
        }
    };

    useEffect(() => {
        getBalance();
        getPendingRewards();
    });
    const contentModalClick = (
        <>
            <table className="table-modal">
                <tr>
                    <th>Amount</th>
                    <th>Stake block</th>
                    <th>Remaining unlock</th>
                </tr>
                {listSchedule
                    .filter((x) => x.amount > 0)
                    .map((item, i) => {
                        return (
                            <tr key={`schedule_${i}`}>
                                <td>{item.amount / 1e18}</td>
                                <td>{item.startStakeBlock}</td>
                                <td>
                                    {item.endStakeBlock}{" "}
                                    <a
                                        href={`https://bscscan.com/block/countdown/${item.endStakeBlock}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-white"
                                    >
                                        <span className="schedules-p-time">
                                            <img src="/images/pool/p-time.png" alt="" />
                                        </span>
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
            </table>
        </>
    );
    const headerPool = (
        <>
            <div className="guide-swap">
                <div className="item-round">
                    <div className="name-project">
                        <div className="box-logo">
                            <img
                                src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=5`}
                                className="logo-big"
                                alt=""
                            />
                            <img
                                src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=5`}
                                className="logo-small"
                                alt=""
                            />
                        </div>
                        <div className="box-name">
                            <span className="title-earn">
                                Earn {pool.earningToken.symbol}
                            </span>
                            <span className="title-stake">
                                Stake {pool.stakingToken.symbol}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-round">
                    <div className="name-project">
                        <div className="number">
                            <span className="txt">
                                <Value size={16} unitafter="%" value={pool.apr || "0%"} />
                            </span>{" "}
                            <span className="img-cul">
                                <button
                                    type="button"
                                    onClick={onPresentApyModal}
                                    className="button-show-modal"
                                >
                                    <img src="/images/pool/cul.png" alt="" />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-round">
                    <div className="name-project">
                        <div className="number">
                            <span className="txt">
                                <Value
                                    size={16}
                                    decimals={0}
                                    unitafter="days"
                                    value={pool.lockup}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-round">
                    <div className="name-project">
                        <div className="number">
                            <span className="txt">
                                <Value
                                    size={16}
                                    unit="$"
                                    value={pool.stakedTvl ? +pool.stakedTvl : 0}
                                />{" "}
                                <span className="symbol">{pool.stakingToken.symbol}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-round">
                    <div className="name-project">
                        <div className="number">
                            <span className="txt">
                                <BlockCountdown pool={pool} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-round">
                    <div className="name-project">
                        <div className="number">
                            <span className="txt main-color-pool">
                                <Value
                                    size={16}
                                    className="txt main-color-pool"
                                    value={account ? isPendingRewards : 0.0}
                                />
                            </span>
                            <span className="symbol">{pool.earningToken.symbol}</span>
                        </div>
                    </div>
                </div>
                <Modal
                    className="modal-schedule"
                    title="Schedule"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    {contentModalClick}
                </Modal>
            </div>
        </>
    );
    const currentBlock = useCurrentBlock();
    const contentBottom = (
        <>
            <div className="main-swap-pool">
                <div className="item-block">
                    <div className="content-block">
                        <div className="reward-in">
                            <span className="txt-l">
                                {currentBlock >= pool.startBlock
                                    ? "Rewards end in"
                                    : "Rewards start in"}
                            </span>
                            <div className="txt-r">
                                <BlockCountdownDetail pool={pool} />
                            </div>
                        </div>
                        <div className="view-port">
                            {pool.userLock ? (
                                <div className="d-flex text-white mt-2">
                                    <div className="text details">Pool Details </div>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <div
                                                className="bg-white p-2"
                                                style={{
                                                    borderRadius: 15,
                                                    paddingRight: 20,
                                                    // maxWidth: 350,
                                                }}
                                            >
                                                <ul>
                                                    <li>
                                                        <b>
                                                            Stake {pool.stakingToken.symbol} Earn{" "}
                                                            {pool.earningToken.symbol}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <b>Total Rewards: </b>
                                                        {pool.totalRewards} {pool.earningToken.symbol}
                                                    </li>
                                                    <li>
                                                        <b>Lockup period: </b>
                                                        {pool.lockup} days
                                                    </li>
                                                    <li>
                                                        <b>Daily emission: </b>
                                                        {pool.dailyRewards} {pool.earningToken.symbol}
                                                    </li>
                                                    <li>
                                                        {" "}
                                                        <b>Claim Reward:</b> Anytime
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <span>
                                            <img src="/images/pool/p-help.svg" alt="" />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            ) : (
                                <UnstakingFeeCountdownRow
                                    fees={pool.fees}
                                    lastStakingBlock={userData.lastStakingBlock}
                                    blockPeriod={pool.blockPeriod}
                                />
                            )}
                        </div>
                        <div className="view-port">
                            <div className="text details">
                                View project{" "}
                                <span>
                                    <a
                                        href={`${pool.stakingToken.projectLink}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src="/images/pool/p-info.svg" alt="" />
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="view-port">
                            <div className="text details">
                                View contract pool{" "}
                                <span>
                                    <a
                                        href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src="/images/pool/p-info.svg" alt="" />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-block">
                    <div className="content-block">
                        <div className="box-stake">
                            <div className="title">{pool.earningToken.symbol} Earned</div>
                            <div className="box-bottom">
                                <div className="round-l">
                                    <div className="number-stake">
                                        <Value size={18} value={account ? isPendingRewards : 0} />{" "}
                                        <span className="symbol">{pool.earningToken.symbol}</span>
                                    </div>
                                    <div className="number-rw">
                                        <Value
                                            size={14}
                                            unit={"$"}
                                            value={earningTokenDollarBalance}
                                        />
                                    </div>
                                </div>
                                <div className="round-r">
                                    {pool.userLock ? (
                                        <>
                                            {pool.versionSC === 3 ? (
                                                <Button
                                                    primary
                                                    disabled={
                                                        !account ||
                                                        pendingTx ||
                                                        userData.pendingReward.eq(new BigNumber(0)) ||
                                                        !isApproved ||
                                                        pool.disHarvest === true
                                                    }
                                                    loading={pendingTx}
                                                    text="Harvest"
                                                    click={handleRewardV3}
                                                />
                                            ) : (
                                                <Button
                                                    primary
                                                    disabled={
                                                        !account ||
                                                        pendingTx ||
                                                        userData.pendingReward.eq(new BigNumber(0)) ||
                                                        !isApproved ||
                                                        pool.disHarvest === true
                                                    }
                                                    loading={pendingTx}
                                                    text="Harvest"
                                                    click={handleRewardUserLock}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Button
                                            primary
                                            disabled={
                                                !account ||
                                                pendingTx ||
                                                userData.pendingReward.eq(new BigNumber(0)) ||
                                                !isApproved ||
                                                pool.disHarvest === true
                                            }
                                            loading={pendingTx}
                                            text="Harvest"
                                            click={handleReward}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-block">
                    <div className="content-block">
                        <div className="box-stake">
                            <div className="title res">{pool.stakingToken.symbol} Staked</div>
                            <div className="box-bottom">
                                <div className="round-l">
                                    <div className="number-stake">
                                        <Value size={20} value={account ? isBalanceStake : 0} />
                                        {pool.versionSC === 3 ? (
                                            <button
                                                type="button"
                                                className="btn-schedule mg-left-5"
                                                onClick={showModal}
                                            >
                                                <span>
                                                    <img src="/images/pool/calendar.svg" alt="" />
                                                </span>
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="number-rw">
                                        <Value
                                            size={15}
                                            unit={"$"}
                                            value={account ? stakingTokenDollarBalance : "$0.00"}
                                        />
                                    </div>
                                </div>
                                <div className="round-r">
                                    {pool.userLock ? (
                                        <>
                                            {pool.versionSC === 3 ? (
                                                <Button
                                                    ghost
                                                    disabled={
                                                        !account ||
                                                        userData.stakedBalance.eq(new BigNumber(0)) ||
                                                        !isApproved ||
                                                        pool.disUnstake === true ||
                                                        userData.unstakeAmount <= 0
                                                    }
                                                    text="Unstake"
                                                    click={onPresentWithdrawUserLockModalV3}
                                                />
                                            ) : (
                                                <Button
                                                    ghost
                                                    disabled={
                                                        !account ||
                                                        userData.stakedBalance.eq(new BigNumber(0)) ||
                                                        !isApproved ||
                                                        pool.disUnstake === true ||
                                                        userData.unstakeAmount <= 0
                                                    }
                                                    text="Unstake"
                                                    click={onPresentWithdrawUserLockModal}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Button
                                            ghost
                                            disabled={
                                                !account ||
                                                userData.stakedBalance.eq(new BigNumber(0)) ||
                                                !isApproved ||
                                                pool.disUnstake === true
                                            }
                                            text="Unstake"
                                            click={onPresentWithdrawModal}
                                        />
                                    )}
                                    <Button
                                        className="btn-plus"
                                        primary
                                        disabled={
                                            !account ||
                                            // !isApproved ||
                                            pool.isFinished === true ||
                                            pool.disStake === true ||
                                            (!isApproved && !isHiden)
                                        }
                                        text="+"
                                        click={onPresentStakeInPoolModal}
                                    />
                                </div>
                            </div>
                            <div className="round-connect">
                                {account ? (
                                    <>
                                        {userDataLoaded ? (
                                            <>
                                                {!isApproved ? (
                                                    <>
                                                        {!isHiden && (
                                                            <Button
                                                                loading={requestedApproval}
                                                                className="btn-w-full"
                                                                text="Approve Contract"
                                                                // disabled={pool.isFinished === true}
                                                                click={handleApprove}
                                                                primary
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <Button
                                                        loading={requestedApproval}
                                                        className="btn-w-full"
                                                        text=" Increase approve"
                                                        // disabled={pool.isFinished === true}
                                                        click={handleApprove}
                                                        ghost
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <Button
                                                primary
                                                className="btn-w-full Loading"
                                                text="Loading..."
                                            />
                                        )}
                                    </>
                                ) : (
                                    <div className="connect-cus-start-pool">
                                        <ConnectWallet />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className="main-round-pool">
                <div className="round-pool">
                    <Collapse onChange={onChangeCollapse}>
                        <Panel header={headerPool}>{contentBottom}</Panel>
                    </Collapse>
                </div>
            </div>
        </>
    );
};
export default StartPools;
