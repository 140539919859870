/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { rendererCountDown, rendererCountDown1 } from "./utils/render-countdown";
import { useContract } from "../../hooks/useContract";
import abiStake from "./utils/abiStake.json"
import { SC_STAKE_BSCS } from "../../constants";
import Value from "../../components/Value";
import {
    Spin, message
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHookStaked } from './storeStaked'
import { useAccount } from "wagmi";

const timeDown = 7 * 24 * 60 * 60 * 1000
// const timeDown = 5 * 60 * 1000

const Action = (props: any) => {

    const { address } = useAccount();
    let account = address
    const [state, actions] = useHookStaked()
    const contractStake: any = useContract(SC_STAKE_BSCS, abiStake);
    const [getUser, setGetUser] = useState<any>();
    const [timeUnstake, setTimeUnstake] = useState<any>();
    const [amountBscsPool, setAmountBscsPool] = useState<any>(0);
    const [amountReward, setAmountReward] = useState<any>(0);
    const [loadingStake, setloadingStake]: any = useState(false)
    const [loadingWithdraw, setloadingWithdraw]: any = useState(false)
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
    const [isAction, setIsAction]: any = useState(false)
    const [isAction1, setIsAction1]: any = useState(false)
    const [timeLock, settimeLock]: any = useState(0)
    const [stakeTime, setstakeTime]: any = useState(0)
    const [lockTimeUnstake, setlockTimeUnstake]: any = useState(0)


    const sendData = (value: any) => {
        props.parentCallback(value);
    };
    useEffect(() => {
        sendData(isAction1)
    }, [isAction1])

    const formatNumber = (x: any, max: any) => {
        let tem = x * 10000000
        let tem1 = Math.floor(tem)
        let tem2 = tem1 / 10000000
        if (tem2) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };

    const getBscsPool = async () => {
        if (contractStake) {
            const amount = await contractStake.balanceOf(account);
            let temp = Math.floor((Number(amount) / 1e18) * 100000) / 100000;
            setAmountBscsPool(Number(temp));
        }
    };

    useEffect(() => {
        if (account) {
            getBscsPool();
        } else {
            setAmountBscsPool(0)
        }
    }, [account, props.isSuccess, props.tabBox, isAction, contractStake]);

    const getUserMap = async () => {
        if (contractStake && account) {
            const user = await contractStake.userMap(account);
            setGetUser(user.inUnStakeAmount / 1e18);
            setTimeUnstake(Number(user.unstakeTime))
            const amountRW = await contractStake.getEarnedRewardTokens(account);
            setAmountReward(amountRW / 1e18 || 0)
        }
    };

    const getTimeLock = async () => {
        if (contractStake && account) {
            let time = await contractStake.stakeLockPeriod()
            settimeLock(Number(time))
            let time1 = await contractStake.stakeTime(account)
            setstakeTime(Number(time1))
            let time2 = await contractStake.lockTimePeriod()
            setlockTimeUnstake(Number(time2))
        }
    }

    useEffect(() => {
        if (account) {
            getUserMap();
            getTimeLock()
        } else {
            setGetUser(0)
            setTimeUnstake(0)
            setAmountReward(0)
        }
    }, [account, props.isSuccess, props.tabBox, isAction, contractStake]);

    useEffect(() => {
        const intervalId = setInterval(getUserMap, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, [account]);

    const handleStakeReward = async () => {
        try {
            setloadingStake(true)
            await contractStake.stakeRewards().then(async (res: any) => {
                const timestamp = new Date().getTime();
                const date1 = new Date(timestamp);
                let timeStake = date1.toLocaleString('en-GB')
                let paramNoti = {
                    projectName: "BSCS",
                    amount: amountReward,
                    actionType: 1,
                    userAddress: account,
                    tokenName: "BSCS",
                    actionTime: timeStake,
                    hash: res?.hash,
                    status: "Waiting"
                }
                await actions.callNoti(paramNoti)

                let params = {
                    ownerAddress: account,
                    poolContract: SC_STAKE_BSCS
                }
                let resapi = await actions.updateStaked(params)
                if (resapi.data.data) {
                    res.wait()
                        .then(async (resWait: any) => {
                            if (resWait?.status === 1) {
                                setloadingStake(false)
                                setIsAction(!isAction)
                                setIsAction1(!isAction1)

                                const timestamp = new Date().getTime();
                                const date1 = new Date(timestamp);
                                let timeStake = date1.toLocaleString('en-GB')
                                let paramNoti = {
                                    projectName: "BSCS",
                                    amount: amountReward,
                                    actionType: 1,
                                    userAddress: account,
                                    tokenName: "BSCS",
                                    actionTime: timeStake,
                                    hash: resWait?.transactionHash,
                                    status: "Success"
                                }
                                await actions.callNoti(paramNoti)

                                message.success({
                                    type: "success",
                                    content: "Stake rewards successfully",
                                    className: "custom-class",
                                    duration: 3,
                                });
                            } else {
                                const timestamp = new Date().getTime();
                                const date1 = new Date(timestamp);
                                let timeStake = date1.toLocaleString('en-GB')
                                let paramNoti = {
                                    projectName: "BSCS",
                                    amount: amountReward,
                                    actionType: 1,
                                    userAddress: account,
                                    tokenName: "BSCS",
                                    actionTime: timeStake,
                                    hash: resWait?.transactionHash,
                                    status: "Status hash fail"
                                }
                                await actions.callNoti(paramNoti)
                                message.error({
                                    type: "error",
                                    content: "Unknown error (status hash fail)",
                                    className: "custom-class",
                                    duration: 3,
                                });
                                setloadingStake(false)
                            }
                        }
                        )
                        .catch((err: any) => {
                            console.log('err', err)
                            message.error({
                                type: "error",
                                content: "Unknown error",
                                className: "custom-class",
                                duration: 3,
                            });
                            setloadingStake(false)
                        })
                } else {
                    message.error({
                        type: "error",
                        content: "API update status stake fail",
                        className: "custom-class",
                        duration: 3,
                    });
                    setloadingStake(false)
                }
            })
                .catch((err: any) => {
                    console.log('err stakeRewards', err)
                    message.error({
                        type: "error",
                        content: err.reason || err?.message,
                        className: "custom-class",
                        duration: 3,
                    });
                    setloadingStake(false)
                })
        } catch (error: any) {
            console.log('error stakeRewards', error)
            message.error({
                type: "error",
                content: error,
                className: "custom-class",
                duration: 3,
            });
            setloadingStake(false)
        }
    }

    const handleWithdrawReward = async () => {
        try {
            setloadingWithdraw(true)
            await contractStake.claim2().then(async (res: any) => {
                if (Number(amountReward) > 50000) {
                    const timestamp = new Date().getTime();
                    const date1 = new Date(timestamp);
                    let timeStake = date1.toLocaleString('en-GB')
                    let paramNoti = {
                        projectName: "BSCS",
                        amount: amountReward,
                        actionType: 2,
                        userAddress: account,
                        tokenName: "BSCS",
                        actionTime: timeStake,
                        hash: res?.hash,
                        status: "Waiting"
                    }
                    await actions.callNoti(paramNoti)
                }
                res.wait()
                    .then(async (resWait: any) => {
                        if (resWait?.status === 1) {
                            setloadingWithdraw(false)
                            setIsAction(!isAction)
                            setIsAction1(!isAction1)
                            if (Number(amountReward) > 50000) {
                                const timestamp = new Date().getTime();
                                const date1 = new Date(timestamp);
                                let timeStake = date1.toLocaleString('en-GB')
                                let paramNoti = {
                                    projectName: "BSCS",
                                    amount: amountReward,
                                    actionType: 2,
                                    userAddress: account,
                                    tokenName: "BSCS",
                                    actionTime: timeStake,
                                    hash: resWait?.transactionHash,
                                    status: "Success"
                                }
                                await actions.callNoti(paramNoti)
                            }
                            message.success({
                                type: "success",
                                content: "Withdraw rewards successfully",
                                className: "custom-class",
                                duration: 3,
                            });
                        } else {
                            if (Number(amountReward) > 50000) {
                                const timestamp = new Date().getTime();
                                const date1 = new Date(timestamp);
                                let timeStake = date1.toLocaleString('en-GB')
                                let paramNoti = {
                                    projectName: "BSCS",
                                    amount: amountReward,
                                    actionType: 2,
                                    userAddress: account,
                                    tokenName: "BSCS",
                                    actionTime: timeStake,
                                    hash: resWait?.transactionHash,
                                    status: "Status hash fail"
                                }
                                await actions.callNoti(paramNoti)
                            }
                            message.error({
                                type: "error",
                                content: "Unknown error (status hash fail)",
                                className: "custom-class",
                                duration: 3,
                            });
                            setloadingWithdraw(false)
                        }
                    }
                    )
                    .catch((err: any) => {
                        console.log('err', err)
                        message.error({
                            type: "error",
                            content: "Unknown error",
                            className: "custom-class",
                            duration: 3,
                        });
                        setloadingWithdraw(false)
                    })

            })
                .catch((err: any) => {
                    console.log('err ', err)
                    message.error({
                        type: "error",
                        content: err.reason || err?.message,
                        className: "custom-class",
                        duration: 3,
                    });
                    setloadingWithdraw(false)
                })
        } catch (error: any) {
            console.log('error ', error)
            message.error({
                type: "error",
                content: error,
                className: "custom-class",
                duration: 3,
            });
            setloadingWithdraw(false)
        }
    }

    return (
        <div className="action-pool">
            <div className="your-stake">
                <div className="title">Your Staked</div>
                <div className="num">{formatNumber(amountBscsPool, 6)}</div>
                <div className="count-down-unstake">
                    <div className="time-down">
                        {timeLock > 0 && amountBscsPool > 0 && stakeTime > 0 && getUser === 0 ? (
                            <>
                                {(stakeTime * 1000 + timeLock * 1000) > Date.now() ? (<div className="title">Unstakeable in:</div>) : ('')}
                                <Countdown
                                    date={stakeTime * 1000 + timeLock * 1000}
                                    renderer={rendererCountDown1}
                                />
                            </>
                        ) : ('')}
                    </div>
                </div>
            </div>
            <div className="your-stake">
                <div className="title">Unstaked</div>
                <div className="num">{formatNumber(getUser, 6)}</div>
                {/* <div className="num">{getUser}</div> */}
                <div className="count-down-unstake">
                    {timeUnstake > 0 && getUser > 0 ? (
                        <>
                            {(timeUnstake * 1000 + timeDown) > Date.now() ? (<div className="title">Withdrawable in:</div>) : ('')}
                            <div className="time-down">
                                <Countdown
                                    date={timeUnstake * 1000 + timeDown}
                                    renderer={rendererCountDown}
                                />
                            </div>
                        </>
                    ) : ''}
                </div>
            </div>
            <div className="your-stake acc">
                <div className="title">Rewards</div>
                {/* <div className="num">{formatNumber(amountReward, 4)}</div> */}
                {/* <div className="num">{amountReward}</div> */}
                <div className="num">
                    {Number(amountReward) > 0.000001 ? (
                        <Value unit="" decimals={6} value={amountReward} />
                    ) : (0)}

                </div>
                <div className="action-button">
                    {amountReward > 0.000001 && getUser <= 0 ? (
                        <>
                            <div className="btn-action">
                                {loadingStake ? (
                                    <>
                                        <button className="btn-stake-reward dis" >
                                            Waiting{" "}
                                            <Spin indicator={antIcon} />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn-stake-reward" onClick={handleStakeReward}>
                                            Stake Rewards
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className="btn-action">
                                {loadingWithdraw ? (
                                    <>
                                        <button className="btn-withdraw-reward dis" >
                                            Waiting{" "}
                                            <Spin indicator={antIcon} />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn-withdraw-reward" onClick={handleWithdrawReward}>
                                            Withdraw Rewards
                                        </button>
                                    </>

                                )}

                            </div>
                        </>
                    ) : (
                        <>
                            <div className="btn-action">
                                <button className="btn-stake-reward dis">Stake Rewards</button>
                            </div>
                            <div className="btn-action">
                                <button className="btn-withdraw-reward dis">Withdraw Rewards</button>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </div>
    )
}

export default Action;