/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useContract } from "../../hooks/useContract";
import abiStakePoolNFT from "../../abi/abiStakePoolNFT.json";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Modal } from "antd";
import { _getTotalLocked, _getTokenAmount } from "../LauchPool/details/utils";
import ErrorPage from "../ErrorPage";
import "./style.css";
import { useHookInoPool } from "./Store-Ino-Pool";
import bgMainheader from "./images/banner-ino.png";
import { useHookPrice } from "../../components/common/Store";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";


const ItemPool = ({ item }: any) => {
    const [state, actions] = useHookInoPool();
    const [TVL, setTVL] = useState(0);

    let navigate = useNavigate();

    const viewDetail = (keyword: any) => {
        navigate(`${"/LaunchPool/StakePoolDetails"}/${keyword}`);
    };
    let totalListSc: any = [];

    const test = item.poolAprs;
    // console.log('test: ', test)

    const addSC1 = test && test[0]?.poolContract;
    const addSC2 = test && test[1]?.poolContract;
    const addSC3 = test && test[2]?.poolContract;
    const addSC4 = test && test[3]?.poolContract;
    const addSC5 = test && test[4]?.poolContract;
    const addSC6 = test && test[5]?.poolContract;
    const addSC7 = test && test[6]?.poolContract;
    const addSC8 = test && test[7]?.poolContract;
    const addSC9 = test && test[8]?.poolContract;
    const addSC10 = test && test[9]?.poolContract;

    const contractStakePoolNFT1 = useContract(addSC1, abiStakePoolNFT);
    const contractStakePoolNFT2 = useContract(addSC2, abiStakePoolNFT);
    const contractStakePoolNFT3 = useContract(addSC3, abiStakePoolNFT);
    const contractStakePoolNFT4 = useContract(addSC4, abiStakePoolNFT);
    const contractStakePoolNFT5 = useContract(addSC5, abiStakePoolNFT);
    const contractStakePoolNFT6 = useContract(addSC6, abiStakePoolNFT);
    const contractStakePoolNFT7 = useContract(addSC7, abiStakePoolNFT);
    const contractStakePoolNFT8 = useContract(addSC8, abiStakePoolNFT);
    const contractStakePoolNFT9 = useContract(addSC9, abiStakePoolNFT);
    const contractStakePoolNFT10 = useContract(addSC10, abiStakePoolNFT);

    if (contractStakePoolNFT1 !== null) {
        totalListSc.push(contractStakePoolNFT1);
    }
    if (contractStakePoolNFT2 !== null) {
        totalListSc.push(contractStakePoolNFT2);
    }
    if (contractStakePoolNFT3 !== null) {
        totalListSc.push(contractStakePoolNFT3);
    }
    if (contractStakePoolNFT4 !== null) {
        totalListSc.push(contractStakePoolNFT4);
    }
    if (contractStakePoolNFT5 !== null) {
        totalListSc.push(contractStakePoolNFT5);
    }
    if (contractStakePoolNFT6 !== null) {
        totalListSc.push(contractStakePoolNFT6);
    }
    if (contractStakePoolNFT7 !== null) {
        totalListSc.push(contractStakePoolNFT7);
    }
    if (contractStakePoolNFT8 !== null) {
        totalListSc.push(contractStakePoolNFT8);
    }
    if (contractStakePoolNFT9 !== null) {
        totalListSc.push(contractStakePoolNFT9);
    }
    if (contractStakePoolNFT10 !== null) {
        totalListSc.push(contractStakePoolNFT10);
    }

    useEffect(() => {
        let totalTVL: any = 0;
        if (totalListSc) {
            totalListSc.forEach((obj: any) => {
                _getTotalLocked(obj).then((res: any) => {
                    if (item.isLPStake) {
                        if (res.toString() > 0) {
                            let tempTVL = res.toString();
                            _getTokenAmount(obj, tempTVL).then((res1: any) => {
                                // console.log('res1: ', res1)
                                totalTVL = totalTVL + res1 / 1e18;
                                // console.log('totalTVL111111: ', totalTVL)
                                setTVL(totalTVL);
                            });
                        }
                    } else {
                        if (res.toString() > 0) {
                            totalTVL = totalTVL + res.toString() / 1e18;
                        }
                        setTVL(totalTVL);
                    }

                    // setTVL(totalTVL)
                });
            });
        }
    }, []);

    // console.log('TVL',TVL)

    useEffect(() => {
        const tvlTemp = TVL * item.depositPrice;
        actions.updateTVL(state.totalTvl + tvlTemp);
    }, [TVL]);

    useEffect(() => {
        return () => {
            actions.updateTVL(0);
        };
    }, []);

    return (
        <>
            <div className="colum">
                <div className="content-item">
                    <div className="header-item">
                        <div className="header-l">
                            <div className="name-token">{item.name}</div>
                            <div className="days-token">{item.lockDay} days</div>
                        </div>
                        <div className="header-r">
                            <div className="box-img">
                                <img className="icon-lp" src={item.logoUrl} alt="" />
                                <div className="img-small">
                                    <img src={item.earnLogoUrl} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="body-item">
                        <div className="surget-item">
                            <div className="item-txt">
                                <div className="txt-l">APR</div>
                                <div className="txt-r">{item.rangeApr}</div>
                            </div>
                            <div className="item-txt">
                                <div className="txt-l">TVL</div>
                                <div className="txt-r">
                                    ${(TVL * item.depositPrice).toLocaleString()}
                                </div>
                            </div>
                            <div className="item-txt">
                                <div className="txt-l">Deposit</div>
                                <div className="txt-r">{item.depositSymbol}</div>
                            </div>
                            <div className="item-txt">
                                <div className="txt-l">Earn</div>
                                <div className="txt-r">{item.earnSymbol}</div>
                            </div>
                        </div>
                        <div className="button-select">
                            <button
                                type="button"
                                className="btn-select"
                                onClick={() => viewDetail(item.keyword)}
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const LaunchPool = () => {
    const [state1, actions1]: any = useHookPrice();
    const [state, actions] = useHookInoPool();
    const { TabPane } = Tabs;
    const [isModalVisible, setIsModalVisible] = useState(false);
    let curentChain = localStorage.getItem("chain");
    let balance: any = state.yourBalanceBSCS;
    const { address } = useAccount();
    let account = address
    function callback(key: any) {
        // console.log(key)
    }
    const listInoPool: any = state.inoListPool;
    // console.log("item" , listInoPool)
    useEffect(() => {
        actions.getInfoInoPool();
    }, [state1.chain]);

    useEffect(() => {
        actions.getBalanceByWallet(account);
    }, [account]);

    useEffect(() => {
        document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
        document
            .getElementsByTagName("meta")
            .item(1)
            ?.setAttribute(
                "content",
                "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
            );
    }, []);

    const openLink = (num: any) => {
        setIsModalVisible(false);
        switch (num) {
            case 1: {
                window
                    .open(
                        "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
                        "_blank"
                    )
                    ?.focus();
                break;
            }
            case 2: {
                window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
                break;
            }
            case 3: {
                window
                    .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
                    ?.focus();
                break;
            }
            case 4: {
                window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
                break;
            }
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const chainId = useChainId()

    return (
        <>
            {chainHeader(chainId) === "bep" ? (
                <>
                    <div
                        className="main-stake-pool"
                        style={{
                            background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
                        }}
                    >
                        <div className="container">
                            <div className="banner-stake-pool">
                                <div className="box-img">
                                    {/* <img src="./images/imagesV3/Banner_Launchpad.png" alt="" /> */}
                                </div>
                                <div className="content-banner-pool">
                                    <div className="content-left">
                                        <h3 className="title-pool">BSCS Launchpool</h3>
                                        <p className="desc-pool">
                                            Stake and Earn.
                                            <br />
                                            Generate passive income with <br />
                                            Diamond hands.
                                        </p>
                                    </div>
                                    <div className="content-right">
                                        <div className="total-value">
                                            <div className="item">
                                                Total Value Locked{" "}
                                                <span className="main-colors">
                                                    ${state.totalTvl.toLocaleString() || 0.0}
                                                </span>
                                            </div>
                                            <div className="item">
                                                <div className="txt-left">
                                                    Your balance
                                                    <span className="text">
                                                        {/* <Value value={balance.result / 1e18} /> BSCS */}
                                                        {balance.result
                                                            ? (balance.result / 1e18).toLocaleString() ===
                                                                "NaN"
                                                                ? "TBA"
                                                                : (balance.result / 1e18)
                                                                    .toFixed(1)
                                                                    .toLocaleString() + " BSCS"
                                                            : 0 + " BSCS"}
                                                    </span>
                                                    {/* <span className="main-colors d-block">0 BSCS</span> */}
                                                </div>
                                                <div className="txt-right">
                                                    <button
                                                        type="button"
                                                        className="btn btn-upcoming"
                                                        onClick={showModal}
                                                    >
                                                        <img src="./images/imagesV3/buy-bscs.png" alt="" />{" "}
                                                        Buy BSCS
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tabs-stake-pool">
                                <Tabs defaultActiveKey="1" onChange={callback}>
                                    <TabPane tab="Live" key="1">
                                        <div className="tabs-item-pool">
                                            <div className="columns">
                                                {listInoPool.length > 0 &&
                                                    listInoPool.map((item: any, i: any) => (
                                                        <>
                                                            {item.isFinish === false ? (
                                                                <ItemPool item={item} />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Finished" key="2">
                                        <div className="tabs-item-pool">
                                            <div className="columns">
                                                {listInoPool.length > 0 &&
                                                    listInoPool.map((item: any, i: any) => (
                                                        <>
                                                            {item.isFinish === true ? (
                                                                <ItemPool item={item} />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <ErrorPage />
                </>
            )}

            {/* @ts-ignore this lib is incompatible with react18 */}
            <Modal
                title="Buy BSCS"
                footer={null}
                visible={isModalVisible}
                onCancel={handleCancel}
                className="modal-link"
                width={650}
            >
                <div className="link-buy-bscs" onClick={() => openLink(1)}>
                    {/* <img className="img-link" alt="" src="/images/coins/BSCS.png?v=152" /> */}
                    <p> 🥞Pancakeswap.finance </p>
                </div>
                <div className="link-buy-bscs" onClick={() => openLink(2)}>
                    <img className="img-link" alt="" src="/images/coins/gate.png?v=152" />
                    <p>
                        SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
                        Trading-Gate.io
                    </p>
                </div>
                <div className="link-buy-bscs" onClick={() => openLink(3)}>
                    <img className="img-link" alt="" src="/images/coins/mxc.png?v=152" />
                    <p>
                        MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
                        Quotes{" "}
                    </p>
                </div>
            </Modal>
        </>
    );
};
export default LaunchPool;
