import React from "react";
import MyAccount from "./MyAccount"
import MyReferrals from "./MyReferrals"
import { Tabs } from 'antd';
import styled from "styled-components";

const ProfileUser = () => {
    return (
        <>
            <AccountBanner>
                <div className="container">
                    <div className="main-profile-user">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="My Account" key="1">
                                <MyAccount />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Referrals" key="2">
                                <MyReferrals />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </AccountBanner>
        </>
    )
}
export default ProfileUser

const AccountBanner = styled.div`
    margin-bottom: 90px;
    background-repeat: no-repeat;
    background-position: top !important;
    background-size: cover !important;
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/892421bfdfd6f28761be3ab53b4921bec30a9641502b0107aa.jpg);
    padding-top: 115px;
    @media (max-width: 768px) {
        margin-bottom: 30px;
        padding-top: 115px;
    }
`;