import web3 from 'web3'
import { MaxUint256 } from '@ethersproject/constants'


const convertNumber = (value) => {
  return web3.utils.toHex(web3.utils.toWei(value, 'ether'))
}

export const sleepSystem = (s) => {
  return new Promise(resolve => setTimeout(resolve, s));
}



export const _getPools = async (poolContract) => {
  try {
    return poolContract.stakePeriods()
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _getExpiredTokens = async (poolContract,account) => {
  try {
    return poolContract.getExpiredTokens(account)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _getUnExpiredTokens = async (poolContract,account) => {
  try {
    return poolContract.getUnexpiredTokens(account)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _totalUserStaking = async (poolContract, account) => {
  try {
    return poolContract.totalUserStaking(account)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}
export const _stake = async (poolContract, amount, period) => {
  try {
    const amountTemp = web3.utils.toWei(amount.toString(), 'ether')
    const args = [amountTemp, period]
    const estimatedGas = await poolContract.estimateGas.stake(...args)
    
    return poolContract.stake(...args, {
      gasLimit: estimatedGas,
    })

  } catch (error) {
    return error
  }
}
export const _unstake = async (poolContract, ids) => {
  try {    
    const estimatedGas = await poolContract.estimateGas.unstake(ids)
    return poolContract.unstake(ids, {
      gasLimit: estimatedGas,
    })
  } catch (error) {
    return error
  }
}

export const _harvest = async (poolContract) => {
  try {
    const estimatedGas = await poolContract.estimateGas.harvest()
    return poolContract.harvest({
      gasLimit: estimatedGas,
    })
  } catch (error) {
    return error
  }
}

export const _userStakeIndex = async (poolContract, account) => {
  try {
    const result = await poolContract.userStakeIndex(account)
    return result
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _stakeDetails = async (poolContract, id) => {
  try {
    return poolContract.stakeDetails(id)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _userStakeIds = async (poolContract, account, index) => {
  try {
   // console.log('iii: ',poolContract.userStakeIds(account, index))
    return poolContract.userStakeIds(account, index)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _approveToken = async (contract, address) => {
  try {
    const result = await contract.approve(address, MaxUint256)
    return result
  } catch (error) {
    return false
  }
}

export const _userPeriodAmount = async (poolContract, account, period) => {
  try {
    return poolContract.userPeriodAmount(account, period)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _earning = async (poolContract, id) => {
  try {
    return poolContract.earning(id)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}
export const _collectUnstakeFee = async (poolContract, id) => {
  try {
    return poolContract.collectUnstakeFee(id)
  } catch (error) {
    console.log('error>>', error)
    return false  
  }
}
export const _stakePeriodAPRs = async (poolContract, period) => {
  try {
   // console.log('res _stakePeriodAPRs : ',poolContract.stakePeriodAPRs(period),poolContract.address, period)
    return poolContract.stakePeriodAPRs(period)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}
export const _getTotalLocked = async (poolContract) => {
  try {
    return poolContract.totalLocked()
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _getTokenAmount = async (poolContract, amount) => {
  try {
    // console.log('poolContract:====>1111111',poolContract,amount)
    // console.log('amount:====>1111111111',amount)
   // console.log('res _getTokenAmount:====>111111111',poolContract.getTokenAmount(amount))
    return poolContract.getTokenAmount(amount)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}