import { isMobile } from "react-device-detect";
import "./style.css";

const OurCommunity = () => {
  return (
    <div className="out-com">
      <div className="container">
        <div className="com-all">
          {isMobile ? (
            <>
              <div className="com-inf des-social">
                <div className="title-des">Our community</div>
                <div className="content-des">
                  We have a global network of supporters interested in th
                  projects of the future, like yours.
                </div>
              </div>
              <div className="com-inf social">
                <div className="item-social">
                  <div className="icon-social tw">
                    <img src="./images/apply/twitter.png" alt="" />
                  </div>
                  <div className="inf-social">
                    <div className="num">410k+</div>
                    <div className="des-num">Twitter Followers</div>
                  </div>
                </div>
                <div className="item-social">
                  <div className="icon-social yt">
                    <img src="./images/apply/youtube.png" alt="" />
                  </div>
                  <div className="inf-social">
                    <div className="num">30k+</div>
                    <div className="des-num">Youtube Subscribers</div>
                  </div>
                </div>
              </div>
              <div className="com-inf social">
                <div className="item-social">
                  <div className="icon-social tele">
                    <img src="./images/apply/telegram.png" alt="" />
                  </div>
                  <div className="inf-social">
                    <div className="num">300k+</div>
                    <div className="des-num">Telegram Members</div>
                  </div>
                </div>
                <div className="item-social">
                  <div className="icon-social fb">
                    <img src="./images/apply/facebook.png" alt="" />
                  </div>
                  <div className="inf-social">
                    <div className="num">20k+</div>
                    <div className="des-num">Facebook Likes</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="social-left">
                <div className="com-inf social">
                  <div className="item-social">
                    <div className="icon-social tw">
                      <img src="./images/apply/twitter.png" alt="" />
                    </div>
                    <div className="inf-social">
                      <div className="num">410k+</div>
                      <div className="des-num">Twitter Followers</div>
                    </div>
                  </div>
                  <div className="item-social">
                    <div className="icon-social yt">
                      <img src="./images/apply/youtube.png" alt="" />
                    </div>
                    <div className="inf-social">
                      <div className="num">30k+</div>
                      <div className="des-num">Youtube Subscribers</div>
                    </div>
                  </div>
                </div>
                <div className="com-inf social">
                  <div className="item-social">
                    <div className="icon-social tele">
                      <img src="./images/apply/telegram.png" alt="" />
                    </div>
                    <div className="inf-social">
                      <div className="num">300k+</div>
                      <div className="des-num">Telegram Members</div>
                    </div>
                  </div>
                  <div className="item-social">
                    <div className="icon-social fb">
                      <img src="./images/apply/facebook.png" alt="" />
                    </div>
                    <div className="inf-social">
                      <div className="num">20k+</div>
                      <div className="des-num">Facebook Likes</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="com-inf des-social" style={{ paddingLeft: '5%' }}>
                <div className="title-des">Our community</div>
                <div className="content-des">
                  We have a global network of supporters interested in th
                  projects of the future, like yours.
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurCommunity;
