/* eslint-disable no-sequences */
import { bscTokens } from "../constantsST/index";
import axios from "axios";

const chunk = (arr, n) =>
  arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [];

const lookUpPrices = async function (obj) {
  let id_array = obj.map((x) => x.id);
  let symbol_array = obj.map((x) => x.symbol);

  let prices = {};
  const pricesTank = {};
  try {
    for (const coinIds of chunk(id_array, 50)) {
      // console.log("id_chunk", coinIds);
      // let ids = coinIds.join("%2C");
      let res = await axios.post(
        "https://api.bscstation.org/api/meta/get_price_by_coin_ids",
        { coinIds }
      );
      prices = res.data.data;
    }
  } catch (error) {}

  return { prices };
};
const lookUpPricesBSCS = async function () {
  const prices = {};
  let res = await axios.get(
    "https://api.coingecko.com/api/v3/simple/price?ids=bsc-station&vs_currencies=usd"
  );
  for (const [key, v] of Object.entries(res.data)) {
    if (v.usd) prices[key] = v;
  }
  return prices;
};
export async function getBscPrices() {
  const idPrices_tem = await lookUpPrices(bscTokens.map((x) => x));
  const prices = {};
  if (idPrices_tem.prices) {
    for (const bt of bscTokens)
      for (let pri of idPrices_tem.prices) {
        if (pri.name === bt.id) {
          prices[bt.contract] = pri.value;
        }
      }
  }
  return prices;
}

export async function getPrices() {
  const idPrices_tem = await lookUpPrices(bscTokens.map((x) => x));
  const prices = {};
  if (idPrices_tem.prices) {
    for (const bt of bscTokens)
      for (let pri of idPrices_tem.prices) {
        if (pri.name === bt.id) {
          prices[bt.contract] = pri.value;
        }
      }
  }
  return prices;
}
export async function getPricesBSCS() {
  const idPrices = await lookUpPricesBSCS();
  const prices = {};
  for (const bt of bscTokens)
    if (idPrices[bt.id]) prices.usd = idPrices[bt.id].usd;
  return prices;
}
