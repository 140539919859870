import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

import { useNavigate } from "react-router-dom";

const MainStack = () => {
  let navigate = useNavigate();

  const gotoLaunchpad = () => {
    navigate(`${"/Ido"}`);
  };
  const gotoNFT = () => {
    window.open("https://app.bscs.finance/coming-soon");
  };
  const gotoFarm = () => {
    navigate(`${"/Farms"}`);
  };
  const gotoStartPools = () => {
    window.open("https://app.bscs.finance/swap");
  };

  function load(img: any) {
    const url = img.getAttribute("lazy-src");
    img.setAttribute("src", url);
  }

  function lazy() {
    if ("IntersectionObserver" in window) {
      let lazyImgs = document.querySelectorAll("[lazy-src]");
      let obj = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            load(entry.target);
          }
        });
      });

      lazyImgs.forEach((img) => {
        obj.observe(img);
      });
    }
  }

  // function ready() {
  //   lazy();
  // }

  // document.addEventListener("DOMContentLoaded", ready);
  useEffect(() => {
    lazy();
  }, []);

  if (isMobile) {
    return (
      <div className="main-setion">
        <div className="container">
          <div className="main-title">
            <p className="desc">WHAT WE OFFER</p>
            <h3 className="title">A Full-Stack DEFI</h3>
          </div>
          <div className="table-stack-round">
            <div className="grid-row">
              <div className="round-one">
                <div className="content-round-stack">
                  <div className="title">Launchpad</div>
                  <div className="desc">
                    Being the innovative decentralized IDO platform across all
                    main blockchain networks.
                  </div>
                  <div className="button-link" onClick={() => gotoLaunchpad()}>
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoLaunchpad()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img lazy-src="/images/l-v3-1.png" src="" alt="" />
                  </div>
                </div>
              </div>
              <div className="round-two">
                <div className="content-round-stack">
                  <div className="title">Tools</div>
                  <div className="desc">
                    Your all-in-one solution for mastering the dynamic DeFi
                    landscape
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoNFT()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img src="" lazy-src="/images/l-v3-2.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="round-three">
                <div className="content-round-stack">
                  <div className="title">Farms</div>
                  <div className="desc">
                    Allowing users to earn BSCS while supporting BSCS.
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoFarm()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img src="" lazy-src="/images/l-v3-3.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="round-four">
                <div className="content-round-stack">
                  <div className="title">Dex Aggregator</div>
                  <div className="desc">
                    Help you find the best rates and manage trades efficiently
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoStartPools()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img src="" lazy-src="/images/l-v3-4.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="main-setion">
        <div className="container">
          <div className="main-title">
            <p className="desc">WHAT WE OFFER</p>
            <h3 className="title">A Full-Stack DEFI</h3>
          </div>
          <div className="table-stack-round">
            <div className="grid-row">
              <div className="round-one">
                <div className="content-round-stack">
                  <div className="title">Launchpad</div>
                  <div className="desc">
                    Being the innovative decentralized IDO platform across all
                    main blockchain networks.
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoLaunchpad()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img lazy-src="/images/l-v3-1.png" src="" alt="" />
                  </div>
                </div>
              </div>
              <div className="round-two">
                <div className="content-round-stack">
                  <div className="title">Tools</div>
                  <div className="desc">
                    Your all-in-one solution for mastering the dynamic DeFi
                    landscape
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoNFT()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img lazy-src="/images/l-v3-2.png" src="" alt="" />
                  </div>
                </div>
              </div>
              <div className="round-three">
                <div className="content-round-stack">
                  <div className="title">Farms</div>
                  <div className="desc">
                    Allowing users to earn BSCS while supporting BSCS.
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoFarm()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img lazy-src="/images/l-v3-3.png" src="" alt="" />
                  </div>
                </div>
              </div>
              <div className="round-four">
                <div className="content-round-stack">
                  <div className="title">Dex Aggregator</div>
                  <div className="desc">
                    Help you find the best rates and manage trades efficiently
                  </div>
                  <div className="button-link">
                    <button
                      type="button"
                      className="btn-getstarted"
                      onClick={() => gotoStartPools()}
                    >
                      Get Started {">>"}
                    </button>
                  </div>
                  <div className="img-round">
                    <img lazy-src="/images/l-v3-4.png" src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainStack;
