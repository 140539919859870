import React, { useEffect, useState } from "react";

import { useWallet } from "use-wallet";
import fetchFarms from "./fetchFarms";

import Context from "../../contextsST/Farms/context";
import useTotalAllocPoints from "../../hooksST/useTotalAllocPoints";
import { useRewardPerWeek } from "../../hooksST/useRewardPerBlock";
import { getPoolApr } from "../../utilsST/index";
import { useAccount } from "wagmi";

const Farms: React.FC = ({ children }: any) => {
  const [farms, setFarms] = useState<any[]>([]);
  const [rewardPrice, setRewardPrice] = useState(0);
  const { ethereum } = useWallet();
  const { address } = useAccount();

  let account = address
  const totalAllocPoints = useTotalAllocPoints();
  const rewardsPerWeek = useRewardPerWeek();

  useEffect(() => {
    const getFarms = async () => {
      const farmsRes: any = await fetchFarms(account, ethereum);
      setFarms(farmsRes.farms);
      setRewardPrice(farmsRes.rewardPrice);
    };
    getFarms();
  }, []);

  useEffect(() => {
    if (
      farms.length > 0 &&
      totalAllocPoints.isGreaterThan(0) &&
      rewardsPerWeek.isGreaterThan(0) &&
      rewardPrice > 0 &&
      !farms?.[0]?.apr
    ) {
      const farmsAprs = farms.map((farm, index) => {
        let staked_tvl = farm?.staked_tvl || 0;
        if (farm.pid === 27) {
          staked_tvl = farm.staked.toNumber();
        }

        const apr = getPoolApr(
          totalAllocPoints,
          farm.allocPoint,
          rewardsPerWeek,
          rewardPrice,
          staked_tvl
        );

        return {
          ...farms[index],
          apr,
        };
      });

      setFarms(farmsAprs);
    }
  }, [farms, totalAllocPoints, rewardsPerWeek, rewardPrice]);

  return (
    <Context.Provider
      value={{
        farms,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Farms;
