import React, { useState, useCallback } from "react";
import { useContext } from "react";
import { ModalContext } from "../../../components/modal";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.min.css";
import {
  CloseModal,
  TitleModal,
  ButtonCommon,
  TextCustom,
} from "../../../components/common/styleCommon";

const AttributeModal = (props: any) => {
  const { onCloseModal }: any = useContext(ModalContext);
  let navigate = useNavigate();

  const { data, queryKey }: any = props;
  const gotoDetail = async (id: any) => {
    navigate(`${"/listing"}/${id}`);
    onCloseModal();
  };
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <>
      {/* {data && ( */}
      <>
        <FormContainer>
          <TitleModal>Congrats</TitleModal>
          <CloseModal onClick={() => onCloseModal()}> X </CloseModal>
          <Slider {...settings}>
            <div className="">
              111111
            </div>
            <div className="">
              222222
            </div>
            <div className="">
              333333
            </div>
          </Slider>
          <NFTItem>
            <TextCustom
              color="rgba(112, 122, 138, 1)"
              fontSize="14px"
              textAlign="center"
            >
              You just got for yourself a unique NFT
            </TextCustom>
            <RowItem>
              <IMGItem>
                {/* <img src={data?.imageUrl} alt="" /> */}
                <img src="../images/mint-nft-audi.gif" alt="" />
              </IMGItem>
            </RowItem>
            <TextCustom fontSize="14px" textAlign="center">
              BSCS #123
            </TextCustom>
            <InforConfirm>
              <BoxAttribute>
                <Attribute>
                  <TitleConfirm>{"data?.unicorn"}</TitleConfirm>
                  <ValueConfirm>
                    {"data?.name"} <Percent>{"data?.rate"}%</Percent>
                  </ValueConfirm>
                  <TitleConfirm>Floor: {"data?.floor"} BSCS</TitleConfirm>
                </Attribute>
              </BoxAttribute>
              <BoxAttribute>
                <Attribute>
                  <TitleConfirm>{"data?.unicorn"}</TitleConfirm>
                  <ValueConfirm>
                    {"data?.name"} <Percent>{"data?.rate"}%</Percent>
                  </ValueConfirm>
                  <TitleConfirm>Floor: {"data?.floor"} BSCS</TitleConfirm>
                </Attribute>
              </BoxAttribute>
              <BoxAttribute>
                <Attribute>
                  <TitleConfirm>{"data?.unicorn"}</TitleConfirm>
                  <ValueConfirm>
                    {"data?.name"} <Percent>{"data?.rate"}%</Percent>
                  </ValueConfirm>
                  <TitleConfirm>Floor: {"data?.floor"} BSCS</TitleConfirm>
                </Attribute>
              </BoxAttribute>
            </InforConfirm>
            <RowButton>
              <ButtonCommon disabled onClick={() => gotoDetail(queryKey)}>
                List for sale
              </ButtonCommon>
            </RowButton>
          </NFTItem>
        </FormContainer>
      </>
      {/* )} */}
    </>
  );
};

export default AttributeModal;

export const FormContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dce2e9;
  border-radius: 6px;
  @media screen and (min-width: 744px) {
    width: 600px;
  }
  @media screen and (max-width: 743px) {
    padding: 15px;
  }
`;
export const NFTItem = styled.div`
  margin: 10px;
`;
export const RowItem = styled.div`
  display: flex;
  justify-content: center;
`;
export const IMGItem = styled.div`
  width: 300px;
  border-radius: 10px;
  padding: 5px;
  img {
    border-radius: 10px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const InforConfirm = styled.div`
  border-radius: 6px;
  padding: 20px;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: 743px) {
    padding: 0px;
  }
`;

export const TitleConfirm = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(112, 122, 138, 1);
`;
export const ValueConfirm = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
`;
export const Percent = styled.span`
  color: rgba(235, 0, 41, 1);
  font-weight: 400;
`;
export const RowButton = styled.div`
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const BoxAttribute = styled.div`
  background: rgba(233, 239, 245, 1);
  margin: 5px;
  width: calc((100% - 30px) / 3);
  border-radius: 6px;
  @media screen and (max-width: 550px) {
    width: calc((100% - 20px) / 2);
  }
`;
export const Attribute = styled.div`
  padding: 15px 5px;
`;
