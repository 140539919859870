import styled from "styled-components";
import bg from "./images/bg-main-banner.png";

export const Wrapper = styled.div`
  background: url(${bg});
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 82px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  padding: 0 10px;
  margin: 0 auto;
  position: relative;
`;

export const Content = styled.div`
  padding: 86px 0 74px 0;
  @media screen and (max-width: 576px) {
    padding-bottom: 41px;
  }
`;

export const MainTitle = styled.h3`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;

  @media screen and (max-width: 576px) {
    font-size: 30px;
  }
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  margin-bottom: 15px;
  color: #1e2329;
  text-align: left;
`;
export const Desc = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #707a8a;
  text-align: justify;
  .bold{
    font-weight: 600;
  }
`;

