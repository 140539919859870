import web3 from "web3";
import BigNumber from "bignumber.js";

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), "ether");
  if (decimal !== 18)
    amount = new BigNumber(value)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  return amount;
};

export const _approveBUSD = async (
  contractBUSD,
  address,
  amount,
  usdDecimals
) => {
  const amountTemp = convertTokenToWei(amount, usdDecimals);
  const result = await contractBUSD.approve(address, amountTemp);
  return result;
};

export const _isRefundedRC = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase());
};

export const _claimTokens = async (claimContract, item, tokenDecimals) => {
  // console.log('claimContract, item, tokenDecimals' , claimContract, item, tokenDecimals);
  const amountTemp = convertTokenToWei(item.claimToken, tokenDecimals);
  const args = [amountTemp, item.signToken];
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
};

export const _refund = async (claimContract, amountBusd, item, usdDecimals) => {
  const amount = convertTokenToWei(amountBusd, usdDecimals);
  const args = [amount, item.signBusd];
  const estimatedGas = await claimContract.estimateGas.refund(...args);
  return claimContract.refund(...args, {
    gasLimit: estimatedGas,
  });
};

export const _showClaimBtn = async (
  claimContract,
  account,
  amount,
  tokenDecimals
) => {
  const amountTemp = convertTokenToWei(amount, tokenDecimals);
  const result = await claimContract.showClaimBtn(account, amountTemp);
  return result;
};

export const _isClaimed = async (poolContract, account) => {
  return poolContract && poolContract.isClaimed(account);
};

export const _joinPoolNew = async (
  joinPoolContract,
  amount,
  projectId,
  signBusd,
  chainId
) => {
  if (chainId === 1) {
    const amountTemp = web3.utils.toWei(amount.toString(), "picoether");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

  if (chainId === 137) {
    const amountTemp = web3.utils.toWei(amount.toString(), "picoether");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

  if (chainId === 43114) {
    const amountTemp = web3.utils.toWei(amount.toString(), "mwei");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

  if (chainId === 2000) {
    const amountTemp = web3.utils.toWei(amount.toString(), "picoether");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

  if (chainId === 10) {
    const amountTemp = web3.utils.toWei(amount.toString(), "picoether");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

  if (chainId === 250) {
    const amountTemp = web3.utils.toWei(amount.toString(), "picoether");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

  if (chainId === 42161) {
    const amountTemp = web3.utils.toWei(amount.toString(), "mwei");
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }
  const amountTemp = web3.utils.toWei(amount.toString(), "ether");
  const args = [amountTemp, projectId, projectId, signBusd];
  const gas = await joinPoolContract.estimateGas.join(...args);
  return joinPoolContract.join(...args);
};

export const _refundChange = async (
  claimContract,
  amountBusd,
  item,
  usdDecimals
) => {
  const amount = convertTokenToWei(amountBusd, usdDecimals);
  const args = [amount, item];
  const estimatedGas = await claimContract.estimateGas.refund(...args);
  return claimContract.refund(...args, {
    gasLimit: estimatedGas,
  });
};

export const _isClaimChange = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase());
};

export const convertWeiToToken = (value, decimal) => {
  if (decimal === 18) return web3.utils.fromWei(value.toString(), "ether");
  else if (decimal === 6)
    return web3.utils.fromWei(value.toString(), "picoether");
  else if (decimal === 9)
    return web3.utils.fromWei(value.toString(), "nanoether");
  else if (decimal === 12)
    return web3.utils.fromWei(value.toString(), "microether");
  else if (decimal === 15)
    return web3.utils.fromWei(value.toString(), "milliether");
  else return value.toString() / 10 ** decimal;
};

export const _totalClaimed = async (poolContract, account, tokenDecimals) => {
  const res = poolContract && (await poolContract.totalClaimed(account));
  return convertWeiToToken(res, tokenDecimals);
};

export const _claimTokensBonus = async (claimContract) => {
  return claimContract.claimTokens();
};

export const totalDailyClaimed = async (claimContract,account) => {
  return claimContract.userInfo(account);
};

export const totalJoinPool = async (poolJoinContract) => {
  const total = poolJoinContract && (await poolJoinContract.totalJoined());

  return total !== null ? total.toString() : 0;
};

export const _isJoined = async (
  poolJoinContract,
  account,
  projectId,
  roundId
) => {
  try {
    return poolJoinContract.isJoined(account, projectId, projectId);
  } catch (error) {
    return false;
  }
};

