import styled from "styled-components";

export const FormContainer = styled.div`
  position: relative;
  //   min-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dce2e9;
  border-radius: 6px;
  @media screen and (min-width: 744px) {
    min-width: 600px;
  }
  @media screen and (max-width: 743px) {
    padding: 15px;
  }
`;
export const NFTItem = styled.div`
  margin: 10px;
`;
export const RowItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  //   padding-bottom: 10px;
`;
export const IMGItem = styled.div`
  width: 150px;
  border-radius: 10px;
  padding: 5px;
  img {
    border-radius: 10px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const InforItem = styled.div`
  padding: 5px;
`;
export const TitleCollection = styled.div`
  padding-bottom: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
`;
export const NFTName = styled.div`
  padding-bottom: 10px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  img {
    width: 30px;
    border-radius: 50px;
    border: 1px solid rgba(239, 243, 248, 1);
    padding: 3px;
  }
`;
export const TotalConfirm = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  img {
    width: 30px;
    border-radius: 50px;
    border: 1px solid rgba(239, 243, 248, 1);
    padding: 3px;
    margin: 5px 0;
  }
`;

export const MiniTitle = styled.span`
  color: rgba(112, 122, 138, 1);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;
export const InforConfirm = styled.div`
  background: rgba(233, 239, 245, 1);
  border-radius: 6px;
  padding: 20px;
  margin: 5px;
`;
export const RowConfirm = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;
export const RowConditions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;
export const TextConditions = styled.div`
  font-size: 14px;
  color: rgba(112, 122, 138, 1);
  margin-bottom: 0px;
  font-family: Poppins;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding: 5px;
  display: flex;
  span {
    color: #000;
  }
  .c-checkbox-bscs-wrapper {
    padding-left: 25px !important;
  }
`;
export const TitleConfirm = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(112, 122, 138, 1);
`;
export const ValueConfirm = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  img {
    width: 30px;
    border-radius: 50px;
    border: 1px solid rgba(239, 243, 248, 1);
    padding: 3px;
  }
`;
export const RowButton = styled.div`
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const RowInput = styled.div`
  .input {
    height: 44px;
    width: 100%;
    border: none;
    border-radius: 6px;
    background: rgba(239, 242, 245, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1) inset;
    color: #000;
    padding: 5px 10px;
  }
`;
