/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import TopIdoDetails from "./TopIdoDetails";
import BottomIdoDetails from "./BottomIdoDetails";
import { useHookIDO } from "../StoreIdo";
// import { MAPPING_CHAINID, CHAINID_CONVERT } from "../../../constants";
// import switchNetworkChain from "../../../utils/walletChain";
import { useHookPrice } from "../../../components/common/Store";
import { useHookAptos } from "../../APT/ConnectAPT/Store-aptos";

import { useAccount } from "wagmi";
import ErrorPage from "pages/ErrorPage";


const IdoDetails = () => {
  const [state, actions]: any = useHookIDO();
  const [state1, actions1]: any = useHookPrice();
  const [state2, actions2]: any = useHookAptos();

  const { address } = useAccount();
  let account = address

  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];

  const params = {
    address:
      localStorage.getItem("accAPT") === "undefined" ||
        localStorage.getItem("accAPT") === undefined ||
        localStorage.getItem("accAPT") === null ||
        localStorage.getItem("chain") !== "apt"
        ? account
        : localStorage.getItem("accAPT"),
    symbol: pathHash,
  };

  useEffect(() => {
    actions.getDetailIDO(params);
    return () => {
      actions.resetDetail();
    };
  }, [localStorage.getItem("accAPT"), state2.accAPT, params.address]);

  useEffect(() => {
    document.title = "Launchpad - BSCS (BSCS)";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "BSCS Launchpad is the innovative decentralized IDO platform across all main blockchain networks."
      );
  }, []);



  return (
    <>
      {state.objDetailIDO.network !== state1.chain &&
        state.objDetailIDO.network !== undefined ? (
        <ErrorPage text={'Aptos Network'} />
      ) : (
        <div className="main-body">
          <div className="full-width">
            <div className="container">
              <div className="main-content-details">
                <div className="breadcrum-swap">
                  <ul className="list-breadcrum">
                    <li>
                      <Link to={"/Ido"}>Project</Link>
                    </li>
                    <li>{">"}</li>
                    <li>IDO Pool</li>
                  </ul>
                  <TopIdoDetails dataTop={state.objDetailIDO} />
                  <BottomIdoDetails dataBody={state.objDetailIDO} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


    </>
  );
};
export default IdoDetails;
