/* eslint-disable import/prefer-default-export */

export enum STATUS {
  COMING = 1,
  OPEN = 2,
  CLOSE = 3,
  JOINED = 4,
}
export enum NETWORK {
  BSC = "bep",
  Poly = "poly",
  Ethereum = "erc",
  Arbitrum = "arb",
  OPBNB = "opbnb",
  BASE = "base",
  AVAX = "avax"
}
export const MAPPING_CHAINID: any = {
  bep: "0x38",
  erc: "0x1",
  poly: "0x89",
  ftom: "0xFA",
  okc: "0x42",
  arb: "0xa4b1",
  opbnb: "0xcc",
  base: "0x2105",
  avax: "0xa86a"
};
export const CHAINID_CONVERT: any = {
  56: "bep",
  1: "erc",
  138: "poly",
  250: "ftom",
  66: 'okc',
  42161: "arb",
  204: "opbnb",
  8453: "base",
  43114: "avax"
};
export const CHAINID_FULLNAME: any = {
  bep: "BSC",
  erc: "Ethereum",
  poly: "Polygon",
  ftom: "Phantom",
  avax: "Avalanche C Chain"
};
export enum TIMELINE_STT {
  PENDING = 0,
  PROCESS = 1,
  COMPLETED = 2,
}
