/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Component } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile } from "react-device-detect";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ReactPlayer from "react-player";
import "video-react/dist/video-react.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHookIDO } from "../StoreIdo";
import { CHAIN } from "../index.d";
import { APPLY } from "../../../constants/index";
import { ICON_BNB_URL, ICON_ETH_URL, ICON_POLY_URL } from "../../../constants";
import switchNetwork from "../../../utils/wallet";
import { MAPPING_CHAINID, CHAINID_CONVERT } from "../index.d";
import { fillNetwork, fillIconChain } from "../utilsIDO";
import { useAccount, useChainId } from "wagmi";


const FullSearchProject = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [state, actions]: any = useHookIDO();
  const [isOtherChain, setOtherChain] = useState(false);
  const { address } = useAccount();

  const chainId = useChainId()
  let account = address
  const arr = state.listResearch?.imageUrls;
  const setting = {
    asNavFor: nav2,
    slidesToShow: 1,
    dots: false,
    focusOnSelect: true,
    arrows: false,
    vertical: false,
  };
  const settings = {
    asNavFor: nav1,
    slidesToShow:
      state.listResearch?.imageUrls?.length >= 4
        ? 4
        : state.listResearch?.imageUrls?.length,
    dots: false,
    focusOnSelect: true,
    arrows: false,
    vertical: false,
  };

  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];
  useEffect(() => {
    actions.getListResearch({ symbol: pathHash });
  }, []);

  let navigate = useNavigate();
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const gotoApply = () => {
    navigate("/apply-launch");
  };
  const handleCallClick = (network: any, symbol: any, isOldIDo: any, idoTrust: any) => {
    if (network === CHAIN.APTOS) {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else if (network === CHAIN.TON) {
      navigate(`${"/Ido/Detail"}/${network}?${symbol}`);
    }
    else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else
      if (idoTrust === 'Vanguard' && symbol !== "MEY") {
        navigate(`${"/Ido/details-vanguard"}?${symbol}`);
      } else {
        navigate(`${"/Ido/Details"}?${symbol}`);
      }
  };

  const itemSliderBig = (
    <>
      <div className="item-slider-big">
        <ReactPlayer url={state.listResearch.youtube} />
      </div>
    </>
  );
  const ItemSliderSmall = (item: any) => {
    return (
      <>
        <div className="item-slider-small">
          <img src={item.item} alt="" />
        </div>
      </>
    );
  };
  const network = state.listResearch?.idoInfo?.network;
  const network_token = state.listResearch?.idoInfo?.tokenNetwork;
  const network_url =
    network === "bep"
      ? ICON_BNB_URL
      : network === "eth"
        ? ICON_ETH_URL
        : ICON_POLY_URL;

  const network_url_token =
    network_token === "bep"
      ? ICON_BNB_URL
      : network === "eth"
        ? ICON_ETH_URL
        : ICON_POLY_URL;

  useEffect(() => {
    if (state.listResearch?.idoInfo?.network) {
      if (isMobile && chainId) {
        if (
          state.listResearch &&
          state.listResearch?.idoInfo?.network !== CHAINID_CONVERT[chainId]
        ) {
          setOtherChain(true);
        }
      } else {
        switchNetwork(MAPPING_CHAINID[state.listResearch?.idoInfo?.network]);
      }
    }
    return () => {
      // TODO
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.listResearch?.idoInfo?.network, account, chainId]);

  const formatNumber = (x: any, max: any) => {
    if (x) {
      return x.toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: max,
      });
    } else return 0;
  };

  const itemContentSearch = (
    <>
      <div className="content-swap-search-ido">
        <div className="box-img">
          <span className="img">
            <img src={state.listResearch?.idoInfo?.logoUrl} alt="" />
          </span>
        </div>
        <div className="name-project-s">
          {state.listResearch?.idoInfo?.name}{" "}
          <span className="token-name">
            {state.listResearch?.idoInfo?.unit}
          </span>
        </div>
        <div className="desc">{state.listResearch?.idoInfo?.description}</div>
        <div className="social-address">
          <div className="list-social">
            <div className="box-icon-sc">
              <a
                className="linkMedia"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.website}
              >
                <img src="/images/imgido/web.png" alt="" />
              </a>

              <a
                className="linkMedia"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.telegram}
              >
                <img src="/images/imgido/tele-1.png" alt="" />
              </a>

              <a
                className="linkMedia"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.twitter}
              >
                <img src="/images/imgido/twi-1.png" alt="" />
              </a>

              <a
                className="linkMedia"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.medium}
              >
                <img src="/images/imgido/me-1.png" alt="" />
              </a>
            </div>
          </div>
          <div className="box-address">
            {state.listResearch?.idoInfo?.network === "apt" ? (
              <>
                {state.listResearch?.idoInfo?.idoContract === "TBA"
                  ? "TBA"
                  : state.listResearch?.idoInfo?.idoContract &&
                  `${state.listResearch?.idoInfo?.idoContract.substring(
                    0,
                    10
                  )}...${state.listResearch?.idoInfo?.idoContract.substring(
                    81,
                    state.listResearch?.idoInfo?.idoContract.length
                  )}`}
              </>
            ) : (
              <>
                {state.listResearch?.idoInfo?.idoContract === "TBA"
                  ? "TBA"
                  : state.listResearch?.idoInfo?.idoContract &&
                  `${state.listResearch?.idoInfo?.idoContract.substring(
                    0,
                    10
                  )}...${state.listResearch?.idoInfo?.idoContract.substring(
                    37,
                    state.listResearch?.idoInfo?.idoContract.length
                  )}`}
              </>
            )}

            {state.listResearch?.idoInfo?.idoContract === "TBA" ? (
              ""
            ) : (
              <CopyToClipboard
                text={state.listResearch?.idoInfo?.idoContract}
                onCopy={() => {
                  message.success({
                    type: "success",
                    content: "Copied",
                    className: "custom-class",
                    duration: 1,
                  });
                }}
              >
                <span className="img-copy">
                  <img className="coppy" src="/images/imgido/copy.png" alt="" />
                </span>
              </CopyToClipboard>
            )}
          </div>
        </div>
        <div className="list-info-search">
          <ul className="list-s">
            <li>
              <span className="txt-l">Join Network:</span>
              <span className="txt-r icon-img">
                {fillIconChain(state.listResearch?.idoInfo?.network)}
                {fillNetwork(state.listResearch?.idoInfo?.network)}

                {/* <img src={network_url} alt="" /> */}
              </span>
            </li>
            <li>
              <span className="txt-l">Token Network:</span>
              <span className="txt-r icon-img">
                {fillIconChain(state.listResearch?.idoInfo?.tokenNetwork)}
                {fillNetwork(state.listResearch?.idoInfo?.tokenNetwork)}
                {/* <img src={network_url_token} alt="" /> */}
              </span>
            </li>
            <li>
              <span className="txt-l">Swap Rate:</span>
              <span className="txt-r">
                {state.listResearch?.idoInfo?.swapAmount}
              </span>
            </li>
            <li>
              <span className="txt-l">IDO Supply:</span>
              <span className="txt-r">
                {formatNumber(state.listResearch?.idoInfo?.idoSupply, 1)}{" "}
                {state.listResearch?.idoInfo?.symbol}
              </span>
            </li>
            <li>
              <span className="txt-l">Total Supply:</span>
              <span className="txt-r">
                {formatNumber(state.listResearch?.idoInfo?.totalSupply, 1)}
                {/* {state.listResearch?.idoInfo?.totalSupply.toLocaleString()} */}{" "}
                {state.listResearch?.idoInfo?.symbol}
              </span>
            </li>
          </ul>
          <ul className="list-s">
            {state.listResearch?.idoInfo &&
              state.listResearch?.idoInfo?.schedules.map(
                (item: any, index: any) => (
                  <>
                    <li>
                      <span className="txt-l">{item.round}</span>
                      <span className="txt-r">{item.startDate}</span>
                    </li>
                  </>
                )
              )}
          </ul>
        </div>
        <div className="button-view">
          <button
            className="btn btn-view"
            type="button"
            onClick={() =>
              handleCallClick(
                state.listResearch?.idoInfo?.network,
                state.listResearch?.idoInfo?.symbol,
                state.listResearch?.idoInfo?.isOldIDo,
                state.listResearch?.idoInfo?.idoTrust
              )
            }
          >
            View Pool
          </button>
        </div>
      </div>
    </>
  );

  const description = (item: any) => {
    return <div dangerouslySetInnerHTML={{ __html: item }} />;
  };

  return (
    <>
      <div className="main-body">
        <div className="full-width">
          <div className="container">
            <div className="main-full-project">
              <div className="breadcrum-swap">
                <ul className="list-breadcrum">
                  <li>
                    <Link to={"/Ido"}>Project</Link>
                  </li>
                  <li>{">"}</li>
                  <li>{state.listResearch?.idoInfo?.name}</li>
                </ul>
              </div>
              <div className="main-full-project">
                <div className="table-swap">
                  <div className="columns">
                    <div className="colum w-8">
                      <div className="content-description">
                        <h3 className="title-name">
                          {state.listResearch?.idoInfo?.name}
                        </h3>
                        {state.listResearch.youtube !== null ||
                          arr?.length > 0 ? (
                          <div className="slider-search-project">
                            {state.listResearch.youtube !== null ? (
                              <div className="slider-big">
                                <Slider
                                  {...setting}
                                  ref={(slider1: any) => setNav1(slider1)}
                                  asNavFor={nav2}
                                >
                                  {itemSliderBig}
                                </Slider>
                              </div>
                            ) : (
                              ""
                            )}
                            {arr?.length > 0 ? (
                              <div className="slider-small">
                                <Slider
                                  {...settings}
                                  ref={(slider2: any) => setNav2(slider2)}
                                  asNavFor={nav1}
                                >
                                  {arr &&
                                    arr.map((item: any, index: any) => (
                                      <ItemSliderSmall item={item} />
                                    ))}
                                </Slider>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="content-desc">
                          <div className="item-desc">
                            <p className="desc-s">
                              {description(
                                state.listResearch.description &&
                                state.listResearch.description
                              )}
                            </p>
                          </div>
                          <div className="box-apply-launch">
                            <p className="desc">Never want to miss a sale?</p>
                            <h3 className="txt">
                              Want to launch your project on BSCS
                            </h3>
                            <div className="btn-apply-launch button-view">
                              <button
                                onClick={gotoApply}
                                className="btn btn-view"
                              >
                                Apply to Launchpad
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="colum w-40">{itemContentSearch}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FullSearchProject;
