import React, { useCallback } from 'react'
import { soushHarvestUserLock } from '../utilsST/callHelpers'
import { getSousChefContract } from '../utilsST/contractHelpers'
import Web3 from "web3";
import { useContract } from "../hooks/useContract";
import sousChefAbi from "../config/abi/sousChef.json";
import poolsConfig from '../constantsST/pools'
import { useAccount } from 'wagmi';


const useSousHarvestUserLock = (sousId) => {
	const { address } = useAccount();
	let account = address
	const web3 = new Web3(window.ethereum)
	const pool = poolsConfig.find((pool) => pool.sousId === sousId);
	const contract = useContract(pool.contractAddress, sousChefAbi);
	const handleRewardUserLock = useCallback(async () => {
		const txHash = await soushHarvestUserLock(contract, account)
		return txHash;
	}, [account, web3])
	return { onRewardUserLock: handleRewardUserLock }
}

export default useSousHarvestUserLock
