/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";

const StoreProjects = createStore({
  initialState: {
    inoListHome: [],
    listBanner: [],
    upcomingListHome: [],
    idoListPriceHome: [],
    marketInfo: {},
    price: "",
  },
  actions: {
    getInoV2Home:
      () =>
      async ({ setState }) => {
        let res = null;
        try {
          res = await axios.post(`${API_URL}/inov2/home_ino`);
          const {
            data: { data },
          } = res;
          setState({ inoListHome: data || [] });
        } catch (err) {
          // TODO
        }
        return res;
      },
    getListBanner:
      () =>
      async ({ setState }) => {
        let res = null;
        try {
          res = await axios.get(`${API_URL}/meta/image_slide`);
          const {
            data: { data },
          } = res;
          setState({ listBanner: data || [] });
        } catch (err) {
          // TODO
        }
        return res;
      },
    getUpcomingV2Home:
      () =>
      async ({ setState }) => {
        let res = null;
        try {
          res = await axios.post(`${API_URL}/launchpadv2/home_upcoming`);

          setState({ upcomingListHome: res?.data?.data || [] });
        } catch (err) {
          // TODO
        }
        return res;
      },
    getIdoHome:
      () =>
      async ({ setState }) => {
        let res = null;
        try {
          res = await axios.post(`${API_URL}/launchpadv2/home_endido`);
          // res = await axios.post(
          //   `https://api-staging.bscstation.org/api/launchpadv2/home_endido`
          // );
          const {
            data: { data },
          } = res;
          setState({ idoListPriceHome: data || [] });
        } catch (err) {
          // TODO
        }
        return res;
      },
    getMarketInfo:
      () =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios(`${API_URL}/Market/api/get_market`).then((res) => {
            const { data } = res.data;
            setState({ marketInfo: { ...data } }); // TODO
            resolve(data);
          });
        });
      },
    savePrice:
      (param) =>
      async ({ setState, getState }) => {
        setState({ price: param });
      },
  },
  name: "Projects Store",
});

export const useHookProjects = createHook(StoreProjects);
export const Container = createContainer(StoreProjects, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreProjects);
