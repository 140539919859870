import { configureStore } from '@reduxjs/toolkit'

import blockReducer from './block'
import poolsReducer from './pools'
import pricesReducer from './prices'
import transactions from '../state/transactions/reducer'


const store = configureStore({
	devTools: process.env.NODE_ENV !== 'production',
	reducer: {
		block: blockReducer,
		prices: pricesReducer,
		pools: poolsReducer,
		transactions,
	},

})

export default store