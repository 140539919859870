import React, { useEffect, useState } from "react";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { isMobile } from "react-device-detect";
import { Select, Input, DatePicker, Empty, Collapse } from 'antd';
import { useHookACC } from "./StoreAcc";
import moment from 'moment';
import { useAccount } from "wagmi";

const ReferralList = () => {
    const { Option } = Select;
    const { Search } = Input;
    const { RangePicker } = DatePicker;
    const [valueKey, setValueKey] = useState("0");
    const [searchQuery, setSearchQuery] = useState("")
    const { Panel } = Collapse;

    const onChange = (
        value: any,
        dateString: [string, string] | string,
    ) => {
        actionsAcc.getListReferal({
            ownerAddress: account,
            searchAddress: "",
            status: Number(valueKey),
            fromTime: moment.utc(value[0]._d).format(),
            toTime: moment.utc(value[1]._d).format(),
            lastTime: null
        });
    };

    const handleChange = (value: any) => {
        setValueKey(value)
        actionsAcc.getListReferal({
            ownerAddress: account,
            searchAddress: searchQuery,
            status: Number(value),
            fromTime: null,
            toTime: null,
            lastTime: null
        });
    };
    const [stateAcc, actionsAcc]: any = useHookACC();
    const { address } = useAccount();
    let account = address

    const handleChangeSearchQuery = (e: any) => {
        setSearchQuery(e.target.value);
        actionsAcc.getListReferal({
            ownerAddress: account,
            searchAddress: e.target.value,
            status: Number(valueKey),
            fromTime: null,
            toTime: null,
            lastTime: null
        });
    };

    const [enableLoadMore, setEnableLoadMore] = useState(true);

    const handleMore = () => {
        if (stateAcc.listReferalMore.length > 0) {
            params.lastTime =
                stateAcc.listReferalMore[stateAcc.listReferalMore.length - 1].lastDate;
        }
        actionsAcc.getListReferaldMore(params).then((data: any) => {
            if (data.length === 0) setEnableLoadMore(false);
        });
    };

    const params = {
        ownerAddress: account,
        searchAddress: searchQuery,
        status: 0,
        fromTime: null,
        toTime: null,
        lastTime: stateAcc?.listReferal.length > 0 ? stateAcc?.listReferal[stateAcc?.listReferal.length - 1].lastDate : null,
    }
    useEffect(() => {
        if (account) {
            actionsAcc.getListReferal(params);
        }
    }, [actionsAcc, account]);

    const HeaderTopIdo = (props: any) => {
        const { item } = props;
        return (
            <div className="content-header-panel">
                <div className="project-name-m">
                    {item.createOn}
                </div>
                <div className="token-name-m">
                    {item?.ownerAddress?.substring(0, 4)}...{item?.ownerAddress?.substring(item?.ownerAddress.length - 4)}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="main-wrap-referral-list">
                <div className="content-guide">
                    <div className="header-tabs">
                        <div className="title">
                            <div className="txt">
                                Referral List
                            </div>
                            <div className="desc">
                                All your referral friends in one place
                            </div>
                        </div>
                        <div className="search-project res">
                            <div className="sort">
                                <Select defaultValue="0" onChange={handleChange} style={{ width: 160 }}>
                                    <Option value="0">All</Option>
                                    <Option value="1">Today</Option>
                                    <Option value="2">This Week</Option>
                                    <Option value="3">This Month</Option>
                                    <Option value="4">Custom Range</Option>
                                </Select>
                                {valueKey === "4" ?
                                    <>
                                        <div className="date-picker">
                                            {/* @ts-ignore this lib is incompatible with react18  */}
                                            <RangePicker onChange={onChange} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {""}
                                    </>
                                }
                            </div>
                            <div className="search">
                                <Search
                                    placeholder="Search Wallet"
                                    onChange={handleChangeSearchQuery}
                                />
                                <div className="icon-search">
                                    <img src="./images/imgido/search-normal.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="body-table">
                        {isMobile ?
                            <>
                                {/* @ts-ignore this lib is incompatible with react18  */}
                                <Collapse defaultActiveKey={['1']}>
                                    {/* @ts-ignore this lib is incompatible with react18  */}
                                    {stateAcc?.listReferal && stateAcc?.listReferal.map((item: any, index: any) => (
                                        /* @ts-ignore this lib is incompatible with react18  */
                                        <Panel header={<HeaderTopIdo item={item} />} key={index.toString()} className="round-ido-m">
                                            <div className="content-ref-m">
                                                <div className="item">
                                                    <div className="txt-l">
                                                        Staking IDO
                                                    </div>
                                                    <div className="txt-r">
                                                        <div className="top">
                                                            {item?.stakeBSCS} BSCS
                                                        </div>
                                                        <div className="bot">
                                                            ${item?.stakeBSCS * 0.0089}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="txt-l">
                                                        Launchpad Commited
                                                    </div>
                                                    <div className="txt-r">
                                                        <div className="top">
                                                            {item?.commitFund} USDT
                                                        </div>
                                                        <div className="bot">
                                                            ${item?.commitFund}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="txt-l">
                                                        Total Reward
                                                    </div>
                                                    <div className="txt-r">
                                                        <span className="main-color">
                                                            ${item?.reward}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </>
                            :
                            <>
                                <table className="main-table">
                                    <tr className="tr-top">
                                        <th>Date</th>
                                        <th>Wallet Address</th>
                                        <th>Staking IDO</th>
                                        <th>Launchpad Commited</th>
                                        <th>Total Reward</th>
                                    </tr>
                                    {stateAcc?.listReferal.length > 0 ?
                                        <>
                                            {stateAcc?.listReferal && stateAcc?.listReferal.map((item: any, i: any) => (
                                                <tr className="tr-bottom">
                                                    <td>{item?.createOn}</td>
                                                    <td>{item?.ownerAddress?.substring(0, 4)}...{item?.ownerAddress?.substring(item?.ownerAddress.length - 4)}</td>
                                                    <td>
                                                        <div className="top">{item?.stakeBSCS} BSCS</div>
                                                        <div className="bot">${item?.stakeBSCS}</div>
                                                    </td>
                                                    <td>
                                                        <div className="top">{item?.commitFund} USDT</div>
                                                        <div className="bot">${item?.commitFund}</div>
                                                    </td>
                                                    <td className="main-color">${item?.reward}</td>
                                                </tr>
                                            ))}
                                        </>
                                        :
                                        <>
                                            <tr className="bottom">
                                                <td colSpan={5}>
                                                    <div className="empty-colspan">
                                                        <Empty description={false} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {stateAcc?.listReferalMore && stateAcc?.listReferalMore.map((item: any, i: any) => (
                                        <tr className="tr-bottom">
                                            <td>{item?.createOn}</td>
                                            <td>{item?.ownerAddress?.substring(0, 4)}...{item?.ownerAddress?.substring(item?.ownerAddress.length - 4)}</td>
                                            <td>
                                                <div className="top">{item?.stakeBSCS} BSCS</div>
                                                <div className="bot">${item?.stakeBSCS}</div>
                                            </td>
                                            <td>
                                                <div className="top">{item?.commitFund} USDT</div>
                                                <div className="bot">${item?.commitFund}</div>
                                            </td>
                                            <td className="main-color">${item?.reward}</td>
                                        </tr>
                                    ))}
                                </table>
                            </>
                        }
                        {enableLoadMore && stateAcc?.listReferal.length !== 0 ? (
                            <>
                                <div className="button-read-more">
                                    <button className="btn-read-more" type="button" onClick={handleMore}>
                                        Read More
                                    </button>
                                </div>
                            </>
                        )
                            :
                            (
                                <>
                                    {""}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default ReferralList