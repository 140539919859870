import React, { useState, useEffect } from 'react';

const TimeAgo = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeDifference = currentTime - timestamp;

    if (timeDifference < 60) {
      setTimeAgo(`${timeDifference} seconds ago`);
    } else if (timeDifference < 3600) {
      const minutesAgo = Math.floor(timeDifference / 60);
      setTimeAgo(`${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`);
    } else if (timeDifference < 86400) {
      const hoursAgo = Math.floor(timeDifference / 3600);
      setTimeAgo(`${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`);
    } else {
      const daysAgo = Math.floor(timeDifference / 86400);
      setTimeAgo(`${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`);
    }
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};


export default TimeAgo;
