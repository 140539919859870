import axios from "axios";
import { Cookies } from "react-cookie";

axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";

// axios.defaults.baseURL = API_SERVICES_BASE_URL.USER;

function ignore(config: any) {
  return config.url.indexOf("https://fullnode") === -1;
}

// set interceptors requests
axios.interceptors.request.use(
  function (config: any) {
    if (config && config.url?.indexOf("bscscan") !== -1) {
      return config;
    }

    const cookies = new Cookies();
    config.headers["BSCS-Token-Authentication"] = cookies.get("user") || null;
    return config;
  },
  function (error) {
    return Promise.reject(error);

  },

  { runWhen: ignore }
  
);

// set interceptors responses
axios.interceptors.response.use(
  function (response) {
    return response;
  }
  // console.log('console.log('console.log('dataLog: ',);dataLog: ',);dataLog: ',);
  // function (error) {
  //   if (error.toString().indexOf('401') !==a -1) {
  //     _removeCookie('token')
  //     window.location.pathname='/login'
  //   }
  //   return Promise.reject(error);
  // }
);
// end
