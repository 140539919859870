import "./style.css";

const Raise = () => {
  const gotoApply = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    );
  };
  return (
    <>
      <div className="raise-apply">
        <div className="raise-left">
          <div className="raise-text">
            <span className="text-custom">How to raise</span>
            <br />
            <span>on BSCS</span>
          </div>
          <div className="btn-all bottom">
            <div className="banner-button" onClick={gotoApply}>
              Apply to Launch
            </div>
          </div>
        </div>
        <div className="raise-right">
          <div className="line-raise"></div>
          <div
            className="step-line"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <div className="step-l">
              <img src="./images/apply/step1-raise.png" alt="" />
            </div>
            <div className="step-r">
              <div className="step-r-first">Apply</div>
              <div className="step-r-sec">
                Submit your project proposal with the full information requested
                on our Form
              </div>
            </div>
          </div>
          <div className="step-line">
            <div className="step-l">
              <img src="./images/apply/step2-raise.png" alt="" />
            </div>
            <div className="step-r">
              <div className="step-r-first">Interview</div>
              <div className="step-r-sec">
                After due diligence, we will have a team interview to discuss
                the project's details and Q&A.
              </div>
            </div>
          </div>
          <div className="step-line">
            <div className="step-l">
              <img src="./images/apply/step3-raise.png" alt="" />
            </div>
            <div className="step-r">
              <div className="step-r-first">Preparation</div>
              <div className="step-r-sec">
                Once approved, projects get the full BSCS team support
                leading up to the sale.
              </div>
            </div>
          </div>
          <div className="step-line">
            <div className="step-l">
              <img src="./images/apply/step4-raise.png" alt="" />
            </div>
            <div className="step-r">
              <div className="step-r-first">Launch</div>
              <div className="step-r-sec">
                Launch the Public Sale on the official BSCS Launchpad
                website.
              </div>
            </div>
          </div>
          <div
            className="step-line"
            style={{
              display: "flex",
              alignItems: "flex-end",
              paddingBottom: "0px",
            }}
          >
            <div className="step-l">
              <img src="./images/apply/step5-raise.png" alt="" />
            </div>
            <div className="step-r">
              <div className="step-r-first">Scale</div>
              <div className="step-r-sec">
                Scale up the project value to its highest potential with the
                help of BSCS.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Raise;
