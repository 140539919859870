/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message, Collapse, Skeleton } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { postData } from "../../../axios/marketNFT";
import {
  API_NFTs_MARKET_URL,
  MINT_NFT_CONTRACT,
  BSCS_CONTRACT,
  UNICORN_NFT,
  BID_NFT,
} from "../../../constants/index";
import Loading from "./../../../components/Loading";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "./../../../utils/notication";
import abiMintNFT from "../../../abi/abiMintNFT.json";
import abiBSCS from "../../../abi/abiBSCS.json";
import abiBidNFT from "../../../abi/abiBidNFT.json";
import abiUniCorn from "../../../abi/abiUnicornNFTMarket.json";
import Value from "../../../components/Value/Value";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import switchNetworkChain from "../../../utils/walletChain";
import { ModalContext } from "../../../components/modal";
import ConfirmSellModal from "./modal/confirmSellModal";
import { getDataByTokenID } from "./../../../hooks/getAllDataMarkets";
import "./style.css";
import { getMarketInfor } from "../../../axios/marketNFT";
import {
  _approveSell,
  _listing,
  _editListing,
  _cancelListing,
  _listingBid,
  _editListingBid,
  _cancelListingBid,
} from "../../../utils/utilsNFT";
import { useContract } from "../../../hooks/useContract";
import { useAccount, useChainId } from "wagmi";

declare const window: Window & typeof globalThis & { ethereum: any };

const DetailNFTNew = () => {
  const currentTime = Math.floor(Date.now() / 1000);

  const { Panel } = Collapse;
  // common
  const { onOpenModal }: any = useContext(ModalContext);
  const web3 = new Web3(window.ethereum);
  const { address } = useAccount();
  const chainId = useChainId()
  let account = address
  const [loadingSwitch, setLoadingSwitch] = useState<any>(false);
  const [priceNFT, setPriceNFT] = useState<any>("0");
  const [detailNFT, setDetailNFT] = useState<any>();
  const [_auctionId, setAuctionId] = useState<any>("");
  const [tokenID, setTokenID] = useState<any>("");
  const pathSymbol = window.location.pathname.split("/");
  const queryKey = pathSymbol[2];
  const [loadingApprove, setLoadingApprove] = useState<any>(false);
  const [objData, setObjData] = useState<any>();
  const [isChoose1, setIsChoose1] = useState(true);
  const [isChoose2, setIsChoose2] = useState(false);

  // fixcel price
  const [loading, setLoading] = useState<any>(false);
  const [loadingCancel, setLoadingCancel] = useState<any>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<any>(false);
  const [priceSell, setPriceSell] = useState<any>("");

  // sell to highest
  const [currentSellect, setCurrentSellect] = useState<any>("");
  const [loadingCancelBid, setLoadingCancelBid] = useState<any>(false);
  const [loadingSetBid, setLoadingSetBid] = useState<any>(false);
  const [loadingUpdatePriceBid, setUpdatePriceBid] = useState<any>(false);
  const [timeBiding, setTimeBiding] = useState<any>("");
  const [priceToken, setPriceToken] = useState<any>("0");

  const price_USD = Number(priceToken) * Number(priceSell);
  let navigate = useNavigate();

  const gotoDetail = async (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };

  const handleChoose1 = (e: any) => {
    setIsChoose1(e.target.checked);
    setIsChoose2(!e.target.checked);
  };
  const handleChoose2 = (e: any) => {
    setIsChoose2(e.target.checked);
    setIsChoose1(!e.target.checked);
  };
  const contractMint: any = useContract(MINT_NFT_CONTRACT, abiMintNFT);
  const uniCorn: any = useContract(UNICORN_NFT, abiUniCorn);
  const contractBid: any = useContract(BID_NFT, abiBidNFT);
  const bidContract = new web3.eth.Contract(
    abiBidNFT as unknown as AbiItem,
    BID_NFT
  );
  useEffect(() => {
    if (queryKey) {
      getDetailNFT();
    }
  }, [queryKey, account]);

  useEffect(() => {
    if (tokenID) {
      getAlldata();
    }
  }, [tokenID]);

  // common
  const getAlldata = async () => {
    await getDataByTokenID(tokenID).then((res) => {
      setObjData(res);
    });
  };
  const getDetailNFT = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    const detail = await postData(
      `${API_NFTs_MARKET_URL}/unicorn/detail`,
      data
    );

    if (detail?.data) {
      setDetailNFT(detail?.data);
      setTokenID(detail?.data?.tokenId);
      setPriceNFT(detail?.data?.price);
      setAuctionId(detail?.data?.auction?.auctionId);
    }
  };
  useEffect(() => {
    getPriceToken();
  }, [priceToken]);

  // common
  const getPriceToken = async () => {
    const market = await getMarketInfor();
    const priceUSD = market?.price || 0;
    setPriceToken(priceUSD);
  };

  const asyncData = async () => {
    await getDetailNFT();
    await getAlldata();
  };

  const switchChain = async () => {
    try {
      if (chainId !== 56) {
        setLoadingSwitch(true);
        await switchNetworkChain("0x38", "bep");
        setLoadingSwitch(false);
      }
    } catch (error) {
      console.error("error Switch", error);
      setLoadingSwitch(false);
    }
  };
  const savePrice = async (price: any, type: number, txHash: any) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      setPrice: Number(price),
      nftType: type,
      txHash: txHash,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/set_price`, data).catch(
      (err) => {
        console.error("save price", err?.message);
      }
    );
  };
  const handleApproveList = async (contract: any) => {
    try {
      setLoadingApprove(true);
      if (account) {
        await _approveSell(contractMint, contract, tokenID)
          .then((res: any) => {
            res.wait().then((resSuccess: any) => {
              asyncData();
              notiSuccess("Approve successfully", 5);
              setLoadingApprove(false);
            });
          })
          .catch((err: any) => {
            console.error("error Approve", err);
            notiError(err?.reason);
            setLoadingApprove(false);
          });
      }
    } catch (error: any) {
      console.error("error Approve", error);
      notiError(error.message);
      setLoadingApprove(false);
    }
  };
  const createHash = async (txHash: any, type: number) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      txHash: txHash,
      type: type,
    };

    await postData(`${API_NFTs_MARKET_URL}/unicorn/create_hash`, data).catch(
      (err) => {
        console.error("save priceBid", err?.message);
      }
    );
  };
  // end comon

  // Fixed price
  const handleListing = async () => {
    try {
      setLoading(true);
      if (account && Number(priceSell) > 0) {
        await _listing(
          uniCorn,
          MINT_NFT_CONTRACT,
          tokenID,
          convertToWei(priceSell, 18),
          BSCS_CONTRACT
        )
          .then((resListing: any) => {
            if (resListing?.hash) {
              createHash(resListing.hash, 4);
            }
            resListing.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(priceSell, 1, resSuccess?.transactionHash);
                notiSuccess("Listed successfully", 5);
                setLoading(false);
                setTimeout(() => {
                  navigate(`${"/detailNFT"}/${queryKey}`);
                }, 1000);
              }
            });
          })
          .catch((err: any) => {
            console.error("error Listing", err);
            notiError(err?.reason, 10);
            setLoading(false);
          });
      } else {
        notiWarning("The selling price must be greater than 0", 10);
        setLoading(false);
      }
    } catch (error: any) {
      notiError(error.message, 10);
      console.error("error Listing", error);
      setLoading(false);
    }
  };
  const cancelListed = async () => {
    try {
      setLoadingCancel(true);
      if (account) {
        await _cancelListing(uniCorn, MINT_NFT_CONTRACT, tokenID)
          .then((resCancel: any) => {
            resCancel.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(0, 0, resSuccess?.transactionHash);
                asyncData();
                notiSuccess("Cancel successfully", 5);
                setLoadingCancel(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resCancel", err);
            notiError(err?.reason, 10);
            setLoadingCancel(false);
          });
      }
    } catch (error: any) {
      console.error("error resCancel", error);
      notiError(error.message, 10);
      setLoadingCancel(false);
    }
  };
  const handleUpdatePrice = async () => {
    try {
      setLoadingUpdate(true);
      if (account) {
        await _editListing(
          uniCorn,
          MINT_NFT_CONTRACT,
          tokenID,
          convertToWei(priceSell, 18)
        )
          .then((resUpdate: any) => {
            resUpdate.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(priceSell, 1, resSuccess?.transactionHash);
                notiSuccess("Update price successfully", 5);
                setLoadingUpdate(false);
                setTimeout(() => {
                  navigate(`${"/detailNFT"}/${queryKey}`);
                }, 1000);
              }
            });
          })
          .catch((err: any) => {
            console.error("error Update", err);
            notiError(err?.reason, 10);
            setLoadingUpdate(false);
          });
      }
    } catch (error: any) {
      console.error("error Update", error);
      notiError(error.message, 10);
      setLoadingUpdate(false);
    }
  };

  // end Fixed price

  // Sell to highest bidder
  const saveSettingPriceBid = async (
    price: any,
    auctionId: any,
    txHash: any,
    type: number
  ) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      expireTime: timeBiding,
      setPrice: Number(price),
      auctionId: auctionId,
      txHash: txHash,
      nftType: type,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/set_auction`, data).catch(
      (err) => {
        console.error("save priceBid", err?.message);
      }
    );
  };

  const handleChangeTime = (event: any) => {
    const selectedValue = event.target.value;
    setCurrentSellect(selectedValue);
    if (selectedValue !== "") {
      let timestamp;
      switch (selectedValue) {
        case "1":
          timestamp = Math.floor(Date.now() / 1000) + 1 * 24 * 60 * 60;
          break;
        case "2":
          timestamp = Math.floor(Date.now() / 1000) + 7 * 24 * 60 * 60;
          break;
        case "3":
          timestamp = Math.floor(Date.now() / 1000) + 14 * 24 * 60 * 60;
          break;
        case "4":
          timestamp = Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60;
          break;
        // case "5":
        //   timestamp = Math.floor(Date.now() / 1000) + 5 * 60;
        //   break;
        default:
          timestamp = 0;
      }
      setTimeBiding(timestamp);
    } else {
      setTimeBiding("");
    }
  };
  const handleListingBid = async () => {
    try {
      setLoadingSetBid(true);
      if (account && Number(priceSell) > 0) {
        const gasPrice = await web3.eth.getGasPrice();
        await bidContract.methods
          .createAuction(
            MINT_NFT_CONTRACT,
            BSCS_CONTRACT,
            tokenID,
            convertToWei(priceSell, 18),
            timeBiding
          )
          .send({
            from: account,
            gasPrice: web3.utils.toHex(String(gasPrice)),
          })
          .on("transactionHash", (tx: any) => {
            if (tx) {
              createHash(tx, 2);
            }
          })
          .then((resBiding: any) => {
            if (resBiding?.status) {
              saveSettingPriceBid(
                priceSell,
                Number(resBiding?.events?.NewAuction?.returnValues[0]),
                resBiding?.transactionHash,
                2
              );
              // asyncData();
              notiSuccess("Listed successfully", 5);
              setLoadingSetBid(false);
              setTimeout(() => {
                navigate(`${"/detailNFT"}/${queryKey}`);
              }, 1000);
            }
          })
          .catch((err: any) => {
            console.error("error ListingBid", err);
            notiError(err.message, 10);
            setLoadingSetBid(false);
          });
      } else {
        notiWarning("The price must be greater than 0", 5);
        setLoadingSetBid(false);
      }
    } catch (error: any) {
      console.error("error ListingBid", error);
      notiError(error.message, 10);
      setLoadingSetBid(false);
    }
  };
  const cancelListedBid = async () => {
    try {
      setLoadingCancelBid(true);
      if (account) {
        await _cancelListingBid(contractBid, _auctionId)
          .then((resCancelBid: any) => {
            resCancelBid.wait().then((resSuccess: any) => {
              if (resSuccess) {
                saveSettingPriceBid(
                  0,
                  _auctionId,
                  resSuccess?.transactionHash,
                  0
                );
                asyncData();
                notiSuccess("Cancel successfully", 5);
                setLoadingCancelBid(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resCancelBid", err);
            notiError(err?.reason, 10);
            setLoadingCancelBid(false);
          });
      }
    } catch (error: any) {
      console.error("error resCancelBid", error);
      notiError(error.message, 10);
      setLoadingCancelBid(false);
    }
  };
  const updatePriceBid = async () => {
    try {
      setUpdatePriceBid(true);
      if (account) {
        await _editListingBid(contractBid, _auctionId, convertToWei(priceSell, 18))
          .then((resUpdateBid: any) => {
            resUpdateBid.wait().then((resSuccess: any) => {
              if (resSuccess) {
                saveSettingPriceBid(
                  priceSell,
                  _auctionId,
                  resSuccess?.transactionHash,
                  2
                );
                // asyncData();
                notiSuccess("Update price successfully", 5);
                setUpdatePriceBid(false);
                setTimeout(() => {
                  navigate(`${"/detailNFT"}/${queryKey}`);
                }, 1000);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resUpdateBid", err);
            notiError(err?.reason, 10);
            setUpdatePriceBid(false);
          });
      }
    } catch (error: any) {
      console.error("error resUpdateBid", error);
      notiError(error.message, 10);
      setUpdatePriceBid(false);
    }
  };
  const onConfirmSell = () =>
    onOpenModal(
      <ConfirmSellModal
        detail={detailNFT}
        priceSell={priceSell}
        priceUSD={price_USD}
        type={"1"}
        onConfirm={handleListing}
      />
    );
  const onConfirmSellBid = () =>
    onOpenModal(
      <ConfirmSellModal
        detail={detailNFT}
        priceSell={priceSell}
        priceUSD={price_USD}
        type={"2"}
        onConfirm={handleListingBid}
      />
    );

  // end Sell to highest bidder

  // console.log("objData", objData);
  // console.log("detailNFT", detailNFT);
  const ItemLeft = () => {
    return (
      <div
        className="content-l"
        onClick={() => gotoDetail(detailNFT?.queryKey)}
      >
        <div className="box-img">
          <img src={detailNFT?.nftUrl} alt="" />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="main-market-launch-details">
        <div className="container">
          <div className="round-details">
            <div className="breadcrum-swap cus">
              <ul className="list-breadcrum">
                <li>
                  <Link to={"/nft-megamarket"}>Marketplace</Link>
                </li>
                <li>{">"}</li>
                <li>Detail NFT's {detailNFT?.name}</li>
              </ul>
            </div>
            <div className="columns">
              <div className="colum w-6">
                <ItemLeft />
              </div>
              <div className="colum w-6">
                <div className="content-r">
                  <div className="box-info-nft">
                    <div className="choose">
                      <div className="choose-text">Choose a type of sale</div>
                      <div className={isChoose1 ? "choose1" : "no-choose"}>
                        <div className="choose1-text">
                          <div className="choose1-text1">Fixed price</div>
                          <div className="choose1-text2">
                            The item is listed at the price you set
                          </div>
                        </div>

                        <div className="radio-item">
                          <input
                            onClick={handleChoose1}
                            type="radio"
                            id="op1"
                            name="saleType"
                            value="1"
                            disabled={
                              loadingApprove ||
                              loadingCancel ||
                              loadingCancelBid ||
                              loading ||
                              loadingUpdate ||
                              loadingSetBid ||
                              loadingUpdatePriceBid
                            }
                            checked={isChoose1}
                          />
                          <label htmlFor="op1"></label>
                        </div>
                      </div>
                      <div className={isChoose2 ? "choose1" : "no-choose"}>
                        <div className="choose1-text">
                          <div className="choose1-text1">
                            Sell to highest bidder
                          </div>
                          <div className="choose1-text2">
                            The item is listed for auction
                          </div>
                        </div>
                        <div className="radio-item">
                          <input
                            onClick={handleChoose2}
                            type="radio"
                            id="op2"
                            name="saleType"
                            value="2"
                            disabled={
                              loadingApprove ||
                              loadingCancel ||
                              loadingCancelBid ||
                              loading ||
                              loadingUpdate ||
                              loadingSetBid ||
                              loadingUpdatePriceBid
                            }
                            checked={isChoose2}
                          />
                          <label htmlFor="op2"></label>
                        </div>
                      </div>
                    </div>
                    <div className="set-price">Set a price</div>
                    <div className="set-price-input">
                      <div className="set-price-label">
                        <div className="label-left">Starting price</div>
                        <div className="label-right">BSCS</div>
                      </div>
                      <input
                        className="input-set-price"
                        type="number"
                        min={0}
                        disabled={
                          loadingApprove ||
                          loadingCancel ||
                          loadingCancelBid ||
                          loading ||
                          loadingUpdate ||
                          loadingSetBid ||
                          loadingUpdatePriceBid
                        }
                        placeholder="0.00"
                        value={priceSell}
                        onChange={(e: any) => setPriceSell(e.target.value)}
                      />
                      <div className="bot-text-set">
                        ~ <Value value={price_USD} unit="$" />
                      </div>
                    </div>
                    {isChoose2 && (
                      <div
                        className="set-price-input duration"
                        hidden={Number(detailNFT?.price) > 0}
                      >
                        <div className="set-price-label">
                          <div className="label-left">Duration</div>
                        </div>
                        <select
                          disabled={
                            loadingApprove ||
                            loadingCancel ||
                            loadingCancelBid ||
                            loading ||
                            loadingUpdate ||
                            loadingSetBid ||
                            loadingUpdatePriceBid
                          }
                          className="form-control"
                          value={currentSellect}
                          onChange={handleChangeTime}
                        >
                          <option value="" disabled>
                            Select Time
                          </option>
                          <option value="1">1 Day</option>
                          <option value="2">7 Days</option>
                          <option value="3">14 Days</option>
                          <option value="4">30 Days</option>
                        </select>
                      </div>
                    )}

                    <div className="group-btn-price">
                      {chainId !== 56 && account && (
                        <button
                          type="button"
                          disabled={loadingSwitch}
                          className="btn-buy-nft-new"
                          onClick={switchChain}
                        >
                          <Loading
                            status={loadingSwitch}
                            content="Switch Chain"
                          />
                        </button>
                      )}

                      {!objData || !detailNFT ? (
                        <Skeleton active paragraph={{ rows: 3 }} />
                      ) : (
                        <>
                          {isChoose1 && (
                            <>
                              {objData?.mint_OwnerOf?.toLowerCase() ===
                                account?.toLowerCase() && (
                                  <>
                                    {objData?.mint_AddressApprove?.toLowerCase() ===
                                      UNICORN_NFT?.toLowerCase() ? (
                                      <>
                                        <button
                                          type="button"
                                          disabled={
                                            loading ||
                                            !priceSell ||
                                            !account ||
                                            chainId !== 56
                                          }
                                          className="btn-buy-nft-new"
                                          onClick={onConfirmSell}
                                        >
                                          <Loading
                                            status={loading}
                                            content="Set price"
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        type="button"
                                        disabled={
                                          loadingApprove ||
                                          !account ||
                                          chainId !== 56
                                        }
                                        className="btn-buy-nft-new"
                                        onClick={() =>
                                          handleApproveList(UNICORN_NFT)
                                        }
                                      >
                                        <Loading
                                          status={loadingApprove}
                                          content="Approve Sell"
                                        />
                                      </button>
                                    )}
                                  </>
                                )}
                              {Number(priceNFT) > 0 &&
                                detailNFT?.ownerAddress?.toLowerCase() ===
                                account?.toLowerCase() && (
                                  <>
                                    <button
                                      type="button"
                                      hidden={detailNFT?.buyType === 2}
                                      disabled={
                                        loadingUpdate ||
                                        !account ||
                                        chainId !== 56 ||
                                        !priceSell ||
                                        loadingCancel
                                      }
                                      className="btn-set-price"
                                      onClick={handleUpdatePrice}
                                    >
                                      <Loading
                                        status={loadingUpdate}
                                        content="Set new price"
                                      />
                                    </button>
                                    <button
                                      type="button"
                                      hidden={detailNFT?.buyType === 2}
                                      disabled={
                                        loadingCancel ||
                                        !account ||
                                        chainId !== 56 ||
                                        loadingUpdate
                                      }
                                      className="btn-cancel-price"
                                      onClick={cancelListed}
                                    >
                                      <Loading
                                        status={loadingCancel}
                                        content="Cancel"
                                      />
                                    </button>
                                  </>
                                )}
                            </>
                          )}
                          {isChoose2 && (
                            <>
                              {detailNFT?.ownerAddress?.toLowerCase() ===
                                account?.toLowerCase() && (
                                  <>
                                    {objData?.mint_AddressApprove?.toLowerCase() ===
                                      BID_NFT?.toLowerCase() ||
                                      Number(detailNFT?.price) > 0 ? (
                                      <>
                                        <button
                                          type="button"
                                          hidden={Number(detailNFT?.price) > 0}
                                          disabled={
                                            loadingSetBid ||
                                            loadingUpdatePriceBid ||
                                            loadingCancelBid ||
                                            !priceSell ||
                                            !timeBiding ||
                                            Number(detailNFT?.price) > 0 ||
                                            !account ||
                                            chainId !== 56
                                          }
                                          className="btn-buy-nft-new"
                                          onClick={onConfirmSellBid}
                                        >
                                          <Loading
                                            status={loadingSetBid}
                                            content="Set price"
                                          />
                                        </button>
                                        {detailNFT?.ownerAddress?.toLowerCase() ===
                                          account?.toLowerCase() &&
                                          Number(detailNFT?.price) > 0 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn-set-price"
                                                hidden={detailNFT?.buyType === 1}
                                                disabled={
                                                  !_auctionId ||
                                                  loadingCancelBid ||
                                                  loadingUpdatePriceBid ||
                                                  Number(detailNFT?.price) <= 0 ||
                                                  !priceSell ||
                                                  !account ||
                                                  chainId !== 56 ||
                                                  currentTime >
                                                  detailNFT?.auction?.expireTime
                                                }
                                                onClick={updatePriceBid}
                                              >
                                                <Loading
                                                  status={loadingUpdatePriceBid}
                                                  content="Set new price"
                                                />
                                              </button>
                                              <button
                                                type="button"
                                                className="btn-cancel-price"
                                                hidden={detailNFT?.buyType === 1}
                                                disabled={
                                                  !_auctionId ||
                                                  loadingCancelBid ||
                                                  loadingUpdatePriceBid ||
                                                  Number(detailNFT?.price) <= 0 ||
                                                  !account ||
                                                  chainId !== 56
                                                }
                                                onClick={cancelListedBid}
                                              >
                                                <Loading
                                                  status={loadingCancelBid}
                                                  content="Cancel"
                                                />
                                              </button>
                                            </>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          type="button"
                                          disabled={
                                            loadingApprove ||
                                            !account ||
                                            chainId !== 56
                                          }
                                          className="btn-buy-nft-new"
                                          onClick={() =>
                                            handleApproveList(BID_NFT)
                                          }
                                        >
                                          <Loading
                                            status={loadingApprove}
                                            content="Approve Sell"
                                          />
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailNFTNew;
