/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useHookNftDetail } from "./Store-DetailNft";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message, Modal, Input, Spin, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Button from "./../../../../components/Button";
import Value from "./../../../../components/Value";
import { STATUS_NFT } from "../../index.d";
import ErrorPage from "../../../ErrorPage";
import {
  _approve,
  _buyToken,
  _bidToken,
  _getBids,
  _getAsks,
} from "../../utils";
import {
  useContract,
  UseNftMarketContractV2,
  useNftMarketContract,
} from "../../../../hooks/useContract";
import abiBSCS from "../../../../abi/abiBSCS.json";

import {
  NFT_CONTRACTID,
  TOKEN_BSCS_TESTNET,
  API_IMAGE,
  TOKEN_BSCS,
  CONTRACT_NFT,
  CONTRACT_BID_VERSION,
  API_VIDEO,
} from "../../../../constants";
import { useHookPrice } from "../../../../components/common/Store";

import BindTrad from "./bid-trad";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";

const MarketPlaceDetails = () => {
  const { address } = useAccount();
  const [state1, actions1]: any = useHookPrice();
  const [state, actions]: any = useHookNftDetail();
  const item = state.objData;


  const objSocial = item.social;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAu, setIsLoadingAu] = useState(false);

  const [isApproveNFT, setApproveNFT] = useState(0);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [bscsBalance, setBscsBalance] = useState(0);
  const [valueBiding, setValueBiding] = useState(0);
  const [auctionOn, setAuctionOn] = useState(false);
  const [buying, setBuying] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const CONTRACT_BID = CONTRACT_BID_VERSION[item.isNew ? 1 : 0];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const bidingNft = async () => {
    if (valueBiding >= bscsBalance) {
      setIsLoadingAu(false);
      message.error({
        type: "error",
        content: "Insufficient balance",
        className: "custom-class",
        duration: 2,
      });
      return;
    }
    setBuying(true);
    await actions.updateTokenId(item.tokenId);
    await _bidToken(bidContract, item.tokenId, valueBiding)
      .then((response: any) => {
        if (response.hash) {
          response.wait().then((res1: any) => {
            if (res1 !== null) {
              actions
                .insertBiding({
                  amount: valueBiding,
                  address,
                  hash: response.hash,
                  code: item.code,
                })
                .then((res1: any) => {
                  setIsLoadingAu(false);
                  setBuying(false);
                  message.success({
                    type: "success",
                    content: "Biding successfully!",
                    className: "custom-class",
                    duration: 2,
                  });
                  window.location.reload();
                })
                .catch((err: any) => {
                  setIsLoadingAu(false);
                  setBuying(false);
                  message.error({
                    type: "error",
                    content: err,
                    className: "custom-class",
                    duration: 2,
                  });
                });
            }
          });
        }
      })
      .catch((err: any) => {
        setIsLoadingAu(false);
        setBuying(false);
        message.error({
          type: "error",
          content: err.message,
          className: "custom-class",
          duration: 1,
        });
      });
  };

  const handleOk = async () => {
    setIsModalOpen(false);

    await bidingNft();
  };

  const onChangeAmount = (value: any) => {
    setValueBiding(value);
  };

  const handleCancel = () => {
    setIsLoadingAu(false);
    setIsModalOpen(false);
  };

  let navigate = useNavigate();

  const bscsContract = useContract(TOKEN_BSCS, abiBSCS);
  const bidContract = UseNftMarketContractV2(item.isNew);

  const location = useLocation();
  const pathSymbol = location.pathname.split("/");
  const id = pathSymbol[2];

  useEffect(() => {
    actions.getMarketInfo();
    actions.getProductsDetail(id).then((res: any) => {
      setIsLoadingImg(false);
    });
  }, [id, state1.chain]);

  useEffect(() => {
    getBscs();
  }, [address, bscsBalance]);

  const getBscs = async () => {
    if (bscsContract) {
      const amount = await bscsContract.balanceOf(address);
      setBscsBalance(amount.toString() / 1e18);
    }
  };

  const checkApprove = async () => {
    if (bscsContract && address) {
      await bscsContract.allowance(address, CONTRACT_BID).then((res: any) => {
        if (res.toString() / 1e18 > 0) {
          setApproveNFT(1);
        } else {
          setApproveNFT(-1);
        }
      });
    }
  };

  useEffect(() => {
    if (bscsContract && address) {
      checkApprove();
    }

  }, [address, item.isNew, isApproveNFT, bscsContract]);

  useEffect(() => {
    if (item.tokenId && bidContract?.address) {
      _getBids(bidContract, item.tokenId).then((res: any) => {
        const listBid = res.map((item: any) => {
          return {
            bidder: item.bidder,
            price: item.price,
          };
        });
        actions.updateListBid(listBid);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.tokenId, address, bidContract?.address]);

  useEffect(() => {
    if (address && bidContract?.address) {
      _getAsks(bidContract, address).then((res: any) => {
        const asks = res.map((item: any) => {
          return {
            tokenId: item.tokenId.toString(),
            price: item.price.toString(),
          };
        });
        actions.updateListAsk(asks);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.bids, item.tokenId, address, bidContract?.address]);

  const gotoProfile = (author: any) => {
    navigate(`${"/usercenter"}/${author}`);
  };

  // Logic------------------------------------------------------------
  // handle approve
  const approveNFT = async () => {
    setIsLoading(true);
    await _approve(bscsContract, CONTRACT_BID)
      .then((res: any) => {
        res.wait().then((res1: any) => {
          if (res1 !== null) {
            setIsLoading(false);
            setApproveNFT(1);
            message.success({
              type: "success",
              content: "Approve successfully!",
              className: "custom-class",
              duration: 2,
            });
          } else {
            setIsLoading(false);
          }
        });
      })
      .catch((error: any) => {
        message.error({
          type: "error",
          content: error?.message,
          className: "custom-class",
          duration: 2,
        });
        setIsLoading(false);
      });
  };

  // handle Buy NFT
  const buyNFT = async () => {
    setIsLoading(true);
    setAuctionOn(true);
    actions
      .checkNftAvailable({ id: item.id, address })
      .then((res: any) => {
        if (res?.data?.succeeded) {
          _buyToken(bidContract, item.tokenId)
            .then((response: any) => {
              if (response.hash) {
                response.wait().then((res1: any) => {
                  if (res1 !== null) {
                    if (address) {
                      actions
                        .insertBuyToDB({
                          artworkId: item.id,
                          ownerAddress: address,
                          totalSold: 1,
                          transactionHash: response.hash,
                        })
                        .then((resInsert: any) => {
                          if (resInsert.data?.succeeded) {
                            message.success({
                              type: "success",
                              content: "Buy NFT successfully!",
                              className: "custom-class",
                              duration: 2,
                            });

                            setIsLoading(false);
                            setAuctionOn(false);
                            navigate(`${"/MyArtwork"}`);
                          }
                        });
                    }
                  }
                });
              }
            })
            .catch((err: any) => {
              message.error({
                type: "error",
                content: err.message,
                className: "custom-class",
                duration: 2,
              });
              setAuctionOn(false);
              setIsLoading(false);
            });
        } else {
          message.warning({
            type: "warning",
            content: res?.data?.message,
            className: "custom-class",
            duration: 2,
          });
          setAuctionOn(false);
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        setAuctionOn(false);
      });
  };

  // handle AuctionNFT
  const auctionNFT = async () => {
    setIsLoadingAu(true);
    setIsModalOpen(true);
  };


  const ItemLeft = () => {
    return (
      <>
        <div className="content-l">
          <div className="box-img">
            {isLoadingImg ? (
              <div className="spin-detail">
                <Spin indicator={antIcon} size="large" />
              </div>
            ) : item.fileType === 2 ? (
              <video
                autoPlay
                loop
                playsInline
                style={{
                  borderRadius: "6px",
                  width: "-webkit-fill-available",
                  height: "453px",
                }}
              >
                <source
                  src={`${item.fileName && item.fileName.indexOf("amazonaws") !== -1
                    ? ""
                    : API_VIDEO
                    }${item.fileName}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={`${item.fileName && item.fileName.indexOf("amazonaws") !== -1
                  ? ""
                  : API_IMAGE
                  }${item.fileName}?v=122`}
                alt=""
              />
            )}
          </div>

          <div className="info-user">
            <div className="info-user-top">
              <div className="img-user">
                <img
                  src={`${API_IMAGE}${item && item.artistAvatar}?v=122`}
                  alt=""
                />
              </div>
              <div className="text-user">
                <div className="name-user">{item.authorName}</div>
                {/* <div className="social-user">
                  <img src="../images/imgmarket/link.svg" alt="" />
                  <img src="../images/imgmarket/tele-1.svg" alt="" />
                  <img src="../images/imgmarket/twi-1.svg" alt="" />
                  <img src="../images/imgmarket/me-1.svg" alt="" />
                </div> */}
                <div className="social-user">
                  {objSocial?.website && (
                    <a
                      href={`${objSocial?.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="../images/imgmarket/link.svg"
                        alt=""
                        className="btn-copied"
                      />
                    </a>
                  )}
                  {objSocial?.telegram && (
                    <a
                      href={`${objSocial?.telegram}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="../images/imgmarket/tele-1.svg"
                        alt=""
                        className="btn-copied"
                      />
                    </a>
                  )}
                  {objSocial?.twitter && (
                    <a
                      href={`${objSocial?.twitter}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="../images/imgmarket/twi-1.svg"
                        alt=""
                        className="btn-copied"
                      />
                    </a>
                  )}
                  {objSocial?.medium && (
                    <a
                      href={`${objSocial?.medium}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="../images/imgmarket/me-1.svg"
                        alt=""
                        className="btn-copied"
                      />
                    </a>
                  )}
                </div>
                <div className="wallet-user">
                  Wallet address :{" "}
                  <div className="address">
                    {item?.creatorAddress &&
                      `${item?.creatorAddress?.substring(
                        0,
                        10
                      )}...${item?.creatorAddress?.substring(
                        37,
                        item?.creatorAddress?.length
                      )}`}

                    <CopyToClipboard
                      text={item?.creatorAddress}
                      onCopy={() => {
                        message.success({
                          type: "success",
                          content: "Copied",
                          className: "custom-class",
                          duration: 0.5,
                        });
                      }}
                    >
                      <span className="img-copy">
                        <img
                          className="coppy"
                          src="/images/imgido/copy.png"
                          alt=""
                        />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-user-bot">
              <div className="desc">{item.artistDescription}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ItemRight = () => {
    return (
      <>
        <div className="content-r">
          <div className="box-info-nft">
            <div className="nft-name" onClick={() => gotoProfile(item.author)}>
              <div className="icon">
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={`${API_IMAGE}${item && item.artistAvatar}?v=122`}
                  alt=""
                />
              </div>
              <div className="name">{item.authorName}</div>
              <div className="icon-smail">
                <img src="../images/imgmarket/img-v2.svg" alt="" />
              </div>
            </div>
            <div className="nft-title">{item.name}</div>
            <div className="nft-artis">
              NFT Category
              <span className="approve-artis">
                {item.status === STATUS_NFT.APPROVE
                  ? "Approve"
                  : item.status === STATUS_NFT.PENDING
                    ? "Pending"
                    : item.status === STATUS_NFT.REJECT
                      ? "Reject"
                      : item.status === STATUS_NFT.SOLD
                        ? "Sold"
                        : "NA"}
              </span>
            </div>
            <div className="nft-description">
              Description
              <span className="desc">{item.descriptions}</span>
            </div>
            <div className="nft-current-price">
              <div className="title">Current Price</div>
              <div className="price-line">
                <div className="icon">
                  <img src="../images/imgmarket/logoBSC.svg" alt="" />
                </div>

                <div className="number">
                  {item.price} <span className="name-token">BSCS</span>
                </div>
              </div>
              <div className="price-now">
                ~{(item.price * state.marketInfo?.price).toFixed(2)}$
              </div>
            </div>

            {/* {address ? (
              <>
                {item.price > 0 && item.ownerAddress !== address ? (
                  item.status !== STATUS_NFT.REJECT ? ( 
                    item.status === STATUS_NFT.PENDING ? (
                      <div className="nft-button">
                        <button
                          type="button"
                          className="btn btn-approve "
                          disabled={true}
                        >
                          Pending
                        </button>
                      </div>
                    ) : isApproveNFT === 1 ? (
                      item.isNew === undefined ? (
                        <Skeleton.Button
                          active={true}
                          size="large"
                          className="nft-skeleton"
                        />
                      ) : bscsBalance >= item.price ? (
                          <div className="buy-au">
                            {state.bids.find((x: any) => x.bidder === address) ? (
                              <div className="nft-button">
                                <button
                                  style={{ width: "150px" }}
                                  type="button"
                                  className="btn btn-approve "
                                  onClick={() => {
                                    message.warning({
                                      type: "warning",
                                      content: " Please cancel auction !",
                                      className: "custom-class",
                                      duration: 2,
                                    });
                                  }}
                                 disabled={isApproveNFT}
                                >
                                  Buy
                                </button>
                              </div>
                            ) : (
                              <div className="nft-button">
                                <button
                                  style={{ width: "150px" }}
                                  type="button"
                                  className="btn btn-approve "
                                  onClick={() => buyNFT()}
                                  disabled={isLoading || buying}
                                >
                                  {isLoading ? (
                                    <>
                                      Buy
                                      <i className="fa fa-spinner fa-spin" />
                                    </>
                                  ) : (
                                    "Buy"
                                  )}
                                </button>
                              </div>
                            )}

                            {state.bids.find((x: any) => x.bidder === address) ? (
                              <div className="nft-button">
                                <button
                                  style={{ width: "150px" }}
                                  type="button"
                                  className="btn btn-approve "
                                  onClick={() => {
                                    message.warning({
                                      type: "warning",
                                      content: "Auction is in existence !",
                                      className: "custom-class",
                                      duration: 2,
                                    });
                                  }}
                                 disabled={isApproveNFT}
                                >
                                  Auction
                                </button>
                              </div>
                            ) : (
                              <div className="nft-button">
                                <button
                                  style={{ width: "150px" }}
                                  type="button"
                                  className="btn btn-approve "
                                  onClick={() => auctionNFT()}
                                  disabled={isLoadingAu || auctionOn}
                                >
                                  {isLoadingAu ? (
                                    <>
                                      Auction
                                      <i className="fa fa-spinner fa-spin" />
                                    </>
                                  ) : (
                                    "Auction"
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        ) : bscsBalance > 0 && bscsBalance < item.price ? (
                          state.bids.find((x: any) => x.bidder === address) ? (
                            <div className="nft-button">
                              <button
                                type="button"
                                className="btn btn-approve "
                                onClick={() => {
                                  message.warning({
                                    type: "warning",
                                    content: "Auction is in existence !",
                                    className: "custom-class",
                                    duration: 2,
                                  });
                                }}
                               disabled={isApproveNFT}
                              >
                                Auction
                              </button>
                            </div>
                          ) : (
                            <div className="nft-button">
                              <button
                                type="button"
                                className="btn btn-approve "
                                onClick={() => auctionNFT()}
                                disabled={isLoadingAu || auctionOn}
                              >
                                {isLoadingAu ? (
                                  <>
                                    Auction
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  "Auction"
                                )}
                              </button>
                            </div>
                          )
                        ) : (
                          <div className="nft-button">
                            <button
                              type="button"
                              className="btn btn-approve "
                              disabled={true}
                            >
                              Insufficient balance
                            </button>
                          </div>
                        )
                    ) : isApproveNFT === -1 ? (
                      <div className="nft-button">
                        <button
                          type="button"
                          className="btn btn-approve "
                          onClick={() => approveNFT()}
                         disabled={isApproveNFT}
                        >
                          {isLoading ? (
                            <>
                              Approve Contract
                              <i className="fa fa-spinner fa-spin" />
                            </>
                          ) : (
                            "Approve Contract"
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="nft-button">
                        <Skeleton.Button
                          active={true}
                          size="large"
                          className="nft-skeleton"
                        />

                       
                      </div>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </>
            ) : (
              <Skeleton.Button
                active={true}
                size="large"
                className="nft-skeleton"
              />
            )} */}
          </div>
          <div className="box-nft-swap">
            <div className="item-swap">
              <div className="title">Item Info</div>
              <ul className="list-swap">
                <li>
                  <div className="txt-l">NFT Contract ID :</div>
                  <div className="txt-r">
                    {NFT_CONTRACTID?.substring(0, 5)}...
                    {NFT_CONTRACTID?.substring(37, NFT_CONTRACTID?.length)}
                    <a
                      className="a-bsc"
                      rel="noreferrer"
                      target="_blank"
                      href={`https://bscscan.com/token/${NFT_CONTRACTID}`}
                    >
                      <img src="../images/imgmarket/for.svg" alt="" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="txt-l">Token ID :</div>
                  <div className="txt-r">
                    {item.tokenId}{" "}
                    <img src="../images/imgmarket/for.svg" alt="" />
                  </div>
                </li>
                <li>
                  <div className="txt-l">Creator's Address :</div>
                  <div className="txt-r">
                    {item.creatorAddress?.substring(0, 5)}...
                    {item.creatorAddress?.substring(
                      37,
                      item.creatorAddress?.length
                    )}
                    <a
                      className="a-bsc"
                      rel="noreferrer"
                      target="_blank"
                      href={`https://bscscan.com/token/${item.creatorAddress}`}
                    >
                      <img src="../images/imgmarket/for.svg" alt="" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="txt-l">Owner's Address :</div>
                  <div className="txt-r">
                    {item?.ownerAddress?.substring(0, 5)}...
                    {item?.ownerAddress?.substring(
                      37,
                      item?.ownerAddress?.length
                    )}
                    <a
                      className="a-bsc"
                      rel="noreferrer"
                      target="_blank"
                      href={`https://bscscan.com/token/${item?.ownerAddress}`}
                    >
                      <img src="../images/imgmarket/for.svg" alt="" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="item-swap">
              <div className="title">Collection Info</div>
              <ul className="list-media">
                <li>N/A</li>
                <li>A.B.1/6-S.S.alt.v4</li>
                <li>MP4</li>
                <li>Alternate Banality 1st of 6</li>
                <li>Shopping Spree / Alternate Version 04</li>
              </ul>
            </div> */}

            <BindTrad
              // data={state.bids}
              tokenId={item.tokenId}
              isNew={item.isNew}
              bidContract={bidContract}
            />
          </div>
        </div>
      </>
    );
  };
  const contentModal = (
    <>
      <div className="main-body-modal">
        <div className="input-amount">
          <Input
            type="number"
            placeholder="0"
            onChange={(e: any) => onChangeAmount(e.target.value)}
          />
          {/* <input onChange={(e: any) => onChangeAmount(e.target.value)} /> */}
          <div className="name-token-staking price">BSCS</div>
        </div>

        <div className="button-select d-flex row-btn-content">
          <Button
            text="Cancel"
            primary
            click={() => {
              handleCancel();
            }}
          />
          <Button
            text="Submit"
            ghost
            disabled={valueBiding <= 0}
            click={() => {
              handleOk();
            }}
          />
        </div>
      </div>
    </>
  );
  const chainId = useChainId()
  return (
    <>
      {chainHeader(chainId) === "bep" ? (
        <>
          <div className="main-market-launch-details">
            <div className="container">
              <div className="round-details">
                <div className="breadcrum-swap cus">
                  <ul className="list-breadcrum">
                    <li>
                      <Link to={"/nft-megamarket"}>Marketplace</Link>
                    </li>
                    <li>{">"}</li>
                    <li>Detail NFT's name</li>
                  </ul>
                </div>
                <div className="columns">
                  <div className="colum w-6">
                    <ItemLeft />
                  </div>
                  <div className="colum w-6">
                    <ItemRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorPage />
        </>
      )}
      <div className="modal-staking">
        {/* @ts-ignore this lib is incompatible with react18  */}
        <Modal
          title="Auction Modal"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="modal-seting-price"
        >
          <div className="modal-content-staking">{contentModal}</div>
        </Modal>
      </div>
    </>
  );
};

export default MarketPlaceDetails;
