import React, { useState } from "react";
import { Modal } from 'antd';
import { isMobile } from "react-device-detect";

import './style.css'

const VerifyInfo = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenS, setIsModalOpenS] = useState(false);
    const [value, setvalue] = useState('');
    const [type, setType] = useState('');

    const data = ['@justinld', '@Soldier09', '@AnneNg9', '@galaxyy_hp', '@LeeHoyuka', '@Billybutcher9112', '@steveding', '@danny_vinyard', '@LeeHoyukaBSCS', '@Galaxyy1903',
      'business@bscs.finance', 'contact@bscs.finance', 'https://t.me/BSCS_Channel', 'https://bscs.substack.com/', 'https://x.com/bscs_global'];

    const dataTele = ['@justinld', '@Soldier09', '@AnneNg9', '@galaxyy_hp', '@LeeHoyuka', '@Billybutcher9112', '@steveding', '@danny_vinyard', 'https://t.me/BSCS_Channel'];

    const dataTW = ['https://x.com/bscs_global', '@LeeHoyukaBSCS', '@Galaxyy1903'];
    const dataFB = ['@BSCStation'];
    const dataYT = ['@BSCStationOfficial'];
    const dataSub = ['https://bscs.substack.com/'];
    const dataEmail = ['business@bscs.finance'];

    const handleVerify = (val: any) => {
        // console.log('check', data.includes(val))
        let tele = dataTele.includes(val)
        let tw = dataTW.includes(val)
        let fb = dataFB.includes(val)
        let yt = dataYT.includes(val)
        let sub = dataSub.includes(val)
        let email = dataEmail.includes(val)

        if (tele) {
            setType('Telegram')
        }
        if (tw) {
            setType('Twitter')
        }
        if (fb) {
            setType('Facebook')
        }
        if (yt) {
            setType('Youtube')
        }
        if (sub) {
            setType('Substack')
        }
        if (email) {
            setType('Email')
        }

        if (data.includes(val)) {
            showModalS()
        } else {
            showModal()
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalS = () => {
        setIsModalOpenS(true);
    };

    const handleCancelS = () => {
        setIsModalOpenS(false);
    };

    const handleChangeValue = (e: any) => {
        setvalue(e.target.value.trim())
    }

    const titleModal = (val: any, type: any) =>
    (
        <div className="modal-stt">
            <img src="images/err-verify.png" alt="" />
            <div className="title-stt">Univerified Source</div>
            <div className="des-stt">Your search [{val}] is not a verified BSCS official source</div>
        </div>
    )

    const contentModal =
        (
            <div className="modal-content-verify">
                <div className="content1">
                    Please verify that the information and the format you entered are correct:
                </div>
                <ul>
                    <li>Mobile number: xx-xxxxxxxxx </li>
                    <li>Telegram/Twitter/Facebook/Youtube: @xxxxx (with @ symbol)</li>
                    <li>LinkedIn/Telegram group/Medium/Reddit: https://xxxxx (website link)</li>
                </ul>
                <div className="content2">
                    If you have questions about the format, please feel free to contact Customer Service.
                </div>
                <div className="content3">
                    *Do not disclose your account information and password to anyone.
                </div>
                <div className="content4">
                    *Beware of scams and operate with caution.
                </div>
            </div>
        )

    const titleModalS = (val: any, type: any) => (
        <div className="modal-stt">
            <img src="images/success-verify.png" alt="" />
            <div className="title-stt">Verified Official Source</div>
            <div className="des-stt">Your search [{val}] is a BSCS official {type}</div>
        </div>
    )

    const contentModalS = (
        <div className="modal-content-verify-s">
            <div className="content3">
                *Do not disclose your account information and password to anyone.
            </div>
            <div className="content4">
                *Beware of scams and operate with caution.
            </div>
        </div>
    )

    return (
        <>
            <div className="verify-bscs">
                <div className="content-verify">
                    <div className="logo-verify">
                        <img src="images/logo-brand-v3.svg" alt="" />
                    </div>
                    <div className="title-verify">BSCS Verify</div>
                    <div className="des-verify">On this page, you can verify whether a URL, email address, phone number, or social media account like Twitter and Telegram, is a verified BSCS official source. This will help you avoid losing your assets to phishing and online scams.</div>
                    <div className="input-verify">
                        {isMobile ? (
                            <input
                                value={value}
                                onInput={handleChangeValue}
                                type="text"
                                placeholder='Please enter the information and...' />
                        ) : (
                            <input
                                value={value}
                                onInput={handleChangeValue}
                                type="text"
                                placeholder='Please enter the information and format correctly for verification' />
                        )}

                        <div className="icon-verify" onClick={() => handleVerify(value)}>
                            <img src="images/search-verify.png" alt="" />
                        </div>

                    </div>
                    <div className="status-verify">
                        <img src="images/stt-verify.png" alt="" />
                    </div>
                </div>
            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal title={titleModal(value)}
                visible={isModalOpen}
                className="popup-verify"
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <button className="ok-verify" key="back" onClick={handleCancel}>
                        OK
                    </button>
                ]}
            >
                {contentModal}
            </Modal>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal title={titleModalS(value, type)}
                visible={isModalOpenS}
                className="popup-verify"
                // onOk={handleOk}
                onCancel={handleCancelS}
                footer={[
                    <button className="ok-verify" key="back" onClick={handleCancelS}>
                        OK
                    </button>
                ]}
            >
                {contentModalS}
            </Modal>

        </>
    )
}

export default VerifyInfo;