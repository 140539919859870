/* eslint-disable react-hooks/exhaustive-deps, react/button-has-type */
import React, { forwardRef, memo, useCallback } from "react";
import debounce from "lodash.debounce";
import { PropsButtonBSC, RefButtonBSC } from "./index.d";
import "./index.less";

const ButtonBSC = memo<PropsButtonBSC>(
  forwardRef<RefButtonBSC, PropsButtonBSC>((props, ref) => {
    const {
      ghost,
      gray,
      loading,
      id,
      className,
      primary,
      left,
      right,
      disabled,
      type,
      link,
      dark,
      manager,
    } = props;
    const hasSpecTheme = useCallback((): string => {
      const classes: string[] = [];
      if (primary) {
        classes.push("btn-bsc-primary");
      }
      if (gray) {
        classes.push("btn-bsc-gray");
      }
      if (ghost) {
        classes.push("btn-unstake");
      }
      if (loading) {
        classes.push("btn-bsc-loading");
      }
      if (disabled) {
        classes.push("btn-bsc-disabled");
      }
      if (link) {
        classes.push("btn-bsc-link");
      }
      if (manager) {
        classes.push("btn-manager");
      }

      if (dark) {
        classes.push("btn-dark");
      }

      return classes.join(" ");
    }, [ghost, gray, loading, primary, disabled, type, link]);
    // }, [ghost, gray, loading, primary, disabled, hide])
    const handleClick = useCallback(
      (e: any) => {
        return props.click && props.click(e);
      },
      [props.click]
    );

    return (
      <div {...(id ? { id } : {})}>
        <button
          className={`
				${hasSpecTheme()} 
				${className || ""}
			  `}
          onClick={debounce(handleClick, 200, {
            leading: true,
            trailing: false,
          })}
          type={type || "button"}
          disabled={props.disabled}
        >
          <div className="justify-content-row">
            {left && left}
            {props.text && <span>{props.text}</span>}
            {props.loading && (
              <img
                src="/images/spinner.svg"
                className="btn-bsc-icon-loading"
                alt=""
              />
            )}
            {right && right}
          </div>
        </button>
      </div>
    );
  })
);

export * from "./index.d";

export default ButtonBSC;
