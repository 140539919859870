import React, { useMemo, useState, useCallback } from "react";
import Input from "../InputManager/index";
import Modal from "../../../componentsST/Modal/index";
import Button from "../../../componentsST/ButtonV2/index";
import "../../../componentsST/StakeInPoolModal/index.less";
import { useContract } from "../../../hooks/useContract";
import sousChefAbi from "../../../config/abi/sousChef.json";
import poolsConfig from "../../../constantsST/pools";
import { withdrawTokenStake } from "../utils/callHelpers";
import { message } from "antd";

const WithdrawStakeModal = (props) => {
  const [val, setVal] = useState("");
  const [pendingTx, setPendingTx] = useState(false);
  const { sousId, decimals, onDismiss } = props;

  const handleChange = useCallback(
    (e) => {
      setVal(e.currentTarget.value);
    },
    [setVal]
  );

  const pool = poolsConfig.find((pool) => pool.sousId === sousId);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const handleUpdate = async () => {
    try {
      setPendingTx(true);
      await withdrawTokenStake(contract, val, decimals).then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
          if (res2 !== null) {
            setPendingTx(false);
            onDismiss();
            message.success({
              type: "success",
              content: "Update success!",
              className: "custom-class",
              duration: 3,
            });
          }
        });
      });
    } catch (e) {
      message.error({
        type: "error",
        content: "Update failed",
        className: "custom-class",
        duration: 3,
      });
      setPendingTx(false);
    }
  };
  return (
    <Modal>
      <div className="bsc-stake_in_pool-modal">
        <div className="bsc-stake_in_pool-modal-header">
          <span>Withdraw token stake</span>
          <span>
            {pool.stakingToken.symbol} - {pool.earningToken.symbol}
          </span>
        </div>
        <div className="bsc-stake_in_pool-modal-content">
          <div className="bsc-stake_in_pool-modal-content-top">
            <span>
              <a
                href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "#1bc5bd" }}
              >
                Contract: {pool.contractAddress.substr(0, 5)}...
                {pool.contractAddress.substr(pool.contractAddress.length - 10)}
              </a>
            </span>
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid">
            <Input
              change={handleChange}
              type="number"
              value={val}
              right={<span>Amount</span>}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-bottom mb-4">
            <Button text="Cancel" ghost click={onDismiss} />
            <Button
              text="Confirm"
              primary
              loading={pendingTx}
              disabled={pendingTx || val <= 0}
              click={handleUpdate}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawStakeModal;
