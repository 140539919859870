/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from "react";
import { useHookNftDetail } from "./Store-DetailNft";
import { message } from "antd";
import { _cancelBidToken, _getAsks, _sellTokenTo, _getBids } from "../../utils";
// import { useNftMarketContract, UseNftMarketContractV2 } from "../../../../hooks/useContract";
import { useAccount } from "wagmi";

const ItemRow = ({ item, i, bidContract, tokenId, account }: any) => {
  const [state, actions]: any = useHookNftDetail();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSell, setIsLoadingSell] = useState(false);
  const cancel = async () => {
    setIsLoading(true);
    _cancelBidToken(bidContract, state.objData.tokenId)
      .then((response: any) => {
        if (response.hash) {
          response
            .wait()
            .then(async (res1: any) => {
              if (res1 !== null) {
                actions.cancelBiding({ account, code: state.objData.code });
                message.success({
                  type: "success",
                  content: "Cancel NFT successfully!",
                  className: "custom-class",
                  duration: 2,
                });
                setIsLoading(false);
                _getBids(bidContract, tokenId).then((res: any) => {
                  const listBid = res.map((item: any) => {
                    return {
                      bidder: item.bidder,
                      price: item.price,
                    };
                  });
                  actions.updateListBid(listBid);
                });
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              message.error({
                type: "error",
                content: err.message,
                className: "custom-class",
                duration: 2,
              });
            });
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        message.error({
          type: "error",
          content: err.message,
          className: "custom-class",
          duration: 2,
        });
      });
  };

  const sellTo = (to: any) => {
    setIsLoadingSell(true);
    _sellTokenTo(bidContract, state.objData.tokenId, to)
      .then((response: any) => {
        response
          .wait()
          .then(async (res1: any) => {
            if (res1 !== null) {
              actions.updatelBiding({
                account: to,
                code: state.objData.code,
              });
              await _getBids(bidContract, tokenId).then((res: any) => {
                const listBid = res.map((item: any) => {
                  return {
                    bidder: item.bidder,
                    price: item.price,
                  };
                });
                actions.updateListBid(listBid);
              });
              message.success({
                type: "success",
                content: "Sell NFT successfully!",
                className: "custom-class",
                duration: 2,
              });
            }
          })
          .catch((err: any) => {
            setIsLoadingSell(false);
            message.error({
              type: "error",
              content: err.message,
              className: "custom-class",
              duration: 2,
            });
          });
      })
      .catch((err: any) => {
        setIsLoadingSell(false);
        message.error({
          type: "error",
          content: err.message,
          className: "custom-class",
          duration: 2,
        });
      });
  };

  return (
    <tr className="tr-bottom">
      <td>{i + 1}</td>
      <td>
        {item.bidder && item.bidder.substring(0, 8)}.....
        {item.bidder && item.bidder.substring(35, item.bidder.length)}
      </td>
      <td>{item.price.toString() / 1e18} BSCS</td>
      <td>
        {state.asks.find((x: any) => parseInt(x.tokenId) === tokenId) ? (
          <button
            onClick={() => sellTo(item.bidder)}
            className="btn-bidding"
            type="button"
            disabled={isLoadingSell}
          >
            {isLoadingSell ? (
              <>
                Sell
                <i className="fa fa-spinner fa-spin" />
              </>
            ) : (
              "Sell"
            )}
          </button>
        ) : (
          ""
        )}

        {item.bidder && item.bidder === account ? (
          <button
            disabled={isLoading}
            onClick={() => cancel()}
            className="btn-bidding"
            type="button"
          >
            {isLoading ? (
              <>
                Cancel
                <i className="fa fa-spinner fa-spin" />
              </>
            ) : (
              "Cancel"
            )}
          </button>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

const BindTrad = (props: any) => {
  const [state, actions]: any = useHookNftDetail();
  const { address } = useAccount();
  let account = address
  const tokenId = props.tokenId;
  let bidContract = props.bidContract
  // const bidContract = UseNftMarketContractV2(props.isNew);
  const [listAsk, setListAsk] = useState([]);

  useEffect(() => {
    if (account && bidContract?.address) {
      _getAsks(bidContract, account).then((res: any) => {
        if (res.length > 0) {
          const asks = res.map((item: any) => {
            return {
              tokenId: item.tokenId.toString(),
              price: item.price.toString(),
            };
          });
          setListAsk(asks);
          // actions.updateListAsk(asks);
        }
      });
    }
  }, [bidContract?.address]);


  return (
    <>
      <div className="item-swap">
        <div className="title">Bidding</div>
        <div className="table-nft">
          <table className="main-table">
            <tr className="tr-top">
              <th>No.</th>
              <th>Address</th>
              <th>Price</th>
              <th>
                {listAsk.find(
                  (x: any) => x.tokenId === tokenId && tokenId.toString()
                )
                  ? "Sell"
                  : "Action"}
              </th>
            </tr>
            {state.bids.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div className="box-message">
                    <div className="info-message">
                      No one is currently bidding here
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              state.bids.map((item: any, i: any) => (
                <ItemRow
                  item={item}
                  i={i}
                  tokenId={tokenId}
                  account={account}
                  bidContract={bidContract}
                />
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};



export default BindTrad;
