/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import {
  Tabs,
  Input,
  Select,
  message,
  Empty,
  Radio,
  Popover,
  Spin,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useHookProfile } from "./Store-Profile";
import "./style.css";
import { API_IMAGE, API_VIDEO } from "../../../constants";
import { parseMarker } from "@fullcalendar/react";

const UserCenter = () => {
  const [state, actions]: any = useHookProfile();
  const { Search } = Input;
  const { Option } = Select;
  let navigate = useNavigate();

  const location = useLocation();
  const pathSymbol = location.pathname.split("/");
  const id = pathSymbol[2];
  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let params = {
    author: "",
    fileType: 0,
    keywork: "",
    pageNumber: 1,
    pageSize: 12,
    sortByPrice: "asc",
    sortType: 0,
    typeArtworks: "",
    category: 0,
  };

  const [valueCollection, setValueCollection] = useState("");
  const [valueSearch, setValueSearch] = useState("");
  const [valueFileType, setValueFileType] = useState(0);
  const [loadingOnSearch, setLoadingOnSearch] = useState(false);
  const [valueSort, setValueSort] = useState(1);
  const [tab, setTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(2);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const onChangeRadioCollection = (e: any) => {
    setLoadingOnSearch(true);
    setValueCollection(e.target.value);
    params = {
      ...params,
      pageNumber: 1,
      keywork: valueSearch,
      fileType: valueFileType,
      sortType: valueSort,
      typeArtworks: e.target.value, // filter art collection
      category: tab,
    };
    actions.getProductsUser(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onChangeRadioType = (e: any) => {
    setLoadingOnSearch(true);
    setValueFileType(e.target.value);
    params = {
      ...params,
      pageNumber: 1,
      keywork: valueSearch,
      sortType: valueSort,
      typeArtworks: valueCollection,
      fileType: e.target.value,
      category: tab,
    };
    actions.getProductsUser(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onSearch = (value: string) => {
    setLoadingOnSearch(true);
    setValueSearch(value);
    params = {
      ...params,
      sortType: valueSort,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: value,
      category: tab,
    };
    actions.getProductsUser(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onChangeSearch = (value: any) => {
    setValueSearch(value);
    params = {
      ...params,
      sortType: valueSort,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: value.target.value,
      category: tab,
    };
    if (value.target.value === "") {
      setLoadingOnSearch(true);
      actions.getProductsUser(params).then((res: any) => {
        if (res) {
          setLoadingOnSearch(false);
        }
      });
      // actions.getProducts();
      // setValueSearch("");
    }
  };

  const handleChangeSort = (value: any) => {
    setLoadingOnSearch(true);
    setValueSort(value);
    params = {
      ...params,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: valueSearch,
      sortType: value,
      category: tab,
    };
    actions.getProductsUser(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onChange = (key: string) => {
    setLoadingOnSearch(true);
    setTab(parseInt(key));
    params = {
      ...params,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: valueSearch,
      sortType: valueSort,
      category: parseInt(key),
    };
    actions.getProductsUser(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const handleLoadMore = () => {
    setIsLoadMore(true);
    params = {
      ...params,
      category: tab, // switch tab
      pageNumber, // next page
      pageSize: 12,
      sortByPrice: "asc",
      keywork: valueSearch, // search
      fileType: valueFileType, // filter art type
      sortType: valueSort, // sort
      typeArtworks: valueCollection, // filter art collection
    };
    actions.getProductsUserMore(params).then((res: any) => {
      if (res) {
        setIsLoadMore(false);
      }
    });
    setPageNumber(pageNumber + 1);
  };

  const gotoDetail = (id: any) => {
    navigate(`${"/nft-megamarket-detail"}/${id}`);
  };

  const Item = (data: any) => {
    // console.log("data: ", data);
    const item = data.data;
    return (
      <>
        <div className="colum w-4">
          <div className="guide-market" onClick={() => gotoDetail(item.id)}>
            <div className="box-img">
              {item.fileType === 2 ? (
                <>
                  <div className="imgType">
                    <img src="/images/imgnft/typeVideo.png" alt="" />
                  </div>
                  <img className="imgNft" src={item.thumbnail} alt="image" />
                </>
              ) : (
                <>
                  <div className="imgType">
                    <img src="/images/imgnft/typeImg.png" alt="" />
                  </div>
                  <img className="imgNft" src={item.fileName} alt="image" />
                </>
              )}
            </div>
            <div className="name-item">{item.name}</div>
            <div className="artis-item">{item.authorName}</div>
            <div className="price-item">
              <span className="t-left">Current price:</span>
              <div className="t-right">
                <div className="icon">
                  <img src="/images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">{item.price} BSCS</div>
              </div>
            </div>
            <div className="current-price">
              ~ {(item.price * 0.014).toFixed(2)}$
            </div>
          </div>
        </div>
      </>
    );
  };

  params = {
    ...params,
    author: id,
  };

  useEffect(() => {
    setLoadingOnSearch(true);
    actions.getUserDetail(id);
    actions.getProductsUser(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const item = state.objData;
  const listNft = state.listNFT;

  const content = (
    <div className="content-popover">
      <div className="columns">
        <div className="colum w-6">
          <div className="round-popover">
            <div className="title">Collection</div>
            <div className="list-radio">
              <Radio.Group
                onChange={onChangeRadioCollection}
                value={valueCollection}
              >
                <Radio value="">All</Radio>
                <Radio value="Characters">Characters</Radio>
                <Radio value="Weapon">Weapon</Radio>
                <Radio value="Pet">Pet</Radio>
                <Radio value="My Neighbor Alice">My Neighbor Alice</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="colum w-6">
          <div className="round-popover">
            <div className="title">Artwork Type</div>
            <div className="list-radio">
              <Radio.Group onChange={onChangeRadioType} value={valueFileType}>
                <Radio value={0}>All</Radio>
                <Radio value={1}>Image</Radio>
                <Radio value={2}>Video</Radio>
                <Radio value={3}>Gif</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="main-profile-user">
        <div className="baanner-user">
          <img src="/images/imgmarket/banner-user.png" alt="" />
        </div>
        <div className="container">
          <div className="content-profile-user">
            <div className="columns">
              <div className="colum w-25">
                <div className="swap-left">
                  <div className="box-img">
                    {item.avatarName === null ? (
                      <img src="/images/imgmarket/profile-1.png" alt="" />
                    ) : (
                      <img
                        //   style={{ width: "30px", height: "30px" }}
                        src={`${API_IMAGE}${item && item.avatarName}?v=122`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="name-rl">
                    Name: <span className="rl">{item.artistName}</span>
                  </div>
                  <div className="social-rl">
                    <img src="/images/imgmarket/link.svg" alt="" />
                    <img src="/images/imgmarket/tele-1.svg" alt="" />
                    <img src="/images/imgmarket/twi-1.svg" alt="" />
                    <img src="/images/imgmarket/me-1.svg" alt="" />
                  </div>
                  <div className="address-rl">
                    Wallet address :{" "}
                    <div className="address">
                      {item?.ownerAddress &&
                        `${item?.ownerAddress?.substring(
                          0,
                          4
                        )}...${item?.ownerAddress?.substring(
                          38,
                          item?.ownerAddress?.length
                        )}`}
                      <CopyToClipboard
                        text={item?.ownerAddress}
                        onCopy={() => {
                          message.success({
                            type: "success",
                            content: "Copied",
                            className: "custom-class",
                            duration: 0.5,
                          });
                        }}
                      >
                        <span className="img-copy">
                          <img
                            className="coppy"
                            src="/images/imgido/copy.png"
                            alt=""
                          />
                        </span>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="desc-rl">{item.artistDescription}</div>
                </div>
              </div>
              <div className="colum w-75">
                <div className="swap-right">
                  <div className="tabs-market">
                    <div className="swap-filter">
                      <div className="target-project">
                        <Popover
                          content={content}
                          placement="bottom"
                          title="Filter Artwork"
                          trigger="click"
                        >
                          <button className="btn-filter" type="button">
                            <img src="../images/imgmarket/setting.png" alt="" />{" "}
                            Fillter
                          </button>
                        </Popover>
                      </div>
                      <div className="search-project">
                        <Search
                          placeholder="Search for project"
                          onSearch={onSearch}
                          onChange={onChangeSearch}
                        />
                        <div className="icon-search">
                          <img src="/images/imgido/search-normal.png" alt="" />
                        </div>
                      </div>
                      <div className="filter-project">
                        <Select
                          defaultValue="Sort by"
                          style={{ width: 125 }}
                          onChange={handleChangeSort}
                        >
                          <Option value={1}>Price low - high</Option>
                          <Option value={2}>Price high - low</Option>
                          <Option value={0}>Latest</Option>

                        </Select>
                      </div>
                    </div>
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                      <Tabs.TabPane tab="All" key="1">
                        <div className="round-tabs">
                          <div className="columns">
                            {loadingOnSearch ? (
                              <div className="nft-loading">
                                <Spin indicator={antIconLoad} />
                              </div>
                            ) : listNft.length === 0 ? (
                              <div className="nft-empty">
                                <Empty />
                              </div>
                            ) : (
                              <>
                                {listNft &&
                                  listNft.map((item: any, i: any) => (
                                    <Item data={item} />
                                  ))}
                                {state.nftListMore &&
                                  state.nftListMore.map((item: any, i: any) => (
                                    <Item data={item} />
                                  ))}
                              </>
                            )}
                          </div>
                          <div className="load-more-round">
                            {isLoadMore ? (
                              <Spin indicator={antIconLoad} />
                            ) : state.hideLoadmore ? (
                              ""
                            ) : state.nftList?.length === 0 ? (
                              ""
                            ) : (
                              <button
                                className="btn-loadmore cus"
                                type="button"
                                onClick={() => handleLoadMore()}
                              >
                                Load More
                              </button>
                            )}
                          </div>
                        </div>
                      </Tabs.TabPane>
                      {/* <Tabs.TabPane tab="Digital Art" key="2">
                        <div className="round-tabs">
                          <div className="columns">
                            <Item />
                            <Item />
                            <Item />
                          </div>
                          <div className="load-more-round">
                            <button className="btn-loadmore cus" type="button">
                              Load More
                            </button>
                          </div>
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Game Items" key="3">
                        <div className="round-tabs">
                          <div className="columns">
                            <Item />
                            <Item />
                            <Item />
                          </div>
                          <div className="load-more-round">
                            <button className="btn-loadmore cus" type="button">
                              Load More
                            </button>
                          </div>
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Collectibles" key="4">
                        <div className="round-tabs">
                          <div className="columns">
                            <Item />
                            <Item />
                            <Item />
                          </div>
                          <div className="load-more-round">
                            <button className="btn-loadmore cus" type="button">
                              Load More
                            </button>
                          </div>
                        </div>
                      </Tabs.TabPane> */}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCenter;
