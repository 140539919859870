import { isMobile } from "react-device-detect";

import "./style.css";

const Process = () => {
  const gotoMore = () => {
    window.open(
      "https://docs.bscs.finance/defi/launchpad/apply-to-launch"
    );
  };
  const gotoApply = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    );
  };
  return (
    <div className="wrap-process">
      <div className="process">
        <div className="line-process">
          <img src="./images/apply/line-step.png" alt="" />
        </div>
        <div className="container">
          <div className="process-title">Application process on BSCS</div>
          {isMobile ? (
            <>
              <div className="step">
                <div className="img-step step1">
                  <div className="step-des">
                    <div className="step-name">Step 1</div>
                    <div className="step-title">
                      Project fills Information Form
                    </div>
                  </div>
                  <img src="./images/apply/step1-m.png" alt="" />
                </div>
                <div className="img-step step2">
                  <div className="step-des">
                    <div className="step-name">Step 2</div>
                    <div className="step-title">Due diligence & Discussion</div>
                  </div>
                  <img src="./images/apply/step2-m.png" alt="" />
                </div>
                <div className="img-step step3">
                  <div className="step-des">
                    <div className="step-name">Step 3</div>
                    <div className="step-title">Legal entity & KYCs</div>
                  </div>
                  <img src="./images/apply/step3-m.png" alt="" />
                </div>
                <div className="img-step step4">
                  <div className="step-des">
                    <div className="step-name">Step 4</div>
                    <div className="step-title">Service Agreement</div>
                  </div>
                  <img src="./images/apply/step4-m.png" alt="" />
                </div>
                <div className="img-step step5">
                  <div className="step-des">
                    <div className="step-name">Step 5</div>
                    <div className="step-title">Agreement Installation</div>
                  </div>
                  <img src="./images/apply/step5-m.png" alt="" />
                </div>
                <div className="img-step step6">
                  <div className="step-des">
                    <div className="step-name">Step 6</div>
                    <div className="step-title">Fund transfer & Acceptance</div>
                  </div>
                  <img src="./images/apply/step6-m.png" alt="" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="step">
                <div className="img-step step1">
                  <div className="step-des">
                    <div className="step-name">Step 1</div>
                    <div className="step-title">
                      Project fills Information Form
                    </div>
                  </div>
                  <img src="./images/apply/step1.png" alt="" />
                </div>
                <div className="img-step step2">
                  <div className="step-des">
                    <div className="step-name">Step 2</div>
                    <div className="step-title">Due diligence & Discussion</div>
                  </div>
                  <img src="./images/apply/step2.png" alt="" />
                </div>
                <div className="img-step step3">
                  <div className="step-des">
                    <div className="step-name">Step 3</div>
                    <div className="step-title">Legal entity & KYCs</div>
                  </div>
                  <img src="./images/apply/step3.png" alt="" />
                </div>
                <div className="img-step step4">
                  <div className="step-des">
                    <div className="step-name">Step 4</div>
                    <div className="step-title">Service Agreement</div>
                  </div>
                  <img src="./images/apply/step4.png" alt="" />
                </div>
                <div className="img-step step5">
                  <div className="step-des">
                    <div className="step-name">Step 5</div>
                    <div className="step-title">Agreement Installation</div>
                  </div>
                  <img src="./images/apply/step5.png" alt="" />
                </div>
                <div className="img-step step6">
                  <div className="step-des">
                    <div className="step-name">Step 6</div>
                    <div className="step-title">Fund transfer & Acceptance</div>
                  </div>
                  <img src="./images/apply/step6.png" alt="" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="get-button process">
        <button
          type="button"
          className="btn btn-trade pro why"
          onClick={gotoApply}
        >
          Apply to launch
        </button>
        <button type="button" className="btn btn-trade pro " onClick={gotoMore}>
          Learn more
        </button>
      </div>
    </div>
  );
};

export default Process;
