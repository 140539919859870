import Web3 from "web3";
import abiBSCS from "../abi/abiBSCS.json";

const web3 = new Web3(window.ethereum);
const tokenAddress = "0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D";


async function getBalanceBSCS(address) {
  const contract = new web3.eth.Contract(abiBSCS,tokenAddress);

  try {
    const balanceBSCS = await contract.methods.balanceOf(address).call()
    return web3.utils.fromWei(balanceBSCS, 'ether')
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default getBalanceBSCS
