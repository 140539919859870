/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Button, Collapse, Modal } from "antd";
import bgMainheader from "./images/bg-footer.png";
import useGetPriceData from "../../hooks/useGetPriceData";
import { useHookProjects } from "../../pages/Home/Store";
import { Link } from "react-router-dom";
import { addTokenToWallet } from "./../../utils/token";
import pdf from './Tokenomics2.pdf'
import {
  SWAP_BSCS_URL,
  NFT_URL,
  LAUNCHPAD_URL,
  FARMS_URL,
  AIR_DROP,
  DOCS_URL,
  GITHUB_URL,
  SolidProof_URL,
  CERTIK_AUDIT_URL,
  PECKSHIELD_AUDIT_URL,
  Quillhash_AUDIT_URL,
  INO_URL,
  TWITTER_URL,
  TELEGRAM_URL,
  YOUTUBE_URL,
  REDIT_URL,
  SUBSTACK,
  BUILDERS,
  APPLY,
  SUPPORT,
  LOGO_ICON,
  FACE_URL,
  ADDRESS_BSCS_ADDRESS,
  WEB3,
} from "../../constants/index";

const Footer = () => {
  const [state, actions] = useHookProjects();
  const priceBSCS = state.price;
  const { Panel } = Collapse;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openLink = (num) => {
    setIsModalVisible(false);
    switch (num) {
      case 1: {
        window
          .open(
            "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
            "_blank"
          )
          ?.focus();
        break;
      }
      case 2: {
        window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
        break;
      }
      case 3: {
        window
          .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
          ?.focus();
        break;
      }
      case 4: {
        window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
        break;
      }
    }
  };

  const onChange = (key) => {
    // console.log(key);
  };

  const menuMobileFooter = (
    <>
      <Collapse
        className="collapse-menu"
        defaultActiveKey={["0"]}
        onChange={onChange}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header="About" key="1">
          <ul className="sub-menu-collapse">
            <li>
              <a href={`${DOCS_URL}`} target="_blank" rel="noreferrer">
                Docs
              </a>
            </li>{" "}
            <li>
              <a
                href="https://skynet.certik.com/projects/bscs"
                target="_blank"
                rel="noreferrer"
              >
                Audit
              </a>
            </li>{" "}
            <li>
              <a href="/Brand" target="_blank" rel="noreferrer">
                Brand Asset
              </a>
            </li>
            <li>
              <a href="/bscs-verify" target="_blank" rel="noreferrer">
                BSCS Verify
              </a>
            </li>{" "}
            <li>
              <a href="https://app.bscs.finance/swap" target="_blank">
                Buy BSCS
              </a>
            </li>
            <li>
              <a href={pdf} target = "_blank">Tokenomics 2.0</a>
            </li>
          </ul>
        </Panel>
        <Panel header="Products" key="2">
          <ul className="sub-menu-collapse">
            <li>
              <a href={`${LAUNCHPAD_URL}`} rel="noreferrer">
                Launchpad
              </a>
            </li>
            <li>
              <a
                href="https://app.bscs.finance/swap"
                rel="noreferrer"
                target="blank"
              >
                Swap
              </a>
            </li>
            <li>
              <a
                href="https://app.bscs.finance/whale-tracker"
                rel="noreferrer"
                target="blank"
              >
                Whale tracker
              </a>
            </li>

            <li>
              <a
                href="https://app.bscs.finance/dex-tools"
                rel="noreferrer"
                target="blank"
              >
                Bot
              </a>
            </li>
            <li>
              <a
                href="https://app.bscs.finance/home-page"
                rel="noreferrer"
                target="blank"
              >
                View
              </a>
            </li>
            <li>
              <a href={`${NFT_URL}`} rel="noreferrer">
                NFT
              </a>
            </li>
          </ul>
        </Panel>
        <Panel header="Community" key="3">
          <ul className="sub-menu-collapse">
            <li>
              <a href={`${TWITTER_URL}`} target="_blank" rel="noreferrer">
                <img src="/images/twi.svg?v=2" alt="" /> X.com
              </a>
            </li>

            <li>
              <a href={`${TELEGRAM_URL}`} target="_blank" rel="noreferrer">
                <img src="/images/tele.svg" alt="" /> Telegram
              </a>
            </li>
            <li>
              <a href={`${FACE_URL}`} target="_blank" rel="noreferrer">
                <img src="/images/face-v3.png" alt="" /> Facebook
              </a>
            </li>
            <li>
              <a href={`${YOUTUBE_URL}`} target="_blank" rel="noreferrer">
                <img src="/images/you.svg" alt="" /> Youtube
              </a>
            </li>
            <li>
              <a
                href="https://bscs.substack.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/substack.png" alt="" /> Substack
              </a>
            </li>
          </ul>
        </Panel>
        <Panel header="Service" key="4">
          <ul className="sub-menu-collapse">
            <li>
              <a href={`${AIR_DROP}`} target="_blank" rel="noreferrer">
                Airdrops
              </a>
            </li>
            <li>
              <a href={`${SUPPORT}`} target="_blank" rel="noreferrer">
                Support
              </a>
            </li>
            <li>
              <a href={`${BUILDERS}`} target="_blank" rel="noreferrer">
                IDO Builders
              </a>
            </li>
            <li>
              <a href={`${APPLY}`} target="_blank" rel="noreferrer">
                Apply to Launch
              </a>
            </li>
            <li>
              <a href={`${WEB3}`} target="_blank" rel="noreferrer">
                Web3 Ignite
              </a>
            </li>
          </ul>
        </Panel>
        <Panel header="Legal" key="5">
          <ul className="sub-menu-collapse">
            <li>
              <a href="/TermOfUse" target="_blank" rel="noreferrer">
                Terms
              </a>
            </li>
            <li>
              <a href="/PrivacyPolicy" target="_blank" rel="noreferrer">
                Privacy
              </a>
            </li>
            <li>
              <a href="/CookiePolicy" target="_blank" rel="noreferrer">
                Cookies
              </a>
            </li>
          </ul>
        </Panel>
      </Collapse>
    </>
  );
  if (isMobile) {
    return (
      <>
        <footer
          className="main-footer mobile"
          style={{ backgroundImage: `url(${bgMainheader})` }}
        >
          <div className="container">
            <div className="columns">
              <div className="colum w-100">
                <div className="content-footer-r">
                  <div className="list-content">
                    <div className="box-logo">
                      <img src="/images/logo-brand-v3.svg" alt="" />
                    </div>

                    <div className="content-ft-menu social-footer">
                      <div className="list-social-footer">
                        <div className="icon-sl-first">
                          <a
                            href={`${TWITTER_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/twi-ft.svg?v=2" alt="" />
                          </a>
                        </div>
                        <div className="icon-sl">
                          <a
                            href={`${TELEGRAM_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/tele-ft.svg" alt="" />
                          </a>
                        </div>
                        <div className="icon-sl">
                          <a
                            href={`${REDIT_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/sub-ft.svg" alt="" />
                          </a>
                        </div>

                        <div className="icon-sl">
                          <a
                            href={`${YOUTUBE_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/you-ft.svg" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <p className="desc">
                      Please contact us if you have any specific idea or
                      request.
                    </p>
                    <p className="desc-mail">
                      <img src="/images/email.png" alt="" />{" "}
                      contact@bscs.finance
                    </p>
                    <div className="price-ft">
                      <div className="price-l">
                        <div
                          onClick={() =>
                            addTokenToWallet({
                              image: LOGO_ICON,
                              address: ADDRESS_BSCS_ADDRESS,
                              symbol: "BSCS",
                              decimals: 18,
                            })
                          }
                          className="img-price-ft"
                        >
                          <img src="/images/icon-price.png" alt="" />
                        </div>
                        <span>${priceBSCS}</span>
                      </div>
                      <div className="price-r">
                        <div className="icon-meta">
                          <img src="/images/icon-meta.png" alt="" />
                        </div>
                        <button
                          type="button"
                          className="btn-buy-bscs"
                          onClick={() =>
                            addTokenToWallet({
                              image: LOGO_ICON,
                              address: ADDRESS_BSCS_ADDRESS,
                              symbol: "BSCS",
                              decimals: 18,
                            })
                          }
                        >
                          Add to wallet
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-ft-">{menuMobileFooter}</div>
                <div className="copy-right">
                  BSCS{" "}
                  <div className="row-copy-right">
                    {" "}
                    <img
                      src="/images/copyright.svg"
                      className="copyright-icon"
                      alt=""
                    />{" "}
                  </div>
                  2023
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* @ts-ignore this lib is incompatible with react18 */}
        <Modal
          title="Buy BSCS"
          footer={null}
          visible={isModalVisible}
          onCancel={handleCancel}
          className="modal-link"
          width={650}
        >
          <div className="link-buy-bscs" onClick={() => openLink(1)}>
            <p> 🥞 Pancakeswap.finance </p>
          </div>
          <div className="link-buy-bscs" onClick={() => openLink(2)}>
            <img
              className="img-link"
              alt=""
              src="/images/coins/gate.png?v=152"
            />
            <p>
              SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
              Trading-Gate.io
            </p>
          </div>
          <div className="link-buy-bscs" onClick={() => openLink(3)}>
            <img
              className="img-link"
              alt=""
              src="/images/coins/mxc.png?v=152"
            />
            <p>
              MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
              Quotes{" "}
            </p>
          </div>
        </Modal>
      </>
    );
  }
  return (
    <>
      <MainFooter>
        <div className="container">
          <div className="columns">
            <div className="colum w-70">
              <div className="content-footer-l">
                <div className="columns">
                  <div className="colum w-20">
                    <div className="content-menu">
                      <h3 className="title-ft">About</h3>
                      <ul className="list-menu-ft">
                        <li>
                          <a
                            href={`${DOCS_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Docs
                          </a>
                        </li>{" "}
                        <li>
                          <a
                            href="https://skynet.certik.com/projects/bscs"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Audit
                          </a>
                        </li>{" "}
                        <li>
                          <a href="/Brand" target="_blank" rel="noreferrer">
                            Brand Asset
                          </a>
                        </li>
                        <li>
                          <a
                            href="/bscs-verify"
                            target="_blank"
                            rel="noreferrer"
                          >
                            BSCS Verify
                          </a>
                        </li>{" "}
                        <li>
                          <a
                            href="https://app.bscs.finance/swap"
                            target="_blank"
                          >
                            Buy BSCS
                          </a>
                        </li>
                        <li>
                          <a href={pdf} target = "_blank">Tokenomics 2.0</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="colum w-20">
                    <div className="content-menu">
                      <h3 className="title-ft">Products</h3>
                      <ul className="list-menu-ft">
                        <li>
                          <a href={`${LAUNCHPAD_URL}`} rel="noreferrer">
                            Launchpad
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://app.bscs.finance/swap"
                            rel="noreferrer"
                            target="blank"
                          >
                            Swap
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://app.bscs.finance/whale-tracker"
                            rel="noreferrer"
                            target="blank"
                          >
                            Whale tracker
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://app.bscs.finance/dex-tools"
                            rel="noreferrer"
                            target="blank"
                          >
                            Bot
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://app.bscs.finance/home-page"
                            rel="noreferrer"
                            target="blank"
                          >
                            View
                          </a>
                        </li>
                        <li>
                          <a href={`${NFT_URL}`} rel="noreferrer">
                            NFT
                          </a>
                        </li>
                        {/* <li>Cross-chain Swap</li> */}
                        {/* <li>Coinmarket</li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="colum w-20">
                    <div className="content-menu">
                      <h3 className="title-ft">Community</h3>
                      <ul className="list-menu-ft">
                        <li>
                          <a
                            href={`${TWITTER_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/twi.svg?v=2" alt="" /> X.com
                          </a>
                        </li>

                        <li>
                          <a
                            href={`${TELEGRAM_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/tele.svg" alt="" /> Telegram
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${FACE_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/face-v3.png" alt="" /> Facebook
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${YOUTUBE_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/you.svg" alt="" /> Youtube
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://bscs.substack.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/images/substack.png" alt="" /> Substack
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="colum w-20">
                    <div className="content-menu">
                      <h3 className="title-ft">Campaigns</h3>
                      <ul className="list-menu-ft">
                        <li>
                          <a
                            href={`${AIR_DROP}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Airdrops
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${SUPPORT}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Support
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${BUILDERS}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            IDO Builders
                          </a>
                        </li>
                        <li>
                          <a href={`${APPLY}`} target="_blank" rel="noreferrer">
                            Apply to Launch
                          </a>
                        </li>
                        <li>
                          <a href={`${WEB3}`} target="_blank" rel="noreferrer">
                            Web3 Ignite
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="colum w-20">
                    <div className="content-menu">
                      <h3 className="title-ft">Legal</h3>
                      <ul className="list-menu-ft">
                        <li>
                          <a href="/TermOfUse" target="_blank" rel="noreferrer">
                            Terms
                          </a>
                        </li>
                        <li>
                          <a
                            href="/PrivacyPolicy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy
                          </a>
                        </li>
                        <li>
                          <a
                            href="/CookiePolicy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Cookies
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="colum w-30">
              <div className="content-footer-r">
                <div className="list-content">
                  <div className="box-logo">
                    <img src="/images/logo-brand-v3.svg" alt="" />
                  </div>
                  <p className="desc">
                    Please contact us if you have any specific idea or request.
                  </p>
                  <p className="desc-mail">
                    <img src="/images/email.png" alt="" /> contact@bscs.finance
                  </p>

                  <div className="price-ft">
                    <div className="price-l">
                      <div
                        onClick={() =>
                          addTokenToWallet({
                            image: LOGO_ICON,
                            address: ADDRESS_BSCS_ADDRESS,
                            symbol: "BSCS",
                            decimals: 18,
                          })
                        }
                        className="img-price-ft"
                      >
                        <img src="/images/icon-price.png" alt="" />
                      </div>
                      <span>${priceBSCS}</span>
                    </div>
                    <div className="price-r">
                      <div className="icon-meta">
                        <img src="/images/icon-meta.png" alt="" />
                      </div>

                      <button
                        type="button"
                        className="btn-buy-bscs"
                        onClick={() =>
                          addTokenToWallet({
                            image: LOGO_ICON,
                            address: ADDRESS_BSCS_ADDRESS,
                            symbol: "BSCS",
                            decimals: 18,
                          })
                        }
                      >
                        Add to wallet
                      </button>
                    </div>
                  </div>
                  <div className="copy-right">
                    BSCS
                    <div className="row-copy-right">
                      {" "}
                      <img
                        src="/images/copyright.svg"
                        className="copyright-icon"
                        alt=""
                      />{" "}
                    </div>
                    2023
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* @ts-ignore this lib is incompatible with react18 */}
        <Modal
          title="Buy BSCS"
          footer={null}
          visible={isModalVisible}
          onCancel={handleCancel}
          className="modal-link"
          width={650}
        >
          <div className="link-buy-bscs" onClick={() => openLink(1)}>
            {/* <img className="img-link" alt="" src="/images/coins/BSCS.png?v=152" /> */}
            <p> 🥞Pancakeswap.finance </p>
          </div>
          <div className="link-buy-bscs" onClick={() => openLink(2)}>
            <img
              className="img-link"
              alt=""
              src="/images/coins/gate.png?v=152"
            />
            <p>
              SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
              Trading-Gate.io
            </p>
          </div>
          <div className="link-buy-bscs" onClick={() => openLink(3)}>
            <img
              className="img-link"
              alt=""
              src="/images/coins/mxc.png?v=152"
            />
            <p>
              MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
              Quotes{" "}
            </p>
          </div>
        </Modal>
      </MainFooter>
    </>
  );
};
export default Footer;

const MainFooter = styled.footer`
  padding-bottom: 50px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  background-image: url(${bgMainheader});
`;
