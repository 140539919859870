import web3 from "web3";
import { MaxUint256 } from "@ethersproject/constants";
import { MINT_PRICE, CONTRACT_NFT } from "../../constants";

//
export const _getMaxTokenId = (nftContract: any) => {
  return nftContract.nextTokenId();
};

const convertNumber = (value: any) => {
  return web3.utils.toHex(web3.utils.toWei(value, "ether"));
};
export const _transferNFT = async (
  nftContract: any,
  account: any,
  to: any,
  tokenId: any
) => {
  const args = [account, to, tokenId];
  const estimatedGas = await nftContract.estimateGas.transferFrom(...args);
  return nftContract.transferFrom(...args, {
    gasLimit: estimatedGas,
  });
};

export const _cancelTokenTo = async (bidContract: any, tokenId: any) => {
  const args = [tokenId];
  const estimatedGas = await bidContract.estimateGas.cancelSellToken(tokenId);
  return bidContract.cancelSellToken(...args, {
    gasLimit: estimatedGas, // exe
  });
};

export const _sellTokenTo = async (
  bidContract: any,
  tokenId: any,
  address: any
) => {
  const args = [tokenId, address];
  const estimatedGas = await bidContract.estimateGas.sellTokenTo(
    tokenId,
    address
  );
  return bidContract.sellTokenTo(...args, {
    gasLimit: estimatedGas, //
  });
};
export const _cancelBidToken = async (bidContract: any, tokenId: any) => {
  const args = [tokenId];
  const estimatedGas = await bidContract.estimateGas.cancelBidToken(tokenId);
  return bidContract.cancelBidToken(...args, {
    gasLimit: estimatedGas,
  });
};

export const _getBids = async (bidContract: any, tokenId: any) => {
  return new Promise((resolve, reject) => {
    const args = [tokenId];
    bidContract
      .getBids(...args, {
        gasLimit: 200000,
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const _getAsks = (bidContract: any, account: any) => {
  const args = [account];
  return bidContract?.getAsksByUser(...args);
};

export const _buyToken = async (bidContract: any, tokenId: any) => {
  const args = [tokenId];
  const estimatedGas = await bidContract.estimateGas.buyToken(...args);
  const result = await bidContract.buyToken(...args, {
    gasLimit: estimatedGas,
  });
  return result;
};

const _updateBidToken = async (bidContract: any, tokenId: any, price: any) => {
  return new Promise((resolve, reject) => {
    const args = [tokenId, convertNumber(price)];
    bidContract
      .updateBidPrice(...args, {
        gasLimit: 2000000,
      })
      .then((response: any) => {
        console.log("response>>", response);
        resolve(response);
      })
      .catch((error: any) => {
        console.log("response>>");
        reject(error);
      });
  });
};

export const _getOwnerToken = async (nftContract: any, account: any) => {
  return nftContract.ownerOf(account);
};

export const _bidToken = async (bidContract: any, tokenId: any, price: any) => {
  const args = [tokenId, convertNumber(price)];
  const estimatedGas = await bidContract.estimateGas.bidToken(...args);
  return bidContract.bidToken(...args, {
    gasLimit: estimatedGas,
  });
};

export const _setCurrentPrice = async (
  bidContract: any,
  tokenId: any,
  price: any
) => {
  const args = [tokenId, convertNumber(price)];
  const estimatedGas = await bidContract.estimateGas.setCurrentPrice(...args);
  return bidContract.setCurrentPrice(...args, {
    gasLimit: estimatedGas,
  });
};

export const _readyToSellToken = async (
  bidContract: any,
  tokenId: any,
  price: any
) => {
  const args = [tokenId, convertNumber(price)];
  const estimatedGas = await bidContract.estimateGas.readyToSellToken(...args);
  return bidContract.readyToSellToken(...args, {
    gasLimit: estimatedGas,
  });
};

export const _approve = async (contractBSCS: any, CONTRACT_BID: any) => {
  // const amountTemp = web3.utils.toWei(amount.toString());
  const result = await contractBSCS.approve(CONTRACT_BID, MaxUint256);
  return result;
};

const _mintToken = async (nftContract: any, account: any, url: any) => {
  const args = [account, url];
  // const estimatedGas = await nftContract.estimateGas.mint(...[account, url], { from: account, value: MINT_PRICE })
  return nftContract.mint(...args, {
    gasLimit: 850000,
    value: MINT_PRICE,
  });
};

export default _mintToken;
