import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { BIG_TEN } from "./bigNumber";
import web3 from "web3";

export const getOwner = async (masterChefContract) => {
  return masterChefContract.methods.owner().call();
};

export const sousUnstake = async (souschefContract, amount, decimals) => {
  try {
    const result = await souschefContract.withdraw(
      new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed()
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const sousUpdateFee = async (souschefContract, amount) => {
  try {
    const result = await souschefContract.updateUnstakingFee(amount.toFixed());
    return result;
  } catch (error) {
    return error;
  }
};

export const sousUpdateReward = async (
  souschefContract,
  amount,
  decimals,
  tokenReward
) => {
  try {
    const result = await souschefContract.updateRewardPerBlock(
      new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed(),
      tokenReward
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const withdrawTokenReward = async (
  souschefContract,
  amount,
  decimals
) => {
  try {
    const result = await souschefContract.emergencyRewardWithdraw(
      new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed()
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const withdrawTokenStake = async (
  souschefContract,
  amount,
  decimals
) => {
  try {
    const result = await souschefContract.emergencyRemoval(
      new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed()
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const configures = async (
  souschefContract,
	stakeToken,
	rewardToken,
	startBlock,
	endBlock,
	rewardBlock,
	unstakeBlock,
	feeUnstake,
	feeCollect,
	admin,
	decimals,
) => {
  try {
    const result = await souschefContract.initialize(
      stakeToken,
			[rewardToken],
			[new BigNumber(rewardBlock).times(BIG_TEN.pow(decimals)).toFixed()],
			[startBlock,endBlock],
			[startBlock,endBlock],
			unstakeBlock,
			[feeUnstake,unstakeBlock],
			feeCollect,
			0,
			0,
			true,
			admin,
    );
    return result;
  } catch (error) {
    return error;
  }
};