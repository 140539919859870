import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.min.css";
import { useHookProjects } from "./Store";

const MainChainSlider = () => {
    const [state, actions]: any = useHookProjects();

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 6000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        pauseOnHover: true
    };
    const listSlideBanner = state.listBanner;
    const ItemSlider = (item: any) => {
        const slider_item = item.imageUrl;

        return (
            <div className="item-slider">
                <a href={`${item.linkUrl}`} target="_blank" rel="noreferrer">
                    <img src={slider_item} alt="" />
                </a>
            </div>
        );
    };
    const itemSlider = (
        <>
            <div className="item-slider">
                <img src="/images/img-slider-1.png" alt="" />
            </div>
        </>
    );
    const itemSlider2 = (
        <>
            <div className="item-slider">
                <img src="/images/img-slider-2.png" alt="" />
            </div>
        </>
    );
    const itemSlider3 = (
        <>
            <div className="item-slider">
                <img src="/images/img-slider-3.png" alt="" />
            </div>
        </>
    );

    return (
        <>
            <div className="main-setion">
                <div className="container">
                    <div className="main-chain">
                        <div className="chain-swap">
                            {[
                                {
                                    opacity: "/images/ether-opacity.svg?=v2",
                                    normal: "/images/ether.svg?=v2",
                                },
                                {
                                    opacity: "/images/bnb-opacity.svg?=v2",
                                    normal: "/images/bnb.svg?=v2",
                                },
                                {
                                    opacity: "/images/trx-opacity.svg?=v2",
                                    normal: "/images/trx.svg?=v2",
                                },
                                {
                                    opacity: "/images/polygon-opacity.svg?=v2",
                                    normal: "/images/polygon.svg?=v2",
                                },
                                {
                                    opacity: "/images/fantom-opacity.svg?=v2",
                                    normal: "/images/fantom.svg?=v2",
                                },
                                {
                                    opacity: "/images/solona-opacity.svg?=v2",
                                    normal: "/images/solona.svg?=v2",
                                },
                                {
                                    opacity: "/images/base.svg?=v2",
                                    normal: "/images/base-opacity.svg?=v2",
                                },
                            ].map((chain, index) => (
                                <div className="box-img chain-custom" key={index}>
                                    <img src={chain.opacity} alt="" />
                                    <img src={chain.normal} alt="" className="hight-light" />
                                </div>
                            ))}
                        </div>
                        <div className="chain-swap cus" style={{ paddingTop: "20px" }}>
                            {[
                                {
                                    opacity: "/images/avalan-opacity.svg?=v2",
                                    normal: "/images/avalan.svg?=v2",
                                },
                                {
                                    opacity: "/images/x11.png",
                                    normal: "/images/x1.png",
                                },
                                {
                                    opacity: "/images/dogechain-opacity.svg?=v2",
                                    normal: "/images/dogechain.svg?=v2",
                                },
                                {
                                    opacity: "/images/aptos-opacity.svg?=v2",
                                    normal: "/images/aptos.svg?=v2",
                                },
                                {
                                    opacity: "/images/optimis-opacity.svg?=v2",
                                    normal: "/images/optimis.svg?=v2",
                                },
                                {
                                    opacity: "/images/sui.svg?=v3",
                                    normal: "/images/sui-opacity.svg?=v3",
                                },
                                {
                                    opacity: "/images/polkadot-opacity.svg?=v2",
                                    normal: "/images/polkadot.svg?=v2",
                                },
                                {
                                    opacity: "/images/arbitrum-opacity.svg?=v2",
                                    normal: "/images/arbitrum.svg?=v2",
                                },
                            ].map((chain, index) => (
                                <div className="box-img chain-custom" key={index}>
                                    <img style={{ height: '45px' }} src={chain.opacity} alt="" />
                                    <img style={{ height: '45px' }} src={chain.normal} alt="" className="hight-light" />
                                </div>
                            ))}
                        </div>
                        <div className="chain-swap new" >
                            {[
                                {
                                    opacity: "/images/zk-opacity.svg?=v2",
                                    normal: "/images/zk.svg?=v2",
                                },
                                {
                                    opacity: "/images/ton-opacity.svg",
                                    normal: "/images/ton.svg",
                                },

                            ].map((chain, index) => (
                                <>
                                    {index === 0 ? (
                                        <div className="box-img chain-custom" key={index}>
                                            <img style={{ height: '90px', width: '110px' }} src={chain.opacity} alt="" />
                                            <img style={{ height: '90px', width: '110px' }} src={chain.normal} alt="" className="hight-light2" />
                                        </div>
                                    ) : (
                                        <div className="box-img chain-custom" key={index}>
                                            <img style={{ height: '45px' }} src={chain.opacity} alt="" />
                                            <img style={{ height: '45px' }} src={chain.normal} alt="" className="hight-light1" />
                                        </div>
                                    )}
                                </>

                            ))}
                        </div>
                    </div>
                    <div className="table-raise">
                        <div className="columns">
                            <div className="colum w-25">
                                <div className="content-raise">
                                    <div className="title">RAISED CAPITAL</div>
                                    <div className="number-raise">
                                        ${state.marketInfo?.raisedCapital}M
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-25">
                                <div className="content-raise">
                                    <div className="title">IDEAS FUNDED</div>
                                    <div className="number-raise">
                                        {state.marketInfo?.ideasFunded}
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-25">
                                <div className="content-raise">
                                    <div className="title">PROJECTS MKT CAP</div>
                                    <div className="number-raise">
                                        ${state.marketInfo?.projectMktCap}M
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-25">
                                <div className="content-raise">
                                    <div className="title">UNIQUE PARTICIPANTS</div>
                                    <div className="number-raise">
                                        {state.marketInfo?.uniqueParticipans} users
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-chain">
                        {listSlideBanner.length == 0 ? (
                            <Slider {...settings}>
                                {itemSlider}
                                {itemSlider2}
                                {itemSlider3}
                                {itemSlider}
                                {itemSlider2}
                                {itemSlider3}
                            </Slider>
                        ) : (
                            <Slider {...settings}>
                                {listSlideBanner.map((item: any, index: any) => (
                                    <>{ItemSlider(item)}</>
                                ))}
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default MainChainSlider;
