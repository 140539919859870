/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from "react";
import BannerIdo from "./BannerIdo";
import TabsIdo from "./TabsIdo";
import styled from "styled-components";
import { useHookSol } from "../Solana/Store-sol";
import "./style.css";

const IdoLaunchPad = () => {
  const [state, actions]: any = useHookSol();

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "BSCS Launchpad is the innovative decentralized IDO platform across all main blockchain networks."
      );
  }, [state.accSOL]);

 

  return (
    <>
      <Wrapper>
        <Fullwidth>
          <Container>
            <BannerIdo />
            <TabsIdo />
          </Container>
        </Fullwidth>
      </Wrapper>
    </>
  );
};
export default IdoLaunchPad;

const Wrapper = styled.div`
  padding-top: 92px;
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/892421bfdfd6f28761be3ab53b4921bec30a9641502b0107aa.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
const Fullwidth = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
