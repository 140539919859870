import React, { useState, useCallback } from "react";
import { useContext } from "react";
import { ModalContext } from "../../../../components/modal";

import {
  FormContainer,
  NFTItem,
  RowItem,
  IMGItem,
  InforItem,
  TitleCollection,
  NFTName,
  MiniTitle,
  InforConfirm,
  RowConfirm,
  TitleConfirm,
  ValueConfirm,
  RowButton,
} from "./style";
import {
  CloseModal,
  TitleModal,
  ButtonCommon,
} from "../../../../components/common/styleCommon";
import { FEE_CREATOR, FEE_SERVICE } from "../../../../constants/index";
import Loading from "../../../../components/Loading";
import Value from "../../../../components/Value";

const ConfirmSellModal = (props:any) => {
  const { onCloseModal }:any = useContext(ModalContext);

  const [pendingTx, setPendingTx] = useState(false);
  const { onConfirm, detail, priceSell, priceUSD, type } = props;

  const creatorFee = (FEE_CREATOR / 100) * Number(priceSell);
  const serviceFee = (FEE_SERVICE / 100) * Number(priceSell);
  const totalReceived = Number(priceSell) - creatorFee - serviceFee || 0;

  const handleConfirm = useCallback(async () => {
    try {
      setPendingTx(true);
      await onConfirm();
      setPendingTx(false);
      onCloseModal()
    } catch (e) {
      console.error("err confirm", e);
      setPendingTx(false);
    }
  }, [onConfirm]);

  return (
    <>
      <FormContainer>
        <TitleModal>List item</TitleModal>
        <CloseModal onClick={() => onCloseModal()}> X </CloseModal>
        <NFTItem>
          <RowItem>
            <IMGItem>
              <img src={detail?.nftUrl} alt="" />
            </IMGItem>
            <InforItem>
              <TitleCollection>
                Blue-the Unicorn NFT Collections
              </TitleCollection>
              <NFTName>{detail?.name}</NFTName>
              <MiniTitle>Listing price:</MiniTitle>
              <NFTName>
                <img src="./../images/logo.png" alt="" /> <Value value={Number(priceSell)} fontWeight={600} /> BSCS{" "}
                <MiniTitle>${priceUSD}</MiniTitle>
              </NFTName>
            </InforItem>
          </RowItem>
          <InforConfirm>
            <RowConfirm>
              <div>
                {" "}
                <TitleConfirm>Listing price:</TitleConfirm>
              </div>
              <div>
                {" "}
                <ValueConfirm><Value value={Number(priceSell)} /> BSCS</ValueConfirm>
              </div>
            </RowConfirm>
            <RowConfirm>
              <TitleConfirm>Creator earnings:</TitleConfirm>
              <ValueConfirm>{FEE_CREATOR}%</ValueConfirm>
            </RowConfirm>
            <RowConfirm>
              <TitleConfirm>Service fee:</TitleConfirm>
              <ValueConfirm>{FEE_SERVICE}%</ValueConfirm>
            </RowConfirm>
            <RowConfirm>
              <TitleConfirm>Type of Sale:</TitleConfirm>
              <ValueConfirm>
                {type === "1" ? "Fixed price" : "Sell To Highest Bidder"}
              </ValueConfirm>
            </RowConfirm>
            <RowConfirm>
              <TitleConfirm>Total potential earnings:</TitleConfirm>
              <ValueConfirm><Value value={Number(totalReceived)} /> BSCS</ValueConfirm>
            </RowConfirm>
          </InforConfirm>
          <RowButton>
            <ButtonCommon disabled={pendingTx} onClick={handleConfirm}>
              <Loading status={pendingTx} content="Complete listing" />
            </ButtonCommon>
          </RowButton>
        </NFTItem>
      </FormContainer>
    </>
  );
};

export default ConfirmSellModal;
