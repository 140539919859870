import React from "react";
import { Clock, Target } from "react-feather";
import Value from "../../componentsST/Value";
import { useCurrentBlock } from "../../store/hooks";
import { getPoolBlockInfo } from "./helpers";

const BlockCountdown = ({ pool }) => {
  const currentBlock = useCurrentBlock();

  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
  } = getPoolBlockInfo(pool, currentBlock);
  return (
    <>
      {shouldShowBlockCountdown &&
        ((blocksRemaining || blocksUntilStart) && currentBlock ? (
          <>
            <span className="txt">
              <Value size={16} value={blocksToDisplay} decimals={0} />
              <span className="symbol">Blocks</span>
            </span>
          </>
        ) : (
          <span className="txt">---</span>
        ))}
    </>
  );
};

export default BlockCountdown;
