/* eslint-disable object-shorthand */
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";

import { API_URL } from "../../../constants";

const getProducts =
  (params) =>
  ({ setState, getState }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/Artworks/company_artworks`, {
          // .post("https://api.bscstation.org/api/Artworks/company_artworks", {
          ...params,
        })
        .then((res) => {
          setState({ nftList: res.data.data });
          resolve(true);
        }, [])
        .catch((e) => {
          reject(e);
        });
    });
  };
const getProductsMore =
  (params) =>
  ({ setState, getState }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/Artworks/company_artworks`, {
          // .post("https://api.bscstation.org/api/Artworks/company_artworks", {
          ...params,
        })
        .then((res) => {
          if (res.data.data.length === 0) {
            setState({ hideLoadmore: true });
          }
          // setState({ nftList: res.data.data });
          setState({
            nftListMore: getState().nftListMore.concat(res.data.data) || [],
          });
          resolve(true);
        }, [])
        .catch((e) => {
          reject(e);
        });
    });
  };

const StoreMarket = createStore({
  initialState: {
    nftList: [],
    nftListMore: [],
    listBanner: [],
    marketInfo: {},
    totalVL: {},
    hideLoadmore: false,
  },
  actions: {
    getProducts,
    getProductsMore,
    getMarketInfo:
      () =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios(`${API_URL}/Market/api/get_market`).then((res) => {
            const { data } = res.data;
            setState({ marketInfo: { ...data } }); // TODO
            resolve(data);
          });
        });
      },
      getListBanner:
      () =>
      async ({ setState }) => {
        let res = null;
        try {
          res = await axios.get(`${API_URL}/meta/image_slide`);
          const {
            data: { data },
          } = res;
          setState({ listBanner: data || [] });
        } catch (err) {
          // TODO
        }
        return res;
      },
    getTVL:
      () =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios(`${API_URL}/Market/api/get_tvl`).then((res) => {
            const { data } = res.data;
            setState({ totalVL: { data } }); // TODO
            resolve(data);
          });
        });
      },
  },
});

export const useHookNftMarket = createHook(StoreMarket);
export const Container = createContainer(StoreMarket, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreMarket);
