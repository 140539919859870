import React, { useEffect, useState } from "react";
import "./style.css"

const Web3Ignite = () => {
    const handleOpenLink = () => {
        window.open(`https://docs.google.com/forms/d/e/1FAIpQLSfs08UUXOKO0IlZu1S6LvQ_wpggDrEjOCR7QFiP_l7SNqi1Rg/viewform`, "_blank");
    };
    return (
        <>
            <div className="section-ignite" style={{ backgroundImage: `url(${"https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/1e56dfa01c4631ca66f8a3f0a71584bbd9abd59bc46c224012.png"})` }}>
                <div className="container">
                    <div className="main-banner">
                        <div className="title">
                            Web3 Ignite Program <br />
                            <span className="main-color">
                                Fueling Early-Stage Web3 Projects for Success
                            </span>
                        </div>
                        <div className="gr-button-apply">
                            <button className="btn-apply-ignite" onClick={handleOpenLink}>
                                Apply Now
                            </button>
                        </div>
                        <div className="txt">
                            RAISE CAPITAL ACROSS ALL MAIN BLOCKCHAIN NETWORKS
                        </div>
                        <div className="main-chain">
                            <div className="chain-swap">
                                {[
                                    {
                                        opacity: "/images/ether-opacity.svg?=v2",
                                        normal: "/images/ether.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/bnb-opacity.svg?=v2",
                                        normal: "/images/bnb.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/trx-opacity.svg?=v2",
                                        normal: "/images/trx.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/polygon-opacity.svg?=v2",
                                        normal: "/images/polygon.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/fantom-opacity.svg?=v2",
                                        normal: "/images/fantom.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/solona-opacity.svg?=v2",
                                        normal: "/images/solona.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/base.svg?=v2",
                                        normal: "/images/base-opacity.svg?=v2",
                                    },
                                ].map((chain, index) => (
                                    <div className="box-img chain-custom" key={index}>
                                        <img src={chain.opacity} alt="" />
                                        <img src={chain.normal} alt="" className="hight-light" />
                                    </div>
                                ))}
                            </div>
                            <div className="chain-swap cus" style={{ paddingTop: "20px" }}>
                                {[
                                    {
                                        opacity: "/images/avalan-opacity.svg?=v2",
                                        normal: "/images/avalan.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/okc-opacity.svg?=v2",
                                        normal: "/images/okc.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/dogechain-opacity.svg?=v2",
                                        normal: "/images/dogechain.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/aptos-opacity.svg?=v2",
                                        normal: "/images/aptos.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/optimis-opacity.svg?=v2",
                                        normal: "/images/optimis.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/sui.svg?=v2",
                                        normal: "/images/sui-opacity.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/polkadot-opacity.svg?=v2",
                                        normal: "/images/polkadot.svg?=v2",
                                    },
                                    {
                                        opacity: "/images/arbitrum-opacity.svg?=v2",
                                        normal: "/images/arbitrum.svg?=v2",
                                    },
                                ].map((chain, index) => (
                                    <div className="box-img chain-custom" key={index}>
                                        <img src={chain.opacity} alt="" />
                                        <img src={chain.normal} alt="" className="hight-light" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="main-program">
                        <div className="row-program">
                            <div className="columns">
                                <div className="colum w-6">
                                    <div className="content">
                                        <div className="title">
                                            What is <span className="main-color">Web3 Ignite</span>
                                        </div>
                                        <div className="desc">
                                            The <span className="fw-700">Web3 Ignite Program</span> is a dynamic and forward-thinking
                                            incubator designed by <span className="fw-700">BSCS</span> to nurture and elevate the next generation of Web3 projects. 
                                             <span className="fw-700">Web3 Ignite</span> is a bridge to investors, and a hub for collaboration within the ever-evolving world 
                                             of decentralized technologies.
                                        </div>
                                        <div className="gr-button-apply">
                                            <button className="btn-apply-ignite"  onClick={handleOpenLink}>
                                                Apply Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="box-img">
                                        <img src="./images/web3-banner.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-out-partner">
                    <div className="container">
                        <div className="row-out-partner">
                            <div className="title">
                                Our Partners
                            </div>
                            <div className="desc">
                                We’re supported by <span className="main-color">1200+</span> partners in our ecosystem
                            </div>
                            <div className="box-guide">
                                <div className="txt">
                                    Launchpad
                                </div>
                                <div className="row-path">
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-1.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-2.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-3.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-4.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-5.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-6.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-7.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-8.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-9.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-10.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-11.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="box-guide">
                                <div className="txt">
                                    Capital Venture
                                </div>
                                <div className="row-path">
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-12.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-13.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-14.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-15.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-16.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-17.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-18.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-19.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-20.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-21.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-22.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-23.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-24.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-25.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-26.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-27.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-28.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="box-guide">
                                <div className="txt">
                                    Listing
                                </div>
                                <div className="row-path">
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-29.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-30.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-31.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-32.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-33.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-34.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-35.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-36.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-37.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-38.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="box-guide">
                                <div className="txt">
                                    Media
                                </div>
                                <div className="row-path">
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-39.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-40.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-41.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-42.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-43.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-44.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-45.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="box-guide">
                                <div className="txt">
                                    Security
                                </div>
                                <div className="row-path">
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-46.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-47.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-48.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-49.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-50.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-51.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-52.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-53.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="box-guide">
                                <div className="txt">
                                    Market maker
                                </div>
                                <div className="row-path">
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-54.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-55.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-56.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-58.png" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="./images/pat-v3/v3-57.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-feature-key" style={{ backgroundImage: `url(${"https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/f6733b00076455132ce8cc19493f021737f71604e87f0044e3.png"})` }}>
                    <div className="container">
                        <div className="row-feature-key">
                            <div className="title">
                                <span className="main-color">
                                    Key Features
                                </span>
                            </div>
                            <div className="item-row">
                                <div className="box-icon">
                                    <img src="./images/pat-v3/s-1.png" alt="" />
                                </div>
                                <div className="text">
                                    <div className="txt">
                                    Comprehensive Support
                                    </div>
                                    <div className="desc">
                                        <span className="fw-600">Guidance</span>: Our experienced mentors and advisors provide project founders valuable insights, mentorship, and strategic guidance.<br />
                                        <span className="fw-600">Resource Access</span>: Participants gain access to a wealth of resources, from technical expertise to marketing support, to accelerate their projects.
                                    </div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="box-icon">
                                    <img src="./images/pat-v3/s-2.png" alt="" />
                                </div>
                                <div className="text">
                                    <div className="txt">
                                    Showcasing Innovations
                                    </div>
                                    <div className="desc">
                                        <span className="fw-600">Demo Days:</span> Our program includes Demo Days, where projects present their innovations to a diverse audience, gaining valuable exposure and feedback.<br />
                                        <span className="fw-600">Networking Opportunities:</span> Connect with investors, fellow entrepreneurs, and industry experts during networking events and conferences.
                                    </div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="box-icon">
                                    <img src="./images/pat-v3/s-3.png" alt="" />
                                </div>
                                <div className="text">
                                    <div className="txt">
                                        Investor Relations
                                    </div>
                                    <div className="desc">
                                        <span className="fw-600">Investor Introductions:</span> We facilitate introductions between projects and potential investors, fostering meaningful business relationships.<br />
                                        <span className="fw-600">Pitch Opportunities:</span> Showcase your project to a curated audience of investors seeking to support cutting-edge Web3 innovations.
                                    </div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="box-icon">
                                    <img src="./images/pat-v3/s-4.png" alt="" />
                                </div>
                                <div className="text">
                                    <div className="txt">
                                    Community Engagement
                                    </div>
                                    <div className="desc">
                                        <span className="fw-600">Collaboration:</span> The Web3 Ignite community is a hub for collaboration, idea exchange, and mutual support among founders.<br />
                                        <span className="fw-600">Resources and Insights:</span> Gain access to a network of like-minded individuals and resources to fuel your project's growth.
                                    </div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="box-icon">
                                    <img src="./images/pat-v3/s-5.png" alt="" />
                                </div>
                                <div className="text">
                                    <div className="txt">
                                    Tailored Growth Plans
                                    </div>
                                    <div className="desc">
                                        <span className="fw-600">Customized Strategies:</span> We work with each project to create tailored growth plans and strategies aligned with their unique goals and visions.<br />
                                        <span className="fw-600">Scalability: </span> Whether you're an NFT project, DeFi protocol, or Web3 infrastructure provider, we cater to diverse projects across the Web3 spectrum.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-choose">
                    <div className="container">
                        <div className="row-choose">
                            <div className="title">
                                Why Choose the Web3 Ignite Program?
                            </div>
                            <div className="table-choose">
                                <div className="columns">
                                    <div className="colum w-4">
                                        <div className="content">
                                            <div className="icon">
                                                <img src="./images/pat-v3/c-1.png" alt="" />
                                            </div>
                                            <div className="txt">
                                                Accelerated Growth
                                            </div>
                                            <div className="desc">
                                                Our program is designed to fast-track the development and success of early-stage Web3 projects.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-4">
                                        <div className="content">
                                            <div className="icon">
                                                <img src="./images/pat-v3/c-2.png" alt="" />
                                            </div>
                                            <div className="txt">
                                            Exposure and Visibility
                                            </div>
                                            <div className="desc">
                                            Showcase your project to a broad audience and gain the exposure needed to attract users, investors, and collaborators.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-4">
                                        <div className="content">
                                            <div className="icon">
                                                <img src="./images/pat-v3/c-3.png" alt="" />
                                            </div>
                                            <div className="txt">
                                            Meaningful Connections
                                            </div>
                                            <div className="desc">
                                            Forge lasting connections with investors, industry experts, and fellow entrepreneurs in the Web3 space.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-4">
                                        <div className="content">
                                            <div className="icon">
                                                <img src="./images/pat-v3/c-4.png" alt="" />
                                            </div>
                                            <div className="txt">
                                            Customized Support
                                            </div>
                                            <div className="desc">
                                            Benefit from tailored support and resources to address your project's unique needs and challenges.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-4">
                                        <div className="content">
                                            <div className="icon">
                                                <img src="./images/pat-v3/c-5.png" alt="" />
                                            </div>
                                            <div className="txt">
                                            Community
                                            </div>
                                            <div className="desc">
                                            Join a vibrant and supportive community of Web3 innovators who share your passion for decentralization and blockchain technologies
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-choose-bottom">
                                <div className="columns">
                                    <div className="colum w-6">
                                        <div className="row">
                                            <div className="box-img">
                                                <img src="./images/pat-v3/row-chose.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-6">
                                        <div className="row">
                                            <div className="content-key">
                                                <div className="title">
                                                    <span className="main-color">Ignite your Web3 Journey </span> with us!
                                                </div>
                                                <div className="desc">
                                                    Whether you're a visionary project founder seeking support or an investor looking for the next big thing in Web3, the <span className="fw-700">Web3 Ignite Program</span> by BSCS is your launchpad for success. Join us on this exciting innovation, collaboration, and transformative growth journey in the decentralized era.
                                                </div>
                                                <div className="gr-button-apply">
                                                    <button className="btn-apply-ignite now"  onClick={handleOpenLink}>
                                                        Apply Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Web3Ignite