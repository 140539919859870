import { useCallback, useEffect, useState } from "react";
import { getOwner } from "./../utils/callHelpers";
import { getSousChefContract } from "./../utils/contractHelpers";
import Web3 from "web3";
import { useAccount } from "wagmi";


const useSousGetOwner = (sousId) => {
  const { address } = useAccount();
  let account = address
  const web3 = new Web3(window.ethereum);
  const [owner, setOwner] = useState("");
  const handleGetOwner = useCallback(async () => {
    const souschefContract = getSousChefContract(sousId, web3);
    const owner = await getOwner(souschefContract);
    setOwner(owner);
  }, [account, sousId, web3]);

  useEffect(() => {
    if (account) {
      handleGetOwner();
    }
  }, [account, setOwner]);
  return owner;
};

export default useSousGetOwner;
