export const getPoolBlockInfo = (pool, currentBlock) => {
	const { startBlock, endBlock, isFinished } = pool

	const shouldShowBlockCountdown = Boolean(
		!isFinished && startBlock && endBlock,
	)
	const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
	const blocksRemaining = Math.max(endBlock - currentBlock, 0)
	const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
	const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
	return {
		shouldShowBlockCountdown,
		blocksUntilStart,
		blocksRemaining,
		hasPoolStarted,
		blocksToDisplay,
	}
}

// export const getPoolBlockUnstake = (pool, currentBlock) => {
// 	const { startBlock, unstakeBlock, isFinished } = pool
// 	console.log('unstakeBlock',unstakeBlock)

// 	const shouldShowBlockCountdown = Boolean(
// 		!isFinished && startBlock && unstakeBlock,
// 	)
// 	const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
// 	const blocksRemaining = Math.max(unstakeBlock - currentBlock, 0)
// 	const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
// 	const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
// 	return {
// 		shouldShowBlockCountdown,
// 		blocksUntilStart,
// 		blocksRemaining,
// 		hasPoolStarted,
// 		blocksToDisplay,
// 	}
// }

// export const getPoolBlockHarvest = (pool, currentBlock) => {
// 	const { startBlock, harvestBlock, isFinished } = pool

// 	const shouldShowBlockCountdown = Boolean(
// 		!isFinished && startBlock && harvestBlock,
// 	)
// 	const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
// 	const blocksRemaining = Math.max(harvestBlock - currentBlock, 0)
// 	const hasPoolHarvest = blocksUntilStart === 0 && blocksRemaining > 0
// 	const blocksToDisplay = hasPoolHarvest ? blocksRemaining : blocksUntilStart
// 	return {
// 		shouldShowBlockCountdown,
// 		blocksUntilStart,
// 		blocksRemaining,
// 		hasPoolHarvest,
// 		blocksToDisplay,
// 	}
// }
