import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import './style.css'

const BonusClaim = () => {
    let navigate = useNavigate();
    const viewDetail = () => {
        navigate(`${"/employee-bonus/details"}`);
    };
    return (
        <>
            <Wrapper>
                <Fullwidth>
                    <Container>
                        <div className="main-title-bonus">
                            BSCS REWARD POOL
                        </div>
                        <div className="table-bonus-row">
                            <div className="columns-bonus">
                                <div className="colum-bonus">
                                    <div className="content-bonus">
                                        <div className="top-row-bn">
                                            <div className="left">
                                                <div className="name">
                                                    BSCS reward Pool
                                                </div>
                                                <div className="time">
                                                    365 days
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="box-img">
                                                    <img src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/0d311ab7a9ac35379c72225fb02263aedafc020bdf2bd30435.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="middle-row-bn">
                                            <div className="box-img">
                                                <img src="./images/icon-make.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="bottom-row-bn">
                                            <button type="button" className="select-row-bn" onClick={() => viewDetail()}>
                                                Select
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Fullwidth>
            </Wrapper>
        </>
    )
}
export default BonusClaim

const Wrapper = styled.div`
  padding-top: 132px;
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/892421bfdfd6f28761be3ab53b4921bec30a9641502b0107aa.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-bottom: 90px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
const Fullwidth = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
