import React, { useState } from "react";
import { Modal } from "antd";
import "antd/dist/antd.min.css";
import bgMainheader from "./images/bg-main-banner.png";
import { useNavigate } from "react-router-dom";
import { APPLY } from "./../../constants";

const MainBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  let navigate = useNavigate();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openLink = (num: any) => {
    setIsModalVisible(false);
    switch (num) {
      case 1: {
        window
          .open(
            "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
            "_blank"
          )
          ?.focus();
        break;
      }
      case 2: {
        window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
        break;
      }
      case 3: {
        window
          .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
          ?.focus();
        break;
      }
      case 4: {
        window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
        break;
      }
    }
  };

  const gotoLaunchpad = () => {
    navigate(`${"/Ido"}`);
  };
  const gotoApply = () => {
    navigate(`${"/apply-launch"}`);
    // window.open(
    //   "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    // );
  };
  return (
    <>
      <div
        className="main-banner"
        style={{ background: `url(${bgMainheader}) 0% 50% / cover no-repeat` }}
      >
        <div className="container">
          <div className="content-banner">
            <h3 className="title" style={{ WebkitBackgroundClip: "text" }}>
              The fully decentralized protocol for launching <br /> new ideas
            </h3>
            <p className="desc">
              An all-in-one Incubation Hub with a full stack Defi platform{" "}
              <br /> across all main blockchain networks
            </p>
            <div className="get-button">
              <button
                type="button"
                className="btn btn-trade"
                onClick={gotoLaunchpad}
              >
                Upcoming IDO
              </button>

              <button
                type="button"
                onClick={gotoApply}
                className="btn btn-trade"
              >
                Apply to launch
              </button>
            </div>
            <p className="type">
              RAISE CAPITAL ACROSS ALL MAIN BLOCKCHAIN NETWORKS
            </p>
          </div>
        </div>
      </div>
      {/* @ts-ignore this lib is incompatible with react18 */}
      <Modal
        title="Buy BSCS"
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="modal-link"
        width={650}
      >
        <div className="link-buy-bscs" onClick={() => openLink(1)}>
          {/* <img className="img-link" alt="" src="/images/coins/BSCS.png?v=152" /> */}
          <p> 🥞Pancakeswap.finance </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(2)}>
          <img className="img-link" alt="" src="/images/coins/gate.png?v=152" />
          <p>
            SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
            Trading-Gate.io
          </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(3)}>
          <img className="img-link" alt="" src="/images/coins/mxc.png?v=152" />
          <p>
            MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
            Quotes{" "}
          </p>
        </div>
      </Modal>
    </>
  );
};
export default MainBanner;
