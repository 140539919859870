import styled from "styled-components";

export const FormContainer = styled.div`
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dce2e9;
  border-radius: 6px;
  @media screen and (max-width: 743px) {
    padding: 15px;
  }
`;
export const TitleModal = styled.p<{
  fontSize?: string;
  color?: string;
  fontWeight?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "20px")};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
  text-align: center;
`;
export const TextCustom = styled.p<{
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  textAlign?: string;
  padding?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  margin-bottom: 0px;
  font-family: Poppins;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding: ${({ padding }) => (padding ? padding : "10px 0")};

`;
export const CloseModal = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;
export const ButtonCommon = styled.a<{
  background?: string;
  textColor?: string;
  disabled?: boolean;
}>`
  display: flex;
  width: 200px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ disabled, background }) =>
    disabled ? "rgba(228, 228, 235, 1)" : background || "rgba(30, 35, 41, 1)"};
  color: ${({ disabled, textColor }) =>
    disabled
      ? "rgba(182, 184, 195, 1) !important"
      : textColor || "#fff !important"};
  font-size: 16px;
  font-weight: 400;
  pointer-events: ${({ disabled }) => disabled && "none"};
  &:hover {
    color: ${({ disabled, textColor }) =>
      disabled
        ? "rgba(182, 184, 195, 1) !important"
        : textColor || "#fff !important"};
  }
  @media screen and (max-width: 743px) {
    font-size: 14px;
  }
`;
