import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from "../../../constants";

const getProductsUserMore =
  (params) =>
  ({ setState, getState }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/Artworks/market_place`, {
          //  .post("https://api.bscstation.org/api/Artworks/market_place", {
          ...params,
        })
        .then((res) => {
          if (res.data.data.length === 0) {
            setState({ hideLoadmore: true });
          }
          // setState({ nftList: res.data.data });
          setState({
            nftListMore: getState().nftListMore.concat(res.data.data) || [],
          });
          resolve(true);
        }, [])
        .catch((e) => {
          reject(e);
        });
    });
  };

const StoreProfile = createStore({
  initialState: {
    objData: {},
    totalCount: 0,
    listNFT: [],
    nftListMore: [],
    hideLoadmore: false,
  },
  actions: {
    getProductsUserMore,
    getUserDetail:
      (id) =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios(`${API_URL}/Artists/${id}`)
            // axios(`https://api.bscstation.org/api/Artists/${id}`)
            .then((res) => {
              const { data } = res.data;
              setState({ objData: { ...data, tokenId: "9" } }); // TODO
            });
        });
      },
    getProductsUser:
      (params) =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          // axios(`${API_URL}/Artworks/market_place`)
          axios
            .post(`${API_URL}/Artworks/market_place`, {
              ...params,
            })
            .then((res) => {
              // const { data } = res.data;
              setState({ listNFT: res.data.data || [] }); // TODO
              resolve(true);
            });
        });
      },
    getProductsUserSort:
      (author, sortType) =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          // axios(`${API_URL}/Artists/${id}`)
          axios
            // .post(`https://api.bscstation.org/api/Artworks/market_place`, {
            .post(`${API_URL}/Artworks/market_place`, {
              author,
              sortType,
            })
            .then((res) => {
              // const { data } = res.data;
              setState({ listNFT: res.data.data || [] }); // TODO
            });
        });
      },
    // getProductsUser:
    //   () =>
    //   ({ getState, setState }) => {
    //     return new Promise((resolve, reject) => {
    //       axios
    //         .post(`${API_URL}/Artworks/search`, getState().params)
    //         .then((res) => {
    //           setState({
    //             totalCount: res.data.totalCount,
    //           });
    //           const data1 = res.data;
    //           setState({
    //             listNFT: data1.data,
    //           });
    //         }, []);
    //     });
    //   },
  },
  name: "StoreProfile",
});

export const useHookProfile = createHook(StoreProfile);
export const Container = createContainer(StoreProfile, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});

export const Subscriber = createSubscriber(StoreProfile);
