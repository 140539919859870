import { isMobile } from "react-device-detect";

import "./style.css";

const ChainList = () => {
  return (
    <>
      <div className="c-apply">
        <div className="title-chain">
          RAISE CAPITAL ACROSS ALL MAIN BLOCKCHAIN NETWORKS
        </div>
        <div>
          {isMobile ? (
            <>
              <div className="network-list1">
                <div className="box-img chain-m">
                  <img src="/images/ether.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/bnb.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/trx.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/polygon.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/fantom.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/apply/solana.svg" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/avalan.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/okc.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/dogechain.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/aptos.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/optimis.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/polkadot.svg?=v1" alt="" />
                </div>
                <div className="box-img chain-m">
                  <img src="/images/apply/arb.png" alt="" />
                </div>
              </div>
            </>
          ) : (
            <div className="list-chain-apply">
              <div className="network-list1">
                <div className="box-img">
                  <img src="/images/ether.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/bnb.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/trx.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/polygon.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/fantom.svg?=v1" alt="" />
                </div>
                <div className="box-img sol">
                  <img src="/images/apply/solana.svg" alt="" />
                </div>
              </div>
              <div className="network-list2">
                <div className="box-img">
                  <img src="/images/avalan.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/okc.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/dogechain.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/aptos.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/optimis.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/polkadot.svg?=v1" alt="" />
                </div>
                <div className="box-img">
                  <img src="/images/apply/arb.png" alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChainList;
