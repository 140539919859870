import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Tabs,
  Radio,
  Popover,
  Input,
  Select,
  Skeleton,
  Space,
  Collapse,
  Checkbox,
  Drawer,
  Empty,
} from "antd";
import { ButtonCommon } from "../../../components/common/styleCommon";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getListNFTMarket,
  getListAttribute,
  getListReport,
} from "../../../axios/marketNFT";
import Value from "./../../../components/Value";
import Loading from "./../../../components/Loading/index";
import "./style.css";
import { isMobile } from "react-device-detect";
import { useAccount } from "wagmi";

interface AttributeItem {
  name: string;
  type: number;
  items: { id: number; name: string }[];
}

interface CollectItem {
  type: number;
  ids: number[];
}
const MarketPlace = () => {
  const { address } = useAccount();
  let account: any = address
  const { Search } = Input;
  const { Option } = Select;
  const { Panel } = Collapse;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  let navigate = useNavigate();
  const gotoDetail = (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };
  const gotoMintNft = () => {
    navigate("/get-your-owner");
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadMore, setLoadMore] = useState<boolean>(false);
  const [valueSort, setValueSort] = useState<any>(1);

  const [listNFTs, setListNFTs] = useState<any>([]);
  const [attribute, setAttributes] = useState<any>([]);
  const [report, setReport] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeFill, setActiveFill] = useState<boolean>(false);
  const [classActiveFill, setClassActiveFill] = useState("");
  const [collect, setCollect] = useState<CollectItem[]>([]);
  // fillter
  const myRef = useRef<null | HTMLDivElement>(null);
  const handleClickFillter = () => {
    setActiveFill(!activeFill);
  };

  useEffect(() => {
    if (activeFill) {
      setClassActiveFill("activeFillter");
    } else {
      setClassActiveFill("");
    }
  }, [activeFill]);

  const onChangeCheckBox = (checkedValues: number[], type: number) => {
    setCollect((prevCollect) => {
      const existingCollect = prevCollect.find((item) => item.type === type);

      if (existingCollect) {
        if (checkedValues.length > 0) {
          existingCollect.ids = checkedValues;
          return [...prevCollect];
        } else {
          const updatedCollect = prevCollect.filter(
            (item) => item.type !== type
          );
          return updatedCollect;
        }
      } else {
        if (checkedValues.length > 0) {
          return [...prevCollect, { type, ids: checkedValues }];
        } else {
          return [...prevCollect];
        }
      }
    });
  };
  const handelClear = () => {
    setCollect([]);
    setVisible(false);
  };
  const handleChangeSort = (value: any) => {
    setValueSort(value);
  };
  const onChange = (key: string) => { };
  const handleSearch = (event: any) => {
    const { value } = event.target;
    setSearchKeyword(value);
  };
  let params = {
    keywork: searchKeyword,
    ownerAddress: "",
    collectionIds: collect,
    orderBy: valueSort,
    lastTime: 0,
  };
  const getListNFT = async () => {
    try {
      params = {
        ...params,
        keywork: searchKeyword,
        ownerAddress: account,
        collectionIds: collect,
        orderBy: valueSort,
        lastTime: 0,
      };
      setIsLoading(true);
      await getListNFTMarket(params).then((res: any) => {
        if (res) {
          setListNFTs(res?.data);
          setIsLoading(false);
        } else {
          setListNFTs([]);
        }
      });
    } catch (error) {
      console.error("error getListNFT", error);
      setIsLoading(false);
    }
  };
  const getAttribute = async () => {
    try {
      await getListAttribute().then((res: any) => {
        if (res) {
          setAttributes(res?.data);
        }
      });
    } catch (error) {
      console.error("error getListNFT", error);
    }
  };
  const getReport = async () => {
    try {
      await getListReport().then((res: any) => {
        if (res) {
          setReport(res?.data);
        }
      });
    } catch (error) {
      console.error("error getListNFT", error);
    }
  };

  const loadMore = async (lastTime: any, lastPrice: any) => {
    try {
      setLoadMore(true);
      const newParams = {
        ...params,
        keywork: searchKeyword,
        ownerAddress: account,
        collectionIds: [],
        orderBy: valueSort,
        lastTime: lastTime,
        lastPrice: lastPrice,
      };
      const response = await getListNFTMarket(newParams);
      if (response?.data) {
        const newList = response?.data;
        setListNFTs((prevList: any) => [...prevList, ...newList]);
        setLoadMore(false);
      } else {
        setLoadMore(false);
      }
    } catch (error) {
      setLoadMore(false);

      console.error("error load more", error);
    }
  };

  useEffect(() => {
    getListNFT();
  }, [collect, valueSort]);

  useEffect(() => {
    getAttribute();
    getReport();
  }, []);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getListNFT();
    }
  };

  const Infomarket = () => {
    return (
      <>
        <div className="content-info">
          <div className="list-info">
            <li>
              <div className="txt-top">Total volume</div>
              <div className="txt-bot">
                <div className="number">
                  {/* <Value value={1234567} /> */}
                  {report?.totalVol}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Floor price</div>
              <div className="txt-bot">
                <div className="number">
                  {/* <Value value={123456} unitafter=" BSCS" /> */}
                  {report?.floorPrice} BSCS
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Best offer</div>
              <div className="txt-bot">
                <div className="number">{report?.bestOffer} BSCS</div>
              </div>
            </li>
            <li>
              <div className="txt-top">Listed</div>
              <div className="txt-bot">
                <div className="number">{report?.listed}</div>
              </div>
            </li>
            <li>
              <div className="txt-top">Owner (Unique)</div>
              <div className="txt-bot">
                <div className="number">{report?.owner}</div>
              </div>
            </li>
          </div>
        </div>
      </>
    );
  };

  const content = (
    <div className="main-menu-mobile">
      <div className="drawer-menu">
        <Drawer
          title="Filter"
          className="drawer-mobile filter-nft"
          placement="right"
          onClose={onClose}
          visible={visible}
          destroyOnClose={true}
        >
          <div className="">
            <div className="">
              <div className="title-fill">Traits</div>
              <div className="item-fill">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <RightOutlined rotate={isActive ? 270 : 180} />
                  )}
                >
                  {attribute?.map((item: AttributeItem, index: number) => (
                    <Panel header={item?.name} key={index + 1}>
                      <p>
                        <Checkbox.Group
                          options={item?.items.map((subItem) => ({
                            label: subItem?.name,
                            value: subItem?.id,
                          }))}
                          onChange={(checkedValues) =>
                            onChangeCheckBox(
                              checkedValues as number[],
                              item.type as any
                            )
                          }
                        />
                      </p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
          <div className="footer-menu-mobile">
            <hr />
            <div className="price-ft mobile">
              <div className="price-l" style={{ width: "150px" }}>
                <ButtonCommon
                  onClick={handelClear}
                  background="rgba(243, 243, 244, 1)"
                  textColor="#1E2329"
                >
                  Clear all
                </ButtonCommon>
              </div>
              <div className="price-r" style={{ width: "150px" }}>
                <ButtonCommon onClick={onClose}>Done</ButtonCommon>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );

  return (
    <>
      <div className="main-megamarket-launch">
        <div className="main-market-launch-banner">
          <div className="home-market-banner">
            <img src="../images/nft-market-banner.png" alt="" />
          </div>
          <h3 className="title">BSCS NFT Marketplace</h3>
          <div className="desc">
            BSCS NFT are 10,000 unique collection NFT with proof of ownership
            stored on the blockchain
          </div>
          <div className="button-ido-stake button-ino">
            <button
              type="button"
              className="btn btn-get-owner w-140"
              onClick={() => gotoMintNft()}
            >
              Get your own
            </button>
            <button
              type="button"
              className="btn btn-stake w-140"
              onClick={() => window.open("https://opensea.io/collection/blue-the-unicorn-nft-collection-7")}
            >
              Visit OpenSea
            </button>
          </div>
        </div>
        <div className="container">
          <div className="main-content-market">
            <div className="info-megamarket-content">
              <Infomarket />
            </div>
            <div className="explore-market-content" ref={myRef}>
              <div className="title">
                Explore BSCS <span className="main-color">NFT</span>
              </div>
              <div className="tabs-market">
                {isMobile ? (
                  <div className="mobie-nft swap-filter">
                    <div className="search-project">
                      <Search
                        placeholder="Search Item"
                        onChange={handleSearch}
                      />
                      <div className="icon-search">
                        <img src="/images/imgido/search-normal.png" alt="" />
                      </div>
                    </div>

                    <div className="target-project">
                      <button
                        className="btn-filter"
                        type="button"
                        onClick={showDrawer}
                      >
                        <img src="../images/imgmarket/setting.png" alt="" />{" "}
                        Fillter
                      </button>
                    </div>
                    {content}
                    <div className="filter-project">
                      <Select
                        defaultValue="Sort by"
                        style={{ width: 125 }}
                        onChange={handleChangeSort}
                      >
                        <Option value={0}>Latest</Option>
                        <Option value={1}>Low - High</Option>
                        <Option value={2}>High - Low</Option>
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className="swap-filter">
                    <div className="filter-project">
                      <Select
                        defaultValue="Sort by"
                        style={{ width: 125 }}
                        onChange={handleChangeSort}
                      >
                        <Option value={1}>Low - High</Option>
                        <Option value={2}>High - Low</Option>
                        <Option value={0}>Latest</Option>

                      </Select>
                    </div>

                    <div className="search-project">
                      <Search
                        placeholder="Search Item"
                        onChange={handleSearch}
                        onKeyPress={handleKeyPress}
                      />
                      <div className="icon-search">
                        <img src="/images/imgido/search-normal.png" alt="" />
                      </div>
                    </div>

                    <div className="target-project">
                      <button
                        className={`btn-filter ${classActiveFill}`}
                        type="button"
                        onClick={handleClickFillter}
                      >
                        {classActiveFill ? (
                          <>
                            <img
                              src="../images/imgmarket/setting-ac.png"
                              alt=""
                            />{" "}
                          </>
                        ) : (
                          <>
                            <img src="../images/imgmarket/setting.png" alt="" />{" "}
                          </>
                        )}
                        Fillter
                      </button>
                    </div>
                  </div>
                )}

                <Tabs defaultActiveKey="0" onChange={onChange}>
                  <Tabs.TabPane tab="All" key="0">
                    <div className="round-tabs">
                      {activeFill ? (
                        <div className="modal-fillter">
                          <div className="content-fillter">
                            <div className="title-fill">Traits</div>
                            <div className="item-fill">
                              <Collapse
                                bordered={false}
                                expandIcon={({ isActive }) => (
                                  <RightOutlined
                                    rotate={isActive ? 270 : 180}
                                  />
                                )}
                              >
                                {attribute?.map(
                                  (item: AttributeItem, index: number) => (
                                    <Panel header={item?.name} key={index + 1}>
                                      <p>
                                        <Checkbox.Group
                                          options={item.items.map(
                                            (subItem) => ({
                                              label: subItem.name,
                                              value: subItem.id,
                                            })
                                          )}
                                          onChange={(checkedValues) =>
                                            onChangeCheckBox(
                                              checkedValues as number[],
                                              item.type
                                            )
                                          }
                                        />
                                      </p>
                                    </Panel>
                                  )
                                )}
                              </Collapse>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="columns">
                        {isLoading ? (
                          <>
                            <Skeleton active paragraph={{ rows: 3 }} />
                            <Skeleton active paragraph={{ rows: 3 }} />
                            <Skeleton active paragraph={{ rows: 3 }} />
                            <Skeleton active paragraph={{ rows: 3 }} />
                          </>
                        ) : (
                          <>
                            {listNFTs?.length > 0 ? (
                              <>
                                {" "}
                                {listNFTs?.map((data: any, index: any) => {
                                  return (
                                    <>
                                      <div className="colum w-3">
                                        <div className="guide-market">
                                          <div
                                            className="box-img"
                                            onClick={() =>
                                              gotoDetail(data?.queryKey)
                                            }
                                          >
                                            <img
                                              className="imgNft-market"
                                              src={data?.nftUrl}
                                              alt={data?.name}
                                            />
                                          </div>
                                          <div className="name-item">
                                            <div className="marquee-nft">
                                              <div>
                                                <span
                                                  onClick={() =>
                                                    gotoDetail(data?.queryKey)
                                                  }
                                                >
                                                  {data?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="artis-item">
                                            Unicorn NFT Collection
                                          </div>
                                          <div className="price-item">
                                            <span className="t-left">
                                              Current price:
                                            </span>
                                            <div className="t-right">
                                              <div className="icon">
                                                <img
                                                  src="./images/imgmarket/logoBSC.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="number d-flex">
                                                <Value
                                                  value={data?.price}
                                                  fontWeight={600}
                                                />{" "}
                                                BSCS
                                                {/* {data?.price || 0} BSCS */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {" "}
                                <div className="colum w-12">
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {listNFTs?.length > 0 && (
                      <div className="load-more-round">
                        <button
                          className="btn-loadmore cus"
                          disabled={isLoadMore}
                          type="button"
                          onClick={() =>
                            loadMore(
                              listNFTs[listNFTs?.length - 1].lastTime,
                              listNFTs[listNFTs?.length - 1].price
                            )
                          }
                        >
                          <Loading status={isLoadMore} content="Load More" />
                        </button>
                      </div>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MarketPlace;
