/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tabs, Tooltip } from "antd";
import { QuestionCircleOutlined, CheckOutlined } from "@ant-design/icons";
import IDOList from "./IDOList";
import bgMainheader from "./images/pools.png";
import { useFetchPublicPoolsData, usePollBlockNumber } from "../../store/hooks";
import { usePoolsIDO } from "../../store/hooks";
import ErrorPage from "../ErrorPage";
import "./style.css";
import { useHookPrice } from "../../components/common/Store";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";

const IDOPools = () => {
    const [state1, actions1] = useHookPrice();
    let navigate = useNavigate();

    usePollBlockNumber();
    useFetchPublicPoolsData();
    const { address } = useAccount();

    let account = address
    const { pools, userDataLoaded } = usePoolsIDO(account);

    let curentChain = localStorage.getItem("chain");
    const { TabPane } = Tabs;

    useEffect(() => {
        document.title = "Staking for IDO";
        document
            .getElementsByTagName("meta")
            .item(1)
            ?.setAttribute(
                "content",
                "Staking BSCS to join our IDO Guaranteed. Allocation on BSCS Launchpad."
            );
    }, [state1.chain]);

    const textTooltip = (
        <div>
            <div className="text-tooltip">
                <span className="icon">
                    <CheckOutlined />
                </span>
                <span className="text">Calculated based on current rates.</span>
            </div>
            <div className="text-tooltip">
                <span className="icon">
                    <CheckOutlined />
                </span>
                <span className="text">
                    All APY are estimation and by it does not represent guaranteed
                    returns.
                </span>
            </div>
        </div>
    );
    const contentTop = (
        <>
            <div className="main-round-pool">
                <div className="round-slide IDO">
                    <div className="item">
                        <span className="name">Project Token</span>
                    </div>
                    <div className="item">
                        <span className="name d-flex">
                            APY
                            <Tooltip overlayClassName="tooltip-cus" title={textTooltip}>
                                <p className="text-apr icon pd-left-3">
                                    <QuestionCircleOutlined />
                                </p>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="item">
                        <span className="name">Lock-up term</span>
                    </div>
                    <div className="item">
                        <span className="name">Total staked</span>
                    </div>
                    <div className="item">
                        <span className="name">Earned</span>
                    </div>
                </div>
            </div>
        </>
    );

    const handleTabChange = (key) => {
        if (key === "1") {
            navigate(`${"/launch-pool"}`);
        }
        if (key === "3") {
            window.open("https://stake.bscstation.finance/#/ido-pools", "_blank");
        }
    };
    const chainId = useChainId()
    return (
        <>
            {chainHeader(chainId) === "bep" ? (
                <>
                    <div
                        className="main-startpool"
                        style={{
                            background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
                        }}
                    >
                        <div className="container">
                            <div className="content-startpool">
                                <div className="top-startpool ido-pool">
                                    <div className="title">IDO Pool</div>
                                    <p className="desc">
                                        Staking BSCS to join our IDO guaranteed allocation on BSCS
                                        Launchpad.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container pd-top-20">
                        <div className="content-startpool">
                            <div className="bottom-startpool" role="table">
                                <div className="tabs-startpool">
                                    <Tabs
                                        className="tabs-round"
                                        defaultActiveKey="2"
                                        onChange={handleTabChange}
                                    >
                                        <TabPane tab="Live" key="1">
                                            <>
                                                {/* {contentTop}
                        {pools
                          .filter((pool) => pool.isFinished === false)
                          .map((pool, index) => (
                            <IDOList
                              key={index}
                              pool={pool}
                              userDataLoaded={userDataLoaded}
                            />
                          ))} */}
                                            </>
                                        </TabPane>
                                        <TabPane tab="Finished" key="2">
                                            <>
                                                {contentTop}
                                                {pools
                                                    .filter((pool) => pool.isFinished === false)
                                                    .map((pool, index) => (
                                                        <IDOList
                                                            key={index}
                                                            pool={pool}
                                                            userDataLoaded={userDataLoaded}
                                                        />
                                                    ))}
                                            </>
                                        </TabPane>
                                        <TabPane tab="Finished V1" key="3">
                                            <>
                                                {/* {contentTop}
                        {pools
                          .filter((pool) => pool.isFinished === true)
                          .map((pool, index) => (
                            <IDOList
                              key={index}
                              pool={pool}
                              userDataLoaded={userDataLoaded}
                            />
                          ))} */}
                                            </>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <ErrorPage />
                </>
            )}
        </>
    );
};
export default IDOPools;
