import React, { useEffect, memo } from "react";
import { useCookies } from "react-cookie";
import encodeAddress from "../../utils/encodeData";
import { Layout } from "antd";
import { useAccount } from "wagmi";

import Header from "../common/Header";
import Footer from "../common/Footer";

const { Content } = Layout;
function Main({ children }: any) {
    const { address } = useAccount();
    let account = address
    const [cookies, setCookie] = useCookies(["user", "account"]);
    useEffect(() => {
        if (account !== undefined && account) {
            setCookie("user", encodeAddress(account));
            setCookie("account", account);
        } else {
            setCookie("account", undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    return (
        <Layout>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </Layout>
    );
}

export default Main;
