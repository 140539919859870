/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import {
  Tabs,
  Steps,
  Table,
  Tooltip,
  Button,
  message,
  Modal,
  Collapse,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import { TIMELINE_STT } from "../index.d";
import { useHookIno } from "../Store-Ino";
import { useActiveWeb3React } from "../../../hooks";
import { _myBoxInfo, _openBox, getNfts } from "../utils";
import {
  useOpenMyBoxContract,
  useMyBoxContract,
} from "../../../hooks/useContract";
import { useAccount } from "wagmi";


function INO_BOTTOM(props: any) {
  // console.log("props: ", props.content);
  const { address } = useAccount();
  let account = address
  const [state, actions]: any = useHookIno();
  const [dataMyBox, setDataMyBox] = useState(0);

  const data = props.content.content;
  const orders = props.content?.whitelist?.myOrders;
  const contractBox = useMyBoxContract(state.inoItem.contractAddress);
  const { TabPane } = Tabs;
  const { Step } = Steps;
  const [currentItem, setCurrentItem] = useState("");

  const textMyNft = `My NFT(${state.myNFTs.length})`;
  const { Panel } = Collapse;

  const onChangeCollapse = (key: string | string[]) => {
    // console.log(key);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const contentSliderModal = (item: any) => {
    return (
      <>
        <div className="modal-name">{item.name}</div>
        <div className="modal-content">
          <div className="modal-img">
            <img src={item.imageUrl} alt="" />
          </div>
          <div className="modal-des">
            <span className="probability">
              NFT Id: <span className="probability-num">{item.nftId}</span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (item: any) => {
    setCurrentItem(item);
    setIsModalVisible(true);
    // console.log('item: ', item)
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeStep = (current: any) => {
    // setCurrent(current);
    // console.log('onChange:', current)
  };

  const ItemQuantity = ({ item, contractBox, account }: any) => {
    const [amount, setAmount] = useState(0);
    _myBoxInfo(contractBox, account, item.boxId).then((resData) => {
      setAmount(resData.toNumber());
    });
    return <a className="pro-table">{amount}</a>;
  };

  const RowOpenBox = ({ row, account, inoId, openBoxContract }: any) => {
    const [state, actions]: any = useHookIno();
    const [data, setData] = useState({});
    const contractBox: any = useMyBoxContract(state?.inoItem?.contractAddress);
    const [isLoading, setLoading] = useState(false);
    const contractOpenBox = useOpenMyBoxContract(openBoxContract);
    const { chainId, library }: any = useActiveWeb3React();

    const openBox = () => {
      setLoading(true);
      actions.openBox(inoId, row.boxId, account).then((res: any) => {
        if (res.data.succeeded) {
          const {
            boxSC,
            nftContract,
            nftId,
            mintable,
            nftUrl,
            attrs,
            openSignature,
          } = res.data.data;

          _openBox(
            contractOpenBox,
            boxSC,
            nftContract,
            row.boxId,
            nftId,
            mintable,
            nftUrl,
            attrs,
            openSignature
          ).then((resOpen) => {
            if (resOpen && resOpen.hash) {
              const interval = setInterval(function () {
                (async () => {
                  const resHash = await library.getTransactionReceipt(
                    resOpen.hash
                  );
                  if (resHash) {
                    clearInterval(interval);
                    if (resHash.status && resHash.blockNumber) {
                      actions
                        .afterOpenBox(
                          nftId,
                          inoId,
                          row.boxId,
                          account,
                          resOpen.hash
                        )
                        .then(() => {
                          // store.addNotification(addNotify('Open box successfully!', 'warning'))
                          message.success("Open box successfully!", 0.4);
                          setTimeout(() => {
                            setLoading(false);
                            window.location.reload();
                          }, 3000);
                        });
                    }
                  }
                })();
              }, 1000);
            } else if (resOpen && resOpen.data) {
              message.warning(resOpen.data.message, 0.4);
              // store.addNotification(addNotify(resOpen.data.message, 'warning'))
            } else {
              message.error("Fail Open Box", 0.4);
              // store.addNotification(addNotify('Fail Open Box', 'warning'))
            }
          });
        } else {
          setLoading(false);
          message.warning(res.data.message, 0.4);
          // store.addNotification(addNotify(res.data.message, 'warning'))
        }
      });
    };

    useEffect(() => {
      if (contractBox && account && state.inoItem?.boxId) {
        _myBoxInfo(contractBox, account, state.inoItem?.boxId).then(
          (resData) => {
            setData(resData.toNumber());
          }
        );
      }
    }, [contractBox, account, state.inoItem.boxId]);

    return (
      <a className="pro-table">
        {state.inoItem.whitelist?.myOrders &&
          state.inoItem.whitelist?.myOrders
            .filter((item: any) => item.boxId === row.boxId)
            .map((item: any, i: any) => {
              return (
                <>
                  {data === 0 && item.openStatus !== 3 ? (
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={() => openBox()}
                      className="button-open-box disble"
                    >
                      Open box
                      {isLoading ? <i className="fa fa-spinner fa-spin" /> : ""}
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={() => openBox()}
                      className="button-open-box"
                    >
                      Open box
                      {isLoading ? <i className="fa fa-spinner fa-spin" /> : ""}
                    </button>
                  )}
                </>
              );
            })}
      </a>
    );
  };

  const rules = (item: any) => {
    return <div dangerouslySetInnerHTML={{ __html: item }} />;
  };

  const contentTabsRules = (
    <>
      <div className="content-tabs-rules">{rules(data?.rules)}</div>
    </>
  );

  const contentTheBox = (
    <>
      <div className="content-the-box">
        <div className="table-previus">
          <table className="main-table">
            <tr className="tr-top">
              <th>No.</th>
              <th> Name</th>
              <th>Probability</th>
              <th>Amount</th>
              <th>Description</th>
            </tr>

            {data &&
              data.boxInfos &&
              data.boxInfos.map((item: any, i: any) => (
                <tr className="tr-bottom">
                  <td>
                    <div className="type-ss">{i + 1}</div>
                  </td>
                  <td>
                    <div className="box-pro-name">
                      <div className="icon-logo">
                        {item.imageUrl !== null ? (
                          <img alt="" src={item.imageUrl} />
                        ) : (
                          ""
                        )}
                        {/* <img src="/images/imgino/pre-img.png" alt="" /> */}
                      </div>
                      <div className="name-pro">{item.name}</div>
                    </div>
                  </td>
                  <td>
                    <div className="type-ss">{item.probability}</div>
                  </td>
                  <td>
                    <div className="type-sl">{item.amount}</div>
                  </td>
                  {item.description !== null || item.description !== "" ? (
                    <td>
                      <div className="type-sl">{item.description}</div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </table>
        </div>
      </div>
    </>
  );

  const HeaderTopIdo = (props: any) => {
    const { item, index } = props;
    return (
      <div className="content-header-panel mobile-ino">
        <div className="project-name-m">{index + 1}</div>
        <div className="project-name-m img">
          {item.imageUrl !== null ? <img alt="" src={item.imageUrl} /> : ""}
          {/* <img
            src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/97349462b9d91c4bd0631abb779f516a61cdbe60b93b2ce590.png"
            alt=""
          /> */}
          <span className="name-round-m">{item.name}</span>
        </div>
        <div className="token-name-m cus">{item.probability}</div>
      </div>
    );
  };

  const ItemMobile = ({ item }: any) => {
    return (
      <div className="content-joined-mobile ino-mobile">
        <div className="colum-content">
          <div className="item-content">
            <div className="text-l">Amount :</div>
            <div className="text-r">{item.amount}</div>
          </div>
          <div className="item-content">
            <div className="text-l">Description :</div>
            <div className="text-r">
              {item.description !== null || item.description !== "" ? (
                <div className="type-sl">{item.description}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [current, setCurrent]: any = useState(0);

  const onChange = (value: any) => {
    console.log("onChange:", value);
    // setCurrent(value);
  };

  useEffect(() => {
    for (let i = 0; i <= state.inoItem?.content?.timeLines?.length; i++) {
      if (
        state.inoItem?.content?.timeLines[i]?.activeStatus ===
        TIMELINE_STT.PROCESS
      ) {
        setCurrent(i);
      }
    }
  }, [state.inoItem]);

  let listBoxId: any = [];
  const [totalBox, setTotalBox] = useState(0);
  const textOrder = `My Box(${dataMyBox})`;

  for (let i = 0; i < state.inoItem?.boxTypes?.length; i++) {
    listBoxId.push(state.inoItem.boxTypes[i].boxId);
  }

  useEffect(() => {
    if (contractBox && account && state.inoItem.boxId && listBoxId) {
      setDataMyBox(0);
      listBoxId.forEach((ele: any) => {
        _myBoxInfo(contractBox, account, ele).then((resData) => {
          // setTotalBox(totalBox + resData.toNumber());
          // console.log("totalBox111111111: ", totalBox);
          setDataMyBox((dataMyBox) => dataMyBox + resData.toNumber());
        });
      });
    }
  }, [contractBox, account]);

  const contractOpenBox = useOpenMyBoxContract(state.inoItem.openBoxContract);
  useEffect(() => {
    // console.log("contractOpenBox>>>", contractOpenBox);
    if (contractOpenBox && account) {
      getNfts(contractOpenBox, account).then((res) => {
        // console.log("getNfts>>>", res, res.toString().split(","));
        let listNft = res.toString().split(",");
        listNft = listNft.map((item: any) => {
          return parseInt(item);
        });
        actions.getMyNfts(listNft, account);
      });
    }
  }, [contractOpenBox, account]);

  const contentTimeLine = (
    <>
      <div className="content-timeline">
        {/* @ts-ignore this lib is incompatible with react18  */}
        <Steps current={current}>
          {data?.timeLines &&
            data?.timeLines.map((item: any, i: any) => (
              <Step
                title={item.name}
                subTitle={item.status.toUpperCase()}
                description={item.shorthDecs}
              // status={
              //   item.activeStatus === TIMELINE_STT.COMPLETED
              //     ? "finish"
              //     : item.activeStatus === TIMELINE_STT.PROCESS
              //     ? "process"
              //     : item.activeStatus === TIMELINE_STT.PENDING
              //     ? "wait"
              //     : "error"
              // }
              />
            ))}
        </Steps>
      </div>
    </>
  );

  const toolTips = (row: any) => (
    <>
      <div className="box-tooltips">
        <div className="rarity">
          {state.inoItem.whitelist?.myOrders &&
            state.inoItem.whitelist?.myOrders
              .filter((item: any) => item.boxId === row.boxId)
              .map((item: any, i: any) => {
                return (
                  <>
                    {item && item.attr.length === 0
                      ? "TBA"
                      : item.attr.map((item: any, i: any) => {
                        return (
                          <div className="number-rarity">
                            <span className="low">{item.percent} %</span>{" "}
                            {item.name}
                          </div>
                        );
                      })}
                  </>
                );
              })}
        </div>
      </div>
    </>
  );

  const columnsOrder = [
    {
      title: "No.",
      dataIndex: "no",
      render: (text: any, row: any, index: any) => {
        return {
          children: <a className="pro-table">{index + 1}</a>,
        };
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: any, row: any, index: any) => {
        return {
          children: (
            <div className="name-text">
              {text}
              <div className="tooltips-lick">
                <Tooltip title={toolTips(row)} placement="right">
                  Hover here to see the open rate
                </Tooltip>
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: "Remaining quantity",
      dataIndex: "Quantity",
      render: (text: any, row: any, index: any) => {
        return {
          children: (
            <ItemQuantity
              item={row}
              contractBox={contractBox}
              account={account}
            />
          ),
        };
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text: any, row: any, index: any) => {
        return {
          children: (
            <RowOpenBox
              row={row}
              inoId={state.inoItem.id}
              openBoxContract={state.inoItem.openBoxContract}
              account={account}
            />
          ),
        };
      },
    },
  ];

  const order = (datas: any) => {
    return <Table columns={columnsOrder} dataSource={datas} size="middle" />;
  };

  return (
    <>
      <div className="tab-round-ino">
        <Tabs className="tabs-ino" defaultActiveKey="3" onChange={onChange}>
          <TabPane tab="Rules" key="1">
            {contentTabsRules}
          </TabPane>
          <TabPane tab="What’s in the box" key="2">
            {isMobile ? (
              <>
                <div className="collapse-mobile-ido">
                  <div className="table-mobile ino">
                    <div className="title-rounds">No.</div>
                    <div className="title-rounds">Project Name</div>
                    <div className="title-rounds">Probability</div>
                  </div>
                  {/* @ts-ignore this lib is incompatible with react18  */}
                  <Collapse
                    defaultActiveKey={["index"]}
                    onChange={onChangeCollapse}
                  >
                    {data &&
                      data.boxInfos &&
                      data.boxInfos.map((item: any, index: any) => (
                        // @ts-ignore this lib is incompatible with react18
                        <Panel
                          header={<HeaderTopIdo item={item} />}
                          key={index.toString()}
                          className="round-ido-m"
                        >
                          <ItemMobile
                            item={item}
                            account={account}
                            index={index}
                          />
                        </Panel>
                      ))}
                  </Collapse>
                </div>
              </>
            ) : (
              <>{contentTheBox}</>
            )}
          </TabPane>
          <TabPane tab="Timeline" key="3">
            {contentTimeLine}
          </TabPane>
          <TabPane tab={textOrder} key="4">
            {order(orders)}
          </TabPane>
          <TabPane tab={textMyNft} key="5">
            <div className="box-table">
              <table>
                <tr>
                  <th>No.</th>
                  <th>NFT ID</th>
                  <th>Images</th>
                  <th>Hash</th>
                </tr>

                {state.myNFTs.map((item: any, i: any) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.nftId}</td>
                      <td>
                        <Button
                          className="button-show-slider"
                          type="primary"
                          onClick={showModal}
                        >
                          <div className="img-nft">
                            <img src={item.imageUrl} alt="" />
                          </div>
                        </Button>
                      </td>
                      <td className="tap-hash">
                        <span>
                          {item.txHash &&
                            `${item.txHash.substring(
                              0,
                              8
                            )}...${item.txHash.substring(
                              0,
                              8,
                              item.txHash.length
                            )}`}
                        </span>
                        <CopyToClipboard
                          text={item.txHash}
                          onCopy={() => {
                            message.success("Successfully !", 0.4);
                          }}
                        >
                          <span className="imgCP">
                            <img
                              src="/images/copy-v3.png"
                              className="btn-copied"
                              alt=""
                            />
                          </span>
                        </CopyToClipboard>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </TabPane>
        </Tabs>
      </div>

      {/* @ts-ignore this lib is incompatible with react18  */}
      <Modal
        className="custom-ino"
        title="Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Slider {...settings}>
          {state.myNFTs.map((item: any, i: any) => {
            return (
              <>
                <div className="item-slider-modal">
                  {contentSliderModal(item)}
                </div>
              </>
            );
          })}
        </Slider>
      </Modal>
    </>
  );
}

export default INO_BOTTOM;
