/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Modal, Input, Checkbox, Tooltip, Radio, message } from "antd";
import abiStakePoolNFT from "../../../abi/abiStakePoolNFT.json";
import { useContract } from "../../../hooks/useContract";
import abiBEP20 from "../../../abi/abiBEP20.json";
import { InfoCircleFilled, LinkOutlined } from "@ant-design/icons";
import { useHookInoPoolDetails } from "./Store-Ino-Pool-Details";
import {
  _stakePeriodAPRs,
  _approveToken,
  _stake,
  _getTokenAmount,
} from "./utils";
import { useAccount } from "wagmi";


const ItemData = ({
  contractStakePoolNFT,
  setPeriod,
  item,
  i,
  amount,
  lpStake,
  setPoolContract,
}: any) => {
  const [aprEarning, setAprEarning]: any = useState(0);
  const [lpAmount, setLpAmount]: any = useState(0);
  const [tip, setTip] = useState([]);

  const [state, actions] = useHookInoPoolDetails();
  const listPoolFee = state.inoListPoolDetails.poolFees;

  const text = () => {
    listPoolFee.forEach((obj: any) => {
      //  console.log('obj: ', obj )
      if (obj.poolContract === item?.poolContract) {
        setTip(obj.fees);
      }
    });
  };

  const titleTooltipsNew = (arrText: any) => {
    // console.log('arrText: ', arrText)
    return (
      <>
        <div className="main-tooltips">
          <div className="title-tips">Unstaking fee:</div>
          <ul className="list-tips">
            {arrText?.length > 0
              ? arrText?.map((itemFee: any, j: any) => (
                <>
                  <li>
                    Within{" "}
                    <b>
                      {" "}
                      {(itemFee.unstakeDay / 86400).toFixed(0)} days:{" "}
                      {itemFee.fee}%{" "}
                    </b>
                  </li>
                </>
              ))
              : "TBA"}
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    text();
    if (contractStakePoolNFT) {
      _stakePeriodAPRs(contractStakePoolNFT, item.period).then((res) => {
        // console.log('aprEarning : ',  res.toString())
        setAprEarning(res.toString());
      });
    }
  }, [contractStakePoolNFT?.address]);

  useEffect(() => {
    if (contractStakePoolNFT && lpStake) {
      // console.log('check LP' )
      // _getTokenAmount(contractStakePoolNFT, parseInt(amount)).then((res) => {
      _getTokenAmount(contractStakePoolNFT, amount * 1e6).then((res) => {
        // console.log('num res LP: ',res )

        // console.log('res.toNumber(): ',res.toNumber()/1e6 )
        setLpAmount((res.toNumber() / 1e6).toFixed(2));
      });
    }
  }, [amount, contractStakePoolNFT]);

  // console.log('period, aprEarning: ',item.period, aprEarning )
  //  console.log('lpAmount: ',lpAmount )

  const yearToSeconds = 31556926;
  const PERCENT_MUL = 100;
  const PERCENT_DIV = 10000;

  const result = lpStake
    ? ((lpAmount || 0) *
      parseInt(item.period) *
      parseInt(aprEarning) *
      PERCENT_MUL) /
    PERCENT_DIV /
    yearToSeconds
    : ((amount || 0) *
      parseInt(item.period) *
      parseInt(aprEarning) *
      PERCENT_MUL) /
    PERCENT_DIV /
    yearToSeconds;

  const setData = (data: any) => {
    // console.log('data: ', data.poolContract)
    setPeriod(data.period);
    setPoolContract(data.poolContract);
  };

  return (
    <>
      <div className="item-bottom">
        <Radio value={i} onChange={() => setData(item)}>
          <div className="text">{(item.period / 86400).toFixed()} Days</div>
          <div className="text">{item.apr} %</div>
          <div className="text" style={{ paddingLeft: "10px" }}>
            {result.toFixed(2)}
          </div>
          <div className="text" style={{ marginTop: "18px" }}>
            <Tooltip
              overlayClassName="tooltip-textfee"
              placement="topRight"
              title={titleTooltipsNew(tip)}
            >
              <InfoCircleFilled />
            </Tooltip>
          </div>
          {/* <div className="text">{item} %</div> */}
        </Radio>
      </div>
    </>
  );
};
const ModalStaking = ({ amountTokenStaking, setAmountTokenStaking }: any) => {
  const { address } = useAccount();
  let account = address
  const [state, actions] = useHookInoPoolDetails();
  // console.log(state.inoListPoolDetails)
  const [balance, setBalance]: any = useState(0);
  const [amount, setAmount]: any = useState(0);
  const [isCheck, setIsCheck]: any = useState(false);
  const [period, setPeriod]: any = useState(0);
  const [poolContract, setPoolContract]: any = useState();
  const [isApprove, setApprove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const contractStakePoolNFT = useContract(state.inoListPoolDetails.poolContract, abiStakePoolNFT)
  const contractStakePoolNFT = useContract(poolContract, abiStakePoolNFT);

  // console.log('contractStakePoolNFT: ', contractStakePoolNFT)

  // const tokenContract = useContract(state.inoListPoolDetails?.tokenContract, abiBEP20) // get from API
  const tokenContract = useContract(
    state.inoListPoolDetails?.tokenContract,
    abiBEP20
  ); // get from API
  const handleCancel = () => {
    actions.updateShowStake(false);
  };

  const [value, setValue] = React.useState(0);

  const onChange = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    if (state.inoListPoolDetails?.poolAprs) {
      setPeriod(state.inoListPoolDetails?.poolAprs[0].period);
      setPoolContract(state.inoListPoolDetails?.poolAprs[0].poolContract);
    }
  }, [
    state.inoListPoolDetails?.poolAprs,
    state.inoListPoolDetails?.poolContract,
  ]);

  const approve = async () => {
    setIsLoading(true);
    await _approveToken(tokenContract, poolContract)
      .then((res) => {
        res.wait().then((res1: any) => {
          if (res1 !== null) {
            message.success("Approved successfully!!! ");
            setIsLoading(false);
            setApprove(true);
          } else {
            setIsLoading(false);
          }
        });
        // if (res.hash !== null) {
        //     console.log('res approve btn: ', res)
        //     setTimeout(() => {
        //         store.addNotification(addNotify('Approve successfully!', 'success'))
        //         setIsLoading(false)
        //         setApprove(true)
        //     }, 10000)
        // } else {
        //     setIsLoading(false)
        // }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const stake = async () => {
    // console.log('contractStakePoolNFT', contractStakePoolNFT?.address)
    // console.log('amount', amount)
    setIsLoading(true);
    try {
      await _stake(contractStakePoolNFT, amount, `${period}`).then((res) => {
        // console.log('res: ',res )
        // console.log('amount: ',amount )
        if (res.hash) {
          setTimeout(() => {
            message.success("Stake successfully!!! ");
            setIsLoading(false);
            setAmountTokenStaking(amountTokenStaking + parseFloat(amount));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }, 20000);
        } else {
          setIsLoading(false);
          message.warning(res.data.message);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tokenContract && account && poolContract) {
      tokenContract.allowance(account, poolContract).then((res: any) => {
        if (res.toString() / 1e18 > 0) {
          // console.log(' res isApprove f5: ', res.toString() / 1e18)
          setApprove(true);
        } else {
          setApprove(false);
        }
      });
      tokenContract.balanceOf(account).then((res: any) => {
        setBalance(res.toString() / 1e18);
        // console.log('Balance: ',res.toString()/ 1e18)
      });
    }
  }, [tokenContract, account, poolContract]);

  // console.log('isApprove: ', isApprove)

  const getMax = () => {
    setAmount(Math.floor(parseFloat(balance) * 100) / 100);
  };

  const headerModal = (
    <>
      <div className="main-header-modal">
        <h3 className="title">Stake in pool</h3>
        <p className="desc">Stake {state.inoListPoolDetails?.earnSymbol}</p>
      </div>
    </>
  );
  // const titleTooltips = (
  //   <>
  //     <div className="main-tooltips">
  //       <div className="title-tips">Unstaking fee:</div>
  //       <ul className="list-tips">
  //         {state.inoListPoolDetails?.poolFees?.length > 0 &&
  //           state.inoListPoolDetails?.poolFees.map((item, i) => (
  //             <>
  //               <li>
  //                 Within{' '}
  //                 <b>
  //                   {' '}
  //                   {(item.unstakeDay/86400).toFixed(4)} days: {item.fee}%{' '}
  //                 </b>
  //               </li>
  //             </>
  //           ))}
  //       </ul>
  //     </div>
  //   </>
  // )

  const contentModal = (
    <>
      <div className="main-body-modal">
        <div className="staking-amount">
          <div className="title-staking">Staking amount</div>
          <div className="amount-avaiable">
            <span className="main-color">
              {Math.floor(parseFloat(balance) * 100) / 100}
            </span>{" "}
            {state.inoListPoolDetails?.depositSymbol} Available
          </div>
        </div>
        <div className="input-amount">
          <Input value={amount} onChange={(e) => setAmount(e.target.value)} />
          <div className="name-token-staking">
            {state.inoListPoolDetails?.depositSymbol}
          </div>
          <div className="button-max">
            <button type="button" onClick={() => getMax()}>
              Max
            </button>
          </div>
        </div>
        {state.inoListPoolDetails.addLP !== null ? (
          <div className="getLinkLP">
            <a
              className="textLP"
              href={state.inoListPoolDetails?.addLP?.linkAddLp}
              rel="noreferrer"
              target="_blank"
            >
              {state.inoListPoolDetails?.addLP?.name}

              {/* <LinkOutlined /> */}
            </a>
            <a
              href={state.inoListPoolDetails?.addLP?.linkAddLp}
              rel="noreferrer"
              target="_blank"
              style={{ display: "flex" }}
            >
              <img
                className="iconLP"
                src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/64b987078fec8d88026795de08f5acf1bd74b87da319a40281.png"
                alt=""
              />
            </a>
          </div>
        ) : (
          ""
        )}

        <div className="choose-earning">
          <div className="title-choose">Choose your earning</div>

          <div className="table-earning">
            <div className="item-top">
              <div className="text">Period</div>
              <div className="text">APR</div>
              <div className="text">Earning</div>
            </div>

            <Radio.Group onChange={onChange} value={value}>
              {state.inoListPoolDetails?.poolAprs?.length > 0 &&
                state.inoListPoolDetails?.poolAprs.map((item: any, i: any) => {
                  return (
                    <ItemData
                      contractStakePoolNFT={contractStakePoolNFT}
                      i={i}
                      setPeriod={setPeriod}
                      item={item}
                      amount={amount}
                      lpStake={state.inoListPoolDetails.isLPStake}
                      setPoolContract={setPoolContract}
                    />
                  );
                })}
            </Radio.Group>
          </div>
        </div>
        <div className="stake-rules">
          <div className="rules-title">Staking Rules</div>
          <ul className="list-rules">
            <li>
              Stake token in smart contract during staking period for passive
              income.
            </li>
            <li>
              Token distribution will be released after the smart contract ends.
            </li>
            <li>
              During locking period,{" "}
              {/* <Tooltip title={titleTooltips}>
                <span className="tooltips">unstaking fee</span>
              </Tooltip>{' '} */}
              unstaking fee will be applied and you will withdraw without
              earnings.
            </li>
          </ul>
          <Checkbox onChange={() => setIsCheck(!isCheck)} checked={isCheck}>
            I understand the staking rules.
          </Checkbox>
        </div>
        <div className="button-select">
          {isApprove ? (
            <button
              onClick={() => stake()}
              disabled={!(parseInt(amount) > 0 && isCheck) || isLoading}
              type="button"
              className="btn-select"
            >
              Stake
              {isLoading ? <i className="fa fa-spinner fa-spin" /> : ""}
            </button>
          ) : (
            <button
              onClick={() => approve()}
              disabled={!(parseInt(amount) > 0 && isCheck) || isLoading}
              type="button"
              className="btn-select"
            >
              Approve
              {isLoading ? <i className="fa fa-spinner fa-spin" /> : ""}
            </button>
          )}
        </div>
      </div>
    </>
  );
  // console.log('balance: ', balance)

  return (
    <>
      <div className="modal-staking">
        {/* @ts-ignore this lib is incompatible with react18  */}
        <Modal
          title={headerModal}
          className="modal-staking-main"
          visible={state.isShowModalStake}
          onCancel={handleCancel}
        >
          <div className="modal-content-staking">{contentModal}</div>
        </Modal>
      </div>
    </>
  );
};
// comkit
export default ModalStaking;
