/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Slider, Input, Spin, Select, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { useHookIno } from "./Store-Ino";
import bgMainheader from "./images/banner-ino.png";
import "./style.css";
import { useContract } from "../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS } from "../../constants";
import abiBSCS from "../../abi/abiBSCS.json";
import Value from "../../components/Value";
import { STATUS, NETWORK } from "./index.d";
import { useAccount } from "wagmi";


const noData = (
  <>
    <img className="no-data" src="/images/empty.png" alt="" />
    <p>Currently there is no projects</p>
  </>
);
const ether = (
  <>
    <img src="/images/ether.png" alt="" />
  </>
);
const poly = (
  <>
    <img src="/images/poly.png" alt="" />
  </>
);
const bsc = (
  <>
    <img src="/images/bnb-bnb-logo.png" alt="" />
  </>
);

const fillIconChain = (network: any) => {
  switch (network) {
    case NETWORK.BSC:
      return bsc;
      break;
    case NETWORK.Poly:
      return poly;
      break;
    case NETWORK.Ethereum:
      return ether;
      break;
  }
};

const fillNetwork = (network: any) => {
  switch (network) {
    case NETWORK.BSC:
      return "Binance Smart Chain";
      break;
    case NETWORK.Poly:
      return "Polygon";
      break;
    case NETWORK.Ethereum:
      return "Ethereum";
      break;
  }
};

const ContentBannerIno = (data: any) => {
  let navigate = useNavigate();
  const gotoBuyBscs = () => {
    window.open(
      "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D"
    );
  };

  const gotoStakeNow = () => {
    navigate(`${"/launch-pool"}`);
  };
  const howtoStake = () => {
    window.open(
      "https://docs.bscs.finance/defi/launchpad/ido/staking-for-ido"
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openLink = (num: any) => {
    setIsModalVisible(false);
    switch (num) {
      case 1: {
        window
          .open(
            "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
            "_blank"
          )
          ?.focus();
        break;
      }
      case 2: {
        window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
        break;
      }
      case 3: {
        window
          .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
          ?.focus();
        break;
      }
      case 4: {
        window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
        break;
      }
    }
  };
  return (
    <>
      <div className="top-content-ino">
        <div className="wrap-butterfly">
          <div className="title-ino">BSCS INO Launchpad</div>
        </div>

        <div className="balance-ino">
          <p className="desc">Your balance:</p>
          <div className="number">
            <div className="d-flex justify-content-center">
              <Value value={data.yourBSCS} /> BSCS
            </div>
          </div>
        </div>
        <div className="button-ido-stake button-ino">
          <button
            type="button"
            className="btn btn-stake-pool"
            onClick={showModal}
          // onClick={() => gotoBuyBscs()}
          >
            Buy BSCS
          </button>
          <button
            type="button"
            className="btn btn-stake "
            onClick={() => gotoStakeNow()}
          >
            Stake Now
          </button>
        </div>
        <div className="how-stake">
          <button
            type="button"
            className="btn-how-stake"
            onClick={() => howtoStake()}
          >
            How to Stake {">>"}
          </button>
        </div>
        <div className="balance-ino">
          <p className="desc">Your staked amount:</p>
          <div className="number">
            <div className="d-flex justify-content-center">
              <Value value={data.stake} /> BSCS
            </div>
          </div>
        </div>
        <div className="slide-rank-ino">
          <Slider
            min={0}
            max={90000}
            defaultValue={data.stake}
            tooltipVisible
            disabled
          />
        </div>
        <div className="tier-rank">
          <div className="item-tier">
            <div className="content-tier">
              <div className="box-icon">
                <img src="/images/imgino/tier-ino-1.png" alt="" />
              </div>
              <div className="name-tier">Tier 1</div>
              <div className="number-tier">
                <div className="d-flex justify-content-center">
                  <Value decimals={0} value={30000} /> BSCS
                </div>
              </div>
            </div>
          </div>
          <div className="item-tier">
            <div className="content-tier">
              <div className="box-icon">
                <img src="/images/imgino/tier-ino-2.png" alt="" />
              </div>
              <div className="name-tier">Tier 2</div>
              <div className="number-tier">
                {" "}
                <div className="d-flex justify-content-center">
                  <Value decimals={0} value={60000} /> BSCS
                </div>
              </div>
            </div>
          </div>
          <div className="item-tier">
            <div className="content-tier">
              <div className="box-icon">
                <img src="/images/imgino/tier-ino-3.png" alt="" />
              </div>
              <div className="name-tier">Tier 3</div>
              <div className="number-tier">
                {" "}
                <div className="d-flex justify-content-center">
                  <Value decimals={0} value={90000} /> BSCS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* @ts-ignore this lib is incompatible with react18 */}
      <Modal
        title="Buy BSCS"
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="modal-link"
        width={650}
      >
        <div className="link-buy-bscs" onClick={() => openLink(1)}>
          <p> 🥞 Pancakeswap.finance </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(2)}>
          <img className="img-link" alt="" src="/images/coins/gate.png?v=152" />
          <p>
            SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
            Trading-Gate.io
          </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(3)}>
          <img className="img-link" alt="" src="/images/coins/mxc.png?v=152" />
          <p>
            MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
            Quotes{" "}
          </p>
        </div>
      </Modal>
    </>
  );
};

const ItemUpcoming = (data: any) => {
  const item = data.itemINO;
  let navigate = useNavigate();
  const viewDetail = (keywork: any) => {
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };
  return (
    <>
      <div className="colum w-25">
        <div
          className="content-upcoming"
          onClick={() => viewDetail(item.keywork)}
        >
          <div className="box-img">
            <div className="img-big">
              <img src={item.logoUrl} alt="" />
              <span className="icon-img">{fillIconChain(item.network)}</span>
              <span className="allow-open-ino">Allowlist Open</span>
            </div>
            <div className="img-small">
              <img src={item.symbolUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.totalSale} NFTs</span>
          </div>
          <p className="desc">{item.description}</p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">${item.totalRaise}</span>
            </li>
            <li>
              <span className="left">Participants:</span>
              <span className="right">{item.participants}</span>
            </li>
          </ul>
          <div
            className="title-upcoming-sale"
            onClick={() => viewDetail(item.keywork)}
          >
            NFT Sale
          </div>
        </div>
      </div>
    </>
  );
};

const ItemOpen = (data: any) => {
  const item = data.itemINO;
  let navigate = useNavigate();
  const viewDetail = (keywork: any) => {
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };

  return (
    <>
      <div className="colum w-25">
        <div
          className="content-upcoming"
          onClick={() => viewDetail(item.keywork)}
        >
          <div className="box-img">
            <div className="img-big">
              <img src={item.logoUrl} alt="" />
              <span className="icon-img">{fillIconChain(item.network)}</span>
              <span className="allow-open-ino">Allowlist Open</span>
            </div>
            <div className="img-small">
              <img src={item.symbolUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.totalSale} NFTs</span>
          </div>
          <p className="desc">{item.description}</p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">$ {item.totalSale}</span>
            </li>
            <li>
              <span className="left">Participants:</span>
              <span className="right">{item.participants}</span>
            </li>
          </ul>
          <div
            className="title-upcoming-sale"
            onClick={() => viewDetail(item.keywork)}
          >
            NFT Sale
          </div>
        </div>
      </div>
    </>
  );
};

const ItemPreviousMobie = (data: any) => {
  const item = data.itemINO;
  let navigate = useNavigate();
  const viewDetail = (keywork: any) => {
    // console.log('id: ', id)
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };
  return (
    <>
      <div className="colum w-25">
        <div className="content-upcoming">
          <div className="box-img">
            <div className="img-big">
              <img src={item.logoUrl} alt="" />
              <span className="icon-img">{fillIconChain(item.network)}</span>
              <span className="allow-open-ino">Allowlist Open</span>
            </div>
            <div className="img-small">
              <img src={item.symbolUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.totalSale} NFTs</span>
          </div>
          <p className="desc">{item.description}</p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">$ {item.totalSale}</span>
            </li>
            <li>
              <span className="left">Participants:</span>
              <span className="right">{item.participants}</span>
            </li>
          </ul>
          <div
            className="title-upcoming-sale"
            onClick={() => viewDetail(item.keywork)}
          >
            NFT Sale
          </div>
        </div>
      </div>
    </>
  );
};

const ItemPrevious = (data: any) => {
  const item = data.itemINO;
  let navigate = useNavigate();
  const viewDetail = (keywork: any) => {
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };

  return (
    <>
      <tr className="tr-bottom">
        <td>
          <div
            className="box-pro-name"
            onClick={() => viewDetail(item.keywork)}
          >
            <div className="icon-logo">
              <img src={item.logoUrl} alt="" />
            </div>
            <div className="name-pro">
              {item.name} <span className="type">{item.inoType}</span>
            </div>
          </div>
        </td>
        <td>
          <div className="type-sc">Land Sale</div>
        </td>
        <td>
          <div className="type-sl">{item.participants}</div>
        </td>
        <td>
          <div className="type-sl d-flex">
            <Value decimals={0} value={item.totalSale} />BOX
          </div>
        </td>
        <td>
          <div className="type-sl">
            {fillNetwork(item.network)}{" "}
            <span className="icon-img">{fillIconChain(item.network)}</span>
          </div>
        </td>
        <td>
          <div className="type-pr">
            {item.price} {item.symbol}/BOX
          </div>
        </td>
      </tr>
    </>
  );
};

const InoLaunchPad = () => {
  const [state, actions]: any = useHookIno();
  const { address } = useAccount();
  let account = address
  const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);
  const { Search } = Input;
  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [valueSort, setValueSort] = useState("All");
  const [islistClose, SetlistClose] = useState("");
  const listIno: any = state.inoList;
  const listInoMore: any = state.inoListMore;
  const antIcon = <LoadingOutlined style={{ fontSize: 25 }} spin />;

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  const paramsMore = {
    lastDate: listIno.length > 0 ? listIno[listIno.length - 1].openDate : null,
  };

  const handleLoadMore = () => {
    if (listInoMore.length > 0) {
      paramsMore.lastDate = listInoMore[listInoMore.length - 1].openDate;
    }
    actions.getInfoINOMore(paramsMore).then((data: any) => {
      if (data.length === 0) setEnableLoadMore(false);
    });
  };
  const handleLoadMoreMobie = () => {
    if (listInoMore.length > 0) {
      paramsMore.lastDate = listInoMore[listInoMore.length - 1].openDate;
    }
    actions.getInfoINOMore(paramsMore).then((data: any) => {
      // if (data.length === 0) setEnableLoadMore(false)
      try {
        setLoadingSpin(true);
        setTimeout(() => {
          setLoadingSpin(false);
        }, 500);
      } catch (error) {
        setLoadingSpin(false);
      }
    });
  };
  const [amountBscs, setAmountBscs] = useState(0);

  useEffect(() => {
    if (account) {
      const param = {
        ownerAddress: account,
      };
      actions.getTierINO(param);
    }
    getBscs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const listClose =
    state.inoList?.length > 0 &&
    state.inoList?.filter((inoItem: any) => inoItem.status === STATUS.CLOSE);

  const listOpen =
    state.inoList?.length > 0 &&
    state.inoList?.filter((inoItem: any) => inoItem.status === STATUS.OPEN);
  const listComming =
    state.inoList?.length > 0 &&
    state.inoList?.filter(
      (inoItem: any) =>
        inoItem.status === STATUS.COMING || inoItem.status === STATUS.PENDING
    );
  useEffect(() => {
    if (valueSort !== "All") {
      SetlistClose(
        state.inoList?.length > 0 &&
        state.inoList?.filter(
          (inoItem: any) =>
            inoItem.status === STATUS.CLOSE && inoItem.network === valueSort
        )
      );
    } else {
      SetlistClose(
        state.inoList?.length > 0 &&
        state.inoList?.filter(
          (inoItem: any) => inoItem.status === STATUS.CLOSE
        )
      );
    }
  }, [valueSort, state.inoList]);

  const params = {
    lastDate: null,
    keywork: "",
  };
  useEffect(() => {
    actions.getInfoINO(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value: any) => {
    if (value.target.value === "") {
      actions.getInfoINO({ lastDate: null, keywork: "" });
      setEnableLoadMore(true);
    }
  };
  const onSearch = (value: string) => {
    setEnableLoadMore(false);
    const paramsSearch = {
      lastDate: null,
      keywork: value,
    };
    actions.getInfoINO(paramsSearch);
  };

  const getBscs = async () => {
    if (bscsContract && account) {
      const amount = await bscsContract.balanceOf(account);
      setAmountBscs(amount.toString() / 1e18);
    } else {
      setAmountBscs(0);
    }
  };

  const { Option } = Select;
  const onChangeSort = (value: string) => {
    if (value === "All") {
      setEnableLoadMore(true);
    }
    setValueSort(value);
  };
  return (
    <>
      <div
        className="main-ino-launch"
        style={{ background: `url(${bgMainheader}) 0% 50% / cover no-repeat` }}
      >
        <div className="container">
          <div className="content-ino-launch-top">
            <div className="img-octopus rotate-animation">
              <img src="/images/imgino/tuot.png" alt="" />
            </div>
            <div className="img-jellyfish rotate-animation">
              <img src="/images/imgino/bong.png" alt="" />
            </div>
            <div className="img-butterfly">
              <img src="/images/imgino/buom.png" alt="" />
            </div>
            <ContentBannerIno
              stake={state.inoTiers?.stakeAmount}
              yourBSCS={amountBscs}
            />
          </div>
          <div className="content-ino-launch-bottom">
            <div className="title">Opening sales on BSCS</div>
            <div className="table-upcoming-ino">
              <div className="columns">
                {listOpen.length === 0 || listOpen === false ? (
                  <div className="empty">{noData}</div>
                ) : (
                  listOpen.map((item: any, i: any) => (
                    <ItemOpen itemINO={item} />
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="content-ino-launch-bottom">
            <div className="title">Upcoming sales on BSCS</div>
            <div className="table-upcoming-ino">
              <div className="columns">
                {listComming.length === 0 || listComming === false ? (
                  <div className="empty">{noData}</div>
                ) : (
                  listComming.map((item: any, i: any) => (
                    <ItemUpcoming itemINO={item} />
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="content-ino-launch-bottom">
            {isMobile ? (
              <>
                <div className="title res mobie">
                  <p>Previous projects</p>
                  <div className="search-project">
                    <div className="sort">
                      <Select defaultValue="All" onChange={onChangeSort}>
                        <Option value="All">All Networks</Option>
                        <Option value="bep">BNB</Option>
                        <Option value="erc">ETH</Option>
                        <Option value="poly">POLY</Option>
                      </Select>
                    </div>
                    <div className="search">
                      <Search
                        placeholder="Search"
                        onChange={onChange}
                        onSearch={onSearch}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="title res">
                  <p>Previous projects</p>
                  <div className="search-project">
                    <div className="sort">
                      <Select defaultValue="All" onChange={onChangeSort}>
                        <Option value="All">All Networks</Option>
                        <Option value="bep">BNB</Option>
                        <Option value="erc">ETH</Option>
                        <Option value="poly">POLY</Option>
                        <Option value="avax">AVAX</Option>
                      </Select>
                    </div>
                    <div className="search">
                      <Search
                        placeholder="Search"
                        onChange={onChange}
                        onSearch={onSearch}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="table-upcoming-ino">
              {isMobile ? (
                <div className="columns" onTouchEnd={handleLoadMoreMobie}>
                  {listClose.length === 0 || listClose === false ? (
                    <div style={{ paddingTop: "35px" }} className="empty">
                      {noData}
                    </div>
                  ) : (
                    <>
                      {valueSort !== "All"
                        ? listClose
                          .filter((item: any) => item.network === valueSort)
                          .map((item: any, i: any) => (
                            <ItemPreviousMobie itemINO={item} />
                          ))
                        : listClose.map((item: any, i: any) => (
                          <ItemPreviousMobie itemINO={item} />
                        ))}

                      {valueSort !== "All"
                        ? state.inoListMore
                          .filter((item: any) => item.network === valueSort)
                          .map((item: any, i: any) => (
                            <ItemPreviousMobie itemINO={item} />
                          ))
                        : state.inoListMore.map((item: any, i: any) => (
                          <ItemPreviousMobie itemINO={item} />
                        ))}
                    </>
                  )}

                  {loadingSpin ? (
                    <div className="loading-inoMore">
                      <Spin indicator={antIcon} delay={1} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <>
                  <table className="main-table">
                    <tr className="tr-top">
                      <th>Project Name</th>
                      <th>Type</th>
                      <th>Participants</th>
                      <th>Total raised</th>
                      <th>Network</th>
                      <th>Price</th>
                    </tr>

                    {listClose.length === 0 || listClose === false ? (
                      ""
                    ) : (
                      <>
                        {valueSort !== "All"
                          ? listClose
                            .filter((item: any) => item.network === valueSort)
                            .map((item: any, i: any) => (
                              <ItemPrevious itemINO={item} />
                            ))
                          : listClose.map((item: any, i: any) => (
                            <ItemPrevious itemINO={item} />
                          ))}

                        {valueSort !== "All"
                          ? state.inoListMore
                            .filter((item: any) => item.network === valueSort)
                            .map((item: any, i: any) => (
                              <ItemPrevious itemINO={item} />
                            ))
                          : state.inoListMore.map((item: any, i: any) => (
                            <ItemPrevious itemINO={item} />
                          ))}
                      </>
                    )}
                  </table>
                  {listClose.length === 0 ||
                    listClose === false ||
                    (islistClose.length === 0 && (
                      <div className="nodata">{noData}</div>
                    ))}
                  {enableLoadMore && islistClose.length !== 0 && (
                    <button className="btn-loadmore" onClick={handleLoadMore}>
                      More
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InoLaunchPad;
