/* eslint-disable no-unreachable */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import {
  Tabs,
  Input,
  // Select,
  message,
  // Empty,
  Radio,
  // Popover,
  Spin,
  Modal,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useFormik } from "formik";
import Value from "./../../../components/Value";
import TimeAgo from "./../../../utils/timeAgo";
import "./style.css";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useHookArt,
  //  PropertiesArtwork 
} from "./StoreArt";
import {
  useContract,
  UseNftMarketContractV2,
} from "./../../../hooks/useContract";
// import CheckBox from "../Upload/components/CheckBox";
import UploadFile from "../Upload/components/UploadFile";
import { API_IMAGE } from "./../../../constants/index";
import Button from "./../../../components/Button";
import { _ownerOf, _getApproved } from "./utils";
import abiNFT from "./../../../constants/abis/nft.json";
import { CONTRACT_NFT, CONTRACT_BID_VERSION } from "./../../../constants";
import { useApproveNFTCallbackCustom } from "./../../../hooks/useApproveCallback";
import ErrorPage from "../../ErrorPage";
import {
  _cancelTokenTo,
  _readyToSellToken,
  // _getOwnerToken,
  // _sellTokenTo,
  // _getBids,
  // _bidToken,
  // _buyToken,
  // _transferNFT,
  // _setCurrentPrice,
} from "./../utils";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";

const Artwork = () => {
  const [state, actions]: any = useHookArt();

  const { objData } = state;
  const objSocial = objData.social;

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let navigate = useNavigate();
  const { address } = useAccount();
  // let address = '0xF6155243b3649b0957A9c11620D8EA62E11a59C4'
  const chainId = useChainId()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueCollection, setValueCollection] = useState("");
  const [valueSearch, setValueSearch] = useState("");
  const [valueFileType, setValueFileType] = useState(0);
  const [loadingOnSearch, setLoadingOnSearch] = useState(false);
  const [valueSort, setValueSort] = useState(1);
  const [tab, setTab] = useState(0);
  // const [pageNumber, setPageNumber] = useState(2);
  // const [isLoadMore, setIsLoadMore] = useState(false);

  const [myNftList, setMyNftList]: any = useState([]);
  const [myYourOffer, setMyYourOffer]: any = useState([]);
  const [hisOffer, setHisOffer]: any = useState([]);
  const [curentChain, setCurentChain]: any = useState();

  useEffect(() => {
    setCurentChain(chainHeader(chainId))
  }, [chainId])

  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no artwork</p>
    </>
  );

  let params = {
    ownerAddress: address,
    fileType: 0,
    keywork: "",
    pageNumber: 1,
    pageSize: 12,
    sortByPrice: "asc",
    sortType: 0,
    typeArtworks: "",
    category: 0,
  };

  // const onChangeRadioCollection = (e: any) => {
  //   setLoadingOnSearch(true);
  //   setValueCollection(e.target.value);
  //   params = {
  //     ...params,
  //     pageNumber: 1,
  //     keywork: valueSearch,
  //     fileType: valueFileType,
  //     sortType: valueSort,
  //     typeArtworks: e.target.value, // filter art collection
  //     category: tab,
  //   };
  //   actions.getMyArtwork(params).then((res: any) => {
  //     if (res) {
  //       setLoadingOnSearch(false);
  //     }
  //   });
  // };

  // const onChangeRadioType = (e: any) => {
  //   setLoadingOnSearch(true);
  //   setValueFileType(e.target.value);
  //   params = {
  //     ...params,
  //     pageNumber: 1,
  //     keywork: valueSearch,
  //     sortType: valueSort,
  //     typeArtworks: valueCollection,
  //     fileType: e.target.value,
  //     category: tab,
  //   };
  //   actions.getMyArtwork(params).then((res: any) => {
  //     if (res) {
  //       setLoadingOnSearch(false);
  //     }
  //   });
  // };

  // const onSearch = (value: string) => {
  //   setLoadingOnSearch(true);
  //   setValueSearch(value);
  //   params = {
  //     ...params,
  //     sortType: valueSort,
  //     pageNumber: 1,
  //     typeArtworks: valueCollection,
  //     fileType: valueFileType,
  //     keywork: value,
  //     category: tab,
  //   };
  //   actions.getMyArtwork(params).then((res: any) => {
  //     if (res) {
  //       setLoadingOnSearch(false);
  //     }
  //   });
  // };
  // const onChangeSearch = (value: any) => {
  //   setValueSearch(value);
  //   params = {
  //     ...params,
  //     sortType: valueSort,
  //     pageNumber: 1,
  //     typeArtworks: valueCollection,
  //     fileType: valueFileType,
  //     keywork: value.target.value,
  //     category: tab,
  //   };
  //   if (value.target.value === "") {
  //     setLoadingOnSearch(true);
  //     actions.getMyArtwork(params).then((res: any) => {
  //       if (res) {
  //         setLoadingOnSearch(false);
  //       }
  //     });
  //     // actions.getProducts();
  //     // setValueSearch("");
  //   }
  // };

  // const handleChangeSort = (value: any) => {
  //   setLoadingOnSearch(true);
  //   setValueSort(value);
  //   params = {
  //     ...params,
  //     pageNumber: 1,
  //     typeArtworks: valueCollection,
  //     fileType: valueFileType,
  //     keywork: valueSearch,
  //     sortType: value,
  //     category: tab,
  //   };
  //   actions.getMyArtwork(params).then((res: any) => {
  //     if (res) {
  //       setLoadingOnSearch(false);
  //     }
  //   });
  // };

  const onChange = (key: string) => {
    setLoadingOnSearch(true);
    setTab(parseInt(key));
    params = {
      ...params,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: valueSearch,
      sortType: valueSort,
      category: parseInt(key),
    };
    if (key === '2') {
      actions.getMyArtwork(params).then((res: any) => {
        if (res) {
          setLoadingOnSearch(false);
        }
      });
    } else {
      setLoadingOnSearch(false);
    }

  };
  // const handleLoadMore = () => {
  //   setIsLoadMore(true);
  //   params = {
  //     ...params,
  //     category: tab, // switch tab
  //     pageNumber, // next page
  //     pageSize: 12,
  //     sortByPrice: "asc",
  //     keywork: valueSearch, // search
  //     fileType: valueFileType, // filter art type
  //     sortType: valueSort, // sort
  //     typeArtworks: valueCollection, // filter art collection
  //   };
  //   actions.getMyArtworkMore(params).then((res: any) => {
  //     if (res) {
  //       setIsLoadMore(false);
  //     }
  //   });
  //   setPageNumber(pageNumber + 1);
  // };

  // const getMyArtworks = useCallback(async () => {
  //   try {
  //     setLoadingOnSearch(true);
  //     await actions.getMyArtwork({
  //       ...params,
  //     });
  //   } catch (err) {
  //     setLoadingOnSearch(false);
  //   } finally {
  //     setIsLoading(false);
  //     setLoadingOnSearch(false);
  //   }
  // }, [actions, address]);

  // const initData = useCallback(() => {
  //   getMyArtworks();
  // }, [getMyArtworks]);

  // useEffect(() => {
  //   initData();
  // }, [initData]);

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  const [avatar, setAvatar] = useState("");
  const [artistName, setArtistName] = useState("");
  const [artistDescription, setartistDescription] = useState("");

  useEffect(() => {
    if (address) {
      actions.getArtistsByWallet(address).then((res: any) => {
        if (res.data) {
          setAvatar("");
          setArtistName("");
          setartistDescription("");
        } else {
          setAvatar(res.avatarName);
          setArtistName(res.artistName);
          setartistDescription(res.artistDescription);
        }
      });
    }
  }, [address, avatar]);

  const gotoCreateProfile = () => {
    navigate(`${"/create-profile"}`);
  };
  const gotoMintNFT = () => {
    navigate(`${"/get-your-owner"}`);
  };
  const gotoDetail = (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };
  const gotoDetailGenesis = (id: any) => {
    navigate(`${"/nft-megamarket-detail"}/${id}`);
  };

  const Item = (data: any) => {

    // const [Owner, setOwner] = useState("");
    // const [allowance, setAllowance] = useState("");
    const item = data.data;
    // const CONTRACT_BID = CONTRACT_BID_VERSION[item.isNew ? 1 : 0];
    // const NFTContract = useContract(CONTRACT_NFT, abiNFT);
    // const ownerNFT = _ownerOf(NFTContract, item.tokenId);
    const bidContract = UseNftMarketContractV2(item.isNew);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingSell, setIsLoadingSell] = useState(false);
    // const [isLoadingRevoke, setIsLoadingRevoke] = useState(false);
    // const [isApprove, setApprove] = useState(false);
    // const [loadAprrove, setLoadApprove] = useState(false);
    const [value, setValue] = useState(0);

    const handleOk = async () => {
      setIsModalOpen(false);
      await readyToSellToken();
    };
    const onChangeAmount = (value: any) => {
      setValue(value);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
      setIsLoadingSell(false);
    };
    // const sellNFT = async () => {
    //   setIsModalOpen(true);
    //   setIsLoadingSell(true);
    // };
    const updateInfoData = (id: any, amount: any, ownerAddress = "") => {
      let obj: any = { id, price: parseFloat(amount) };
      if (ownerAddress) {
        obj = { ...obj, ownerAddress };
      }
      actions.updateInfo(obj).then(() => {
        // actions.getProductsDetail(id);
        actions.getMyArtwork({
          ...params,
        });
      });
    };
    // const cancelTokenTo = async () => {
    //   setIsLoadingRevoke(true);
    //   await _cancelTokenTo(bidContract, item.tokenId)
    //     .then((response: any) => {
    //       response.wait().then((res1: any) => {
    //         if (res1 !== null) {
    //           updateInfoData(item.id, 0, address || "");
    //           setIsLoadingRevoke(false);
    //           setLoadApprove(true);
    //           //  actions.getMyArtwork(address);
    //         } else {
    //           setIsLoadingRevoke(false);
    //         }
    //       });
    //     })
    //     .catch((error: any) => {
    //       setIsLoadingRevoke(false);
    //     });
    // };

    const readyToSellToken = async () => {
      setIsLoadingSell(true);
      await _readyToSellToken(bidContract, item.tokenId, value)
        .then((response: any) => {
          response.wait().then((res1: any) => {
            if (res1 !== null) {
              updateInfoData(item.id, value, address || "");
              setIsLoadingSell(false);
            } else {
              setIsLoadingSell(false);
            }
          });
        })
        .catch((error: any) => {
          setIsLoadingSell(false);
        });
    };

    // const [_approvalSell] = useApproveNFTCallbackCustom(
    //   CONTRACT_NFT,
    //   CONTRACT_BID,
    //   item.tokenId,
    //   item.tokenId
    // );
    // async function onAttemptToApproveSell() {
    //   return _approvalSell();
    // }
    // const handleApproveSell = useCallback(async () => {
    //   try {
    //     setApprove(true);
    //     await onAttemptToApproveSell().then((response: any) => {
    //       response.wait().then((res1: any) => {
    //         if (res1 !== null) {
    //           setApprove(false);
    //           setLoadApprove(false);
    //         } else {
    //           setApprove(false);
    //         }
    //       });
    //     });
    //   } catch (e: any) {
    //     setApprove(false);
    //     setLoadApprove(false);
    //     message.error({
    //       type: "error",
    //       content: e.message,
    //       className: "custom-class",
    //       duration: 3,
    //     });
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [_approvalSell]);

    // ownerNFT.then((res) => {
    //   setOwner(res);
    // });
    // const allowanceNFT = _getApproved(NFTContract, item.tokenId);
    // allowanceNFT.then((res) => {
    //   setAllowance(res);
    // });
    // const ownerNFTTemp = Owner && Owner.toLowerCase();
    // const accountTemp = address && address.toLowerCase();
    // const allowanceNFTTemp =
    //   (allowance && allowance.toString().toLowerCase()) || "";

    const contentModal = (
      <>
        <div className="main-body-modal">
          <div className="input-amount">
            <Input
              type="number"
              placeholder="0"
              onChange={(e: any) => onChangeAmount(e.target.value)}
            />

            <div className="name-token-staking price">BSCS</div>
          </div>

          <div className="button-select d-flex row-btn-content">
            <Button
              text="Cancel"
              primary
              click={() => {
                handleCancel();
              }}
            />
            <Button
              text="Submit"
              ghost
              disabled={value <= 0}
              click={() => {
                handleOk();
              }}
            />
          </div>
        </div>
      </>
    );
    return (
      <>
        <div className="item-new colum w-2">
          <div className="guide-market">
            <div className="box-img"
              onClick={() => gotoDetail(item?.queryKey)}
            >
              <div className="box-img box-img-genesis">

                {/* <div className="imgType">
                  <img src="/images/imgnft/typeImg.png" alt="" />
                </div> */}

                {item?.tokenId >= 20000 ? (
                  <video
                    className="imgNft full-dis video-mo"
                    autoPlay
                    controls
                    loop
                    playsInline
                    style={{
                      borderRadius: "6px",
                      height: "235px",
                    }}
                  >
                    <source
                      src={item?.nftUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    className="imgNft full-dis"
                    src={item?.nftUrl}
                    alt={item.name}
                  />
                )}




              </div>
              {/* <div className="price-item d-flex pd-top-10">
                <span className="t-left">Status</span>
                <div className="t-right">
                  <div className="number">
                    {(() => {
                      switch (item.status) {
                        case 0:
                          return "Pending";
                        case 1:
                          return "Approved";
                        case 2:
                          return "Rejected";
                        default:
                          return "Unknow";
                      }
                    })()}
                  </div>
                </div>
              </div> */}
            </div>
            <div className="name-item">{item.name}</div>
            {/* <div className="artis-item">{item.authorName}</div> */}
            {item?.tokenId >= 20000 ? ('') : (
              <>
                <div className="price-item d-flex">
                  <span className="t-left">Current price:</span>
                  <div className="t-right">
                    <div className="icon">
                      <img src="./images/imgmarket/logoBSC.svg" alt="" />
                    </div>
                    <div className="number d-flex">
                      <Value value={item.price} /> BSCS
                    </div>
                  </div>
                </div>
              </>
            )}


            {/* <div className="guide-action">
              {accountTemp !== item.ownerAddress.toLowerCase() ? (
                <Button
                  click={() => gotoDetail(item.id)}
                  primary
                  className="btn btn-approve-mk mar-b-10"
                  text="Details"
                />
              ) : (!loadAprrove &&
                allowanceNFTTemp !==
                "0x0000000000000000000000000000000000000000" &&
                allowanceNFTTemp === CONTRACT_BID.toString().toLowerCase()) ||
                ownerNFTTemp === CONTRACT_BID.toString().toLowerCase() ? (
                <>
                  {ownerNFTTemp && ownerNFTTemp !== accountTemp ? (
                    <div className="bsc-artwork-item-combo-btn">
                      <Button
                        primary
                        className="full-with-btn "
                        loading={isLoadingRevoke}
                        disabled={isLoadingRevoke}
                        click={cancelTokenTo}
                        text="Revoke"
                      />
                    </div>
                  ) : (
                    <div className="bsc-artwork-item-combo-btn">
                      <Button
                        ghost
                        className="full-with-btn "
                        loading={isLoadingSell}
                        disabled={isLoadingSell}
                        click={() => sellNFT()}
                        text="Sell"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {item.status !== 0 && (
                    <Button
                      primary
                      className="btn btn-approve-mk mar-b-10"
                      disabled={isApprove}
                      loading={isApprove}
                      click={handleApproveSell}
                      text="Approve NFT"
                    />
                  )}
                </>
              )}
            </div> */}
          </div>
        </div>
        <div className="modal-staking">
          {/* @ts-ignore this lib is incompatible with react18  */}
          <Modal
            title="Please set the selling price"
            className="modal-seting-price"
            visible={isModalOpen}
            onCancel={handleCancel}
          >
            <div className="modal-content-staking">{contentModal}</div>
          </Modal>
        </div>
      </>
    );
  };

  const ItemGenesis = (data: any) => {

    // const [Owner, setOwner] = useState("");
    // const [allowance, setAllowance] = useState("");
    const item = data.data;
    // const CONTRACT_BID = CONTRACT_BID_VERSION[item.isNew ? 1 : 0];
    // const NFTContract = useContract(CONTRACT_NFT, abiNFT);
    // const ownerNFT = _ownerOf(NFTContract, item.tokenId);
    // const bidContract = UseNftMarketContractV2(item.isNew);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isLoadingSell, setIsLoadingSell] = useState(false);
    // const [isLoadingRevoke, setIsLoadingRevoke] = useState(false);
    // const [isApprove, setApprove] = useState(false);
    // const [loadAprrove, setLoadApprove] = useState(false);
    // const [value, setValue] = useState(0);

    // const handleOk = async () => {
    //   setIsModalOpen(false);
    //   await readyToSellToken();
    // };
    // const onChangeAmount = (value: any) => {
    //   setValue(value);
    // };
    // const handleCancel = () => {
    //   setIsModalOpen(false);
    //   setIsLoadingSell(false);
    // };
    // const sellNFT = async () => {
    //   setIsModalOpen(true);
    //   setIsLoadingSell(true);
    // };
    // const updateInfoData = (id: any, amount: any, ownerAddress = "") => {
    //   let obj: any = { id, price: parseFloat(amount) };
    //   if (ownerAddress) {
    //     obj = { ...obj, ownerAddress };
    //   }
    //   actions.updateInfo(obj).then(() => {
    //     // actions.getProductsDetail(id);
    //     actions.getMyArtwork({
    //       ...params,
    //     });
    //   });
    // };
    // const cancelTokenTo = async () => {
    //   setIsLoadingRevoke(true);
    //   await _cancelTokenTo(bidContract, item.tokenId)
    //     .then((response: any) => {
    //       response.wait().then((res1: any) => {
    //         if (res1 !== null) {
    //           updateInfoData(item.id, 0, address || "");
    //           setIsLoadingRevoke(false);
    //           setLoadApprove(true);
    //           //  actions.getMyArtwork(address);
    //         } else {
    //           setIsLoadingRevoke(false);
    //         }
    //       });
    //     })
    //     .catch((error: any) => {
    //       setIsLoadingRevoke(false);
    //     });
    // };

    // const readyToSellToken = async () => {
    //   setIsLoadingSell(true);
    //   await _readyToSellToken(bidContract, item.tokenId, value)
    //     .then((response: any) => {
    //       response.wait().then((res1: any) => {
    //         if (res1 !== null) {
    //           updateInfoData(item.id, value, address || "");
    //           setIsLoadingSell(false);
    //         } else {
    //           setIsLoadingSell(false);
    //         }
    //       });
    //     })
    //     .catch((error: any) => {
    //       setIsLoadingSell(false);
    //     });
    // };

    // const [_approvalSell] = useApproveNFTCallbackCustom(
    //   CONTRACT_NFT,
    //   CONTRACT_BID,
    //   item.tokenId,
    //   item.tokenId
    // );
    // async function onAttemptToApproveSell() {
    //   return _approvalSell();
    // }
    // const handleApproveSell = useCallback(async () => {
    //   try {
    //     setApprove(true);
    //     await onAttemptToApproveSell().then((response: any) => {
    //       response.wait().then((res1: any) => {
    //         if (res1 !== null) {
    //           setApprove(false);
    //           setLoadApprove(false);
    //         } else {
    //           setApprove(false);
    //         }
    //       });
    //     });
    //   } catch (e: any) {
    //     setApprove(false);
    //     setLoadApprove(false);
    //     message.error({
    //       type: "error",
    //       content: e.message,
    //       className: "custom-class",
    //       duration: 3,
    //     });
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [_approvalSell]);

    // ownerNFT.then((res) => {
    //   setOwner(res);
    // });
    // const allowanceNFT = _getApproved(NFTContract, item.tokenId);
    // allowanceNFT.then((res) => {
    //   setAllowance(res);
    // });
    // const ownerNFTTemp = Owner && Owner.toLowerCase();
    // const accountTemp = address && address.toLowerCase();
    // const allowanceNFTTemp =
    //   (allowance && allowance.toString().toLowerCase()) || "";

    // const contentModal = (
    //   <>
    //     <div className="main-body-modal">
    //       <div className="input-amount">
    //         <Input
    //           type="number"
    //           placeholder="0"
    //           onChange={(e: any) => onChangeAmount(e.target.value)}
    //         />

    //         <div className="name-token-staking price">BSCS</div>
    //       </div>

    //       <div className="button-select d-flex row-btn-content">
    //         <Button
    //           text="Cancel"
    //           primary
    //           click={() => {
    //             handleCancel();
    //           }}
    //         />
    //         <Button
    //           text="Submit"
    //           ghost
    //           disabled={value <= 0}
    //           click={() => {
    //             handleOk();
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </>
    // );
    return (
      <>
        <div className="item-new colum w-2">
          <div className="guide-market">
            <div className="box-img"
              onClick={() => gotoDetailGenesis(item?.id)}
            >
              <div className="box-img box-img-genesis">

                {/* <div className="imgType">
                  <img src="/images/imgnft/typeImg.png" alt="" />
                </div> */}

                {item?.tokenId >= 20000 ? (
                  <video
                    className="imgNft full-dis video-mo"
                    autoPlay
                    controls
                    loop
                    playsInline
                    style={{
                      borderRadius: "6px",
                      height: "235px",
                    }}
                  >
                    <source
                      src={item?.nftUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    className="imgNft full-dis"
                    src={item?.thumbnail}
                    alt={item.name}
                  />
                )}

              </div>
              {/* <div className="price-item d-flex pd-top-10">
                <span className="t-left">Status</span>
                <div className="t-right">
                  <div className="number">
                    {(() => {
                      switch (item.status) {
                        case 0:
                          return "Pending";
                        case 1:
                          return "Approved";
                        case 2:
                          return "Rejected";
                        default:
                          return "Unknow";
                      }
                    })()}
                  </div>
                </div>
              </div> */}
            </div>
            <div className="name-item">{item.name}</div>
            {/* <div className="artis-item">{item.authorName}</div> */}
            {item?.tokenId >= 20000 ? ('') : (
              <>
                <div className="price-item d-flex">
                  <span className="t-left">Current price:</span>
                  <div className="t-right">
                    <div className="icon">
                      <img src="./images/imgmarket/logoBSC.svg" alt="" />
                    </div>
                    <div className="number d-flex">
                      <Value value={item.price} /> BSCS
                    </div>
                  </div>
                </div>
              </>
            )}


            {/* <div className="guide-action">
              {accountTemp !== item.ownerAddress.toLowerCase() ? (
                <Button
                  click={() => gotoDetail(item.id)}
                  primary
                  className="btn btn-approve-mk mar-b-10"
                  text="Details"
                />
              ) : (!loadAprrove &&
                allowanceNFTTemp !==
                "0x0000000000000000000000000000000000000000" &&
                allowanceNFTTemp === CONTRACT_BID.toString().toLowerCase()) ||
                ownerNFTTemp === CONTRACT_BID.toString().toLowerCase() ? (
                <>
                  {ownerNFTTemp && ownerNFTTemp !== accountTemp ? (
                    <div className="bsc-artwork-item-combo-btn">
                      <Button
                        primary
                        className="full-with-btn "
                        loading={isLoadingRevoke}
                        disabled={isLoadingRevoke}
                        click={cancelTokenTo}
                        text="Revoke"
                      />
                    </div>
                  ) : (
                    <div className="bsc-artwork-item-combo-btn">
                      <Button
                        ghost
                        className="full-with-btn "
                        loading={isLoadingSell}
                        disabled={isLoadingSell}
                        click={() => sellNFT()}
                        text="Sell"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {item.status !== 0 && (
                    <Button
                      primary
                      className="btn btn-approve-mk mar-b-10"
                      disabled={isApprove}
                      loading={isApprove}
                      click={handleApproveSell}
                      text="Approve NFT"
                    />
                  )}
                </>
              )}
            </div> */}
          </div>
        </div>
        <div className="modal-staking">
          {/* @ts-ignore this lib is incompatible with react18  */}
          {/* <Modal
            title="Please set the selling price"
            className="modal-seting-price"
            visible={isModalOpen}
            onCancel={handleCancel}
          >
            <div className="modal-content-staking">{contentModal}</div>
          </Modal> */}
        </div>
      </>
    );
  };


  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      fileAvatar: "0",
      name: objData?.artistName || "",
      social: objData?.publicProfileLink || "",
      bio: objData?.artistDescription || "",
      fileBanner: "",
      medium: objSocial?.medium || "",
      telegram: objSocial?.telegram || "",
      twitter: objSocial?.twitter || "",
      website: objSocial?.website || "",
    },
    onSubmit: () => {
      actions
        .submitEditArtists(formikEdit.values, address)
        .then(() => {
          formikEdit.resetForm();
          message.success({
            type: "success",
            content: "Update success",
            className: "custom-class",
            duration: 2,
          });
          actions.getArtistsByWallet(address).then((res: any) => {
            if (res.data) {
              setAvatar("");
              setArtistName("");
              setartistDescription("");
            } else {
              setAvatar(res.avatarName);
              setArtistName(res.artistName);
              setartistDescription(res.artistDescription);
            }
          });
          setIsModalEditOpen(false);
        })
        .catch((error: any) => {
          message.error({
            type: "error",
            content: error?.response.data.Message,
            className: "custom-class",
            duration: 2,
          });
        });
    },
  } as any);

  const upLoadFileAvatarEdit = (event: any) => {
    formikEdit.setFieldValue("fileAvatar", event.target.files[0]);
  };
  const handleSave = async () => {
    setIsModalEditOpen(false);
    // await readyToSellToken();
  };
  const handleEditCancel = () => {
    setIsModalEditOpen(false);
    // setIsLoadingSell(false);
  };
  const editProfile = async () => {
    setIsModalEditOpen(true);
    // setIsLoadingSell(true);
  };
  const contentEditModal = (
    <>
      <form
        id="frm-create-artist"
        className="uploadInfo"
        onSubmit={formikEdit.handleSubmit}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Enter your details.</div>
          </div>
          <div className="round-r">
            <div className="form-group">
              <input
                placeholder="Enter the artist name"
                type="text"
                className="form-control f-control"
                name="name"
                value={formikEdit.values.name}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Add a short bio.</div>
          </div>
          <div className="round-r">
            <div className="form-group">
              <textarea
                className="form-control f-control cus"
                name="bio"
                placeholder="Please write something about yourself"
                value={formikEdit.values.bio}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Upload a profile image.</div>
            <div className="desc-round">
              Recommended size: <br />
              1180x230px.
              <br />
              JPG, PNG, or GIF.
              <br />
              10MB max size.
            </div>
          </div>
          <div className="round-r">
            <div className="formfile">
              <div className="form-group avatar-upload">
                <UploadFile
                  src={`${API_IMAGE}${objData.avatarName}?v=122`}
                  name="fileName"
                  id="imgFile"
                  accept="image/*"
                  onChange={upLoadFileAvatarEdit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">
              Add links to your social <br /> media profiles.
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/link.svg" alt="" /> Website
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Website URL"
                name="website"
                value={formikEdit.values.website}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Telegram.svg" alt="" /> Telegram
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Telegram URL"
                name="telegram"
                value={formikEdit.values.telegram}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Twitter.svg" alt="" /> X.com
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="X.com URL"
                name="twitter"
                value={formikEdit.values.twitter}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Medium.svg" alt="" /> Medium
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Medium URL"
                name="medium"
                value={formikEdit.values.medium}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="btn-inf" id="btn-submit-artist">
          <>
            <Button
              type="submit"
              text="Submit"
              click={() => handleSave()}
              primary
              loading={isLoading}
            />
          </>
        </div>
      </form>
    </>
  );
  // const content = (
  //   <div className="content-popover">
  //     <div className="columns">
  //       <div className="colum w-6">
  //         <div className="round-popover">
  //           <div className="title">Collection</div>
  //           <div className="list-radio">
  //             <Radio.Group
  //               onChange={onChangeRadioCollection}
  //               value={valueCollection}
  //             >
  //               <Radio value="">All</Radio>
  //               <Radio value="Characters">Characters</Radio>
  //               <Radio value="Weapon">Weapon</Radio>
  //               <Radio value="Pet">Pet</Radio>
  //               <Radio value="My Neighbor Alice">My Neighbor Alice</Radio>
  //             </Radio.Group>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="colum w-6">
  //         <div className="round-popover">
  //           <div className="title">Artwork Type</div>
  //           <div className="list-radio">
  //             <Radio.Group onChange={onChangeRadioType} value={valueFileType}>
  //               <Radio value={0}>All</Radio>
  //               <Radio value={1}>Image</Radio>
  //               <Radio value={2}>Video</Radio>
  //               <Radio value={3}>Gif</Radio>
  //             </Radio.Group>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  // const FilterRow = () => {
  //   return (
  //     <div className="swap-filter-new">
  //       <div className="target-project">
  //         <Popover
  //           content={content}
  //           placement="bottom"
  //           title="Filter Artwork"
  //           trigger="click"
  //         >
  //           <button className="btn-filter" type="button">
  //             <img
  //               src="../images/imgmarket/setting.png"
  //               alt=""
  //             />{" "}
  //             Fillter
  //           </button>
  //         </Popover>
  //       </div>
  //       <div className="search-project">
  //         <Search
  //           placeholder="Search for project"
  //           onSearch={onSearch}
  //           onChange={onChangeSearch}
  //         />
  //         <div className="icon-search">
  //           <img
  //             src="/images/imgido/search-normal.png"
  //             alt=""
  //           />
  //         </div>
  //       </div>
  //       <div className="filter-project">
  //         <Select
  //           defaultValue="Sort by"
  //           style={{ width: 125 }}
  //           onChange={handleChangeSort}
  //         >
  //           <Option value={1}>Price low - high</Option>
  //           <Option value={2}>Price high - low</Option>
  //           <Option value={0}>Latest</Option>

  //         </Select>
  //       </div>
  //     </div>
  //   )
  // }

  const getListMyNft = async () => {
    let params = {
      ownerAddress: address || '',
      lastTime: 0
    }
    let mynft = await actions.myNFT(params)
    setMyNftList(mynft || [])
  }

  const getYourOffer = async () => {
    let params = {
      ownerAddress: address,
    }
    let yourOffer = await actions.yourOffer(params)
    setMyYourOffer(yourOffer || [])
  }

  const getHisOffer = async () => {
    let params = {
      ownerAddress: address,
    }
    let hisOffer = await actions.hisOffer(params)
    setHisOffer(hisOffer || [])
  }

  useEffect(() => {
    getListMyNft()
  }, [address])

  useEffect(() => {
    getYourOffer()
    getHisOffer()
  }, [address])


  const renderStatus = (value: any) => {
    switch (value) {
      case 1:
        return 'Sell'
        break;
      case 2:
        return 'Success'
        break;
      case 3:
        return 'Cancel'
        break;
      case 4:
        return 'Faild'
        break;

      default:
        return '--'
        break;
    }
  }


  return (
    <>
      {chainHeader(chainId) === "bep" ? (
        <>
          <div className="main-profile-user-rowß">
            <div className="baanner-user">
              <img src="./images/imgnft/top-profile.png" alt="" />
            </div>
            <div className="container">
              <div className="content-profile-user res tabs-artwork">
                <div className="columns profile-new">
                  <div className="colum w-25">
                    <div className="swap-left">
                      {address ? (
                        <>
                          <div className="box-img">
                            {avatar !== "" ? (
                              <>
                                <img
                                  src={`${API_IMAGE}${avatar}?v=122`}
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <img
                                  src="./images/imgmarket/profile-1.png"
                                  alt=""
                                />
                              </>
                            )}
                          </div>
                          <div className="name-rl">
                            Name:{" "}
                            <span className="rl">{artistName || "N/A"}</span>
                          </div>
                          <div className="social-rl">
                            {objSocial.website && (
                              <a
                                href={`${objSocial.website}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/link.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.telegram && (
                              <a
                                href={`${objSocial.telegram}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/tele-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.twitter && (
                              <a
                                href={`${objSocial.twitter}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/twi-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.medium && (
                              <a
                                href={`${objSocial.medium}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/me-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="box-img">
                            <img
                              src="./images/imgmarket/profile-1.png"
                              alt=""
                            />
                          </div>
                          <div className="name-rl">
                            Name: <span className="rl">N/A</span>
                          </div>
                        </>
                      )}

                      <div className="address-rl">
                        {address && (
                          <>
                            Wallet address :{" "}
                            <div className="address">
                              {address &&
                                `${address.substring(
                                  0,
                                  4
                                )}...${address.substring(38, address.length)}`}
                              <span className="img-copy">
                                <CopyToClipboard
                                  text={`${address}`}
                                  onCopy={() =>
                                    message.success({
                                      type: "success",
                                      content: "Copied success",
                                      className: "custom-class",
                                      duration: 1,
                                    })
                                  }
                                >
                                  <img
                                    className="coppy btn-copied"
                                    src="/images/imgido/copy.png"
                                    alt=""
                                  />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="desc-rl">{artistDescription || ""}</div>
                      <div className="edit-profile pd-top-10">
                        {artistName === "" ? (
                          <button
                            className="btn-edit"
                            type="button"
                            onClick={() => gotoCreateProfile()}
                          >
                            <img src="./images/imgmarket/edit.svg" alt="" />{" "}
                            Create profile
                          </button>
                        ) : (
                          <>
                            {/* <button
                              className="btn-edit"
                              type="button"
                              onClick={() => gotoMintNFT()}
                            >
                              <img src="./images/imgmarket/edit.svg" alt="" />{" "}
                              Mint NFT
                            </button> */}
                            <button
                              className="btn-edit mg-top-10"
                              type="button"
                              onClick={() => editProfile()}
                            >
                              <img src="./images/imgmarket/edit.svg" alt="" />{" "}
                              Edit profile
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="colum w-75">
                    <div className="swap-right">
                      <div className="tabs-market res-new display-seach">
                        <button className="new-mint-btn" onClick={() => gotoMintNFT()}>
                          Mint NFT
                        </button>

                        <Tabs defaultActiveKey="1" onChange={onChange}>
                          <Tabs.TabPane tab="BSCS NFT" key="1">
                            {/* <FilterRow /> */}
                            <div className="round-tabs pd-top-20 new-list-profile">
                              <div className="columns pd-top-20">
                                {loadingOnSearch ? (
                                  <div className="nft-loading">
                                    <Spin indicator={antIconLoad} />
                                  </div>
                                ) : myNftList.length === 0 ? (
                                  <div className="nft-empty pd-top-20 align-center">
                                    {noData}
                                  </div>
                                ) : (
                                  <>
                                    {myNftList &&
                                      myNftList.map((item: any, i: any) => (
                                        <Item data={item} />
                                      ))}
                                  </>
                                )}

                                {/* {
                                loadingOnSearch ? (
                                  <div className="nft-loading">
                                    <Spin indicator={antIconLoad} />
                                  </div>
                                ) : state.nftList.length === 0 ? (
                                  <div className="nft-empty pd-top-20 align-center">
                                    {noData}
                                  </div>
                                ) : (
                                  <>
                                    {state.nftList &&
                                      state.nftList.map((item: any, i: any) => (
                                        <Item data={item} />
                                      ))}
                                    {state.nftListMore &&
                                      state.nftListMore.map(
                                        (item: any, i: any) => (
                                          <Item data={item} />
                                        )
                                      )}
                                  </>
                                )} */}


                              </div>
                              <div className="load-more-round">
                                {/* {isLoadMore ? (
                                  <Spin indicator={antIconLoad} />
                                ) : state.hideLoadmore ? (
                                  ""
                                ) : state.nftList?.length === 0 ? (
                                  ""
                                ) : (
                                  <button
                                    className="btn-loadmore cus"
                                    type="button"
                                    onClick={() => handleLoadMore()}
                                  >
                                    Load More
                                  </button>
                                )} */}
                              </div>
                            </div>
                          </Tabs.TabPane>

                          <Tabs.TabPane tab="Genesis NFT" key="2">
                            {/* <FilterRow /> */}
                            <div className="round-tabs pd-top-20 new-list-profile">
                              <div className="columns pd-top-20">
                                {
                                  loadingOnSearch ? (
                                    <div className="nft-loading">
                                      <Spin indicator={antIconLoad} />
                                    </div>
                                  ) : state.nftList.length === 0 ? (
                                    <div className="nft-empty pd-top-20 align-center">
                                      {noData}
                                    </div>
                                  ) : (
                                    <>
                                      {state.nftList &&
                                        state.nftList.map((item: any, i: any) => (
                                          <ItemGenesis data={item} />
                                        ))}
                                      {state.nftListMore &&
                                        state.nftListMore.map(
                                          (item: any, i: any) => (
                                            <ItemGenesis data={item} />
                                          )
                                        )}
                                    </>
                                  )}
                              </div>
                            </div>
                          </Tabs.TabPane>

                          <Tabs.TabPane tab="Offer history" key="3">
                            {/* <FilterRow /> */}
                            <div className="your-offer">
                              <div className="offer-his">
                                Offer history
                              </div>
                              <div className="wrap-table">
                                <table className="table-offer-his">
                                  <tr className="tr-head-offer">
                                    <th>NFT Offer</th>
                                    <th>Price</th>
                                    <th>Floor Difference</th>
                                    <th>Expiration</th>
                                    <th>From</th>

                                  </tr>
                                  {hisOffer.length > 0 ? (
                                    hisOffer.map((item: any, index: any) => (
                                      <tr style={{ cursor: 'pointer' }} onClick={() => gotoDetail(item.queryKey)}>
                                        <td className="td-offer td-his1">
                                          <div className="img-nft-his">
                                            <img src={item?.nftUrl} alt="" />
                                          </div>
                                          <div className="name-nft-his">{item?.name}</div>

                                        </td>
                                        <td className="td-offer td-his2">{item?.price} BSCS</td>
                                        <td className="td-offer td-his3">{item?.floorDiff}%</td>
                                        <td className="td-offer td-his4">
                                          <TimeAgo timestamp={item?.expiration} />

                                        </td>
                                        <td className="td-offer td-his4" style={{ color: '#3AA3FF' }}>{item?.fromAddress?.slice(0, 4)}...{item?.fromAddress?.slice(-4)}</td>
                                        {/* <td className="td-offer td-his5">
                                      <button className="btn-cancel-offer">Cancel Offer</button>
                                    </td> */}
                                      </tr>
                                    ))

                                  ) : (
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="nft-empty pd-top-20 align-center">
                                          {noData}
                                        </div>
                                      </td>
                                    </tr>
                                  )}


                                </table>
                              </div>
                            </div>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="Your offer" key="4">
                            {/* <FilterRow /> */}
                            <div className="your-offer">
                              <div className="offer-his">
                                Offer history
                              </div>
                              <div className="wrap-table">
                                <table className="table-offer-his">

                                  <tr className="tr-head-offer">
                                    <th>NFT Offer</th>
                                    <th>Price</th>
                                    <th>Floor Difference</th>
                                    <th>Expiration</th>
                                    <th>Status</th>
                                  </tr>
                                  {myYourOffer.length > 0 ? (
                                    myYourOffer.map((item: any, index: any) => (
                                      <tr style={{ cursor: 'pointer' }} onClick={() => gotoDetail(item?.queryKey)}>
                                        <td className="td-offer td-his1">

                                          <div className="img-nft-his">
                                            <img src={item?.nftUrl} alt="" />
                                          </div>
                                          <div className="name-nft-his">{item?.name}</div>

                                        </td>
                                        <td className="td-offer td-his2">{item?.price} BSCS</td>
                                        <td className="td-offer td-his3">{item?.floorDiff}%</td>
                                        <td className="td-offer td-his4">
                                          <TimeAgo timestamp={item?.auction?.expiration} />

                                          {/* in 15 minutes */}
                                        </td>
                                        <td className="td-offer td-his5">
                                          <button className="btn-cancel-offer">
                                            {renderStatus(item?.auction?.status)}
                                          </button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="nft-empty pd-top-20 align-center">
                                          {noData}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </table>
                              </div>
                            </div>
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorPage />
        </>
      )}
      <div className="modal-staking">
        {/* @ts-ignore this lib is incompatible with react18  */}
        <Modal
          title="Edit profile"
          className="modal-eidt-profile"
          visible={isModalEditOpen}
          onOk={handleSave}
          onCancel={handleEditCancel}
        >
          <div className="modal-edit-profile">{contentEditModal}</div>
        </Modal>
      </div>
    </>
  );
};
export default Artwork;
