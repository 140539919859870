import React from "react";
import styled from "styled-components";
import FormDetail from "./FormDetail";
import FormBody from "./FormBody";
import bgMainheader from "./images/banner-ino.png";
import "./style.css";

const Info = () => {
  return (
    <>
      <div
        className="main-edit-profile"
        style={{
          background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
        }}
      >
        <div className="header-form">
          <div className="title">Edit your profile</div>
          <div className="desc">BSCS artist</div>
        </div>
        <FormBody>
          <FormDetail />
        </FormBody>
      </div>
    </>
  );
};

export default Info;
