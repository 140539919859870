/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Menu, Button, Drawer, Collapse } from "antd";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useHookProjects } from "../../pages/Home/Store";
import { SolidProof_URL } from "../../constants/index";
import WagmiConnect from "components/Wagmi-wallet";

import "antd/dist/antd.min.css";
import "./style.css";

const iconHome = (
    <>
        <div className="box-icon-menu">
            <img src="/images/icon-home.svg" alt="" />
        </div>
    </>
);
const iconView = (
    <>
        <div className="box-icon-menu">
            <img src="/images/view-v3.png" alt="" />
        </div>
    </>
);
const iconSwap = (
    <>
        <div className="box-icon-menu">
            <img src="/images/swap-v3.png" alt="" />
        </div>
    </>
);
const iconNFT = (
    <>
        <div className="box-icon-menu">
            <img src="/images/icon-nft.svg" alt="" />
        </div>
    </>
);
const iconEarn = (
    <>
        <div className="box-icon-menu">
            <img src="/images/earn-v3.png" alt="" />
        </div>
    </>
);
const iconMore = (
    <>
        <div className="box-icon-menu">
            <img src="/images/icon-more.svg" alt="" />
        </div>
    </>
);

const Header = () => {
    const [state, actions] = useHookProjects();

    useEffect(() => {
        actions.getMarketInfo();
    }, []);

    const priceData = state.marketInfo.price;
    const bscsPriceUsd = priceData ? Number(priceData) : "0.00";
    const priceBSCS = Number(bscsPriceUsd).toFixed(3);
    useEffect(() => {
        actions.savePrice(priceBSCS);
    }, [priceBSCS]);

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const { Panel } = Collapse;
    const onChange = (key) => {
        console.log(key);
    };

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1);
        });
    }, []);

    const [scrollBg, setScrollBg] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollBg(window.scrollY > 1);
        });
    }, []);

    let location = useLocation();
    const pathLive = location.pathname.split("/");
    const pathLiveCenter = pathLive[1];

    const headerHome = (
        <>
            <div className="heeader-menu-collapse">
                <div className="box-icon">{iconHome}</div>
                <div className="title-menu">Launchpad</div>
            </div>
        </>
    );

    const headerNFT = (
        <>
            <div className="heeader-menu-collapse">
                <div className="box-icon">{iconNFT}</div>
                <div className="title-menu">NFT</div>
            </div>
        </>
    );

    const headerEarn = (
        <>
            <div className="heeader-menu-collapse">
                <div className="box-icon">{iconEarn}</div>
                <div className="title-menu">Earn</div>
            </div>
        </>
    );

    const headerMore = (
        <>
            <div className="heeader-menu-collapse">
                <div className="box-icon">{iconMore}</div>
                <div className="title-menu">More</div>
            </div>
        </>
    );

    const headerSwap = (
        <>
            <div className="heeader-menu-collapse">
                <div className="box-icon">{iconSwap}</div>
                <div className="title-menu">Swap</div>
            </div>
        </>
    );

    const headerView = (
        <>
            <div className="heeader-menu-collapse">
                <div className="box-icon">{iconView}</div>
                <div className="title-menu">View</div>
            </div>
        </>
    );

    const menuMobile = (
        <>
            <Collapse
                className="collapse-menu"
                accordion
                // defaultActiveKey={["0"]}
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                onChange={onChange}
            >
                <Panel header={headerHome} key="1">
                    <ul className="sub-menu-collapse">
                        <Link to="/Ido" onClick={onClose}>
                            <li>IDO</li>
                        </Link>
                        <Link to="/InoLaunchPad" onClick={onClose}>
                            <li>INO</li>
                        </Link>

                    </ul>
                </Panel>

                <Panel header={headerSwap} key="44">
                    <ul className="sub-menu-collapse">
                        <a href="https://app.bscs.finance/swap">
                            <li>Swap</li>
                        </a>
                    </ul>
                </Panel>
                <Panel header={headerView} key="45">
                    <ul className="sub-menu-collapse">
                        <a href="https://app.bscs.finance/home-page">
                            <li>View</li>
                        </a>
                    </ul>
                </Panel>

                <Panel header={headerNFT} key="4">
                    <ul className="sub-menu-collapse">
                        <Link to="/nft-megamarket" onClick={onClose}>
                            <li>Megamarket</li>
                        </Link>

                        <Link to="/nft-genesis" onClick={onClose}>
                            <li>Genesis Market</li>
                        </Link>

                        <Link to="/stakeNFT" onClick={onClose}>
                            <li>Stake NFT</li>
                        </Link>
                        <Link to="/MyArtwork" onClick={onClose}>
                            <li>My NFT Artworkst</li>
                        </Link>
                    </ul>
                </Panel>
                <Panel header={headerEarn} key="5">
                    <ul className="sub-menu-collapse">
                        <Link to="/Farms" onClick={onClose}>
                            <li>Farms</li>
                        </Link>
                        <Link to="/launch-pool" onClick={onClose}>
                            <li>Launch Pools</li>
                        </Link>
                        <Link to="/Pools" onClick={onClose}>
                            <li>Start Pools</li>
                        </Link>
                        <Link to="/IDOPools" onClick={onClose}>
                            <li>IDO Pools</li>
                        </Link>

                        <Link to="/Airdrop" onClick={onClose}>
                            <li>Airdrop</li>
                        </Link>
                    </ul>
                </Panel>

                <Panel header={headerMore} key="9">
                    <ul className="sub-menu-collapse">
                        <li>
                            <a href="https://github.com/BSCStationSwap" target={"_blank"}>
                                Github
                            </a>
                        </li>
                        <li>
                            <a href="https://docs.bscs.finance/" target={"_blank"}>
                                Docs
                            </a>
                        </li>
                        <li>
                            <a href="https://bscs.substack.com/" target={"_blank"}>
                                Blog
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://skynet.certik.com/projects/bscs"
                                target={"_blank"}
                            >
                                Certik Audit
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BSCStationStartPools-v1.0.pdf"
                                target={"_blank"}
                            >
                                PeckShield Audit
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/Quillhash/QuillAudit_Reports/blob/master/BSCS%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf"
                                target={"_blank"}
                            >
                                Quillhash Audit
                            </a>
                        </li>
                        <li>
                            <a href={`${SolidProof_URL}`} target="_blank" rel="noreferrer">
                                SolidProof
                            </a>
                        </li>
                        <Link to="/bscs-verify" onClick={onClose}>
                            <li>BSCS Verify</li>
                        </Link>
                    </ul>
                </Panel>
            </Collapse>
        </>
    );

    const headerMobileDrawer = (
        <>
            <div className="main-logo-mobile-row">
                <div className="box-img">
                    <Link to={"/"}>
                        <img src="/images/logo-brand-v3.svg" alt="" />
                    </Link>
                </div>
            </div>
        </>
    );

    if (isMobile) {
        return (
            <>
                <div
                    className={
                        scroll ? "main-header-mobile scroll" : "main-header-mobile"
                    }
                >
                    <div className="main-logo-mobile">
                        <div className="box-img">
                            <Link to={"/"}>
                                <img src="/images/logo-brand-v3.svg" alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className="main-cnt-mobile">
                        <WagmiConnect />
                    </div>

                    <div className="main-menu-mobile">
                        <div className="drawer-menu">
                            <Button type="primary" className="btn-open" onClick={showDrawer}>
                                <img src="/images/open-menu.png" alt="" />
                            </Button>
                            <Drawer
                                title={headerMobileDrawer}
                                className="drawer-mobile"
                                placement="right"
                                onClose={onClose}
                                visible={visible}
                                destroyOnClose={true}
                            >
                                {menuMobile}
                                <div className="footer-menu-mobile">
                                    <div className="content-ft-menu">
                                        <ul className="list-menu-ft">
                                            <li>
                                                <a
                                                    href="https://x.com/bscstation"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src="/images/twi.svg?v=2" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://bscs.substack.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src="/images/substack.png" alt="" />
                                                </a>
                                            </li>

                                            <li>
                                                <a
                                                    href="https://t.me/bscstation_media"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src="/images/tele.svg" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.facebook.com/BSCStation"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src="/images/face-v3.png" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.youtube.com/c/BSCStationOfficial"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src="/images/you.svg" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="price-ft mobile">
                                        <div className="price-l">
                                            <div className="img-price-ft">
                                                <a
                                                    href="https://bscscan.com/token/0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d"
                                                    target="_blank"
                                                >
                                                    <img src="/images/icon-price.png" alt="" />{" "}
                                                </a>
                                            </div>
                                            <span>${priceBSCS}</span>
                                        </div>
                                        <div className="price-r ">
                                            <div className="icon-meta">
                                                <img src="/images/icon-meta.png" alt="" />
                                            </div>
                                            <button type="button" className="btn-buy-bscs">
                                                Buy BSCS
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const menuHeader = (
        <>
            <Menu mode="horizontal">
                <Menu.SubMenu key="SubMenu" title="Launchpad" icon={iconHome}>
                    <Menu.Item key="1">
                        <Link to="/Ido">
                            <div className="ti-menu">
                                <span className="textMenu">IDO</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to="/InoLaunchPad">
                            <div className="ti-menu">
                                <span className="textMenu">INO</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>

                </Menu.SubMenu>

                <Menu.Item key="SubMenu232" icon={iconSwap}>
                    <a href="https://app.bscs.finance/swap" target="blank">
                        <div className="txt-menu-v3">
                            Swap{" "}
                            <span>
                                <img
                                    src="../images/new-v3.png"
                                    alt=""
                                    className="rotate-animation"
                                />
                            </span>
                        </div>
                    </a>
                    {/* <a href="#" target="blank">
                        <div className="txt-menu-v3">
                            Swap <span><img src="./images/soon.png" alt="" className="rotate-animation" /></span>
                        </div>
                    </a> */}
                </Menu.Item>
                <Menu.Item key="SubMenu31" icon={iconView}>
                    <a href="https://app.bscs.finance/home-page" target="blank">
                        <div className="txt-menu-v3">
                            View{" "}
                            <span>
                                <img
                                    src="../images/new-v3.png"
                                    alt=""
                                    className="rotate-animation"
                                />
                            </span>
                        </div>
                    </a>
                    {/* <a href="#" target="blank">
                        <div className="txt-menu-v3">
                            View <span><img src="./images/soon.png" alt="" className="rotate-animation" /></span>
                        </div>
                    </a> */}
                </Menu.Item>

                <Menu.SubMenu key="SubMenu4" title="NFT" icon={iconNFT}>
                    <Menu.Item key="7">
                        <Link to="/nft-megamarket">
                            <div className="ti-menu">
                                <span className="textMenu">Megamarket</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="8">
                        <Link to="/nft-genesis">
                            <div className="ti-menu">
                                <span className="textMenu">Genesis Market</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="9">
                        <Link to="/stakeNFT">
                            <div className="ti-menu">
                                <span className="textMenu">Stake NFT</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="10">
                        <Link to="/MyArtwork">
                            <div className="ti-menu">
                                <span className="textMenu">My NFT Artworks</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="SubMenu5" title="Earn" icon={iconEarn}>
                    <Menu.Item key="11">
                        <Link to="/Farms">
                            <div className="ti-menu">
                                <span className="textMenu">Farms</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="14">
                        <Link to="/launch-pool">
                            <div className="ti-menu">
                                <span className="textMenu">Launch Pools</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="12">
                        <Link to="/Pools">
                            <div className="ti-menu">
                                <span className="textMenu">Start Pools</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="13">
                        <Link to="/IDOPools">
                            <div className="ti-menu">
                                <span className="textMenu">IDO Pools</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="30">
                        <Link to="/Airdrop">
                            <div className="ti-menu">
                                <span className="textMenu">Airdrop</span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="SubMenu7" icon={iconMore}>
                    <Menu.Item key="15">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a href="https://github.com/BSCSGlobal" target={"_blank"}>
                                    Github
                                </a>
                            </span>
                            <span className="iconMenu">
                                {" "}
                                {/* <img src="/images/arrow-right.svg" alt="" /> */}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="20">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a href="https://docs.bscs.finance/" target={"_blank"}>
                                    Docs
                                </a>
                            </span>
                            <span className="iconMenu">
                                {" "}
                                {/* <img src="/images/arrow-right.svg" alt="" /> */}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="21">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a href="https://bscstation.substack.com/" target={"_blank"}>
                                    Blog
                                </a>
                            </span>
                            <span className="iconMenu">
                                {" "}
                                {/* <img src="/images/arrow-right.svg" alt="" /> */}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="22">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a
                                    href="https://skynet.certik.com/projects/bscs"
                                    target={"_blank"}
                                >
                                    Certik Audit
                                </a>
                            </span>
                            <span className="iconMenu">
                                {" "}
                                {/* <img src="/images/arrow-right.svg" alt="" /> */}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="23">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a
                                    href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BSCStationStartPools-v1.0.pdf"
                                    target={"_blank"}
                                >
                                    PeckShield Audit
                                </a>
                            </span>
                            <span className="iconMenu">
                                {" "}
                                {/* <img src="/images/arrow-right.svg" alt="" /> */}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="24">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a
                                    href="https://www.quillaudits.com/smart-contract-audit"
                                    target={"_blank"}
                                >
                                    QuillAudits
                                </a>
                            </span>
                            <span className="iconMenu">
                                {" "}
                                {/* <img src="/images/arrow-right.svg" alt="" /> */}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="25">
                        <div className="ti-menu">
                            <span className="textMenu">
                                <a href={`${SolidProof_URL}`} target="_blank" rel="noreferrer">
                                    SolidProof
                                </a>
                            </span>
                            <span className="iconMenu"></span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="99">
                        <Link to="/bscs-verify">
                            <div className="ti-menu">
                                <span className="textMenu" style={{ color: "rgba(0,0,0,.85)" }}>
                                    BSCS Verify
                                </span>
                                <span className="iconMenu">
                                    {" "}
                                    {/* <img src="/images/arrow-right.svg" alt="" /> */}
                                </span>
                            </div>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        </>
    );

    return (
        // -----------------------------------
        <div className="wrap-header">
            <header className={scroll ? "main-header scroll" : "main-header"}>
                {/* <header className="main-header"> */}
                <div className="main-guide">
                    <div className="main-logo">
                        <div className="box-img">
                            <Link to={"/"}>
                                <img src="/images/logo-brand-v3.svg" alt="" />
                            </Link>
                        </div>
                    </div>
                    {pathLiveCenter === "usercenter" ||
                        pathLiveCenter === "stakeNFT" ||
                        pathLiveCenter === "MyArtwork" ? (
                        <>
                            {scrollBg ? (
                                <>
                                    <div className="main-menu">{menuHeader}</div>
                                </>
                            ) : (
                                <>
                                    {/* <div className="main-menu white">{menuHeader}</div> */}
                                    <div className="main-menu">{menuHeader}</div>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="main-menu">{menuHeader}</div>
                    )}
                </div>
                <div className="main-connect">
                    {/* <div className="cnt-left">
                        <div className="img-price">
                            <a
                                href="https://bscscan.com/token/0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d"
                                target="_blank"
                            >
                                <img src="/images/icon-price.png" alt="" />
                            </a>
                        </div>
                        <span>${priceBSCS}</span>
                    </div> */}

                    <div className="cnt-right">
                        <WagmiConnect />
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
