/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BrandBanner } from "./BrandBanner";
import { BrandDownload } from "./BrandDownload";

const Brand = () => {
  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);
  return (
    <>
      <BrandBanner />
      <BrandDownload />
    </>
  );
};
export default Brand;
