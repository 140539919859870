const switchNetwork = async (chainId : any) => {
  const provider: any = (window as Window).ethereum
  if (provider) {
    try {
      if (provider?.selectedAddress && chainId) {
        await provider
          .request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId,
              },
            ],
          })
          .then(() => {
            // window.location.reload()
            
          })
      }
    } catch (error:any) {
      if (error.code === 4902) {
        try {
          if (chainId ==='0x89') {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: 'Polygon Mainnet',
                  chainId: '0x89',
                  rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  }
                },
              ],
            }); 
          }
        } catch (addError) {
          console.error(addError);
        }
      }
      console.error(error);
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
  }
}

export default switchNetwork
