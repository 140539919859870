import {
  API_NFTs_MARKET_URL,
  API_URL
} from "../constants/index";


export async function getListNFTMarket(param) {
  try {

    const response = await fetch(`${API_NFTs_MARKET_URL}/unicorn/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket', error)
    throw error;
  }
}
export async function getListAttribute() {
  try {

    const response = await fetch(`${API_NFTs_MARKET_URL}/unicorn/collection`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket', error)
    throw error;
  }
}
export async function getListReport() {
  try {

    const response = await fetch(`${API_NFTs_MARKET_URL}/unicorn/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket', error)
    throw error;
  }
}
export async function getMarketInfor() {
  try {
    const response = await fetch(`${API_NFTs_MARKET_URL}/Market/api/get_market`, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    });
    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error("Error getMarketInfor:", error);
    throw error;
  }
}
export async function getData(url) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getData:", error);
    throw error;
  }
}
export async function postData(url, body) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error postData', error)
    throw error;
  }
}
export async function putData(url, body) {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error putData', error)
    throw error;
  }
}
export async function deleteData(url, body) {
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error deleteData', error)
    throw error;
  }
}
