/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import {
  API_URL,
  BSCSCAN_API_MAINNET,
  TOKEN_BSCS,
  BSCSCAN_APIKEY,
} from "../../constants/index";

const StoreInoPool = createStore({
  initialState: {
    inoListPool: [],
    yourBalanceBSCS: {},
    totalTvl: 0,
  },
  actions: {
    updateTVL:
      (totalTvl) =>
      ({ getState, setState }) => {
        setState({ totalTvl });
      },
    getInfoInoPool:
      (params) =>
      ({ getState, setState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/pool/new_infos`).then((res) => {
            const {
              data: { data },
            } = res;
            // console.log("res" , res)
            setState({ inoListPool: res.data.data } || []);
            resolve(data);
          });
        });
      },
    getBalanceByWallet:
      (addresswallet) =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios(
            `${BSCSCAN_API_MAINNET}/api?module=account&action=tokenbalance&contractaddress=${TOKEN_BSCS}&address=${addresswallet}&apikey=${BSCSCAN_APIKEY}`
          ).then((res) => {
            // console.log("res: ", res);
            const { data } = res;
            setState({ yourBalanceBSCS: { ...data } }); // TODO
            resolve(data);
          });
        });
      },
    // getPrice: (params) => async ({ setState, getState }) => {
    //   try {
    //     axios
    //     .get("https://api.coingecko.com/api/v3/coins/markets", {
    //           params: params,
    //         }).then((res1)=>{
    //          // console.log('res1: ', res1)
    //   setState({price : res1.data[0].current_price})
    // })
    //   } catch (err) {
    //     // TODO
    //   }
    // },
  },
  name: "StoreInoPool",
});

export const useHookInoPool = createHook(StoreInoPool);
export const Container = createContainer(StoreInoPool, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreInoPool);
