import {
  Account,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TokenAccountNotFoundError,
  TokenInvalidAccountOwnerError,
  TokenInvalidMintError,
  TokenInvalidOwnerError,
  TOKEN_PROGRAM_ID
} from '@solana/spl-token';
import {
  Connection,
  PublicKey,
  Transaction,
  Commitment,
  ConfirmOptions,
  ParsedAccountData
} from '@solana/web3.js';
import {
  SignerWalletAdapterProps,
  WalletAdapterProps
} from '@solana/wallet-adapter-base';

import { confirmTransaction } from './web3-helper';

export async function getOrCreateAssociatedTokenAccountCustom(
  connection: Connection,
  payer: PublicKey,
  mint: PublicKey,
  owner: PublicKey,
  sendTransaction: WalletAdapterProps['sendTransaction'],
  allowOwnerOffCurve = false,
  commitment?: Commitment,
  confirmOptions?: ConfirmOptions,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
): Promise<Account> {
  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    allowOwnerOffCurve,
    programId,
    associatedTokenProgramId
  );
  console.log(`associatedToken: ${associatedToken.toString()}`);

  let account: Account;
  try {
    account = await getAccount(
      connection,
      associatedToken,
      commitment,
      programId
    );
  } catch (error: unknown) {
    if (
      error instanceof TokenAccountNotFoundError ||
      error instanceof TokenInvalidAccountOwnerError
    ) {
      try {
        const transaction = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            payer,
            associatedToken,
            owner,
            mint,
            programId,
            associatedTokenProgramId
          )
        );

        const signature = await sendTransaction(transaction, connection, {
          preflightCommitment: 'finalized'
        });

        confirmTransaction(connection, signature);
      } catch (error: unknown) {
        console.error(`Create ATA error ${JSON.stringify(error, null, 2)}`);
      }

      account = await getAccount(
        connection,
        associatedToken,
        commitment,
        programId
      );
    } else {
      throw error;
    }
  }

  if (!account.mint.equals(mint)) throw new TokenInvalidMintError();
  if (!account.owner.equals(owner)) throw new TokenInvalidOwnerError();

  return account;
}