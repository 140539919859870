import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'


// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
    try {
      return getAddress(value)
    } catch {
      return false
    }
  }

export const chainHeader = (value: any) => {
    switch (value) {
      case 1:
        return "erc";
      case 56:
        return "bep";
      case 137:
        return "poly";
      case 42161:
        return "arb";
      case 43114:
        return "avax";
      case 10:
        return "op";
      case 2000:
        return "doge";
      case 250:
        return "fmt";
      case 8453:
        return "base";
      case 204:
        return "opbnb";
      default:
        return "";
    }
  };

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked()
  }

  // account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library
  }



// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract  {
    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}