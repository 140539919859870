/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { BigNumber } from "bignumber.js";
import {
  Input,
  Select,
  Row,
  Col,
  Breadcrumb,
  Progress,
  message,
  Space,
  Skeleton,
} from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./style.css";
import { postData } from "../../../axios/marketNFT";
import {
  CloseModal,
  TitleModal,
  ButtonCommon,
} from "./../../../components/common/styleCommon";
import {
  API_NFTs_MARKET_URL,
  MINT_NFT_CONTRACT,
  BSCS_CONTRACT,
  TOTAL_NFT_MINT,
} from "../../../constants/index";
import Loading from "./../../../components/Loading";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import { getInforContractMint } from "../../../hooks/getInforContractMint";
import { rendererCountDown } from "./../../../utils/formats";
import { _mintToken } from "./../../../utils/utilsNFT";
import moment from "moment";
import { notiError, notiSuccess } from "./../../../utils/notication";
import abiMintNFT from "../../../abi/abiMintNFT.json";
import Value from "../../../components/Value/Value";
import Web3 from "web3";
import { ModalContext } from "../../../components/modal";
import AttributeModal from "./AttributeModal";
import MultiAttributeModal from "./MultiAttributeModal";
import { useHookGetOwner } from "./store";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import { useContract } from "../../../hooks/useContract";
import { AbiItem } from "web3-utils";
import switchNetworkChain from "../../../utils/walletChain";
import { useAccount, useChainId } from "wagmi";

declare const window: Window & typeof globalThis & { ethereum: any };

const GetYourOwner = () => {
  const { onOpenModal }: any = useContext(ModalContext);
  const [state, actions]: any = useHookGetOwner();
  const web3 = new Web3(window.ethereum);
  const { address } = useAccount();

  const chainId = useChainId()
  let account = address
  const [loadingMint, setLoadingMint] = useState<any>(false);
  const [balance, setBalance] = useState<any>("0");
  const [mAmount, setMAmount] = useState<any>("0");
  const [mFee, setMFee] = useState<any>("0");
  const [dataMint, setDataMint] = useState<any>();
  const [dataSign, setSign] = useState<any>();
  const [dataSchedule, setDataSchedule]: any = useState<any>();
  const [loadingSwitch, setLoadingSwitch] = useState<any>(false);

  let time = moment(dataSchedule?.mintStartTime * 1000).format(
    "MMM DD YYYY h:mm A"
  );
  const contractMint: any = useContract(MINT_NFT_CONTRACT, abiMintNFT);
  const mint = new web3.eth.Contract(
    abiMintNFT as unknown as AbiItem,
    MINT_NFT_CONTRACT
  );
  // console.log("---------------");
  // console.log("MINT_NFT_CONTRACT", MINT_NFT_CONTRACT);
  const getDataSchedule = async () => {
    let dataS = await actions.getSchedule();
    setDataSchedule(dataS);
  };

  useEffect(() => {
    getDataSchedule();
  }, []);

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "Auction is one of the most prominent features of the BSCS platform. This is a highly efficient mechanism for realizing a resource configuration in the face of inadequate information"
      );
  }, []);
  const switchChain = async () => {
    try {
      if (chainId !== 56) {
        setLoadingSwitch(true);
        await switchNetworkChain("0x38", "bep");
        setLoadingSwitch(false);
      }
    } catch (error) {
      console.error("error Switch", error);
      setLoadingSwitch(false);
    }
  };
  const getContractMint = async () => {
    const objMint = await getInforContractMint();
    setDataMint(objMint);
  };
  useEffect(() => {
    if (!dataMint) {
      getContractMint();
    }
  }, [dataMint]);

  const getBalanceBNB = async () => {
    if (account) {
      const balanceTowei = await web3.eth.getBalance(account);
      const balance = await convertFromWei(balanceTowei, 18);
      setBalance(balance);
    } else {
      setBalance("0");
    }
  };

  useEffect(() => {
    getBalanceBNB();
  }, [account]);

  const getContract = async () => {
    if (account) {
      const amount = await mint.methods.walletToMinted(account).call();
      const fee = await mint.methods.walletToFreeMinted(account).call();
      setMAmount(amount);
      setMFee(fee);
    }
  };

  useEffect(() => {
    getContract();
  }, [account]);
  console.log("walletToMinted", mAmount);

  // const getSign = async () => {
  //   const data: any = {
  //     ownerAddress: account,
  //   };
  //   await postData(`${API_NFTs_MARKET_URL}/unicorn/gen_sign`, data).then(
  //     (data) => {
  //       if (data?.data) {
  //         setSign(data?.data);

  //       }
  //     }
  //   );
  // };
  // useEffect(() => {
  //   getSign();

  // }, [account]);
  // console.log('dataSign',dataSign)

  const handleMintNFT = async () => {
    try {
      if (account && dataMint?.mintFee) {
        setLoadingMint(true);
        let data: any = {
          ownerAddress: account,
        };
        const item = await postData(
          `${API_NFTs_MARKET_URL}/unicorn/get_tokenid`,
          data
        );
        const sign = await postData(
          `${API_NFTs_MARKET_URL}/unicorn/gen_sign`,
          data
        );
        if (item?.data && sign?.data) {
          console.log("walletToFreeMinted", Number(mFee));
          console.log("tier)", Number(sign?.data?.tier));
          await _mintToken(
            contractMint,
            account,
            sign?.data?.tier,
            item?.data?.tokenId,
            Number(mFee) < Number(sign?.data?.tier)
              ? 0
              : convertToWei(dataMint?.mintFee, 18),
            sign?.data?.sign
          )
            .then((resMint: any) => {
              if (resMint?.hash) {
                let resData: any = {
                  queryKey: item?.data?.queryKey,
                  ownerAddress: account,
                  txHash: resMint?.hash,
                  type: 1,
                };
                postData(`${API_NFTs_MARKET_URL}/unicorn/create_hash`, resData);
              }
              resMint.wait().then((resSuccess: any) => {
                if (resSuccess) {
                  getContract();
                  getDataSchedule();
                  let dataMint: any = {
                    ownerAddress: account,
                    queryKey: item?.data?.queryKey,
                    txHash: resSuccess?.transactionHash,
                  };
                  postData(
                    `${API_NFTs_MARKET_URL}/unicorn/mint_nft`,
                    dataMint
                  ).then((resUpdate) => {
                    notiSuccess("Mint successfully", 5);
                    if (resUpdate?.succeeded) {
                      onOpenModal(
                        <AttributeModal
                          data={resUpdate?.data}
                          queryKey={item?.data?.queryKey}
                        />
                      );
                    }
                  });
                  setLoadingMint(false);
                  getContractMint();
                }
              });
            })
            .catch((err: any) => {
              console.error("error Mint", err);
              notiError(err?.data?.message, 5);
              setLoadingMint(false);
            });
        } else {
          console.error("error get_tokenid", item?.data?.message);
          setLoadingMint(false);
        }
      }
    } catch (error: any) {
      console.error("error getListNFT", error);
      notiError(error?.message, 5);
      setLoadingMint(false);
    }
  };
  const onShowModal = () => onOpenModal(<MultiAttributeModal />);
  return (
    <>
      <div className="mint-nft-page">
        <div className="container">
          <Row gutter={30}>
            <Col xs={24} sm={12} xl={12} md={12}>
              <div className="text-left">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <a className="" href="/nft-megamarket">
                      <ArrowLeftOutlined /> Back
                    </a>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="mint-nft-content">
                <h3 className="mint-nft-title text-left">
                  {dataSchedule?.title}
                </h3>
                <div className="mint-nft-description text-left">
                  {dataSchedule?.decs}
                </div>
                {isMobile ? (
                  <div className="gif-mo">
                    <Col xs={24} sm={12} xl={12} md={12}>
                      <div className="gif-nft-block">
                        <img src="../images/mint-nft-audi.gif" alt="" />
                      </div>
                    </Col>
                  </div>
                ) : (
                  ""
                )}
                <div className="mint-nft-progress text-left">
                  <span className="progress-title">Available items</span>
                  <Progress
                    className="mint-progress"
                    strokeColor={{ "0%": "#FBC843", "100%": "#FBC843" }}
                    percent={Number(
                      (
                        (dataMint?.totalSupply / TOTAL_NFT_MINT) * 100 || 0
                      ).toFixed(2)
                    )}
                  />
                  <div className="progress-number-sold">
                    <Value
                      decimals={0}
                      value={Number(dataMint?.totalSupply || "0")}
                    />
                    /{" "}
                    <Value
                      decimals={0}
                      value={Number(TOTAL_NFT_MINT || "0")}
                      unitafter=" NFT"
                    />
                  </div>
                </div>

                <div className="mint-nft-form">
                  <div className="mint-form-title">Open Minting:</div>
                  <div className="mint-form-balance">
                    {!dataMint ? (
                      <>
                        <Skeleton.Avatar active={true} />
                        <Skeleton.Button active={true} />
                        <Skeleton.Input active={true} />
                      </>
                    ) : (
                      <>
                        <span>
                          <img
                            width="25px"
                            src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/e3828a3eb62a65a551423b45d04a4c816e92d347be968298bb.png?v=152"
                            alt=""
                          />
                        </span>
                        <span>
                          <Value value={dataMint?.mintFee} unitafter={"BNB"} />
                        </span>
                      </>
                    )}
                  </div>
                  <div className="mint-form-button">
                    {account ? (
                      <>
                        {chainId !== 56 && (
                          <button
                            className="btn-buy-nft-new "
                            disabled={loadingSwitch}
                            onClick={switchChain}
                          >
                            <Loading
                              status={loadingSwitch}
                              content="Switch Chain"
                            />
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="mint-form-button">
                          <button
                            type="button"
                            disabled
                            className="btn btn-stake btn-full"
                          >
                            Please Connect Wallet
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {account && chainId === 56 && (
                    <>
                      {/* <div className="mint-form-button">
                        <button
                          type="button"
                          disabled={
                            loadingMint ||
                            !account ||
                            chainId !== 56 ||
                            // Number(balance) < dataMint?.mintFee ||
                            !dataSchedule?.fcfs?.status ||
                            Number(dataSchedule?.fcfs?.limitPerAddress) <=
                              Number(mAmount)
                          }
                          className="btn btn-stake btn-full"
                          onClick={handleMintNFT}
                        >
                          <Loading status={loadingMint} content={"Mint"} />
                        </button>
                      </div> */}
                    </>
                  )}

                  {/* <div
                    className="mint-form-button "
                    style={{ paddingTop: "10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-stake btn-full"
                      onClick={onShowModal}
                    >
                      TEST
                    </button>
                  </div> */}
                </div>

                {Number(dataMint?.totalSupply) >= TOTAL_NFT_MINT ? (
                  <>
                    <div className="mint-nft-remaning">
                      <div className="mint-remaning-title">Mint sold out</div>
                      <div className="mint-remaning-time">
                        <p>All items were minted</p>
                        <div className="mint-remaning-button">
                          <Link to="/MyArtwork">
                            <span className="btn btn-stake btn-full">
                              View Collection
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mint-nft-remaning">
                      <div className="mint-remaning-title">
                        Mint starts {dataSchedule?.mintStartDate}
                      </div>
                      {!dataSchedule?.fcfs?.status && (
                        <>
                          {" "}
                          <div className="mint-remaning-time">
                            <p>Start in:</p>

                            <Countdown
                              date={time}
                              renderer={rendererCountDown}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="mint-schedule">
                      <h4>Mint schedule</h4>

                      <div className="mint-schedule-block">
                        <div className="mint-schedule-title">
                          <span>{dataSchedule?.fcfs?.title}</span>
                          {dataSchedule?.fcfs?.status ? (
                            <span className="is-eligible">Eligible</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mint-schedule-date">
                          {/* {moment(1702564955289)
                            .utcOffset(7)
                            .format("MMMM D [at] hh:mm A")} */}
                          {dataSchedule?.fcfs?.fromDate}
                        </div>
                        <div className="mint-schedule-amount">
                          {dataSchedule?.fcfs?.price}{" "}
                          {dataSchedule?.fcfs?.symbol}
                        </div>

                        <div className="mint-schedule-limit">
                          Limit: 3,000 NFT, max{" "}
                          {dataSchedule?.fcfs?.limitPerAddress} NFT per wallet
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
            {isMobile ? (
              ""
            ) : (
              <Col xs={24} sm={12} xl={12} md={12}>
                <div className="gif-nft-block">
                  <img src="../images/mint-nft-audi.gif" alt="" />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};
export default GetYourOwner;
