/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import bgMainheader from "../LauchPool/images/banner-ino.png";
import './style.css'
import Stake from "./Stake";
import UnStake from "./Unstake";
import Withdraw from "./Withdraw";
import '../LauchPool/style.css'
import { useContract } from "../../hooks/useContract";
import abiStake from "./utils/abiStake.json"
import { SC_STAKE_BSCS } from "../../constants";
import { ADDRESS_BSCS_ADDRESS } from "../../constants";
import abiBSCS from "../../abi/abiBSCS.json";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useAccount } from "wagmi";

const StakePoolV2 = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const { TabPane } = Tabs;
    const [defaultKey, setDefaultKey] = useState("stake");
    const pathTabsLive = location.search.split("?");
    const tabBox = pathTabsLive[1] === undefined ? "stake" : pathTabsLive[1];

    const { address } = useAccount();

    let account = address
    const contractStake: any = useContract(SC_STAKE_BSCS, abiStake);

    let web3: any = new Web3('https://bsc-dataseed4.defibit.io');
    const contractStake1 = new web3.eth.Contract(
        abiStake as unknown as AbiItem,
        SC_STAKE_BSCS
    );

    const [totalStaked, setTotalStaked]: any = useState(0)
    const [numberStaked, setNumberStaked]: any = useState(0)
    const [isCheckAction, setIsCheckAction]: any = useState(false)

    function callback(key: any) {
        setDefaultKey(key);
        navigate(`${"/launch-pool"}?${key}`);
    }

    useEffect(() => {
        if (tabBox) {
            setDefaultKey(tabBox);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabBox]);

    // const getBscs = async () => {
    //     if (bscsContract) {
    //         const amount = await bscsContract.balanceOf(SC_STAKE_BSCS);
    //         setTotalStaked(amount.toString() / 1e18);
    //     } else {
    //         setTotalStaked(0)
    //     }
    // };

    // console.log('contractStake', contractStake)
    // console.log('contractStake1', contractStake1)

    const getTotalStaked = async () => {
        // await contractStake.tokenTotalStaked().then((res: any) => {
        //     setTotalStaked(res / 1e18)
        // })
        //     .catch((err: any) => {
        //         console.log('err', err)
        //         setTotalStaked(0)
        //     })
        await contractStake1.methods.tokenTotalStaked().call()
            .then((res: any) => {
                // console.log('res11------', res)
                setTotalStaked(res / 1e18)
            })
            .catch((err: any) => {
                // console.log('err11-------', err)
                setTotalStaked(0)
            })

    }

    const getNumberStaked = async () => {
        try {
            // await contractStake.stakerCount().then((res: any) => {

            //     setNumberStaked(Number(res))
            // })
            //     .catch((err: any) => {
            //         setNumberStaked(0)
            //         console.log('err', err)
            //     })

            await contractStake1.methods.stakerCount().call()
                .then((res: any) => {
                    // console.log('res22------', res)
                    setNumberStaked(Number(res))
                })
                .catch((err: any) => {
                    // console.log('err22-------', err)
                    setNumberStaked(0)
                })

        } catch (error) {

        }

    }
    useEffect(() => {
        if (contractStake1) {
            getNumberStaked()
        }

    }, [contractStake, isCheckAction]);

    useEffect(() => {

        if (contractStake1) {
            getTotalStaked()
        }
    }, [account, contractStake, isCheckAction]);



    const formatNumber = (x: any, max: any) => {
        if (x) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };

    return (
        <>
            <div
                className="main-stake-pool-v2"
                style={{
                    background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
                }}
            >
                <div className="container-stake">
                    <div className="big-title">Launch Pool</div>
                    <div className="item-info">
                        <div className="item-1">
                            <div className="title-item">Number of Stakers</div>

                            <div className="num-item">2{numberStaked}</div>
                        </div>
                        <div className="item-1">
                            <div className="title-item">Total BSCS Staked</div>
                            {totalStaked && totalStaked > 0 ? (
                                // <div className="num-item">{totalStaked} BSCS</div>
                                <div className="num-item">{formatNumber(totalStaked, 4)} BSCS</div>
                            ) : (
                                <div className="num-item">0 BSCS</div>
                            )}

                        </div>
                        <div className="item-1">
                            <div className="title-item">APY</div>
                            <div className="num-item">3%</div>
                        </div>
                    </div>

                    <div className="tabs-stake-pool v2">
                        <Tabs activeKey={defaultKey} onChange={callback}>
                            <TabPane tab="Stake" key="stake">
                                <Stake tabBox={tabBox} setIsCheckAction={setIsCheckAction} isCheckAction={isCheckAction} />
                            </TabPane>
                            <TabPane tab="Unstake" key="unstake">
                                <UnStake tabBox={tabBox} setIsCheckAction={setIsCheckAction} isCheckAction={isCheckAction} />
                            </TabPane>
                            <TabPane tab="Withdraw" key="withdraw">
                                <Withdraw tabBox={tabBox} setIsCheckAction={setIsCheckAction} isCheckAction={isCheckAction} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StakePoolV2;
