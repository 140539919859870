/* eslint-disable default-case */
/* eslint-disable no-mixed-operators */
import web3 from "web3";
import BigNumber from "bignumber.js";
import { NETWORK, CHAIN } from "./index.d";
import { ethers } from "ethers";

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), "ether");
  if (decimal !== 18)
    amount = new BigNumber(value)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  return amount;
};

export const convertWeiToToken = (value, decimal) => {
    if (decimal === 18) return web3.utils.fromWei(value.toString(), "ether");
    else if (decimal === 6)
        return web3.utils.fromWei(value.toString(), "picoether");
    else if (decimal === 9)
        return web3.utils.fromWei(value.toString(), "nanoether");
    else if (decimal === 12)
        return web3.utils.fromWei(value.toString(), "microether");
    else if (decimal === 15)
        return web3.utils.fromWei(value.toString(), "milliether");
    else return value.toString() / 10 ** decimal;
};

export const _claimTokenRef = async (claimContract, reward , keyId , sign , tokenDecimals) => {
    const amount = convertTokenToWei(reward, tokenDecimals)
    let args = [amount, keyId, sign]
    const estimatedGas = await claimContract.estimateGas.claimTokens(...args)
    return claimContract.claimTokens(...args, {
    gasLimit: estimatedGas,
    })
}

export const _isClaimedRef = async (poolContract, account) => {
    return poolContract && poolContract.isClaimed(account);
};

