import React, { useState, useCallback } from "react";
import { useContext } from "react";
import { ModalContext } from "../../../../components/modal";
import styled from "styled-components";
import {
  NFTItem,
  RowConfirm,
  RowButton,
  RowInput,
  RowConditions,
  TextConditions,
} from "./style";
import {
  CloseModal,
  TitleModal,
  ButtonCommon,
  TextCustom,
} from "../../../../components/common/styleCommon";
import { FEE_CREATOR, FEE_SERVICE } from "../../../../constants/index";
import Loading from "../../../../components/Loading";
import Value from "../../../../components/Value";
import CheckBox from "../../Upload/components/CheckBox";

const BidNFT = (props: any) => {
  const { onCloseModal }: any = useContext(ModalContext);
  const [val, setVal] = useState("");
  const [pendingTx, setPendingTx] = useState(false);
  const [checked, setChecked] = useState(false);
  const { onConfirm, priceUSD, balanceToken } = props;
  const price_USD = Number(priceUSD) * Number(val || 0);

  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleConfirm = useCallback(async () => {
    try {
      setPendingTx(true);
      await onConfirm(val);
      setPendingTx(false);
      onCloseModal();
    } catch (e) {
      console.error("err handleConfirm", e);
      setPendingTx(false);
    }
  }, [onConfirm, val]);
  return (
    <>
      <FormContainer>
        <TitleModal>Place offer</TitleModal>
        <CloseModal onClick={() => onCloseModal()}> X </CloseModal>
        <NFTItem>
          <RowInput>
            <RowConfirm>
              <TextCustom padding="0px" fontWeight="500">
                Offer price:
              </TextCustom>
              <TextCustom padding="0px" color="rgba(112, 122, 138, 1)">
                Balance: <Value value={Number(balanceToken)} />BSCS
              </TextCustom>
            </RowConfirm>

            <input
              className="input"
              type="number"
              min={0}
              placeholder="0.00"
              value={val}
              onChange={(e: any) => setVal(e.target.value)}
            />
            <RowConfirm>
              <TextCustom padding="5px" color="rgba(112, 122, 138, 1)">
                {price_USD > 0 && <Value value={price_USD} unit="$" />}
              </TextCustom>
              <TextCustom padding="5px" color="rgba(254, 85, 85, 1)">
                {Number(balanceToken) < Number(val) && (
                  <i>*Not enough BSCS to make offer</i>
                )}
              </TextCustom>
            </RowConfirm>
            <RowConditions>
              <TextConditions>
                <CheckBox onChange={onChange} />I have read the{" "}
                <span>Terms and Conditions</span>
              </TextConditions>
            </RowConditions>
          </RowInput>

          <RowButton>
            <ButtonCommon
              disabled={
                pendingTx ||
                !checked ||
                Number(balanceToken) < Number(val) ||
                !val
              }
              onClick={handleConfirm}
            >
              <Loading status={pendingTx} content="Confirm" />
            </ButtonCommon>
          </RowButton>
        </NFTItem>
      </FormContainer>
    </>
  );
};

export default BidNFT;

export const FormContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dce2e9;
  border-radius: 6px;
  @media screen and (min-width: 744px) {
    min-width: 500px;
  }
  @media screen and (max-width: 743px) {
    padding: 15px;
  }
`;
