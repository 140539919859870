/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_NFTs_MARKET_URL } from "../../../constants/index";
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";

const headersConfig = {
    "Content-Type": "application/json",
};

const GetOwnerStore = createStore({
    initialState: {},
    actions: {
        getSchedule:
            () =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios
                            .get(`${API_NFTs_MARKET_URL}/unicorn/schedule`)
                            .then((res) => {
                                // console.log('res unicorn/schedule==>', res)
                                resolve(res.data?.data)
                            })
                            .catch((err) => {
                                console.log('err unicorn/schedule==>', err)
                            })
                    });
                },
    },
    name: "GetOwner Store",
});

export const useHookGetOwner = createHook(GetOwnerStore);
export const Container = createContainer(GetOwnerStore, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(GetOwnerStore);
