/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from "../../constants";

const StoreAirdrop = createStore({
  initialState: {
    airdropListActive: [],
    airdropListJoined: [],
    airdropListEnded: [],
    airdropListMore: [],
    airdropListEndedMore: [],
    airdropListJoinedMore:[],
    airdropItem: {},
  },
  actions: {
    getInfoAirdropActive:
      (params) =>
     
      ({ getState, setState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/airdrop/infos`, { ...params }).then((res) => {
            const {
              data: { data },
            } = res;
            setState({ airdropListActive: res.data.data } || []);
            resolve(data);
          });
        });
    },
    getInfoAirdropJoined:
      (params) =>
     
      ({ getState, setState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/airdrop/infos`, { ...params }).then((res) => {
            const {
              data: { data },
            } = res;
            setState({ airdropListJoined: res.data.data } || []);
            resolve(data);
          });
        });
      },
      getInfoAirdropEnded:
      (params) =>
     
      ({ getState, setState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/airdrop/infos`, { ...params }).then((res) => {
            const {
              data: { data },
            } = res;
            setState({ airdropListEnded: res.data.data } || []);
            resolve(data);
          });
        });
      },
      getInfoAirdropEndedMore:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/airdrop/infos`, params).then((res) => {
            setState({
              airdropListEndedMore:
                getState().airdropListEndedMore.concat(res.data.data) || [],
            });

            const {
              data: { data },
            } = res;
            resolve(data);
          });
        });
      },
      getInfoAirdropJoinedMore:
      (params) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          axios.post(`${API_URL}/airdrop/infos`, params).then((res) => {
            setState({
              airdropListJoinedMore:
                getState().airdropListJoinedMore.concat(res.data.data) || [],
            });

            const {
              data: { data },
            } = res;
            resolve(data);
          });
        });
      },
  },
  name: "StoreAirdrop",
});

export const useHookAirdrop = createHook(StoreAirdrop);
export const Container = createContainer(StoreAirdrop, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreAirdrop);
