/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, Collapse, Button as ButtonConnect } from "antd";
import Value from "../../componentsST/Value";
import "./style.css";
import useModal from "../../hooksST/useModal";
import Button from "../../componentsST/ButtonV2";
import BlockCountdownDetail from "./BlockCountdownDetail";
import ConnectWallet from "../../components/Connect-wallet/ConnectWallet";
import { useCurrentBlock } from "../../store/hooks";
import useSousGetOwner from "./hooks/useSousGetOwner";
import FeeModal from "./Modal/FeeModal";
import RewardModal from "./Modal/RewardModal";
import WithdrawRewardModal from "./Modal/WithdrawRewardModal";
import WithdrawStakeModal from "./Modal/WithdrawStakeModal";
import ConfigModal from "./Modal/ConfigModal"
import { useAccount } from "wagmi";

const PoolManager = ({ pool, userDataLoaded }) => {
  const { address } = useAccount();
  let account = address
  const { userData } = pool;

  //----------------------------------
  // get Owner pool
  const owner = useSousGetOwner(pool.sousId);
  // start update fee
  const [onUpdateFeeInPoolModal] = useModal(<FeeModal sousId={pool.sousId} />);
  // start update reward
  const [onUpdateRewardInPoolModal] = useModal(
    <RewardModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.stakedBalance}
    />
  );
  // start withdraw token reward
  const [onWithdrawRewardInPoolModal] = useModal(
    <WithdrawRewardModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
    />
  );
  // start withdraw token stake
  const [onWithdrawStakeInPoolModal] = useModal(
    <WithdrawStakeModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
    />
  );
  // start configs
  const [onConfigInPoolModal] = useModal(
    <ConfigModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
    />
  );
  // ---------------------------------

  const { Panel } = Collapse;

  const onChangeCollapse = (key) => {
    console.log(key);
  };

  const currentBlock = useCurrentBlock();

  const typepool = (type) => {
    switch (type) {
      case 1:
        return "Stake";
        break;
      case 2:
        return "Farm";
        break;
      case 3:
        return "IDO";
        break;
    }
  };
  const headerPool = (
    <>
      <div className="guide-swap Manager">
        <div className="item-round">
          <div className="name-project">
            <div className="box-logo">
              <>
                {pool.birthday ? (
                  <img
                    src={`/tokens/birthday-${pool.earningToken.symbol.toLowerCase()}.png?v=6`}
                    className="logo-big"
                    alt=""
                  />
                ) : (
                  <>
                    <img
                      src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=6`}
                      className="logo-big"
                      alt=""
                    />
                    <img
                      src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=6`}
                      className={pool.idoLP ? "logo-small-LP" : "logo-small"}
                      alt=""
                    />
                  </>
                )}
              </>
            </div>

            <div className="box-name">
              <span className="title-earn">
                {pool.typePool === 2 || pool.idoLP === true ? (
                  <> {pool.stakingToken.symbol}</>
                ) : (
                  <>
                    {pool.earningToken.symbol} - {pool.stakingToken.symbol}
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">{typepool(pool.typePool)}</span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <Value size={16} decimals={0} value={pool.lockup} />
                {pool.birthday ? "hours" : "day"}
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <Value
                  size={16}
                  unit="$"
                  value={pool.stakedTvl ? +pool.stakedTvl : 0}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt main-color-pool">
                {owner.substr(0, 4)}.....
                {owner.substr(owner.length - 4)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const contentBottom = (
    <>
      <div className="main-manager-pool">
        <div className="item-block">
          <div className="content-block">
            <div className="reward-in  dis-desktop">
              <span className="txt-l">Lock-up term:</span>
              <div className="txt-r">
                {pool.lockup} {pool.birthday ? "hours" : "day"}
              </div>
            </div>
            <div className="reward-in">
              <span className="txt-l">
                {currentBlock >= pool.startBlock
                  ? "Rewards end in"
                  : "Rewards start in"}
              </span>
              <div className="txt-r">
                <BlockCountdownDetail pool={pool} />
              </div>
            </div>
            <div className="view-port pt-30 ">
              <div className="reward-in" style={{ alignItems: "center" }}>
                <a
                  href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="text details">
                    View contract{" "}
                    <span>
                      <img src="/images/pool/p-info.svg" alt="" />
                    </span>
                  </div>
                </a>
                <div className="txt-r">
                  <Button
                    dark
                    disabled={
                      !account || account.toLowerCase() !== owner.toLowerCase()
                    }
                    text="+"
                    click={onConfigInPoolModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-block">
          <div className="content-block">
            <div className="box-stake">
              <div className="title">Update pools</div>
              <div className="box-bottom">
                <div className="round-l">
                  <Button
                    manager
                    disabled={
                      !account || account.toLowerCase() !== owner.toLowerCase()
                    }
                    text="Fee unstake"
                    click={onUpdateFeeInPoolModal}
                  />
                </div>
                <div className="round-r">
                  <Button
                    manager
                    disabled={
                      !account || account.toLowerCase() !== owner.toLowerCase()
                    }
                    text="Reward"
                    click={onUpdateRewardInPoolModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-block">
          <div className="content-block">
            <div className="box-stake">
              <div className="title">Emergency withdraw</div>
              <div className="box-bottom">
                <div className="round-l">
                  <Button
                    manager
                    disabled={
                      !account || account.toLowerCase() !== owner.toLowerCase()
                    }
                    text="Token reward"
                    click={onWithdrawRewardInPoolModal}
                  />
                </div>
                <div className="round-r">
                  <Button
                    manager
                    disabled={
                      !account || account.toLowerCase() !== owner.toLowerCase()
                    }
                    text="Token stake"
                    click={onWithdrawStakeInPoolModal}
                  />
                </div>
              </div>
              <div className="round-connect">
                {!account && (
                  <div className="connect-cus-start-pool">
                    <ConnectWallet />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );

  return (
    <>
      <div className="main-round-pool">
        <div className="round-pool">
          <Collapse onChange={onChangeCollapse}>
            <Panel header={headerPool}>{contentBottom}</Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};
export default PoolManager;
