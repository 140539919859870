/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { CHAIN_NETWORK_APTOS } from "../../../constants/index";
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import { rejects } from "assert";

const StoreIDO_APT = createStore({
  initialState: {},
  actions: {
    get_amount:
      (params) =>
      ({ getState, setState }) => {
        return new Promise((resolve, reject) => {
          axios
            .post(`${CHAIN_NETWORK_APTOS}/view`, params, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              // console.log("res api view", res);
              resolve(res.data);
            })
            .catch((err) => {
              console.log("err api view", err);
              rejects(err.data);
            });
        });
      },
  },
  name: "IDO_APT Store",
});

export const useHookIDO_APT = createHook(StoreIDO_APT);
export const Container = createContainer(StoreIDO_APT, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreIDO_APT);
