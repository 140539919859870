/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Tabs, Input } from "antd";
import PoolManager from "./PoolManager";
import bgMainheader from "./images/pools.png";
import { useFetchPublicPoolsData, usePollBlockNumber } from "../../store/hooks";
import { usePoolsManager } from "../../store/hooks";
import ErrorPage from "../ErrorPage";
import "./style.css";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";

const PoolManagers = () => {
  usePollBlockNumber();
  useFetchPublicPoolsData();
  const { address } = useAccount();
  let account = address
  const { pools, userDataLoaded } = usePoolsManager(account);
  const { Search } = Input;
  let curentChain = localStorage.getItem("chain");
  const { TabPane } = Tabs;

  const [searchQuery, setSearchQuery] = useState("");
  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };
  const contentTop = (
    <>
      <div className="main-round-pool">
        <div className="round-slide Manager">
          <div className="item">
            <span className="name">Project Token</span>
          </div>
          <div className="item">
            <span className="name d-flex">
              Type pool
            </span>
          </div>
          <div className="item">
            <span className="name">Lock-up term</span>
          </div>
          <div className="item">
            <span className="name">Total staked</span>
          </div>
          <div className="item">
            <span className="name">Owner</span>
          </div>
        </div>
      </div>
    </>
  );
  const chainId = useChainId()
  return (
    <>
      {chainHeader(chainId) === "bep" ? (
        <>
          <div
            className="main-startpool"
            style={{
              background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
            }}
          >
            <div className="container">
              <div className="content-startpool">
                <div className="top-startpool ido-pool">
                  <div className="title">Manager Pools</div>
                  <p className="desc">
                    An all-in-one Incubation Hub with a full stack Defi platform{" "}
                    <br />
                    across all main blockchain networks
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container pd-top-20">
            <div className="content-startpool">
              <div className="bottom-startpool" role="table">
                <div className="tabs-startpool">
                  <div className="filter-swap">
                    <div className="switch-filter"></div>
                    <div className="box-search-round">
                      <div className="search-project earn">
                        <Search
                          placeholder="Input token stake"
                          type="text"
                          onChange={handleChangeSearchQuery}
                        />
                        <div className="icon-search">
                          <img src="/images/imgido/search-normal.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Tabs className="tabs-round" defaultActiveKey="1">
                    <TabPane tab="Live" key="1">
                      <>
                        {contentTop}
                        {pools
                          .filter(
                            (pool) =>
                              pool.isFinished === false &&
                              pool.stakingToken.symbol
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                          )
                          .map((pool, index) => (
                            <PoolManager
                              key={index}
                              pool={pool}
                              userDataLoaded={userDataLoaded}
                            />
                          ))}
                      </>
                    </TabPane>
                    <TabPane tab="Finished" key="2">
                      <>
                        {contentTop}
                        {pools
                          .filter(
                            (pool) =>
                              pool.isFinished === true &&
                              pool.stakingToken.symbol
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                          )
                          .map((pool, index) => (
                            <PoolManager
                              key={index}
                              pool={pool}
                              userDataLoaded={userDataLoaded}
                            />
                          ))}
                      </>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorPage />
        </>
      )}
    </>
  );
};
export default PoolManagers;
