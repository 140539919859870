/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useChainId } from "wagmi";
import { Progress, Button, message, Modal, InputNumber, Spin } from "antd";
import { AbiItem } from "web3-utils";
import Web3 from "web3";
import { ScheduleOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { useHookIDO } from "../StoreIdo";
import { STATUS, NETWORK } from "../index.d";
import Value from "./../../../components/Value";
import { useContract, useIdoTokenClaimContract } from "../../../hooks/useContract";
import abiBUSD from "../../../abi/abiBUSD.json";
import abiClaimOneButton from "../Abi/abiClaimOneButton.json";
import abiJoinPoolv2 from "../Abi/abiJoinPoolv2.json"
import abiClaimv2 from "../Abi/abiClaimv2.json"
import ModalClaimedAfter from "../details/ModalClaimedAfter/ModalClaimedAfter";
import {
    _isJoined,
    _approveBUSD,
    getProgressTime,
    rendererCountDown,
    _isRefundedRC,
    _refund,
    _claimTokens,
    _showClaimBtn,
    // _isClaimed,
    _joinPoolNew,
    _refundChange,
    _isClaimChange,
    _totalClaimed,
    totalDailyClaimed,
    convertWeiToToken,
    totalJoinPool,
    _totalRefunded,
    _showBtnRFAll,
} from "../utilsIDO";
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useCounterContract } from '../../TON/hooksTon/useTonContract';
import { useTonConnect } from '../../TON/hooksTon/useTonConnect';
import IdoClaim from '../../TON/hooksTon/tact_IdoClaim'
import { toNano } from "@ton/ton";
import TonWeb from "tonweb";
import { Sender, SenderArguments } from '@ton/core';

const web3 = new Web3("https://bsc-dataseed1.binance.org:443");
const ZERO_ADD = "0x0000000000000000000000000000000000000000"

const Item = ({
    item,
    logo,
    unit,
    symbol,
    decimals,
    network,
    usdDecimals,
    tokenJoinPool,
    symbolUSD,
    priceToken,
    tokenNetwork,
    isRefundSaveDB
}: any) => {
    const userFriendlyAddress: any = useTonAddress();
    const [state, actions] = useHookIDO();
    const address = userFriendlyAddress;
    const chainId = useChainId();
    let navigate = useNavigate();
    //
    const [isJoined, setIsJoined] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reCheck, setReCheck] = useState(false);
    const [isApproveJoin, setApproveJoin] = useState(false);
    const [disnableJoin, setDisnableJoin] = useState(false);
    const [isRefundedRC, setRefundedFromSC] = useState(false);
    const [isLoadingRefund, setLoadingRefund] = useState(false);
    const [isLoadingRefundAll, setLoadingRefundAll] = useState(false);
    const [isRefundAll, setIsRefundAll] = useState(false);
    const [isShowClaim, setIsShowClaim] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoadingClaimChange, setLoadingClaimChange] = useState(false);
    const [isClaimChangeRC, setClaimChangeFromSC] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState<any>(0);
    const [totalClaimDaily, setTotalClaimDaily] = useState<any>(0);
    const [totalJoined, setTotalJoined] = useState<any>(0);
    const [addClass, setAddClass] = useState("");
    const [totalRefunded, setTotalRefunded] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isOpenConfirmRefund, setIsOpenConfirmRefund] = useState(false);
    const [amounJoinNotConnect, setamounJoinNotConnect] = useState(0);
    const [valueSubmit, setValueSubmit] = useState<any>(0);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [idoNative, setIdoNative]: any = useState(false);
    const [isClaimed, setIsClaimed]: any = useState(false);


    // 
    let scClaimAddress = item.allocation && item.allocation?.claimContract;
    if (scClaimAddress === undefined) scClaimAddress = null;
    let scJoinAddress = item.commitFundContract;
    if (scJoinAddress === undefined) scJoinAddress = null;
    let scClaimChangeAddress = item.allocation?.claimChange?.refundContract;
    if (scClaimChangeAddress === undefined) scClaimChangeAddress = null;
    const totalRaise = item.totalRaise;
    const ignoreFullSlot = item.ignoreFullSlot;
    let isFullSlot = false;
    const amountJoin = item.allocation?.joinBusd;
    const joinStatus = item.allocation && item.allocation.joinStatus;
    const allocation = item.allocation;
    const is_allow_joinpool =
        item.allocation &&
        item.allocation.joinSignBusd !== null &&
        item.status === 3;
    const signRefund = item.allocation?.claimChange?.signRefund;
    const is_commit_fund =
        item.allocation && item.allocation.claimChange.refundContract !== null;
    const showPopupClaim = item.showPopupClaim;
    const claimbles = item.allocation && item.allocation.claimbles;
    const joinSignBusd = item.allocation?.joinSignBusd;
    const fundProcess = item.allocation?.claimChange?.fundProcess;
    const refundBusd = item.allocation?.claimChange?.refundBusd;

    const daily_obj =
        item.allocation &&
        item.allocation.claimbles.find((f: any) => f.claimRound === 100);
    const contract_daily =
        daily_obj !== undefined && daily_obj !== null
            ? daily_obj.contractAddress
            : null;

    let _is_refunded_api = 0;
    let _claimedTokens: any =
        parseFloat(totalClaimed.toString()) +
        parseFloat(totalClaimDaily.toString());

    let totalClaimAndRefund: any =
        parseFloat(totalClaimed.toString()) +
        parseFloat(totalClaimDaily.toString()) +
        (priceToken > 0 ? parseFloat((totalRefunded / priceToken).toString()) : 0);

    let _processClaim =
        item.allocation !== null
            ? (totalClaimAndRefund / item.allocation.joinToken) * 100
            : 0;
    if (_is_refunded_api === 1 || isRefundedRC) _processClaim = 100;

    const is_network_bep = network === "bep";
    const is_network_erc = network === "erc";
    const is_network_poly = network === "poly";
    const is_network_okc = network === "okc";
    const is_network_arb = network === "arb";
    const is_network_opbnb = network === "opbnb";
    const is_network_base = network === "base";
    const is_network_avax = network === "avax";

    const is_hide_btn =
        (is_network_bep && chainId !== 56) ||
        (is_network_erc && chainId !== 1) ||
        (is_network_avax && chainId !== 43114) ||
        (is_network_poly && chainId !== 137) ||
        (is_network_okc && chainId !== 66) ||
        (is_network_arb && chainId !== 42161) ||
        (is_network_opbnb && chainId !== 204) ||
        (is_network_base && chainId !== 8453);

    const busdContract = useContract(tokenJoinPool === ZERO_ADD ? '' : tokenJoinPool, abiBUSD);
    const claimContract = useContract(scClaimAddress, abiClaimv2);
    const joinPoolContract = useContract(scJoinAddress === ZERO_ADD ? '' : scJoinAddress, abiJoinPoolv2);

    const claimChangeContract = useContract(
        scClaimChangeAddress,
        abiClaimOneButton
    );
    const idoTokenClaimDailyContract = useIdoTokenClaimContract(
        contract_daily,
        daily_obj
    );

    if (item.allocation && item.allocation.claimbles?.length > 0) {
        for (let i = 0; i < item.allocation.claimbles?.length; i++) {
            if (item.allocation.claimbles[i]?.claimedType === 2)
                _claimedTokens += item.allocation.claimbles[i]?.claimToken;
            else if (item.allocation.claimbles[i]?.claimedType === 1)
                _is_refunded_api = 1;
        }
    }

    let checkApprove =
        allocation !== null &&
        allocation?.minCommitUsd > 0 &&
        allocation?.maxCommitUsd > 0 &&
        allocation?.joinBusd === 0 &&
        allocation?.joinToken === 0;

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let currentRefId = localStorage.getItem("refId");
    const paramsJoined = {
        ownerAddress: address,
        idoId: item?.id,
        referralAddress: currentRefId === undefined ? null : currentRefId,
    };

    const _handleRefundAll = async () => {
        try {
            setLoadingRefundAll(true);
            if (isRefundSaveDB) {
                setIsOpenConfirmRefund(true)
            } else {
                await _refund(
                    claimContract,
                    item.allocation?.joinBusd,
                    item.allocation?.joinToken,
                    item.allocation?.claimbles[0]?.signSupDiamondUsd,
                    decimals,
                    usdDecimals,
                    idoNative
                ).then((res: any) => {
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setLoadingRefundAll(false);
                            setRefundedFromSC(false);
                            setIsShowClaim(false);
                            message.success({
                                type: "success",
                                content: "Refund success",
                                className: "custom-class",
                                duration: 2,
                            });
                        }
                    });
                });

                return true;
            }
        } catch (error: any) {
            setLoadingRefundAll(false);
            if (error?.error?.message) {
                message.error({
                    type: "error",
                    content: error?.error?.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
            return false;
        }
    };

    let sc: any = item?.commitFundContract


    const getUserCommit = async (paramsFund: any) => {
        try {
            await actions.getUserCommitFund(paramsFund)

        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        if (address) {
            let paramsFund = {
                ownerAddress: address,
                contract: sc,
                secretKey: "UQB2eGkLWs2DkbYbCwRp4DlY"
            }
            getUserCommit(paramsFund)
        }

    }, [address])

    // let dataUser: any = state?.listInfoTon
    const [dataUser, setDataUser]: any = useState()

    useEffect(() => {
        setDataUser(state?.listInfoTon)
    }, [state?.listInfoTon]);



    const x: any = useCounterContract(sc);
    const tonweb = new TonWeb(new TonWeb.HttpProvider('https://toncenter.com/api/v2/jsonRPC'));
    function sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    // Get user's last transaction hash using tonweb
    const waitTxh = async () => {
        const lastTx = await tonweb.getTransactions(userFriendlyAddress, 1)
        let lastTxHash = lastTx[0]?.transaction_id?.hash
        let txHash = lastTxHash
        while (txHash === lastTxHash) {
            await sleep(1500)
            let tx = (await tonweb.getTransactions(userFriendlyAddress, 1))[0]
            txHash = tx.transaction_id.hash
        }
        return txHash
    }

    const [ske, setSke] = useState(true)
    useEffect(() => {
        if (dataUser === null) {
            setSke(true)
        } else {
            setSke(false)
        }
    }, [dataUser])

    // HANDLE CLICK JOIN POOL
    const [tonConnectUI] = useTonConnectUI();
    // const { sender } = useTonConnect();
    // let { sender } = useTonConnect1()

    const _handleJoinPool = async (idoId: any) => {
        try {

            setIsLoading(true);
            const msg = 'joinpool';
            let sender1 = {
                send: async (args: SenderArguments) => {
                    tonConnectUI.sendTransaction({
                        messages: [
                            {
                                address: args.to.toString(),
                                amount: args.value.toString(),
                                payload: args.body?.toBoc().toString('base64'),
                            },
                        ],
                        validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                    })
                        .then(async (res: any) => {
                            let transactionHash: any = await waitTxh()
                            if (transactionHash) {
                                // window.location.reload()
                                let paramsFund = {
                                    ownerAddress: address,
                                    contract: sc,
                                    secretKey: "UQB2eGkLWs2DkbYbCwRp4DlY"
                                }
                                getUserCommit(paramsFund)

                                setIsLoading(false);
                                message.success({
                                    type: "success",
                                    content: 'success',
                                    className: "custom-class",
                                    duration: 2,
                                });
                            }
                        })
                        .catch((err: any) => {
                            console.log('err', err)
                            setIsLoading(false);
                            message.error({
                                type: "error",
                                content: 'Reject request',
                                className: "custom-class",
                                duration: 2,
                            });

                        })
                },
            }
            await x.claimContract.send(
                sender1, { value: toNano(Number(item?.allocation.joinToken) + 0.05) }, msg)
                .then(async (data: any) => {
                    // let transactionHash: any = await waitTxh()
                    // if (transactionHash) {
                    //     // window.location.reload()
                    // }
                }).catch((e: any) => {
                    // console.log('e', e)
                    // setIsLoading(false);
                    // message.error({
                    //     type: "error",
                    //     content: e?.data?.message || e?.reason,
                    //     className: "custom-class",
                    //     duration: 2,
                    // });
                })
        } catch (error) {
            console.log('error', error)
            setIsLoading(false);
        }


    };

    const [isLoadingClaim, setIsLoadingClaim] = useState(false)
    const [isLoadingRefunded, setIsLoadingRefunded] = useState(false)
    // handle Claim
    const _handleClaim = async () => {
        setIsLoadingClaim(true);
        if (item.allocation.claimbles[0]) {
            try {
                setIsLoadingClaim(true);
                const msg = 'claim';
                let sender1 = {
                    send: async (args: SenderArguments) => {
                        tonConnectUI.sendTransaction({
                            messages: [
                                {
                                    address: args.to.toString(),
                                    amount: args.value.toString(),
                                    payload: args.body?.toBoc().toString('base64'),
                                },
                            ],
                            validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                        })
                            .then(async (res: any) => {
                                let transactionHash: any = await waitTxh()
                                console.log('transactionHash', transactionHash)
                                if (transactionHash) {
                                    // window.location.reload()
                                    let paramsRow = {
                                        userAddress: address,
                                        scParams: sc
                                    }
                                    let paramsFund = {
                                        ownerAddress: address,
                                        contract: sc,
                                        secretKey: "UQB2eGkLWs2DkbYbCwRp4DlY"
                                    }
                                    getUserCommit(paramsFund)
                                    setIsLoadingClaim(false);
                                    setIsClaimed(true)
                                    message.success({
                                        type: "success",
                                        content: 'Success',
                                        className: "custom-class",
                                        duration: 2,
                                    });
                                }
                            })
                            .catch((err: any) => {
                                console.log('err', err)
                                setIsLoadingClaim(false);
                                setIsClaimed(false)
                                message.error({
                                    type: "error",
                                    content: 'Reject request',
                                    className: "custom-class",
                                    duration: 2,
                                });

                            })
                    },
                }
                await x.claimContract.send(sender1, { value: toNano(0.05) }, msg)
                    .then(async (data: any) => {
                        // let transactionHash: any = await waitTxh()
                        // if (transactionHash) {
                        //     window.location.reload()
                        // }
                    })
                    .catch((e: any) => {
                        // message.error({
                        //     type: "error",
                        //     content: "Fail",
                        //     className: "custom-class",
                        //     duration: 2,
                        // });
                        setIsLoadingClaim(false);
                        setIsClaimed(false)
                    })
            } catch (error: any) {
                console.log('error', error)
                setIsLoadingClaim(false);
                setIsClaimed(false)
                if (error?.error?.data) {
                    message.error({
                        type: "error",
                        content: error?.error?.data?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                }
                if (error?.message) {
                    message.error({
                        type: "error",
                        content: error?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                }
            }
        }
    };


    const [isRefuned, setIsRefuned] = useState(false)
    const _handleRefund = async () => {
        setIsLoadingRefunded(true);
        if (item.allocation.claimbles[0]) {
            try {
                setIsLoadingRefunded(true);
                let sender1 = {
                    send: async (args: SenderArguments) => {
                        tonConnectUI.sendTransaction({
                            messages: [
                                {
                                    address: args.to.toString(),
                                    amount: args.value.toString(),
                                    payload: args.body?.toBoc().toString('base64'),
                                },
                            ],
                            validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                        })
                            .then(async (res: any) => {
                                let transactionHash: any = await waitTxh()
                                if (transactionHash) {
                                    // window.location.reload()
                                    let paramsFund = {
                                        ownerAddress: address,
                                        contract: sc,
                                        secretKey: "UQB2eGkLWs2DkbYbCwRp4DlY"
                                    }
                                    getUserCommit(paramsFund)
                                    setIsLoadingRefunded(false);
                                    setIsRefuned(true)
                                    message.success({
                                        type: "success",
                                        content: 'Success',
                                        className: "custom-class",
                                        duration: 2,
                                    });
                                } else {
                                    setIsRefuned(false)
                                    setIsLoadingRefunded(false);
                                    message.success({
                                        type: "success",
                                        content: 'Transaction fail',
                                        className: "custom-class",
                                        duration: 2,
                                    });
                                }
                            })
                            .catch((err: any) => {
                                console.log('err', err)
                                setIsRefuned(false)
                                setIsLoadingRefunded(false);
                                message.error({
                                    type: "error",
                                    content: 'Reject request',
                                    className: "custom-class",
                                    duration: 2,
                                });

                            })
                    },
                }
                const msg = 'refund';
                await x.claimContract.send(sender1, { value: toNano(0.05) }, msg)
                    .then(async (data: any) => {
                        // let transactionHash: any = await waitTxh()
                        // if (transactionHash) {
                        //     window.location.reload()
                        // }
                    })
                    .catch((e: any) => {
                        // message.error({
                        //     type: "error",
                        //     content: "Fail",
                        //     className: "custom-class",
                        //     duration: 2,
                        // });
                        setIsRefuned(false)
                        setIsLoadingRefunded(false);
                        // window.location.reload()
                    })
            } catch (error: any) {
                setIsRefuned(false)
                setIsLoadingRefunded(false);
                if (error?.error?.data) {
                    message.error({
                        type: "error",
                        content: error?.error?.data?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                }
                if (error?.message) {
                    message.error({
                        type: "error",
                        content: error?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                }
            }
        }
    };


    // handle claim change
    const _handleClaimChange = async () => {
        try {
            setLoadingClaimChange(true);
            await _refundChange(
                claimChangeContract,
                refundBusd,
                signRefund,
                usdDecimals,
                idoNative
            )
                .then((res: any) => {
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setLoadingClaimChange(false);
                            setClaimChangeFromSC(true);
                        }
                    });
                })
                .catch((err: any) => {
                    message.warning({
                        type: "warning",
                        content: err.error?.data?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                    setLoadingClaimChange(false);
                });

            return true;
        } catch (error: any) {
            setLoadingClaimChange(false);
            if (error?.data) {
                message.warning({
                    type: "warning",
                    content: error?.data?.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
            return false;
        }
    };

    // Control state Button
    const btnApprove = () => {
        const obj = {
            className: `btn btn-join`,
            disabled:
                is_hide_btn || isApproveJoin || isLoading || isFullSlot || checkApprove,
            loading: isLoading,
        };
        return obj;
    };
    const btnJoinPool = () => {
        const obj = {
            className: `btn btn-join`,
            disabled:
                is_hide_btn || isLoading || isJoined || disnableJoin || isFullSlot,
            loading: isLoading,
        };
        return obj;
    };
    const btnClaim = () => {
        const obj = {
            className: `btn btn-join`,
            disabled:
                is_hide_btn ||
                isLoading ||
                !isShowClaim ||
                isLoadingRefund ||
                isLoadingRefundAll,
            loading: isLoading,
        };
        return obj;
    };
    const btnRefund = () => {
        const obj = {
            className: `btn btn-join mar-b-10 rf-1`,
            disabled:
                is_hide_btn ||
                isLoading ||
                !isRefundedRC ||
                isLoadingRefund ||
                isLoadingRefundAll,
            loading: isLoadingRefund,
        };
        return obj;
    };
    const btnRefundAll = () => {
        const obj = {
            className: `btn btn-join mar-b-10 rf-1`,
            disabled:
                is_hide_btn ||
                isLoading ||
                !isRefundedRC ||
                isLoadingRefund ||
                isLoadingRefundAll ||
                allocation?.claimbles[0]?.signSupDiamondUsd === null ||
                !isRefundAll,
            loading: isLoadingRefundAll,
        };
        return obj;
    };
    const btnclaimChange = () => {
        const obj = {
            className: `btn btn-join btn-fund`,
            disabled: is_hide_btn || isLoadingClaimChange || isClaimChangeRC,
            loading: isLoadingClaimChange,
        };
        return obj;
    };
    const btnclaimChangeCalcula = () => {
        const obj = {
            className: `btn btn-join`,
            disabled: true,
        };
        return obj;
    };
    const btnSubmit = () => {
        const obj = {
            className: `btn-join`,
            disabled: isLoadingSubmit,
            loading: isLoadingSubmit,
        };
        return obj;
    };
    const formatNumber = (x: any, max: any) => {
        if (x) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };
    const getTotalJoin = async () => {
        const scJoin = new web3.eth.Contract(
            abiJoinPoolv2 as unknown as AbiItem,
            scJoinAddress
        );
        const amountCheck = await scJoin.methods.totalJoined().call();
        // setamounJoinNotConnect(amountCheck / (1 * 10 ** usdDecimals) || 0);
        setamounJoinNotConnect(amountCheck / (1 * 10 ** 18) || 0); // a tâm kêu fix
    };

    let _percentProcess = 0;
    if (totalRaise > 0) {
        if (!ignoreFullSlot && totalJoined >= totalRaise) isFullSlot = true;
        const _percent = (amounJoinNotConnect / totalRaise) * 100;
        if (_percent > item.percentProcess) {
            _percentProcess = _percent;
        } else {
            _percentProcess = item.percentProcess;
        }
    }

    let percentProcess: any = formatNumber(_percentProcess, 1)
    let percentProcessNew: any = formatNumber(_percentProcess, 5)

    // useEffect---------------------------------------
    // check approve when reload page
    useEffect(() => {
        if (idoNative) {
            setApproveJoin(true);
        } else {
            setTimeout(() => {
                if (joinStatus === 2 || isJoined) {
                    setApproveJoin(true);
                } else if (busdContract && scJoinAddress !== null && scJoinAddress !== "") {
                    busdContract.allowance(address, scJoinAddress).then((res: any) => {
                        if (Number(convertWeiToToken(res, usdDecimals)) > 0 && Number(convertWeiToToken(res, usdDecimals) >= amountJoin)) {
                            setApproveJoin(true);
                        } else {
                            setApproveJoin(false);
                        }
                    });
                }
            }, 500);
        }

    }, [address, scJoinAddress, joinStatus, isJoined, isSubmit, reCheck, busdContract, idoNative]);

    useEffect(() => {
        if (scJoinAddress !== null && scJoinAddress !== "") {
            getTotalJoin();
        }
    }, []);

    // check after join
    useEffect(() => {
        if (address) {
            if (joinStatus === 2) {
                setIsJoined(true);
            } else if (scJoinAddress !== null && scJoinAddress !== "") {
                _isJoined(joinPoolContract, address, item.id, item.id).then(
                    (res: any) => {
                        setIsJoined(res);
                    }
                );
            }
        }
    }, [address, joinPoolContract, scJoinAddress])

    useEffect(() => {
        if (address) {
            if (scClaimAddress !== null && scClaimAddress !== "" && claimContract) {
                _totalClaimed(claimContract, address, decimals).then(
                    (res: any) => {
                        setTotalClaimed(res);
                    }
                );

                _totalRefunded(claimContract, address, usdDecimals).then(
                    (res: any) => {
                        // console.log('res', res)
                        setTotalRefunded(res);
                    }
                );

                _showBtnRFAll(
                    claimContract,
                    address,
                    allocation?.joinBusd,
                    usdDecimals
                ).then((res: any) => {
                    setIsRefundAll(res);
                });

                _isRefundedRC(
                    claimContract,
                    address,
                    allocation?.claimbles[0]?.refundUsd,
                    usdDecimals
                ).then((res: any) => {
                    setRefundedFromSC(res);
                });
            }

            if (scClaimChangeAddress !== null && scClaimChangeAddress !== "") {
                _isClaimChange(claimChangeContract, address)
                    .then((res: any) => {
                        setClaimChangeFromSC(res);
                    })

            }
        }
    }, [
        address,
        scClaimAddress,
        scClaimChangeAddress,
        joinStatus,
        isRefundedRC,
        isShowClaim,
        claimChangeContract,
        claimContract
    ]);

    useEffect(() => {
        if (address && claimbles?.length > 0 && !showPopupClaim) {
            _showClaimBtn(
                claimContract,
                address,
                item.allocation.claimbles[0].claimToken,
                decimals
            ).then((res: any) => {
                setIsShowClaim(res);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, claimContract, scClaimAddress, allocation, decimals]);

    useEffect(() => {
        try {
            if (contract_daily !== null && contract_daily !== "") {
                totalDailyClaimed(idoTokenClaimDailyContract, address).then(
                    (res: any) => {
                        const _totalClaimDaily = convertWeiToToken(
                            res.tokensClaimed,
                            decimals
                        );
                        setTotalClaimDaily(_totalClaimDaily);
                    }
                );
            }
        } catch (error) {
            // TODO
        }
    }, [address, contract_daily]);

    useEffect(() => {
        if (
            claimbles?.length > 0 &&
            item.allocation.claimbles[0].signToken !== null &&
            claimbles?.length > 0 &&
            allocation?.claimbles[0]?.signBusd !== null
        ) {
            setAddClass("btn-claim-rf");
        }
    }, [addClass]);

    useEffect(() => {
        try {
            if (scJoinAddress !== null && scJoinAddress !== "") {
                totalJoinPool(joinPoolContract).then((res: any) => {
                    const _totalJoined = convertWeiToToken(res, usdDecimals);
                    setTotalJoined(_totalJoined);
                });
            }
        } catch {
            // TODO
        }
    }, [scJoinAddress, joinPoolContract]);

    useEffect(() => {
        if (tokenJoinPool === ZERO_ADD) {
            setIdoNative(true)
        } else {
            setIdoNative(false)
        }

    }, [tokenJoinPool, address])





    const submitAllocation = async (value: any) => {
        try {
            setIsLoadingSubmit(true);
            let params = {
                ownerAddress: address,
                idoId: item.id,
                amount: value,
            };
            // let params1 = {
            //     address: address,
            //     amount: Number(value),
            //     contract: sc
            // }
            // let paramsRow = {
            //     userAddress: address,
            //     scParams: sc
            // }
            let paramsFund = {
                ownerAddress: address,
                contract: sc,
                secretKey: "UQB2eGkLWs2DkbYbCwRp4DlY"
            }
            await actions.submitAllocation(params).then((res: any) => {
                if (res?.data?.succeeded) {
                    if (address) {
                        let timeCheck = Date.now()
                        let id = setInterval(
                            async function () {
                                let resX: any = await actions.getUserCommitFund(paramsFund)
                                if (resX !== false) {
                                    clearInterval(id);
                                    setIsLoadingSubmit(false);
                                    message.success({
                                        type: "success",
                                        content: "Submit successfully!!!",
                                        className: "custom-class",
                                        duration: 2,
                                    });
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 1500);

                                }
                                if (Date.now() > timeCheck + 60 * 1000) {
                                    clearInterval(id)
                                    setIsLoadingSubmit(false);
                                    message.error({
                                        type: "error",
                                        content: "Submit timeout!!!",
                                        className: "custom-class",
                                        duration: 2,
                                    });
                                    // window.location.reload()
                                }
                            }
                            , 5000);

                        return () => clearInterval(id);

                    }
                } else {
                    message.error({
                        type: "error",
                        content: res?.data?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                    setIsLoadingSubmit(false);
                }
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setIsLoadingSubmit(false);
                })
            // let result = await actions.getUserCommitFund(paramsFund)
            // return

            //  await actions.getUserCommit(params1);

            // actions.submitAllocation(params).then((res: any) => {
            //     if (res.data.succeeded) {
            //         setIsSubmit(res);
            //     }
            //     if (res.data.succeeded) {
            //         setIsLoadingSubmit(false);
            //         message.success({
            //             type: "success",
            //             content: "Submit successfully!!!",
            //             className: "custom-class",
            //             duration: 2,
            //         });
            //         setTimeout(() => {
            //             window.location.reload();
            //         }, 500);
            //     } else {
            //         message.error({
            //             type: "error",
            //             content: res.data.message,
            //             className: "custom-class",
            //             duration: 2,
            //         });
            //         setIsLoadingSubmit(false);
            //     }
            // });
        } catch (error) {
            console.log('error', error)
            message.error({
                type: "error",
                content: "Submit error",
                className: "custom-class",
                duration: 2,
            });
            setIsLoadingSubmit(false);
        }
    };

    const renderSubmit = (
        <>
            <div className="min-max">
                <div className="app-min">
                    Min:{" "}
                    <span>
                        {formatNumber(allocation?.minCommitUsd || 0, 4)} {symbolUSD}
                    </span>
                </div>
                <div className="app-min">
                    Max:{" "}
                    <span>
                        {formatNumber(allocation?.maxCommitUsd || 0, 4)} {symbolUSD}
                    </span>
                </div>
            </div>
            <div className="submit-tier">
                <InputNumber
                    addonAfter="$"
                    size="large"
                    value={valueSubmit}
                    onChange={setValueSubmit}
                    disabled={isLoadingSubmit}
                />
            </div>
        </>
    );


    const handleCancelConfirmRefund = () => {
        setIsOpenConfirmRefund(false)
        setLoadingRefundAll(false);
    }
    let params = {
        ownerAddress: address,
        idoId: item?.id
    }

    const handleOkConfirmRefund = async () => {
        await actions.updateRefuned(params).then((res: any) => {
            if (res.succeeded) {
                setIsOpenConfirmRefund(false)
                setLoadingRefundAll(false);
                window.location.reload()
            } else {
                message.error({
                    type: "error",
                    content: res?.message,
                    className: "custom-class",
                    duration: 2,
                });
                setIsOpenConfirmRefund(false)
                setLoadingRefundAll(false);
            }

        })
            .catch((err: any) => {
                setIsOpenConfirmRefund(false)
                setLoadingRefundAll(false);
            })

    }

    console.log('dataUser', dataUser)

    return (
        <>
            <div className="colum w-33">
                <div className="content-details-rounds">
                    <div className="rounds-name">{item?.name}</div>
                    <div className="url-name">
                        <span className="img">
                            <img src={logo} alt="" />
                        </span>{" "}
                        {unit}
                    </div>
                    <ul className="list-info-details">
                        <li>
                            <span className="txt-l">Total raised:</span>
                            <span className="txt-r">
                                {/* ${formatNumber(item?.totalRaise, 1)} */}
                                <Value unit="$" value={item?.totalRaise} />
                            </span>
                        </li>
                        <li>
                            <span className="txt-l">Swap Rate:</span>
                            <span className="txt-r">{item?.swapAmount}</span>
                        </li>
                        <li>
                            <span className="txt-l">Start Pool:</span>
                            <span className="txt-r">{item?.startDate}</span>
                        </li>
                        <li>
                            <span className="txt-l">End Pool:</span>
                            <span className="txt-r">{item?.endDate}</span>
                        </li>
                        <li>
                            <span className="txt-l">Vesting:</span>
                            <span className="txt-r">
                                <div className="tooltips">
                                    {item?.vesting ? item?.vesting : "TBA"}
                                </div>
                            </span>
                        </li>
                        <li>
                            <span className="txt-l">Claim:</span>
                            <span className="txt-r color-green">{item?.claimTime}</span>
                        </li>
                    </ul>
                    {item.status === STATUS.COMING ? (
                        <>
                            <div className="rounds-join-pool">
                                {" "}
                                Commit fund starts in : TBA
                            </div>
                        </>
                    ) : item.status === STATUS.GOING && item.startOpenIdo !== null ? (
                        <>
                            {is_commit_fund ? (
                                <div className="rounds-join-pool">Commit fund starts in</div>
                            ) : (
                                <div className="rounds-join-pool">Open in: </div>
                            )}
                            <span className="times-join-detail">
                                <Countdown
                                    date={getProgressTime(item.startOpenIdo)}
                                    renderer={rendererCountDown}
                                />
                            </span>
                        </>
                    ) : item.status === STATUS.OPEN && item.closeJoinIdo !== null ? (
                        <>
                            {is_commit_fund ? (
                                <div className="rounds-join-pool">Commit fund closes in</div>
                            ) : (
                                <div className="rounds-join-pool">Pools ends in:</div>
                            )}{" "}
                            <span className="times-join-detail">
                                <Countdown
                                    date={getProgressTime(item.closeJoinIdo)}
                                    renderer={rendererCountDown}
                                />
                            </span>
                        </>
                    ) : (
                        ""
                    )}

                    <div className="rounds-process">
                        <span className="title-process">COMMIT PROGRESS</span>
                        <div className="process-join-ido">
                            <Progress
                                strokeColor={{
                                    "0%": "rgb(249 208 100 / 89%)",
                                    "100%": "#F9D064",
                                }}
                                percent={_percentProcess}
                                status="active"
                            />
                            <span className="text-pro-cus">{percentProcess}%</span>
                            <div className="process-join-num">
                                {item?.totalRaise > 0 ? (
                                    <>
                                        {formatNumber((percentProcessNew / 100) * item?.totalRaise, 3)}{" "}
                                        {symbolUSD} / {formatNumber(item?.totalRaise, 3)}{" "}
                                        {symbolUSD}
                                    </>
                                ) : (
                                    <>
                                        0 {symbolUSD} / 0 {symbolUSD}
                                    </>
                                )}
                            </div>
                        </div>

                        {item?.status === STATUS.OPEN ? (
                            <>
                                {allocation !== null ? (
                                    <>
                                        {allocation?.minCommitUsd > 0 && allocation?.maxCommitUsd > 0 && !dataUser ? (
                                            <>
                                                {renderSubmit}

                                            </>
                                        ) : (
                                            <>
                                                {/* {allocation?.minCommitUsd > 0 && allocation?.maxCommitUsd > 0 && dataUser ? (
                                                    <div
                                                        className="row-claim-token"
                                                        style={{ paddingBottom: "5px", paddingTop: "10px" }}
                                                    >
                                                        <div className="text">Your submit:</div>
                                                        <div className="name-row">
                                                            {formatNumber(allocation.joinBusd, 6)} {symbolUSD}
                                                        </div>
                                                    </div>
                                                ) : ('')} */}
                                            </>
                                        )}
                                    </>
                                ) : ('')}
                            </>
                        ) : ('')}

                        {/* {allocation !== null ? (
                            allocation?.minCommitUsd > 0
                                && allocation?.maxCommitUsd > 0
                                && !dataUser
                                ? (
                                    <>
                                        {allocation?.joinBusd === 0 &&
                                            item.status !== STATUS.CLOSE
                                            ? (
                                                <>{renderSubmit}</>
                                            ) : (
                                                <>
                                                    {item.status !== STATUS.CLOSE && address ? (
                                                        <div
                                                            className="row-claim-token"
                                                            style={{ paddingBottom: "5px", paddingTop: "10px" }}
                                                        >
                                                            <div className="text">Your submit:</div>
                                                            <div className="name-row">
                                                                {formatNumber(allocation.joinBusd, 6)} {symbolUSD}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                    </>
                                ) : item.status === STATUS.GOING ? (
                                    <>
                                        {allocation?.minCommitUsd > 0
                                            && allocation?.maxCommitUsd > 0
                                            && dataUser ? ('') : (
                                            <div
                                                className="row-claim-token"
                                                style={{ paddingBottom: "5px", paddingTop: "10px" }}
                                            >
                                                <div className="text">Funds needed:</div>
                                                <div className="name-row">
                                                    {formatNumber(amountJoin, 6)} {symbolUSD}
                                                </div>
                                            </div>
                                        )}
                                    </>

                                ) : (
                                    ""
                                )
                        ) : (
                            ""
                        )} */}
                    </div>

                    <div className="btn-rounds">
                        {ske ? ("") : (
                            <>
                                {Number(dataUser?.joined_amt) > 0 && item.status === STATUS.CLOSE ? (
                                    <>
                                        <div className="line-allo"></div>
                                        <div className="y-allo">YOUR ALLOCATION</div>
                                        {/* hide/show button remaining fund */}
                                        {is_commit_fund && !is_hide_btn ? (
                                            <>
                                                {item.status === STATUS.CLOSE && signRefund !== null ? (
                                                    <>
                                                        {fundProcess === 1 ? (
                                                            <div className="row-chane">
                                                                <div className="text">Remaining Fund:</div>
                                                                <div className="num-change">
                                                                    {isClaimChangeRC ? (
                                                                        <span className="next-ves">
                                                                            Claimed {Number(refundBusd).toFixed(2)}{" "}
                                                                            {symbolUSD}
                                                                        </span>
                                                                    ) : (
                                                                        <Button
                                                                            {...btnclaimChange()}
                                                                            onClick={() => _handleClaimChange()}
                                                                        >
                                                                            Claim {Number(refundBusd).toFixed(2)}{" "}
                                                                            {symbolUSD}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="row-chane">
                                                                <div className="text">Remaining Fund:</div>
                                                                <div className="num-change">
                                                                    <Button {...btnclaimChangeCalcula()}>
                                                                        Claim {Number(refundBusd).toFixed(2)}{" "}
                                                                        {symbolUSD}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <div className="row-chane">
                                                        <div className="text">Remaining Fund:</div>
                                                        <div className="num-change">
                                                            <Button {...btnclaimChangeCalcula()}>
                                                                Calculating
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {/* show next vesting */}
                                        <div className="row-chane">
                                            <div className="text">Next Vesting:</div>
                                            <div className="num-change">
                                                {allocation !== null && allocation.nextClaim !== null ? (
                                                    <span className="next-ves">{allocation?.nextClaim}</span>
                                                ) : (
                                                    <span className="next-ves">--</span>
                                                )}
                                            </div>
                                        </div>

                                        {allocation !== null && allocation.lastRefund !== null ? (
                                            <div className="row-chane">
                                                <div className="text">Refund ends at:</div>
                                                <div className="num-change">
                                                    <span className="next-ves">{allocation?.lastRefund}</span>
                                                </div>
                                            </div>
                                        ) : ('')}

                                        {/* show claimed */}
                                        {dataUser?.isClaimed ? (
                                            <>
                                                <div className="row-chane">
                                                    <div className="text">
                                                        Claimed
                                                    </div>
                                                    <div className="num-change">
                                                        <span className="next-ves">
                                                            {formatNumber(item.allocation?.joinToken, 5)} {symbol}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : ('')}


                                        {/* show refunded */}
                                        {dataUser?.is_refunded ? (
                                            <>
                                                <div className="row-chane">
                                                    <div className="text">
                                                        Refunded
                                                    </div>
                                                    <div className="num-change">
                                                        <span className="next-ves">
                                                            {formatNumber(amountJoin, 6)} {symbolUSD}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : ('')}

                                        {/* show allocation */}
                                        {signRefund !== null || !is_commit_fund ? (
                                            <div className="row-chane">
                                                <div className="text">Allocation:</div>
                                                <div className="num-change">
                                                    <span className="next-ves">
                                                        {formatNumber(item.allocation?.joinToken, 6)} {symbol}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row-chane">
                                                <div className="text">Allocation:</div>
                                                <div className="num-change">
                                                    <span className="next-ves">Calculating</span>
                                                </div>
                                            </div>
                                        )}
                                        {/* show investment */}
                                        <div className="row-chane">
                                            <div className="text">Investment:</div>
                                            <div className="num-change">
                                                <span className="next-ves">
                                                    {formatNumber(amountJoin, 6)} {symbolUSD}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                {item.status === STATUS.OPEN && allocation !== null ? (
                                    <>
                                        {Number(dataUser?.joined_amt) > 0 ? (
                                            <div className="row-chane">
                                                <div className="text">Investment:</div>
                                                <div className="num-change">
                                                    <span className="next-ves">
                                                        {formatNumber(amountJoin, 6)} {symbolUSD}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {allocation?.minCommitUsd > 0
                                                    && allocation?.maxCommitUsd > 0
                                                    && !dataUser ? ('') : (
                                                    <div className="row-chane">
                                                        <div className="text">Funds needed:</div>
                                                        <div className="num-change">
                                                            <span className="next-ves">
                                                                {formatNumber(amountJoin, 6)} {symbolUSD}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </>

                                        )}
                                    </>
                                ) : (
                                    ""
                                )}


                                {allocation === null ?
                                    <>
                                        {item?.status === STATUS.COMING ? (
                                            <Button className="btn btn-join" disabled>
                                                Coming Soon
                                            </Button>
                                        ) : (
                                            <Button className="btn btn-join" disabled>
                                                You’re not whitelisted
                                            </Button>
                                        )}

                                    </>
                                    :
                                    <>
                                        {item?.status === STATUS.CLOSE && item?.allocation?.claimbles?.length === 0 && !dataUser ? (
                                            <Button className="btn btn-join" disabled>
                                              You’re not join pool
                                            </Button>
                                        ) : (
                                            <>
                                              {item?.status === STATUS.CLOSE && Number(dataUser?.joined_amt) > 0 ?
                                                  <>
                                                    {item?.allocation.claimbles[0] ?
                                                        <>
                                                          {dataUser?.isClaimed || isClaimed ?
                                                              <>
                                                                <Button
                                                                    className="btn btn-join mar-b-10"
                                                                    disabled
                                                                >
                                                                  Claimed
                                                                </Button>
                                                              </>
                                                              :
                                                              <>
                                                                {dataUser?.is_refunded || isRefuned ?
                                                                    <>
                                                                      {""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                      {isLoadingRefunded ?
                                                                          <>
                                                                            <Button
                                                                                className="btn btn-join mar-b-10"
                                                                                disabled
                                                                            >
                                                                              Claim
                                                                            </Button>
                                                                          </>
                                                                          :
                                                                          <>
                                                                            {item?.allocation.claimbles[0].signToken === null ? (
                                                                                <Button
                                                                                    className="btn btn-join mar-b-10"
                                                                                    disabled
                                                                                >
                                                                                    <>
                                                                                      Claim
                                                                                    </>
                                                                                </Button>
                                                                            ) : (
                                                                                <>
                                                                                  {isLoadingClaim ? (
                                                                                      <Button
                                                                                          className="btn btn-join mar-b-10"
                                                                                          disabled
                                                                                      >
                                                                                          <>
                                                                                            Claim <Spin />
                                                                                          </>
                                                                                      </Button>) : (
                                                                                      <Button
                                                                                          className="btn btn-join mar-b-10"
                                                                                          onClick={() => _handleClaim()}
                                                                                      >
                                                                                        Claim
                                                                                      </Button>)}
                                                                                </>
                                                                            )}
                                                                          </>
                                                                      }
                                                                    </>
                                                                }

                                                                {dataUser?.is_refunded || isRefuned ?
                                                                    <>
                                                                      <Button
                                                                          className="btn btn-join"
                                                                          disabled
                                                                      >
                                                                        Refunded
                                                                      </Button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                      {isLoadingClaim ?
                                                                          <>
                                                                            <Button
                                                                                className="btn btn-join"
                                                                                disabled
                                                                            >
                                                                              Refund
                                                                            </Button>
                                                                          </>
                                                                          :
                                                                          <>
                                                                            {item?.allocation.claimbles[0].signBusd === null ? (
                                                                                <Button
                                                                                    className="btn btn-join"
                                                                                    disabled
                                                                                >
                                                                                    <>
                                                                                      Refund
                                                                                    </>
                                                                                </Button>
                                                                            ) : (
                                                                                <>
                                                                                  {isLoadingRefunded ? (
                                                                                      <Button
                                                                                          className="btn btn-join"
                                                                                          disabled
                                                                                      >
                                                                                          <>
                                                                                            Refund <Spin />
                                                                                          </>
                                                                                      </Button>) : (
                                                                                      <Button
                                                                                          className="btn btn-join"
                                                                                          onClick={() => _handleRefund()}
                                                                                      >
                                                                                        Refund
                                                                                      </Button>)}
                                                                                </>
                                                                            )}
                                                                          </>
                                                                      }
                                                                    </>
                                                                }
                                                              </>
                                                          }
                                                        </>
                                                        :
                                                        <>
                                                          <Button
                                                              className="btn btn-join mar-b-10"
                                                              disabled
                                                          >
                                                            Joined
                                                          </Button>
                                                        </>
                                                    }

                                                    {!showPopupClaim ? (
                                                        <div className="inf-claim">
                                                          <ScheduleOutlined onClick={showModal} />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                  </>
                                                  :
                                                  <>
                                                    {item?.status === STATUS.OPEN ? (
                                                        <>
                                                          {allocation?.minCommitUsd > 0 &&
                                                          allocation?.maxCommitUsd > 0 &&
                                                          allocation?.maxCommitUsd > 0 &&
                                                          !dataUser ?
                                                              <>
                                                                <Button
                                                                    size="large"
                                                                    onClick={() => submitAllocation(valueSubmit)}
                                                                    {...btnSubmit()}
                                                                    style={{ height: "45px" }}
                                                                >
                                                                  Submit
                                                                  {isLoadingSubmit ? (
                                                                      <i className="fa fa-spinner fa-spin" />
                                                                  ) : (
                                                                      ""
                                                                  )}
                                                                </Button>
                                                              </>
                                                              :
                                                              <>
                                                                {Number(dataUser?.joined_amt) > 0 ?
                                                                    <>
                                                                      <Button
                                                                          {...btnJoinPool()}
                                                                          disabled
                                                                      >
                                                                        Joined
                                                                      </Button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                      <Button
                                                                          {...btnJoinPool()}
                                                                          onClick={() => _handleJoinPool(item.id)}
                                                                      >
                                                                        Join pool
                                                                      </Button>
                                                                    </>
                                                                }
                                                              </>
                                                          }
                                                        </>
                                                    ) : (
                                                        <>
                                                          {item?.status === STATUS.CLOSE ? (
                                                              <>
                                                                {allocation === null ? (
                                                                    <Button className="btn btn-join" disabled>
                                                                      You’re not whitelisted
                                                                    </Button>
                                                                ) : (
                                                                    <>
                                                                      {dataUser?.joined_amt <= 0 ? (
                                                                          <Button className="btn btn-join" disabled>
                                                                            You’re not join pool
                                                                          </Button>
                                                                      ) : ('')}
                                                                    </>
                                                                )}
                                                              </>) : ('')}
                                                        </>

                                                    )}
                                                  </>
                                              }
                                            </>
                                        )}
                                    </>
                                }
                            </>
                        )}


                    </div>
                </div>
            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                className="modal-claimed-after"
                title="Schedule Claim"
                visible={isModalVisible}
                onCancel={handleCancel}
            >
                <div className="bsc-p-launchpad_detail-allocations">
                    <table>
                        <tr>
                            <th style={{ width: "33%" }}>Claimable</th>
                            <th style={{ width: "34%" }}>Claim Date</th>
                            <th style={{ width: "33%" }}>%</th>
                        </tr>

                        {allocation !== null ? (
                            allocation.schedules?.map((item: any, i: any) => (
                                <tr key={i}>
                                    <td style={{ width: "33%", paddingLeft: "15px" }}>
                                        {item.claimToken !== null ? item.claimToken : "TBA"}
                                    </td>
                                    <td
                                        style={{
                                            width: "34%",
                                            justifyContent: "start",
                                            paddingLeft: "15px",
                                        }}
                                    >
                                        {item.claimTime !== null ? item.claimTime : "TBA"}
                                    </td>
                                    <td style={{ width: "33%", paddingLeft: "15px" }}>
                                        {item.percentage !== null ? item.percentage : "TBA"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <div className="bsc-p-launchpad_detail-allocations-empty">
                                <span>Empty</span>
                            </div>
                        )}
                    </table>
                </div>
            </Modal>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                className="modal-goto-sui"
                title="Confirm"
                visible={isOpenConfirmRefund}
                onCancel={handleCancelConfirmRefund}
                onOk={handleOkConfirmRefund}
            >
                <div className="confirm-rf">
                    We're recorded your refund request. Your fund will be transfered to your wallet after Refund Period.
                </div>
            </Modal>
        </>
    );
};

const Item1 = ({
    commitFundContract,
    totalRaise,
    percentProcess,
    network,
    tokenNetwork,
    status,
    symbol,
    name,
    logo,
    unit,
    swapAmount,
    startDate,
    endDate,
    vesting,
    claimTime,
    startOpenIdo,
    closeJoinIdo,
    symbolUSD

}: any) => {

    // let navigate = useNavigate();
    // const [amounJoinNotConnect, setamounJoinNotConnect] = useState(0);
    let scJoinAddress = commitFundContract;
    if (scJoinAddress === undefined) scJoinAddress = null;

    const formatNumber = (x: any, max: any) => {
        if (x) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };
    // const getTotalJoin = async () => {
    //     const scJoin = new web3.eth.Contract(
    //         abiJoinPoolv2 as unknown as AbiItem,
    //         scJoinAddress
    //     );
    //     const amountCheck = await scJoin.methods.totalJoined().call();
    //     // setamounJoinNotConnect(amountCheck / (1 * 10 ** usdDecimals) || 0);
    //     setamounJoinNotConnect(amountCheck / (1 * 10 ** 18) || 0); // a tâm kêu fix
    // };

    // let _percentProcess = 0;
    // if (totalRaise > 0) {
    //     const _percent = (amounJoinNotConnect / totalRaise) * 100;
    //     if (_percent > percentProcess) {
    //         _percentProcess = _percent;
    //     } else {
    //         _percentProcess = percentProcess;
    //     }
    // }

    // let percentProcess1: any = formatNumber(_percentProcess, 1)
    // let percentProcessNew: any = formatNumber(_percentProcess, 5)

    // useEffect---------------------------------------
    // useEffect(() => {
    //     if (scJoinAddress !== null && scJoinAddress !== "") {
    //         getTotalJoin();
    //     }
    // }, []);


    // if (
    //     network === "ton" &&
    //     tokenNetwork === "ton" &&
    //     status === STATUS.CLOSE
    // ) {
    //     navigate(`/Ido/Detail/ton?${symbol}`);
    // }



    return (
        <>
            <div className="colum w-33">
                <div className="content-details-rounds">
                    <div className="rounds-name">{name}</div>
                    <div className="url-name">
                        <span className="img">
                            <img src={logo} alt="" />
                        </span>{" "}
                        {unit}
                    </div>
                    <ul className="list-info-details">
                        <li>
                            <span className="txt-l">Total raised:</span>
                            <span className="txt-r">
                                {/* ${formatNumber(item?.totalRaise, 1)} */}
                                <Value unit="$" value={totalRaise} />
                            </span>
                        </li>
                        <li>
                            <span className="txt-l">Swap Rate:</span>
                            <span className="txt-r">{swapAmount}</span>
                        </li>
                        <li>
                            <span className="txt-l">Start Pool:</span>
                            <span className="txt-r">{startDate}</span>
                        </li>
                        <li>
                            <span className="txt-l">End Pool:</span>
                            <span className="txt-r">{endDate}</span>
                        </li>
                        <li>
                            <span className="txt-l">Vesting:</span>
                            <span className="txt-r">
                                <div className="tooltips">
                                    {vesting ? vesting : "TBA"}
                                </div>
                            </span>
                        </li>
                        <li>
                            <span className="txt-l">Claim:</span>
                            <span className="txt-r color-green">{claimTime}</span>
                        </li>
                    </ul>
                    {status === STATUS.COMING ? (
                        <>
                            <div className="rounds-join-pool">
                                {" "}
                                Commit fund starts in : TBA
                            </div>
                        </>
                    ) : status === STATUS.GOING && startOpenIdo !== null ? (
                        <>
                            <div className="rounds-join-pool">Open in: </div>
                            <span className="times-join-detail">
                                <Countdown
                                    date={getProgressTime(startOpenIdo)}
                                    renderer={rendererCountDown}
                                />
                            </span>
                        </>
                    ) : status === STATUS.OPEN && closeJoinIdo !== null ? (
                        <>
                            <div className="rounds-join-pool">Pools ends in:</div>
                            <span className="times-join-detail">
                                <Countdown
                                    date={getProgressTime(closeJoinIdo)}
                                    renderer={rendererCountDown}
                                />
                            </span>
                        </>
                    ) : (
                        ""
                    )}

                    {/* <div className="rounds-process">
                        <span className="title-process">COMMIT PROGRESS</span>
                        <div className="process-join-ido">
                            <Progress
                                strokeColor={{
                                    "0%": "rgb(249 208 100 / 89%)",
                                    "100%": "#F9D064",
                                }}
                                percent={_percentProcess}
                                status="active"
                            />
                            <span className="text-pro-cus">{percentProcess1}%</span>
                            <div className="process-join-num">
                                {totalRaise > 0 ? (
                                    <>
                                        {formatNumber((percentProcessNew / 100) * totalRaise, 3)}{" "}
                                        {symbolUSD} / {formatNumber(totalRaise, 3)}{" "}
                                        {symbolUSD}
                                    </>
                                ) : (
                                    <>
                                        0 {symbolUSD} / 0 {symbolUSD}
                                    </>
                                )}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

const BottomIdoDetails = (props: any) => {
    const userFriendlyAddress: any = useTonAddress();
    const data = props.dataBody;
    let objDetail = props?.objDetail
    const [open, setOpen] = useState(true);
    const [mess, setMess] = useState("");

    useEffect(() => {
        if (data?.ownerAddress === null) {
            if (data?.tokenNetwork === "apt") {
                setMess("You have to bind the APT Wallet to join the IDO.");
            } else if (data?.tokenNetwork === "sui") {
                setMess("You have to bind the SUI Wallet to join the IDO.");
            } else if (data?.tokenNetwork === "sol") {
                setMess("You have to bind the Solana Wallet to join the IDO.");

            }
            else if (data?.tokenNetwork === "ton") {
                setMess("You have to bind the Ton Wallet to join the IDO.");
            }
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [data?.ownerAddress]);

    let navigate = useNavigate();
    const gotoSui = () => {
        navigate("/my-account");
    };

    const titleModal = () => (
        <div className="modal-stt">
            <img src="/images/go-sui.png" alt="" />
            <div className="title-stt">Warning!</div>
        </div>
    );

    return (
        <>
            <div className="main-list-details">
                <div className="columns">
                    {userFriendlyAddress ? (
                        <>
                            {data &&
                                data.details?.map((item: any, i: any) => (
                                    <Item
                                        item={item}
                                        logo={data?.logoUrl}
                                        unit={data?.unit}
                                        symbol={data?.symbol}
                                        decimals={data?.decimals}
                                        network={data?.network}
                                        usdDecimals={data?.decimalUsd}
                                        key={i}
                                        tokenJoinPool={data.tokenJoinPool}
                                        symbolUSD={data.symbolUSD}
                                        priceToken={data.pricePer}
                                        tokenNetwork={data?.tokenNetwork}
                                        isRefundSaveDB={data?.isRefundSaveDB}
                                    />
                                ))}
                        </>
                    ) : (
                        <>
                            {objDetail &&
                                objDetail.details?.map((item: any, i: any) => (
                                    <Item1
                                        commitFundContract={item?.commitFundContract}
                                        totalRaise={item?.totalRaise}
                                        status={item?.status}
                                        name={item?.name}
                                        percentProcess={item?.percentProcess}
                                        swapAmount={item?.swapAmount}
                                        startDate={item?.startDate}
                                        endDate={item?.endDate}
                                        vesting={item?.vesting}
                                        claimTime={item?.claimTime}
                                        startOpenIdo={item?.startOpenIdo}
                                        closeJoinIdo={item?.closeJoinIdo}
                                        network={objDetail?.network}
                                        tokenNetwork={objDetail?.tokenNetwork}
                                        symbol={objDetail?.symbol}
                                        logo={objDetail?.logo}
                                        unit={objDetail?.unit}
                                        symbolUSD={objDetail?.symbolUSD}
                                    />
                                ))}
                        </>
                    )}

                    <ModalClaimedAfter dataFromParent={data} />
                </div>
            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                className="modal-goto-sui"
                title={titleModal()}
                visible={open}
                footer={false}
            >
                <p className="noti-sui">{mess}</p>
                <div className="go-sui">
                    <button onClick={gotoSui}>Go to My Account</button>
                </div>
            </Modal>


            {/* @ts-ignore this lib is incompatible with react18  */}
            {/* <Modal
                className="modal-goto-sui"
                title={titleModal()}
                visible={open}
                footer={false}
            >
                <p className="noti-sui">{mess}</p>
                <div className="go-sui">
                    <button onClick={gotoSui}>Go to My Account</button>
                </div>
            </Modal> */}
            {/* <Modal
                className="modal-goto-sui"
                title={titleModal()}
                visible={open}
                footer={false}
            >
                <p className="noti-sui">
                    Please link your APT address before joining the IDO
                </p>
                <div className="go-sui">
                    <button onClick={gotoSui}>Go to My Account</button>
                </div>
            </Modal> */}
        </>
    );
};
export default BottomIdoDetails;


