import "./style.css";

const DataLaunch = () => {
  return (
    <div className="info">
      <div className="info-box">
        <div className="info-num">X430</div>
        <div className="info-des">High ATH ROI</div>
      </div>
      <div className="info-box">
        <div className="info-num">x70</div>
        <div className="info-des">Average ATH ROI</div>
      </div>
      <div className="info-box">
        <div className="info-num">1.2M+</div>
        <div className="info-des">Highest Whitelist</div>
      </div>
      <div className="info-box">
        <div className="info-num">$16M+</div>
        <div className="info-des">Total Amount Raised</div>
      </div>
      <div className="info-box">
        <div className="info-num">60+</div>
        <div className="info-des">IDO & INO Projects</div>
      </div>
    </div>
  );
};

export default DataLaunch;
