/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHookAptos } from "./Store-aptos";
import { CHAIN_NETWORK_APTOS, TYPE_APT_TOKEN } from "../../../constants/index";
import "./style.css";

const ConnectAPT = () => {
    const [isAcc, setIsAcc]: any = useState("");
    const [isBalanceAPT, setBalanceAccAPT] = useState(0);
    const [isNetWork, setIsNetWork] = useState("");
    const [state, actions]: any = useHookAptos();
    let navigate = useNavigate();

    useEffect(() => {
        window.pontem?.onNetworkChange((newNetwork: any) => {
            // console.log("onNetworkChange: ", newNetwork);
            setIsNetWork(newNetwork.chainId);
            actions.updateNetAPT(newNetwork.chainId);
        });
    }, []);

    window.pontem?.onChangeAccount((newAccount: any) => {
        setIsAcc(newAccount);
        localStorage.setItem("accAPT", newAccount);
        // window.location.reload();
    });

    const getAptosWallet = () => {
        if (
            typeof window.pontem !== "undefined" &&
            window.pontem !== undefined &&
            window.pontem?._isConnected
        ) {
            return window.pontem;
        }
    };
    const getAptosWalletNew = () => {
        if (typeof window.pontem !== "undefined") {
            return window.pontem;
        } else {
            window.open(
                "https://chrome.google.com/webstore/detail/pontem-aptos-wallet/phkbamefinggmakgklpkljjmgibohnba",
                `_blank`
            );
        }
    };

    const connectAptos = async () => {
        handleCancel();
        const wallet = getAptosWalletNew();

        if (wallet !== undefined) {
            try {
                await wallet.connect().then((resc: any) => {
                    setIsAcc(resc.address);
                    localStorage.setItem("accAPT", resc.address);
                });
            } catch (error: any) {
                message.warning({
                    type: "warning",
                    content: error.message,
                    className: "custom-class",
                    duration: 1,
                });
            }
        }
    };

    const disConnectAptos = async () => {
        actions.updateNetAPT("");
        setIsNetWork("");
        setIsAcc("");
        actions.updateAccAPT("");
        localStorage.setItem("accAPT", "");
        const wallet = getAptosWalletNew();
        await wallet.disconnect();
    };

    useEffect(() => {
        let wallet = getAptosWallet();
        wallet
            ?.isConnected()
            .then((res: any) => {
                // console.log("res---", res);
                if (res) {
                    window.pontem?.account().then((data: any) => {
                        setIsAcc(data);
                        localStorage.setItem("accAPT", data);
                    });
                    window.pontem?.chainId().then((res: any) => {
                        setIsNetWork(res);
                    });
                }
            })
            .catch((err: any) => {
                console.log("err", err);
            });
    }, [isAcc, window.pontem]);

    useEffect(() => {
        if (localStorage.getItem("accAPT")) {
            setIsAcc(localStorage.getItem("accAPT"));
        }
    }, []);

    useEffect(() => {
        actions.updateAccAPT(isAcc);
    }, [isAcc]);

    useEffect(() => {
        actions.updateNetAPT(isNetWork);
    }, [isNetWork]);

    const accountEllipsisRow =
        isAcc && isAcc !== "undefined"
            ? `${isAcc?.substring(0, 8)}...${isAcc?.substring(isAcc.length - 8)}`
            : "Connect Wallet ";

    const accountEllipsisApt =
        isAcc && isAcc !== "undefined"
            ? `${isAcc?.substring(0, 4)}...${isAcc?.substring(isAcc.length - 4)}`
            : "Connect Wallet ";

    const gotoIdoPool = () => {
        navigate("/IDOPools");
    };
    const gotoLaunchPool = () => {
        navigate("/LaunchPool");
    };
    const gotoMyNft = () => {
        navigate("/MyArtwork");
    };
    const gotoMyAccount = () => {
        navigate("/my-account");
    };

    const menuApt = (
        <>
            <div className="main-dropdown-wl">
                <div className="top-dropdown">
                    <div className="box-address">
                        <div className="address-name">Wallet address :</div>
                        <div className="account-name">
                            {accountEllipsisRow}

                            <CopyToClipboard
                                text={`${isAcc}`}
                                onCopy={() => {
                                    message.success({
                                        type: "success",
                                        content: "Copied",
                                        className: "custom-class",
                                        duration: 0.5,
                                    });
                                }}
                            >
                                <span className="img-copy">
                                    <img src="/images/imgido/copy.png" alt="" />
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="bottom-dropdown">
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoMyAccount()}>
                            <img src="/images/award.png" alt="" />{" "}
                            <span className="name-rs">My Account</span>
                        </div>
                    </div>
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoIdoPool()}>
                            <img src="/images/menu-board.png" alt="" />{" "}
                            <span className="name-rs">IDO Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoLaunchPool()}>
                        <div className="icon-rs">
                            <img src="/images/rl.png" alt="" />{" "}
                            <span className="name-rs">Launch Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoMyNft()}>
                        <div className="icon-rs">
                            <img src="/images/rs.png" alt="" />{" "}
                            <span className="name-rs">My NFT</span>
                        </div>
                    </div>
                </div>
                <div className="footer-dropdown">
                    <button type="button" className="btn-dis" onClick={disConnectAptos}>
                        Disconnect Wallet
                    </button>
                </div>
            </div>
        </>
    );

    useEffect(() => {
        if (isAcc && isAcc !== "undefined") {
            // chainNetWork(isNetWork);
            actions
                .getBalanceAPT(isAcc, TYPE_APT_TOKEN, CHAIN_NETWORK_APTOS)
                .then((res: any) => {
                    setBalanceAccAPT(res);
                });
        } else {
            setBalanceAccAPT(0);
        }
    }, [isAcc]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModalAPT = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return {
        connectAptos,
        disConnectAptos,
        menuApt,
        accountEllipsisApt,
        isAcc,
        isBalanceAPT,
    };


};

export default ConnectAPT;
