/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { isMobile, mobileModel } from "react-device-detect";

const Ecosystem = () => {
  function load(img: any) {
    const url = img.getAttribute("lazy-eco");
    img.setAttribute("src", url);
  }

  function lazy() {
    if ("IntersectionObserver" in window) {
      let lazyImgs = document.querySelectorAll("[lazy-eco]");
      let obj = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            load(entry.target);
          }
        });
      });

      lazyImgs.forEach((img) => {
        obj.observe(img);
      });
    }
  }

  // function ready() {
  //   lazy();
  // }

  // document.addEventListener("DOMContentLoaded", ready);
  useEffect(() => {
    lazy();
  }, []);
  return (
    <>
      <div className="main-setion bg-ecosystem ">
        <div className="container-ecosystem">
          {/* <div className="banner-img">
            <img src="/images/banner-ecosystem.svg" alt="" />
          </div>
          <div className="main-title">
            <p className="desc">BSCStation Ecosystem</p>
          </div> */}
          {/* <img src="/images/ecosystem.svg" alt="image" /> */}
          {isMobile ? (
            <div className="eco-mobie">
              <div className="eco-img">
                <img
                  className="eco-title"
                  src="/images/BSCS-Ecosystem.png"
                  alt="image"
                />
                <img src="/images/banner-eco-new-m.png" alt="image" />
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/imgnft/eco-farm.svg" alt="image" />
                </div>
                <div className="eco-text">BSCS Farms</div>
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/imgnft/eco-com.svg" alt="image" />
                </div>
                <div className="eco-text">BSCS Community</div>
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/imgnft/eco-launch.svg" alt="image" />
                </div>
                <div className="eco-text">BSCS Launchpad</div>
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/imgnft/eco-fund.svg" alt="image" />
                </div>
                <div className="eco-text">BSCS Fund</div>
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/dex-m.png" alt="image" />
                </div>
                <div className="eco-text">BSCS DEX Aggregator</div>
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/imgnft/eco-hub.svg" alt="image" />
                </div>
                <div className="eco-text">BSCS Incubation Hub</div>
              </div>
              <div className="eco-content">
                <div className="eco-icon">
                  <img src="/images/dex-tool.png" alt="image" />
                </div>
                <div className="eco-text">BSCS DEX Toolkits</div>
              </div>
            </div>
          ) : (
            <img lazy-eco="/images/banner-eco-new.png" src="" alt="image" />
          )}

          {/* <div className="table-ecosystem">
                        <div className="columns">
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-1.svg" alt="" /> <span className="text">BSCStation Staking {'&'} Farming</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    Benefit holders and projects with high APR
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-3.svg" alt="" /> <span className="text">BSCStation Fund</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    A supportive Venture Capital
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-2.svg" alt="" /> <span className="text">BSCStation Swap</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    A simple way to trade BEP-20 tokens
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-4.svg" alt="" /> <span className="text">BSCStation Launchpad</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    Bring thousands of users and holders to projects
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-5.svg" alt="" /> <span className="text">BSCStation Community</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    Strong, supportive and loyal
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-6.svg" alt="" /> <span className="text">BSCStation GameFi</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    Ground-breaking blockchain Games 
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-7.svg" alt="" /> <span className="text">BSCStation NFTs Marketplace</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    Exchange NFTs and more
                                    </p>
                                </div>
                            </div>
                            <div className="colum w-50">
                                <div className="content-ecosystem">
                                    <div className="title-icon">
                                        <div className="icon-img">
                                            <img src="/images/icon-eco-8.svg" alt="" /> <span className="text">BSCStation Incubation Hub</span>
                                        </div>
                                    </div>
                                    <p className="desc">
                                    Nurture and propel your Project
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
};
export default Ecosystem;
