/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from "react";
import { Select, Table } from "antd";
import { isMobile } from "react-device-detect";
import type { ColumnsType } from "antd/es/table";
import { DollarOutlined } from "@ant-design/icons";

import { useHookReport } from "./StoreIdo";

import "./style.css";
import { useAccount } from "wagmi";

const formatNumber = (num: any) => {
  if (num) {
    let number = Number(num);
    return new Intl.NumberFormat("en-US").format(number);
  } else {
    return "--";
  }
};

const ReportIDO = () => {
  const [state, actions]: any = useHookReport();
  const { address } = useAccount();

  let account = address
  const [listIdo, setListIdo]: any = useState([]);
  const [symbolActive, setSymbolActive]: any = useState();
  const [dataDetail, setDataDetail]: any = useState({});
  const options: any = [];
  const [loading, setLoading]: any = useState(false);

  const getList = async () => {
    try {
      await actions
        .list()
        .then((res: any) => {
          setListIdo(res?.data);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } catch (error) { }
  };

  useEffect(() => {
    if (account) {
      getList();
    }
  }, [account]);

  for (let i = 0; i < listIdo.length; i++) {
    options.push({
      value: listIdo[i].symbol,
      label: listIdo[i].name,
    });
  }

  const getDetails = async () => {
    try {
      let params = {
        address: account,
        symbol: localStorage.getItem("selectRP")
          ? localStorage.getItem("selectRP")
          : symbolActive,
      };
      await actions
        .getDetail(params)
        .then((res: any) => {
          setDataDetail(res?.data);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log("err1", err);
        });
    } catch (error) { }
  };

  const handleChange = (value: any) => {
    localStorage.setItem("selectRP", value);
    setLoading(true);
    setSymbolActive(value);
  };

  useEffect(() => {
    if (localStorage.getItem("selectRP") && account) {
      setLoading(true);
      getDetails();
    }
  }, [account]);

  useEffect(() => {
    if (symbolActive && account) {
      getDetails();
    }
  }, [symbolActive, account]);

  const dataSource = dataDetail?.details?.map((item: any, index: any) => ({
    key: item + 1,
    round: item?.name,
    totalRaise: formatNumber(item?.totalRaise),
    totaljoined: formatNumber(item?.totalJoined),
    totalrefunded: formatNumber(item?.totalRefunded),
    totalclaimed: formatNumber(item?.totalClaimed),
    totaltoken: formatNumber(item?.totalToken),
    totalusdt: formatNumber(item?.totalUSDT),
  }));
  const columns = [
    {
      title: "Round",
      dataIndex: "round",
      key: "round",
    },
    {
      title: "Total Raise($)",
      dataIndex: "totalRaise",
      key: "totalRaise",
    },
    {
      title: "Total Joined($)",
      dataIndex: "totaljoined",
      key: "totaljoined",
    },
    {
      title: "Total Refunded($)",
      dataIndex: "totalrefunded",
      key: "totalrefunded",
    },
    {
      title: "Total Claimed",
      dataIndex: "totalclaimed",
      key: "totalclaimed",
    },
    {
      title: "Total Token",
      dataIndex: "totaltoken",
      key: "totaltoken",
    },
    {
      title: "Total USDT",
      dataIndex: "totalusdt",
      key: "totalusdt",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="all-report">
          <div className="title-report">REPORT IDO</div>
          {isMobile ? (
            <div className="select-ido-mb">
              <Select
                size="large"
                defaultValue={
                  localStorage.getItem("selectRP")
                    ? localStorage.getItem("selectRP")
                    : "Select IDO or Search by Symbol"
                }
                onChange={handleChange}
                options={options}
                style={{ width: "100%" }}
                showSearch
              />
            </div>
          ) : (
            <div className="select-ido">
              <Select
                size="large"
                defaultValue={
                  localStorage.getItem("selectRP")
                    ? localStorage.getItem("selectRP")
                    : "Select IDO or Search by Symbol"
                }
                onChange={handleChange}
                options={options}
                style={{ width: "100%" }}
                showSearch
              />
            </div>
          )}

          {isMobile ? (
            <div className="mb-content-rp">
              <div className="mb-content1">
                <div className="mb-content12">
                  <div className="mb-title">Total Raise</div>
                  <div className="mb-num">
                    <DollarOutlined />
                    <span>{formatNumber(dataDetail?.totalRaise)}</span>
                  </div>
                </div>
              </div>
              <div className="mb-content1">
                <div className="mb-content12">
                  <div className="mb-title">Total Commit Fund</div>
                  <div className="mb-num">
                    <DollarOutlined />
                    <span>{formatNumber(dataDetail?.totalCommitFund)}</span>
                  </div>
                </div>
              </div>
              <div className="mb-content1">
                <div className="mb-content12">
                  <div className="mb-title">Total Refund Amount</div>
                  <div className="mb-num">
                    <DollarOutlined />
                    <span>--</span>
                  </div>
                </div>
              </div>
              <div className="mb-content1">
                <div className="mb-content12">
                  <div className="mb-title">Total Token Remaining</div>
                  <div className="mb-num">
                    <DollarOutlined />
                    <span>--</span>
                  </div>
                </div>
              </div>
              <div className="mb-content1">
                <div className="mb-content12">
                  <div className="mb-title">Total USDT Remaining</div>
                  <div className="mb-num">
                    <DollarOutlined />
                    <span>--</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="content-rp">
              <div className="line-rp"></div>
              <div className="line-rp-next"></div>
              <div className="col-rp">
                <div className="content-col">
                  <div className="top-content">
                    <div className="title-top">Total Raise</div>
                    <div className="num-top">
                      <DollarOutlined />
                      <span className="text-num-top">
                        {formatNumber(dataDetail?.totalRaise)}
                      </span>
                    </div>
                  </div>
                  <div className="content-bottom">
                    <div className="title-top">Total Commit Fund</div>
                    <div className="num-top">
                      <DollarOutlined />
                      <span className="text-num-top">
                        {formatNumber(dataDetail?.totalCommitFund)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-rp">
                <div className="content-col">
                  <div className="top-content">
                    <div className="title-top">Total Refund Amount</div>
                    <div className="num-top">
                      <DollarOutlined />
                      <span className="text-num-top">--</span>
                    </div>
                  </div>
                  <div className="content-bottom">
                    <div className="title-top">Total Token Remaining</div>
                    <div className="num-top">
                      <DollarOutlined />
                      <span className="text-num-top">--</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-rp">
                <div className="content-col">
                  <div className="top-content">
                    <div className="title-top">Total USDT Remaining</div>
                    <div className="num-top">
                      <DollarOutlined />
                      <span className="text-num-top">--</span>
                    </div>
                  </div>
                  {/* <div className="content-bottom">
                  <div className="title-top">DAILY AVERAGE INCOME</div>
                  <div className="num-top">$10000</div>
                </div> */}
                </div>
              </div>
            </div>
          )}
        </div>

        {isMobile ? (
          <div className="table-rp-mb">
            {dataDetail?.details?.map((item: any, index: any) => (
              <div className="mb-rount">
                <div className="round-name">{item?.name}</div>
                <div className="all-content">
                  <div className="round-content">
                    <div className="mb-detail-title">Total Raise</div>
                    <div className="mb-detail-num">
                      ${formatNumber(item?.totalRaise)}
                    </div>
                  </div>
                  <div className="round-content">
                    <div className="mb-detail-title">Total Joined</div>
                    <div className="mb-detail-num">
                      ${formatNumber(item?.totalJoined)}
                    </div>
                  </div>
                  <div className="round-content">
                    <div className="mb-detail-title">Total Refunded</div>
                    <div className="mb-detail-num">
                      ${formatNumber(item?.totalRefunded)}
                    </div>
                  </div>
                  <div className="round-content">
                    <div className="mb-detail-title">Total Claimed</div>
                    <div className="mb-detail-num">
                      {formatNumber(item?.totalClaimed)}
                    </div>
                  </div>
                  <div className="round-content">
                    <div className="mb-detail-title">Total Token</div>
                    <div className="mb-detail-num">
                      {formatNumber(item?.totalToken)}
                    </div>
                  </div>
                  <div className="round-content">
                    <div className="mb-detail-title">Total USDT</div>
                    <div className="mb-detail-num">
                      {formatNumber(item?.totalUSDT)} USDT
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="table-rp">
            <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ReportIDO;
