import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { NETWORK } from "../Ido/detailsSOL/const";

const StoreSol = createStore({
  initialState: {
    balanceSOL: 0,
    accSOL: "",
    netSOL: "",
    balanceTokenJoin: 0,
    claimIdoId: -1,
  },
  actions: {
    updateAccSOL:
      (acc) =>
      ({ setState, getState }) => {
        setState({ accSOL: acc || "" });
      },

    updateNetSOL:
      (net) =>
      ({ setState, getState }) => {
        setState({ netSOL: net || "" });
      },
    setBalaceSol:
      (num) =>
      ({ setState, getState }) => {
        setState({ balanceSOL: num || 0 });
      },
    getTokenBalance:
      (acc, token) =>
      async ({ setState, getState }) => {
        return new Promise((resolve, reject) => {
          const res = axios({
            url: `https://api.${NETWORK}.solana.com/`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              jsonrpc: "2.0",
              id: 1,
              method: "getTokenAccountsByOwner",
              params: [
                acc,
                {
                  mint: token,
                },
                {
                  encoding: "jsonParsed",
                },
              ],
            },
          });
          res.then((response) => {
            if (
              response?.data?.result?.value &&
              response?.data?.result?.value?.length > 0 &&
              response?.data?.result?.value[0]?.account?.data?.parsed?.info
                ?.tokenAmount?.amount > 0
            ) {
              setState({
                balanceTokenJoin:
                  response?.data?.result?.value[0]?.account?.data?.parsed?.info
                    ?.tokenAmount?.uiAmount || 0,
              });
            }
          });
        });
      },
    openModalClaim:
      (id) =>
      ({ setState }) => {
        setState({ claimIdoId: id || -1 });
      },
  },
  name: "StoreSol",
});

export const useHookSol = createHook(StoreSol);
export const Container = createContainer(StoreSol, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});

export const Subscriber = createSubscriber(StoreSol);
