/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from "react";
import axios from "axios";
import {
  Progress,
  Tooltip,
  Button,
  message,
  Spin,
  Skeleton,
  Modal,
} from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { AptosClient, CoinClient, AptosAccount } from "aptos";
import { useHookAptos } from "../../APT/ConnectAPT/Store-aptos";
import { STATUS } from "../index.d";
import { CHAIN_NETWORK_APTOS, TYPE_APT_TOKEN } from "../../../constants";
import { useHookIDO_APT } from "./StoreIdoAPT";
import Value from "./../../../components/Value";

import { getProgressTime, rendererCountDown } from "../utilsIDO";

const client = new AptosClient(CHAIN_NETWORK_APTOS);
const coinClient = new CoinClient(client);

const Item = ({
  item,
  logo,
  unit,
  symbol,
  decimals,
  network,
  usdDecimals,
  accAPT,
  tokenDeposit,
  idoContract,
  netAPT,
  symbolUSD
}: any) => {
  const [state, actions]: any = useHookIDO_APT();
  const [isWaiting, setIsWaiting] = useState(true);
  const [isWaitingClaim, setIsWaitingClaim] = useState(true);
  const [isWaitingRF, setIsWaitingRF] = useState(true);
  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const [isLoadingRF, setIsLoadingRF] = useState(false);
  const [isLoadingRF_All, setIsLoadingRF_All] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);

  //----
  const [isClaimNew, setIsClaimNew] = useState(true);
  const [isRefundNew, setIsRefundNew] = useState(true);
  const [isRefundAll, setIsRefundAll] = useState(false);

  //-----
  const [isBalanceDeposit, setBalanceDeposit] = useState(0);
  const [isBalanceGas, setBalanceGas] = useState(0);
  const [amountJoinSC, setAmountJoinSC] = useState(0);

  const [allocationSc, setAllocation] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [amountCanRefund, setAmountCanRefund] = useState(0);
  const [amountCanClaim, setAmountCanClaim] = useState(0);
  const [processClaim, setProcessClaim]: any = useState(0);
  const [processDeposit, setProcessDeposit]: any = useState(0);

  const [addClass, setAddClass] = useState("");
  const [addClassRF, setAddClassRF] = useState("");

  // console.log("---------------------");
  // console.log("allocation user (allocationSc) = ", allocationSc);
  // console.log("amount user đã claim (claimedAmount) = ", claimedAmount);
  // console.log("amount user đã refund (refundAmount )= ", refundAmount);
  // console.log(
  //   "amount user có thể refund (amountCanRefund) = ",
  //   amountCanRefund
  // );
  // console.log("amount user có thể claim (amountCanClaim) = ", amountCanClaim);
  // console.log("---------------------");

  const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

  const amountJoin = item.allocation?.joinBusd;
  const allocation = item.allocation;
  const is_commit_fund =
    item.allocation && item.allocation.claimChange.refundContract !== null;
  const claimbles = item.allocation && item.allocation.claimbles;

  const signRefund = item.allocation?.claimChange?.signRefund;
  const refundContract = item.allocation?.claimChange?.refundContract;

  let scDeposit = item?.commitFundContract;
  let typeScDeposit = `${scDeposit}${"::mjoinpool::UserInfo<"}${tokenDeposit}${">"}`;
  let typeToken_Deposit = `0x1::coin::CoinStore<${tokenDeposit}>`;
  let scClaim = item.allocation?.claimContract;
  // let scClaim =
  // "0xdf14fc590af9c03c2ba103430c4b55a8a289d220f79eea1cc71f16b14106db53";
  // let typeScClaim = `${scClaim}${"::mclaim::UserInfo<"}${idoContract}${", "}${tokenDeposit}${">"}`

  const showProcess = () => {
    if (allocationSc !== 0) {
      let process =
        item.allocation !== null
          ? Number(((claimedAmount / allocationSc) * 100).toFixed(2))
          : 0;
      setProcessClaim(process);
    }
  };

  const checkWL = async () => {
    await client
      .getAccountResources(accAPT)
      .then((res: any) => {
        let balance_deposit = res.find(
          (item: any) => item.type === typeToken_Deposit
        );

        if (balance_deposit) {
          setBalanceDeposit(balance_deposit?.data?.coin?.value / 1e8);
        }
        let balance_gas = res.find((item: any) => item.type === TYPE_APT_TOKEN);
        if (balance_gas) {
          setBalanceGas(balance_gas?.data?.coin?.value / 1e8);
        }
      })
      .catch((err: any) => {
        // console.log(
        //   "%c [ check ]",
        //   "font-size:13px; background:pink; color:#bf2c9f;",
        //   err
        // );
      });
  };

  const use_usd = "USDT";

  const btnDeposit = () => {
    const obj = {
      className: `btn btn-join`,
      disabled: isLoadingDeposit || isDeposit,
    };
    return obj;
  };
  const btnRefundAll = () => {
    const obj = {
      className: `btn btn-join custom-class-claim`,
      disabled:
        isLoadingClaim || isLoadingRF || isLoadingRF_All || refundAmount > 0,
    };
    return obj;
  };
  const btnClaim_APT = () => {
    const obj = {
      className: `btn btn-join custom-class-claim`,
      disabled: isLoadingClaim || isLoadingRF || isLoadingRF_All,
    };
    return obj;
  };
  const btnRF_APT = () => {
    const obj = {
      className: `btn btn-refund custom-class-rf`,
      disabled: isLoadingRF || isRefundNew || isLoadingClaim || isLoadingRF_All,
    };
    return obj;
  };
  const btnWaiting = () => {
    const obj = {
      className: `btn-waiting`,
      disabled: true,
    };
    return obj;
  };

  const payloadDeposit = {
    type: "entry_function_payload",
    function: `${scDeposit}::mjoinpool::deposit`,
    arguments: [],
    type_arguments: [tokenDeposit],
  };

  const handleDeposit = async () => {
    if (isBalanceDeposit < amountJoinSC) {
      message.error({
        type: "error",
        content: "Not enough balance",
        className: "custom-class",
        duration: 2,
      });
      return;
    }
    if (isBalanceGas < 0) {
      message.error({
        type: "error",
        content: "Not enough balance gas",
        className: "custom-class",
        duration: 2,
      });
      return;
    }

    setIsLoadingDeposit(true);
    try {
      await window.pontem
        .signAndSubmit(payloadDeposit)
        .then(async (res: any) => {
          // console.log("res: ", res);
          if (res.result.hash) {
            await client
              .waitForTransactionWithResult(res.result.hash)
              .then((resWait: any) => {
                console.log("resWait: ", resWait);
                if (resWait.success) {
                  setIsLoadingDeposit(false);
                  setIsDeposit(true);
                  setAddClassRF("");
                  message.success({
                    type: "success",
                    content: "Deposit Successful",
                    className: "custom-class",
                    duration: 2,
                  });
                } else {
                  setIsLoadingDeposit(false);
                  setIsDeposit(false);
                  message.error({
                    type: "error",
                    content: resWait.vm_status,
                    className: "custom-class",
                    duration: 2,
                  });
                }
              });
          }
        });
    } catch (error: any) {
      setIsDeposit(false);
      setIsLoadingDeposit(false);
      message.error({
        type: "error",
        content: error.message,
        className: "custom-class",
        duration: 2,
      });
    }
  };

  const payloadClaim = {
    type: "entry_function_payload",
    function: `${scClaim}::mclaim::claim`,
    arguments: [],
    type_arguments: [idoContract, tokenDeposit],
  };

  const handleClaim_APT = async () => {
    setIsLoadingClaim(true);
    try {
      await window.pontem.signAndSubmit(payloadClaim).then(async (res: any) => {
        if (res.result.hash) {
          await client
            .waitForTransactionWithResult(res.result.hash)
            .then((resWait: any) => {
              if (resWait.success) {
                setIsLoadingClaim(false);
                setIsClaimNew(true);
                setIsRefundAll(true);
                setIsRefundNew(true);
                // setAddClass("");
                message.success({
                  type: "success",
                  content: "Claim Successful",
                  className: "custom-class",
                  duration: 2,
                });
                get_amount();
              } else {
                setIsLoadingClaim(false);
                setIsClaimNew(true);
                setIsRefundNew(true);
                message.error({
                  type: "error",
                  content: resWait.vm_status,
                  className: "custom-class",
                  duration: 2,
                });
              }
            });
        }
      });
    } catch (error: any) {
      setIsLoadingClaim(false);
      setIsClaimNew(false);
      message.error({
        type: "error",
        content: error.message,
        className: "custom-class",
        duration: 2,
      });
    }
  };

  const payloadRefund = {
    type: "entry_function_payload",
    function: `${scClaim}::mclaim::refund`,
    arguments: [false],
    type_arguments: [idoContract, tokenDeposit],
  };

  const handleRF_APT = async () => {
    setIsLoadingRF(true);
    try {
      await window.pontem
        .signAndSubmit(payloadRefund)
        .then(async (res: any) => {
          if (res.result.hash) {
            await client
              .waitForTransactionWithResult(res.result.hash)
              .then((resWait: any) => {
                if (resWait.success) {
                  setIsLoadingRF(false);
                  setIsRefundNew(true);
                  message.success({
                    type: "success",
                    content: "Refund Successful",
                    className: "custom-class",
                    duration: 2,
                  });
                  get_amount();
                } else {
                  setIsLoadingRF(false);
                  setIsClaimNew(true);
                  setIsRefundNew(true);
                  message.error({
                    type: "error",
                    content: "Refund Fail",
                    className: "custom-class",
                    duration: 2,
                  });
                }
              });
          }
        });
    } catch (error: any) {
      setIsLoadingRF(false);
      setIsRefundNew(false);
      message.error({
        type: "error",
        content: error.message,
        className: "custom-class",
        duration: 2,
      });
    }
  };

  const payloadRefund_All = {
    type: "entry_function_payload",
    function: `${scClaim}::mclaim::refund`,
    arguments: [true],
    type_arguments: [idoContract, tokenDeposit],
  };

  const handleRefundAll = async () => {
    setIsLoadingRF_All(true);
    try {
      await window.pontem
        .signAndSubmit(payloadRefund_All)
        .then(async (res: any) => {
          if (res.result.hash) {
            await client
              .waitForTransactionWithResult(res.result.hash)
              .then((resWait: any) => {
                if (resWait.success) {
                  setIsLoadingRF_All(false);
                  setIsRefundAll(true);
                  setIsClaimNew(true);
                  message.success({
                    type: "success",
                    content: "Refund All Successful",
                    className: "custom-class",
                    duration: 2,
                  });
                  get_amount();
                } else {
                  setIsLoadingRF_All(false);
                  setIsClaimNew(true);
                  setIsRefundNew(true);
                  message.error({
                    type: "error",
                    content: "Refund All Fail",
                    className: "custom-class",
                    duration: 2,
                  });
                }
              });
          }
        });
    } catch (error: any) {
      setIsLoadingRF_All(false);
      message.error({
        type: "error",
        content: error.message,
        className: "custom-class",
        duration: 2,
      });
    }
  };

  let params_pool_inf = {
    type: "entry_function_payload",
    function: `${scDeposit}::mjoinpool::get_pool_infor`,
    arguments: [],
    type_arguments: [tokenDeposit],
  };
  let params_user_inf_join = {
    type: "entry_function_payload",
    function: `${scDeposit}::mjoinpool::get_user_infor`,
    arguments: [accAPT],
    type_arguments: [tokenDeposit],
  };

  let params_user_info = {
    function: `${scClaim}::mclaim::get_user_info`,
    type_arguments: [idoContract, tokenDeposit],
    arguments: [accAPT],
  };

  let params_check_claim = {
    function: `${scClaim}::mclaim::get_amount_claim`,
    type_arguments: [idoContract, tokenDeposit],
    arguments: [accAPT],
  };

  let params_check_refund = {
    function: `${scClaim}::mclaim::get_amount_refund`,
    type_arguments: [idoContract, tokenDeposit],
    arguments: [accAPT],
  };



  const get_amount = () => {
    try {
      // console.log("scClaim", scClaim);
      if (scClaim) {
        setIsWaitingClaim(true);
        actions
          .get_amount(params_check_claim)
          .then((res: any) => {
            // console.log("res chcekc claim", res);
            if (res) {
              setAmountCanClaim(Number(res[0]) / 1e8);
            }
            // console.log("res check_claim----", res);
          })
          .catch((err: any) => {
            console.log("err check_claim", err);
          });
        // console.log("-------------------------");
        // actions
        //   .get_amount(params_check_refund)
        //   .then((res: any) => {
        //     console.log("res check_refund", res);
        //     if (res[0] > 0) {
        //       setIsRefundNew(false);
        //     } else {
        //       setIsRefundNew(true);
        //     }
        //   })
        //   .catch((err: any) => {
        //     console.log("err check_refund", err);
        //   });

        actions
          .get_amount(params_user_info)
          .then((res: any) => {
            console.log("res", res);
            // console.log("res user info = ", res);
            // console.log("res user_info", res);
            // if (res[1] === res[4]) {
            //   setIsRefundAll(true);
            // } else {
            //   setIsRefundAll(false);
            // }

            // claimed amount
            setClaimedAmount(Number(res[2]) / 1e8);

            //  refunded amount
            setRefundAmount(Number(res[3]) / 1e8);

            // user can refund amount
            setAmountCanRefund(Number(res[1]) / 1e8);
            // res[1]

            // allocation
            setAllocation(Number(res[0]) / 1e8);

          })
          .catch((err: any) => {
            console.log("err user_info", err);
          });
      }

      if (item.allocation !== null) {
        setIsWaiting(false);
        setIsDeposit(true);
        setAllocation(item?.allocation?.joinToken);
      }
      // if (scDeposit) {
      //   actions.get_amount(params_pool_inf).then((res: any) => {
      //     setProcessDeposit((Number(res[2]) / Number(res[3])) * 100);
      //   });
      //   actions.get_amount(params_user_inf_join).then((res: any) => {
      //     setAmountJoinSC(res[0] / 1e8);
      //     if (res[0] === res[1]) {
      //       setIsWaiting(false);
      //       setIsDeposit(true);
      //     } else {
      //       setIsWaiting(false);
      //       setIsDeposit(false);
      //     }
      //   });
      // }
    } catch (error) {
    } finally {
      setIsWaitingClaim(false);
      setIsWaiting(false);
    }
  };

  useEffect(() => {
    if (
      accAPT !== "undefined" &&
      accAPT !== undefined &&
      item.allocation !== null
    ) {
      checkWL();
      get_amount();
    }
    return () => {
      setIsWaiting(true);
      setIsWaitingClaim(true);
    };
  }, [accAPT, item.allocation, isClaimNew, isRefundNew]);

  useEffect(() => {
    showProcess();
  }, [allocationSc, processDeposit, claimedAmount, amountCanClaim]);

  const formatNumber = (x: any, max: any) => {
    if (x) {
      return x.toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: max,
      });
    } else return 0;
  };



  return (
    <>
      <div className="colum w-33">
        <div className="content-details-rounds">
          <div className="rounds-name">{item?.name}</div>
          <div className="url-name">
            <span className="img">
              <img src={logo} alt="" />
            </span>{" "}
            {unit}
          </div>
          <ul className="list-info-details">
            <li>
              <span className="txt-l">Total Raise:</span>
              <span className="txt-r">
                <Value unit="$" value={item?.totalRaise} />
              </span>
            </li>
            <li>
              <span className="txt-l">Swap Rate:</span>
              <span className="txt-r">{item?.swapAmount}</span>
            </li>
            <li>
              <span className="txt-l">Start Pool:</span>
              <span className="txt-r">{item?.startDate}</span>
            </li>
            <li>
              <span className="txt-l">End Pool:</span>
              <span className="txt-r">{item?.endDate}</span>
            </li>
            <li>
              <span className="txt-l">Vesting:</span>
              <span className="txt-r">
                <div className="tooltips">
                  {item?.vesting ? item?.vesting : "TBA"}
                </div>
              </span>
            </li>
            <li>
              <span className="txt-l">Claim:</span>
              <span className="txt-r color-green">{item?.claimTime}</span>
            </li>
          </ul>

          {item.status === STATUS.COMING ? (
            <>
              <div className="rounds-join-pool">
                {" "}
                Commit fund starts in : TBA
              </div>
            </>
          ) : item.status === STATUS.GOING ? (
            <>
              {is_commit_fund ? (
                <div className="rounds-join-pool">Commit fund starts in</div>
              ) : (
                <div className="rounds-join-pool">Join pool starts in</div>
              )}
              <span className="times-join">
                <Countdown
                  date={getProgressTime(item.startOpenIdo)}
                  renderer={rendererCountDown}
                />
              </span>
            </>
          ) : item.status === STATUS.OPEN ? (
            <>
              {is_commit_fund ? (
                <div className="rounds-join-pool">Commit fund close in</div>
              ) : (
                <div className="rounds-join-pool">Join pool close in</div>
              )}{" "}
              <span className="times-join">
                <Countdown
                  date={getProgressTime(item.closeJoinIdo)}
                  renderer={rendererCountDown}
                />
              </span>
            </>
          ) : (
            ""
          )}

          <div className="rounds-process">
            <span className="title-process">COMMIT PROGRESS</span>
            {item.status === STATUS.CLOSE &&
              allocation !== null &&
              isDeposit ? (
              <Progress
                strokeColor={{
                  "0%": "rgb(249 208 100 / 89%)",
                  "100%": "#F9D064",
                }}
                status="active"
                percent={item?.percentProcess.toFixed(2)}
              // percent={processClaim.toFixed(2)}
              />
            ) : (
              <Progress
                strokeColor={{
                  "0%": "rgb(249 208 100 / 89%)",
                  "100%": "#F9D064",
                }}
                percent={item?.percentProcess.toFixed(2)}
                // percent={processDeposit.toFixed(2)}
                status="active"
              />
            )}
            <div className="process-join-num">
              {item?.totalRaise > 0 ? (
                <>
                  {formatNumber((item?.percentProcess / 100) * item?.totalRaise, 1)}{" "}
                  {symbolUSD} / {formatNumber(item?.totalRaise, 1)}{" "}
                  {symbolUSD}
                </>
              ) : (
                <>
                  0 {symbolUSD} / 0 {symbolUSD}
                </>
              )}
            </div>
          </div>

          {/* <Button onClick={() => handleClaim_APT()}>
            <>
              {isLoadingClaim ? (
                <>
                  Waiting <Spin indicator={antIcon} />
                </>
              ) : (
                "Claim"
              )}
            </>
          </Button> */}

          {accAPT && accAPT !== undefined && accAPT !== "undefined" ? (
            <>
              <div className="row-claim-box">
                {item.status === STATUS.CLOSE &&
                  !is_commit_fund &&
                  isDeposit ? (
                  <>
                    <div className="line-allo"></div>
                    <div className="y-allo">YOUR ALLOCATION</div>
                  </>
                ) : (
                  ""
                )}

                {item.status === STATUS.GOING ||
                  item.status === STATUS.COMING ? (
                  <>
                    <div className="row-claim-token">
                      <div className="text">Funds needed:</div>
                      <div className="name-row">
                        {Number(amountJoin).toFixed(2)} {use_usd}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {item.status === STATUS.OPEN && !isDeposit ? (
                  <>
                    <div className="row-claim-token">
                      <div className="text">Funds needed:</div>
                      <div className="name-row">
                        {Number(amountJoin).toFixed(2)} {use_usd}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}




                {item.status === STATUS.CLOSE &&
                  !is_commit_fund &&
                  isDeposit ? (
                  <>
                    {refundAmount > 0 ? (
                      ""
                    ) : (
                      <div className="row-allocation-token-apt">
                        <div className="text">Next Vesting:</div>
                        <div className="name-row">{allocation?.nextClaim}</div>
                      </div>
                    )}
                    <div className="row-allocation-token-apt">
                      <div className="text">Claimed:</div>
                      <div className="name-row">
                        {claimedAmount} {symbol}
                      </div>
                    </div>
                    <div className="row-allocation-token-apt">
                      <div className="text">Refunded:</div>
                      <div className="name-row">
                        {refundAmount} {use_usd}
                      </div>
                    </div>

                  </>
                ) : (
                  // todo enable claim change
                  ""
                )}

                {item.status === STATUS.CLOSE &&
                  !is_commit_fund &&
                  isDeposit ? (
                  <div className="row-allocation-token-apt">
                    <div className="text">Allocation:</div>
                    <div className="name-row">
                      {formatNumber(allocationSc, 1)} {symbol}
                    </div>
                  </div>
                ) : (
                  // todo enable claim change
                  ""
                )}

                {item.status === STATUS.OPEN && isDeposit ? (
                  <>
                    <div className="row-allocation-token-apt">
                      <div className="text">Investment:</div>
                      <div className="name-row">${amountJoin}</div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {item.status === STATUS.CLOSE && isDeposit ? (
                  <>
                    <div className="row-allocation-token-apt">
                      <div className="text">Investment:</div>
                      <div className="name-row">${amountJoin}</div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* {item.status === STATUS.CLOSE && isDeposit ? (
                    <>
                      <div className="row-claim-token">
                        <div className="text">Claimed</div>
                        <div className="name-row">{totalClaimed}</div>
                      </div>
                    </>
                  ) : (
                    ""
                  )} */}

                {item.status === STATUS.CLOSE && isDeposit ? (
                  <>
                    {signRefund !== null ? (
                      <div className="row-allocation-token-apt">
                        <div className="text">Allocation:</div>
                        <div className="name-row">${amountJoin}</div>
                      </div>
                    ) : (
                      <>
                        {item.status === STATUS.CLOSE &&
                          refundContract !== null ? (
                          <div className="row-allocation-token-apt">
                            <div className="text">Allocation:</div>
                            <div className="name-row">Calculating</div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="btn-rounds">
                {allocation === null ? (
                  <Button className="btn btn-join" disabled>
                    You’re not whitelisted
                  </Button>
                ) : (
                  <>
                    {item.status === STATUS.OPEN ? (
                      <>
                        {isWaiting ? (
                          <Skeleton.Button active {...btnWaiting()} />
                        ) : (
                          <Button
                            {...btnDeposit()}
                            onClick={() => handleDeposit()}
                          >
                            {isDeposit ? (
                              "Deposited"
                            ) : (
                              <>
                                {isLoadingDeposit ? (
                                  <>
                                    Waiting <Spin indicator={antIcon} />
                                  </>
                                ) : (
                                  "Deposit"
                                )}
                              </>
                            )}
                          </Button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {/* ------------------------------------------ */}
                    {item.status === STATUS.GOING ||
                      item.status === STATUS.COMING ? (
                      <>
                        <Button className="btn btn-join" disabled>
                          Coming soon
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    {/* ------------------------------------------ */}
                    {item.status === STATUS.CLOSE ? (
                      <>
                        {isWaitingClaim ? (
                          <Skeleton.Button active {...btnWaiting()} />
                        ) : (
                          <>
                            {!isDeposit ? (
                              <Button className="btn btn-join" disabled>
                                You have&apos;t joined pool
                              </Button>
                            ) : (
                              <>
                                {claimbles?.length > 0 &&
                                  item.allocation.claimbles[0].signToken !==
                                  null ? (
                                  <>
                                    {refundAmount > 0 ? (
                                      ""
                                    ) : (

                                      <>
                                        {allocationSc > 0 &&
                                          amountCanClaim > 0 ? (
                                          <Button
                                            {...btnClaim_APT()}
                                            onClick={() => handleClaim_APT()}
                                          >
                                            <>
                                              {isLoadingClaim ? (
                                                <>
                                                  Waiting{" "}
                                                  <Spin indicator={antIcon} />
                                                </>
                                              ) : (
                                                "Claim"
                                              )}
                                            </>
                                          </Button>
                                        ) : (
                                          <>
                                            {claimedAmount > 0 ? (
                                              <Button
                                                className="btn btn-join custom-class-claim"
                                                disabled
                                              >
                                                Claimed
                                              </Button>
                                            ) : (
                                              <Button
                                                className="btn btn-join custom-class-claim"
                                                disabled
                                              >
                                                Claim
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <Button className="btn btn-join" disabled>
                                    Claim
                                  </Button>
                                )}

                                {/* -----------rf tge----------------------------------------- */}
                                {/* {claimbles?.length > 0 ? (
                              <>
                                {item.allocation.claimbles[0].signBusd !==
                                null ? (
                                  <>
                                    {isClaimNew ? (
                                      <>
                                        <Button
                                          className="btn btn-join custom-class-rf"
                                          disabled
                                        >
                                          Refund
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          {...btnRF_APT()}
                                          onClick={() => handleRF_APT()}
                                        >
                                          {isRefundNew ? (
                                            "Refunded"
                                          ) : (
                                            <>
                                              {isLoadingRF ? (
                                                <>
                                                  Waiting{" "}
                                                  <Spin indicator={antIcon} />
                                                </>
                                              ) : (
                                                "Refund"
                                              )}
                                            </>
                                          )}
                                        </Button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )} */}
                                {/* ------------------------------- */}
                                {claimedAmount > 0 ? (
                                  ""
                                ) : (
                                  <>
                                    {allocation && claimbles && claimbles?.length > 0 &&
                                      claimbles[0]?.signSupDiamondUsd !== null ? (
                                      <Button
                                        {...btnRefundAll()}
                                        onClick={() => handleRefundAll()}
                                        style={{
                                          width: "100%",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {refundAmount > 0 ? (
                                          "Refunded All"
                                        ) : (
                                          <>
                                            {isLoadingRF_All ? (
                                              <>
                                                Waiting{" "}
                                                <Spin indicator={antIcon} />
                                              </>
                                            ) : (
                                              "Refund All"
                                            )}
                                          </>
                                        )}
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}

                                {/* --------------------------------------------- */}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="btn-rounds">
              <div className="btn btn-join please-connect">
                Connect Pontem Wallet
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const BottomIdoDetails = (props: any) => {
  const [state, actions]: any = useHookAptos();
  const data = props.dataBody;

  const titleModal = () => (
    <div className="modal-stt">
      <img src="/images/go-sui.png" alt="" />
      <div className="title-stt">Warning!</div>
    </div>
  );

  let navigate = useNavigate();
  const gotoSui = () => {
    navigate("/my-account");
  };

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (
      state.accAPT &&
      state.accAPT !== "undefined" &&
      data?.ownerAddress === null
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data?.ownerAddress, state.accAPT]);

  return (
    <>
      <div className="main-list-details">
        <div className="columns">
          {data &&
            data.details?.map((item: any) => (
              <Item
                item={item}
                logo={data?.logoUrl}
                unit={data?.unit}
                symbol={data?.symbol}
                symbolUSD={data.symbolUSD}
                decimals={data?.decimals}
                network={data?.network}
                usdDecimals={data?.decimalUsd}
                accAPT={state.accAPT}
                netAPT={state.netAPT}
                tokenDeposit={data.tokenJoinPool}
                idoContract={data.idoContract}
              />
            ))}
        </div>
      </div>

      {/* @ts-ignore this lib is incompatible with react18  */}
      <Modal
        className="modal-goto-sui"
        title={titleModal()}
        visible={open}
        footer={false}
      >
        <p className="noti-sui">
          Please link your APT address before joining the IDO
        </p>
        <div className="go-sui">
          <button onClick={gotoSui}>Go to My Account</button>
        </div>
      </Modal>
    </>
  );
};
export default BottomIdoDetails;
