/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
import { createStore, createHook, createContainer, createSubscriber } from 'react-sweet-state'
import axios from 'axios'
import { API_URL} from "../../../constants/index";


const StoreInoPoolDetails:any = createStore({
    initialState: {
    inoListPoolDetails: {},
    isShowModalStake:false,
    isShowModalUnStake:false
  },
  actions: {
    getInfoInoPoolDetails: (param) => ({ getState, setState }) => {
      return new Promise((resolve, reject) => {
        axios
        .post(`${API_URL}/pool/new_detail`,{...param})
          .then((res) => {
            const { data: { data } } = res
            setState({ inoListPoolDetails: res.data.data } || [])
            resolve(data)
          })
      })
    },
    updateShowStake: (val) => ({ setState }) => {
      setState({ isShowModalStake: val});
    },
    updateShowUnStake: (val) => ({ setState }) => {
      setState({ isShowModalUnStake: val});
    },
  },
  name: 'StoreInoPoolDetails',
})

export const useHookInoPoolDetails = createHook(StoreInoPoolDetails)
export const Container = createContainer(StoreInoPoolDetails, {
  onInit: () => ({ setState }, props) => {
    setState({ ...props })
  },
})
export const Subscriber = createSubscriber(StoreInoPoolDetails)
