/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message, Collapse, Skeleton, Empty } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { postData } from "../../../axios/marketNFT";
import styled from "styled-components";
import moment from "moment";
import Countdown from "react-countdown";

import {
  API_NFTs_MARKET_URL,
  MINT_NFT_CONTRACT,
  BSCS_CONTRACT,
  UNICORN_NFT,
  BID_NFT,
} from "../../../constants/index";
import Loading from "./../../../components/Loading";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import approveContract from "./../../../utils/approve";
import { formatAddress, rendererCountDown } from "./../../../utils/formats";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "./../../../utils/notication";
import abiBidNFT from "../../../abi/abiBidNFT.json";
import abiUniCorn from "../../../abi/abiUnicornNFTMarket.json";
import Value from "../../../components/Value/Value";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import switchNetworkChain from "../../../utils/walletChain";
import { ModalContext } from "../../../components/modal";
import BidModal from "./modal/bidModal";
import BuyModal from "./modal/buyModal";
import SuccessBuyModal from "./modal/successBuyModal";
import TimeAgo from "./../../../utils/timeAgo";
import { getDataByAccountAndAuctionId } from "./../../../hooks/getAllDataMarkets";
import "./style.css";
import { subset } from "semver";
import { getMarketInfor } from "../../../axios/marketNFT";
import {
  _buyNFT,
  _bidNFT,
  _claimNFT,
  _claimToken,
} from "../../../utils/utilsNFT";
import { useContract } from "../../../hooks/useContract";
import { useAccount, useChainId } from "wagmi";

declare const window: Window & typeof globalThis & { ethereum: any };

const DetailNFTNew = () => {
  const { Panel } = Collapse;
  // common
  const currentTime = Math.floor(Date.now() / 1000);
  const { onOpenModal }: any = useContext(ModalContext);
  const { address } = useAccount();
  const chainId = useChainId()
  let account = address

  const [loadingSwitch, setLoadingSwitch] = useState<any>(false);
  const [priceNFT, setPriceNFT] = useState<any>("0");
  const [priceToken, setPriceToken] = useState<any>("0");
  const [detailNFT, setDetailNFT] = useState<any>("");
  const [sugges, setSugges] = useState<any>("");
  const [history, setHistory] = useState<any>("");
  const [_auctionId, setAuctionId] = useState<any>("");
  const [tokenID, setTokenID] = useState<any>("");
  const pathSymbol = window.location.pathname.split("/");
  const queryKey = pathSymbol[2];
  const [loadingApproveBuy, setLoadingApproveBuy] = useState<any>(false);
  const [objData, setObjData] = useState<any>();

  // fixcel price
  const [loadingBuy, setLoadingBuy] = useState<any>(false);

  // sell to highest
  const [loadingBid, setLoadingBid] = useState<any>(false);
  const [loadingClaimToken, setLoadingClaimToken] = useState<any>(false);
  const [loadingClaimNFT, setLoadingClaimNFT] = useState<any>(false);
  const [timeBiding, setTimeBiding] = useState<any>("");
  const price_USD = Number(priceToken) * Number(detailNFT?.price);

  let navigate = useNavigate();
  let time = moment(detailNFT?.auction?.expireTime * 1000).format(
    "MMM DD YYYY h:mm A"
  );

  const gotoListing = async (id: any) => {
    navigate(`${"/listing"}/${id}`);
  };
  const gotoDetail = async (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };
  const uniCorn: any = useContract(UNICORN_NFT, abiUniCorn);
  const contractBid: any = useContract(BID_NFT, abiBidNFT);

  useEffect(() => {
    getActivities();
  }, [queryKey, account, chainId]);

  useEffect(() => {
    getSugges();
  }, [queryKey, account, chainId]);

  useEffect(() => {
    if (queryKey) {
      getDetailNFT();
    }
  }, [queryKey, account]);

  useEffect(() => {
    getAlldata();
  }, [account, _auctionId, chainId]);

  useEffect(() => {
    getPriceToken();
  }, [priceToken]);

  // common
  const getPriceToken = async () => {
    const market = await getMarketInfor();
    const priceUSD = market?.price || 0;
    setPriceToken(priceUSD);
  };
  const getAlldata = async () => {
    await getDataByAccountAndAuctionId(account, _auctionId).then((res: any) => {
      setObjData(res);
    });
  };

  const getDetailNFT = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/detail`, data).then(
      (detail) => {
        if (detail?.data) {
          setDetailNFT(detail?.data);
          setTokenID(detail?.data?.tokenId);
          setPriceNFT(detail?.data?.price);
          setAuctionId(detail?.data?.auction?.auctionId);
        }
      }
    );
  };
  const getSugges = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/suggested`, data).then(
      (res) => {
        if (res?.data) {
          setSugges(res?.data);
        }
      }
    );
  };
  const getActivities = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/activities`, data).then(
      (res) => {
        if (res?.data) {
          setHistory(res?.data);
        }
      }
    );
  };

  const switchChain = async () => {
    try {
      if (chainId !== 56) {
        setLoadingSwitch(true);
        await switchNetworkChain("0x38", "bep");
        setLoadingSwitch(false);
      }
    } catch (error) {
      console.error("error Switch", error);
      setLoadingSwitch(false);
    }
  };
  const savePrice = async (price: any, type: number) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      setPrice: Number(price),
      nftType: type,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/set_price`, data).catch(
      (err) => {
        console.error("save price", err?.message);
      }
    );
  };
  const handleApproveBuy = async (contract: any) => {
    try {
      setLoadingApproveBuy(true);
      if (account) {
        await approveContract(contract, BSCS_CONTRACT, account)
          .then((res: any) => {
            getAlldata();
            getDetailNFT();
            setLoadingApproveBuy(false);
            notiSuccess("Approved successfully", 5);
          })
          .catch((err: any) => {
            console.error("error Approve", err);
            notiError(err.message, 10);
            setLoadingApproveBuy(false);
          });
      }
    } catch (error: any) {
      console.error("error Approve", error);
      notiError(error.message, 10);
      setLoadingApproveBuy(false);
    }
  };
  const updateOwner = async (txHash: any, owner: any) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: owner,
      setPrice: 0,
      txHash: txHash,
      nftType: 0,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/set_owner`, data).catch(
      (err) => {
        console.error("updateOwner", err?.message);
      }
    );
  };
  // end comon

  // Fixed price

  const handleBuyNFT = async () => {
    try {
      setLoadingBuy(true);
      if (account) {
        await _buyNFT(uniCorn, MINT_NFT_CONTRACT, tokenID)
          .then((resBuy: any) => {
            if (resBuy?.hash) {
              createHash(resBuy.hash, 5);
            }
            resBuy.wait().then((resSuccess: any) => {
              if (resSuccess) {
                // savePrice(0, 0);
                setTimeout(() => {
                  updateOwner(resSuccess?.transactionHash, account);
                }, 500);
                onOpenModal(<SuccessBuyModal detail={detailNFT} />);
                getDetailNFT();
                getAlldata();
                notiSuccess("Buy NFT successfully", 5);
                setLoadingBuy(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error BuyNFT", err);
            notiError(err?.reason, 10);
            setLoadingBuy(false);
          });
      }
    } catch (error: any) {
      console.error("error BuyNFT", error);
      notiError(error.message, 10);
      setLoadingBuy(false);
    }
  };
  // end Fixed price

  // Sell to highest bidder
  const createHash = async (txHash: any, type: number) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      txHash: txHash,
      type: type,
    };

    await postData(`${API_NFTs_MARKET_URL}/unicorn/create_hash`, data).catch(
      (err) => {
        console.error("save priceBid", err?.message);
      }
    );
  };
  const saveOfferBid = async (
    code: any,
    price: any,
    auctionId: any,
    txHash: any
  ) => {
    let data: any = {
      queryKey: queryKey,
      code: code,
      ownerAddress: account,
      setPrice: Number(price),
      auctionId: auctionId,
      txHash: txHash,
    };
    await postData(`${API_NFTs_MARKET_URL}/unicorn/make_offer`, data).catch(
      (err) => {
        console.error("save priceBid", err?.message);
      }
    );
  };

  const handleBidNFT = async (amount: any) => {
    try {
      setLoadingBid(true);
      if (Number(amount) > Number(priceNFT)) {
        if (account && detailNFT?.auction?.code) {
          await _bidNFT(
            contractBid,
            _auctionId.toString(),
            convertToWei(amount.toString(), 18)
          )
            .then((resBid: any) => {
              if (resBid?.hash) {
                createHash(resBid.hash, 3);
              }
              resBid.wait().then((resSuccess: any) => {
                if (resSuccess) {
                  saveOfferBid(
                    detailNFT?.auction?.code,
                    amount,
                    Number(detailNFT?.auction?.auctionId),
                    resSuccess?.transactionHash
                  );
                  getAlldata();
                  setLoadingBid(false);
                  notiSuccess("Bid NFT successfully", 5);
                  getDetailNFT();
                  getAlldata();
                  setLoadingBid(false);
                }
              });
            })
            .catch((err: any) => {
              console.error("error resBid", err);
              notiError(err?.reason, 10);
              setLoadingBid(false);
            });
        } else {
          notiWarning("NFTs have not opened for auction yet", 5);
          setLoadingBid(false);
        }
      } else {
        notiWarning(
          "The auction price must be greater than the current bid price",
          5
        );
        setLoadingBid(false);
      }
    } catch (error) {
      console.error("error resBid", error);
      setLoadingBid(false);
    }
  };
  const handleClaimToken = async () => {
    try {
      setLoadingClaimToken(true);
      if (account) {
        await _claimToken(contractBid, _auctionId)
          .then((resClaimToken: any) => {
            if (resClaimToken?.hash) {
              createHash(resClaimToken.hash, 6);
            }
            resClaimToken.wait().then((resSuccess: any) => {
              if (resSuccess) {
                // savePrice(detailNFT?.price, 2);
                setTimeout(() => {
                  updateOwner(
                    resSuccess?.transactionHash,
                    objData?.auction_CurrentOwnerBid
                  );
                }, 500);
                setLoadingClaimToken(false);
                notiSuccess("Claim token successfully", 5);
                getDetailNFT();
                getAlldata();
                setLoadingClaimToken(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resClaimToken", err);
            notiError(err?.reason, 10);
            setLoadingClaimToken(false);
          });
      }
    } catch (error: any) {
      console.error("error resClaimToken", error);
      notiError(error.message, 10);
      setLoadingClaimToken(false);
    }
  };
  const handleClaimNFT = async () => {
    try {
      setLoadingClaimNFT(true);
      if (account) {
        await _claimNFT(contractBid, _auctionId)
          .then((resClaimNFT: any) => {
            if (resClaimNFT?.hash) {
              createHash(resClaimNFT.hash, 6);
            }
            resClaimNFT.wait().then((resSuccess: any) => {
              if (resSuccess) {
                // savePrice(detailNFT?.price, 2);
                setTimeout(() => {
                  updateOwner(resSuccess?.transactionHash, account);
                }, 500);
                setLoadingClaimNFT(false);
                notiSuccess("Claim NFT successfully", 5);
                getDetailNFT();
                getAlldata();
                setLoadingClaimNFT(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resClaimNFT", err);
            notiError(err?.reason, 10);
            setLoadingClaimNFT(false);
          });
      }
    } catch (error: any) {
      console.error("error resClaimNFT", error);
      notiError(error.message, 10);
      setLoadingClaimNFT(false);
    }
  };

  const onConfirmBid = () =>
    onOpenModal(
      <BidModal
        priceUSD={priceToken}
        balanceToken={objData?.token_balance}
        onConfirm={handleBidNFT}
      />
    );
  const onConfirmBuy = () =>
    onOpenModal(
      <BuyModal
        detail={detailNFT}
        price={priceNFT}
        priceUSD={priceToken}
        onConfirm={handleBuyNFT}
      />
    );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [queryKey]);

  // end Sell to highest bidder
  // console.log("objData", objData);

  const ItemLeft = () => {
    return (
      <div className="content-l">
        <div className="box-img">
          {detailNFT?.tokenId >= 20000 ? (
            <video
              autoPlay
              controls
              loop
              playsInline
              style={{
                borderRadius: "6px",
                width: "-webkit-fill-available",
                height: "453px",
              }}
            >
              <source
                src={detailNFT?.nftUrl}
                type="video/mp4"
              />
            </video>
          ) : (
            <img src={detailNFT?.nftUrl} alt="" />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="main-market-launch-details">
        <div className="container">
          <div className="round-details">
            <div className="breadcrum-swap cus">
              <ul className="list-breadcrum">
                <li>
                  <Link to={"/nft-megamarket"}>Marketplace</Link>
                </li>
                <li>{">"}</li>
                <li>Detail NFT's {detailNFT?.name}</li>
              </ul>
            </div>
            <div className="columns">
              <div className="colum w-6">
                <ItemLeft />
              </div>
              <div className="colum w-6">
                <div className="content-r">
                  <div className="box-info-nft">
                    <div className="nft-name new">

                      {detailNFT?.tokenId >= 20000 ? (
                        <>
                          <div className="name">
                            BSCS 3rd Birthday Collections
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="icon">
                            <img
                              style={{ width: "40px", height: "40px" }}
                              src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/91beb211beede4111d4988c2594edb270eb0e603002c0ac9e9.png"
                              alt=""
                            />
                          </div>
                          <div className="name">
                            Blue-the Unicorn NFT Collections
                          </div>
                        </>
                      )}

                      <div className="icon-smail">
                        <img src="../images/imgmarket/img-v2.svg" alt="" />
                      </div>
                    </div>
                    <div className="id-nft">
                      <div className="num-id">{detailNFT?.name}</div>
                      <div className="owner-nft-add">
                        {formatAddress(detailNFT?.ownerAddress, 5, 4)}
                        <CopyToClipboard
                          text={detailNFT?.ownerAddress}
                          onCopy={() => {
                            message.success({
                              type: "success",
                              content: "Copied",
                              className: "custom-class",
                              duration: 0.5,
                            });
                          }}
                        >
                          <span className="img-copy-new">
                            <img
                              className="coppy"
                              src="/images/imgido/copy.png"
                              alt=""
                            />
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                    {/* owner */}
                    {!objData ||
                      (!detailNFT && (
                        <Skeleton
                          className="pt-10"
                          active
                          paragraph={{ rows: 3 }}
                        />
                      ))}

                    {detailNFT?.tokenId >= 20000 ? ('') : (
                      <>
                        <div className="box-buy">
                          <div className="content-box-buy">
                            {detailNFT?.buyType === 2 && (
                              <>
                                <div className="title-price-nft">Sale end in:</div>
                                <TimeCountdown>
                                  <Countdown
                                    date={time}
                                    renderer={rendererCountDown}
                                  />
                                </TimeCountdown>
                              </>
                            )}

                            <div className="title-price-nft">
                              {detailNFT?.buyType === 2
                                ? "Highest offer:"
                                : "Current price:"}
                            </div>
                            <div className="price-new-nft">
                              <div className="logo-price-token">
                                <img
                                  src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/f6809fdcc09391f822abcebf9af600493af5173e04c33bf57e.png"
                                  alt=""
                                />
                              </div>

                              <div className="num-price-token">
                                <Value value={priceNFT} fontWeight={600} /> BSCS
                              </div>
                              <div className="num-price-usd">
                                {price_USD > 0 && (
                                  <Value value={Number(price_USD)} unit="$" />
                                )}
                              </div>
                            </div>
                            <div className="active-buy-nft pb-10">
                              {chainId !== 56 && account && (
                                <button
                                  className="btn-buy-nft-new "
                                  disabled={loadingSwitch}
                                  onClick={switchChain}
                                >
                                  <Loading
                                    status={loadingSwitch}
                                    content="Switch Chain"
                                  />
                                </button>
                              )}
                            </div>
                            <div className="active-buy-nft">
                              {detailNFT?.ownerAddress?.toLowerCase() ===
                                account?.toLowerCase() && (
                                  <button
                                    disabled={chainId !== 56 || !account}
                                    className="btn-buy-nft-new"
                                    onClick={() => gotoListing(detailNFT?.queryKey)}
                                  >
                                    {detailNFT?.price > 0
                                      ? "Edit Listing"
                                      : "List for sale"}
                                  </button>
                                )}

                              {!objData || !detailNFT ? (
                                <Skeleton
                                  className="pt-10"
                                  active
                                  paragraph={{ rows: 3 }}
                                />
                              ) : (
                                <>
                                  {/* User buy fix cel */}
                                  {Number(priceNFT) > 0 &&
                                    account?.toLowerCase() !==
                                    detailNFT?.ownerAddress?.toLowerCase() &&
                                    detailNFT?.buyType === 1 && (
                                      <>
                                        {Number(objData?.token_balance) <
                                          Number(priceNFT) ? (
                                          <button
                                            type="button"
                                            disabled
                                            className="btn-buy-nft-new"
                                          >
                                            Balance insufficient
                                          </button>
                                        ) : (
                                          <>
                                            {Number(
                                              objData?.token_allowance_market
                                            ) >= Number(priceNFT) ? (
                                              <button
                                                type="button"
                                                disabled={
                                                  loadingBuy ||
                                                  !account ||
                                                  chainId !== 56
                                                }
                                                className="btn-buy-nft-new"
                                                onClick={onConfirmBuy}
                                              >
                                                <Loading
                                                  status={loadingBuy}
                                                  content={`Buy NFT`}
                                                />
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                disabled={
                                                  loadingApproveBuy ||
                                                  !account ||
                                                  chainId !== 56
                                                }
                                                className="btn-buy-nft-new"
                                                onClick={() =>
                                                  handleApproveBuy(UNICORN_NFT)
                                                }
                                              >
                                                <Loading
                                                  status={loadingApproveBuy}
                                                  content="Approve Buy"
                                                />
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  {/* user bid */}
                                  {detailNFT?.ownerAddress?.toLowerCase() !==
                                    account?.toLowerCase() &&
                                    Number(detailNFT?.price) > 0 &&
                                    detailNFT?.buyType === 2 && (
                                      <>
                                        {Number(objData?.token_balance) >=
                                          Number(detailNFT?.price) ? (
                                          <>
                                            {Number(objData?.token_allowance_bid) >=
                                              Number(detailNFT?.price) ? (
                                              <button
                                                disabled={
                                                  loadingBid ||
                                                  loadingClaimNFT ||
                                                  !account ||
                                                  chainId !== 56 ||
                                                  currentTime >
                                                  detailNFT?.auction?.expireTime
                                                }
                                                type="button"
                                                className="btn-buy-nft-new"
                                                onClick={onConfirmBid}
                                              >
                                                <Loading
                                                  status={loadingBid}
                                                  content="Bid NFT"
                                                />
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                disabled={
                                                  loadingApproveBuy ||
                                                  !account ||
                                                  chainId !== 56
                                                }
                                                className="btn-buy-nft-new"
                                                onClick={() =>
                                                  handleApproveBuy(BID_NFT)
                                                }
                                              >
                                                <Loading
                                                  status={loadingApproveBuy}
                                                  content="Approve Bid"
                                                />
                                              </button>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              type="button"
                                              disabled
                                              className="btn-buy-nft-new"
                                            >
                                              Balance insufficient
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="nft-attribute">
                          <Collapse
                            // defaultActiveKey={["1"]}
                            bordered={false}
                            expandIcon={({ isActive }) => (
                              <RightOutlined rotate={isActive ? 270 : 180} />
                            )}
                          >
                            <Panel header="Attributes" key="1">
                              <div className="wrap-item-att">
                                {detailNFT?.attrs?.map((data: any, index: any) => {
                                  return (
                                    <>
                                      <div key={index} className="att-col">
                                        <div className="att-item">
                                          <div className="att-title1">
                                            {data?.unicorn}
                                          </div>
                                          <div className="att-title2">
                                            {data?.name} <span>{data?.rate}%</span>
                                          </div>
                                          <div className="att-title3">
                                            Floor: {data?.floor} BSCS
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      </>
                    )}

                    <div className="nft-attribute">
                      <Collapse
                        defaultActiveKey={["1"]}
                        bordered={false}
                        expandIcon={({ isActive }) => (
                          <RightOutlined rotate={isActive ? 270 : 180} />
                        )}
                      >
                        <Panel header="Details" key="1">
                          <div className="content-detail-nft-new">
                            <div className="detai-new line1">
                              <div className="title-left">Token address:</div>
                              <div className="content-right-nft">
                                {formatAddress(MINT_NFT_CONTRACT, 5, 4)}
                                <CopyToClipboard
                                  text={MINT_NFT_CONTRACT}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <span className="img-copy-new">
                                    <img
                                      className="coppy"
                                      src="/images/imgido/copy.png"
                                      alt=""
                                    />
                                  </span>
                                </CopyToClipboard>
                              </div>
                            </div>
                            <div className="detai-new line2">
                              <div className="title-left">Owner:</div>
                              <div className="content-right-nft">
                                {formatAddress(detailNFT?.ownerAddress, 5, 4)}
                                <CopyToClipboard
                                  text={detailNFT?.ownerAddress}
                                  onCopy={() => {
                                    message.success({
                                      type: "success",
                                      content: "Copied",
                                      className: "custom-class",
                                      duration: 0.5,
                                    });
                                  }}
                                >
                                  <span className="img-copy-new">
                                    <img
                                      className="coppy"
                                      src="/images/imgido/copy.png"
                                      alt=""
                                    />
                                  </span>
                                </CopyToClipboard>
                              </div>
                            </div>
                            {detailNFT?.tokenId >= 20000 ? ("") : (
                              <div className="detai-new line3">
                                <div className="title-left">Creator fees:</div>
                                <div className="content-right-nft">3%</div>
                              </div>
                            )}

                            <div className="detai-new line4">
                              <div className="title-left">Chain:</div>
                              <div className="content-right-nft">
                                <img
                                  className="chain-logo-nft-new"
                                  src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/8962866318f8a125b2ddffe94ac1a740f33e59518380966a79.png"
                                  alt=""
                                />
                                BNB Chain
                              </div>
                            </div>
                            <div className="detai-new line5">
                              <div className="title-left">Create Date:</div>
                              <div className="content-right-nft">
                                <TimeAgo timestamp={detailNFT?.createDate} />
                              </div>
                            </div>
                            <div className="detai-new line5">
                              <div className="title-left">Last Updated:</div>
                              <div className="content-right-nft">
                                <TimeAgo timestamp={detailNFT?.updateDate} />
                              </div>
                            </div>
                            <div className="detai-new line6">
                              <div className="title-left">Token Standard:</div>
                              <div className="content-right-nft">BEP-721</div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>

              {detailNFT?.tokenId >= 20000 ? ('') : (
                <>
                  <div className="colum w-12">
                    <div className="nft-attribute">
                      <Collapse
                        defaultActiveKey={["1"]}
                        bordered={false}
                        expandIcon={({ isActive }) => (
                          <RightOutlined rotate={isActive ? 270 : 180} />
                        )}
                      >
                        <Panel header="Offer history" key="1">
                          <div className="content-detail-nft-new">
                            <table className="table-active-nft">
                              <tr className="table-nft-header">
                                <th>STT</th>
                                <th>Price</th>
                                <th>Floor Difference</th>
                                <th>Last Updated</th>
                                <th>From </th>
                                <th> </th>
                              </tr>

                              {detailNFT?.auction?.offerHistories?.length > 0 ? (
                                <>
                                  {" "}
                                  {detailNFT?.auction?.offerHistories.map(
                                    (item: any, index: any) => {
                                      return (
                                        <>
                                          <tr
                                            key={index}
                                            className="table-nft-body"
                                          >
                                            <td>{index + 1}</td>
                                            <td>
                                              <b>{item?.price} BSCS</b>
                                            </td>
                                            <td>{item?.floor}%</td>
                                            <td>
                                              <TimeAgo
                                                timestamp={item?.createTime - 10}
                                              />
                                            </td>
                                            <td>
                                              <a
                                                href={`https://bscscan.com/address/${item?.fromAddress}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {formatAddress(
                                                  item?.fromAddress,
                                                  5,
                                                  4
                                                )}
                                              </a>
                                            </td>
                                            <td>
                                              <div className="btn-open-link">
                                                {!item?.txHash &&
                                                  objData?.auction_Owner?.toLowerCase() ===
                                                  account?.toLowerCase() ? (
                                                  <button
                                                    className="btn-offer"
                                                    disabled={
                                                      currentTime <
                                                      detailNFT?.auction
                                                        ?.expireTime ||
                                                      loadingClaimToken ||
                                                      !account ||
                                                      chainId !== 56
                                                    }
                                                    onClick={handleClaimToken}
                                                  >
                                                    <Loading
                                                      status={loadingClaimToken}
                                                      content="Claim Token"
                                                    />
                                                  </button>
                                                ) : !item?.txHash &&
                                                  objData?.auction_CurrentOwnerBid?.toLowerCase() ===
                                                  account?.toLowerCase() ? (
                                                  <button
                                                    className="btn-offer"
                                                    disabled={
                                                      currentTime <
                                                      detailNFT?.auction
                                                        ?.expireTime ||
                                                      loadingClaimNFT ||
                                                      !account ||
                                                      chainId !== 56
                                                    }
                                                    onClick={handleClaimNFT}
                                                  >
                                                    <Loading
                                                      status={loadingClaimNFT}
                                                      content="Claim NFT"
                                                    />
                                                  </button>
                                                ) : item?.txHash ? (
                                                  <a
                                                    href={`https://bscscan.com/tx/${item?.txHash}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    <i>Refunded</i>
                                                  </a>
                                                ) : (
                                                  "highest bid"
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <>
                                  <tr className="table-nft-body">
                                    <td colSpan={6}>
                                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </td>
                                  </tr>
                                </>
                              )}
                            </table>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                  <div className="colum w-12">
                    <div className="nft-attribute">
                      <Collapse
                        defaultActiveKey={["1"]}
                        bordered={false}
                        expandIcon={({ isActive }) => (
                          <RightOutlined rotate={isActive ? 270 : 180} />
                        )}
                      >
                        <Panel header="Activities" key="1">
                          <div className="content-detail-nft-new">
                            <table className="table-active-nft">
                              <tr className="table-nft-header">
                                <th>STT</th>
                                <th>Type</th>
                                <th>Price</th>
                                <th>Date</th>
                                <th>Transaction </th>
                                <th>Address</th>
                              </tr>
                              {history?.length > 0 ? (
                                <>
                                  {history?.map((item: any, index: any) => {
                                    return (
                                      <>
                                        <tr key={index} className="table-nft-body">
                                          <td>{index + 1}</td>
                                          <td>{item?.type}</td>
                                          <td>{item?.price}</td>
                                          <td>
                                            <TimeAgo
                                              timestamp={item?.createTime - 10}
                                            />
                                          </td>
                                          <td>
                                            {item?.transaction !== "-"
                                              ? formatAddress(
                                                item?.transaction,
                                                5,
                                                4
                                              )
                                              : "--"}
                                          </td>
                                          <td>
                                            {formatAddress(item?.mintAddress, 5, 4)}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr className="table-nft-body">
                                    <td colSpan={6}>
                                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </td>
                                  </tr>
                                </>
                              )}
                            </table>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                  {sugges?.length > 0 && (
                    <div className="title-sugg">Suggested</div>
                  )}

                  <div className="columns-new">
                    {!sugges ? (
                      <>
                        <Skeleton active paragraph={{ rows: 3 }} />
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </>
                    ) : (
                      <>
                        {" "}
                        {sugges?.map((data: any, index: any) => {
                          return (
                            <>
                              <div className="colum w-3 newnft">
                                <div className="guide-market">
                                  <div
                                    className="box-img"
                                    onClick={() => gotoDetail(data?.queryKey)}
                                  >
                                    <img
                                      className="imgNft-market"
                                      src={data?.nftUrl}
                                      alt=""
                                    />
                                  </div>
                                  <div className="name-item">
                                    <div className="marquee-nft-detail">
                                      <div>
                                        <span>{data?.name}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="artis-item-detail">
                                    BSCS NFT Academy
                                  </div>
                                  <div className="price-item-detail">
                                    <span className="t-left-detail">
                                      Current price:
                                    </span>
                                    <div className="t-right-detail">
                                      <div className="icon-detail">
                                        <img
                                          src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/f6809fdcc09391f822abcebf9af600493af5173e04c33bf57e.png"
                                          alt=""
                                        />
                                      </div>
                                      <div className="number-detail d-flex ">
                                        <Value
                                          value={data?.price}
                                          fontWeight={600}
                                        />{" "}
                                        BSCS
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </>
              )}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailNFTNew;

export const TimeCountdown = styled.span`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
