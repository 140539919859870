import React, { useMemo, useState, useCallback, useEffect } from "react";

import { getFullDisplayBalance } from "../../utilsST/formatBalance";
import Input from "../../componentsST/InputV2/index";
// import Modal from "../../componentsST/Modal/index";
import Button from "../../componentsST/ButtonV2/index";
import useSousStake from "../../hooksST/useSousStake";
import { Modal, message } from "antd";
import { sousStake } from "../../utilsST/callHelpers";
import "../../componentsST/StakeInPoolModal/index.less";
import { useContract } from "../../hooks/useContract";
import sousChefAbi from "../../config/abi/sousChef.json";
import poolsConfig from '../../constantsST/pools'

const DepositModal = (props) => {
  const [val, setVal] = useState("");
  const [pendingTx, setPendingTx] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    sousId,
    decimals,
    onConfirm,
    onDismiss,
    max,
    stakingToken,
    addressToken,
    isPool = false,
    // isModalOpen,
  } = props;
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max);
  }, [max]);

  const handleChange = useCallback(
    (e) => {
      setVal(e.currentTarget.value);
    },
    [setVal]
  );

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance);
  }, [fullBalance, setVal]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const pool = poolsConfig.find((pool) => pool.sousId === sousId);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const handleStake = async () => {
    try {
      setPendingTx(true);
      await sousStake(contract, val, decimals).then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
          if(res2 != null ){
            setPendingTx(false);
            onDismiss()
            message.success({
              type: "success",
              content: "Staking success!",
              className: "custom-class",
              duration: 3,
              
            });
          }
          
        });
      })
      .catch((error) => {
        setPendingTx(false);
      });;
    } catch (e) {
      message.error({
        type: "error",
        content: e?.message,
        className: "custom-class",
        duration: 3,
      });
      setPendingTx(false);
    }
  };
  return (
    <Modal
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal-cus-pool"
    >
      <div className="bsc-stake_in_pool-modal">
        <div className="bsc-stake_in_pool-modal-header">
          <span>Stake in pool</span>
          <span>{`Stake ${stakingToken.symbol}`}</span>
        </div>
        <div className="bsc-stake_in_pool-modal-content">
          <div className="bsc-stake_in_pool-modal-content-top">
            <span>{getFullDisplayBalance(max, stakingToken.decimals)}</span>
            <span>{`${stakingToken.symbol} Available`}</span>
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid">
            <Input
              change={handleChange}
              type="number"
              value={val}
              right={
                <>
                  <span>{stakingToken.symbol}</span>
                  <Button text="Max" primary click={handleSelectMax} />
                </>
              }
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-bottom mb-4">
            <Button text="Cancel" ghost click={onDismiss} />
            <Button
              text="Confirm"
              primary
              loading={pendingTx}
              disabled={pendingTx || val <= 0}
              click={handleStake}
            />
          </div>
          <span className="bsc-stake_in_pool-modal-content-bottom mb-4 text-getmore-stake">
            <a
              href={`https://pancakeswap.finance/swap?outputCurrency=${addressToken.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              {`Get more ${stakingToken.symbol} >>`}
            </a>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default DepositModal;
