const Icon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="#687688"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5743 2.9L7.66601 2.28333C4.40767 1.775 2.89934 2.875 2.38268 6.13333L1.76601 10.0417C1.43268 12.175 1.78268 13.5583 2.99101 14.4C3.62434 14.85 4.49101 15.15 5.61601 15.325L9.52434 15.9417C12.7827 16.45 14.291 15.35 14.8077 12.0917L15.416 8.18333C15.516 7.54166 15.5577 6.96666 15.5243 6.45833C15.416 4.375 14.191 3.30833 11.5743 2.9ZM6.86601 8.29166C5.89101 8.29166 5.09934 7.49999 5.09934 6.53333C5.09934 5.55833 5.89101 4.76666 6.86601 4.76666C7.83267 4.76666 8.62434 5.55833 8.62434 6.53333C8.62434 7.49999 7.83267 8.29166 6.86601 8.29166Z"
      
    />
    <path d="M17.0832 11.725L15.8332 15.4833C14.7915 18.6166 13.1249 19.45 9.99154 18.4083L6.2332 17.1583C5.0582 16.7666 4.2082 16.2833 3.6582 15.675C4.1832 15.8833 4.79154 16.0416 5.4832 16.15L9.39987 16.7666C9.9332 16.85 10.4332 16.8916 10.8999 16.8916C13.6499 16.8916 15.1249 15.4083 15.6332 12.2166L16.2415 8.3083C16.3249 7.82497 16.3582 7.39997 16.3582 7.0083C17.6249 8.04997 17.8082 9.5333 17.0832 11.725Z" />
  </svg>
);
export default Icon;
