import Header from "../Upload/components/Header";
import FormDetail from "../Upload/components/FormDetail";
import bgMainheader from "./images/banner-ino.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Upload = () => {
  let navigate = useNavigate();

  return (
    <>
      <Wrapper
        style={{
          background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
        }}
      >
        <Fullwidth>
          <Container>
            <div className="bodyUpload">
              <button className="back-mint" onClick={() => navigate(-1)}>
                <div className="icon-mint">
                  <ArrowLeftOutlined />
                </div>

                <div className="text-back"> Back</div>
              </button>

              <Header
                title="Mint new NFT on BSCS"
                description="Create NFT"
              />

              <FormDetail />
            </div>
          </Container>
        </Fullwidth>
      </Wrapper>
    </>
  );
};

export default Upload;

const Wrapper = styled.div`
  padding-top: 92px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
const Fullwidth = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 736px;
  padding: 0 10px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
