/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Progress, Button, message, Spin, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { STATUS } from "../index.d";
import {
    TOKEN_PROGRAM_ID,
    getOrCreateAssociatedTokenAccount,
} from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";


import { SOLANA_HOST } from "../detailsSOL/const";
import { PublicKey, LAMPORTS_PER_SOL, Connection } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { useHookSol } from "../../Solana/Store-sol";

import IDO_IDL from "./bscs_ido.json";
import ModalClaim from "./ModalClaim";
import { getProgressTime, rendererCountDown } from "../utilsIDO";
import { getOrCreateAssociatedTokenAccountCustom } from "./utils/spl-token";

const connection = new Connection(SOLANA_HOST, {
    commitment: "confirmed",
});

const Item = ({
    item,
    logo,
    unit,
    symbol,
    accSOL,
    usdtAddress,
    symbolUSD
}:
    any) => {

    const { publicKey, sendTransaction }: any = useWallet();

    const [state, actions]: any = useHookSol();
    const [isDeposit, setIsDeposit] = useState(false);
    const [totalJoined, setTotalJoined]: any = useState(0);

    const [totalRefund, setTotalRefund] = useState(0);
    const [joinedAmount, setJoinedAmount]: any = useState(0);
    const [processJoin, setProcessJoin]: any = useState(0);
    const [processClaim, setProcessClaim]: any = useState(0);

    const [allocationSc, setAllocation] = useState(0);

    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [isLoadingRF, setIsLoadingRF] = useState(false);

    const [isClaim, setIsClaim] = useState(false);
    const [amounClaimed, setAmounClaimed] = useState(0);

    const [isRefundAll, setIsRefundAll] = useState(false);
    const [amounRefunded, setAmounRefunded] = useState(0);


    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

    const amountJoin = item.allocation?.joinBusd;
    const allocation = item.allocation;
    const is_commit_fund =
        item.allocation && item.allocation.claimChange.refundContract !== null;

    const signRefund = item.allocation?.claimChange?.signRefund;
    const refundContract = item.allocation?.claimChange?.refundContract;

    useEffect(() => {
        setProcessJoin(((totalJoined / item?.totalRaise) * 100).toFixed(2));
    }, [totalJoined]);

    useEffect(() => {
        if (amounClaimed > 0) {
            setProcessClaim(100)
        } else {
            setProcessClaim(0)
        }
        // setProcessClaim(
        //   (totalRefund / joinedAmount) * 100 +
        //   (totalClaim / item.allocation?.joinToken) * 100
        // );
    }, [
        processClaim,
        item.allocation?.joinToken,
        totalRefund,
        joinedAmount,
        amounClaimed
    ]);

    const use_usd = "USDT";

    const formatNumber = (x: any, max: any) => {
        if (x) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };

    // const btnDeposit = () => {
    //   const obj = {
    //     className: `btn btn-join`,
    //     disabled: isLoadingDeposit || isDeposit,
    //   };
    //   return obj;
    // };

    // const btnWaiting = () => {
    //   const obj = {
    //     className: `btn-waiting`,
    //     disabled: true,
    //   };
    //   return obj;
    // };

    // let IDO_PROGRAM_ID: any;
    // if (item?.commitFundContract) {
    //   // IDO_PROGRAM_ID = new PublicKey(item?.commitFundContract);
    //   IDO_PROGRAM_ID = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB');

    // }

    // const provider = new anchor.AnchorProvider(
    //   connection,
    //   window?.solana,
    //   anchor.AnchorProvider.defaultOptions()
    // );

    // let program: any;
    // if (IDO_PROGRAM_ID && provider) {
    //   program = new anchor.Program<any>(IDO_IDL, IDO_PROGRAM_ID, provider);
    // }

    // const getUserInfoPDA = async (userPubkey: PublicKey): Promise<[any, any]> => {
    //   const seed = userPubkey;
    //   if (program) {
    //     return await anchor.web3.PublicKey.findProgramAddress(
    //       [seed?.toBuffer(), Buffer.from("ido_insert_whitelist")],
    //       program?.programId
    //     );
    //   } else return ["", ""];
    // };

    // const getMinInfoPDA = async (minPubkey: PublicKey): Promise<[any, any]> => {
    //   const seed = minPubkey;
    //   if (program) {
    //     return await anchor.web3.PublicKey.findProgramAddress(
    //       [seed?.toBuffer(), Buffer.from("ido_pool_config")],
    //       program?.programId
    //     );
    //   } else return ["", ""];
    // };

    // const handleDeposit = async () => {
    //   try {
    //     // check balance fee SOL
    //     // let balanceSol: any = await connection.getBalance(
    //     //   window?.solana?.publicKey
    //     // );
    //     // console.log(`balance: ${balanceSol / LAMPORTS_PER_SOL}`);
    //     if (state.balanceSOL <= 0) {
    //       message.warning({
    //         type: "warning",
    //         content: "Not enough balance",
    //         className: "custom-class",
    //         duration: 2,
    //       });
    //       return;
    //     }

    //     // console.log("balanceJoin: ", balanceJoin);
    //     // if (balanceJoin < amountJoin) {
    //     //   message.warning({
    //     //     type: "warning",
    //     //     content: "Not enough balance",
    //     //     className: "custom-class",
    //     //     duration: 2,
    //     //   });
    //     //   return;
    //     // }

    //     setIsLoadingDeposit(true);
    //     let usdtAta = await getOrCreateAssociatedTokenAccount(
    //       connection,
    //       window?.solana?.publicKey,
    //       usdtAddress,
    //       window?.solana?.publicKey
    //     );

    //     let [programUsdtAta, programUsdtAtaBump] =
    //       await anchor.web3.PublicKey.findProgramAddress(
    //         [usdtAddress.toBuffer()],
    //         program.programId
    //       );

    //     const getConfigPDA = async (
    //       usdtMintAddress: PublicKey
    //     ): Promise<[PublicKey, number]> => {
    //       const seed = usdtMintAddress;
    //       return await anchor.web3.PublicKey.findProgramAddress(
    //         [seed.toBuffer(), Buffer.from("ido_pool_config")],
    //         program.programId
    //       );
    //     };
    //     const [config, _] = await getConfigPDA(usdtAddress);
    //     // let dataCf: any = await program.account.config.fetch(config);
    //     let [userInfoPDA, x] = await getUserInfoPDA(window.solana.publicKey);
    //     let signature = await program.rpc.joinpool(
    //       programUsdtAtaBump,
    //       // usdtAmount,
    //       {
    //         accounts: {
    //           tokenProgram: TOKEN_PROGRAM_ID,
    //           userUsdtAta: usdtAta.address,
    //           userUsdtAtaAuthority: window?.solana?.publicKey,
    //           programUsdtAta: programUsdtAta,
    //           usdtMint: usdtAddress,
    //           // treasury: treasury,
    //           payer: window?.solana?.publicKey,
    //           config: config,
    //           userInfo: userInfoPDA,
    //         },
    //       }
    //     );

    //     if (signature) {
    //       const result = await connection.getSignatureStatus(signature, {
    //         searchTransactionHistory: true,
    //       });
    //       if (result.value?.err === null) {
    //         setIsLoadingDeposit(false);
    //         setIsDeposit(true);
    //         message.success({
    //           type: "success",
    //           content: "Deposit Success",
    //           className: "custom-class",
    //           duration: 2,
    //         });
    //       }
    //     }
    //   } catch (error: any) {
    //     if (error?.message) {
    //       let err = error?.message.toLowerCase();
    //       let index = err?.indexOf("error message");

    //       let errCustom = err?.slice(index);
    //       if (index !== -1) {
    //         message.error({
    //           type: "error",
    //           content: errCustom,
    //           className: "custom-class",
    //           duration: 2,
    //         });
    //       } else {
    //         message.error({
    //           type: "error",
    //           content: error?.message,
    //           className: "custom-class",
    //           duration: 2,
    //         });
    //       }
    //     }

    //     setIsLoadingDeposit(false);
    //   }
    // };

    // check data
    // const logData = async () => {
    //   let [userInfo, y]: any = [];
    //   let [minInfo, x]: any = [];
    //   if (window?.solana?.publicKey) {
    //     [userInfo, y] = await getUserInfoPDA(window?.solana?.publicKey);
    //   }
    //   if (program && usdtAddress) {
    //     [minInfo, x] = await getMinInfoPDA(usdtAddress);
    //   }

    //   if (program && userInfo && item?.allocation) {
    //     await program?.account?.userInfoAccount
    //       .fetch(userInfo)
    //       .then((res: any) => {
    //         // console.log("joinedAmount", res?.joinedAmount.toNumber());
    //         if (res?.joinedAmount.toNumber() > 0) {
    //           setJoinedAmount(res.joinedAmount.toNumber() / LAMPORTS_PER_SOL);
    //           setIsDeposit(true);
    //           setIsWaiting(false);
    //         }

    //         // setTotalClaimed(res?.claimedAmount.toNumber() / LAMPORTS_PER_SOL);
    //         setJoinAmount(res?.joinAmount.toNumber() / LAMPORTS_PER_SOL);
    //       })
    //       .catch((err: any) => {
    //         console.log(
    //           "%c [ err ]-522",
    //           "font-size:13px; background:pink; color:#bf2c9f;",
    //           err
    //         );
    //       })
    //       .finally(() => {
    //         setIsWaiting(false);
    //         // setIsWaitingClaim(false);
    //       });
    //   } else {
    //     setIsWaiting(false);
    //   }
    //   // --------------------
    //   if (program && minInfo) {
    //     await program?.account?.config.fetch(minInfo).then((res: any) => {
    //       if (res) {
    //         setTotalJoined(res?.totalJoinedAmount.toNumber() / LAMPORTS_PER_SOL);
    //         // setTotalRaise(res?.maxRaised.toNumber());
    //       }
    //     });
    //   }
    // };

    // useEffect(() => {
    //   logData();
    // }, [window?.solana, accSOL, item?.allocation]);

    const showModal = (id: any) => {
        actions.openModalClaim(id);
    };

    const caculAmount = async () => {
        setAllocation(item?.allocation?.joinToken);
    };

    useEffect(() => {
        caculAmount();
    }, [item]);

    useEffect(() => {
        if (item.allocation !== null) {
            setIsDeposit(true);
        }
    }, [])

    //------------------------------

    const btnClaim = () => {
        const obj = {
            className: `btn btn-join custom-class-claim`,
            disabled:
                isClaim ||
                amounClaimed > 0 ||
                isRefundAll ||
                amounRefunded > 0 ||
                isLoadingClaim ||
                isLoadingRF
        };
        return obj;
    };

    const btnRefundAll = () => {
        const obj = {
            className: `btn btn-join custom-class-claim`,
            disabled:
                isClaim ||
                isRefundAll ||
                amounClaimed > 0 ||
                amounRefunded > 0 ||
                isLoadingClaim ||
                isLoadingRF
        };
        return obj;
    };





    //---handle button------------------

    let IDO_PROGRAM_ID: any;
    if (item?.allocation?.claimContract) {
        IDO_PROGRAM_ID = new PublicKey(item?.allocation?.claimContract);
    }

    const provider: any = new anchor.AnchorProvider(
        connection,
        window?.solana,
        anchor.AnchorProvider.defaultOptions()
    );

    let program: any;
    if (IDO_PROGRAM_ID && provider) {
        program = new anchor.Program<any>(IDO_IDL, IDO_PROGRAM_ID, provider);
    }

    const getUserInfoPDA = async (userPubkey: PublicKey): Promise<[any, any]> => {
        const seed = userPubkey;
        if (program) {
            return await anchor.web3.PublicKey.findProgramAddress(
                [seed?.toBuffer(), Buffer.from("ido_insert_whitelist")],
                program?.programId
            );
        } else return ["", ""];
    };

    const handleRefundAll = async () => {
        try {
            setIsLoadingRF(true);
            // setdisableClaim(true);
            // let usdtAta = await getOrCreateAssociatedTokenAccount(
            //     connection,
            //     window.solana?.publicKey,
            //     usdtAddress,
            //     window.solana?.publicKey
            // );

            let usdtAta = await getOrCreateAssociatedTokenAccountCustom(
                connection,
                publicKey,
                // window.solana?.publicKey,
                usdtAddress,
                publicKey,
                // window.solana?.publicKey,
                sendTransaction
            )

            let [programUsdtAta, programUsdtAtaBump] =
                await anchor.web3.PublicKey.findProgramAddress(
                    [usdtAddress.toBuffer()],
                    program.programId
                );

            const getConfigPDA = async (
                usdtMintAddress: PublicKey
            ): Promise<[PublicKey, number]> => {
                const seed = usdtMintAddress;
                return await anchor.web3.PublicKey.findProgramAddress(
                    [seed.toBuffer(), Buffer.from("ido_pool_config")],
                    program.programId
                );
            };
            const [config, _] = await getConfigPDA(usdtAddress);
            let [userInfoPDA, x] = await getUserInfoPDA(window.solana.publicKey);

            let signature = await program.rpc.refundAll(programUsdtAtaBump, {
                accounts: {
                    tokenProgram: TOKEN_PROGRAM_ID,
                    userUsdtAta: usdtAta.address,
                    userUsdtAtaAuthority: window?.solana?.publicKey,
                    programUsdtAta: programUsdtAta,
                    programUsdtAuthority: programUsdtAta,
                    usdtMint: usdtAddress,
                    payer: window?.solana?.publicKey,
                    config: config,
                    userInfo: userInfoPDA,
                },
            });
            if (signature) {
                const result = await connection.getSignatureStatus(signature, {
                    searchTransactionHistory: true,
                });
                if (result.value?.err === null) {
                    setIsLoadingRF(false);
                    setIsRefundAll(true);
                    message.success({
                        type: "success",
                        content: "Refund All Success",
                        className: "custom-class",
                        duration: 2,
                    });
                }
            }
        } catch (error: any) {
            if (error?.message) {
                let err = error?.message.toLowerCase();
                let index = err?.indexOf("error message");

                let errCustom = err?.slice(index);
                if (index !== -1) {
                    message.error({
                        type: "error",
                        content: errCustom,
                        className: "custom-class",
                        duration: 2,
                    });
                } else {
                    message.error({
                        type: "error",
                        content: error?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                }
            }
            setIsLoadingRF(false);
        } finally {
            // setdisableClaim(false);
        }
    };

    useEffect(() => {
        if (window?.solana) {
            setTimeout(() => {
                window?.solana.on("accountChanged", (publicKey: any) => {
                    window.location.reload()
                });
              }, 2000);
        }

    }, [window?.solana]);


    const handleClaim = async () => {
        try {
            setIsLoadingClaim(true);
            // let usdtAta: any = await getOrCreateAssociatedTokenAccount(
            //     connection,
            //     window.solana?.publicKey,
            //     usdtAddress,
            //     window.solana?.publicKey
            // )

            let usdtAta = await getOrCreateAssociatedTokenAccountCustom(
                connection,
                publicKey,
                // window.solana?.publicKey,
                usdtAddress,
                publicKey,
                // window.solana?.publicKey,
                sendTransaction
            )
            console.log('usdtAta', usdtAta)

            const getConfigPDA = async (
                usdtMintAddress: PublicKey
            ): Promise<[PublicKey, number]> => {
                const seed = usdtMintAddress;
                return await anchor.web3.PublicKey.findProgramAddress(
                    [seed.toBuffer(), Buffer.from("ido_pool_config")],
                    program.programId
                );
            };

            const [config, _] = await getConfigPDA(usdtAddress);

            let [userInfoPDA, x] = await getUserInfoPDA(window.solana.publicKey);

            let [programUsdtAta, programUsdtAtaBump] =
                await anchor.web3.PublicKey.findProgramAddress(
                    [usdtAddress.toBuffer()],
                    program.programId
                );

            let signature = await program.rpc.claim(programUsdtAtaBump, {
                accounts: {
                    tokenProgram: TOKEN_PROGRAM_ID,
                    userUsdtAta: usdtAta.address,
                    userUsdtAtaAuthority: window?.solana?.publicKey,
                    programUsdtAta: programUsdtAta,
                    programUsdtAuthority: programUsdtAta,
                    usdtMint: usdtAddress,
                    payer: window?.solana?.publicKey,
                    config: config,
                    userInfo: userInfoPDA,
                },
            })

            if (signature) {
                const result = await connection.getSignatureStatus(signature, {
                    searchTransactionHistory: true,
                });
                if (result.value?.err === null) {
                    setIsLoadingClaim(false);
                    setIsClaim(true);
                    message.success({
                        type: "success",
                        content: "Claim Success",
                        className: "custom-class",
                        duration: 2,
                    });
                    // getData();
                }
            }
        } catch (error: any) {
            let startMes = error?.toString().indexOf("Error Message");
            if (startMes !== -1) {
                let showMes = error.toString().slice(startMes);
                message.error({
                    type: "error",
                    content: showMes,
                    className: "custom-class",
                    duration: 2,
                });
            } else {
                message.error({
                    type: "error",
                    content: error?.message || 'TokenAccountNotFoundError',
                    className: "custom-class",
                    duration: 2,
                });
            }
            setIsLoadingClaim(false);
        } finally {
            // setdisableRF(false);
            setIsLoadingClaim(false);
        }
    };
    //----------------------------------

    //-----check data--------
    const getData = async () => {
        let [userInfo, y]: any = [];
        if (window?.solana?.publicKey) {
            [userInfo, y] = await getUserInfoPDA(window?.solana?.publicKey);
        }

        if (program && userInfo) {
            await program?.account?.userInfoAccount
                .fetch(userInfo)
                .then((res: any) => {
                    // console.log('res', res)
                    if (res) {
                        // check amount claimed
                        if (res?.claimedAmount) {
                            setAmounClaimed(res?.claimedAmount?.toNumber() / LAMPORTS_PER_SOL)
                        } else {
                            setAmounClaimed(0)
                        }

                        // check status claim
                        if (res?.claimedAmount?.toNumber() > 0) {
                            setIsClaim(true)
                        } else {
                            setIsClaim(false)
                        }

                        // check amount refunded
                        if (res?.refundedAmount) {
                            setAmounRefunded(res?.refundedAmount?.toNumber() / LAMPORTS_PER_SOL)
                        } else {
                            setAmounRefunded(0)
                        }

                        // check status refund
                        if (res?.refundedAmount?.toNumber() > 0) {
                            setIsRefundAll(true)
                        } else {
                            setIsRefundAll(false)
                        }
                    }
                })
                .catch((err: any) => {
                    console.log('userInfoAccount', err)
                });
        } else {
            // setIsWaiting(false);
            // setIsWaitingClaim(false);
        }
    };

    useEffect(() => {
        getData();
    }, [window?.solana, item]);

    const claimbles = item.allocation && item.allocation.claimbles;


    const [isSig, setIsSig]: any = useState()
    const [loadingSig, setloadingSig]: any = useState(false)

    const btnGen = () => {
        const obj = {
            className: `btn btn-join custom-class-claim`,
            disabled:
                loadingSig ||
                isSig
        };
        return obj;
    };

    const handleGenSig = async () => {
        try {
            setloadingSig(true)
            await getOrCreateAssociatedTokenAccountCustom(
                connection,
                publicKey,
                usdtAddress,
                publicKey,
                sendTransaction
            ).then((res: any) => {
                console.log('res', res)
                setloadingSig(false)
                setIsSig(res)
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setloadingSig(false)
                    setIsSig('xxx')
                })

        } catch (error) {
            setloadingSig(false)
        }
    }

    return (
        <>
            <div className="colum w-33">
                <div className="content-details-rounds">
                    <div className="rounds-name">{item?.name}</div>
                    <div className="url-name">
                        <span className="img">
                            <img src={logo} alt="" />
                        </span>{" "}
                        {unit}
                    </div>
                    <ul className="list-info-details">
                        <li>
                            <span className="txt-l">Total Raise:</span>
                            <span className="txt-r">${item?.totalRaise}</span>
                        </li>
                        <li>
                            <span className="txt-l">Swap Rate:</span>
                            <span className="txt-r">{item?.swapAmount}</span>
                        </li>
                        <li>
                            <span className="txt-l">Start Pool:</span>
                            <span className="txt-r">{item?.startDate}</span>
                        </li>
                        <li>
                            <span className="txt-l">End Pool:</span>
                            <span className="txt-r">{item?.endDate}</span>
                        </li>
                        <li>
                            <span className="txt-l">Vesting:</span>
                            <span className="txt-r">
                                <div className="tooltips">
                                    {item?.vesting ? item?.vesting : "TBA"}
                                </div>
                            </span>
                        </li>
                        <li>
                            <span className="txt-l">Claim:</span>
                            <span className="txt-r color-green">{item?.claimTime}</span>
                        </li>
                    </ul>

                    {item.status === STATUS.COMING ? (
                        <>
                            <div className="rounds-join-pool">
                                {" "}
                                Commit fund starts in : TBA
                            </div>
                        </>
                    ) : item.status === STATUS.GOING ? (
                        <>
                            {is_commit_fund ? (
                                <div className="rounds-join-pool">Commit fund starts in</div>
                            ) : (
                                <div className="rounds-join-pool">Join pool starts in</div>
                            )}
                            <span className="times-join">
                                <Countdown
                                    date={getProgressTime(item.startOpenIdo)}
                                    renderer={rendererCountDown}
                                />
                            </span>
                        </>
                    ) : item.status === STATUS.OPEN ? (
                        <>
                            {is_commit_fund ? (
                                <div className="rounds-join-pool">Commit fund close in</div>
                            ) : (
                                <div className="rounds-join-pool">Join pool close in</div>
                            )}{" "}
                            <span className="times-join">
                                <Countdown
                                    date={getProgressTime(item.closeJoinIdo)}
                                    renderer={rendererCountDown}
                                />
                            </span>
                        </>
                    ) : (
                        ""
                    )}

                    <div className="rounds-process">
                        <span className="title-process">COMMIT PROGRESS</span>
                        <Progress
                            strokeColor={{
                                "0%": "rgb(249 208 100 / 89%)",
                                "100%": "#F9D064",
                            }}
                            percent={processClaim}
                            status="active"
                        />

                        <div className="process-join-num">
                            {item?.totalRaise > 0 ? (
                                <>
                                    {formatNumber((item?.percentProcess / 100) * item?.totalRaise, 1)}{" "}
                                    {symbolUSD} / {formatNumber(item?.totalRaise, 1)}{" "}
                                    {symbolUSD}
                                </>
                            ) : (
                                <>
                                    0 {symbolUSD} / 0 {symbolUSD}
                                </>
                            )}
                        </div>
                    </div>

                    {accSOL && accSOL !== undefined && accSOL !== "undefined" ? (
                        <>
                            <div className="row-claim-box">
                                {item.status === STATUS.CLOSE &&
                                    !is_commit_fund &&
                                    isDeposit ? (
                                    <>
                                        <div className="line-allo"></div>
                                        <div className="y-allo">YOUR ALLOCATION</div>
                                    </>
                                ) : (
                                    ""
                                )}

                                {item.status === STATUS.GOING ||
                                    item.status === STATUS.COMING ? (
                                    <>
                                        <div className="row-claim-token">
                                            <div className="text">Funds needed:</div>
                                            <div className="name-row">
                                                {Number(amountJoin).toFixed(2)} {use_usd}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                {item.status === STATUS.OPEN && !isDeposit ? (
                                    <>
                                        <div className="row-claim-token">
                                            <div className="text">Funds needed:</div>
                                            <div className="name-row">
                                                {Number(amountJoin).toFixed(2)} {use_usd}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}


                                {item.status === STATUS.CLOSE &&
                                    !is_commit_fund &&
                                    isDeposit ? (
                                    <>
                                        {amounRefunded > 0 ? (
                                            ""
                                        ) : (
                                            <div className="row-allocation-token-apt">
                                                <div className="text">Next Vesting:</div>
                                                <div className="name-row">{allocation?.nextClaim}</div>
                                            </div>
                                        )}
                                        <div className="row-allocation-token-apt">
                                            <div className="text">Claimed:</div>
                                            <div className="name-row">
                                                {amounClaimed} {symbol}
                                            </div>
                                        </div>
                                        <div className="row-allocation-token-apt">
                                            <div className="text">Refunded:</div>
                                            <div className="name-row">
                                                {amounRefunded} {use_usd}
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    // todo enable claim change
                                    ""
                                )}

                                {item.status === STATUS.CLOSE &&
                                    !is_commit_fund &&
                                    isDeposit ? (
                                    <div className="row-allocation-token-apt">
                                        <div className="text">Allocation:</div>
                                        <div className="name-row">
                                            {formatNumber(allocationSc, 1)} {symbol}
                                        </div>
                                    </div>
                                ) : (
                                    // todo enable claim change
                                    ""
                                )}

                                {item.status === STATUS.OPEN && isDeposit ? (
                                    <>
                                        <div className="row-allocation-token-apt">
                                            <div className="text">Investment:</div>
                                            <div className="name-row">${amountJoin}</div>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                {item.status === STATUS.CLOSE && isDeposit ? (
                                    <>
                                        <div className="row-allocation-token-apt">
                                            <div className="text">Investment:</div>
                                            <div className="name-row">${amountJoin}</div>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                {item.status === STATUS.CLOSE && isDeposit ? (
                                    <>
                                        {signRefund !== null ? (
                                            <div className="row-allocation-token-apt">
                                                <div className="text">Allocation:</div>
                                                <div className="name-row">${amountJoin}</div>
                                            </div>
                                        ) : (
                                            <>
                                                {item.status === STATUS.CLOSE &&
                                                    refundContract !== null ? (
                                                    <div className="row-allocation-token-apt">
                                                        <div className="text">Allocation:</div>
                                                        <div className="name-row">Calculating</div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="btn-rounds">

                                {allocation === null ? (
                                    <Button className="btn btn-join" disabled>
                                        You’re not whitelisted
                                    </Button>
                                ) : (
                                    <>
                                        {/* ------------------------------------------ */}
                                        {item.status === STATUS.GOING ||
                                            item.status === STATUS.COMING ? (
                                            <>
                                                <Button className="btn btn-join" disabled>
                                                    Coming soon
                                                </Button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {/* ------------------------------------------ */}
                                        {item.status === STATUS.CLOSE ? (
                                            <>
                                                {!isDeposit ? (
                                                    <Button className="btn btn-join" disabled>
                                                        You have&apos;t joined pool
                                                    </Button>
                                                ) : (
                                                    <>

                                                        {usdtAddress && !isSig && !isClaim && !isRefundAll ? (
                                                            <Button  {...btnGen()} onClick={handleGenSig}>
                                                                {loadingSig ? (
                                                                    <>
                                                                        Waiting{" "}
                                                                        <Spin indicator={antIcon} />
                                                                    </>
                                                                ) : ('Confirm Your Wallet Address')}

                                                            </Button>
                                                        ) : (
                                                            <>
                                                                {claimbles?.length > 0 &&
                                                                    item.allocation.claimbles[0].signToken !==
                                                                    null ? (
                                                                    <>
                                                                        {isRefundAll ? (
                                                                            ""
                                                                        ) : (
                                                                            <>
                                                                                {isClaim ? (
                                                                                    <Button
                                                                                        className="btn btn-join custom-class-claim"
                                                                                        disabled
                                                                                    >
                                                                                        Claimed
                                                                                    </Button>
                                                                                ) : (
                                                                                    <>
                                                                                        <Button

                                                                                            {...btnClaim()}
                                                                                            onClick={() => handleClaim()}
                                                                                        >
                                                                                            <>
                                                                                                {isLoadingClaim ? (
                                                                                                    <>
                                                                                                        Waiting{" "}
                                                                                                        <Spin indicator={antIcon} />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    "Claim"
                                                                                                )}
                                                                                            </>
                                                                                        </Button>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <Button className="btn btn-join" disabled>
                                                                        Claim
                                                                    </Button>
                                                                )}

                                                                {isClaim ? (
                                                                    ""
                                                                ) : (
                                                                    <>
                                                                        {allocation && claimbles && claimbles?.length > 0 &&
                                                                            claimbles[0]?.signSupDiamondUsd !== null ? (

                                                                            <>
                                                                                {isRefundAll ? (
                                                                                    <Button
                                                                                        className="btn btn-join custom-class-claim"
                                                                                        disabled
                                                                                    >
                                                                                        Refunded
                                                                                    </Button>
                                                                                ) : (
                                                                                    <>
                                                                                        <Button
                                                                                            {...btnRefundAll()}
                                                                                            onClick={() => handleRefundAll()}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                marginTop: "10px",
                                                                                            }}
                                                                                        >
                                                                                            {isLoadingRF ? (
                                                                                                <>
                                                                                                    Waiting{" "}
                                                                                                    <Spin indicator={antIcon} />
                                                                                                </>
                                                                                            ) : (
                                                                                                "Refund All"
                                                                                            )}
                                                                                        </Button>
                                                                                    </>
                                                                                )}
                                                                            </>


                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}



                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {/* show text */}
                                        {/* <div className="row-claim-box">
                      {!is_commit_fund ? (
                        item.status !== STATUS.CLOSE && allocation !== null ? (
                          !isDeposit ? (
                            <div className="row-claim-token">
                              <div className="text">Funds needed:</div>
                              <div className="name-row">
                                {formatNumber(joinAmount, 2)} {use_usd}
                              </div>
                            </div>
                          ) : isDeposit ? (
                            <div className="row-claim-token">
                              <div className="text">Investment:</div>
                              <div className="name-row">
                                {joinedAmount.toFixed(2)} {use_usd}
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {!is_commit_fund &&
                        item.status === STATUS.CLOSE &&
                        isDeposit ? (
                        <>
                          <div className="row-claim-token">
                            <div className="text">Claimed</div>
                            <div className="name-row">
                              {formatNumber(totalClaim, 2)} {symbol}
                            </div>
                          </div>
                          <div className="row-claim-token">
                            <div className="text">Refunded</div>
                            <div className="name-row">
                              {formatNumber(totalRefund, 2)} {use_usd}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {!isDeposit ? (
                        ""
                      ) : (
                        <>
                          {signRefund !== null || !is_commit_fund ? (
                            <div className="row-allocation-token">
                              <div className="text">Allocation:</div>
                              <div className="name-row">
                                {formatNumber(item.allocation?.joinToken, 2)}{" "}
                                {symbol}
                              </div>
                            </div>
                          ) : item.status === STATUS.CLOSE &&
                            signRefund === null ? (
                            <div className="row-allocation-token">
                              <div className="text">Allocation:</div>
                              <div className="name-row">Calculating</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div> */}
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="btn-rounds">
                            <div className="btn btn-join please-connect">
                                Connect Phantom Wallet
                            </div>
                        </div>
                    )}


                </div>

            </div>
            <ModalClaim
                data={allocation?.claimbles}
                idoId={item?.id}
                usdtAddress={usdtAddress}
            />
        </>
    );
};
const BottomIdoDetails = (props: any) => {
    const [state, actions]: any = useHookSol();
    const data = props.dataBody;

    const [usdtAddress, setusdtAddress]: any = useState();
    useEffect(() => {
        if (data) {
            try {
                // setusdtAddress(new PublicKey(data?.tokenJoinPool));
                setusdtAddress(new PublicKey(data?.idoContract));
            } catch (error) {
                console.log('error', error)
            }
        }
        // if (window?.solana?.publicKey && data.tokenJoinPool) {
        //   let publicKeyWallet = new PublicKey(window?.solana?.publicKey);
        //   actions.getTokenBalance(publicKeyWallet, data.tokenJoinPool);
        // }
    }, [state.accSOL, data?.idoContract, data]);

    const titleModal = () => (
        <div className="modal-stt">
            <img src="/images/go-sui.png" alt="" />
            <div className="title-stt">Warning!</div>
        </div>
    );

    let navigate = useNavigate();
    const gotoSui = () => {
        navigate("/my-account");
    };

    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (
            state.accSOL &&
            state.accSOL !== "undefined" &&
            data?.ownerAddress === null
        ) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [data?.ownerAddress, state.accSOL]);

    return (
        <>
            <div className="main-list-details">
                <div className="columns">
                    {data &&
                        data.details?.map((item: any) => (
                            <Item
                                item={item}
                                logo={data?.logoUrl}
                                unit={data?.unit}
                                symbol={data?.symbol}
                                decimals={data?.decimals}
                                network={data?.network}
                                usdDecimals={data?.decimalUsd}
                                accSOL={state.accSOL}
                                symbolUSD={data.symbolUSD}
                                // netAPT={state.netAPT}
                                tokenDeposit={data.tokenJoinPool}
                                idoContract={data.idoContract}
                                // publicKeyWallet={publicKeyWallet}
                                usdtAddress={usdtAddress}
                            // balanceJoin={state.balanceTokenJoin}
                            />
                        ))}
                </div>
            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                className="modal-goto-sui"
                title={titleModal()}
                visible={open}
                footer={false}
            >
                <p className="noti-sui">
                    You have to bind the Solana Wallet to join the IDO.
                </p>
                <div className="go-sui">
                    <button onClick={gotoSui}>Go to My Account</button>
                </div>
            </Modal>
        </>
    );
};
export default BottomIdoDetails;
