import { useEffect, useState } from 'react';
import Counter from './contract';
import IdoClaim from './tact_IdoClaim'
import { useTonClient } from './useTonClient';
import { useTonConnect } from './useTonConnect';
import { useAsyncInitialize } from './useAsyncInitialize';
import { Address, OpenedContract } from '@ton/core';

export function useCounterContract(sc: any) {
    const client = useTonClient();
    const [val, setVal] = useState<null | string>();
    const { sender } = useTonConnect();

    const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

    const counterContract = useAsyncInitialize(async () => {
        if (!client) return;
        const contract = new Counter(
            Address.parse(sc) // replace with your address from tutorial 2 step 8
        );
  

        return client.open(contract) as OpenedContract<Counter>;
    }, [client]);

    const claimContract = useAsyncInitialize(async () => {
        if (!client) return;
        const contractClaim = new IdoClaim(
            Address.parse(sc)
        );
        return client.open(contractClaim) as OpenedContract<IdoClaim>;
    }, [client]);


    // useEffect(() => {
    //   async function getValue() {
    //     if (!counterContract) return;
    //     setVal(null);
    //     const val = await counterContract.getCounter();
    //     setVal(val.toString());
    //     await sleep(5000); // sleep 5 seconds and poll value again
    //     getValue();
    //   }
    //   getValue();
    // }, [counterContract]);

    return {
        // value: val,
        // address: counterContract?.address.toString(),
        // sendIncrement: () => {
        //   return counterContract?.sendIncrement(sender);
        // },
        counterContract,
        claimContract
    };
}