/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import {
  Tabs,
  Input,
  Select,
  message,
  Empty,
  Radio,
  Popover,
  Spin,
  Modal,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Yup from "yup";
import Value from "./../../../../components/Value";
import { useFormik } from "formik";
import "./style.css";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useHookStakeNFT } from "./../StoreNFT";
import { useContract } from "./../../../../hooks/useContract";
import UploadFile from "./../../Upload/components/UploadFile";
import {
  CONTRACT_NFT,
  API_IMAGE,
  STAKE_NFT,
  NFT_CONTRACTID,
  CONTRACT_BID_VERSION,
} from "./../../../../constants/index";
import Button from "./../../../../components/Button";
import abiStakeNFT from "./../abiStakeNFT.json";
import { useApproveNFTCallbackCustom } from "./../../../../hooks/useApproveCallback";
import { _ownerOf, _getApproved } from "./../../MyArtwork/utils";
import ErrorPage from "../../../ErrorPage";
import {
  _checkStakes,
  _stake,
  _unStake,
  _getReward,
  _havest,
} from "./../utils";
import abiNFT from "./../../../../constants/abis/nft.json";
import getBalanceBSCS from "../../../../hooks/getBalanceBSCS";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";

const StakeNft = () => {
  const [state, actions]: any = useHookStakeNFT();
  const { objData } = state;
  const objSocial = objData.social;
  const { Search } = Input;

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { Option } = Select;
  let navigate = useNavigate();
  let curentChain = localStorage.getItem("chain");
  const { address } = useAccount();
  let account = address
  const [isLoadMore, setIsLoadMore] = useState(false);
  const contractPoolStake = "0x1e3fa6a96c20259e030a105308a9a09d1b6d150f";

  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no artwork</p>
    </>
  );

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  const handleLoadMore = () => {
    setIsLoadMore(true);
    actions.getMyNFT(account).then((res: any) => {
      if (res) {
        setIsLoadMore(false);
      }
    });
  };

  const getMyNFT = useCallback(async () => {
    await actions.getMyNFT(account);
  }, [actions, account]);

  const initData = useCallback(() => {
    if (account !== undefined) {
      getMyNFT();
    }
  }, [getMyNFT]);
  useEffect(() => {
    if (account !== undefined) {
      initData();
    }
  }, [initData]);

  const [avatar, setAvatar] = useState("");
  const [artistName, setArtistName] = useState("");
  const [artistDescription, setartistDescription] = useState("");

  useEffect(() => {
    if (account) {
      actions.getArtistsByWallet(account).then((res: any) => {
        if (res.data) {
          setAvatar("");
          setArtistName("");
          setartistDescription("");
        } else {
          setAvatar(res.avatarName);
          setArtistName(res.artistName);
          setartistDescription(res.artistDescription);
        }
      });
    }
  }, [account, avatar]);

  const gotoCreateProfile = () => {
    navigate(`${"/create-profile"}`);
  };
  const gotoMintNFT = () => {
    navigate(`${"/mint-nft"}`);
  };
  const Item = (data: any) => {
    const item = data.data;
    const CONTRACT_BID = CONTRACT_BID_VERSION[item.isNew ? 1 : 0];
    const [reward, setReward] = useState(0);
    const [isStatusStake, setIsStatusStake] = useState(false);
    const stakeNFTContract = useContract(STAKE_NFT, abiStakeNFT);
    const [Owner, setOwner] = useState("");
    const [allowance, setAllowance] = useState("");
    const [loadAprrove, setLoadApprove] = useState(false);
    const [isApprove, setApprove] = useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [statusStake, setStatusStake] = useState(false);
    const [statusUnStake, setStatusUnStake] = useState(false);
    const NFTContract = useContract(CONTRACT_NFT, abiNFT);
    const ownerNFT = _ownerOf(NFTContract, item.tokenId);
    const [_approvalSell] = useApproveNFTCallbackCustom(
      CONTRACT_NFT,
      CONTRACT_BID,
      item.tokenId,
      item.tokenId
    );
    ownerNFT.then((res) => {
      setOwner(res);
    });
    const allowanceNFT = _getApproved(NFTContract, item.tokenId);
    allowanceNFT.then((res) => {
      setAllowance(res);
    });
    const ownerNFTTemp = Owner && Owner.toLowerCase();
    const accountTemp = account && account.toLowerCase();
    const allowanceNFTTemp =
      (allowance && allowance.toString().toLowerCase()) || "";
    const [approvalStake] = useApproveNFTCallbackCustom(
      CONTRACT_NFT,
      STAKE_NFT,
      item.tokenId,
      item.tokenId
    );

    const getRward = () => {
      _getReward(
        stakeNFTContract,
        NFT_CONTRACTID,
        item.tokenId,
        item.price
      ).then((res) => {
        setReward(res && parseInt(res._hex) / 1e18);
      });
    };

    useEffect(() => {
      const interval = setInterval(async () => {
        getRward();
      }, 3000);
      return () => clearInterval(interval);
    }, []);
    useEffect(() => {
      getRward();
    }, [reward, item.tokenId]);
    useEffect(() => {
      _checkStakes(stakeNFTContract, CONTRACT_NFT, item.tokenId).then((res) => {
        setIsStatusStake(res.stakingStaus);
      });
    }, []);

    const handleUnstake = async () => {
      try {
        const balancePool = await getBalanceBSCS(contractPoolStake);
        if (parseFloat(balancePool) > reward) {
          actions.gen_sig(account, item.tokenId).then((resSig: any) => {
            if (resSig.data.succeeded) {
              setStatusUnStake(true);
              _unStake(
                stakeNFTContract,
                item.tokenId,
                CONTRACT_NFT,
                item.price,
                resSig.data.data
              )
                .then((response) => {
                  response.wait().then((res1: any) => {
                    if (res1 !== null) {
                      actions
                        .nftUnStake({
                          ownerAddress: account,
                          nftCode: item.code,
                        })
                        .then(() => {
                          actions.getMoreStakeNFT(account);
                          setStatusUnStake(false);
                          setIsStatusStake(false);
                          setLoadApprove(true);
                        });
                      setStatusUnStake(false);
                    } else {
                      setStatusUnStake(false);
                    }
                  });
                })
                .catch((error: any) => {
                  setStatusUnStake(false);
                  message.error({
                    type: "error",
                    content: "Tx Signature: User denied transaction signature.",
                    className: "custom-class",
                    duration: 3,
                  });
                });
            } else {
              setStatusUnStake(false);
            }
          });
          setStatusUnStake(false);
        } else {
          message.error({
            type: "error",
            content: "The pool is temporarily out of tokens.",
            className: "custom-class",
            duration: 5,
          });
        }
      } catch (error: any) {
        setStatusUnStake(false);
      }
    };
    const handleHarvest = async () => {
      try {
        const balancePool = await getBalanceBSCS(contractPoolStake);
        if (parseFloat(balancePool) > reward) {
          actions.gen_sig(account, item.tokenId).then((resSig: any) => {
            if (resSig.data.succeeded) {
              setLoading(true);
              _havest(
                stakeNFTContract,
                item.tokenId,
                CONTRACT_NFT,
                item.price,
                resSig.data.data
              )
                .then((response) => {
                  setLoading(true);

                  response.wait().then((res1: any) => {
                    if (res1 !== null) {
                      setLoading(false);
                    } else {
                      setLoading(false);
                    }
                  });
                })
                .catch((error: any) => {
                  setLoading(false);
                });
            } else {
              setLoading(false);
            }
          });
        } else {
          message.error({
            type: "error",
            content: "The pool is temporarily out of tokens.",
            className: "custom-class",
            duration: 5,
          });
        }
      } catch (error: any) {
        setLoading(false);
      }
    };
    const handleStake = async () => {
      try {
        actions.gen_sig(account, item.tokenId).then((resSig: any) => {
          if (resSig.data.succeeded) {
            setStatusStake(true);
            _stake(
              stakeNFTContract,
              item.tokenId,
              CONTRACT_NFT,
              item.price,
              resSig.data.data
            )
              .then((response) => {
                response.wait().then((res1: any) => {
                  if (res1 !== null) {
                    actions
                      .nftStake({
                        txnHash: response.hash,
                        ownerAddress: account,
                        nftCode: item.code,
                      })
                      .then(() => {
                        actions.getMoreStakeNFT(account);
                        setStatusStake(false);
                        setIsStatusStake(true);
                      });
                    setStatusStake(false);
                  } else {
                    setStatusStake(false);
                  }
                });
              })
              .catch((error) => {
                setStatusStake(false);
              });
          } else {
            setStatusStake(false);
          }
        });
      } catch (error: any) {
        setStatusStake(false);
        message.error({
          type: "error",
          content: error.message,
          className: "custom-class",
          duration: 3,
        });
      }
      setStatusStake(false);
    };
    async function onAttemptToApproveStake() {
      return approvalStake();
    }
    const handleApproveStake = useCallback(async () => {
      try {
        setApprove(true);
        await onAttemptToApproveStake().then((response: any) => {
          response.wait().then((res1: any) => {
            if (res1 !== null) {
              setApprove(false);
              setLoadApprove(false);
            } else {
              setApprove(false);
            }
          });
        });
      } catch (e: any) {
        setApprove(false);
        setLoadApprove(false);
        message.error({
          type: "error",
          content: e.message,
          className: "custom-class",
          duration: 1,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalStake]);

    return (
      <>
        <div className="colum w-4">
          <div className="guide-market">
            <div className="box-img">
              <div className="box-img box-img-genesis">
                {item.fileType === 2 ? (
                  <>
                    <div className="imgType">
                      <img src="/images/imgnft/typeVideo.png" alt="" />
                    </div>
                    <img
                      className="full-dis img-genesis"
                      src={
                        item.fileType === 2
                          ? item.thumbnail
                          : `${item.fileName &&
                            item.fileName.indexOf("amazonaws") !== -1
                            ? ""
                            : API_IMAGE
                          }${item.fileName}?v=122`
                      }
                      alt={item.name}
                    />
                  </>
                ) : (
                  <>
                    <div className="imgType">
                      <img src="/images/imgnft/typeImg.png" alt="" />
                    </div>
                    <img
                      className="imgNft full-dis"
                      src={`${item?.fileName &&
                        item?.fileName.indexOf("amazonaws") !== -1
                        ? ""
                        : API_IMAGE
                        }${item?.fileName}?v=122`}
                      alt={item.name}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="name-item">{item.name}</div>
            <div className="artis-item">{item.authorName}</div>
            <div className="price-item d-flex">
              <span className="t-left">Current price:</span>
              <div className="t-right">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number d-flex">
                  <Value decimals={0} value={item.price} /> BSCS
                </div>
              </div>
            </div>
            <div className="price-item pd-top-10">
              <span className="t-left main-color">
                <Value decimals={3} value={reward || "0.00"} />
              </span>
              <div className="t-right">
                <Button
                  click={handleHarvest}
                  type="button"
                  disabled={isLoading || reward <= 0 || statusUnStake}
                  loading={isLoading}
                  text="Harvest"
                  ghost
                />
              </div>
            </div>
            <div className="guide-action">
              {ownerNFTTemp === undefined ||
                allowanceNFTTemp === null ||
                STAKE_NFT === undefined ? (
                <>
                  <Button type="button" disabled text="Loading..." primary />
                </>
              ) : (
                <>
                  {!loadAprrove &&
                    ((ownerNFTTemp && ownerNFTTemp === STAKE_NFT.toLowerCase()) ||
                      (allowanceNFTTemp &&
                        allowanceNFTTemp === STAKE_NFT.toLowerCase()) ||
                      isStatusStake) ? (
                    <>
                      {(allowanceNFTTemp &&
                        allowanceNFTTemp !== STAKE_NFT.toLowerCase()) ||
                        isStatusStake ? (
                        <Button
                          className="full-with-btn"
                          ghost
                          disabled={statusUnStake || isLoading}
                          text="Unstake"
                          loading={statusUnStake}
                          click={handleUnstake}
                        />
                      ) : (
                        <Button
                          className="full-with-btn"
                          primary
                          disabled={statusStake}
                          loading={statusStake}
                          text="Stake"
                          click={handleStake}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        className="full-with-btn"
                        text="Approve Stake"
                        primary
                        disabled={isApprove}
                        loading={isApprove}
                        click={() => {
                          handleApproveStake();
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      fileAvatar: "0",
      name: objData?.artistName || "",
      social: objData?.publicProfileLink || "",
      bio: objData?.artistDescription || "",
      fileBanner: "",
      medium: objSocial?.medium || "",
      telegram: objSocial?.telegram || "",
      twitter: objSocial?.twitter || "",
      website: objSocial?.website || "",
    },
    onSubmit: () => {
      actions
        .submitEditArtists(formikEdit.values, account)
        .then(() => {
          formikEdit.resetForm();
          message.success({
            type: "success",
            content: "Update success",
            className: "custom-class",
            duration: 2,
          });
          actions.getArtistsByWallet(account).then((res: any) => {
            if (res.data) {
              setAvatar("");
              setArtistName("");
              setartistDescription("");
            } else {
              setAvatar(res.avatarName);
              setArtistName(res.artistName);
              setartistDescription(res.artistDescription);
            }
          });
          setIsModalEditOpen(false);
        })
        .catch((error: any) => {
          message.error({
            type: "error",
            content: error?.response.data.Message,
            className: "custom-class",
            duration: 2,
          });
        });
    },
  } as any);

  const upLoadFileAvatarEdit = (event: any) => {
    formikEdit.setFieldValue("fileAvatar", event.target.files[0]);
  };
  const handleSave = async () => {
    setIsModalEditOpen(false);
  };
  const handleEditCancel = () => {
    setIsModalEditOpen(false);
  };
  const editProfile = async () => {
    setIsModalEditOpen(true);
  };
  const contentEditModal = (
    <>
      <form
        id="frm-create-artist"
        className="uploadInfo"
        onSubmit={formikEdit.handleSubmit}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Enter your details.</div>
          </div>
          <div className="round-r">
            <div className="form-group">
              <input
                placeholder="Enter the artist name"
                type="text"
                className="form-control f-control"
                name="name"
                value={formikEdit.values.name}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Add a short bio.</div>
          </div>
          <div className="round-r">
            <div className="form-group">
              <textarea
                className="form-control f-control cus"
                name="bio"
                placeholder="Please write something about yourself"
                value={formikEdit.values.bio}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Upload a profile image.</div>
            <div className="desc-round">
              Recommended size: <br />
              1180x230px.
              <br />
              JPG, PNG, or GIF.
              <br />
              10MB max size.
            </div>
          </div>
          <div className="round-r">
            <div className="formfile">
              <div className="form-group avatar-upload">
                <UploadFile
                  src={`${API_IMAGE}${objData.avatarName}?v=122`}
                  name="fileName"
                  id="imgFile"
                  accept="image/*"
                  onChange={upLoadFileAvatarEdit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">
              Add links to your social <br /> media profiles.
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/link.svg" alt="" /> Website
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Website URL"
                name="website"
                value={formikEdit.values.website}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Telegram.svg" alt="" /> Telegram
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Telegram URL"
                name="telegram"
                value={formikEdit.values.telegram}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Twitter.svg" alt="" /> X.com
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="X.com URL"
                name="twitter"
                value={formikEdit.values.twitter}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Medium.svg" alt="" /> Medium
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Medium URL"
                name="medium"
                value={formikEdit.values.medium}
                onChange={formikEdit.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="btn-inf" id="btn-submit-artist">
          <>
            <Button
              type="submit"
              text="Submit"
              click={() => handleSave()}
              primary
            // loading={isLoading}
            />
          </>
        </div>
      </form>
    </>
  );
  const [searchQuery, setSearchQuery] = useState("");
  const handleChangeSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const [searchPrice, setSearchPrice] = useState(0);
  const handleChangeSort = (value: any) => {
    setSearchPrice(value);
  };
  const chainId = useChainId()
  const iconEdit = <img src="./images/imgmarket/edit.svg" alt="" />;
  return (
    <>
      {chainHeader(chainId) === "bep" ? (
        <>
          <div className="main-profile-user">
            <div className="baanner-user">
              <img src="./images/imgmarket/banner-user.png" alt="" />
            </div>
            <div className="container">
              <div className="content-profile-user res tabs-artwork">
                <div className="columns">
                  <div className="colum w-25">
                    <div className="swap-left">
                      {account ? (
                        <>
                          <div className="box-img">
                            {avatar !== "" ? (
                              <>
                                <img
                                  src={`${API_IMAGE}${avatar}?v=122`}
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <img
                                  src="./images/imgmarket/profile-1.png"
                                  alt=""
                                />
                              </>
                            )}
                          </div>
                          <div className="name-rl">
                            Name:{" "}
                            <span className="rl">{artistName || "N/A"}</span>
                          </div>
                          <div className="social-rl">
                            {objSocial.website && (
                              <a
                                href={`${objSocial.website}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/link.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.telegram && (
                              <a
                                href={`${objSocial.telegram}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/tele-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.twitter && (
                              <a
                                href={`${objSocial.twitter}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/twi-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.medium && (
                              <a
                                href={`${objSocial.medium}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/me-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}

                            {objSocial.telegram && (
                              <a
                                href={`${objSocial.telegram}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/tele-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.twitter && (
                              <a
                                href={`${objSocial.twitter}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/twi-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                            {objSocial.medium && (
                              <a
                                href={`${objSocial.medium}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="../images/imgmarket/me-1.svg"
                                  alt=""
                                  className="btn-copied"
                                />
                              </a>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="box-img">
                            <img
                              src="./images/imgmarket/profile-1.png"
                              alt=""
                            />
                          </div>
                          <div className="name-rl">
                            Name: <span className="rl">N/A</span>
                          </div>
                        </>
                      )}

                      <div className="address-rl">
                        {account && (
                          <>
                            Wallet address :{" "}
                            <div className="address">
                              {account &&
                                `${account.substring(
                                  0,
                                  4
                                )}...${account.substring(38, account.length)}`}
                              <span className="img-copy">
                                <CopyToClipboard
                                  text={`${account}`}
                                  onCopy={() =>
                                    message.success({
                                      type: "success",
                                      content: "Copied success",
                                      className: "custom-class",
                                      duration: 1,
                                    })
                                  }
                                >
                                  <img
                                    className="coppy btn-copied"
                                    src="/images/imgido/copy.png"
                                    alt=""
                                  />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="desc-rl">{artistDescription || ""}</div>
                      <div className="edit-profile pd-top-10">
                        {artistName === "" ? (
                          <>
                            <button
                              className="btn-edit"
                              type="button"
                              onClick={() => gotoCreateProfile()}
                            >
                              <img src="./images/imgmarket/edit.svg" alt="" />
                              Create profile
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn-edit"
                              type="button"
                              onClick={() => gotoMintNFT()}
                            >
                              <img src="./images/imgmarket/edit.svg" alt="" />{" "}
                              Mint NFT
                            </button>
                            <button
                              className="btn-edit mg-top-10"
                              type="button"
                              onClick={() => editProfile()}
                            >
                              <img src="./images/imgmarket/edit.svg" alt="" />{" "}
                              Edit profile
                            </button>
                          </>
                        )}
                      </div>

                      <div className="nft-boots mar-t-10">
                        <div className="title-boots">NFT Boost</div>
                        <div className="item-boots">
                          Staking Boost:{" "}
                          <span className="number">
                            {state.stakingBoost === 0
                              ? "0%"
                              : state.stakingBoost}
                          </span>
                        </div>
                        <div className="item-boots">
                          Addtional Pool Weight:{" "}
                          <span className="number">
                            {state.additionalPoolWeight === 0
                              ? "0%"
                              : state.additionalPoolWeight}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="colum w-75">
                    <div className="swap-right">
                      <div className="tabs-market display-seach">
                        <Tabs defaultActiveKey="1">
                          <Tabs.TabPane tab="All" key="1">
                            <div className="swap-filter">
                              <div className="search-project">
                                <Search
                                  placeholder="Search for project"
                                  type="text"
                                  onChange={handleChangeSearchQuery}
                                />
                                <div className="icon-search">
                                  <img
                                    src="/images/imgido/search-normal.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="filter-project">
                                <Select
                                  defaultValue="Sort by"
                                  style={{ width: 125 }}
                                  onChange={handleChangeSort}
                                >
                                  <Option value={0}>All</Option>
                                  <Option value={1500}>1500 BSCS</Option>
                                  <Option value={3000}>3000 BSCS</Option>
                                  <Option value={15000}>15,000 BSCS</Option>
                                  <Option value={75000}>75,000 BSCS</Option>
                                </Select>
                              </div>
                            </div>
                            <div className="round-tabs pd-top-20">
                              <div className="columns pd-top-20">
                                {state.nftListStake.length === 0 ? (
                                  <div className="nft-empty pd-top-20 align-center">
                                    {noData}
                                  </div>
                                ) : (
                                  <>
                                    {searchPrice === 0 ? (
                                      <>
                                        {state.nftListStake
                                          .filter((list: any) =>
                                            list.name
                                              .toLowerCase()
                                              .includes(
                                                searchQuery.toLowerCase()
                                              )
                                          )
                                          .map((item: any, i: any) => (
                                            <Item data={item} />
                                          ))}
                                      </>
                                    ) : (
                                      <>
                                        {state.nftListStake
                                          .filter(
                                            (list: any) =>
                                              list.name
                                                .toLowerCase()
                                                .includes(
                                                  searchQuery.toLowerCase()
                                                ) && list.price === searchPrice
                                          )
                                          .map((item: any, i: any) => (
                                            <Item data={item} />
                                          ))}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="load-more-round">
                                {isLoadMore ? (
                                  <Spin indicator={antIconLoad} />
                                ) : state.hideLoadmore ? (
                                  ""
                                ) : state.nftListStake?.length === 0 ? (
                                  ""
                                ) : (
                                  <button
                                    className="btn-loadmore cus"
                                    type="button"
                                    onClick={() => handleLoadMore()}
                                  >
                                    Load More
                                  </button>
                                )}
                              </div>
                            </div>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="Staking" key="2">
                            <div className="swap-filter">
                              <div className="search-project">
                                <Search
                                  placeholder="Search for project"
                                  type="text"
                                  onChange={handleChangeSearchQuery}
                                />
                                <div className="icon-search">
                                  <img
                                    src="/images/imgido/search-normal.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="filter-project">
                                <Select
                                  defaultValue="Sort by"
                                  style={{ width: 125 }}
                                  onChange={handleChangeSort}
                                >
                                  <Option value={0}>All</Option>
                                  <Option value={1500}>1500 BSCS</Option>
                                  <Option value={3000}>3000 BSCS</Option>
                                  <Option value={15000}>15,000 BSCS</Option>
                                  <Option value={75000}>75,000 BSCS</Option>
                                </Select>
                              </div>
                            </div>
                            <div className="round-tabs pd-top-20">
                              <div className="columns pd-top-20">
                                {state.nftListStake.filter(
                                  (x: any) => x.stakeStatus === 1
                                ).length === 0 ||
                                  state.nftListStake.filter(
                                    (x: any) => x.stakeStatus === 1
                                  ) === false ? (
                                  <div className="nft-empty pd-top-20 align-center">
                                    {noData}
                                  </div>
                                ) : (
                                  <>
                                    {searchPrice === 0 ? (
                                      <>
                                        {state.nftListStake
                                          .filter(
                                            (list: any) =>
                                              list.stakeStatus === 1 &&
                                              list.name
                                                .toLowerCase()
                                                .includes(
                                                  searchQuery.toLowerCase()
                                                )
                                          )
                                          .map((item: any, i: any) => (
                                            <Item data={item} />
                                          ))}
                                      </>
                                    ) : (
                                      <>
                                        {state.nftListStake
                                          .filter(
                                            (list: any) =>
                                              list.stakeStatus === 1 &&
                                              list.name
                                                .toLowerCase()
                                                .includes(
                                                  searchQuery.toLowerCase()
                                                ) &&
                                              list.price === searchPrice
                                          )

                                          .map((item: any, i: any) => (
                                            <Item data={item} />
                                          ))}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                              {state.nftListStake.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  {" "}
                                  <div className="load-more-round">
                                    {isLoadMore ? (
                                      <Spin indicator={antIconLoad} />
                                    ) : state.hideLoadmore ? (
                                      ""
                                    ) : state.nftListStake?.length === 0 ? (
                                      ""
                                    ) : (
                                      <button
                                        className="btn-loadmore cus"
                                        type="button"
                                        onClick={() => handleLoadMore()}
                                      >
                                        Load More
                                      </button>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorPage />
        </>
      )}
      <div className="modal-staking">
        {/* @ts-ignore this lib is incompatible with react18  */}
        <Modal
          title="Edit profile"
          className="modal-eidt-profile"
          visible={isModalEditOpen}
          onOk={handleSave}
          onCancel={handleEditCancel}
        >
          <div className="modal-edit-profile">{contentEditModal}</div>
        </Modal>
      </div>
    </>
  );
};
export default StakeNft;
