/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";

const StoreStaked = createStore({
    initialState: {

    },
    actions: {
        updateStaked:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}/pool/staked`, { ...params }).then((res) => {

                            resolve(res)
                        });
                    });
                },
        callNoti:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`https://noti-tnx-bot-dev.metatech.foundation/stake/message`, { ...params }).then((res) => {

                            resolve(res)
                        });
                    });
                },
    },
    name: "StoreStaked",
});

export const useHookStaked = createHook(StoreStaked);
export const Container = createContainer(StoreStaked, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreStaked);
