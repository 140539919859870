const Icon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="#687688"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8415 5.07496V5.69163L11.8915 3.98329C10.7749 3.34163 9.21654 3.34163 8.1082 3.98329L5.1582 5.69996V5.07496C5.1582 3.19996 6.1832 2.16663 8.0582 2.16663H11.9415C13.8165 2.16663 14.8415 3.19996 14.8415 5.07496Z" />
    <path d="M14.866 7.14162L14.7494 7.08328L13.616 6.43328L11.266 5.07495C10.5493 4.65828 9.44935 4.65828 8.73268 5.07495L6.38268 6.42495L5.24935 7.09162L5.09935 7.16662C3.64102 8.14995 3.54102 8.33328 3.54102 9.90828V13.675C3.54102 15.25 3.64102 15.4333 5.13268 16.4416L8.73268 18.5166C9.09102 18.7333 9.54102 18.825 9.99935 18.825C10.4493 18.825 10.9077 18.725 11.266 18.5166L14.8993 16.4166C16.366 15.4333 16.4577 15.2583 16.4577 13.675V9.90828C16.4577 8.33328 16.3577 8.14995 14.866 7.14162ZM12.3243 11.75L11.816 12.375C11.7327 12.4666 11.6743 12.6416 11.6827 12.7666L11.7327 13.5666C11.766 14.0583 11.416 14.3083 10.9577 14.1333L10.216 13.8333C10.0993 13.7916 9.90768 13.7916 9.79102 13.8333L9.04935 14.125C8.59102 14.3083 8.24102 14.05 8.27435 13.5583L8.32435 12.7583C8.33268 12.6333 8.27435 12.4583 8.19102 12.3666L7.67435 11.75C7.35768 11.375 7.49935 10.9583 7.97435 10.8333L8.74935 10.6333C8.87435 10.6 9.01602 10.4833 9.08268 10.3833L9.51602 9.71662C9.78268 9.29995 10.2077 9.29995 10.4827 9.71662L10.916 10.3833C10.9827 10.4916 11.1327 10.6 11.2493 10.6333L12.0243 10.8333C12.4993 10.9583 12.641 11.375 12.3243 11.75Z" />
  </svg>
);
export default Icon;
