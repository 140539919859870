import {
  BinanceWallet,
  Metamask,
  SafePalWallet,
  TrustWallet,
  WalletConnect,
  TokenPocket,
  MathWallet,
  PhantomWallet,
  PontemWallet,
  OkxWallet,
} from "../components/icons";

export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = "https://api.bscs.finance/api";

export const API_NFTs_MARKET_URL = process.env.REACT_APP_API_URL;
// export const API_NFTs_MARKET_URL = 'https://api.bscs.finance/api';

export const BSCSCAN_API_MAINNET = "https://api.bscscan.com";
export const BSCSCAN_APIKEY = "T2IXE481IAX4947JTMHSB7482992S7VE1W";
export const NetworkContextName = "NETWORK";

export const MAPPING_CHAINID: any = {
  bep: "0x38",
  erc: "0x1",
  poly: "0x89",
  fmt: "0xfa",
  okc: "0x42",
  avax: "0xa86a",
  op: "0xa",
  doge: "0x7d0",
  arb: "0xa4b1",
  opbnb: "0xcc",
  base: "0x2105",
  zkevm: '0x343b',
  blast: '0x13e31'
};

export const CHAINID_CONVERT: any = {
  56: "bep",
  1: "erc",
  137: "poly",
  66: "okc",
  42161: "arb",
  204: "opbnb",
  8453: "base",
  13371: 'zkevm',
  43114: 'avax'
};

export const KEY_CRYPTOJS = "4kHlZXyYTew38QnxvWFnag==";

// wallet list
export const WALLET_LIST = [
  { icon: Metamask, title: "Meta Mask", connectorId: "injected" },
  {
    icon: BinanceWallet,
    title: "Binance Wallet",
    connectorId: "bsc",
  },
  {
    icon: WalletConnect,
    title: "Wallet Connect",
    connectorId: "walletconnect",
  },
  // { icon: OkxWallet, title: "OKX Wallet", connectorId: "walletconnect" },
  // { icon: TrustWallet, title: "Trust Wallet", connectorId: "walletconnect" },
  { icon: TokenPocket, title: "TokenPocket", connectorId: "injected" },
  { icon: PontemWallet, title: "Pontem Wallet", connectorId: "-1" },
  // { icon: PhantomWallet, title: "Phantom Wallet", connectorId: "-2" },
];
export const ADDRESS_BSCS_ADDRESS =
  "0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d";
export const ADDRESS_SWAP_ADDRESS =
  "0xcAbe333d7C211F70b57fcde51628873df14EA1d1";
export const ADDRESS_RECEVIE_BUSD =
  "0x9a5D956C9FBc8Bd51a08DFC31441A2cCff2584aE";
export const NEED_A_PLACEHOLDER = WALLET_LIST.length % 2 !== 0;

export const SWAP_BSCS_URL =
  "https://bscs.finance/#/swap?outputCurrency=0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d";
export const NFT_URL = "/nft-megamarket";
export const FARMS_URL = "/farms";
export const AIR_DROP = "/Airdrop";
export const STARTPOOL_URL = "/Pools";
export const LAUNCHPAD_URL = "/Ido";
export const DOCS_URL = "https://docs.bscstation.finance/";
export const GITHUB_URL = "https://github.com/BSCSGlobal";
export const CERTIK_AUDIT_URL = "https://skynet.certik.com/projects/bscs";
export const Quillhash_AUDIT_URL =
  "https://github.com/Quillhash/QuillAudit_Reports/blob/master/BSCS%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf";
export const PECKSHIELD_AUDIT_URL =
  "https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BSCStationStartPools-v1.0.pdf";
export const SolidProof_URL =
  "https://github.com/solidproof/projects/tree/main/BSCStation";
export const INO_URL = "/InoLaunchPad";
export const TWITTER_URL = "https://twitter.com/BSCS_Global";
export const TELEGRAM_URL = "https://t.me/BSCS_Channel";
export const FACE_URL = "https://www.facebook.com/BSCS.Channel";
export const YOUTUBE_URL = "https://www.youtube.com/@BSCSOfficial";
export const REDIT_URL = "https://www.reddit.com/r/BSCStation/";
export const SUBSTACK = "https://bscstation.substack.com/";
export const BUILDERS = "https://t.me/bscstation_media/1937";
export const SUPPORT = "https://support.bscs.finance/portal/en/home";
export const APPLY = "https://bscs.finance/apply-launch";
export const WEB3 = "https://bscs.finance/web3-ignite";

export const ICON_BNB_URL =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/e3828a3eb62a65a551423b45d04a4c816e92d347be968298bb.png";
export const ICON_OPBNB_URL =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/e3828a3eb62a65a551423b45d04a4c816e92d347be968298bb.png";
export const ICON_ETH_URL =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/816fa85de2c761a9dac2e5770f0b08738177d6995efda00fee.png";
export const ICON_POLY_URL =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/9970ad6f8e2d9b36ab187859b143093a544eaf1f69c3202e0a.png";

export const LOGO_ICON =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/ae1e8eff3079d9e6fda98d093af950a6cc73745e669c4a85f3.png";

export const ICON_OKEX =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/9be394a592a19934e5066d58ceb0a2d950668139b3199c9adf.png";
export const ICON_AVAX =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/adb2afbda8bb2acb7d15e8d209981919841009d3f12187f2fc.png";
export const ICON_OP =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/13dfe69f3ccf21e7579385e316f2f07e9d51c2cf7be5e321ed.png";
export const ICON_DOGE =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/3fa42fb64f69685000dbd30173274b2ee73d815bf67d131e34.png";
export const ICON_FMT =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/ff78a6f70a8d833aa32bbdbd7ae7087e68014e27d682387ef8.png";
export const ICON_ARB =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/6fc117523771441b9c43a0e1e00d04e5279127ebcc3c7870a9.png";
export const ICON_BASE =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/438026a4dc0063a49b85dba902070e4343ec0a1eb27adad29e.png";
export const ICON_ZK =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/fc32b1c4f9d077d349a3f532c1143d34c75ff3c7810625cbb2.jpeg";
export const ICON_BLAST =
  "https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/00b71eeb7fe55ab613c2da6361ca080cfdb110349e8b35ebef.jpeg";
export const bscTokens = [
  // stake pool
  {
    id: "wbnb",
    symbol: "wbnb",
    contract: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  },
  {
    id: "binance-usd",
    symbol: "busd",
    contract: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    id: "tether",
    symbol: "USDT",
    contract: "0x55d398326f99059fF775485246999027B3197955",
  },
  {
    id: "ethereum",
    symbol: "ETH",
    contract: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
  },
  {
    id: "dai",
    symbol: "DAI",
    contract: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
  },
  {
    id: "polis",
    symbol: "POLIS",
    contract: "0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd",
  },
  {
    id: "tether",
    symbol: "USDT",
    contract: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
  },
  {
    id: "usd-coin",
    symbol: "USDC",
    contract: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  },
  {
    id: "bscs",
    symbol: "BSCS",
    contract: "0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d",
  },
  {
    id: "kingdom-game-4-0",
    symbol: "KDG",
    contract: "0x87a2d9a9a6b2d61b2a57798f1b4b2ddd19458fb6",
  },
];
export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "56"
);

export const STATKE_NFTS: any = {
  // 56: '0x2bf314b388935fB9b4a0F568939a663f22e99b33',
  56: "0x1e3fA6a96C20259E030a105308a9a09d1B6d150F",
  97: "0xD6B35471f4a35F4E93c42E7D145C6f865c84CC52",
};

const NFTs: any = {
  56: "0x2433bE070fAeE3F960C435fb91e9f320986DdE34",
  97: "0x341EAd06CbcfA919Ef2bD902DF6f536d730E0456",
};

const Bids: any = {
  56: "0x1B02D6E3BD9B2C812ACeE4068195fE29680f0c0C",
  97: "0x1e288b14a3e78433609aFF438Bae2cB15c65b761",
};
const BSCSs: any = {
  56: "0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d",
  97: "0xE1068958C357e84f2C065D8C9b9f15C70028B546",
};
export const STAKE_NFT = STATKE_NFTS[NETWORK_CHAIN_ID];
export const MINT_PRICE = "1000000000000000"; // 0,01BNB
export const CONTRACT_NFT = NFTs[NETWORK_CHAIN_ID];
// export const API_IMAGE = "https://nft.bscstation.finance/img/nft/";
export const API_IMAGE = "https://nft.bscs.finance/img/nft/";

export const NFT_CONTRACTID = "0x2433bE070fAeE3F960C435fb91e9f320986DdE34";
export const TOKEN_BSCS_TESTNET = BSCSs[NETWORK_CHAIN_ID];
export const TOKEN_BSCS = BSCSs[NETWORK_CHAIN_ID];
export const CONTRACT_BID_VERSION = {
  0: "0xF6155243b3649b0957A9c11620D8EA62E11a59C4", // contract bid old
  1: "0x1B02D6E3BD9B2C812ACeE4068195fE29680f0c0C", // contract bid new
};
export const API_VIDEO = "https://nft.bscstation.finance/video/";
export const CONTRACT_BID = Bids[NETWORK_CHAIN_ID];

export const CHAIN_NETWORK_APTOS = "https://fullnode.mainnet.aptoslabs.com/v1";

export const KEY_APT = "0x1";
export const TYPE_APT_TOKEN =
  "0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>";

// export const SC_DEPOSIT_APT =
//   "0xba25c9ab8aae86729cdb3aa1bacb588d41183dda0ac747ed369b4cad64a008fe";

// export const TOKEN_DEPOSIT_APT = "0x1::aptos_coin::AptosCoin";
// export const SC_DEPOSIT_APT =
//   "0x264a6a0906c110b0e3ac8ca08d93782cea457982ee2a93751f05f53ec1639c03";


export const SC_STAKE_BSCS = "0x0eC48e293f34680EF14055AD14d84b2E83A9d6B1"
// export const MINT_NFT_CONTRACT = "0xF6D7a3004c77dC96FCf804E4bAb6523d89905500";
// export const UNICORN_NFT = "0x44f105d424C719cBF42028d805396Ce08F27DE98";
// export const BID_NFT = "0x324F969873B3D7d9A20707A1B346dc5F62E32308";

export const MINT_NFT_CONTRACT: any = process.env.REACT_APP_MINT_CONTRACT
export const UNICORN_NFT: any = process.env.REACT_APP_UNICORN_NFT
export const BID_NFT: any = process.env.REACT_APP_BID_NFT
export const BSCS_CONTRACT = "0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D";



export const TOTAL_NFT_MINT = 3000
export const FEE_CREATOR = 3 // percent
export const FEE_SERVICE = 2.5 // percent

export const auction_status = (stt: any) => {
  switch (stt) {
    case '1':
      return "Selling";
    case '2':
      return "Sold";
    case '3':
      return "Finished";
    default:
      return "---";
  }
};
