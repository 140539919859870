import React from "react";
import { useHookProjects } from "./Store";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Value from "../../components/Value";
import { ICON_BNB_URL, ICON_ETH_URL, ICON_POLY_URL } from "../../constants";
import { CHAIN } from "pages/Ido/index.d";

const Upcoming = () => {
  const [state, actions]: any = useHookProjects();
  let navigate = useNavigate();
  const gotoIDO = () => {
    navigate("/Ido");
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };
  const gotoIDODetail = (network: any, symbol: any, isOldIDo: any, idoTrust: any) => {
    if (network === "apt") {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
    } else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else if (network === CHAIN.TON) {
      navigate(`${"/Ido/Detail"}/${network}?${symbol}`);
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else if (idoTrust === 'Vanguard' && symbol !== "MEY") {
      navigate(`${"/Ido/details-vanguard"}?${symbol}`);
    } else {
      navigate(`${"/Ido/Details"}?${symbol}`);
    }
  };
  const gotoINODetail = (keywork: any) => {
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };
  const listUpcomingHome = state.upcomingListHome;

  const settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <>
        <div className="style-ar-l" onClick={onClick}>
          <img src="/images/ar-l.png" alt="" />
        </div>
      </>
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <>
        <div className="style-ar-r" onClick={onClick}>
          <img src="/images/ar-r.png" alt="" />
        </div>
      </>
    );
  }
  const Item = (item: any) => {
    const network_url =
      item.network === "bep"
        ? ICON_BNB_URL
        : item.network === "eth"
          ? ICON_ETH_URL
          : ICON_POLY_URL;
    let shortDescCut = item.shortDesc?.slice(0, 84);
    return (
      <div className="colum w-25">
        <div className="content-upcoming">
          <div className="box-img">
            <div className="img-big">
              <img src={item.backgroundUrl} alt="" />
              <span className="icon-img">
                <img src={network_url} alt="" />
              </span>
              <span className="allow-open">Allowlist Open</span>
            </div>
            <div className="img-small">
              <img src={item.logoUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.symbol}</span>
          </div>
          <p className="desc">
            {item.shortDesc?.length > 84 ? `${shortDescCut}...` : shortDescCut}
          </p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">
                <Value unit="$" decimals={0} value={item.totalRaised} />
              </span>
            </li>
            <li>
              <span className="left">Participants:</span>
              <span className="right">{item.participants}</span>
            </li>
          </ul>
          {item.id === 0 ? (
            <>
              <div
                className="title-upcoming-sale"
                onClick={() =>
                  gotoIDODetail(item.network, item.symbol, item.isOldIDo, item?.idoTrust)
                }
              >
                Token Sale
              </div>
            </>
          ) : (
            <>
              <div
                className="title-upcoming-sale"
                onClick={() => gotoINODetail(item.keywork)}
              >
                NFT Sale
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const ItemMobile = (item: any) => {
    let shortDescCut = item.shortDesc?.slice(0, 84);
    return (
      <div className="item-slider">
        <div className="content-upcoming">
          <div className="box-img">
            <div className="img-big">
              <img src={item.backgroundUrl} alt="" />
              <span className="icon-img">
                {item.network === "bep" || "BEP" ? (
                  <img src="/images/bnb-bnb-logo.png" alt="" />
                ) : (
                  ""
                )}
              </span>
              <span className="allow-open">Allowlist Open</span>
            </div>
            <div className="img-small">
              <img src={item.logoUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.symbol}</span>
          </div>
          <p className="desc">
            {item.shortDesc?.length > 84 ? `${shortDescCut}...` : shortDescCut}
          </p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">${item.totalRaised}</span>
            </li>
            <li>
              <span className="left">Participants:</span>
              <span className="right">{item.participants}</span>
            </li>
          </ul>

          {item.id === 0 ? (
            <>
              <div
                className="title-upcoming-sale"
                onClick={() =>
                  gotoIDODetail(item.network, item.symbol, item.isOldIDo, item?.idoTrust)
                }
              >
                Token Sale
              </div>
            </>
          ) : (
            <>
              <div
                className="title-upcoming-sale"
                onClick={() => gotoINODetail(item.keywork)}
              >
                NFT Sale
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );

  if (isMobile) {
    return (
      <div className="main-setion">
        <div className="container">
          <div className="main-title">
            <h3 className="title">Upcoming sales on BSCS</h3>
          </div>
          <div className="slide-row-funded">
            <div className="slider-chain mobile">
              {listUpcomingHome?.length !== 0 ? (
                <Slider {...settings}>
                  {listUpcomingHome.map((item: any, index: any) => (
                    <>{ItemMobile(item)}</>
                  ))}
                </Slider>
              ) : (
                <div className="empty">{noData}</div>
              )}
            </div>
            <div className="btn btn-show-more" onClick={() => gotoIDO()}>
              Explore projects
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="main-setion">
        <div className="container">
          <div className="main-title">
            <h3 className="title">Upcoming sales on BSCS</h3>
          </div>
          <div className="table-upcoming">
            <div className="columns">
              {listUpcomingHome?.length !== 0 ? (
                listUpcomingHome.map((item: any, index: any) => (
                  <>{Item(item)}</>
                ))
              ) : (
                <div className="empty">{noData}</div>
              )}
            </div>
            <div className="btn btn-show-more" onClick={() => gotoIDO()}>
              Explore projects
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Upcoming;
