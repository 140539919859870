/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import { API_URL } from "../../constants/index";

const StoreReport = createStore({
  initialState: {},
  actions: {
    list:
      (params) =>
        ({ getState, setState }) => {
          return new Promise((resolve, reject) => {
            axios
              .get(`${API_URL}/noti/ido`, {
                headers: { "Content-Type": "application/json" },
              })
              .then((res) => {
                resolve(res.data);
              });
          });
        },
    getDetail:
      (params) =>
        ({ getState, setState }) => {
          return new Promise((resolve, reject) => {
            axios
              .get(`${API_URL}/noti/symbol?address=${params?.address}&symbol=${params?.symbol}`, {
                headers: { "Content-Type": "application/json" },
              })
              .then((res) => {
                resolve(res.data);
              });
          });
        },
  },
  name: "StoreReport",
});

export const useHookReport = createHook(StoreReport);
export const Container = createContainer(StoreReport, {
  onInit:
    () =>
      ({ setState }, props) => {
        setState({ ...props });
      },
});
export const Subscriber = createSubscriber(StoreReport);
