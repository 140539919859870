import React, { useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { message } from "antd";
import styled from "styled-components";
import { useNftContract } from "../../../hooks/useContract";
import { useHookUpload } from "./Store-Upload";
import UploadFile from "../Upload/components/UploadFile";
import CheckBox from "../Upload/components/CheckBox";
import Button from "../../../components/Button";
import { useAccount } from "wagmi";
// import { render } from 'react-dom'

export default function Form1() {
  const { address } = useAccount();
  let account = address
  const [state, actions] = useHookUpload();
  const nftContract = useNftContract();
  const [isLoading, setLoading] = React.useState(false);

  const upLoadFileAvatar = (event: any) => {
    formik.setFieldValue("fileAvatar", event.target.files[0]);
  };
  const upLoadFileBanner = (event: any) => {
    if (event.target.files[0] === undefined) {
      formik.setFieldValue("fileBanner", "");
    } else {
      formik.setFieldValue("fileBanner", event.target.files[0]);
    }
  };

  // init formit and validate
  const formik: any = useFormik({
    initialValues: {
      fileAvatar: "",
      name: "",
      social: "",
      bio: "",
      fileBanner: "",
    },
    validationSchema: Yup.object({
      fileAvatar: Yup.string().required("Required!"),
      name: Yup.string().required("Required!"),
      social: Yup.string().required("Required!"),
      bio: Yup.string().required("Required!"),
      // fileBanner: Yup.string().required('Required!')
    } as any),

    onSubmit: () => {
      actions.submit(formik.values, account, nftContract).then(() => {
        formik.resetForm();
        window.location.href = "#/mint-nft";
        setTimeout(() => {
          window.location.reload();
        }, 200);
      });
    },
  } as any);

  const chosse = (event: any) => {
    formik.setFieldValue("accept", event.target.value);
  };

  return (
    <>
      <form
        id="frm-create-artist"
        className="uploadInfo"
        onSubmit={formik.handleSubmit}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Enter your details.</div>
          </div>
          <div className="round-r">
            <label className="labelName" htmlFor="nameInput">
              Artist Name <span className="label-cus">Optional</span>
            </label>
            <div className="form-group">
              <input
                placeholder="Enter the artist name"
                type="text"
                className="form-control f-control"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && formik.touched.name && (
                <p style={{ color: "red" }}>{formik.errors.name}</p>
              )}
            </div>
          </div>
        </div>
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Add a short bio.</div>
          </div>
          <div className="round-r">
            <label className="labelName" htmlFor="nameInput">
              Enter a short bio <span className="label-cus">Optional</span>
            </label>
            <div className="form-group">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

              <textarea
                className="form-control f-control cus"
                name="bio"
                placeholder="Please write something about yourself"
                value={formik.values.bio}
                onChange={formik.handleChange}
              />
              {formik.errors.bio && formik.touched.bio && (
                <p style={{ color: "red" }}>{formik.errors.bio}</p>
              )}
            </div>
          </div>
        </div>
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Upload a profile image.</div>
            <div className="desc-round">
              Recommended size: <br />
              1000x1000px.
              <br />
              JPG, PNG, or GIF.
              <br />
              10MB max size.
            </div>
          </div>
          <div className="round-r">
            {/* <label className="avatar" htmlFor="avatar">
              Your avatar
            </label> */}
            <div className="formfile">
              <div className="form-group avatar-upload">
                <UploadFile
                  name="fileName"
                  id="imgFile"
                  accept="image/*"
                  onChange={upLoadFileAvatar}
                />
                {formik.errors.fileAvatar && formik.touched.fileAvatar && (
                  <p style={{ color: "red" }}>{formik.errors.fileAvatar}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Upload a cover image</div>
            <div className="desc-round">
              Recommended size:
              <br />
              1500x300px.
              <br />
              JPG, PNG, or GIF.
              <br />
              10MB max size.
            </div>
          </div>
          <div className="round-r">
            {/* <label className="avatar" htmlFor="avatar">
              Your avatar
            </label> */}
            <div className="formfile">
              <div className="form-group avatar-upload">
                <UploadFile
                  name="fileName"
                  id="imgFile"
                  accept="image/*"
                  onChange={upLoadFileAvatar}
                />
                {formik.errors.fileBanner && formik.touched.fileBanner && (
                  <p style={{ color: "red" }}>{formik.errors.fileBanner}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">
              Add links to your social <br /> media profiles.
            </div>
            <div className="desc-round cus">
              <img src="../images/imgmarket/link.svg" alt="" /> Website
            </div>
          </div>
          <div className="round-r">
            <div className="form-group cus">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Website URL"
                name="social"
                value={formik.values.social}
                onChange={formik.handleChange}
              />
              {formik.errors.social && formik.touched.social && (
                <p style={{ color: "red" }}>{formik.errors.social}</p>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row col-md-12">
            {/* <div className="form-check accept"> */}
            <CheckBox
              className="form-check-label-accept col-md-12"
              name="accept"
              onChange={chosse}
              htmlFor="accept"
              label="I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the
                artwork can be removed anytime if detected."
            />
            {/* </div> */}
            {formik.errors.accept && formik.touched.accept && (
              <p
                style={{
                  color: "red",
                  marginLeft: "17px",
                }}
              >
                {formik.errors.accept}
              </p>
            )}
          </div>
        </div>
        <div className="btn-inf" id="btn-submit-artist">
          <>
            {account === null ? (
              <Button
                type="button"
                click={() => message.warning("Please Unlock Wallet!")}
                primary
                text="Save change"
              />
            ) : (
              <>
                {state.objData.artistName ? (
                  <Button
                    type="button"
                    primary
                    text="Save change"
                    click={() => message.warning("Please create artist!")}
                  />
                ) : (
                  <Button
                    type="submit"
                    text="Save change"
                    primary
                    loading={isLoading}
                  />
                )}
              </>
            )}
          </>
        </div>
      </form>
    </>
  );
}
