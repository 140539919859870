/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import Button from "../../../../components/Button";

import { useHookUpload } from "../Store-Upload";
import { useNftContract } from "../../../../hooks/useContract";
// import {
//   useTransactionAdder,
//   isTransactionRecent,
//   useAllTransactions,
// } from "../../state/transactions/hooks";
// import { TransactionDetails } from "../../state/transactions/reducer";
import UploadFile from "./UploadFile";
import CheckBox from "./CheckBox";

import "../index.css";
import { useAccount } from "wagmi";

// import _mintToken, { _getMaxTokenId } from '../Nft/utils'

// function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
//   return b.addedTime - a.addedTime;
// }

const ListType = ({ item }: any) => {
  // const [state, actions] = useHookUpload()
  return (
    <option selected value={item.id}>
      {item.artworksName}
    </option>
  );
};

export default function Form1() {
  // use state, action from store
  const refUpload = useRef<any>(null);
  const { address } = useAccount();

  let account = address
  const [state, actions] = useHookUpload();
  const [isAccount, setIsAccount] = useState(false);
  const [isArt, setIsArt] = useState(false);
  let navigate = useNavigate();
  const { objListType } = state;

  const nftContract = useNftContract();

  const [isLoading, setLoading] = React.useState(false);

  const [file, setFile] = React.useState("");
  const [fileThum, setFileThum] = React.useState("");
  const [temp, setTemp] = React.useState("image/*");

  const upLoadFile = (event: any) => {
    const obj = event.target.files[0];
    if (obj !== undefined) {
      formik.setFieldValue("fileName", event.target.files[0]);
      setFile(event.target.files[0]);

      if (
        event.target.files[0].type !== "" &&
        event.target.files[0].type !== undefined
      ) {
        formik.values.type = event.target.files[0].type.slice(0, 5);
      }

      if (formik.values.type === "image") {
        formik.setFieldValue("sizeImage", event.target.files[0].size);
      } else if (formik.values.type === "video") {
        formik.setFieldValue("sizeVideo", event.target.files[0].size);
      }
    } else {
      setFile("");
    }
  };

  const upLoadFileImgVideo = (event: any) => {
    // setFileThum('')
    const obj = event.target.files[0];
    if (obj !== undefined) {
      formik.setFieldValue("fileNameImgVideo", event.target.files[0]);

      formik.setFieldValue("sizeImageVideo", event.target.files[0].size);
      setFileThum(event.target.files[0]);
    } else {
      setFileThum("");
      formik.setFieldValue("fileNameImgVideo", "");
    }
  };

  const chosse = (event: any) => {
    formik.setFieldValue("accept", event.target.value);
  };

  const changeType = (event: any) => {
    refUpload.current?.reset();
    setFile("");
    const temp1 = event.target.value;
    if (temp1 === "0" || temp1 === "2") {
      setTemp("image/*");
    } else {
      setTemp("video/*");
    }
    formik.setFieldValue("category", temp1);
    formik.setFieldValue("sizeImage", 0);
    formik.setFieldValue("sizeImageVideo", 0);
    formik.setFieldValue("sizeVideo", 0);
    // formik.setFieldValue('fileName', '')
    // @ts-ignore: Object is possibly 'null'.
    document.getElementById("imgFile").value = "";
  };
  // const addTransaction = useTransactionAdder();
  // init formit and validate
  const formik: any = useFormik({
    initialValues: {
      name: "",
      category: "0",
      channel: "0",
      checkbox: "0",
      description: "",
      fileName: "",
      sizeImage: 0,
      sizeVideo: 0,
      type: "x",
      accept: "",
      sizeImageVideo: 0,
      fileNameImgVideo: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Mininum 2 characters")
        .max(250, "Maximum 250 characters")
        .required("Required!"),
      description: Yup.string().required("Required!"),
      fileName: Yup.string().required("Required!"),
      sizeImage: Yup.number().max(10485760),
      sizeVideo: Yup.number().max(31457280),
      accept: Yup.string().required("Required!"),
      // sizeImageVideo: Yup.number().max(10485760),

      // fileNameImgVideo: Yup.string().required('Required!') ,
    } as any),

    onSubmit: () => {
      // console.log("xxx:", formik.values)
      if (formik.values.type === "video") {
        if (formik.values.fileNameImgVideo === "") {
          // alert("Thumbnail not be empty!!");
          message.error({
            type: "error",
            content: "Thumbnail not be empty!!",
            className: "custom-class",
            duration: 0.5,
          });
          return;
        }
        if (formik.values.sizeImageVideo > 10485760) {
          // alert("Thumbnail not exceed 10M");
          message.error({
            type: "error",
            content: "Thumbnail not exceed 10M",
            className: "custom-class",
            duration: 0.5,
          });
          return;
        }
      }

      setLoading(true);
      actions
        .submit(formik.values, account, nftContract)
        .then((response: any) => {
          if (response) {
            message.success({
              type: "success",
              content: "mint NFT successfully!",
              className: "custom-class",
              duration: 0.5,
            });

            formik.resetForm();
            setLoading(false);
            setTimeout(() => {
              navigate(`${"/MyArtwork"}`);
            }, 500);
          }

          // window.location.href = "/MyArtwork";
        })
        .catch((err: any) => {
          setLoading(false);
          message.warning({
            type: "warning",
            content: err.message,
            className: "custom-class",
            duration: 0.5,
          });
        });
    },
  } as any);

  useEffect(() => {
    if (account) {
      setIsAccount(true);
      if (state.isArt) {
        setIsArt(true);
      }
    }
  }, [account, state.isArt]);

  useEffect(() => {
    actions.getTypeArtworkList();
    actions.getArtistsByWallet(account);
  }, [account]);

  const handleCancel = () => {
    formik.resetForm();
    refUpload.current?.reset();
    setFile("");
    const temp1 = "0";
    setTemp("image/*");
    formik.setFieldValue("category", temp1);
    formik.setFieldValue("sizeImage", 0);
    formik.setFieldValue("sizeImageVideo", 0);
    formik.setFieldValue("sizeVideo", 0);
    // formik.setFieldValue('fileName', '')
    // @ts-ignore: Object is possibly 'null'.
    document.getElementById("imgFile").value = "";
  };

  return (
    <>
      <form
        className="upload bsc-upload"
        id="frm-upload-artwork"
        onSubmit={formik.handleSubmit}
      >
        <div className={!isArt ? "upload-disable" : "form-all"}>
          <div className="rowNumber">
            <div className="rowLeft">Enter your details.</div>
            <div className="rowright">
              <div className="lableAll">
                <label className="labelLeft" htmlFor="nameInput">
                  Artwork Name
                </label>
                <label className="labelRight" htmlFor="nameInput">
                  Obligatory
                </label>
              </div>

              <div className="form-group form-custom">
                <input
                  placeholder="Enter the artwork name"
                  type="text"
                  value={formik.values.name}
                  className="form-control f-control"
                  name="name"
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name && (
                  <p style={{ color: "red" }}>{formik.errors.name}</p>
                )}
              </div>
            </div>
          </div>

          <div className="rowNumber">
            <div className="rowLeft">Add a short bio.</div>
            <div className="rowright">
              <div className="lableAll">
                <label className="labelLeft" htmlFor="Textarea1">
                  Enter a short bio
                </label>
                <label className="labelRight" htmlFor="Textarea1">
                  Optional
                </label>
              </div>

              <div className="form-group form-custom">
                <textarea
                  placeholder="Write somethings about your artwork"
                  className="form-control f-control cus"
                  value={formik.values.description}
                  name="description"
                  onChange={formik.handleChange}
                />
                {formik.errors.description && formik.touched.description && (
                  <p style={{ color: "red" }}>{formik.errors.description}</p>
                )}
              </div>
            </div>
          </div>

          <div className="rowNumber">
            <div className="rowLeft">Type your NFT.</div>
            <div className="rowright">
              <div className="lableAll">
                <label
                  className="labelLeft"
                  style={{ width: "50%", textAlign: "start" }}
                >
                  Enter Type your NFT
                </label>
                <label
                  className="labelLeft"
                  style={{
                    width: "50%",
                    textAlign: "start",
                    paddingLeft: "15px",
                  }}
                >
                  Catelory
                </label>
              </div>

              <div className="form-group form-custom">
                <div className="row">
                  <div className=" col-sm-6 col-md-6 row-line">
                    <img src="../../images/imgnft/icon-select.png" alt="" />
                    <select
                      value={formik.values.category}
                      className="form-control"
                      onChange={changeType}
                    >
                      <option value="0">Image</option>
                      <option value="1">Video</option>
                      <option value="0">Gif</option>
                    </select>
                    {formik.errors.category && formik.touched.category && (
                      <p style={{ color: "red" }}>{formik.errors.category}</p>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-6 row-line">
                    <img src="../../images/imgnft/icon-select.png" alt="" />
                    <select
                      className="form-control"
                      name="channel"
                      onChange={formik.handleChange}
                    >
                      {objListType.map((item: any, i: any) => (
                        <ListType key={item.id} item={item} />
                      ))}
                    </select>
                    {formik.errors.channel && formik.touched.channel && (
                      <p style={{ color: "red" }}>{formik.errors.channel}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rowNumber">
            <div className="rowLeft">
              <p>Upload a file NFT</p>
              <p className="rowLeftSmall">
                Recommended size: JPG, PNG, or GIF. 10MB max size.
              </p>
            </div>
            <div className="rowright">
              <div className="formfile">
                {" "}
                <div className="sdfsd">
                  <div className="sdgfhd">
                    <div className="form-group imagevideo form-custom">
                      <UploadFile
                        ref={refUpload}
                        name="fileName"
                        id="imgFile"
                        accept={temp}
                        onChange={upLoadFile}
                      />
                      {formik.errors.fileName && formik.touched.fileName && (
                        <p style={{ color: "red" }}>{formik.errors.fileName}</p>
                      )}
                      {formik.errors.sizeImage && (
                        <p style={{ color: "red" }}>
                          {formik.errors.sizeImage}
                        </p>
                      )}
                      {formik.errors.sizeVideo && (
                        <p style={{ color: "red" }}>
                          {formik.errors.sizeVideo}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              </div>
            </div>
          </div>

          {formik.values.category === "1" ? (
            <>
              <div className="rowNumber">
                <div className="rowLeft">
                  <p>Upload a cover NFT</p>
                  <p className="rowLeftSmall">
                    Recommended size: 1000x1000px. JPG, PNG, or GIF. 10MB max
                    size.
                  </p>
                </div>
                <div className="rowright">
                  <div className="formfile">
                    {" "}
                    <div className="sdfsd">
                      <div className="sdgfhd">
                        <div className="form-group imagevideo form-custom">
                          <UploadFile
                            // ref={refUpload}
                            name="fileNameImgVideo"
                            id="imgFileImg"
                            accept="image/*"
                            onChange={upLoadFileImgVideo}
                          />
                          {formik.errors.fileName &&
                            formik.touched.fileName && (
                              <p style={{ color: "red" }}>
                                {formik.errors.fileName}
                              </p>
                            )}
                          {formik.errors.sizeImage && (
                            <p style={{ color: "red" }}>
                              {formik.errors.sizeImage}
                            </p>
                          )}
                          {formik.errors.sizeVideo && (
                            <p style={{ color: "red" }}>
                              {formik.errors.sizeVideo}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="form-group">
            <div className="row col-md-12">
              <CheckBox
                className="form-check-label-accept col-md-12"
                name="accept"
                onChange={chosse}
                htmlFor="accept"
                label="I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the
                artwork can be removed anytime if detected."
              />
              {/* </div> */}
              {formik.errors.accept && formik.touched.accept && (
                <p
                  style={{
                    color: "red",
                    marginLeft: "17px",
                  }}
                >
                  {formik.errors.accept}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="btn-wrapper" id="btn-submit-artwork">
          <>
            {account === undefined ? (
              <Button
                type="button"
                click={() =>
                  message.warning({
                    type: "warning",
                    content: "Please Unlock Wallet!",
                    className: "custom-class",
                    duration: 0.5,
                  })
                }
                primary
                text="Mint NFT"
              />
            ) : !isArt ? (
              <div className="nft-button">
                <button
                  type="button"
                  className="btn btn-approve "
                  onClick={() => {
                    navigate(`${"/create-profile"}`);
                  }}
                >
                  Go to Create Artist
                </button>
              </div>
            ) : (
              <>
                <div className="gr-btn-mint">
                  <Button
                    type="button"
                    text="Cancel"
                    ghost
                    click={() => {
                      handleCancel();
                    }}
                    className="cancel-mint"
                  // disabled={isLoading || !isAccount || !isArt}
                  // loading={isLoading}
                  />
                  &emsp;
                  <Button
                    type="submit"
                    text="Mint NFT"
                    primary
                    className="cancel-mint"
                    disabled={isLoading || !isAccount || !isArt}
                    loading={isLoading}
                  />
                </div>
                {/* {state.objData.author === "" ? (
                  <Button
                    type="button"
                    primary
                    text="Mint NFT"
                    click={() =>
                      message.warning({
                        type: "warning",
                        content: "Please create artist!",
                        className: "custom-class",
                        duration: 0.5,
                      })
                    }
                  />
                ) : (
                  <Button
                    type="submit"
                    text="Mint NFT"
                    primary
                    disabled={isLoading || !isAccount || !isArt}
                    loading={isLoading}
                  />
                )} */}
              </>
            )}
          </>
          {/* {!hasPendingTransactions ? (
            <>
              {account === null ? (
                <Button
                  type="button"
                  click={() => message.warning("Please Unlock Wallet!")}
                  primary
                  text="Mint NFT"
                />
              ) : (
                <>
                  {!state.objData.artistName ? (
                    <Button
                      type="button"
                      primary
                      text="Mint NFT"
                        click={() =>
                        message.warning("Please create artist!")}
                        
                      
                    />
                  ) : (
                    <Button
                      type="submit"
                      text="Mint NFT"
                      primary
                      loading={isLoading}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <Button
              disabled={hasPendingTransactions}
              text="Pending"
              primary
              right={<i className="fa fa-spinner fa-spin" />}
            />
          )} */}
        </div>
      </form>
    </>
  );
}
