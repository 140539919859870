import React, { useMemo, useState, useCallback } from "react";
import Input from "../InputManager/index";
import Modal from "../../../componentsST/Modal/index";
import Button from "../../../componentsST/ButtonV2/index";
import "../../../componentsST/StakeInPoolModal/index.less";
import { useContract } from "../../../hooks/useContract";
import sousChefAbi from "../../../config/abi/sousChef.json";
import poolsConfig from "../../../constantsST/pools";
import { configures } from "../utils/callHelpers";
import { QuestionCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";

const ConfigModal = (props) => {
  const [val, setVal] = useState("");
  const [stakeToken, setStakeToken] = useState();
  const [rewardToken, setRewardToken] = useState();
  const [startBlock, setStartBlock] = useState();
  const [endBlock, setEndBlock] = useState();
  const [rewardBlock, setRewardBlock] = useState();
  const [unstakeBlock, setUnstakeBlock] = useState();
  const [feeUnstake, setFeeUnstake] = useState();
  const [feeCollect, setFeeCollect] = useState();
  const [admin, setAdmin] = useState();
  const [pendingTx, setPendingTx] = useState(false);
  const { sousId, decimals, onDismiss } = props;

  const handleChange1 = useCallback(
    (e) => {
      setStakeToken(e.currentTarget.value);
    },
    [setStakeToken]
  );
  const handleChange2 = useCallback(
    (e) => {
      setRewardToken(e.currentTarget.value);
    },
    [setRewardToken]
  );
  const handleChange3 = useCallback(
    (e) => {
      setStartBlock(e.currentTarget.value);
    },
    [setStartBlock]
  );
  const handleChange4 = useCallback(
    (e) => {
      setEndBlock(e.currentTarget.value);
    },
    [setEndBlock]
  );
  const handleChange5 = useCallback(
    (e) => {
      setRewardBlock(e.currentTarget.value);
    },
    [setRewardBlock]
  );
  const handleChange6 = useCallback(
    (e) => {
      setUnstakeBlock(e.currentTarget.value);
    },
    [setUnstakeBlock]
  );
  const handleChange7 = useCallback(
    (e) => {
      setFeeUnstake(e.currentTarget.value);
    },
    [setFeeUnstake]
  );
  const handleChange8 = useCallback(
    (e) => {
      setFeeCollect(e.currentTarget.value);
    },
    [setFeeCollect]
  );
  const handleChange9 = useCallback(
    (e) => {
      setAdmin(e.currentTarget.value);
    },
    [setAdmin]
  );

  const pool = poolsConfig.find((pool) => pool.sousId === sousId);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const handleUpdate = async () => {
    try {
      setPendingTx(true);
      await configures(
        contract,
        stakeToken,
        rewardToken,
        startBlock,
        endBlock,
        rewardBlock,
        unstakeBlock,
        feeUnstake * 100,
        feeCollect,
        admin,
        decimals
      ).then((res) => {
        res.wait().then((res2) => {
          if (res2 !== null) {
            setPendingTx(false);
            onDismiss();
            message.success({
              type: "success",
              content: "Update success!",
              className: "custom-class",
              duration: 3,
            });
          }
        });
      });
    } catch (e) {
      console.log("e", e);
      message.error({
        type: "error",
        content: "Update failed",
        className: "custom-class",
        duration: 3,
      });
      setPendingTx(false);
    }
  };
  const textTooltip1 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input stake token adress.</span>
      </div>
    </div>
  );
  const textTooltip2 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input reward token adress.</span>
      </div>
    </div>
  );
  const textTooltip3 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input pool start time in BLOCK format</span>
      </div>
    </div>
  );
  const textTooltip4 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input pool end time in BLOCK format.</span>
      </div>
    </div>
  );
  const textTooltip5 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input amount reward token in BLOCK format.</span>
      </div>
    </div>
  );
  const textTooltip6 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input unstake time in BLOCK format.</span>
      </div>
    </div>
  );
  const textTooltip7 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input fee unstake in PERCENT format.</span>
      </div>
    </div>
  );
  const textTooltip8 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input address collecttor fee unstake.</span>
      </div>
    </div>
  );
  const textTooltip9 = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Input admin adress.</span>
      </div>
    </div>
  );
  return (
    <Modal>
      <div className="bsc-stake_in_pool-modal config-modal">
        <div className="bsc-stake_in_pool-modal-header">
          <span>Configures pool</span>
          <span>
            {pool.stakingToken.symbol} - {pool.earningToken.symbol}
          </span>
        </div>
        <div className="bsc-stake_in_pool-modal-content">
          <div className="bsc-stake_in_pool-modal-content-top">
            <span>
              <a
                href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "#1bc5bd" }}
              >
                Contract: {pool.contractAddress.substr(0, 5)}...
                {pool.contractAddress.substr(pool.contractAddress.length - 10)}
              </a>
            </span>
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid mb-10">
            <Input
              change={handleChange1}
              type="text"
              value={stakeToken}
              right={
                <span style={{ fontSize: "14px" }}>
                  Stake token{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip1}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: Address"}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid mb-10">
            <Input
              change={handleChange2}
              type="text"
              value={rewardToken}
              right={
                <span style={{ fontSize: "14px" }}>
                  Reward token{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip2}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: Address"}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid d-flex justify-content-between row-flex input-setting-2 mb-10">
            <Input
              change={handleChange3}
              type="number"
              value={startBlock}
              right={
                <span style={{ fontSize: "14px" }}>
                  Start block{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip3}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: 112233"}
            />
            <Input
              change={handleChange4}
              type="number"
              value={endBlock}
              right={
                <span style={{ fontSize: "14px" }}>
                  End block{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip4}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: 445566"}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-mi d-flex justify-content-between row-flex input-setting-3 mb-10">
            <Input
              change={handleChange5}
              type="number"
              value={rewardBlock}
              right={
                <span style={{ fontSize: "14px" }}>
                  Reward{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip5}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: 0.12"}
            />
            <Input
              change={handleChange6}
              type="number"
              value={unstakeBlock}
              right={
                <span style={{ fontSize: "14px" }}>
                  Unstake{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip6}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: 86400"}
            />
            <Input
              change={handleChange7}
              type="number"
              value={feeUnstake}
              right={
                <span style={{ fontSize: "14px" }}>
                  Fee (%){" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip7}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: 2"}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid mb-10">
            <Input
              change={handleChange8}
              type="text"
              value={feeCollect}
              right={
                <span style={{ fontSize: "14px" }}>
                  Fee Collector{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip8}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: Address"}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid mb-10">
            <Input
              change={handleChange9}
              type="text"
              value={admin}
              right={
                <span style={{ fontSize: "14px" }}>
                  Admin address{" "}
                  <Tooltip overlayClassName="tooltip-cus" title={textTooltip9}>
                    <QuestionCircleOutlined style={{ paddingTop: "2px" }} />
                  </Tooltip>
                </span>
              }
              placeholder={"Eg: Address"}
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-bottom mb-4">
            <Button text="Cancel" ghost click={onDismiss} />
            <Button
              text="Confirm"
              primary
              loading={pendingTx}
              disabled={
                pendingTx ||
                [
                  stakeToken,
                  rewardToken,
                  startBlock,
                  endBlock,
                  rewardBlock,
                  unstakeBlock,
                  feeUnstake,
                  feeCollect,
                  admin,
                ].some((value) => value === undefined)
              }
              click={handleUpdate}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigModal;
