import React, { useEffect, useState } from "react";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { Select, Empty, DatePicker, message, Spin, Collapse } from 'antd';
import { useHookACC } from "./StoreAcc";
import { isMobile } from "react-device-detect";
import moment from 'moment';
import { _claimTokenRef, _isClaimedRef } from "./utils";
import AffiliateClaim from "../Ido/Abi/AffiliateClaim.json"
import { useContract } from "../../hooks/useContract";
import { useAccount } from "wagmi";

const StakingList = () => {
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [valueKey, setValueKey] = useState("0");
    const { Panel } = Collapse;
    const onChange = (
        value: any,
        dateString: [string, string] | string,
    ) => {
        actionsAcc.getListStake({
            ownerAddress: account,
            status: Number(valueKey),
            fromTime: moment.utc(value[0]._d).format(),
            toTime: moment.utc(value[1]._d).format(),
            lastTime: null
        })
    };

    const handleChange = (value: any) => {
        setValueKey(value)
        actionsAcc.getListLaunchpad({
            ownerAddress: account,
            status: Number(value),
            fromTime: null,
            toTime: null,
            lastTime: null
        });
    };
    const [stateAcc, actionsAcc]: any = useHookACC();
    const { address } = useAccount();
    let account = address

    const [enableLoadMore, setEnableLoadMore] = useState(true);

    const handleMore = () => {
        if (stateAcc.listStakeMore.length > 0) {
            params.lastTime =
                stateAcc.listStakeMore[stateAcc.listStakeMore.length - 1].lastDate;
        }
        actionsAcc.getListStakeMore(params).then((data: any) => {
            if (data.length === 0) setEnableLoadMore(false);
        });
    };

    const params = {
        ownerAddress: account,
        status: 0,
        fromTime: null,
        toTime: null,
        lastTime: stateAcc?.listStake.length > 0 ? stateAcc?.listStake[stateAcc?.listStake.length - 1].lastDate : null,
    }
    useEffect(() => {
        if (account) {
            actionsAcc.getListStake(params);
        }
    }, [actionsAcc, account]);

    let accountAddress: any = account;
    if (accountAddress === undefined) {
        accountAddress = localStorage.getItem("currentAddress") || "";
    }
    const [isLoading, setIsLoading] = useState(false)


    const Item = ({ item }: any) => {

        const scContractRef = item?.yourRewards?.claimContract
        const claimContractRef = useContract(scContractRef, AffiliateClaim);
        const [isClaimed, setIsClaimed] = useState(false);

        const handleClaim = async () => {
            setIsLoading(true);
            try {
                await _claimTokenRef(claimContractRef, item?.yourRewards?.rewards, item?.yourRewards?.keyId, item?.yourRewards?.claimSign, item?.yourRewards?.tokenDecimal).then(
                    (res) => {
                        res.wait().then((res1: any) => {
                            if (res1 !== null) {
                                setIsLoading(false);
                                setIsClaimed(true);
                            } else {
                                setIsLoading(false);
                            }
                        });
                    }
                )
            } catch (error: any) {
                setIsLoading(false);
                if (error) {
                    message.error(error?.error?.data?.message);
                }
            }
        };

        useEffect(() => {
            if (accountAddress) {
                _isClaimedRef(claimContractRef, accountAddress).then((res: any) => {
                    setIsClaimed(res);
                });
            }
        }, [accountAddress, claimContractRef]);

        return (
            <>
                <tr className="tr-bottom">
                    <td>{item?.createOn}</td>
                    <td>{item?.ownerAddress?.substring(0, 4)}...{item?.ownerAddress?.substring(item?.ownerAddress.length - 4)}</td>
                    <td>{item?.idoPool}</td>
                    <td>
                        <div className="top">{item?.stakeAmount} BSCS</div>
                        <div className="bot">${item?.stakeAmount * 0.00890905}</div>
                    </td>
                    <td>
                        {isLoading ?
                            <>
                                <button className="btn-claim-ref dis" type="button" onClick={handleClaim}>
                                    Claim <Spin size="small" />
                                </button>
                            </>
                            :
                            <>
                                {item?.yourRewards?.rewards !== null && item?.yourRewards?.rewards > 0 ?
                                    <>
                                        {isClaimed ?
                                            <>
                                                <button className="btn-claim-ref dis" type="button">
                                                    Claimed
                                                </button>
                                            </>
                                            :
                                            <>
                                                <button className="btn-claim-ref" type="button" onClick={handleClaim}>
                                                    Claim {item?.yourRewards?.rewards} USDT
                                                </button>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <button className="btn-claim-ref dis" type="button">
                                            Claim {item?.yourRewards?.rewards} USDT
                                        </button>
                                    </>
                                }
                            </>
                        }
                    </td>
                </tr>
            </>
        )
    }

    const HeaderTopIdo = (props: any) => {
        const { item } = props;
        return (
            <div className="content-header-panel">
                <div className="project-name-m">
                    {item.createOn}
                </div>
                <div className="token-name-m">
                    {item?.ownerAddress?.substring(0, 4)}...{item?.ownerAddress?.substring(item?.ownerAddress.length - 4)}
                </div>
            </div>
        );
    };

    const ItemMobile = ({ item }: any) => {
        const scContractRef = item?.yourRewards?.claimContract
        const claimContractRef = useContract(scContractRef, AffiliateClaim);
        const [isClaimed, setIsClaimed] = useState(false);

        const handleClaim = async () => {
            setIsLoading(true);
            try {
                await _claimTokenRef(claimContractRef, item?.yourRewards?.rewards, item?.yourRewards?.keyId, item?.yourRewards?.claimSign, item?.yourRewards?.tokenDecimal).then(
                    (res) => {
                        res.wait().then((res1: any) => {
                            if (res1 !== null) {
                                setIsLoading(false);
                                setIsClaimed(true);
                            } else {
                                setIsLoading(false);
                            }
                        });
                    }
                )
            } catch (error: any) {
                setIsLoading(false);
                if (error) {
                    message.error(error?.error?.data?.message);
                }
            }
        };

        useEffect(() => {
            if (accountAddress) {
                _isClaimedRef(claimContractRef, accountAddress).then((res: any) => {
                    setIsClaimed(res);
                });
            }
        }, [accountAddress, claimContractRef]);


        return (
            <>
                <div className="content-ref-m">
                    <div className="item">
                        <div className="txt-l">
                            IDO Pools
                        </div>
                        <div className="txt-r">
                            {item?.idoPool}
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Staking Amount
                        </div>
                        <div className="txt-r">
                            <div className="top">
                                {item?.stakeAmount} BSCS
                            </div>
                            <div className="bot">
                                ${item?.stakeAmount * 0.0089}
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="txt-l">
                            Referal Rewards
                        </div>
                        <div className="txt-r">
                            {isLoading ?
                                <>
                                    <button className="btn-claim-ref dis" type="button" onClick={handleClaim}>
                                        Claim <Spin size="small" />
                                    </button>
                                </>
                                :
                                <>
                                    {item?.yourRewards?.rewards !== null && item?.yourRewards?.rewards > 0 ?
                                        <>
                                            {isClaimed ?
                                                <>
                                                    <button className="btn-claim-ref dis" type="button">
                                                        Claimed
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button className="btn-claim-ref" type="button" onClick={handleClaim}>
                                                        Claim {item?.yourRewards?.rewards} USDT
                                                    </button>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <button className="btn-claim-ref dis" type="button">
                                                Claim {item?.yourRewards?.rewards} USDT
                                            </button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="main-wrap-referral-list">
                <div className="content-guide">
                    <div className="header-tabs">
                        <div className="title">
                            <div className="txt">
                                Staking IDO Pools
                            </div>
                            <div className="desc">
                                All your Launchpad referral rewards are listed below
                            </div>
                        </div>
                        <div className="search-project res">
                            <div className="sort">
                                <Select defaultValue="0" onChange={handleChange} style={{ width: 160 }}>
                                    <Option value="0">All</Option>
                                    <Option value="1">Today</Option>
                                    <Option value="2">This Week</Option>
                                    <Option value="3">This Month</Option>
                                    <Option value="4">Custom Range</Option>
                                </Select>
                                {valueKey === "4" ?
                                    <>
                                        <div className="date-picker">
                                            {/* @ts-ignore this lib is incompatible with react18  */}
                                            <RangePicker onChange={onChange} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {""}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="body-table">
                        {isMobile ?
                            <>
                                {/* @ts-ignore this lib is incompatible with react18  */}
                                <Collapse defaultActiveKey={['1']}>
                                    {/* @ts-ignore this lib is incompatible with react18  */}
                                    {stateAcc?.listStake && stateAcc?.listStake.map((item: any, index: any) => (
                                        /* @ts-ignore this lib is incompatible with react18  */
                                        <Panel header={<HeaderTopIdo item={item} />} key={index.toString()} className="round-ido-m">
                                            <ItemMobile item={item} />
                                        </Panel>
                                    ))}
                                </Collapse>
                            </>
                            :
                            <>
                                <table className="main-table">
                                    <tr className="tr-top">
                                        <th>Date</th>
                                        <th>Wallet Address</th>
                                        <th>IDO Pools</th>
                                        <th>Staking Amount</th>
                                        <td>Referal Rewards</td>
                                    </tr>
                                    {stateAcc?.listStake.length > 0 ?
                                        <>
                                            {stateAcc?.listStake && stateAcc?.listStake.map((item: any, i: any) => (
                                                <Item item={item} />
                                            ))}
                                        </>
                                        :
                                        <>
                                            <tr className="bottom">
                                                <td colSpan={5}>
                                                    <div className="empty-colspan">
                                                        <Empty description={false} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {stateAcc?.listStakeMore && stateAcc?.listStakeMore.map((item: any, i: any) => (
                                        <Item item={item} />
                                    ))}
                                </table>
                            </>
                        }
                        {enableLoadMore && stateAcc?.listStake.length !== 0 ? (
                            <>
                                <div className="button-read-more">
                                    <button className="btn-read-more" type="button" onClick={handleMore}>
                                        Read More
                                    </button>
                                </div>
                            </>
                        )
                            :
                            (
                                <>
                                    {""}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default StakingList