/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs, Switch, Select } from "antd";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import CalendarUpcoming from "./Upcoming/CalendarUpcoming";
import ListUpcoming from "./Upcoming/ListUpcoming";
import ListIdoActive from "./Active/ListIdoActive";
import ListIdoEnd from "./End/ListIdoEnd";
import ListIdoJoined from "./Joined/ListIdoJoined";
import { useHookIDO } from "./StoreIdo";
const TabsIdo = () => {
    const [state, actions]: any = useHookIDO();
    const { TabPane } = Tabs;

    const onChangeSwitch = (checked: any) => {
        setValueChecked(!checked);
    };
    const [valueChecked, setValueChecked] = useState(false);

    let location = useLocation();

    let navigate = useNavigate();

    const [defaultKey, setDefaultKey] = useState("Upcoming");
    const [activeTab, setActiveTab] = useState("Upcoming");
    const pathTabsLive = location.search.split("?");

    const tabBox = pathTabsLive[1] === undefined ? "Upcoming" : pathTabsLive[1];
    function callback(key: any) {
        console.log("key: ", key);
        setDefaultKey(key);

        navigate(`${"/Ido"}?${key}`);
    }

    const { Option } = Select;

    // const handleChange = (value: string) => {
    //   setDefaultKey(value)
    //   setActiveTab(value)
    // };

    useEffect(() => {
        if (tabBox) {
            setDefaultKey(tabBox);
            setActiveTab(tabBox);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabBox]);

    if (isMobile) {
        return (
            <>
                <div className="main-tabs-ido mobile">
                    {activeTab === "Upcoming" ? (
                        <>
                            <div className="select-mobile">
                                <Select
                                    // activeKey={defaultKey}
                                    defaultValue={tabBox === undefined ? "Upcoming" : tabBox}
                                    onChange={callback}
                                >
                                    <Option key="Upcoming" value="Upcoming">
                                        Upcoming
                                    </Option>
                                    <Option key="Active" value="Active">
                                        Active
                                    </Option>
                                    <Option key="Ending" value="Ending">
                                        Ended
                                    </Option>
                                    <Option key="Joined" value="Joined">
                                        Joined
                                    </Option>
                                </Select>
                            </div>
                            {/* <div className="switch-calendar">
                <span>View Calendar</span>{" "}
                <Switch defaultChecked onChange={onChangeSwitch} />
              </div> */}
                            {/* {!valueChecked ? <CalendarUpcoming /> : <ListUpcoming />} */}
                            <ListUpcoming />
                        </>
                    ) : (
                        ""
                    )}
                    {activeTab === "Active" ? (
                        <>
                            <div className="select-mobile">
                                <Select
                                    // activeKey={defaultKey}
                                    defaultValue={tabBox === undefined ? "Upcoming" : tabBox}
                                    onChange={callback}
                                >
                                    <Option key="Upcoming" value="Upcoming">
                                        Upcoming
                                    </Option>
                                    <Option key="Active" value="Active">
                                        Active
                                    </Option>
                                    <Option key="Ending" value="Ending">
                                        Ended
                                    </Option>
                                    <Option key="Joined" value="Joined">
                                        Joined
                                    </Option>
                                </Select>
                            </div>
                            <ListIdoActive />
                        </>
                    ) : (
                        ""
                    )}
                    {activeTab === "Ending" ? (
                        <>
                            <div className="select-mobile">
                                <Select
                                    // activeKey={defaultKey}
                                    defaultValue={tabBox === undefined ? "Upcoming" : tabBox}
                                    onChange={callback}
                                >
                                    <Option key="Upcoming" value="Upcoming">
                                        Upcoming
                                    </Option>
                                    <Option key="Active" value="Active">
                                        Active
                                    </Option>
                                    <Option key="Ending" value="Ending">
                                        Ended
                                    </Option>
                                    <Option key="Joined" value="Joined">
                                        Joined
                                    </Option>
                                </Select>
                            </div>
                            <ListIdoEnd />
                        </>
                    ) : (
                        ""
                    )}
                    {activeTab === "Joined" ? (
                        <>
                            <div className="select-mobile">
                                <Select
                                    // activeKey={defaultKey}
                                    defaultValue={tabBox === undefined ? "Upcoming" : tabBox}
                                    onChange={callback}
                                >
                                    <Option key="Upcoming" value="Upcoming">
                                        Upcoming
                                    </Option>
                                    <Option key="Active" value="Active">
                                        Active
                                    </Option>
                                    <Option key="Ending" value="Ending">
                                        Ended
                                    </Option>
                                    <Option key="Joined" value="Joined">
                                        Joined
                                    </Option>
                                </Select>
                            </div>
                            <ListIdoJoined />
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <MainTabsIdo>
                <div className="main-tabs-ido">
                    <Tabs className="tabs-ido" activeKey={defaultKey} onChange={callback}>
                        <TabPane tab="Upcoming" key="Upcoming">
                            {/* <div className="switch-calendar">
                <span>View Calendar</span>{" "}
                <Switch defaultChecked onChange={onChangeSwitch} />
              </div> */}
                            <ListUpcoming />
                            {/* {!valueChecked ? <CalendarUpcoming /> : <ListUpcoming />} */}
                        </TabPane>
                        <TabPane tab="Active" key="Active">
                            <ListIdoActive />
                        </TabPane>
                        <TabPane tab="Ended" key="Ending">
                            <ListIdoEnd />
                        </TabPane>
                        <TabPane tab="Joined" key="Joined">
                            <ListIdoJoined />
                        </TabPane>
                    </Tabs>
                </div>
            </MainTabsIdo>
        </>
    );
};
export default TabsIdo;
const MainTabsIdo = styled.div`
  width: 100%;
`;
