/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useHookIDO } from "./StoreIdo";
import { WEIGHT_TIER } from "./index.d";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

const BannerIdo = () => {
  const [state, actions]: any = useHookIDO();
  const { address } = useAccount();
  let account = address
  const [tierCu, setTierCu] = useState("");
  const [tierAg, setTierAg] = useState("");
  const [tierAu, setTierAu] = useState("");
  const [tierPt, setTierPt] = useState("");
  const [tierC, setTierC] = useState("");
  const [tierD, setTierD] = useState("");
  const [isClass, setClass] = useState("");
  let amountStake = state.owner?.currentTier;
  const tierText = (() => {
    // switch (state.owner?.currentTier) {
    switch (amountStake) {
      case WEIGHT_TIER.Bronze:
        return WEIGHT_TIER[WEIGHT_TIER.Bronze];
      case WEIGHT_TIER.Diamond:
        return WEIGHT_TIER[WEIGHT_TIER.Diamond];
      case WEIGHT_TIER.Gold:
        return WEIGHT_TIER[WEIGHT_TIER.Gold];
      case WEIGHT_TIER.Platinum:
        return WEIGHT_TIER[WEIGHT_TIER.Platinum];
      case WEIGHT_TIER.Silver:
        return WEIGHT_TIER[WEIGHT_TIER.Silver];
      case WEIGHT_TIER.SupDiamond:
        return "Super Diamond";
      default:
        return "N/A";
    }
  })();
  useEffect(() => {
    if (account !== undefined || amountStake !== undefined) {
      if (amountStake <= 0) {
        setTierCu("");
        setTierAg("");
        setTierAu("");
        setTierPt("");
        setTierC("");
        setTierD("");
      } else if (amountStake === 15) {
        setTierCu("cuTier");
      } else if (amountStake === 35) {
        setTierAg("agTier");
      } else if (amountStake === 60) {
        setTierAu("auTier");
      } else if (amountStake === 190) {
        setTierPt("pTier");
      } else if (amountStake === 390) {
        setTierC("cTier");
      } else if (amountStake === 750) {
        setTierD("dTier");
      }
    }
  }, [account, amountStake]);
  // console.log('amountStake',amountStake)
  useEffect(() => {
    actions.getTVL();
  }, []);

  const formatNumber = (x: any, max: any) => {
    if (x) {
      return x.toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: max,
      });
    } else return 0;
  };

  const TVL = formatNumber(state.totalVL.data && state.totalVL.data, 2);

  useEffect(() => {
    if (account) {
      // tierUser();
      actions.getOwner({ account });
    }
  }, [account, actions]);
  let navigate = useNavigate();

  const gotoStake = () => {
    navigate(`${"/launch-pool"}`);
  };
  const gotoIdoPool = () => {
    navigate(`${"/IDOPools"}`);
  };

  return (
    <>
      <IdoBanner>
        <div className="columns res">
          <div className="colum w-50">
            <div className="content-ido-banner">
              <h3 className="title-ido">BSCS Launchpad</h3>
              <p className="desc-ido">
                An all-in-one Incubation Hub with a full stack Defi platform
                across all main blockchain networks
              </p>
              <ul className="list-info-stake">
                <li>
                  <span className="text-top">Current Tier</span>
                  <span className="text-bottom">{tierText}</span>
                </li>
                <li>
                  <span className="text-top">Your Stake</span>
                  {account ? (
                    <span className="text-bottom">{state.owner?.staking}</span>
                  ) : (
                    <span className="text-bottom">N/A</span>
                  )}
                </li>
                <li>
                  <span className="text-top">Total value BSCS lock</span>
                  <span className="text-bottom">${TVL || "0"}</span>
                </li>
              </ul>
              <div className="button-ido-stake">
                <button
                  type="button"
                  className="btn btn-stake"
                  onClick={() => {
                    gotoStake();
                  }}
                >
                  Stake
                </button>
                {/* <button
                  type="button"
                  className="btn btn-stake-pool"
                  onClick={() => {
                    gotoIdoPool();
                  }}
                >
                  IDO pool
                </button> */}
              </div>
            </div>
          </div>
          <div className="colum w-50">
            <div className="main-tier">
              <div className="top-tier">
                <div className="logo-tier-bscs">
                  <img src="/images/logo-brand-v3.svg" alt="" />
                </div>
                <div className="logo-tier-current">
                  {amountStake > 0 ? (
                    amountStake === 750 ? (
                      <>
                        <img src={`/images/SUPDIAMOND.svg`} />
                      </>
                    ) : (
                      <>
                        <img src={`/images/${tierText.toUpperCase()}.svg`} />
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="body-tier">
                <div className="line-tier"></div>

                <ul className="list-tier">
                  <li className={tierCu}>
                    <span className="number-stake">15,000</span>
                    <div className="img-tier">
                      <img src="/images/BRONZE.svg" alt="" />
                    </div>
                    <span className="name-tier">Bronze</span>
                  </li>

                  <li className={tierAg}>
                    <span className="number-stake">30,000</span>
                    <div className="img-tier">
                      <img src="/images/SILVER.svg" alt="" />
                    </div>
                    <span className="name-tier">Silver</span>
                  </li>
                  <li className={tierAu}>
                    <span className="number-stake">50,000</span>
                    <div className="img-tier">
                      <img src="/images/GOLD.svg" alt="" />
                    </div>
                    <span className="name-tier">Gold</span>
                  </li>
                  <li className={tierPt}>
                    <span className="number-stake">150,000</span>
                    <div className="img-tier">
                      <img src="/images/PLATINUM.svg" alt="" />
                    </div>
                    <span className="name-tier">PLATINUM</span>
                  </li>
                  <li className={tierC}>
                    <span className="number-stake">300,000</span>
                    <div className="img-tier">
                      <img src="/images/DIAMOND.svg" alt="" />
                    </div>
                    <span className="name-tier">DIAMOND</span>
                  </li>
                  <li className={tierD}>
                    <span className="number-stake">500,000</span>
                    <div className="img-tier">
                      <img src="/images/SUPDIAMOND.svg" alt="" />
                    </div>
                    <span className="name-tier">SUPER DIAMOND</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </IdoBanner>
    </>
  );
};
export default BannerIdo;

const IdoBanner = styled.div`
  margin-bottom: 90px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
