/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from "../../constants/index";

const StorePrice = createStore({
  initialState: {
    price: "",
    amount: 0,
    marketInfo: {},
    chain: "",
  },
  actions: {
    updateChain:
      (param) =>
      async ({ setState, getState }) => {
        setState({ chain: param });
      },
    savePrice:
      (param) =>
      async ({ setState, getState }) => {
        setState({ price: param });
      },
    getMarketInfo:
      () =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          axios(`${API_URL}/Market/api/get_market`).then((res) => {
            const { data } = res.data;
            setState({ marketInfo: { ...data } }); // TODO
            resolve(data);
          });
        });
      },
  },

  name: "Price Store",
});

export const useHookPrice = createHook(StorePrice);
export const Container = createContainer(StorePrice, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StorePrice);
