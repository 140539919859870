/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Progress, message, Modal, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import NumericInput from "react-numeric-input";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import bgMainheader from "../images/banner-ino.png";
// import { Cookies } from "react-cookie";
import { useHookIno } from "../Store-Ino";
import { useContract, useBoxMarketContract } from "../../../hooks/useContract";
import abiBEP20 from "../Abi/abiBEP20.json";
import { getProgressTime, rendererCountDown } from "../../Ido/utilsIDO";
import ErrorPage from "../../ErrorPage";
import { useHookPrice } from "../../../components/common/Store";

import {
  NETWORK,
  // CHAINID_FULLNAME,
  CHAINID_CONVERT,
  MAPPING_CHAINID,
  STATUS,
} from "../index.d";
import {
  _approveBEP20,
  _mysteryBoxInfo,
  _getCampaigns,
  _getBoughtBoxes,
  _getCampaignInfo,
  _buyMysteryBox,
} from "../utils";
import switchNetwork from "../switchWallet";
import INO_BOTTOM from "./DetailBottom";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";


const ItemBoxInfo = ({
  itemBoxActive,
  setItemBox,
  setNewBoxId,
  setLoadingSpin,
  account,
  campaignId,
  item,
  contractBox,
  pathHash,
}: any) => {
  const [data, setData]: any = useState({});
  const [state, actions]: any = useHookIno();
  const param = {
    keywork: pathHash,
    id: 0,
    ownerAddress: account,
    typeId: 0,
  };

  useEffect(() => {
    if (contractBox && item.boxId) {
      _mysteryBoxInfo(contractBox, campaignId, item.boxId).then((resData) => {
        setData(resData);
      });
    }
  }, [contractBox, account, item.boxId]);

  const clickType = (obj: any) => {
    param.typeId = obj.id;
    try {
      setLoadingSpin(true);
      setNewBoxId(obj.boxId);
      actions.getDetailINO(param);
      setItemBox(obj);
      setTimeout(() => {
        setLoadingSpin(false);
      }, 500);
    } catch (error) {
      setLoadingSpin(false);
    }
  };

  let number_buy =
    item.limit -
    ((data[0] && data[0].toNumber()) || (item.buyNumber > 0 ? 0 : item.limit));
  if (number_buy < item.buyNumber) number_buy = item.buyNumber;

  return (
    <div className="round-id">
      <div
        className={
          itemBoxActive.id === item.id
            ? "content-id active"
            : "content-id inactive"
        }
        onClick={() => clickType(item)}
      >
        <div className="box-img">
          {/* <img src="/images/imgino/img-id.png" alt="" /> */}
          <img style={{ height: "44px" }} src={item.imageUrl} alt="" />
        </div>
        <div className="name-id">[{item.name}]</div>
        <div className="quality-id">
          <>
            {account === undefined ? (
              <>0/{item.limit}</>
            ) : (
              <>
                {number_buy}/{item.limit}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

const InoDetails = () => {
  const [state, actions]: any = useHookIno();
  const [state1, actions1]: any = useHookPrice();
  const { address } = useAccount();
  const chainId = useChainId()
  let account = address
  // let curentChain = localStorage.getItem("chain");
  const [itemBox, setItemBox]: any = useState({});
  const [newboxId, setNewBoxId]: any = useState();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [isApproveJoin, setApproveJoin] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [boughtBox, setBoughtBox]: any = useState(0);
  const [quantity, setQuantity]: any = useState(1);
  const [numMaxBox, setMaxBox] = useState(0);
  const [infoUserBox, setInfoUserBox]: any = useState();
  const [isOtherChain, setOtherChain] = useState(false);

  let currentAddress = localStorage.getItem("currentAddress");

  const contractBox: any = useBoxMarketContract(state?.inoItem?.marketContract);
  const bep20Contract = useContract(
    state.inoItem?.tokenContract ||
    "0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
    abiBEP20
  );

  const percentNum =
    state.inoItem?.percentProcess !== null ? state.inoItem?.percentProcess : 0;
  const totalAllBox = state.inoItem?.totalAllBox;
  const totalOrder = state.inoItem?.totalOrder;
  const price = state.inoItem?.price;

  const location = useLocation();
  const pathSymbol = location.pathname?.split("/");

  const pathHash = pathSymbol[3];

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  const _openLink = () => {
    if (state.inoItem?.applyWhitelistUrl) {
      window.open(state.inoItem?.applyWhitelistUrl, "_blank")?.focus();
    }
  };


  useEffect(() => {
    if (contractBox && state.inoItem) {
      _getCampaignInfo(contractBox, state.inoItem?.campaignId).then((data) => {
        setInfoUserBox(data);
      });
    }
  }, [state.inoItem, state.inoItem?.id]);

  useEffect(() => {
    if (bep20Contract && account !== undefined) {

      bep20Contract
        .allowance(account, state.inoItem?.marketContract)
        .then((res: any) => {

          if (res.toString() / 1e18 >= quantity * state.inoItem?.price) {
            setApproveJoin(true);

          } else {
            setApproveJoin(false);
          }
        });
    }
  }, [state.inoItem, state.inoItem?.id, address, quantity, account, bep20Contract]);



  useEffect(() => {
    if (contractBox && state.inoItem && itemBox?.boxId) {
      if (state.inoItem?.campaignId && account) {
        _getBoughtBoxes(
          contractBox,
          account,
          state.inoItem?.campaignId,
          itemBox?.boxId
        ).then((data) => {
          setBoughtBox(data.toString());
        });
      }
    }
  }, [contractBox, state.inoItem?.campaignId, itemBox?.boxId]);



  useEffect(() => {
    if (contractBox && state.inoItem?.campaignId) {
      _getCampaigns(contractBox, state.inoItem?.campaignId).then((data) => {
        setMaxBox(data && data?.maxBuy && data?.maxBuy?.toString());
      })
        .catch((err: any) => {
          console.log('err', err)
        })
    }
  }, [state.inoItem]);

  useEffect(() => {
    if (state.inoItem?.boxId) setNewBoxId(state.inoItem?.boxId);
    // if (state.inoItem.boxTypes !== undefined) setItemBox(state.inoItem.boxTypes[0])
  }, [state.inoItem?.boxId]);

  useEffect(() => {
    if (state.inoItem?.boxTypes !== undefined)
      setItemBox(state.inoItem?.boxTypes[0]);
  }, [state.inoItem?.id]);

  const [img, setImg] = useState("");

  useEffect(() => {
    setImg(itemBox?.imageUrl);
  }, [itemBox?.imageUrl]);

  let accountAddress: any = account;
  if (accountAddress === undefined) {
    accountAddress = localStorage.getItem("currentAddress");
  }
  useEffect(() => {
    if (account !== undefined) {
      if (state.inoItem && state.inoItem?.network !== CHAINID_CONVERT[chainId])
        switchNetwork(MAPPING_CHAINID[state.inoItem?.network]);
    }

    return () => {
      // TODO
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.inoItem, accountAddress, chainId, state1.chain]);

  useEffect(() => {
    if (state.inoItem?.network && account !== undefined) {
      if (isMobile && chainId) {
        if (
          state.inoItem &&
          state.inoItem?.network !== CHAINID_CONVERT[chainId]
        ) {
          setOtherChain(true);
        }
      } else {
        switchNetwork(MAPPING_CHAINID[state.inoItem?.network]);
      }
    }
    return () => {
      // TODO
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.inoItem.network, account, currentAddress, chainId, state1.chain]);

  // handle approve
  const approve = async () => {
    setLoading(true);
    await _approveBEP20(
      bep20Contract,
      state.inoItem?.marketContract,
      quantity * state.inoItem?.price
    )
      .then((res) => {
        res.wait().then((res1: any) => {
          if (res1 !== null) {
            setLoading(false);
            setApproveJoin(true);
            message.success({
              type: "success",
              content: "Approve successfully!",
              className: "custom-class",
              duration: 2,
            });
          } else {
            setLoading(false);
            message.error({
              type: "error",
              content: "Error!",
              className: "custom-class",
              duration: 2,
            });
          }
        });
      })
      .catch((error: any) => {
        message.error({
          type: "error",
          content: error.message,
          className: "custom-class",
          duration: 2,
        });
        setLoading(false);
      });
  };

  // handle buy box

  const buyBox = async () => {
    setLoading(true);
    if (boughtBox === numMaxBox) {
      message.warning({
        type: "warning",
        content: "The maximum limit!",
        className: "custom-class",
        duration: 2,
      });
      setLoading(false);
      return;
    }

    await actions
      .genSign(state.inoItem?.typeId, account)
      .then(async (res: any) => {
        if (!res?.data?.data && state.inoItem?.timelineBuyType === 2) {
          message.warning({
            type: "warning",
            content: res.data?.message,
            className: "custom-class",
            duration: 2,
          });
          setLoading(false);
          return;
        }
        const sig = res?.data?.data;
        try {
          await _buyMysteryBox(
            contractBox,
            price,
            state.inoItem?.campaignId,
            itemBox.boxId,
            quantity,
            sig,
            state.inoItem?.timelineBuyType,
            state.inoItem?.priceType
          )
            .then(async (resBuy) => {
              if (resBuy.hash) {
                await actions
                  .buyBoxAPI({
                    inoId: state.inoItem?.id,
                    typeId: state.inoItem?.typeId,
                    quantity,
                    ownerAddress: account,
                    tnxHash: resBuy?.hash,
                  })
                  .then((resApiBuy: any) => {
                    if (!resApiBuy.data?.succeeded) {
                      message.warning({
                        type: "warning",
                        content: resApiBuy?.data?.message,
                        className: "custom-class",
                        duration: 2,
                      });
                      setLoading(false);
                      return;
                    } else {
                      resBuy.wait().then((res1: any) => {
                        if (res1 !== null) {
                          setBoughtBox(parseInt(boughtBox) + quantity);
                          actions
                            .updateBoxAPI({
                              typeId: state.inoItem?.typeId,
                              ownerAddress: account,
                              tnxHash: resBuy?.hash,
                            })
                            .then((resUpdateBox: any) => {
                              if (resUpdateBox.data?.succeeded) {
                                message.success({
                                  type: "success",
                                  content: "Success!!!",
                                  className: "custom-class",
                                  duration: 2,
                                });
                                setLoading(false);

                                window.location.reload();
                              } else {
                                message.error({
                                  type: "error",
                                  content: "Update Box API errol!!!",
                                  className: "custom-class",
                                  duration: 2,
                                });
                                setLoading(false);
                                console.log(
                                  "error Update Box API...",
                                  resUpdateBox
                                );
                                return;
                              }
                            })
                            .catch((err: any) => {
                              message.warning({
                                type: "warning",
                                content: err,
                                className: "custom-class",
                                duration: 2,
                              });
                              setLoading(false);
                              console.log("error...", err);
                              return;
                            });
                        }
                      });
                    }
                  })
                  .catch((err: any) => {
                    message.warning({
                      type: "warning",
                      content: err,
                      className: "custom-class",
                      duration: 2,
                    });
                  });
              } else {
                setLoading(false);
                // console.log("error no hash...", resBuy);
                message.warning({
                  type: "warning",
                  content: resBuy?.error?.data?.message,
                  className: "custom-class",
                  duration: 2,
                });
                // showErr(resBuy.code);
                return;
              }
            })
            .catch((error: any) => {
              message.warning({
                type: "warning",
                content: error?.message,
                className: "custom-class",
                duration: 2,
              });
              console.log("error by box form SC or wallet...", error);
              setLoading(false);
            });
        } catch (error: any) {
          message.warning({
            type: "warning",
            content: error,
            className: "custom-class",
            duration: 2,
          });
          setLoading(false);
          console.log("Error from function _buyMysteryBox()...", error);
        }
      });
  };

  // buyBox Old
  // const buyBox = () => {
  //   // TODO
  //   setLoading(true);
  //   actions
  //     .genSign(state.inoItem.typeId, account)
  //     .then((res: any) => {
  //       if (!res.data.data && state.inoItem.timelineBuyType === 2) {
  //         message.warning(res.data?.message);
  //         setLoading(false);
  //         return;
  //       }

  //       if (boughtBox === numMaxBox) {
  //         message.warning("The maximum limit!");
  //         setLoading(false);
  //         return;
  //       }
  //       const sig = res.data.data;
  //       try {
  //         _buyMysteryBox(
  //           contractBox,
  //           price,
  //           state.inoItem.campaignId,
  //           itemBox.boxId,
  //           quantity,
  //           sig,
  //           state.inoItem.timelineBuyType,
  //           state.inoItem.priceType
  //         )
  //           .then((resBuy) => {
  //             if (resBuy.hash) {
  //               actions
  //                 .buyBoxAPI({
  //                   inoId: state.inoItem.id,
  //                   typeId: state.inoItem.typeId,
  //                   quantity,
  //                   ownerAddress: account,
  //                   tnxHash: resBuy.hash,
  //                 })
  //                 .then((resApiBuy: any) => {
  //                   if (!resApiBuy.data.succeeded) {
  //                     message.warning(resBuy?.data?.message);
  //                   }
  //                 });

  //               // addTransaction(resBuy, {
  //               //   summary: "Buy mystery box successfully!",
  //               //   attr1: "buymysterybox",
  //               // });
  //               setLoading(false);

  //               // wait tx success then call api update
  //               setTimeout(() => {
  //                 setBoughtBox(parseInt(boughtBox) + quantity);
  //                 actions.updateBoxAPI({
  //                   typeId: state.inoItem.typeId,
  //                   ownerAddress: account,
  //                   tnxHash: resBuy.hash,
  //                 });
  //                 setTimeout(() => {
  //                   setLoading(false);
  //                   message.success("Success!!!");
  //                   window.location.reload();
  //                 }, 20);

  //                 // if (state.inoItem.priceType !== PRICE_TYPE.BNB) {
  //                 //    setApproveJoin(false)
  //                 // }
  //               }, 10000);
  //             } else {
  //               setLoading(false);
  //               message.warning(resBuy?.data?.message);
  //             }
  //           })
  //           .catch((error) => {
  //             setLoading(false);
  //           });
  //       } catch (error) {
  //         setLoading(false);
  //         // console.log('error...', error)
  //       }
  //     })
  //     .catch((error: any) => {
  //       setLoading(false);
  //     });
  // };

  const handleCompleteTime = () => {
    // setTimeout(() => {
    // actions.getDetailINO(param)
    // window.location.reload();
    // }, 2000);
  };

  let param = {
    keywork: pathHash,
    ownerAddress: account,
    id: 0,
  };

  useEffect(() => {
    actions.getDetailINO(param);
  }, [account]);

  const maxQuantity = () => {
    setQuantity(numMaxBox);
  };

  const roundLeft = (
    <>
      <div className="content-left">
        <div className="box-img">
          {loadingSpin ? (
            <Spin size="large" />
          ) : (
            <img
              // src="/images/imgino/img-box.png"
              src={img}
              alt=""
              className="rotate-animation"
            />
          )}
        </div>
        <div className="content-info-user">
          <ul className="list-info-user">
            <li>
              <div className="swap">
                <span className="txt-top">Registered users</span>
                <span className="txt-bottom">
                  {state.inoItem?.registeredUser}
                </span>
              </div>
            </li>
            <li>
              <div className="swap">
                <span className="txt-top">Total Ordered</span>
                <span className="txt-bottom">{state.inoItem?.totalOrder}</span>
              </div>
            </li>
            <li>
              <div className="swap">
                <span className="txt-top">Your Ordered</span>
                <span className="txt-bottom">{state.inoItem?.yourOrder}</span>
              </div>
            </li>
            <li className="status-ino">
              {state.inoItem?.status === STATUS.PENDING ||
                state.inoItem?.status === STATUS.COMING ? (
                <div className="swap">
                  <span className="txt-top">
                    {state.inoItem?.timeLineStatus} STARTS IN:
                  </span>
                  <span className="txt-bottom">
                    <Countdown
                      date={getProgressTime(state.inoItem.openDate)}
                      renderer={rendererCountDown}
                      onComplete={handleCompleteTime}
                    />
                  </span>
                </div>
              ) : (
                <>
                  {state.inoItem?.status === STATUS.OPEN ? (
                    <div className="swap">
                      <span className="txt-top">
                        {state.inoItem?.timeLineStatus} CLOSE IN:
                      </span>
                      <span className="txt-bottom">
                        <Countdown
                          date={getProgressTime(state.inoItem.closeDate)}
                          renderer={rendererCountDown}
                          onComplete={handleCompleteTime}
                        />
                      </span>
                    </div>
                  ) : (
                    <div className="text-close">
                      <span className="txt-bottom-close">CLOSED</span>
                    </div>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );

  const fillNetwork = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return "BSC";
        break;
      case NETWORK.Poly:
        return "POLY";
        break;
      case NETWORK.Ethereum:
        return "ERC";
        break;
    }
  };

  const fillIconChain = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return (
          <img
            src="/images/imgino/icon-bnb-ino.png"
            alt=""
            className="rotate-animation"
          />
        );
        break;
      case NETWORK.Poly:
        return (
          <img
            src="/images/imgino/icon-bnb-ino.png"
            alt=""
            className="rotate-animation"
          />
        );
        break;
      case NETWORK.Ethereum:
        return (
          <img
            src="/images/imgino/icon-bnb-ino.png"
            alt=""
            className="rotate-animation"
          />
        );
        break;
    }
  };

  const roundRight = (
    <>
      <div className="content-right">
        <p className="desc-name">{state.inoItem?.name}</p>
        <div className="title-name">{state.inoItem?.name} INO</div>
        <div className="box-current-price">
          <div className="title-price">Current Price</div>
          <div className="price-box-round">
            <div className="img-bnb">
              <img
                src={state.inoItem.symbolUrl}
                alt=""
                className="rotate-animation"
              />
            </div>
            <div className="number-price">
              {state.inoItem.price}{" "}
              <span className="symbol-price">{state.inoItem.symbol}/Box</span>
            </div>
          </div>
          <ul className="list-price-rank">
            <li>
              <span className="txt-l">Total Rate:</span>
              <span className="txt-r">{state.inoItem.totalSale} Box</span>
            </li>
            <li>
              <span className="txt-l">Supported:</span>
              <span className="txt-r">
                {fillIconChain(state.inoItem.network)}{" "}
                {fillNetwork(state.inoItem.network)}
              </span>
            </li>
            <li>
              <span className="txt-l">Min Rank:</span>
              <span className="txt-r">
                {state.inoItem.minRank === 0 ? "" : "Tier:"}{" "}
                {state.inoItem.minRank === 0 ? "N/A" : state.inoItem.minRank}{" "}
              </span>
            </li>
          </ul>
          <div className="title-price">
            Current{" "}
            <span className="symbol-now">
              {" "}
              <img
                src={state.inoItem.symbolUrl}
                alt=""
                className="rotate-animation"
              />{" "}
              {state.inoItem.symbol}
            </span>
          </div>
          <div className="box-name-round">
            {state.inoItem.boxTypes &&
              state.inoItem.boxTypes.map((item: any, i: any) => (

                <ItemBoxInfo
                  itemBoxActive={itemBox}
                  setItemBox={setItemBox}
                  setNewBoxId={setNewBoxId}
                  setLoadingSpin={setLoadingSpin}
                  account={account}
                  campaignId={state.inoItem.campaignId}
                  contractBox={contractBox}
                  item={item}
                  pathHash={pathHash}
                />
              ))}
          </div>

          {account ? (
            <>
              {state.inoItem.buyStatus === 1 ? (
                <>
                  <div className="ino-amount">
                    <div className="amount-right">
                      <div className="bought-title">Bought / Max</div>
                      <div className="bought-num">
                        {/* 0/9 */}
                        {boughtBox} /{" "}
                        {state.inoItem?.whitelist !== null
                          ? state.inoItem.whitelist?.maxOrder
                          : "0"}
                      </div>
                    </div>

                    <div className="amount-left">
                      <div className="amount-title">Amount</div>
                      <div className="gr-btn">
                        <button className="btn-max" onClick={maxQuantity}>
                          Max
                        </button>
                        <NumericInput
                          min={0}
                          max={numMaxBox}
                          // max={quantity <= boughtBox || boughtBox >= numMaxBox}
                          strict
                          value={quantity}
                          onChange={(e: any) => {

                            setQuantity(e);
                          }}
                          mobile
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="ino-progress">
                <div className="text-progress">
                  <div className="swap-progress"> SWAP PROGRESS</div>
                  <div className="percent-num">{percentNum}%</div>
                </div>
                <Progress percent={percentNum} status="active" />
              </div>
              <div className="number-box">
                {" "}
                {totalOrder}/{totalAllBox} Box
              </div>

              {/* <button
                type="button"
                className="btn btn-join"
                onClick={() => buyBox()}
                // disabled={quantity <= 0 || hasPendingTransactions || account === undefined}
                // disabled={quantity <= 0 || account === undefined || isLoading}
              >
                {isLoading ? (
                  <>
                    Buy now <i className="fa fa-spinner fa-spin" />
                  </>
                ) : (
                  " Buy now"
                )}
              </button> */}

              <div className="button-ino-details">
                {state.inoItem.timelineBuyType === 4 ? (
                  <div className="finished">
                    <span>Finished</span>
                  </div>
                ) : (
                  <>
                    {state.inoItem.buyStatus === 1 ? (
                      <>
                        {state.inoItem.whitelist === null ? (
                          <button type="button" className="btn btn-join">
                            You're not whitelist
                          </button>
                        ) : (
                          <>
                            {!isApproveJoin && state.inoItem.priceType === 1 ? (
                              <button
                                type="button"
                                className="btn btn-join"
                                onClick={() => approve()}
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <>
                                    Approve{" "}
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  "Approve"
                                )}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-join"
                                onClick={() => buyBox()}
                                // disabled={quantity <= 0 || hasPendingTransactions || account === undefined}
                                disabled={
                                  quantity <= 0 ||
                                  account === undefined ||
                                  isLoading
                                }
                              >
                                {isLoading ? (
                                  <>
                                    Buy now{" "}
                                    <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : (
                                  " Buy now"
                                )}
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {state.inoItem.timelineBuyType >= 2 ? (
                          <button type="button" className="btn btn-join">
                            You're not whitelist
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => _openLink()}
                            disabled={state.inoItem.applyWhitelistUrl === null}
                            className="btn btn-join"
                          >
                            {state.inoItem.applyWhitelistUrl === null
                              ? "Buy now"
                              : "Apply Whitelist"}
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );

  let navigate = useNavigate();
  const clickBack = () => {
    navigate(`${"/InoLaunchPad"}`);
  };

  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Please waiting...</p>
    </>
  );



  return (
    <>
      {/* @ts-ignore this lib is incompatible with react18  */}
      {/* <Modal
        onCancel={() => setOtherChain(false)}
        className="modal-beta-show"
        title="Alert!"
        visible={isOtherChain}
      >
        <>
          <div className="content-modal-show">
            <p className="desc-beta">
              This one choose network{" "}
              {state.inoItem && CHAINID_FULLNAME[state.inoItem.network]}
            </p>
          </div>
        </>
      </Modal> */}
      {chainHeader(chainId) === "bep" ? (
        <>
          <div
            className="main-details-ino"
            style={{
              background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
            }}
          >
            <div className="container">
              <div className="content-details-ino">
                <div className="breadcrum-swap">
                  <ul className="list-breadcrum">
                    <li className="backIno" onClick={clickBack}>
                      Project
                    </li>
                    <li>{">"}</li>
                    <li>{state.inoItem.name}</li>
                  </ul>
                </div>
                <div className="table-ino-details">
                  <div className="columns">
                    <div className="colum w-8">{roundLeft}</div>
                    <div className="colum w-4">{roundRight}</div>
                  </div>
                </div>
                <INO_BOTTOM content={state.inoItem} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorPage />
        </>
      )}
    </>
  );
};
export default InoDetails;
