/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHookAirdrop } from "./StoreAirdrop";
import bgMainheader from "./images/banner-ino.png";
import { useNavigate, useLocation } from "react-router-dom";
import Upcoming from "./Upcoming";
import Active from "./Active";
import Ended from "./Ended";
import Joined from "./Joined";
import { Tabs, Switch, Select, Input } from "antd";
import "./style.css";
import { STATUS, NETWORK } from "./index.d";
import { useAccount } from "wagmi";

const TabsAirdrop = () => {
  const [state, actions]: any = useHookAirdrop();
  const { address } = useAccount();

  let account = address
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { Search } = Input;

  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );
  const ether = (
    <>
      <img src="/images/ether.png" alt="" />
    </>
  );
  const poly = (
    <>
      <img src="/images/poly.png" alt="" />
    </>
  );
  const bsc = (
    <>
      <img src="/images/bnb-bnb-logo.png" alt="" />
    </>
  );

  const fillIconChain = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return bsc;
        break;
      case NETWORK.Poly:
        return poly;
        break;
      case NETWORK.Ethereum:
        return ether;
        break;
    }
  };

  const fillNetwork = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return "Binance Smart Chain";
        break;
      case NETWORK.Poly:
        return "Polygon";
        break;
      case NETWORK.Ethereum:
        return "Ethereum";
        break;
    }
  };

  const ContentBannerAirdrop = (data: any) => {
    return (
      <>
        <div className="top-content-ino">
          <div className="title-ino">BSCS Airdrop Projects</div>
          <div className="balance-ino">
            <p className="desc">
              The Ultimate Incubation Hub on Binance Smart Chain, <br />
              Ethereum and Polygon
            </p>
          </div>
        </div>
      </>
    );
  };
  let location = useLocation();

  let navigate = useNavigate();

  const [defaultKey, setDefaultKey] = useState("Active");
  const [activeTab, setActiveTab] = useState("Active");
  const pathTabsLive = location.search.split("?");

  const tabBox = pathTabsLive[1] === undefined ? "Active" : pathTabsLive[1];
  function callback(key: any) {
    setDefaultKey(key);

    navigate(`${"/Airdrop"}?${key}`);
  }
  useEffect(() => {
    if (tabBox) {
      setDefaultKey(tabBox);
      setActiveTab(tabBox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabBox]);

  useEffect(() => {
    document.title = "Airdrops - BSCS (BSCS)";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  const [accountAddress, setAccountAddress] = useState(
    localStorage.getItem("currentAddress")
  );

  useEffect(() => {
    setAccountAddress(localStorage.getItem("currentAddress"));
  }, [account]);

  const [searchChain, setSearchChain] = useState("bep");
  const handleChangeSort = (value: any) => {
    setSearchChain(value);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleChangeSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };



  const listOpen =
    state.airdropList?.length > 0 &&
    state.airdropList?.filter(
      (airdropItem: any) =>
        airdropItem.status === STATUS.OPEN &&
        (searchChain !== "all" && searchChain !== "Sort by"
          ? airdropItem.network === searchChain
          : true) &&
        airdropItem.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  const listClose =
    state.airdropList?.length > 0 &&
    state.airdropList?.filter(
      (airdropItem: any) =>
        airdropItem.status === STATUS.CLOSE &&
        (searchChain !== "all" && searchChain !== "Sort by"
          ? airdropItem.network === searchChain
          : true) &&
        airdropItem.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  const listJoined =
    state.airdropList?.length > 0 &&
    state.airdropList?.filter(
      (airdropItem: any) =>
        airdropItem.status === STATUS.JOINED &&
        (searchChain !== "all" && searchChain !== "Sort by"
          ? airdropItem.network === searchChain
          : true) &&
        airdropItem.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <>
      <div
        className="main-ino-launch"
        style={{ background: `url(${bgMainheader}) 0% 50% / cover no-repeat` }}
      >
        <div className="container airdrop">
          <div className="content-ino-launch-top">
            <ContentBannerAirdrop />
          </div>
          <div className="main-tabs-ido air-tab-button">
            <div className="filter-swap cus-airdrop">
              <div className="box-search-round">
                <div className="search-project">
                  <Search
                    placeholder="Search for project"
                    type="text"
                    onChange={handleChangeSearchQuery}
                  />
                  <div className="icon-search">
                    <img src="/images/imgido/search-normal.png" alt="" />
                  </div>
                </div>
                {/* <div className="filter-project">
                  <Select
                    defaultValue="Sort by"
                    style={{ width: 125 }}
                    onChange={handleChangeSort}
                  >
                    <Option value={"all"}>All Network</Option>
                    <Option value={"bep"}>BNB</Option>
                    <Option value={"erc"}>ETH</Option>
                    <Option value={"poly"}>POLY</Option>
                    <Option value={"avax"}>AVAX</Option>
                  </Select>
                </div> */}
              </div>
            </div>
            <Tabs
              className="tabs-ido air"
              activeKey={defaultKey}
              onChange={callback}
            >
              <TabPane tab="Active" key="Active">
                <Active listOpen={listOpen} />
              </TabPane>
              <TabPane tab="Ended" key="Ended">
                <Ended listClose={listClose} />
              </TabPane>
              <TabPane tab="Joined" key="Joined">
                <Joined listJoined={listJoined} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabsAirdrop;
