/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState, memo } from "react";
import { Modal, message } from "antd";

import { isRefundedRC, claimTokens, isClaimed, totalClaimAble } from "./utils";
import {
  useContract,
  useIdoTokenClaimContract,
} from "../../../../hooks/useContract";
import { useHookIDO } from "../../StoreIdo";
import "./style.css";
import { _claimTokens, _isRefundedRC, _showClaimBtn } from "../../utilsIDO";
import abiClaimOneButton from "../../Abi/abiClaimNew.json";
import { useAccount, useChainId } from "wagmi";


const ItemIdo = ({ account, item, isShowButton, tokenDecimals, usdDecimals }: any) => {
  const [state, actions]: any = useHookIDO();
  const [isClaim, setIsClaim] = useState(false);
  const [isClaimDaily, setIsClaimDaily] = useState(false);
  const [claimAble, setClaimAble] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const idoTokenClaimContract = useIdoTokenClaimContract(
    item.contractAddress,
    item
  );
  const [isLoadingClaim, setLoadingClaim] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [isShowClaim, setIsShowClaim] = useState(false);
  const idoTokenClaimOneBtnContract = useContract(
    item.contractAddress,
    abiClaimOneButton
  );

  const handleClaim = async () => {
    try {
      if (idoTokenClaimContract !== null && item.claimRound < 1000) {
        setLoadingClaim(true);
        actions.changeRefundStatus(true);
        await claimTokens(idoTokenClaimContract, item, tokenDecimals).then(
          () => {
            setTimeout(() => {
              setIsClaim(true);
              setIsClaimDaily(false);
              setLoadingClaim(false);
            }, 10000);
          }
        );
      }
    } catch (error: any) {
      setLoadingClaim(false);
      actions.changeRefundStatus(false);
      if (error?.data) {
        message.error(error.data.message);
      }
    }
  };

  const handleClaimOneBtn = async () => {
    setIsLoading(true);
    try {
      await _claimTokens(idoTokenClaimOneBtnContract, item, tokenDecimals, usdDecimals)
        .then((res: any) => {
          res.wait().then((res1: any) => {
            if (res1 !== null) {
              setIsShowClaim(false);
              setIsClaim(true);
              setIsLoading(false);
            }
          });
        })
        .catch((err: any) => {
          message.error(err.message);
          setIsLoading(false);
        });
    } catch (error: any) {
      if (error?.data) {
        message.error(error.data.message);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      if (idoTokenClaimContract !== null && item.claimRound < 1000) {
        isClaimed(idoTokenClaimContract, account, item).then((res) => {
          setIsClaim(res);
          setIsClaimDaily(res);
          setIsShowClaim(!res);
        });
      }
    } catch (error) {
      setIsClaim(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, idoTokenClaimContract, item.claimRound]);

  useEffect(() => {
    try {
      if (
        idoTokenClaimContract !== null &&
        item.claimRound !== 100 &&
        item.claimRound < 1000
      ) {
        isRefundedRC(idoTokenClaimContract, account).then((res) => {
          setIsRefund(res);
        });
      }
      if (
        idoTokenClaimOneBtnContract !== null &&
        item.claimRound !== 100 &&
        item.claimRound >= 1000
      ) {
        _isRefundedRC(idoTokenClaimOneBtnContract, account).then((res) => {
          setIsRefund(res);
        });
      }
    } catch (error) {
      setIsRefund(false);
    }
  }, [
    account,
    idoTokenClaimContract,
    idoTokenClaimOneBtnContract,
    item.claimRound,
  ]);

  useEffect(() => {
    try {
      if (idoTokenClaimContract !== null && item.claimRound === 100) {
        totalClaimAble(idoTokenClaimContract, account, tokenDecimals).then(
          (res) => {
            setClaimAble(res);
          }
        );
      }
    } catch (error) {
      // TODO
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, idoTokenClaimContract, item.claimRound]);

  useEffect(() => {
    try {
      if (
        account &&
        idoTokenClaimOneBtnContract !== null &&
        item.claimRound >= 1000
      ) {
        _showClaimBtn(
          idoTokenClaimOneBtnContract,
          account,
          item.claimToken,
          tokenDecimals
        ).then((res) => {
          setIsShowClaim(res);
          setIsClaim(!res);
        });
      }
    } catch (e) {
      // TODO
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, idoTokenClaimOneBtnContract, item.claimRound]);

  return (
    <>
      <tr key={item.claimRound}>
        <td>
          {item.claimRound !== 100
            ? item.claimToken
            : claimAble > 0
              ? Number(claimAble).toFixed(4)
              : item.claimToken}{" "}
        </td>
        <td className="timeClaimable">
          {item.claimTime !== null ? item.claimTime : "TBA"}
        </td>
        <td>
          {isRefund
            ? "100%"
            : item.percentage !== null
              ? item.percentage
              : "TBA"}
        </td>
        {item.claimRound === 100 ? (
          <td>
            {!isClaimDaily ? (
              <>
                <button type="button" className="btn-waiting-cus">
                  {item.claimToken > 0 ? "Waiting" : "Finished"}
                </button>
              </>
            ) : isLoadingClaim ? (
              <div>
                <button type="button" className="btn-claim-after">
                  Waiting <i className="fa fa-spinner fa-spin" />
                </button>
              </div>
            ) : (
              <div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleClaim()}
                    className="btn-claim-after"
                  >
                    Claim
                  </button>
                </div>
              </div>
            )}
          </td>
        ) : (
          <td>
            {(item.claimedType === 2 || isClaim || !isShowClaim) &&
              isShowButton ? (
              <button
                type="button"
                className="btn-claimed-cus btn-disible-cus btn-bsc-disabled"
              >
                Claimed
              </button>
            ) : isLoadingClaim && isShowButton ? (
              <div>
                <button type="button" className="btn-claim-after">
                  Waiting <i className="fa fa-spinner fa-spin" />
                </button>
              </div>
            ) : (item.claimedType === 1 || isRefund) && isShowButton ? (
              <button
                type="button"
                className="btn-refunded-cus btn-disible-cus btn-bsc-disabled"
              >
                Refunded
              </button>
            ) : !isShowButton ? (
              <p>TBA</p>
            ) : item.claimRound < 1000 &&
              !isClaim &&
              item.signToken !== null &&
              isShowButton ? (
              <div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleClaim()}
                    className="btn-claim-after"
                  >
                    Claim
                  </button>
                </div>
              </div>
            ) : item.claimRound >= 1000 && isShowButton ? (
              <div>
                <button
                  className="claim-modal"
                  type="button"
                  onClick={() => handleClaimOneBtn()}
                  disabled={isLoading}
                >
                  {!isShowClaim ? (
                    "Claimed"
                  ) : (
                    <>
                      {isLoading ? (
                        <>
                          <i className="fa fa-spinner fa-spin" /> Claim
                        </>
                      ) : (
                        "Claim"
                      )}
                    </>
                  )}
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="btn-claimed-cus btn-disible-cus btn-bsc-disabled"
              >
                Waiting
              </button>
            )}
          </td>
        )}
      </tr>
    </>
  );
};

const ModalClaimedAfter = memo((props: any) => {
  const objIdo = props.dataFromParent;
  const [state, actions]: any = useHookIDO();
  const { address } = useAccount();

  const chainId = useChainId()
  let account = address
  const is_network_bep = objIdo && objIdo.network === "bep";
  const is_network_erc = objIdo && objIdo.network === "erc";
  const is_network_poly = objIdo && objIdo.network === "poly";
  const is_network_okc = objIdo && objIdo.network === "okc";
  const is_network_arb = objIdo && objIdo.network === "arb";
  const is_network_opbnb = objIdo && objIdo.network === "opbnb";
  const is_network_base = objIdo && objIdo.network === "base";
  const showBtnClaim =
    (is_network_bep && chainId === 56) ||
    (is_network_erc && chainId === 1) ||
    (is_network_poly && chainId === 137) ||
    (is_network_okc && chainId === 66) ||
    (is_network_arb && chainId === 42161) ||
    (is_network_opbnb && chainId === 204) ||
    (is_network_base && chainId === 8453)

  const handleOk = () => {
    actions.changeOpenModalClaim(false);
  };
  const handleCancel = () => {
    actions.changeOpenModalClaim(false);
  };

  let claimbles = [];
  for (let i = 0; i < objIdo?.details?.length; i++) {
    if (objIdo?.details[i]?.id === state.claimIdoId) {
      claimbles = objIdo?.details[i]?.allocation.claimbles;
    }
  }

  const isHide = state.claimIdoId !== -1;
  return (
    <>
      {/* @ts-ignore this lib is incompatible with react18 */}
      <Modal
        className="modal-claimed-after"
        title="Claim Progress"
        visible={isHide}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="bsc-p-launchpad_detail-allocations">
          <table>
            <tr>
              <th>Claimable</th>
              <th>Claim Date</th>
              <th>%</th>
              <th>Status</th>
            </tr>
            {claimbles !== null ? (
              claimbles.map((item: any, i: any) => (
                <ItemIdo
                  account={account}
                  item={item}
                  isShowButton={showBtnClaim}
                  tokenDecimals={objIdo.decimals}
                  usdDecimals={objIdo.decimalUsd}
                />
              ))
            ) : (
              <div className="bsc-p-launchpad_detail-allocations-empty">
                <span>Empty</span>
              </div>
            )}
          </table>
        </div>
      </Modal>
    </>
  );
});
export default ModalClaimedAfter;
