import React, { forwardRef, memo, useCallback } from "react";

import { InputBSCProps, InputBSCRef } from "./index.d";
import "./index.css";

const Input = memo<InputBSCProps>(
  forwardRef<InputBSCRef, InputBSCProps>((props) => {
    const { left, right, value, placeholder, change, type = "number" } = props;
    const handleChange = useCallback(
      (e: any) => {
        if (change) {
          change(e);
        }
      },
      [change]
    );
    return (
      <div placeholder={placeholder} className="bsc-input manager_input">
        {left && <div className="bsc-input-left">{left}</div>}
        <input
          className="input_manager"
          {...(value ? { value } : {})}
          onChange={handleChange}
          type={type}
          placeholder={placeholder || "0"}
        />

        {right && (
          <div className="bsc-input-right" style={{ top: "20px"}}>
            {right}
          </div>
        )}
      </div>
    );
  })
);
export * from ".";
export default Input;
