/* eslint-disable prefer-destructuring */

/* eslint-disable operator-assignment */
/* eslint-disable no-loop-func */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import abiStakePoolNFT from "../../../abi/abiStakePoolNFT.json";
import { useContract } from "../../../hooks/useContract";
// import { store } from 'react-notifications-component'
// import addNotify from 'components/Notify/addNotify'
import ModalStaking from "./ModalStaking";
import ModalUnStaking from "./ModalUnStaking";
import { useHookInoPoolDetails } from "./Store-Ino-Pool-Details";
import "./style.css";
import bgMainheader from "../images/banner-ino.png";
// import UnlockButton from '../../components/ConnectWalletButton'
import {
  _getUnExpiredTokens,
  _userPeriodAmount,
  _stakeDetails,
  _userStakeIds,
  _userStakeIndex,
  _harvest,
  _totalUserStaking,
  _getExpiredTokens,
} from "./utils";
import { useAccount } from "wagmi";


const ItemPeriod = ({ contractPool, item }: any) => {
  const contractStakePoolNFT = useContract(item.poolContract, abiStakePoolNFT);
  // const contractStakePoolNFT = useContract('0xaD7Fc30F2be8A9e469DF5c5a1043c7FB7E42E61C', abiStakePoolNFT)
  const { address } = useAccount();
  let account = address

  const [staked, setStaked] = useState(0);

  // console.log('item: ', item)

  useEffect(() => {
    // console.log('contractStakePoolNFT: ',contractStakePoolNFT)
    if (contractStakePoolNFT && account && item.period) {
      _userPeriodAmount(
        contractStakePoolNFT,
        account,
        `${item.period.toString()}`
      ).then((res) => {
        // console.log('ressss1111: ', res.toString() / 1e18, contractStakePoolNFT)
        setStaked(res.toString() / 1e18);
      });
    }
  }, [account, item]);
  return (
    <tr>
      <td> {(item.period / 86400).toFixed(0)} Days</td>
      <td className="main-color"> {item.apr} %</td>
      <td className="main-color">{staked.toFixed(2)}</td>
    </tr>
  );
};

const ItemExpired = (data: any) => {
  const totalListSc = data.totalListSc;
  const { address } = useAccount();
  let account = address

  const [state, actions] = useHookInoPoolDetails();
  const [amountTokenExpired, setAmountTokenExpired] = useState(0);
  const [isLoadingHarvest, setLoadingHarvest] = useState(false);
  const [listHarvest, setListHarvest]: any = useState([]);

  useEffect(() => {
    let listSCHarvest: any = [];
    let totalHarvest: any = 0;
    if (totalListSc.length !== 0 && account) {
      for (let i = 0; i < totalListSc?.length; i++) {
        _getExpiredTokens(totalListSc[i], account).then((res) => {
          // console.log('res havet=====: ', res.toString()/1e18)
          if (res.toString() > 0) {
            listSCHarvest.push(totalListSc[i]);
            // setAmountTokenExpired(amount => amount + res.toString()/1e18)
            totalHarvest = totalHarvest + res.toString() / 1e18;
          }
          setListHarvest(listSCHarvest);
          setAmountTokenExpired(totalHarvest);
        });
      }
      // setListHarvest(listSCHarvest)
      // setAmountTokenExpired(totalHarvest)
    }
  }, [totalListSc?.length, account]);

  // console.log('amountTokenExpired: ', amountTokenExpired)
  // console.log('listHarvest: ', listHarvest)

  const harvest = async () => {
    if (listHarvest) {
      try {
        setLoadingHarvest(true);
        await listHarvest.forEach((obj: any) => {
          _harvest(obj).then((res) => {
            // console.log('ress harvest: ', res)
            if (res.hash) {
              setTimeout(() => {
                message.success("Harvest successfully! ");
                setLoadingHarvest(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }, 15000);
            } else {
              setLoadingHarvest(false);
              // message.warning(res?.data?.message);
            }
          });
        });
      } catch (error) {
        setLoadingHarvest(false);
      }
    }
  };
  return (
    <>
      <div className="colum">
        <div className="item">
          <h4 className="title-txt">Expired</h4>
          <div className="box-img">
            <img
              className="box-img-icon"
              src={state.inoListPoolDetails?.logoUrl}
              alt=""
            />
            <div className="img-small-bot">
              <img src={state.inoListPoolDetails?.earnLogoUrl} alt="" />
            </div>
          </div>

          <div className="balance">{amountTokenExpired?.toFixed(2)}</div>
          <div className="name-token">
            {state.inoListPoolDetails?.earnSymbol}
          </div>
          <div className="button-select cus">
            {account === null ? (
              <div className="btn-wrap btn-unlock">
                {/* <UnlockButton /> */}
                {account}
              </div>
            ) : (
              <button
                onClick={() => harvest()}
                type="button"
                disabled={amountTokenExpired <= 0 || isLoadingHarvest}
                className="btn-select"
              >
                Harvest
                {isLoadingHarvest ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  ""
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ItemStaking = (data: any) => {
  const totalListSc = data.totalListSc;
  const { address } = useAccount();
  let account = address

  const [state, actions] = useHookInoPoolDetails();

  const showModalStake = () => {
    actions.updateShowStake(true);
  };
  const showModalUnStake = () => {
    actions.updateShowUnStake(true);
  };

  const [amountTokenStaking, setAmountTokenStaking] = useState(0);
  const [isUnstake, setIsUnstake] = useState(false);
  const [totalStake, setTotalStake] = useState(0);

  console.log('isUnstake', isUnstake)

  useEffect(() => {
    let checkUnstake: any = false;
    let totalStaked: any = 0;
    if (totalListSc.length !== 0 && account) {
      // console.log('totalListSc: ', totalListSc)
      for (let i = 0; i < totalListSc.length; i++) {
        _getUnExpiredTokens(totalListSc[i], account).then((res) => {
          if (res.toString() > 0) {
            // console.log('res.toString(): ', res.toString())
            // setTotalStake(total =>total+ res.toString()/1e18)
            checkUnstake = true;
            totalStaked = totalStaked + res.toString() / 1e18;
          }
          setIsUnstake(checkUnstake);
          setTotalStake(totalStaked);
        });
      }
    }
  }, [account, totalListSc?.length]);

  return (
    <>
      <div className="colum">
        <div className="item">
          <h4 className="title-txt">Staking</h4>
          {/* <div className="box-img">
            <img src={state.inoListPoolDetails.logoUrl} alt="" />
          </div> */}
          <div className="box-img">
            <img
              className="box-img-icon"
              src={state.inoListPoolDetails?.logoUrl}
              alt=""
            />
            <div className="img-small-bot">
              <img src={state.inoListPoolDetails?.earnLogoUrl} alt="" />
            </div>
          </div>
          <div className="balance">{totalStake.toFixed(2)}</div>
          <div className="name-token">
            {state.inoListPoolDetails?.depositSymbol}
          </div>
          <div className="button-select cus">
            {account === null ? (
              <div className="btn-wrap btn-unlock">
                {/* <UnlockButton /> */}
                {account}
              </div>
            ) : (
              <div className="btn-stake-pool-v2">
                <button
                  type="button"
                  // disabled={amountTokenStaking <= 0}
                  disabled={!isUnstake}
                  className="btn-select unstake"
                  onClick={showModalUnStake}
                >
                  {" "}
                  UnStake
                </button>
                <button
                  type="button"
                  className="btn-select"
                  onClick={showModalStake}
                  disabled={state.inoListPoolDetails.isFinish}
                >
                  {" "}
                  Stake
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalStaking
        amountTokenStaking={amountTokenStaking}
        setAmountTokenStaking={setAmountTokenStaking}
      />

      <ModalUnStaking totalListSc={totalListSc} />
    </>
  );
};

const StakePoolDetails = () => {
  const [state, actions] = useHookInoPoolDetails();
  let location = useLocation();
  let navigate = useNavigate();
  const pathHash = location.pathname.split("/");
  const PoolKey = pathHash[3];
  const param = {
    keyword: PoolKey,
  };

  useEffect(() => {
    actions.getInfoInoPoolDetails(param);
  }, []);

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  let totalListSc: any = [];

  const test = state.inoListPoolDetails?.poolAprs;

  const addSC1 = test && test[0]?.poolContract;
  const addSC2 = test && test[1]?.poolContract;
  const addSC3 = test && test[2]?.poolContract;
  const addSC4 = test && test[3]?.poolContract;
  const addSC5 = test && test[4]?.poolContract;
  const addSC6 = test && test[5]?.poolContract;
  const addSC7 = test && test[6]?.poolContract;
  const addSC8 = test && test[7]?.poolContract;
  const addSC9 = test && test[8]?.poolContract;
  const addSC10 = test && test[9]?.poolContract;

  const contractStakePoolNFT1 = useContract(addSC1, abiStakePoolNFT);
  const contractStakePoolNFT2 = useContract(addSC2, abiStakePoolNFT);
  const contractStakePoolNFT3 = useContract(addSC3, abiStakePoolNFT);
  const contractStakePoolNFT4 = useContract(addSC4, abiStakePoolNFT);
  const contractStakePoolNFT5 = useContract(addSC5, abiStakePoolNFT);
  const contractStakePoolNFT6 = useContract(addSC6, abiStakePoolNFT);
  const contractStakePoolNFT7 = useContract(addSC7, abiStakePoolNFT);
  const contractStakePoolNFT8 = useContract(addSC8, abiStakePoolNFT);
  const contractStakePoolNFT9 = useContract(addSC9, abiStakePoolNFT);
  const contractStakePoolNFT10 = useContract(addSC10, abiStakePoolNFT);

  if (contractStakePoolNFT1 !== null) {
    totalListSc.push(contractStakePoolNFT1);
  }
  if (contractStakePoolNFT2 !== null) {
    totalListSc.push(contractStakePoolNFT2);
  }
  if (contractStakePoolNFT3 !== null) {
    totalListSc.push(contractStakePoolNFT3);
  }
  if (contractStakePoolNFT4 !== null) {
    totalListSc.push(contractStakePoolNFT4);
  }
  if (contractStakePoolNFT5 !== null) {
    totalListSc.push(contractStakePoolNFT5);
  }
  if (contractStakePoolNFT6 !== null) {
    totalListSc.push(contractStakePoolNFT6);
  }
  if (contractStakePoolNFT7 !== null) {
    totalListSc.push(contractStakePoolNFT7);
  }
  if (contractStakePoolNFT8 !== null) {
    totalListSc.push(contractStakePoolNFT8);
  }
  if (contractStakePoolNFT9 !== null) {
    totalListSc.push(contractStakePoolNFT9);
  }
  if (contractStakePoolNFT10 !== null) {
    totalListSc.push(contractStakePoolNFT10);
  }

  return (
    <>
      <div
        className="main-stake-details"
        style={{ background: `url(${bgMainheader}) 0% 50% / cover no-repeat` }}
      >
        <div className="container">
          <div className="main-title-pool">
            <button className="back-mint-pool" onClick={() => navigate(-1)}>
              <div className="icon-mint">
                <ArrowLeftOutlined />
              </div>

              <div className="text-back"> Back</div>
            </button>
            <div className="title-pool">{state.inoListPoolDetails?.name}</div>
            <div className="box-img">
              <img
                style={{ left: "30px" }}
                src={state.inoListPoolDetails?.logoUrl}
                alt=""
              />

              <div className="img-small">
                <img src={state.inoListPoolDetails?.earnLogoUrl} alt="" />
              </div>
            </div>
          </div>
          <div className="tabs-stake-details">
            <div className="columns">
              <ItemExpired totalListSc={totalListSc} />
              <ItemStaking totalListSc={totalListSc} />
            </div>
          </div>
          <div className="table-stake-details">
            <div className="box-table">
              <table className="main-table">
                <tr className="tr-top">
                  <th>Period</th>
                  <th>APR</th>
                  <th>Earn</th>
                </tr>
                {state.inoListPoolDetails &&
                  state.inoListPoolDetails?.poolAprs &&
                  state.inoListPoolDetails?.poolAprs.map((item: any) => {
                    return (
                      <ItemPeriod
                        contractPool={state.inoListPoolDetails?.poolContract}
                        item={item}
                      />
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StakePoolDetails;
