/* eslint-disable import/prefer-default-export */

  export enum STATUS {
    PENDING = 1,
    COMING = 2,
    OPEN = 3,
    CLOSE = 4,
  }
  export enum NETWORK {
    BSC = 'bep',
    Poly = 'poly',
    Ethereum='erc',
    OKC = 'okc',
    ARB = 'arb'
  }
  export const MAPPING_CHAINID : any = {
    bep: '0x38',
    erc: '0x1',
    poly: '0x89',
    okc: "0x42",
    arb: "0xa4b1",
    opbnb: "0xcc",
    ftom: '0xFA',
    base: "0x2105",
  }
  export const CHAINID_CONVERT : any = {
    56: 'bep',
    1: 'erc',
    138: 'poly',
    250: 'ftom',
    66: 'okc',
    42161: 'arb',
    204: 'opbnb',
    8453: 'base'
  }
  export const CHAINID_FULLNAME : any = {
    bep: 'BSC',
    erc: 'Ethereum',
    poly: 'Polygon',
    ftom: 'Phantom',
  }
  export enum TIMELINE_STT {
    PENDING = 0,
    PROCESS = 1,
    COMPLETED = 2
  }