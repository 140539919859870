import "./style.css";

const ATH = () => {
  return (
    <>
      <div className="container">
        <div className="top-ath">
          <div className="top-title">TOP 10 Highest ATH IDO Projects</div>
          <div className="chart-ath">
            <div className="bet-icon">
              <img src="./images/bscs-line.png" alt="" />
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-dk.png" alt="" />
              </div>
              <div className="chart-name">Dragon Kart</div>
              <div className="chart-process item1"></div>
              <div className="num-ath">X435</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-dal.png" alt="" />
              </div>
              <div className="chart-name">DAO Launch</div>
              <div className="chart-process item2"></div>
              <div className="num-ath">X310</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-grbe.png" alt="" />
              </div>
              <div className="chart-name">Green Beli</div>
              <div className="chart-process item3"></div>
              <div className="num-ath">X300</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-run.png" alt="" />
              </div>
              <div className="chart-name">Run Together</div>
              <div className="chart-process item4"></div>
              <div className="num-ath">X234</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-hero.png" alt="" />
              </div>
              <div className="chart-name">Hero TD</div>
              <div className="chart-process item5"></div>
              <div className="num-ath">X200</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-kws.png" alt="" />
              </div>
              <div className="chart-name">Knight War</div>
              <div className="chart-process item6"></div>
              <div className="num-ath">X145</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-luna.png" alt="" />
              </div>
              <div className="chart-name">Luna Rush</div>
              <div className="chart-process item7"></div>
              <div className="num-ath">X87</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-dare.png" alt="" />
              </div>
              <div className="chart-name">DareNFT</div>
              <div className="chart-process item8"></div>
              <div className="num-ath">X85</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-9d.png" alt="" />
              </div>
              <div className="chart-name">9DNFT</div>
              <div className="chart-process item9"></div>
              <div className="num-ath">X35</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/apply/ath-zwz.png" alt="" />
              </div>
              <div className="chart-name">Zombie World Z</div>
              <div className="chart-process item10"></div>
              <div className="num-ath">X32</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ATH;
