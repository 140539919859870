export const MAINNET_BSC_URL = 'https://bsc-dataseed.binance.org'
export const MAINNET_CHAIN_ID = 56


export const bscTokens = [
  // stake pool
  {
    id: 'wbnb',
    symbol: 'wbnb',
    contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  {
    id: 'tether',
    symbol: 'USDT',
    contract: '0x55d398326f99059fF775485246999027B3197955',
  },
  {
    id: 'ethereum',
    symbol: 'ETH',
    contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  {
    id: 'dai',
    symbol: 'DAI',
    contract: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  },
  {
    id: 'polis',
    symbol: 'POLIS',
    contract: '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd',
  },
  {
    id: 'tether',
    symbol: 'USDT',
    contract: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
  },
  {
    id: 'usd-coin',
    symbol: 'USDC',
    contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  },
  {
    id: 'bscs',
    symbol: 'BSCS',
    contract: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
  },
  {
    id: 'my-defi-pet',
    symbol: 'DPET',
    contract: '0xfb62ae373aca027177d1c18ee0862817f9080d08',
  },
  {
    id: 'revomon',
    symbol: 'REVO',
    contract: '0x155040625d7ae3e9cada9a73e3e44f76d3ed1409',
  },
  {
    id: 'faraland',
    symbol: 'FARA',
    contract: '0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1',
  },
  {
    id: 'crowns',
    symbol: 'CWS',
    contract: '0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
  },
  {
    id: 'bytenext',
    symbol: 'BNU',
    contract: '0x4954e0062e0a7668a2fe3df924cd20e6440a7b77',
  },
  {
    id: 'cfl365-finance',
    symbol: 'CFL365',
    contract: '0xc1e0510a0df7646817b6632d32caa681a425a5e6',
  },

  {
    id: '2crazynft',
    symbol: '2CRZ',
    contract: '0x3a6e8b36645d6c252714daddd28ec0673535a109',
  },
  {
    id: 'kingdom-game-4-0',
    symbol: 'KDG',
    contract: '0x87a2d9a9a6b2d61b2a57798f1b4b2ddd19458fb6',
  },
  {
    id: 'polkaplay',
    symbol: 'POLO',
    contract: '0xb28a7f8f5328faffdd862985177583c2bb71e016',
  },
  {
    id: 'vaulty-token',
    symbol: 'VLTY',
    contract: '0xb35c9eaa5957563594f7411c6ca2755640253c15',
  },
  {
    id: 'trustfi-network-token',
    symbol: 'TFI',
    contract: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
  },
  {
    id: 'babyswap',
    symbol: 'BABY',
    contract: '0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
  },
  {
    id: 'nftmall',
    symbol: 'GEM',
    contract: '0xbac1df744df160877cdc45e13d0394c06bc388ff',
  },
  {
    id: 'green-beli',
    symbol: 'GRBE',
    contract: '0x8473927b49e6dd0548f8287ea94109b7b753e3cf',
  },
  {
    id: 'ydragon',
    symbol: 'YDR',
    contract: '0x3757232b55e60da4a8793183ac030cfce4c3865d',
  },
  {
    id: 'knight-war-spirits',
    symbol: 'KWS',
    contract: '0x5d0e95c15ca50f13fb86938433269d03112409fe',
  },
  {
    id: 'tribeone',
    symbol: 'HAKA',
    contract: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
  },
  {
    id: 'legend-of-fantasy-war',
    symbol: 'LFW',
    contract: '0xd71239a33c8542bd42130c1b4aca0673b4e4f48b',
  },
  {
    id: 'oly-sport',
    symbol: 'OLY',
    contract: '0x74c1815474a75dcb366223107cde1bba4a1a7296',
  },
  {
    id: 'cogiverse',
    symbol: 'COGI',
    contract: '0x6cb755c4b82e11e727c05f697c790fdbc4253957',
  },
  {
    id: 'howl-city',
    symbol: 'HWL',
    contract: '0x549cc5df432cdbaebc8b9158a6bdfe1cbd0ba16d',
  },
  {
    id: 'real-realm',
    symbol: 'REAL',
    contract: '0xe91cd52bd65fe23a3eae40e3eb87180e8306399f',
  },
  {
    id: 'acknoledger',
    symbol: 'ACK',
    contract: '0xf7b5fb4607abfe0ecf332c23cbdcc9e425b443a8',
  },
  {
    id: 'darenft',
    symbol: 'DNFT',
    contract: '0x162c2332e92be409254ac7c59ad559c16a3f506e',
  },
  {
    id: 'bitlocus',
    symbol: 'BTL',
    contract: '0x51e7b598c9155b9dccb04eb42519f6eec9c841e9',
  },
  {
    id: 'shopnext',
    symbol: 'NEXT',
    contract: '0x9809e877192b510d767a94ba39a79429219a5afb',
  },
  {
    id: 'daolaunch',
    symbol: 'DAL',
    contract: '0x53e4b7aa6caccb9576548be3259e62de4ddd4417',
  },
  {
    id: 'zombie-world-z',
    symbol: 'ZWZ',
    contract: '0x5445451c07e20ba1ca887b6c74d66d358f46d083',
  },
  {
    id: 'mstation',
    symbol: 'MST',
    contract: '0xe7af3fcc9cb79243f76947402117d98918bd88ea',
  },
  {
    id: 'bsdium',
    symbol: 'BSCD',
    contract: '0xe0387845f8289fd5875e7193064392e061f46e58',
  },
  {
    id: 'istep',
    symbol: 'ISTEP',
    contract: '0x67343c29c0fd9827f33e675e0eb80773f9444444',
  },
  {
    id: 'shelterz',
    symbol: 'TERZ',
    contract: '0xcf3bb6ac0f6d987a5727e2d15e39c2d6061d5bec',
  },
]

export const poolsDeath = [0]
