/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { message, Input, Select, Spin } from "antd";
import Countdown from "react-countdown";
import { LoadingOutlined } from "@ant-design/icons";
import { useHookAirdrop } from "./StoreAirdrop";
import Button from "../../components/Button";
import "./style.css";
import { STATUS, NETWORK } from "./index.d";
import { useContract, useIdoTokenClaimContract } from "../../hooks/useContract";
import { rendererCountDown } from '../Ido/utilsIDO'

import abiClaimAirdrop from "./Abi/abiClaimAirdrop.json";
import abiClaimAirdropNew from "./Abi/abiClaimAirdropNew.json"
import { _claimTokensOld, _claimTokensNew, _isClaimed, _userClaims, _userClaimId } from "./utils";
import { Cookies } from "react-cookie";
import moment from 'moment';
import { useAccount } from "wagmi";

const ListJoined = (props: any) => {
  const [state, actions]: any = useHookAirdrop();
  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const { address } = useAccount();
  let account = address
  let accountAddress: any = account;

  if (accountAddress === undefined) {
    // accountAddress = cookies.get("account") || undefined;
    accountAddress = localStorage.getItem("currentAddress");
  }
  const { Search } = Input;
  const { Option } = Select;

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [searchChain, setSearchChain] = useState("bep");
  const handleChangeSort = (value: any) => {
    setSearchChain(value);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleChangeSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const paramsJoineddMore = {
    ownerAddress: accountAddress,
    lastDate: state.airdropListJoined.length > 0
      ? state.airdropListJoined[state.airdropListJoined.length - 1].lastTime
      : null,
    tabId: 2
  };


  const handleLoadMore = () => {
    setIsLoadMore(true);
    if (state.airdropListJoinedMore.length > 0) {
      paramsJoineddMore.lastDate =
        state.airdropListJoinedMore[state.airdropListJoinedMore.length - 1].lastTime;
    }
    actions.getInfoAirdropJoineddMore(paramsJoineddMore).then((data: any) => {
      setIsLoadMore(false);
      if (data.length === 0) setEnableLoadMore(false);
    });
    // setLoadingMore(false);
  };
  let chain = localStorage.getItem("chain");
  const paramsJoined = {
    ownerAddress: accountAddress,
    lastDate: null,
    tabId: 2,
    network: chain,
  };

  useEffect(() => {
    actions.getInfoAirdropJoined(paramsJoined);
  }, [accountAddress, chain]);


  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );
  const ether = (
    <>
      <img src="/images/ether.png" alt="" />
    </>
  );
  const poly = (
    <>
      <img src="/images/poly.png" alt="" />
    </>
  );
  const bsc = (
    <>
      <img src="/images/bnb-bnb-logo.png" alt="" />
    </>
  );
  const avax = (
    <>
      <img src="/images/avax.png" alt="" />
    </>
  );

  const fillIconChain = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return bsc;
        break;
      case NETWORK.Poly:
        return poly;
        break;
      case NETWORK.Ethereum:
        return ether;
        break;
      case NETWORK.AVAX:
        return avax;
        break;
    }
  };

  const fillNetwork = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return "Binance Smart Chain";
        break;
      case NETWORK.Poly:
        return "Polygon";
        break;
      case NETWORK.Ethereum:
        return "Ethereum";
        break;
      case NETWORK.AVAX:
        return "Avalanche C Chain";
        break;
    }
  };

  const ItemJoined = ({ item }: any) => {

    const scClaimAddress = item.contractAddress;
    const whitelist = item?.whitelist;
    const tokenDecimal = item?.whitelist?.tokenDecimal;
    const claimContract = useContract(scClaimAddress, abiClaimAirdrop);
    const claimContractNew = useContract(scClaimAddress, abiClaimAirdropNew);
    const [isLoading, setIsLoading] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [isClaimedNew, setIsClaimedNew] = useState(false);
    const [isClaimedId, setIsClaimedId] = useState(false);
    const idClaim = item?.id
    let timeClaimEnd = moment(item?.endClaimTime * 1000).format('MMM DD YYYY h:mm A');

    const handleClaim = async () => {
      setIsLoading(true);
      try {
        if (idClaim <= 15) {
          await _claimTokensOld(claimContract, item.whitelist, item?.whitelist?.tokenDecimal).then(
            (res) => {
              res.wait().then((res1: any) => {
                if (res1 !== null) {
                  setIsLoading(false);
                  setIsClaimed(true);
                } else {
                  setIsLoading(false);
                  setIsClaimed(false)
                }
              });
            }
          );
        } else {
          await _claimTokensNew(claimContractNew, item.whitelist?.reward, item.whitelist?.keyId, item.whitelist?.rewardSign, item.whitelist.tokenDecimal).then(
            (res) => {
              res.wait().then((res1: any) => {
                if (res1 !== null) {
                  setIsLoading(false);
                  setIsClaimedNew(true);
                  setIsClaimedId(true)
                } else {
                  setIsLoading(false);
                  setIsClaimedNew(false)
                  setIsClaimedId(false)
                }
              });
            }
          )
        }
      } catch (error: any) {
        setIsLoading(false);
        if (error) {
          message.error(error?.error.message);
        }
      }
    };

    useEffect(() => {
      if (accountAddress) {
        if (claimContract !== null && idClaim <= 15) {
          _isClaimed(claimContract, accountAddress).then((res: any) => {
            setIsClaimed(res);
          });
        } else if (claimContractNew !== null) {
          _userClaims(claimContractNew, accountAddress, item?.whitelist?.keyId).then((res: any) => {
            setIsClaimedNew(res);
          });
        }
      }
    }, [accountAddress, claimContract, claimContractNew]);

    useEffect(() => {
      if (claimContract !== null) {
        _userClaimId(claimContract, accountAddress, item?.whitelist?.keyId).then((res: any) => {
          setIsClaimedId(res);
        });
      }
    }, [accountAddress, claimContract, isClaimedId]);
    return (
      <>
        <div className="colum w-33 airdrop-page">
          <div className="content-up">
            <div className="box-content-top-air">
              <div className="box-img">
                <img src={item?.bannerUrl} alt="" />
              </div>
            </div>
            <div className="box-content-bottom">
              <div className="guide-swap">
                <div className="name-token">{item.name}</div>
                <p className="desc airdrop">{item.decs}</p>
                {item?.reward !== null && item?.reward !== "" ? (
                  <ul className="list-swap airdrop">
                    <li>
                      <span className="text-l center">Reward </span>
                    </li>
                    <li>
                      <span className="text-r center"> {item?.reward}</span>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                <div className="list-socail">
                  {item?.whitelist === null ? (
                    <>
                      <div className="text-item">
                        <div className="text-l">Duration start:</div>
                        <div className="text-r">{item.openDate}</div>
                      </div>
                      <div className="text-item">
                        <div className="text-l">Duration ended:</div>
                        <div className="text-r">{item?.endDate}</div>
                      </div>
                    </>

                  ) : ('')}
                  <div className="text-item">
                    <div className="text-l">Network:</div>

                    <div className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(item?.network)}
                      </span>
                      {fillNetwork(item?.network)}
                    </div>
                  </div>
                </div>


                {!accountAddress ? (
                  <>
                    <div className="button-view">
                      <button
                        className="btn btn-view air-drop disable"
                        type="button"
                      >
                        Please connect wallet
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {item?.whitelist !== null ? (
                      <>
                        <div className="times-claim-air">
                          <span className="times-air-text">End claim in:</span>
                          <Countdown
                            date={timeClaimEnd}
                            renderer={rendererCountDown}
                          />
                        </div>
                        {item?.whitelist?.rewardSign !== null ? (
                          <>
                            {idClaim >= 15 ?
                              <>
                                <div className="button-view">
                                  {idClaim === 21 || idClaim === 22 || idClaim === 20 ?
                                    <>
                                      {isLoading ?
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimedId}
                                            loading={isLoading}
                                            text={
                                              isClaimedId
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                          />
                                        </>
                                        :
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimedId}
                                            loading={isLoading}
                                            text={
                                              isClaimedId
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                            click={handleClaim}
                                          />
                                        </>
                                      }
                                    </>
                                    :
                                    <>
                                      {isLoading ?
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimedNew}
                                            loading={isLoading}
                                            text={
                                              isClaimedNew
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                          />
                                        </>
                                        :
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimedNew}
                                            loading={isLoading}
                                            text={
                                              isClaimedNew
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                            click={handleClaim}
                                          />
                                        </>
                                      }
                                    </>
                                  }
                                </div>
                              </>
                              :
                              <>
                                <div className="button-view">
                                  {isLoading ?
                                    <>
                                      <Button
                                        className="btn btn-view air-drop"
                                        disabled={!accountAddress || isClaimed}
                                        loading={isLoading}
                                        text={
                                          isClaimed
                                            ? "Claimed"
                                            : isLoading
                                              ? "Waiting"
                                              : "Claim"
                                        }
                                      />
                                    </>
                                    :
                                    <>
                                      <Button
                                        className="btn btn-view air-drop"
                                        disabled={!accountAddress || isClaimed}
                                        loading={isLoading}
                                        text={
                                          isClaimed
                                            ? "Claimed"
                                            : isLoading
                                              ? "Waiting"
                                              : "Claim"
                                        }
                                        click={handleClaim}
                                      />
                                    </>
                                  }
                                </div>
                              </>
                            }
                          </>
                        ) : (
                          <Button
                            className="btn btn-view air-drop"
                            disabled={true}
                            // loading={isLoading}
                            text={
                              'Claim'
                            }
                          />
                        )}

                      </>
                    ) : (
                      <>
                        <div className="button-view ">
                          <Button
                            className="btn btn-view air-drop"
                            disabled
                            text="You’re not whitelisted"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="table-airdrop">
        <div className="columns">
          {state.airdropListJoined.length === 0 || state.airdropListJoined === false ? (
            <div className="empty pd-top-30">{noData}</div>
          ) : (
            state.airdropListJoined.map((item: any, i: any) => (
              <ItemJoined item={item} />
            ))
          )}
          {state.airdropListJoinedMore.map((item: any, i: any) => (
            <ItemJoined item={item} />
          ))}
        </div>
        <div className="load-more-round">
          {!isLoadMore && state.airdropListEnded.length !== 0 && enableLoadMore ? (
            <button
              className="btn-loadmore cus"
              type="button"
              onClick={() => handleLoadMore()}
            >
              Load More
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default ListJoined;
