/* eslint-disable react-hooks/rules-of-hooks */

const switchNetworkChain = async (chainId: any, value: any) => {
    const provider: any = (window as Window).ethereum;

    if (provider) {
        try {
            if (provider?.selectedAddress && chainId) {

                await provider
                    .request({
                        method: "wallet_switchEthereumChain",
                        params: [
                            {
                                chainId,
                            },
                        ],
                    })
                    .then((res: any) => {
                        localStorage.setItem("chain", value);
                    })

            }
            // else {
            //   console.log('value2', value)
            //   localStorage.setItem("chain", value);
            // }
        } catch (switchError: any) {
            try {
                if (chainId === "0x89") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Polygon Chain Mainnet",
                                chainId: "0x89",
                                rpcUrls: ["https://polygon-rpc.com"],
                                nativeCurrency: {
                                    name: "MATIC",
                                    symbol: "MATIC",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0x250") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Fantom Opera",
                                chainId: "250",
                                rpcUrls: ["https://rpcapi.fantom.network"],
                                nativeCurrency: {
                                    name: "FTM",
                                    symbol: "FTM",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0xa86a") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Avalanche Mainnet C-Chain",
                                chainId: "0xa86a",
                                rpcUrls: ["https://cchain.explorer.avax.network/"],
                                nativeCurrency: {
                                    name: "AVAX",
                                    symbol: "AVAX",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0xa") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Optimism",
                                chainId: "0xa",
                                rpcUrls: ["https://mainnet.optimism.io"],
                                nativeCurrency: {
                                    name: "ETH",
                                    symbol: "ETH",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0x1") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Ethereum Chain Mainnet",
                                chainId: "0x1",
                                rpcUrls: ["https://mainnet.infura.io/v3"],
                                nativeCurrency: {
                                    name: "ETH",
                                    symbol: "ETH",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0x7d0") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Dogechain",
                                chainId: "0x7d0",
                                rpcUrls: ["https://rpc01-sg.dogechain.dog (hoặc https://rpc02-sg.dogechain.dog/"],
                                nativeCurrency: {
                                    name: "wDOGE",
                                    symbol: "wDOGE",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0xfa") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Fantom Opera",
                                chainId: "0xfa",
                                rpcUrls: ["https://rpcapi.fantom.network "],
                                nativeCurrency: {
                                    name: "FTM",
                                    symbol: "FTM",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0x42") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "OKC Mainnet",
                                chainId: "0x42",
                                rpcUrls: ["https://exchainrpc.okex.org"],
                                nativeCurrency: {
                                    name: "OKT",
                                    symbol: "OKT",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } else if (chainId === "0xa4b1") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Arbitrum One",
                                chainId: "0xa4b1",
                                rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                                nativeCurrency: {
                                    name: "Arbitrum One",
                                    symbol: "ETH",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                }
                else if (chainId === "0xcc") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "opBNB Mainnet",
                                chainId: "0xcc",
                                rpcUrls: ["https://opbnb-mainnet-rpc.bnbchain.org"],
                                nativeCurrency: {
                                    name: "opBNB Mainnet",
                                    symbol: "BNB",
                                    decimals: 18,
                                },
                                blockExplorerUrls: ["https://opbnbscan.com/"],
                            },
                        ],
                    });
                }
                else if (chainId === "0x2105") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Base Mainnet",
                                chainId: "0x2105",
                                rpcUrls: ["https://mainnet.base.org"],
                                nativeCurrency: {
                                    name: "Base Mainnet",
                                    symbol: "ETH",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                }
                else if (chainId === "0x13e31") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Blast",
                                chainId: "0x13e31",
                                rpcUrls: ["https://rpc.blast.io"],
                                nativeCurrency: {
                                    name: "Blast",
                                    symbol: "ETH",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                }
                else if (chainId === "0x343b") {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Immutable zkEVM",
                                chainId: "0x343b",
                                rpcUrls: ["https://rpc.immutable.com"],
                                nativeCurrency: {
                                    name: "IMX",
                                    symbol: "IMX",
                                    decimals: 18,
                                },
                            },
                        ],
                    }).then((res: any) => {
                        localStorage.setItem("chain", value);
                        window.location.reload()
                    })

                }
                else {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "BNB Chain Mainnet",
                                chainId: "0x38",
                                rpcUrls: ["https://bsc-dataseed.binance.org"],
                                nativeCurrency: {
                                    name: "BNB",
                                    symbol: "BNB",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                }
                return false;
            } catch (addError) {
                console.error(addError);
                return false;
            }

        }
    } else {
        return false;

        // console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    }
};

export default switchNetworkChain;
