import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { isMobile } from "react-device-detect";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Value from "../../components/Value";

import { useHookProjects } from "./Store";
import { ICON_BNB_URL, ICON_ETH_URL, ICON_POLY_URL } from "../../constants";
import { CHAIN } from "pages/Ido/index.d";

const FundedProjects = () => {
  const [state, actions]: any = useHookProjects();

  let navigate = useNavigate();
  const gotoINO = () => {
    navigate("/InoLaunchPad");
  };
  const gotoINODetail = (keywork: any) => {
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };

  // const gotoIDODetail = (keywork: any) => {
  //   navigate(`${"/Ido/IdoDetails"}?${keywork}`);
  // };

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const gotoIDODetail = (network: any, symbol: any, isOldIDo: any, idoTrust: any) => {
    if (network === "apt") {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
    } else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else if (network === CHAIN.TON) {
      navigate(`${"/Ido/Detail"}/${network}?${symbol}`);
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else
      if (idoTrust === 'Vanguard' && symbol !== "MEY") {
        navigate(`${"/Ido/details-vanguard"}?${symbol}`);
      } else {
        navigate(`${"/Ido/Details"}?${symbol}`);
      }
  };

  const listInoHome = state.inoListHome;

  const settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <>
        <div className="style-ar-l" onClick={onClick}>
          <img src="/images/ar-l.png" alt="" />
        </div>
      </>
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <>
        <div className="style-ar-r" onClick={onClick}>
          <img src="/images/ar-r.png" alt="" />
        </div>
      </>
    );
  }

  const Item = (item: any) => {
    const network_url =
      item.network === "bep"
        ? ICON_BNB_URL
        : item.network === "eth"
          ? ICON_ETH_URL
          : ICON_POLY_URL;
    let desCut = item.description.slice(0, 84);
    return (
      <div className="item-slider">
        <div className="content-row-funded">
          <div className="box-img">
            <div className="img-big">
              <img src={item.logoUrl} alt="" />

              <span className="icon-img">
                <span>
                  <img src={network_url} alt="" />
                </span>
              </span>
              <span className="allow-open">{item.timeLineStatus}</span>
            </div>
            <div className="img-small">
              <img src={item.symbolUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.symbol}</span>
          </div>
          <p className="desc">
            {item.description?.length > 84 ? `${desCut}...` : desCut}
          </p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">
                <Value unit="$" decimals={0} value={item.totalSale} />
              </span>
            </li>
            <li>
              <span className="left">Participants:</span>

              <span className="right">{item.participants}</span>
            </li>
          </ul>
          {item.id === 0 ? (
            <>
              <div
                className="title-upcoming-sale"
                onClick={() =>
                  gotoIDODetail(item.network, item.symbol, item?.isOldIDo, item?.idoTrust)
                }
              >
                Token Sale
              </div>
            </>
          ) : (
            <>
              {" "}
              <div
                className="title-upcoming-sale"
                onClick={() => gotoINODetail(item.keywork)}
              >
                NFT Sale
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  const ItemMobile = (item: any) => {
    const network_url =
      item.network === "bep"
        ? ICON_BNB_URL
        : item.network === "eth"
          ? ICON_ETH_URL
          : ICON_POLY_URL;
    let desCut = item.description.slice(0, 84);
    return (
      <div className="item-slider">
        <div className="content-row-funded">
          <div className="box-img">
            <div className="img-big">
              <img src={item.logoUrl} alt="" />

              <span className="icon-img">
                <span>
                  <img src={network_url} alt="" />
                </span>
              </span>
              <span className="allow-open">{item.timeLineStatus}</span>
            </div>
            <div className="img-small">
              <img src={item.symbolUrl} alt="" />
            </div>
          </div>
          <div className="box-title">
            <h3 className="title">{item.name}</h3>
            <span className="number">{item.symbol}</span>
          </div>
          <p className="desc">
            {item.description?.length > 84 ? `${desCut}...` : desCut}
          </p>
          <ul className="list-raised">
            <li>
              <span className="left">Total raised:</span>
              <span className="right">
                <Value unit="$" decimals={0} value={item.totalSale} />
              </span>
            </li>
            <li>
              <span className="left">Participants:</span>

              <span className="right">{item.participants}</span>
            </li>
          </ul>
          {item.id === 0 ? (
            <>
              <div
                className="title-upcoming-sale"
                onClick={() =>
                  gotoIDODetail(item.network, item.symbol, item?.isOldIDo, item?.idoTrust)
                }
              >
                Token Sale
              </div>
            </>
          ) : (
            <>
              {" "}
              <div
                className="title-upcoming-sale"
                onClick={() => gotoINODetail(item.keywork)}
              >
                NFT Sale
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );
  return (
    <>
      <div className="main-setion">
        <div className="container">
          <div className="main-title">
            <h3 className="title">Funded Projects</h3>
            <p className="desc">We bring new technologies to our community</p>
          </div>
          <div className="row-funded">
            <div className="columns">
              <div className="colum w-33">
                <div className="content-funded-pj raise">
                  <div className="content-left">
                    <div className="box-icon">
                      <img src="/images/fund-1.svg" alt="" />
                    </div>
                  </div>
                  <div className="content-right">
                    <h4 className="title">Funded Projects</h4>
                    <p className="number-funded">
                      {state.marketInfo?.ideasFunded}
                    </p>
                  </div>
                </div>
              </div>
              <div className="colum w-33">
                <div className="content-funded-pj uni">
                  <div className="content-left">
                    <div className="box-icon">
                      <img src="/images/fund-2.svg" alt="" />
                    </div>
                  </div>
                  <div className="content-right">
                    <h4 className="title">Unique Participants</h4>
                    <p className="number-funded">
                      {state.marketInfo?.uniqueParticipans}
                    </p>
                  </div>
                </div>
              </div>
              <div className="colum w-33">
                <div className="content-funded-pj fun">
                  <div className="content-left">
                    <div className="box-icon">
                      <img src="/images/fund-3.svg" alt="" />
                    </div>
                  </div>
                  <div className="content-right">
                    <h4 className="title">Raised Capital</h4>
                    <p className="number-funded">
                      ${state.marketInfo?.raisedCapital}M
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isMobile ? (
            <>
              <div className="slide-row-funded">
                <div className="slider-chain mobile">
                  {/* <Slider {...settings}>
                    {listInoHome.map((item: any, index: any) => (
                      <>{ItemMobile(item)}</>
                    ))}
                  </Slider> */}
                  {listInoHome?.length !== 0 ? (
                    <Slider {...settings}>
                      {listInoHome.map((item: any, index: any) => (
                        <>{ItemMobile(item)}</>
                      ))}
                    </Slider>
                  ) : (
                    <div className="empty">{noData}</div>
                  )}
                  <div className="btn btn-show-more" onClick={() => gotoINO()}>
                    Explore projects
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="slide-row-funded">
                <div className="slider-chain mobile">
                  <div className="columns funded">
                    {listInoHome.map((item: any, index: any) => (
                      <>{Item(item)}</>
                    ))}
                    <div
                      className="btn btn-show-more"
                      onClick={() => gotoINO()}
                    >
                      Explore projects
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* <div className="slide-row-funded">
            <div className="slider-chain mobile">
              <Slider {...settings}>
                {listInoHome.map((item: any, index: any) => (
                  <>{Item(item)}</>
                ))}
              </Slider>
              <div className="btn btn-show-more" onClick={() => gotoINO()}>
                Explore projects
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default FundedProjects;
