import React from "react";
import FormDetail from "./FormDetail";
import FormBody from "./FormBody";
import bgMainheader from "./images/banner-ino.png";
import "./style.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CreateInfo = () => {
  let navigate = useNavigate();
  return (
    <>
      <div
        className="main-edit-profile"
        style={{
          background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
        }}
      >
        <Fullwidth>
          <Container>
            <div className="bodyUpload">
              <button className="back-mint create-info" onClick={() => navigate(-1)}>
                <div className="icon-mint">
                  <ArrowLeftOutlined />
                </div>

                <div className="text-back"> Back</div>
              </button>
            </div>
            <div className="header-form">
          <div className="title">Create your profile</div>
          <div className="desc">BSCS artist</div>
        </div>
        <FormBody>
          <FormDetail />
        </FormBody>
          </Container>
        </Fullwidth>

        {/* <div className="header-form">
          <div className="title">Create your profile</div>
          <div className="desc">BSCS artist</div>
        </div>
        <FormBody>
          <FormDetail />
        </FormBody> */}
      </div>
    </>
  );
};
const Fullwidth = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 736px;
  padding: 0 10px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export default CreateInfo;
