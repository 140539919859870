import "./style.css";

const Support = () => {
  return (
    <>
      <div className="container">
        <div className="title-sup">
          <div className="title-sup-top">Comprehensive support</div>
          {/* <div className="titlesup-bot">
            It is a long established fact that a reader will be distracted by
            the readable <p>content of a page</p>
          </div> */}
        </div>
        <div className="sup-item">
          <div className="box-sub-item prod">
            <div className="icon-sup">
              <img src="./images/apply/sup-prod.png" alt="" />
            </div>
            <div className="sup-name">Product</div>
            <div className="sup-list-des">
              <ul>
                <li>Product Consulting </li>
                <li>Documents Consulting</li>
                <li>Tokenomics Consulting</li>
                <li>Security & Audit Consulting</li>
                <li>Legal Service Connection</li>
              </ul>
            </div>
          </div>
          <div className="box-sub-item listing">
            <div className="icon-sup listing">
              <img src="./images/apply/sup-listing.png" alt="" />
            </div>
            <div className="sup-name">Listing</div>
            <div className="sup-list-des">
              <ul>
                <li>CEX & DEX Listing Support</li>
                <li>CGK and CMC Listing Support </li>
                <li>Tokenomics Consulting</li>
                <li>Market Maker Support/ Connection</li>
              </ul>
            </div>
          </div>
          <div className="box-sub-item fund">
            <div className="icon-sup fund">
              <img src="./images/apply/sup-fund.png" alt="" />
            </div>
            <div className="sup-name">Fund raising</div>
            <div className="sup-list-des">
              <ul>
                <li>VCs Connection</li>
                <li>Launchpads & Partners Connection</li>
                <li>Investments</li>
              </ul>
            </div>
          </div>
          <div className="box-sub-item pr">
            <div className="icon-sup pr">
              <img src="./images/apply/sup-pr.png" alt="" />
            </div>
            <div className="sup-name">Marketing & PR</div>
            <div className="sup-list-des">
              <ul>
                <li>Marketing Package</li>
                <li>
                  PR on media <br />
                  e-newspapers
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
