import React, { useState, useCallback } from "react";
import { useContext } from "react";
import { ModalContext } from "../../../../components/modal";

import {
  FormContainer,
  NFTItem,
  RowItem,
  IMGItem,
  InforItem,
  TitleCollection,
  NFTName,
  MiniTitle,
  InforConfirm,
  RowConfirm,
  TitleConfirm,
  RowButton,
  RowConditions,
  TextConditions,
  TotalConfirm,
} from "./style";
import {
  CloseModal,
  TitleModal,
  ButtonCommon,
} from "../../../../components/common/styleCommon";
import { FEE_CREATOR, FEE_SERVICE } from "../../../../constants/index";
import Loading from "../../../../components/Loading";
import Value from "../../../../components/Value";
import CheckBox from "../../Upload/components/CheckBox";

const ConfirmBuyModal = (props: any) => {
  const { onCloseModal }: any = useContext(ModalContext);
  const { onConfirm, detail, price, priceUSD } = props;
  const [checked, setChecked] = useState(false);
  const [pendingTx, setPendingTx] = useState(false);

  const price_USD = Number(priceUSD) * Number(price);

  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };
  const handleConfirm = useCallback(async () => {
    try {
      setPendingTx(true);
      onCloseModal();
      await onConfirm();
      setPendingTx(false);
     
    } catch (e) {
      console.error("err confirm", e);
      setPendingTx(false);
    }
  }, [onConfirm]);

  return (
    <>
      <FormContainer>
        <TitleModal>Complete checkout</TitleModal>
        <CloseModal onClick={() => onCloseModal()}> X </CloseModal>
        <NFTItem>
          <RowItem>
            <IMGItem>
              <img src={detail?.nftUrl} alt="" />
            </IMGItem>
            <InforItem>
              <TitleCollection>
                Blue-the Unicorn NFT Collections
              </TitleCollection>
              <NFTName>{detail?.name}</NFTName>
              <MiniTitle>Listing price:</MiniTitle>
              <NFTName>
                <img src="./../images/logo.png" alt="" />{" "}
                <Value value={Number(price)} fontWeight={600} /> BSCS{" "}
                <MiniTitle>
                  {" "}
                  <Value value={price_USD} unit="$" />
                </MiniTitle>
              </NFTName>
            </InforItem>
          </RowItem>
          <InforConfirm>
            <RowConfirm>
              <TitleConfirm style={{ display: "flex", alignItems: "center" }}>
                Total:
              </TitleConfirm>
              <TotalConfirm>
                <img src="./../images/logo.png" alt="" />{" "}
                <Value
                  value={Number(price)}
                  size={24}
                  fontWeight={600}
                  // decimals={0}
                  unitafter=" BSCS"
                />{" "}
                <MiniTitle>
                  <Value value={price_USD} unit="$" />
                </MiniTitle>
              </TotalConfirm>
            </RowConfirm>
          </InforConfirm>
          <RowConditions>
            <TextConditions>
              <CheckBox onChange={onChange} />I have read the{" "}
              <span>Terms and Conditions</span>
            </TextConditions>
          </RowConditions>
          <RowButton>
            <ButtonCommon
              disabled={pendingTx || !checked}
              onClick={handleConfirm}
            >
              <Loading status={pendingTx} content="Confirm checkout" />
            </ButtonCommon>
          </RowButton>
        </NFTItem>
      </FormContainer>
    </>
  );
};

export default ConfirmBuyModal;
