import React from 'react'
import {
    BrandContainer,
    ContentWrapper,
    DownloadContent,
    Title,
    Desc,
    ListLogo,
    Button,
    BtnDesc
} from './BrandStyles'

export const BrandDownload = () => {
    return (
        <BrandContainer>
            <ContentWrapper>
                <DownloadContent>
                    <Title>
                        Downloads
                    </Title>
                    <Desc>
                        We like to keep things simple. So we have put together a package for the brand assets that you <br /> can download and use in your website or app.
                    </Desc>
                </DownloadContent>
                <ListLogo>
                    {/* <img src="../images/banner-brand.png" alt="brand assets BSCS" /> */}
                    <img src="../images/BSCS_brand.svg" alt="brand assets BSCS" />
                </ListLogo>
                <Button href="https://drive.google.com/drive/folders/1l72aMmi7HmTe1cSVfFJ13BEUuQACzGUT" target="_blank" rel="noreferrer">Download Assets</Button>
                <BtnDesc>Vector Logo BSCS (SVG & EPS), PNG</BtnDesc>
            </ContentWrapper>
        </BrandContainer>
    )
}

