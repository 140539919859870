import "./style.css";

const Extensive = () => {
  return (
    <>
      <div className="container">
        <div className="extensive">
          <div className="title-exten">
            <div className="title-ex-top">
              Extensive marketing and Community Support
            </div>
            <div className="title-ex-bot">
              Utilize BSCS's knowledge at every stage of the process.
            </div>
          </div>
          <div className="exten-item">
            <div className="item-ex pre">
              <div className="pre-title">Pre-launch</div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-pre.png" alt="" />
                </div>
                <div className="pre-des-content">Announcement Campaign</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-pre.png" alt="" />
                </div>
                <div className="pre-des-content">Marketing Strategy</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-pre.png" alt="" />
                </div>
                <div className="pre-des-content">Video Production</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-pre.png" alt="" />
                </div>
                <div className="pre-des-content">Live AMA Sessions</div>
              </div>
            </div>

            <div className="item-ex pre">
              <div className="pre-title launch">Launch</div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-lau.png" alt="" />
                </div>
                <div className="pre-des-content">Hands-on Support</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-lau.png" alt="" />
                </div>
                <div className="pre-des-content">Allowlist Engagement</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-lau.png" alt="" />
                </div>
                <div className="pre-des-content">Share Launch Results</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-lau.png" alt="" />
                </div>
                <div className="pre-des-content">Campaign Review</div>
              </div>
            </div>

            <div className="item-ex pre">
              <div className="pre-title post">Post-launch</div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-post.png" alt="" />
                </div>
                <div className="pre-des-content">Performance Review</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-post.png" alt="" />
                </div>
                <div className="pre-des-content">Strategy Review</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-post.png" alt="" />
                </div>
                <div className="pre-des-content">Success Promo Campaign</div>
              </div>
              <div className="pre-des">
                <div className="pre-des-icon">
                  <img src="./images/apply/icon-post.png" alt="" />
                </div>
                <div className="pre-des-content">Live Poolside Podcast</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Extensive;
