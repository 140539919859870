/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
import { createStore, createHook, createContainer, createSubscriber } from 'react-sweet-state'
import axios from 'axios'
import { API_URL } from '../../constants'


const StoreIno = createStore({
  initialState: {
    inoTiers: {},
    yourBalanceBSCS: {},
    inoList: [],
    inoListMore: [],
    inoItem: {},
    myNFTs: [],
  },
  actions: {
    getTierINO: (param) => async ({ setState, getState }) => {
      try {
        const res = await axios.post(`${API_URL}/ino/tier`, { ...param })
        // console.log('res: ', res)
        setState({ inoTiers: res.data.data } || {})
        // return res
      } catch (err) {
        // TODO
      }
    },
    getInfoINO: (params) => ({ getState, setState }) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/inov2/infos`, { ...params })
          .then((res) => {
            const { data: { data } } = res
            setState({ inoList: res.data.data } || [])
            resolve(data)
          })
      })
    },
    getInfoINOMore: (params) => ({ getState, setState }) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/inov2/infos`, { ...params })
          .then((res) => {
            const { data: { data } } = res
            setState({ inoListMore: res.data.data } || [])
            resolve(data)
          })
      })
    },
    getDetailINO: (param) => async ({ setState, getState }) => {
      try {
        const res = await axios.post(`${API_URL}/ino/detail`, { ...param })
        // console.log('res: ', res)
        setState({ inoItem: res.data.data } || {})
        // return res
      } catch (err) {
        // TODO
      }
    },
    updateBoxAPI: (obj) => async ({ setState }) => {
      return new Promise((resolve, reject) => {
        axios.post(
          `${API_URL}/ino/update_buy`, {
          "typeId": obj.typeId,
          "ownerAddress": obj.ownerAddress,
          "tnxHash": obj.tnxHash
        }
        ).then((res) => {
          resolve(res)
        })
      })
    },
    buyBoxAPI: (obj) => async ({ setState }) => {
      return new Promise((resolve, reject) => {
        axios.post(
          `${API_URL}/ino/buy`, {
          "inoId": obj.inoId,
          "typeId": obj.typeId,
          "quantity": obj.quantity,
          "ownerAddress": obj.ownerAddress,
          "tnxHash": obj.tnxHash
        }
        ).then((res) => {
          resolve(res)
        })
      })
    },
    genSign: (typeId, account) => async ({ setState }) => {
      return new Promise((resolve, reject) => {
        axios.post(
          `${API_URL}/ino/gen_sign`, {
          "typeId": typeId,
          "ownerAddress": account
        }
        ).then((res) => {
          resolve(res)
        })
      })
    },
    openBox: (inoId, boxId, ownerAddress) => ({ getState, setState, dispatch }) => {
      return new Promise((resolve, reject) => {
        const res = axios.post(`${API_URL}/ino/open_box`, {
          inoId,
          boxId,
          ownerAddress
        }).then((resData) => {
          resolve(resData)
        })
      })

    },
    afterOpenBox: (nftId, inoId, boxId, ownerAddress, txHash) => ({ getState, setState, dispatch }) => {
      return new Promise((resolve, reject) => {
        const res = axios.post(`${API_URL}/ino/update_open_box`, {
          inoId,
          boxId,
          nftId,
          ownerAddress,
          txHash
        }).then((resData) => {
          resolve(resData)
        })
      })

    },
    getMyNfts: (data, account) => async ({ setState, getState }) => {
      try {
        const res = await axios.post(`${API_URL}/ino/box_nft_detail`, {
          "nftIds": data,
          "ownerAddress": account
        })
        setState({ myNFTs: res.data.data || [] })
      } catch (err) {
        // TODO
      }
    },
  },
  name: 'StoreIno',
})

export const useHookIno = createHook(StoreIno)
export const Container = createContainer(StoreIno, {
  onInit: () => ({ setState }, props) => {
    setState({ ...props })
  },
})
export const Subscriber = createSubscriber(StoreIno)
