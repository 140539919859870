import styled from "styled-components";

import bgBrandBanner from "./images/bg-brand-banner.png";
import bgBrandBanner_mobile from "./images/bg_mobile.png";

export const BrandAssets = styled.div`
  background: url(${bgBrandBanner}) 0% 50% / cover no-repeat;
  padding-top: 86px;
  background-size: cover;
  object-fit: cover;
  @media screen and (max-width: 576px) {
    background: url(${bgBrandBanner_mobile}) 0% 50% / cover no-repeat;
  }
`;
export const BrandContainer = styled.div`
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;
  width: 100%;
`;
export const ContentBanner = styled.div`
  padding: 86px 0 74px 0;
  @media screen and (max-width: 576px) {
    padding-bottom: 41px;
  }
`;
export const FirstContent = styled.div`
  margin-bottom: 65px;
`;
export const FirstTitle = styled.h3`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;

  @media screen and (max-width: 576px) {
    font-size: 30px;
  }
`;
export const FistDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #616772;
  line-height: 22px;
  margin-bottom: 0;
  @media screen and (max-width: 576px) {
    br {
      display: none;
    }
  }
`;
export const SecondContent = styled.div`
  padding: 0;
`;
export const SecondTitle = styled.h4`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 31px;
  @media screen and (max-width: 576px) {
    font-weight: 500;
  }
`;
export const SecondDesc = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #707a8a;
  margin-bottom: 0;
  @media screen and (max-width: 376px) {
    max-width: 319px;
    margin: 0 auto;
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 62px;
`;
export const DownloadContent = styled.div`
  width: 100%;
`;
export const Title = styled.h4`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 31px;

  @media screen and (max-width: 576px) {
    line-height: 41px;
    font-weight: 500;
  }
`;
export const Desc = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #707a8a;
  margin-bottom: 50px;

  @media screen and (max-width: 576px) {
    margin: 0 auto 50px;
    br {
      display: none;
    }
  }

  @media screen and (max-width: 376px) {
    max-width: 320px;
  }
`;
export const ListLogo = styled.div`
  width: 100%;
  height: 100%;
  max-width: 772px;
  max-height: 482px;
`;
export const Button = styled.a`
  text-decoration: none;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.06em;
  padding: 12px 30px;
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  margin-top: 40px;
  cursor: pointer;
  &:hover {
    background: #3f4a57;
    box-shadow: 0px 6px 20px rgb(198 198 198 / 44%);
    border-radius: 6px;
    color: #fff;
  }
`;
export const BtnDesc = styled.p`
  margin-top: 13px;
  font-size: 14px;
  line-height: 34px;
  font-style: italic;
  color: #707a8a;
`;
