import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { message } from "antd";
import {
    SyncOutlined,
} from '@ant-design/icons';
import {
    useContract,
    useIdoTokenClaimContract
} from "../../../hooks/useContract";
import abiDailyBonus from "../../../abi/abiDailyBonus.json"
import { _claimTokens, totalDailyClaimed, convertWeiToToken, _totalClaimed, _claimTokensBonus } from "../utilsBonus";
import '../style.css'
import { useAccount } from "wagmi";

const ClaimDetailsBonus = () => {
    const { address } = useAccount();

    let account = address
    const [isLoading, setIsLoading] = useState(false);
    let scClaimAddressOne = "0x3b9B8665a9f470D7af13eb56D8A0F79fE356d032";
    let scClaimAddressTwo = "0xe4Fc077FC31d9073D509DfCC0C42aA1D787781b0";
    let scClaimAddressThree = "0x82e2503B53F5d0B73f136153cC8f1a9936D8bd4d";
    const claimContractOne = useContract(scClaimAddressOne, abiDailyBonus);
    const claimContractTwo = useContract(scClaimAddressTwo, abiDailyBonus);
    const claimContractThree = useContract(scClaimAddressThree, abiDailyBonus);
    const [totalClaimDaily, setTotalClaimDaily] = useState<any>(0);
    const [totalClaimed, setTotalClaimed] = useState<any>(0);
    const [isClaimed, setIsClaimed] = useState(false);

    useEffect(() => {
        const contract = (account === "0xf080A0aB82FE43A16cF3064104118741645da39B" && claimContractOne) ||
            (account === "0x8dAcDE75982C462844af93F6BbC5285d9c11AD20" && claimContractTwo) ||
            (account && claimContractThree);
        if (contract) {
            contract.userInfo(account).then((res: any) => {
                setTotalClaimDaily(res.tokensClaimed / 1e18);
                setTotalClaimed(res.amount / 1e18)
            });
        }
    }, [account, claimContractOne, claimContractTwo, claimContractThree]);


    // handle Claim
    const handleClaim = async () => {
        setIsLoading(true);
        try {
            let claimContract;
            if (account === "0xD80B9f718634a75E5046351cE30d3dad2765e31f" || account === "0x8dAcDE75982C462844af93F6BbC5285d9c11AD20") {
                claimContract = claimContractTwo;
            } else if (account === "0xf080A0aB82FE43A16cF3064104118741645da39B") {
                claimContract = claimContractOne;
            } else {
                claimContract = claimContractThree;
            }

            const res = await _claimTokensBonus(claimContract);
            const res1 = await res.wait();

            if (res1) {
                setIsLoading(false);
                setIsClaimed(true);
            } else {
                setIsLoading(false);
            }
        } catch (error: any) {
            setIsLoading(false);
            if (error) {
                message.error(error.message);
            }
        }
    };

    return (
        <>
            <Wrapper>
                <Fullwidth>
                    <Container>
                        <div className="content-bonus-dt">
                            <div className="main-title-bonus">
                                Bonus Pool
                                <div className="logo-dt">
                                    <div className="box-img">
                                        <img src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/0d311ab7a9ac35379c72225fb02263aedafc020bdf2bd30435.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="table-bonus-dt">
                                <div className="row-dt">
                                    <div className="name">
                                        Claim Reward
                                    </div>
                                    <div className="box-img">
                                        <img src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/0d311ab7a9ac35379c72225fb02263aedafc020bdf2bd30435.png" alt="" />
                                    </div>
                                    <div className="total-claim">
                                        {totalClaimed - totalClaimDaily}
                                    </div>
                                    <div className="btn-claim-dt">
                                        {/* class dis: disbled */}
                                        {totalClaimed - totalClaimDaily === 0 ?
                                            <>
                                                <button type="button" className="btn-claim-dt-bonus disbled">
                                                    Claim
                                                </button>
                                            </>
                                            :
                                            <>
                                                {isLoading ?
                                                    <>
                                                        <button type="button" className="btn-claim-dt-bonus disbled">
                                                            Claim <SyncOutlined spin />
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn-claim-dt-bonus" onClick={handleClaim}>
                                                            Claim
                                                        </button>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Fullwidth>
            </Wrapper>
        </>
    )
}
export default ClaimDetailsBonus

const Wrapper = styled.div`
  padding-top: 132px;
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/892421bfdfd6f28761be3ab53b4921bec30a9641502b0107aa.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-bottom: 90px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
const Fullwidth = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;