/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { message } from "antd";
import Value from "./../../../components/Value";
import { NETWORK } from "../index.d";

const ether = (
    <>
        <img src="/images/imgido/ether.png" alt="" />
    </>
);
const poly = (
    <>
        <img src="/images/imgido/poly.png" alt="" />
    </>
);
const bsc = (
    <>
        <img src="/images/imgido/icon-bnb-ido.png" alt="" />
    </>
);
const opbnb = (
    <>
        <img src="/images/imgido/icon-bnb-ido.png" alt="" />
    </>
);
const avax = (
    <>
        <img src="/images/imgido/avax.png" alt="" />
    </>
);
const okx = (
    <>
        <img src="/images/imgido/okx.png" alt="" />
    </>
);
const arbitrum = (
    <>
        <img src="/images/imgido/arb.png" alt="" />
    </>
);
const base = (
    <>
        <img src="/images/base.png" alt="" />
    </>
);
const apt = (
    <>
        <img src="/images/apt.png" alt="" />
    </>
);
const sui = (
    <>
        <img src="/images/imgido/sui.jpeg" alt="" />
    </>
);

const sol = (
    <>
        <img src="/images/sol.png" alt="" />
    </>
);
const zkevm = (
    <>
        <img src="/images/zk.jpeg" alt="" />
    </>
);
const ton = (
    <>
        <img src="/images/ton.png" alt="" />
    </>
);

const fillIconChain = (network: any) => {
    switch (network) {
        case NETWORK.BSC:
            return bsc;
            break;
        case NETWORK.Poly:
            return poly;
            break;
        case NETWORK.Avax:
            return avax;
            break;
        case NETWORK.Ethereum:
            return ether;

            break;
        case NETWORK.OKC:
            return okx;
            break;
        case NETWORK.ARB:
            return arbitrum;
            break;
        case NETWORK.OPBNB:
            return opbnb;
            break;
        case NETWORK.BASE:
            return base;
            break;
        case NETWORK.Aptos:
            return apt;
            break;
        case NETWORK.SUI:
            return sui;
            break;
        case NETWORK.SOL:
            return sol;
            break;
        case NETWORK.ZKEVM:
            return zkevm;
            break;
        case NETWORK.TON:
            return ton;
            break;
    }
};

const fillNetwork = (network: any) => {
    switch (network) {
        case NETWORK.BSC:
            return "BNB Chain";
            break;
        case NETWORK.Poly:
            return "Polygon";
            break;
        case NETWORK.Avax:
            return "Avax Chain";
            break;
        case NETWORK.Ethereum:
            return "Ethereum";
            break;
        case NETWORK.OKC:
            return "OKXChain";
            break;
        case NETWORK.ARB:
            return "Arbitrum";
            break;
        case NETWORK.OPBNB:
            return "opBNB";
            break;
        case NETWORK.BASE:
            return "Base";
            break;
        case NETWORK.Aptos:
            return "Aptos";
            break;
        case NETWORK.SUI:
            return "SUI";
            break;
        case NETWORK.SOL:
            return "Solana";
            break;
        case NETWORK.ZKEVM:
            return "zkEVM";
            break;
        case NETWORK.TON:
            return "Ton";
            break;
    }
};

const formatNumber = (x: any, max: any) => {
    if (x) {
        return x.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: max,
        });
    } else return 0;
};

const TopIdoDetail = (props: any) => {
    const data = props.dataTop;
    let navigate = useNavigate();
    const handleCallClick = (symbol: any) => {
        navigate(`${"/Ido/FullSearchProject"}?${symbol}`);
    };
    return (
        <>
            <div className="main-ido-details">
                <div className="columns">
                    <div className="colum w-50">
                        <div className="content-ido-details">
                            <div className="info-project-l">
                                <div className="box-icon">
                                    <img src={data?.logoUrl} alt="" />
                                </div>
                                <div className="social-project">
                                    <div className="name-project">
                                        {data?.name}{" "}
                                        <span className="token">
                                            {data?.unit}
                                            {data?.idoTrust !== "" ?
                                                <>
                                                    {data?.idoTrust === "S" ? (
                                                        <div className="bage-s-detail">Shielded</div>
                                                    ) : data?.idoTrust === "R" ? (
                                                        <div className="bage-r-detail">Riskless</div>
                                                    ) : data?.idoTrust === "Vanguard" ? (
                                                        <div className="bage-v-detail">{data?.idoTrust}</div>
                                                    ) : (
                                                        <div className="bage-d-detail">{data?.idoTrust}</div>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {""}
                                                </>
                                            }

                                        </span>
                                    </div>
                                    <div className="social-name">
                                        <div className="box-icon-sc">
                                            <a
                                                className="linkMedia"
                                                href={data?.socical?.website}
                                                target="blank"
                                            >
                                                <img src="/images/imgido/web.png" alt="" />
                                            </a>

                                            <a
                                                className="linkMedia"
                                                href={data?.socical?.telegram}
                                                target="blank"
                                            >
                                                <img src="/images/imgido/tele-1.png" alt="" />
                                            </a>

                                            <a
                                                className="linkMedia"
                                                href={data?.socical?.twitter}
                                                target="blank"
                                            >
                                                <img src="/images/imgido/twi-1.png" alt="" />
                                            </a>

                                            <a
                                                className="linkMedia"
                                                href={data?.socical?.medium}
                                                target="blank"
                                            >
                                                <img src="/images/imgido/me-1.png" alt="" />
                                            </a>
                                        </div>
                                        <div className="box-address">
                                            {data?.idoContract && data?.idoContract === "TBA" ? (
                                                "TBA"
                                            ) : (
                                                <>
                                                    {data?.idoContract?.slice(0, 5)}...
                                                    {data?.idoContract?.slice(-5)}
                                                </>
                                            )}
                                            {/* {data?.idoContract === "TBA"
                        ? "TBA"
                        : data?.idoContract &&
                          `${data?.idoContract.substring(
                            0,
                            10
                          )}...${data?.idoContract.substring(
                            10,
                            data?.idoContract.length
                          )}`} */}

                                            {data?.idoContract === "TBA" ? (
                                                ""
                                            ) : (
                                                <CopyToClipboard
                                                    text={data?.idoContract}
                                                    onCopy={() => {
                                                        message.success({
                                                            type: "success",
                                                            content: "Copied!!",
                                                            className: "custom-class",
                                                            duration: 2,
                                                        });
                                                    }}
                                                >
                                                    <span className="img-copy">
                                                        <img
                                                            className="coppy"
                                                            src="/images/imgido/copy.png"
                                                            alt=""
                                                        />
                                                    </span>
                                                </CopyToClipboard>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="desc">{data?.description}</p>
                            {data?.isFullResearch ? (
                                <div className="full-search">
                                    <button
                                        type="button"
                                        className="btn-search-project"
                                        onClick={() => handleCallClick(data?.symbol)}
                                    >
                                      Full Project Research {">>"}
                                    </button>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="colum w-50">
                        <div className="content-ido-details">
                            <div className="info-project-r">
                                <ul className="list-token-info">
                                    <li>
                                        <span className="text-l">Symbol:</span>
                                        <span className="text-r">
                                            <span className="icon-r">
                                                <img src={data?.logoUrl} alt="" />
                                            </span>
                                            {data?.symbol}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="text-l">Join Network:</span>
                                        <span className="text-r">
                                            <span className="icon-symbol">
                                                {fillIconChain(data?.network)}
                                            </span>
                                            {fillNetwork(data?.network)}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="text-l">Token Network:</span>
                                        <span className="text-r">
                                            <span className="icon-symbol">
                                                {fillIconChain(data?.tokenNetwork)}
                                            </span>
                                            {fillNetwork(data?.tokenNetwork)}
                                        </span>
                                    </li>
                                </ul>
                                <ul className="list-token-info">
                                    <li>
                                        <span className="text-l">Swap Rate:</span>
                                        <span className="text-r">{data?.swapAmount}</span>
                                    </li>
                                    <li>
                                        <span className="text-l">Total Raise:</span>
                                        <span className="text-r">
                                            {formatNumber(data?.idoSupply * data?.pricePer, 1)} {data?.symbolUSD}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="text-l">Total Supply:</span>
                                        <span className="text-r">
                                            <Value value={data?.totalSupply} />
                                            {data?.symbol}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TopIdoDetail;
