import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { message } from "antd";
import { useHookUpload } from "./Store-Upload";
import UploadFile from "../Upload/components/UploadFile";
import CheckBox from "../Upload/components/CheckBox";
import Button from "../../../components/Button";
import { boolean } from "yup/lib/locale";
import { useAccount } from "wagmi";

export default function Form1() {
  const { address } = useAccount();
  let account = address
  const [state, actions] = useHookUpload();
  const [isLoading, setLoading] = React.useState(false);
  const upLoadFileAvatar = (event: any) => {
    formik.setFieldValue("fileAvatar", event.target.files[0]);
  };
  // init formit and validate
  const formik: any = useFormik({
    initialValues: {
      fileAvatar: "",
      name: "",
      social: "",
      bio: "",
      fileBanner: "",
      accept: boolean,
      medium: "",
      telegram: "",
      twitter: "",
      website: "",
    },
    validationSchema: Yup.object({
      fileAvatar: Yup.string().required("Required!"),
      name: Yup.string().required("Required!"),
      telegram: Yup.string().required("Required!"),
      bio: Yup.string().required("Required!"),
      accept: Yup.string().required("Required!"),
    } as any),

    onSubmit: () => {
      setLoading(true);
      actions
        .submit(formik.values, account)
        .then((res) => {
          if (res.data.succeeded === true) {
            formik.resetForm();
            message.success({
              type: "success",
              content: res.data?.message,
              className: "custom-class",
              duration: 2,
            });
            setLoading(false);
            setTimeout(() => {
              window.location.href = "/MyArtwork";
            }, 800);

          } else {
            message.success({
              type: "success",
              content: res.data?.message,
              className: "custom-class",
              duration: 2,
            });
            setLoading(false);
          }

          // navigate(`${"/MyArtwork"}`);
        })

        .catch((err: any) => {
          message.error({
            type: "error",
            content: err,
            className: "custom-class",
            duration: 2,
          });
          setLoading(false);
          formik.resetForm();
          formik.setFieldValue("fileAvatar", null);
        });
    },
  } as any);

  const chosse = (event: any) => {
    formik.setFieldValue("accept", event.target.value);
  };

  return (
    <>
      <form
        id="frm-create-artist"
        className="uploadInfo"
        onSubmit={formik.handleSubmit}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Enter your details.</div>
          </div>
          <div className="round-r">
            <div className="form-group">
              <input
                placeholder="Enter the artist name"
                type="text"
                className="form-control f-control"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && formik.touched.name && (
                <p style={{ color: "red" }}>{formik.errors.name}</p>
              )}
            </div>
          </div>
        </div>

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Add a short bio.</div>
          </div>
          <div className="round-r">
            <div className="form-group">
              <textarea
                className="form-control f-control cus"
                name="bio"
                placeholder="Please write something about yourself"
                value={formik.values.bio}
                onChange={formik.handleChange}
              />
              {formik.errors.bio && formik.touched.bio && (
                <p style={{ color: "red" }}>{formik.errors.bio}</p>
              )}
            </div>
          </div>
        </div>

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">Upload a profile image.</div>
            <div className="desc-round">
              Recommended size: <br />
              1180x230px.
              <br />
              JPG, PNG, or GIF.
              <br />
              10MB max size.
            </div>
          </div>
          <div className="round-r">
            <div className="formfile">
              <div className="form-group avatar-upload">
                <UploadFile
                  name="fileAvatar"
                  id="imgFile"
                  accept="image/*"
                  onChange={upLoadFileAvatar}
                />
                {formik.errors.fileAvatar && formik.touched.fileAvatar && (
                  <p style={{ color: "red" }}>{formik.errors.fileAvatar}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">
              Add links to your social <br /> media profiles.
            </div>
            <div className="desc-round cus">
              <img src="../images/imgmarket/link.svg" alt="" /> Website
            </div>
          </div>
          <div className="round-r">
            <div className="form-group cus">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Website URL"
                name="social"
                value={formik.values.social}
                onChange={formik.handleChange}
              />
              {formik.errors.social && formik.touched.social && (
                <p style={{ color: "red" }}>{formik.errors.social}</p>
              )}
            </div>
          </div>
        </div> */}

        <div className="item-info-edit">
          <div className="round-l">
            <div className="title-round">
              Add links to your social <br /> media profiles.
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Telegram.svg" alt="" /> Telegram
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Telegram URL"
                name="telegram"
                value={formik.values.telegram}
                onChange={formik.handleChange}
              />
              {formik.errors.telegram && formik.touched.telegram && (
                <p style={{ color: "red" }}>{formik.errors.telegram}</p>
              )}
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/link.svg" alt="" /> Website
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Website URL"
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Twitter.svg" alt="" /> X.com
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="X.com URL"
                name="twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="item-info-edit row-social">
          <div className="round-l">
            <div className="desc-round social-cus">
              <img src="../images/imgmarket/Medium.svg" alt="" /> Medium
            </div>
          </div>
          <div className="round-r">
            <div className="form-group ">
              <input
                type="url"
                className="form-control f-control"
                placeholder="Medium URL"
                name="medium"
                value={formik.values.medium}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row col-md-12">
            <CheckBox
              className="form-check-label-accept col-md-12"
              name="accept"
              onChange={chosse}
              htmlFor="accept"
              label="I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the
                artwork can be removed anytime if detected."
            />
            {formik.errors.accept && formik.touched.accept && (
              <p
                style={{
                  color: "red",
                  marginLeft: "17px",
                }}
              >
                Required!
              </p>
            )}
          </div>
        </div>
        <div className="btn-inf" id="btn-submit-artist">
          <>
            {account === null ? (
              <Button
                type="button"
                click={() => message.warning("Please Unlock Wallet!")}
                primary
                text="Save change"
              />
            ) : (
              <>
                <Button
                  type="submit"
                  text="Submit"
                  primary
                  loading={isLoading}
                />
              </>
            )}
          </>
        </div>
      </form>
    </>
  );
}
