import React, { ReactNode } from "react";
import styled from "styled-components";


interface PageHeaderProps {
  title: ReactNode;
  description?: ReactNode;
}

const StyledPageHeader = styled.div`
  // border-bottom: 1px solid;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  padding-top: 30px;
`;
const Details = styled.div`
  flex: 1;
`;

const Header = ({ title, description }: PageHeaderProps) => {

  return (
    <StyledPageHeader>
      <Details>
        <div className="heading">{title}</div>
        {description && <div className="upload-des">{description}</div>}
      </Details>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {/* {!state.objData.artistName ? (
        <div className="labelName">
          <>
            {account === null ? (
              <button
                type="button"
                onClick={() => message.warning(" Please Unlock Wallet")}
                className="btn-Create"
              >
                Create artist
              </button>
            ) : (
              <a href="#/create-artists" className="btn-Create">
                Create artist
              </a>
            )}
          </>
        </div>
      ) : (
        <label className="labelName color-green" htmlFor="nameInput">
          {state.objData.artistName ? `${state.objData.artistName}` : ""}
        </label>
      )} */}
    </StyledPageHeader>
  );
};

export default Header;
