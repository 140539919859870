import React from "react";
import { Clock, Target } from "react-feather";
import Value from "../../componentsST/Value";
import { useCurrentBlock } from "../../store/hooks";
import { getPoolBlockInfo } from "./helpers";

const BlockCountdownDetail = ({ pool }) => {
  const currentBlock = useCurrentBlock();

  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
  } = getPoolBlockInfo(pool, currentBlock);
  return (
    <>
      {shouldShowBlockCountdown && (
        <>
          {(blocksRemaining || blocksUntilStart) && currentBlock ? (
            <>
              <Value size={15} value={blocksToDisplay} decimals={0} />
              Block
              {/* {hasPoolStarted ? (
                <a
                  href={`https://bscscan.com/block/countdown/${pool.endBlock}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-white"
                >
                  <span>
                    <img src="/images/pool/clock.svg" alt="" />
                  </span>
                </a>
              ) : (
                <a
                  href={`https://bscscan.com/block/countdown/${pool.startBlock}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-white"
                >
                  <span>
                    <img src="/images/pool/clock.svg" alt="" />
                  </span>
                </a>
              )} */}
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default BlockCountdownDetail;
