/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MainBanner from "./MainBanner";
import MainChainSlider from "./MainChainSlider";
import MainStack from "./MainStack";
import Upcoming from "./Upcoming";
import ProjectRaise from "./ProjectRaise";
import FundedProjects from "./FundedProjects";
import Ecosystem from "./Ecosystem";
import Partner from "./Partner";
import MakesOur from "./MakesOur";
import { useHookProjects } from "./Store";
import "./style.css";

const Home = () => {
    const [state, actions]: any = useHookProjects();
    useEffect(() => {
        actions.getInoV2Home();
        actions.getUpcomingV2Home();
        actions.getIdoHome();
        actions.getListBanner();
        // actions.getMarketInfo();
    }, []);
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
        document
            .getElementsByTagName("meta")
            .item(1)
            ?.setAttribute(
                "content",
                "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
            );
    }, []);

    return (
        <>
            <MainBanner />
            <MainChainSlider />
            <MainStack />
            <Upcoming />
            <ProjectRaise />
            <FundedProjects />
            <Ecosystem />
            <Partner />
            <MakesOur />
        </>
    );
};

export default Home;
