/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from "bignumber.js";
import React, { useEffect, useState, useCallback } from "react";
import {
  Tabs,
  Collapse,
  Button as ButtonConnect,
  Modal,
  Skeleton,
  message,
} from "antd";
import Value from "../../componentsST/Value";
import "./style.css";
import useSousApprove from "../../hooksST/useSousApprove";
import useSousHarvest from "../../hooksST/useSousHarvest";
import useSousHarvestV3 from "../../hooksST/useSousHarvestV3";
import useSousHarvestUserLock from "../../hooksST/useSousHarvestUserLock";
import { fetchPoolsUserDataAsync } from "../../store/pools/index";
import useModal from "../../hooksST/useModal";
import DepositModal from "./DepositModal";
import { getBalanceNumber } from "../../utilsST/formatBalance";
import { BIG_ZERO } from "../../utilsST/bigNumber";
import { formatNumber } from "../../utilsST/formatBalance";
import Button from "../../componentsST/ButtonV2";
import { useDispatch } from "react-redux";
import WithdrawModal from "./WithdrawModal";
import ApyCalculatorModal from "../../componentsST/ApyCalculatorModal";
import WithdrawUserLockModal from "./WithdrawUserLockModal";
import BlockCountdown from "./BlockCountdown";
import BlockCountdownDetail from "./BlockCountdownDetail";
import sousChefV3ABI from "../../config/abi/sousChefV3.json";
import { getContract } from "../../utilsST/contractHelpers";
import ConnectWallet from "./../../components/Connect-wallet/ConnectWallet";
import { useCurrentBlock } from "../../store/hooks";
import { getTokenStake, getRewards } from "../../utilsST/callHelpers";
import sousChefAbi from "../../abi/stakeFinal.json";
import { useContract } from "../../hooks/useContract";
import getBalanceReward from "../../hooks/getBalanceReward";
import { useAccount } from "wagmi";

const IDOList = ({ pool, userDataLoaded }) => {
  // console.log('pool',pool)
  const [isBalanceStaking, setBalanceStaking] = useState(0);
  const { address } = useAccount();

  let account = address
  const dispatch = useDispatch();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [isHiden, setHiden] = useState(false);
  const { userData } = pool;
  const { onReward } = useSousHarvest(pool.sousId);
  const { onRewardV3 } = useSousHarvestV3(pool.sousId);
  const { onRewardUserLock } = useSousHarvestUserLock(pool.sousId);
  const [isBalanceStake, setBalanceStake] = useState(0);
  const [isPendingRewards, setPendingRewards] = useState(0);
  const [pendingTx, setPendingTx] = useState(false);
  const [listSchedule, setListSchedule] = useState([]);
  const stakings = userData?.stakedBalance
    ? new BigNumber(userData.stakedBalance)
    : BIG_ZERO;

  let stakingTokenDollarBalance = getBalanceNumber(
    stakings.multipliedBy(pool.stakingTokenPrice),
    pool.stakingToken.decimals
  );
  const earnings = userData?.pendingReward
    ? new BigNumber(userData.pendingReward)
    : BIG_ZERO;
  let earningTokenDollarBalance = getBalanceNumber(
    earnings.multipliedBy(pool.earningTokenPrice),
    pool.earningToken.decimals
  );

  const allowance = userData.allowance;
  const { onApprove } = useSousApprove(
    pool.stakingToken.address,
    pool.contractAddress
  );

  const isApproved = account && allowance && allowance.isGreaterThan(0);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove()
        .then((res) => {
          setRequestedApproval(true);
          res.wait().then((res2) => {
            if (res2 != null) {
              setRequestedApproval(false);
              setHiden(true);
              message.success({
                type: "success",
                content: "Approve success!",
                className: "custom-class",
                duration: 3,
              });
            }
          });
        })
        .catch((error) => {
          setRequestedApproval(false);
        });
      dispatch(fetchPoolsUserDataAsync(account));
    } catch (e) {
      message.error({
        type: "error",
        content: e?.message,
        className: "custom-class",
        duration: 3,
      });
      setRequestedApproval(false);
    }
  }, [onApprove, dispatch, account, pool]);

  const handleReward = useCallback(async () => {
    try {
      const balanceReward = await getBalanceReward(
        pool.earningToken.address,
        pool.contractAddress
      );
      if (parseFloat(balanceReward) > isPendingRewards) {
        setPendingTx(true);
        await onReward()
          .then((res) => {
            setPendingTx(true);
            res.wait().then((res2) => {
              if (res2 != null) {
                setPendingTx(false);
                message.success({
                  type: "success",
                  content: "Harvest success!",
                  className: "custom-class",
                  duration: 3,
                });
              }
            });
          })
          .catch((error) => {
            setPendingTx(false);
          });
        dispatch(fetchPoolsUserDataAsync(account));
      } else {
        message.error({
          type: "error",
          content: "The pool is temporarily out of tokens.",
          className: "custom-class",
          duration: 5,
        });
      }
    } catch (e) {
      setPendingTx(false);
    }
  }, [account, dispatch, onReward, pool]);

  const handleRewardV3 = useCallback(async () => {
    try {
      const balanceReward = await getBalanceReward(
        pool.earningToken.address,
        pool.contractAddress
      );
      if (parseFloat(balanceReward) > isPendingRewards) {
        setPendingTx(true);
        await onRewardV3()
          .then((res) => {
            setPendingTx(true);
            res.wait().then((res2) => {
              if (res2 != null) {
                setPendingTx(false);
                message.success({
                  type: "success",
                  content: "Harvest success!",
                  className: "custom-class",
                  duration: 3,
                });
              }
            });
          })
          .catch((error) => {
            setPendingTx(false);
          });
        dispatch(fetchPoolsUserDataAsync(account));
      } else {
        message.error({
          type: "error",
          content: "The pool is temporarily out of tokens.",
          className: "custom-class",
          duration: 5,
        });
      }
    } catch (e) {
      setPendingTx(false);
    }
  }, [account, dispatch, onRewardV3, pool]);

  const handleRewardUserLock = useCallback(async () => {
    try {
      const balanceReward = await getBalanceReward(
        pool.earningToken.address,
        pool.contractAddress
      );
      if (parseFloat(balanceReward) > isPendingRewards) {
        setPendingTx(true);
        await onRewardUserLock()
          .then((res) => {
            setPendingTx(true);
            res.wait().then((res2) => {
              if (res2 != null) {
                setPendingTx(false);
                message.success({
                  type: "success",
                  content: "Harvest success!",
                  className: "custom-class",
                  duration: 3,
                });
              }
            });
          })
          .catch((error) => {
            setPendingTx(false);
          });
        dispatch(fetchPoolsUserDataAsync(account));
      } else {
        message.error({
          type: "error",
          content: "The pool is temporarily out of tokens.",
          className: "custom-class",
          duration: 5,
        });
      }
    } catch (e) {
      setPendingTx(false);
    }
  }, [account, dispatch, onRewardUserLock, pool]);

  const [onPresentStakeInPoolModal] = useModal(
    <DepositModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      stakingToken={pool.stakingToken}
      addressToken={pool.stakingToken.address}
      max={userData.stakingTokenBalance}
      // onConfirm={handleStake}
      isModalOpen={true}
    />
  );

  const [onPresentWithdrawModal] = useModal(
    <WithdrawModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.stakedBalance}
    // onConfirm={handleUnstake}
    />
  );

  const [onPresentWithdrawUserLockModal] = useModal(
    <WithdrawUserLockModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      versionSC={pool.versionSC}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.unstakeAmount}
      balance={userData.stakedBalance}
    // onConfirm={handleUnstakeUserLock}
    />
  );
  const [onPresentWithdrawUserLockModalV3] = useModal(
    <WithdrawUserLockModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      versionSC={pool.versionSC}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.unstakeAmount}
      balance={userData.stakedBalance}
    // onConfirm={handleUnstakeV3}
    />
  );
  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={pool.earningTokenPrice}
      apr={pool.apr}
      earningTokenSymbol={pool.earningToken.symbol}
      performanceFee={pool.fees}
    />
  );
  const { Panel } = Collapse;

  const onChangeCollapse = (key) => {
    console.log(key);
  };
  const contractAddressPool = getContract(sousChefV3ABI, pool.contractAddress);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const getBalance = async () => {
    if (contract) {
      const balance = await getTokenStake(contract, pool.sousId, account);
      setBalanceStake(balance.amount / 1e18);
    }
  };
  const getPendingRewards = async () => {
    if (contract) {
      const rewards = await getRewards(contract, pool.sousId, account);
      setPendingRewards(rewards[0] / 1e18);
    }
  };

  useEffect(() => {
    getBalance();
    getPendingRewards();
  });
  useEffect(() => {
    if (account && contractAddressPool) {
      contractAddressPool.methods
        .getUserStakedCount(account)
        .call()
        .then(async (resStakedCount) => {
          if (resStakedCount !== "0") {
            let arrData = [];
            for (let i = 0; i < parseInt(resStakedCount); i++) {
              arrData.push(
                contractAddressPool.methods.stakeDetails(account, i).call()
              );
            }
            let listData = [];
            // get result
            await Promise.all([arrData]).then((resAll) => {
              for (let i = 0; i < parseInt(resStakedCount); i++) {
                resAll[0][i].then((data) => {
                  listData.push(data);
                });
              }
            });
            setTimeout(() => {
              setListSchedule(listData);
            }, 1000);
          }
        });
    }
  }, [account]);
  const currentBlock = useCurrentBlock();

  const headerPool = (
    <>
      <div className="guide-swap IDO">
        <div className="item-round">
          <div className="name-project">
            <div className="box-logo">
              <>
                {pool.birthday ? (
                  <img
                    src={`/tokens/birthday-${pool.earningToken.symbol.toLowerCase()}.png?v=6`}
                    className="logo-big"
                    alt=""
                  />
                ) : (
                  <>
                    <img
                      src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=6`}
                      className="logo-big"
                      alt=""
                    />
                    <img
                      src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=6`}
                      className={pool.idoLP ? "logo-small-LP" : "logo-small"}
                      alt=""
                    />
                  </>
                )}
              </>
            </div>

            <div className="box-name">
              <span className="title-earn">
                {pool.earningToken.symbol} - {pool.stakingToken.symbol}
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <Value size={16} unitafter="%" value={pool.apr} />
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <Value size={16} decimals={0} value={pool.lockup} />
                {pool.birthday ? "hours" : "day"}
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <Value
                  size={16}
                  unit="$"
                  value={pool.stakedTvl ? +pool.stakedTvl : 0}
                />{" "}
                {/* <span className="symbol">{pool.stakingToken.symbol}</span> */}
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt main-color-pool">
                <Value
                  size={16}
                  className="txt main-color-pool"
                  value={account ? isPendingRewards : 0.0}
                />
              </span>
              <span className="symbol">{pool.earningToken.symbol}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const contentBottom = (
    <>
      <div className="main-swap-pool">
        <div className="item-block">
          <div className="content-block">
            <div className="reward-in  dis-desktop">
              <span className="txt-l">Lock-up term:</span>
              <div className="txt-r">
                {pool.lockup} {pool.birthday ? "hours" : "day"}
              </div>
            </div>
            <div className="reward-in">
              <span className="txt-l">
                {currentBlock >= pool.startBlock
                  ? "Rewards end in"
                  : "Rewards start in"}
              </span>
              <div className="txt-r">
                <BlockCountdownDetail pool={pool} />
              </div>
            </div>
            <div className="view-port pd-top-20 dis-mb-550">
              <a
                href={`${pool.stakingToken.projectLink}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text details">
                  View project
                  <span>
                    <img src="/images/pool/p-info.svg" alt="" />
                  </span>
                </div>{" "}
              </a>
            </div>
            <div className="view-port dis-mb-550">
              <a
                href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text details">
                  View contract
                  <span>
                    <img src="/images/pool/p-info.svg" alt="" />
                  </span>
                </div>
              </a>
            </div>
            <div className="view-port dis-mb-550">
              <a
                href={`https://bscscan.com/address/${pool.stakingToken.address.toLowerCase()}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text details">
                  See Pair Info
                  <span>
                    <img src="/images/pool/p-info.svg" alt="" />
                  </span>
                </div>{" "}
              </a>
            </div>
            {pool.idoLP && (
              <div className="view-port dis-mb-550">
                <a
                  href={`https://v1.bscstation.finance/#/pool}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="text details">
                    Remove Liquidity
                    <span>
                      <img src="/images/pool/p-info.svg" alt="" />
                    </span>
                  </div>{" "}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="item-block">
          <div className="content-block">
            <div className="box-stake">
              <div className="title">{pool.earningToken.symbol} Earned</div>
              <div className="box-bottom">
                <div className="round-l">
                  <div className="number-stake">
                    <Value size={18} value={account ? isPendingRewards : 0} />{" "}
                    <span className="symbol">{pool.earningToken.symbol}</span>
                  </div>
                  <div className="number-rw">
                    <Value
                      size={14}
                      unit={"$"}
                      value={earningTokenDollarBalance}
                    />
                  </div>
                </div>
                <div className="round-r">
                  <Button
                    primary
                    disabled={
                      !account ||
                      pendingTx ||
                      userData.pendingReward.eq(new BigNumber(0)) ||
                      !isApproved ||
                      pool.disHarvest === true
                    }
                    loading={pendingTx}
                    text="Harvest"
                    click={
                      pool.userLock
                        ? pool.versionSC === 3
                          ? handleRewardV3
                          : handleRewardUserLock
                        : handleReward
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-block">
          <div className="content-block">
            <div className="box-stake">
              <div className="title res">{pool.stakingToken.symbol} Staked</div>
              <div className="box-bottom">
                <div className="round-l">
                  <div className="number-stake">
                    <Value size={20} value={account ? isBalanceStake : 0} />
                  </div>
                  <div className="number-rw">
                    <Value
                      size={15}
                      unit={"$"}
                      value={account ? stakingTokenDollarBalance : "$0.00"}
                    />
                  </div>
                </div>
                <div className="round-r">
                  <Button
                    ghost
                    disabled={
                      !account ||
                      userData.stakedBalance.eq(new BigNumber(0)) ||
                      !isApproved ||
                      pool.disUnstake ||
                      userData.unstakeAmount <= 0
                    }
                    text="Unstake"
                    click={
                      pool.userLock
                        ? pool.versionSC === 3
                          ? onPresentWithdrawUserLockModalV3
                          : onPresentWithdrawUserLockModal
                        : onPresentWithdrawModal
                    }
                  />
                  <Button
                    className="btn-plus"
                    primary
                    // disabled={
                    //   !account ||
                    //   (!isApproved && !isHiden) ||
                    //   pool.isFinished ||
                    //   pool.disStake
                    // }
                    disabled
                    text="+"
                    click={onPresentStakeInPoolModal}
                  />
                </div>
              </div>
              <div className="round-connect">
                {account ? (
                  <>
                    {userDataLoaded ? (
                      <>
                        {!isApproved ? (
                          <>
                            {!isHiden && (
                              <Button
                                loading={requestedApproval}
                                className="btn-w-full"
                                text="Approve Contract"
                                // disabled={pool.isFinished === true}
                                click={handleApprove}
                                primary
                              />
                            )}
                          </>
                        ) : (
                          <Button
                            loading={requestedApproval}
                            disabled
                            className="btn-w-full"
                            text=" Increase allowance"
                            // disabled={pool.isFinished === true}
                            click={handleApprove}
                            ghost
                          />
                        )}
                      </>
                    ) : (
                      <Button
                        primary
                        className="btn-w-full Loading"
                        text="Loading..."
                      />
                    )}
                  </>
                ) : (
                  <div className="connect-cus-start-pool">
                    <ConnectWallet />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="content-block dis-desktop">
          <div className="view-port">
            <a
              href={`${pool.stakingToken.projectLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="text details">
                View project
                <span>
                  <img src="/images/pool/p-info.svg" alt="" />
                </span>
              </div>
            </a>
            <a
              href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="text details">
                View contract
                <span>
                  <img src="/images/pool/p-info.svg" alt="" />
                </span>
              </div>
            </a>
            <a
              href={`https://bscscan.com/address/${pool.stakingToken.address.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="text details">
                See Pair Info
                <span>
                  <img src="/images/pool/p-info.svg" alt="" />
                </span>
              </div>
            </a>
            {pool.idoLP && (
              <a
                href={`https://v1.bscstation.finance/#/pool`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="text details">
                  Remove Liquidity
                  <span>
                    <img src="/images/pool/p-info.svg" alt="" />
                  </span>
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="main-round-pool">
        <div className="round-pool">
          <Collapse onChange={onChangeCollapse}>
            <Panel header={headerPool}>{contentBottom}</Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};
export default IDOList;
