/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Progress, Button, message, Modal } from "antd";
import { ScheduleOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { useHookIDO } from "../StoreIdo";
import { STATUS, NETWORK } from "../index.d";
import Value from "../../../components/Value";
import {
  useContract,
  useIdoTokenClaimContract,
} from "../../../hooks/useContract";
import switchNetwork from "../../../utils/wallet";
import { MAPPING_CHAINID } from "../../../constants";
import abiBUSD from "../../../abi/abiBUSD.json";
import abiClaimOneButton from "../Abi/abiClaimOneButton.json";
import abiJoinPool from "../Abi/abiJoinPoolNew.json";
import ModalClaimedAfter from "./ModalClaimedAfter/ModalClaimedAfter";
import {
  _isJoined,
  _approveBUSD,
  getProgressTime,
  rendererCountDown,
  _isRefundedRC,
  _refund,
  _claimTokens,
  _showClaimBtn,
  _isClaimed,
  _joinPoolNew,
  _refundChange,
  _isClaimChange,
  _totalClaimed,
  totalDailyClaimed,
  convertWeiToToken,
  totalJoinPool,
} from "../utilsIDO_OLD";
import { useAccount, useChainId } from "wagmi";

const Item = ({
  item,
  logo,
  unit,
  symbol,
  decimals,
  network,
  usdDecimals,
  tokenJoinPool,
  symbolUSD,
}: any) => {
  const [state, actions] = useHookIDO();
  const { address } = useAccount();

  const chainId = useChainId()
  let account = address
  let accountAddress: any = account;
  // if (accountAddress === undefined) {
  //   accountAddress = localStorage.getItem("currentAddress");
  // }

  // khai báo trạng thái
  const [isJoined, setIsJoined] = useState(false);
  const [isSke, setIsSke] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isApproveJoin, setApproveJoin] = useState(false);
  const [disnableJoin, setDisnableJoin] = useState(false);
  const [isRefundedRC, setRefundedFromSC] = useState(false);
  const [isLoadingRefund, setLoadingRefund] = useState(false);
  const [isShowClaim, setIsShowClaim] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingClaimChange, setLoadingClaimChange] = useState(false);
  const [isClaimChangeRC, setClaimChangeFromSC] = useState(false);
  const [totalClaimed, setTotalClaimed] = useState<any>(0);
  const [totalClaimDaily, setTotalClaimDaily] = useState<any>(0);
  const [totalJoined, setTotalJoined] = useState<any>(0);
  const [addClass, setAddClass] = useState("");

  // khai báo biến
  let scClaimAddress = item.allocation && item.allocation?.claimContract;
  if (scClaimAddress === undefined) scClaimAddress = null;
  let scJoinAddress = item.allocation && item.allocation?.joinContract;
  if (scJoinAddress === undefined) scJoinAddress = null;

  let scClaimChangeAddress = item.allocation?.claimChange?.refundContract;
  if (scClaimChangeAddress === undefined) scClaimChangeAddress = null;
  const totalRaise = item.totalRaise;
  const ignoreFullSlot = item.ignoreFullSlot;
  let isFullSlot = false;

  const amountJoin = item.allocation?.joinBusd;
  const joinStatus = item.allocation && item.allocation.joinStatus;
  const allocation = item.allocation;
  const is_allow_joinpool =
    item.allocation &&
    item.allocation.joinSignBusd !== null &&
    item.status === 3;

  const signRefund = item.allocation?.claimChange?.signRefund;
  const is_commit_fund =
    item.allocation && item.allocation.claimChange.refundContract !== null;
  const showPopupClaim = item.showPopupClaim;
  const claimbles = item.allocation && item.allocation.claimbles;
  const joinSignBusd = item.allocation?.joinSignBusd;
  const fundProcess = item.allocation?.claimChange?.fundProcess;
  const refundBusd = item.allocation?.claimChange?.refundBusd;

  const daily_obj =
    item.allocation &&
    item.allocation.claimbles.find((f: any) => f.claimRound === 100);
  const contract_daily =
    daily_obj !== undefined && daily_obj !== null
      ? daily_obj.contractAddress
      : null;

  let _is_refunded_api = 0;
  let _claimedTokens: any =
    parseFloat(totalClaimed.toString()) +
    parseFloat(totalClaimDaily.toString());

  let processClaim =
    item.allocation !== null
      ? Number(((_claimedTokens / item.allocation.joinToken) * 100).toFixed(2))
      : 0;
  if (_is_refunded_api === 1 || isRefundedRC) processClaim = 100;

  const is_network_bep = network === "bep";
  const is_network_erc = network === "erc";
  const is_network_poly = network === "poly";
  const is_network_okc = network === "okc";
  const is_network_arb = network === "arb";
  const is_network_opbnb = network === "opbnb";
  const is_network_base = network === "base";
  const is_network_avax = network === "avax";

  const is_hide_btn =
    (is_network_bep && chainId !== 56) ||
    (is_network_erc && chainId !== 1) ||
    (is_network_avax && chainId !== 43114) ||
    (is_network_poly && chainId !== 137) ||
    (is_network_okc && chainId !== 66) ||
    (is_network_arb && chainId !== 42161) ||
    (is_network_opbnb && chainId !== 204) ||
    (is_network_base && chainId !== 8453);

  const busdContract = useContract(tokenJoinPool, abiBUSD);
  const claimContract = useContract(scClaimAddress, abiClaimOneButton);
  const joinPoolContract = useContract(scJoinAddress, abiJoinPool);

  const claimChangeContract = useContract(
    scClaimChangeAddress,
    abiClaimOneButton
  );
  const idoTokenClaimDailyContract = useIdoTokenClaimContract(
    contract_daily,
    daily_obj
  );

  if (item.allocation && item.allocation.claimbles?.length > 0) {
    for (let i = 0; i < item.allocation.claimbles?.length; i++) {
      if (item.allocation.claimbles[i]?.claimedType === 2)
        _claimedTokens += item.allocation.claimbles[i]?.claimToken;
      else if (item.allocation.claimbles[i]?.claimedType === 1)
        _is_refunded_api = 1;
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // claim RGEN
  const claimRGEN = () => {
    window.open("https://launchpad.paragen.io/account", "_blank")?.focus();
  };

  const _handleSwitchNetwork = async () => {
    const _chainId = MAPPING_CHAINID[network];
    switchNetwork(_chainId);
  };

  // handle approve trước khi join
  const _approveJoinPool = async () => {
    setIsLoading(true);
    await _approveBUSD(busdContract, scJoinAddress, amountJoin, usdDecimals)
      .then((res: any) => {
        if (res.hash !== null) {
          res.wait().then((res1: any) => {
            if (res1 !== null) {
              setIsLoading(false);
              setApproveJoin(true);
              if (!is_allow_joinpool) {
                setDisnableJoin(true);
              }

              message.success({
                type: "success",
                content: "Approved successfully!!!",
                className: "custom-class",
                duration: 2,
              });
            } else {
              setIsLoading(false);
              setApproveJoin(false);

              message.error({
                type: "error",
                content: "Error",
                className: "custom-class",
                duration: 2,
              });
            }
          });
        }
      })
      .catch((error: any) => {
        message.error({
          type: "error",

          content: error?.message,
          className: "custom-class",
          duration: 2,
        });
        setIsLoading(false);
      });
  };

  // handle RF
  const _handleRefund = async () => {
    try {
      setLoadingRefund(true);
      await _refund(
        claimContract,
        amountJoin,
        item.allocation.claimbles[0],
        usdDecimals
      ).then((res: any) => {
        res.wait().then((res1: any) => {
          if (res1 !== null) {
            setLoadingRefund(false);
            setRefundedFromSC(true);
          }
        });
      });

      return true;
    } catch (error: any) {
      setLoadingRefund(false);

      if (error?.message) {
        message.error({
          type: "error",
          content: error?.message,
          className: "custom-class",
          duration: 2,
        });
      }
      return false;
    }
  };

  // handle Claim
  const _handleClaim = async () => {
    if (showPopupClaim) {
      actions.changeOpenModalClaim(item.id);
      return;
    }
    if (item.allocation.claimbles[0]) {
      try {
        setIsLoading(true);
        await _claimTokens(
          claimContract,
          item.allocation.claimbles[0],
          decimals
        ).then((res: any) => {
          res.wait().then((res1: any) => {
            if (res1 !== null) {
              setIsLoading(false);
              setIsShowClaim(false);
            }
          });
        });
      } catch (error: any) {
        setIsLoading(false);
        if (error?.message) {
          message.error({
            type: "error",
            content: error?.message,
            className: "custom-class",
            duration: 2,
          });
        }
      }
    }
  };

  // HANDLE CLICK JOIN POOL
  const _handleJoinPool = async (idoId: any) => {
    setIsLoading(true);
    if (!busdContract) {
      return;
    }

    const balance = await busdContract.balanceOf(accountAddress);

    if (convertWeiToToken(balance, usdDecimals) < amountJoin) {
      message.warning({
        type: "warning",
        content: "Not enough balance",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    if (network === NETWORK.BSC && chainId !== 56) {
      message.info({
        type: "info",
        content: "Please select network BSC",
        className: "custom-class",
        duration: 2,
      });

      setIsLoading(false);
      return;
    }
    if (network === NETWORK.Ethereum && chainId !== 1) {
      message.info({
        type: "info",
        content: "Please select network Ethereum",
        className: "custom-class",
        duration: 2,
      });

      setIsLoading(false);
      return;
    }
    if (network === NETWORK.Poly && chainId !== 137) {
      message.info({
        type: "info",
        content: "Please select network Polygon",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    if (network === NETWORK.Avax && chainId !== 43114) {
      message.info({
        type: "info",
        content: "Please select network Avax chain",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    if (network === NETWORK.OKC && chainId !== 66) {
      message.info({
        type: "info",
        content: "Please select network OKX Chain",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    if (network === NETWORK.ARB && chainId !== 42161) {
      message.info({
        type: "info",
        content: "Please select network Arbitrum Chain",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    if (network === NETWORK.OPBNB && chainId !== 204) {
      message.info({
        type: "info",
        content: "Please select network opBNB Chain",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    if (network === NETWORK.BASE && chainId !== 8453) {
      message.info({
        type: "info",
        content: "Please select network Base Chain",
        className: "custom-class",
        duration: 2,
      });
      setIsLoading(false);
      return;
    }
    await _joinPoolNew(
      joinPoolContract,
      amountJoin,
      idoId,
      joinSignBusd,
      chainId,
      usdDecimals
    )
      .then((res: any) => {
        if (res.hash !== null) {
          res.wait().then((res1: any) => {
            if (res1 !== null) {
              setIsLoading(false);
              setIsJoined(true);
            }
          });
        } else {
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        message.warning({
          type: "warning",
          content: error?.data?.message,
          className: "custom-class",
          duration: 2,
        });
        setIsLoading(false);
      });
  };

  // handle claim change
  const _handleClaimChange = async () => {
    try {
      setLoadingClaimChange(true);
      await _refundChange(
        claimChangeContract,
        refundBusd,
        signRefund,
        usdDecimals
      )
        .then((res: any) => {
          res.wait().then((res1: any) => {
            if (res1 !== null) {
              setLoadingClaimChange(false);
              setClaimChangeFromSC(true);
            }
          });
        })
        .catch((err: any) => {
          message.warning({
            type: "warning",
            content: err.error?.data?.message,
            className: "custom-class",
            duration: 2,
          });
          setLoadingClaimChange(false);
        });

      return true;
    } catch (error: any) {
      setLoadingClaimChange(false);
      if (error?.data) {
        message.warning({
          type: "warning",
          content: error?.data?.message,
          className: "custom-class",
          duration: 2,
        });
      }
      return false;
    }
  };

  // Control state Button
  const btnApprove = () => {
    const obj = {
      className: `btn btn-join`,
      disabled: is_hide_btn || isApproveJoin || isLoading || isFullSlot,
      loading: isLoading,
    };
    return obj;
  };
  const btnJoinPool = () => {
    const obj = {
      className: `btn btn-join`,
      disabled:
        is_hide_btn || isLoading || isJoined || disnableJoin || isFullSlot,
      loading: isLoading,
    };
    return obj;
  };

  const btnClaim = () => {
    const obj = {
      className: `btn btn-join`,
      disabled: is_hide_btn || isLoading || !isShowClaim,
      loading: isLoading,
    };
    return obj;
  };

  const btnRefund = () => {
    const obj = {
      className: `btn btn-join mar-b-10 rf-1`,
      disabled: is_hide_btn || isLoading || isRefundedRC || isLoadingRefund,
      loading: isLoadingRefund,
    };
    return obj;
  };

  const btnclaimChange = () => {
    const obj = {
      className: `btn btn-join`,
      disabled: is_hide_btn || isLoadingClaimChange || isClaimChangeRC,
      loading: isLoadingClaimChange,
    };
    return obj;
  };

  const btnclaimChangeCalcula = () => {
    const obj = {
      className: `btn btn-join`,
      disabled: true,
    };
    return obj;
  };

  const btnSwitchNetwork = () => {
    const obj = {
      className: `btn btn-join`,
      disabled: false,
    };
    return obj;
  };

  const formatNumber = (x: any, max: any) => {
    return x.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: max,
    });
  };

  let percentProcess;

  if (totalRaise > 0) {
    if (!ignoreFullSlot && totalJoined >= totalRaise) isFullSlot = true;
    const _percent = (totalJoined / totalRaise) * 100;
    if (_percent > item.percentProcess) {
      percentProcess = Number(_percent).toFixed(2);
    } else {
      percentProcess = item.percentProcess;
    }
  }

  // useEffect---------------------------------------
  // check approve when reload page
  useEffect(() => {
    if (joinStatus === 2 || isJoined) {
      setApproveJoin(true);
    } else if (busdContract && scJoinAddress !== null && scJoinAddress !== "") {
      busdContract.allowance(accountAddress, scJoinAddress).then((res: any) => {
        if (Number(convertWeiToToken(res, usdDecimals)) > 0) {
          setApproveJoin(true);
        } else {
          setApproveJoin(false);
        }
      });
    }
  }, [accountAddress, scJoinAddress, joinStatus, isJoined]);

  useEffect(() => {
    if (accountAddress) {
      if (scClaimAddress !== null && scClaimAddress !== "") {
        _totalClaimed(claimContract, accountAddress, decimals).then(
          (res: any) => {
            setTotalClaimed(res);
          }
        );

        _isClaimed(claimContract, accountAddress).then((res: any) => {
          setIsClaimed(res);
        });

        _isRefundedRC(claimContract, accountAddress).then((res: any) => {
          setRefundedFromSC(res);
        });
      }

      if (scClaimChangeAddress !== null && scClaimChangeAddress !== "") {
        _isClaimChange(claimChangeContract, accountAddress).then((res: any) => {
          setClaimChangeFromSC(res);
        });
      }

      if (joinStatus === 2) {
        setIsJoined(true);
      } else if (scJoinAddress !== null && scJoinAddress !== "") {
        _isJoined(joinPoolContract, accountAddress, item.id, item.id).then(
          (res: any) => {
            setIsJoined(res);
          }
        );
      }
    }
  }, [
    accountAddress,
    scClaimAddress,
    scClaimChangeAddress,
    joinStatus,
    scJoinAddress,
  ]);

  useEffect(() => {
    if (accountAddress && claimbles?.length > 0 && !showPopupClaim) {
      _showClaimBtn(
        claimContract,
        accountAddress,
        item.allocation.claimbles[0].claimToken,
        decimals
      ).then((res: any) => {
        setIsShowClaim(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddress, claimContract, scClaimAddress, allocation, decimals]);

  useEffect(() => {
    try {
      if (contract_daily !== null && contract_daily !== "") {
        totalDailyClaimed(idoTokenClaimDailyContract, accountAddress).then(
          (res: any) => {
            const _totalClaimDaily = convertWeiToToken(
              res.tokensClaimed,
              decimals
            );
            setTotalClaimDaily(_totalClaimDaily);
          }
        );
      }
    } catch (error) {
      // TODO
    }
  }, [accountAddress, contract_daily]);

  useEffect(() => {
    if (
      claimbles?.length > 0 &&
      item.allocation.claimbles[0].signToken !== null &&
      claimbles?.length > 0 &&
      allocation?.claimbles[0]?.signBusd !== null
    ) {
      setAddClass("btn-claim-rf");
    }
  }, [addClass]);

  useEffect(() => {
    try {
      if (scJoinAddress !== null && scJoinAddress !== "") {
        totalJoinPool(joinPoolContract).then((res: any) => {
          const _totalJoined = convertWeiToToken(res, usdDecimals);

          setTotalJoined(_totalJoined);
        });
      }
    } catch {
      // TODO
    }
  }, [scJoinAddress, joinPoolContract]);

  useEffect(() => {
    setTimeout(() => {
      setIsSke(false);
    }, 1000);
  }, []);

  return (
    <>
      <div className="colum w-33">
        <div className="content-details-rounds">
          <div className="rounds-name">{item?.name}</div>
          <div className="url-name">
            <span className="img">
              <img src={logo} alt="" />
            </span>{" "}
            {unit}
          </div>
          <ul className="list-info-details">
            <li>
              <span className="txt-l">Total raised:</span>
              <span className="txt-r">
                <Value unit="$" value={item?.totalRaise} />
              </span>
            </li>
            <li>
              <span className="txt-l">Swap Rate:</span>
              <span className="txt-r">{item?.swapAmount}</span>
            </li>
            <li>
              <span className="txt-l">Start Pool:</span>
              <span className="txt-r">{item?.startDate}</span>
            </li>
            <li>
              <span className="txt-l">End Pool:</span>
              <span className="txt-r">{item?.endDate}</span>
            </li>
            <li>
              <span className="txt-l">Vesting:</span>
              <span className="txt-r">
                <div className="tooltips">
                  {item?.vesting ? item?.vesting : "TBA"}
                </div>
              </span>
            </li>
            <li>
              <span className="txt-l">Claim:</span>
              <span className="txt-r color-green">{item?.claimTime}</span>
            </li>
          </ul>
          {item.status === STATUS.COMING ? (
            <>
              <div className="rounds-join-pool">
                {" "}
                Commit fund starts in : TBA
              </div>
            </>
          ) : item.status === STATUS.GOING ? (
            <>
              {is_commit_fund ? (
                <div className="rounds-join-pool">Commit fund starts in</div>
              ) : (
                <div className="rounds-join-pool">Join pool starts in</div>
              )}
              <span className="times-join">
                <Countdown
                  date={getProgressTime(item.startOpenIdo)}
                  renderer={rendererCountDown}
                />
              </span>
            </>
          ) : item.status === STATUS.OPEN ? (
            <>
              {is_commit_fund ? (
                <div className="rounds-join-pool">Commit fund close in</div>
              ) : (
                <div className="rounds-join-pool">Join pool close in</div>
              )}{" "}
              <span className="times-join">
                <Countdown
                  date={getProgressTime(item.closeJoinIdo)}
                  renderer={rendererCountDown}
                />
              </span>
            </>
          ) : (
            ""
          )}

          <div className="rounds-process">
            <span className="title-process">COMMIT PROGRESS</span>
            {symbol !== "RGEN" ? (
              <Progress
                strokeColor={{
                  "0%": "rgb(249 208 100 / 89%)",
                  "100%": "#F9D064",
                }}
                // percent={percentProcess}
                percent={percentProcess}
                status="active"
              />
            ) : (
              <Progress
                strokeColor={{
                  "0%": "rgb(249 208 100 / 89%)",
                  "100%": "#F9D064",
                }}
                // percent={percentProcess}
                percent={100}
                status="active"
              />
            )}
            {/* {symbol !== "RGEN" ? (
              item.status === STATUS.CLOSE &&
                allocation !== null &&
                isJoined ? (
                isSke ? (
                  <SkeletonLoading />
                ) : (
                  <Progress
                    strokeColor={{ "0%": "rgb(249 208 100 / 89%)", "100%": "#F9D064" }}
                    percent={processClaim}
                    status="active"
                  />
                )
              ) : isSke ? (
                <SkeletonLoading />
              ) : (
                <Progress
                  strokeColor={{ "0%": "rgb(249 208 100 / 89%)", "100%": "#F9D064" }}
                  // percent={percentProcess}
                  percent={percentProcess}
                  status="active"
                />
              )
            ) : (
              ""
            )} */}

            {/* <span className="balance-rounds">0/{item?.totalRaise} $</span> */}
          </div>
          <div className="btn-rounds">
            {isJoined && item.status === STATUS.CLOSE ? (
              <>
                <div className="line-allo"></div>
                <div className="y-allo">YOUR ALLOCATION</div>
                {/* hide/show button remaining fund */}
                {is_commit_fund && !is_hide_btn ? (
                  <>
                    {item.status === STATUS.CLOSE && signRefund !== null ? (
                      <>
                        {fundProcess === 1 ? (
                          <div className="row-chane">
                            <div className="text">Remaining Fund:</div>
                            <div className="num-change">
                              {isClaimChangeRC ? (
                                <span className="next-ves">
                                  Claimed {Number(refundBusd).toFixed(2)}{" "}
                                  {symbolUSD}
                                </span>
                              ) : (
                                <Button
                                  {...btnclaimChange()}
                                  onClick={() => _handleClaimChange()}
                                >
                                  Claim {Number(refundBusd).toFixed(2)}{" "}
                                  {symbolUSD}
                                </Button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="row-chane">
                            <div className="text">Remaining Fund:</div>
                            <div className="num-change">
                              <Button {...btnclaimChangeCalcula()}>
                                Claim {Number(refundBusd).toFixed(2)}{" "}
                                {symbolUSD}
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="row-chane">
                        <div className="text">Remaining Fund:</div>
                        <div className="num-change">
                          <Button {...btnclaimChangeCalcula()}>
                            Calculating
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* show next vesting */}
                <div className="row-chane">
                  <div className="text">Next Vesting:</div>
                  <div className="num-change">
                    <span className="next-ves">--</span>
                  </div>
                </div>

                {isRefundedRC ? (
                  <div className="row-chane">
                    <div className="text">Refunded (100%)</div>
                    <div className="num-change">
                      <span className="next-ves">
                        {formatNumber(amountJoin, 6)} {symbolUSD}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="row-chane">
                    <div className="text">Claimed ({processClaim}%)</div>
                    <div className="num-change">
                      <span className="next-ves">
                        {formatNumber(_claimedTokens, 2)} {symbol}
                      </span>
                    </div>
                  </div>
                )}
                {/* show refunded */}

                {/* show allocation */}
                {signRefund !== null || !is_commit_fund ? (
                  <div className="row-chane">
                    <div className="text">Allocation:</div>
                    <div className="num-change">
                      <span className="next-ves">
                        {formatNumber(item.allocation?.joinToken, 2)} {symbol}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="row-chane">
                    <div className="text">Allocation:</div>
                    <div className="num-change">
                      <span className="next-ves">Calculating</span>
                    </div>
                  </div>
                )}
                {/* show investment */}
                <div className="row-chane">
                  <div className="text">Investment:</div>
                  <div className="num-change">
                    <span className="next-ves">
                      {formatNumber(amountJoin, 6)} {symbolUSD}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {item.status === STATUS.OPEN ? (
              <>
                {isJoined ? (
                  <div className="row-chane">
                    <div className="text">Investment:</div>
                    <div className="num-change">
                      <span className="next-ves">
                        {formatNumber(amountJoin, 6)} {symbolUSD}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="row-chane">
                    <div className="text">Funds needed:</div>
                    <div className="num-change">
                      <span className="next-ves">
                        {formatNumber(amountJoin, 6)} {symbolUSD}
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {/* {is_commit_fund && !is_hide_btn ? (
              item.status === STATUS.CLOSE && signRefund !== null ? (
                fundProcess === 1 ? (
                  <>
                    <Button
                      {...btnclaimChange()}
                      onClick={() => _handleClaimChange()}
                      className="btn btn-join mar-b-10"
                    >
                      {isClaimChangeRC
                        ? "All fund have been claimed"
                        : "Claim remaining fund"}
                    </Button>
                    <div className="row-chane">
                      <div className="text">Change:</div>
                      <div className="num-change">
                        {Number(refundBusd).toFixed(2)} {symbolUSD}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Button {...btnclaimChange()} disabled>
                      Claim remaining fund
                    </Button>
                    <div className="row-chane">
                      <div className="text">Change:</div>
                      <div className="num-change">
                        {Number(refundBusd).toFixed(2)} {symbolUSD}
                      </div>
                    </div>
                  </>
                )
              ) : (
                <>
                  {item.status === STATUS.CLOSE ? (
                    <Button {...btnclaimChangeCalcula()}>Claim Change</Button>
                  ) : (
                    ""
                  )}
                  <div className="row-chane">
                    <div className="text">Fund commited:</div>
                    <div className="num-change">
                      {Number(amountJoin).toFixed(2)} {symbolUSD}
                    </div>
                  </div>
                </>
              )
            ) : (
              ""
            )} */}

            {allocation === null && account ? (
              <Button className="btn btn-join" disabled>
                You’re not whitelisted
              </Button>
            ) : (
              <>
                {item.status === STATUS.CLOSE && !is_hide_btn ? (
                  !isJoined ? ( // nếu k join
                    <Button className="btn btn-join" disabled>
                      You're not joined the pool
                    </Button>
                  ) : // check đã rf hay chưa
                    isRefundedRC ? (
                      <Button className="btn btn-join" disabled>
                        Refunded
                      </Button>
                    ) : symbol === "RGEN" ? (
                      <Button
                        className="btn btn-join"
                        onClick={() => claimRGEN()}
                      >
                        View Claim
                      </Button>
                    ) : is_commit_fund && signRefund === null ? (
                      ""
                    ) : (
                      <>
                        <div className={addClass}>
                          {showPopupClaim ? ( // TH claim loai cu
                            <Button
                              className="btn btn-join"
                              onClick={() => _handleClaim()}
                            >
                              View Claim
                            </Button>
                          ) : // TH claim new
                            claimbles?.length > 0 &&
                              item.allocation.claimbles[0].signToken !== null ? (
                              <Button
                                {...btnClaim()}
                                onClick={() => _handleClaim()}
                                className="btn btn-join mar-b-10 claim-1"
                              >
                                {isLoading ? (
                                  <>
                                    {" "}
                                    Claim <i className="fa fa-spinner fa-spin" />
                                  </>
                                ) : !isShowClaim ? (
                                  "Claimed"
                                ) : (
                                  "Claim"
                                )}
                              </Button>
                            ) : (
                              <Button {...btnClaim()} disabled>
                                Claim
                              </Button>
                            )}

                          {/* RFund */}
                          {!isClaimed &&
                            claimbles?.length > 0 &&
                            allocation?.claimbles[0]?.signBusd !== null ? (
                            <Button
                              {...btnRefund()}
                              onClick={() => _handleRefund()}
                            >
                              Refund
                              {isLoadingRefund ? (
                                <i className="fa fa-spinner fa-spin" />
                              ) : (
                                ""
                              )}
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* check KYC */}
                        {item.enableKYC && !allocation.completeKYC ? (
                          <Button className="btn btn-join" disabled>
                            You're not completed the KYC
                          </Button>
                        ) : (
                          ""
                        )}

                        {/* show Schedule */}
                        {!showPopupClaim ? (
                          <div className="inf-claim">
                            <ScheduleOutlined onClick={showModal} />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )
                ) : !isApproveJoin && !isJoined && !is_hide_btn ? (
                  <Button {...btnApprove()} onClick={() => _approveJoinPool()}>
                    {isLoading
                      ? "Waiting"
                      : !isFullSlot
                        ? "Approve"
                        : "Fully joined"}
                  </Button>
                ) : is_allow_joinpool && !is_hide_btn ? (
                  // after commit fund change text = Claim change && disable
                  <Button
                    {...btnJoinPool()}
                    onClick={() => _handleJoinPool(item.id)}
                  >
                    {isLoading
                      ? "Waiting"
                      : isJoined
                        ? is_commit_fund
                          ? "Claim Change"
                          : "Joined"
                        : is_commit_fund
                          ? "Commit fund"
                          : isFullSlot
                            ? "Fully joined"
                            : "Join pool"}
                  </Button>
                ) : !is_hide_btn ? (
                  <Button {...btnJoinPool()} disabled>
                    Coming soon
                  </Button>
                ) : (
                  <>
                    {accountAddress ? (
                      <Button
                        {...btnSwitchNetwork()}
                        onClick={() => _handleSwitchNetwork()}
                      >
                        Switch Network
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {/* show text khi co allo */}
                <div className="row-claim-box">
                  {!is_commit_fund ? (
                    item.status !== STATUS.CLOSE && !isJoined ? (
                      <div className="row-claim-token">
                        <div className="text">Funds needed:</div>
                        <div className="name-row">
                          {formatNumber(amountJoin, 6)} {symbolUSD}
                        </div>
                      </div>
                    ) : !isJoined ? (
                      ""
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {!isJoined ? "" : ""}

                  {!isJoined ? (
                    ""
                  ) : (
                    <>
                      {item.status === STATUS.CLOSE &&
                        is_commit_fund &&
                        signRefund !== null ? (
                        <div className="row-allocation-token">
                          <div className="text">Investment:</div>
                          <div className="name-row">
                            {formatNumber(amountJoin, 6)} {symbolUSD}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            {!account ? (
              <div className="btn btn-join please-connect">Connect Wallet</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* @ts-ignore this lib is incompatible with react18  */}
      <Modal
        className="modal-claimed-after"
        title="Schedule Claim"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <div className="bsc-p-launchpad_detail-allocations">
          <table>
            <tr>
              <th style={{ width: "33%" }}>Claimable</th>
              <th style={{ width: "34%" }}>Claim Date</th>
              <th style={{ width: "33%" }}>%</th>
            </tr>

            {allocation !== null ? (
              allocation.schedules?.map((item: any, i: any) => (
                <tr key={i}>
                  <td style={{ width: "33%", paddingLeft: "15px" }}>
                    {item.claimToken !== null ? item.claimToken : "TBA"}
                  </td>
                  <td
                    style={{
                      width: "34%",
                      justifyContent: "start",
                      paddingLeft: "15px",
                    }}
                  >
                    {item.claimTime !== null ? item.claimTime : "TBA"}
                  </td>
                  <td style={{ width: "33%", paddingLeft: "15px" }}>
                    {item.percentage !== null ? item.percentage : "TBA"}
                  </td>
                </tr>
              ))
            ) : (
              <div className="bsc-p-launchpad_detail-allocations-empty">
                <span>Empty</span>
              </div>
            )}
          </table>
        </div>
      </Modal>
    </>
  );
};


const Item1 = ({
  totalRaise,
  percentProcess,
  status,
  symbol,
  name,
  logo,
  unit,
  swapAmount,
  startDate,
  endDate,
  vesting,
  claimTime,
  startOpenIdo,
  closeJoinIdo,
}: any) => {
  return (
    <>
      <div className="colum w-33">
        <div className="content-details-rounds">
          <div className="rounds-name">{name}</div>
          <div className="url-name">
            <span className="img">
              <img src={logo} alt="" />
            </span>{" "}
            {unit}
          </div>
          <ul className="list-info-details">
            <li>
              <span className="txt-l">Total raised:</span>
              <span className="txt-r">
                <Value unit="$" value={totalRaise} />
              </span>
            </li>
            <li>
              <span className="txt-l">Swap Rate:</span>
              <span className="txt-r">{swapAmount}</span>
            </li>
            <li>
              <span className="txt-l">Start Pool:</span>
              <span className="txt-r">{startDate}</span>
            </li>
            <li>
              <span className="txt-l">End Pool:</span>
              <span className="txt-r">{endDate}</span>
            </li>
            <li>
              <span className="txt-l">Vesting:</span>
              <span className="txt-r">
                <div className="tooltips">
                  {vesting ? vesting : "TBA"}
                </div>
              </span>
            </li>
            <li>
              <span className="txt-l">Claim:</span>
              <span className="txt-r color-green">{claimTime}</span>
            </li>
          </ul>
          {status === STATUS.COMING ? (
            <>
              <div className="rounds-join-pool">
                {" "}
                Commit fund starts in : TBA
              </div>
            </>
          ) : status === STATUS.GOING ? (
            <>
              <div className="rounds-join-pool">Join pool starts in</div>
              <span className="times-join">
                <Countdown
                  date={getProgressTime(startOpenIdo)}
                  renderer={rendererCountDown}
                />
              </span>
            </>
          ) : status === STATUS.OPEN ? (
            <>
              <div className="rounds-join-pool">Join pool close in</div>
              <span className="times-join">
                <Countdown
                  date={getProgressTime(closeJoinIdo)}
                  renderer={rendererCountDown}
                />
              </span>
            </>
          ) : (
            ""
          )}

          <div className="rounds-process">
            <span className="title-process">COMMIT PROGRESS</span>
            {symbol !== "RGEN" ? (
              <Progress
                strokeColor={{
                  "0%": "rgb(249 208 100 / 89%)",
                  "100%": "#F9D064",
                }}
                percent={percentProcess}
                status="active"
              />
            ) : (
              <Progress
                strokeColor={{
                  "0%": "rgb(249 208 100 / 89%)",
                  "100%": "#F9D064",
                }}
                percent={100}
                status="active"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const BottomIdoDetails = (props: any) => {
  const { address } = useAccount();
  const data = props.dataBody;
  let objDetail = props?.objDetail
  return (
    <>
      <div className="main-list-details">
        <div className="columns">
          {address ? (
            <>
              {data &&
                data.details?.map((item: any, i: any) => (
                  <Item
                    item={item}
                    logo={data?.logoUrl}
                    unit={data?.unit}
                    symbol={data?.symbol}
                    decimals={data?.decimals}
                    network={data?.network}
                    usdDecimals={data?.decimalUsd}
                    key={i}
                    tokenJoinPool={data.tokenJoinPool}
                    symbolUSD={data.symbolUSD}
                  />
                ))}
            </>
          ) : (
            <>
              {objDetail &&
                objDetail.details?.map((item: any, i: any) => (
                  <Item1
                    totalRaise={item?.totalRaise}
                    status={item?.status}
                    name={item?.name}
                    percentProcess={item?.percentProcess}
                    swapAmount={item?.swapAmount}
                    startDate={item?.startDate}
                    endDate={item?.endDate}
                    vesting={item?.vesting}
                    claimTime={item?.claimTime}
                    startOpenIdo={item?.startOpenIdo}
                    closeJoinIdo={item?.closeJoinIdo}
                    symbol={objDetail?.symbol}
                    logo={objDetail?.logo}
                    unit={objDetail?.unit}
                  />
                ))}
            </>
          )}

          <ModalClaimedAfter dataFromParent={data} />
        </div>
      </div>
    </>
  );
};
export default BottomIdoDetails;
