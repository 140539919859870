import React, { useState, useCallback } from "react";
import { useContext } from "react";
import { ModalContext } from "../../../../components/modal";
import { useNavigate } from "react-router-dom";

import { FormContainer, NFTItem, RowItem, IMGItem, RowButton } from "./style";
import {
  TitleModal,
  ButtonCommon,
  TextCustom,
} from "../../../../components/common/styleCommon";

const SuccessModal = (props: any) => {
  const { onCloseModal }: any = useContext(ModalContext);
  const { detail } = props;

  let navigate = useNavigate();

  const gotoDetail = async (id: any) => {
    navigate(`${"/listing"}/${id}`);
    onCloseModal();
  };
  return (
    <>
      <FormContainer>
        <TitleModal>You successfully bought {detail?.name}</TitleModal>
        <TextCustom color="#616772" fontSize="16px" fontWeight="400">
          Congratulations! You successfully bought <br /> {detail?.name} on
          BSCS.Finance{" "}
        </TextCustom>

        <NFTItem>
          <RowItem>
            <IMGItem style={{ margin: "0 auto", width: "300px" }}>
              <img src={detail?.nftUrl} alt="" />
            </IMGItem>
          </RowItem>

          <RowButton>
            <ButtonCommon onClick={() => gotoDetail(detail?.queryKey)}>
              List for sale
            </ButtonCommon>
          </RowButton>
        </NFTItem>
      </FormContainer>
    </>
  );
};

export default SuccessModal;
