import React, { useMemo, useState, useCallback } from 'react'
import UnstakingFeeCountdownRow from '../StartPools/UnstakingFeeCountdownRow'

import { getFullDisplayBalance } from '../../utilsST/formatBalance'
import Input from '../../componentsST/Inputdisabled/index'
import Modal from '../../componentsST/Modal/index'
import Button from '../../componentsST/ButtonV2/index'
import '../../componentsST/StakeInPoolModal/index.less'
import { useContract } from "../../hooks/useContract";
import sousChefAbi from "../../config/abi/sousChef.json";
import sousChefAbiV3 from "../../config/abi/sousChefV3.json";
import poolsConfig from '../../constantsST/pools'
import { sousUnstakeUserLock,sousUnstakeV3 } from "../../utilsST/callHelpers";
import { message } from "antd";


const WithdrawModal = (props) => {
	const [val, setVal] = useState('0')
	const [pendingTx, setPendingTx] = useState(false)
	const { 
		sousId,
		decimals,
		versionSC,
		onConfirm,
		onDismiss,
		max,
		balance,
		stakingToken,
	} = props

	const fullBalance = useMemo(() => {
		return getFullDisplayBalance(max)
	}, [max])

	const handleChange = useCallback(
		(e) => {
			setVal(e.currentTarget.value)
		},
		[setVal],
	)

	const handleSelectMax = useCallback(() => {
		setVal(fullBalance)
	}, [fullBalance, setVal])

	

	const pool = poolsConfig.find((pool) => pool.sousId === sousId);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const contractV3 = useContract(pool.contractAddress, sousChefAbiV3);
  const handleUnstakeUserLock = async () => {
    try {
      setPendingTx(true);
      await sousUnstakeUserLock(contract, val, decimals).then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
			if(res2 != null ){
				setPendingTx(false);
				onDismiss()
				message.success({
				  type: "success",
				  content: "Unstaking success!",
				  className: "custom-class",
				  duration: 3,
				  
				});
			  }
        });
      })
      .catch((error) => {
        setPendingTx(false);
      });;
    } catch (e) {
      message.error({
        type: "error",
        content: e?.message,
        className: "custom-class",
        duration: 3,
      });
      setPendingTx(false);
    }
  };
  const handleUnstakeV3 = async () => {
    try {
      setPendingTx(true);
      await sousUnstakeV3(contractV3, val, decimals).then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
			if(res2 != null ){
				setPendingTx(false);
				onDismiss()
				message.success({
				  type: "success",
				  content: "Unstaking success!",
				  className: "custom-class",
				  duration: 3,
				  
				});
			  }
        });
      })
      .catch((error) => {
        setPendingTx(false);
      });;
    } catch (e) {
      message.error({
        type: "error",
        content: e?.message,
        className: "custom-class",
        duration: 3,
      });
      setPendingTx(false);
    }
  };
	return (
		<Modal>
			<div className="bsc-stake_in_pool-modal">
				<div className="bsc-stake_in_pool-modal-header">
					<span>Unstake in pool</span>
					<span>{`Unstake ${stakingToken.symbol}`}</span>
				</div>
				<div className="bsc-stake_in_pool-modal-content">
					<div className="bsc-stake_in_pool-modal-content-top">
						<span>{getFullDisplayBalance(balance, stakingToken.decimals)}</span>
						<span>{`${stakingToken.symbol} Locked`}</span>
					</div>
					<div className="bsc-stake_in_pool-modal-content-top">
						<span>{getFullDisplayBalance(max, stakingToken.decimals)}</span>
						<span>{`${stakingToken.symbol} Unlockable`}</span>
					</div>
					<div className="bsc-stake_in_pool-modal-content-mid">
						<Input
							change={handleChange}
							type="number"
							value={val}
							right={
								<>
									<span>{stakingToken.symbol}</span>
									<Button text="Max" primary click={handleSelectMax} />
								</>
							}
						/>
					</div>
					<div className="bsc-stake_in_pool-modal-content-bottom mb-4">
						
						<Button text="Cancel" ghost click={onDismiss} />
						<Button
							text="Confirm"
							primary
							loading={pendingTx}
							disabled={pendingTx || val <= 0}
							click={versionSC === 3
								? handleUnstakeV3
								: handleUnstakeUserLock
		}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default WithdrawModal
