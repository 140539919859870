import { useEffect, useState, memo } from "react";
import { message, Spin, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import {
  TOKEN_PROGRAM_ID,
  getOrCreateAssociatedTokenAccount,
} from "@solana/spl-token";

import { useHookSol } from "../../Solana/Store-sol";
import { SOLANA_HOST } from "../detailsSOL/const";
import IDO_IDL from "./bscs_ido.json";

const connection = new anchor.web3.Connection(SOLANA_HOST);
// let totalClaim = 0;

const Item = ({ item, usdtAddress, index }: any) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const [isLoadingRF, setIsLoadingRF] = useState(false);
  const [isLoadingRF_All, setIsLoadingRF_All] = useState(false);

  const [isClaim, setIsClaim] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [isRefundAll, setIsRefundAll] = useState(false);

  // useEffect(() => {
  //   totalClaim = totalClaim + amoundClaim;
  // }, [amoundClaim]);
  // console.log("totalClaim11111", totalClaim);

  const [isShowRefundAll, setIsShowRefundAll] = useState(false);

  // const [disableRF, setdisableRF] = useState(false);
  // const [disableClaim, setdisableClaim] = useState(false);

  //--- control button
  const btnClaim = () => {
    const obj = {
      className: `btn-claim-sol`,
      disabled:
        isClaim ||
        isRefund ||
        isRefundAll ||
        isLoadingRF ||
        isLoadingClaim ||
        isLoadingRF_All,
    };
    return obj;
  };
  const btnRefund = () => {
    const obj = {
      className: `btn-refund-sol`,
      disabled:
        isClaim ||
        isRefund ||
        isRefundAll ||
        isLoadingRF ||
        isLoadingClaim ||
        isLoadingRF_All,
    };
    return obj;
  };
  const btnRefundAll = () => {
    const obj = {
      className: `btn-refundAll-sol`,
      disabled:
        isClaim ||
        isRefund ||
        isRefundAll ||
        isLoadingRF ||
        isLoadingClaim ||
        isLoadingRF_All,
    };
    return obj;
  };
  //---------------------------------

  //---handle button------------------
  let IDO_PROGRAM_ID: any;
  if (item?.contractAddress) {
    IDO_PROGRAM_ID = new PublicKey(item?.contractAddress);
  }

  const provider = new anchor.AnchorProvider(
    connection,
    window?.solana,
    anchor.AnchorProvider.defaultOptions()
  );

  let program: any;
  if (IDO_PROGRAM_ID && provider) {
    program = new anchor.Program<any>(IDO_IDL, IDO_PROGRAM_ID, provider);
  }

  const getUserInfoPDA = async (userPubkey: PublicKey): Promise<[any, any]> => {
    const seed = userPubkey;
    if (program) {
      return await anchor.web3.PublicKey.findProgramAddress(
        [seed?.toBuffer(), Buffer.from("ido_insert_whitelist")],
        program?.programId
      );
    } else return ["", ""];
  };

  const getMinInfoPDA = async (minPubkey: PublicKey): Promise<[any, any]> => {
    const seed = minPubkey;
    if (program) {
      return await anchor.web3.PublicKey.findProgramAddress(
        [seed?.toBuffer(), Buffer.from("ido_pool_config")],
        program?.programId
      );
    } else return ["", ""];
  };

  const handleRefund = async () => {
    try {
      setIsLoadingRF(true);
      // setdisableClaim(true);
      let usdtAta = await getOrCreateAssociatedTokenAccount(
        connection,
        window.solana?.publicKey,
        usdtAddress,
        window.solana?.publicKey
      );

      let [programUsdtAta, programUsdtAtaBump] =
        await anchor.web3.PublicKey.findProgramAddress(
          [usdtAddress.toBuffer()],
          program.programId
        );

      const getConfigPDA = async (
        usdtMintAddress: PublicKey
      ): Promise<[PublicKey, number]> => {
        // const program = anchor.workspace.BscsIdo;
        const seed = usdtMintAddress;
        return await anchor.web3.PublicKey.findProgramAddress(
          [seed.toBuffer(), Buffer.from("ido_pool_config")],
          program.programId
        );
      };
      const [config, _] = await getConfigPDA(usdtAddress);
      let [userInfoPDA, x] = await getUserInfoPDA(window.solana.publicKey);

      let signature = await program.rpc.refund(programUsdtAtaBump, {
        accounts: {
          tokenProgram: TOKEN_PROGRAM_ID,
          userUsdtAta: usdtAta.address,
          userUsdtAtaAuthority: window?.solana?.publicKey,
          programUsdtAta: programUsdtAta,
          programUsdtAuthority: programUsdtAta,
          usdtMint: usdtAddress,
          payer: window?.solana?.publicKey,
          config: config,
          userInfo: userInfoPDA,
        },
      });
      if (signature) {
        const result = await connection.getSignatureStatus(signature, {
          searchTransactionHistory: true,
        });
        if (result.value?.err === null) {
          setIsLoadingRF(false);
          setIsRefund(true);
          message.success({
            type: "success",
            content: "Refund Success",
            className: "custom-class",
            duration: 2,
          });
        }
      }
    } catch (error: any) {
      if (error?.message) {
        let err = error?.message.toLowerCase();
        let index = err?.indexOf("error message");

        let errCustom = err?.slice(index);
        if (index !== -1) {
          message.error({
            type: "error",
            content: errCustom,
            className: "custom-class",
            duration: 2,
          });
        } else {
          message.error({
            type: "error",
            content: error?.message,
            className: "custom-class",
            duration: 2,
          });
        }
      }
      setIsLoadingRF(false);
    } finally {
      // setdisableClaim(false);
    }
  };

  const handleRefundAll = async () => {
    try {
      setIsLoadingRF_All(true);
      // setdisableClaim(true);
      let usdtAta = await getOrCreateAssociatedTokenAccount(
        connection,
        window.solana?.publicKey,
        usdtAddress,
        window.solana?.publicKey
      );

      let [programUsdtAta, programUsdtAtaBump] =
        await anchor.web3.PublicKey.findProgramAddress(
          [usdtAddress.toBuffer()],
          program.programId
        );

      const getConfigPDA = async (
        usdtMintAddress: PublicKey
      ): Promise<[PublicKey, number]> => {
        // const program = anchor.workspace.BscsIdo;
        const seed = usdtMintAddress;
        return await anchor.web3.PublicKey.findProgramAddress(
          [seed.toBuffer(), Buffer.from("ido_pool_config")],
          program.programId
        );
      };
      const [config, _] = await getConfigPDA(usdtAddress);
      let [userInfoPDA, x] = await getUserInfoPDA(window.solana.publicKey);

      let signature = await program.rpc.refundAll(programUsdtAtaBump, {
        accounts: {
          tokenProgram: TOKEN_PROGRAM_ID,
          userUsdtAta: usdtAta.address,
          userUsdtAtaAuthority: window?.solana?.publicKey,
          programUsdtAta: programUsdtAta,
          programUsdtAuthority: programUsdtAta,
          usdtMint: usdtAddress,
          payer: window?.solana?.publicKey,
          config: config,
          userInfo: userInfoPDA,
        },
      });
      if (signature) {
        const result = await connection.getSignatureStatus(signature, {
          searchTransactionHistory: true,
        });
        if (result.value?.err === null) {
          setIsLoadingRF_All(false);
          setIsRefundAll(true);
          message.success({
            type: "success",
            content: "Refund All Success",
            className: "custom-class",
            duration: 2,
          });
        }
      }
    } catch (error: any) {
      if (error?.message) {
        let err = error?.message.toLowerCase();
        let index = err?.indexOf("error message");

        let errCustom = err?.slice(index);
        if (index !== -1) {
          message.error({
            type: "error",
            content: errCustom,
            className: "custom-class",
            duration: 2,
          });
        } else {
          message.error({
            type: "error",
            content: error?.message,
            className: "custom-class",
            duration: 2,
          });
        }
      }
      setIsLoadingRF_All(false);
    } finally {
      // setdisableClaim(false);
    }
  };

  const handleClaim = async () => {
    try {
      setIsLoadingClaim(true);
      // setdisableRF(true);
      let usdtAta = await getOrCreateAssociatedTokenAccount(
        connection,
        window?.solana?.publicKey,
        usdtAddress,
        window?.solana?.publicKey
      );

      const getConfigPDA = async (
        usdtMintAddress: PublicKey
      ): Promise<[PublicKey, number]> => {
        const seed = usdtMintAddress;
        return await anchor.web3.PublicKey.findProgramAddress(
          [seed.toBuffer(), Buffer.from("ido_pool_config")],
          program.programId
        );
      };
      const [config, _] = await getConfigPDA(usdtAddress);
      let [userInfoPDA, x] = await getUserInfoPDA(window.solana.publicKey);

      let [programUsdtAta, programUsdtAtaBump] =
        await anchor.web3.PublicKey.findProgramAddress(
          [usdtAddress.toBuffer()],
          program.programId
        );
      let signature = await program.rpc.claim(programUsdtAtaBump, {
        accounts: {
          tokenProgram: TOKEN_PROGRAM_ID,
          userUsdtAta: usdtAta.address,
          userUsdtAtaAuthority: window?.solana?.publicKey,
          programUsdtAta: programUsdtAta,
          programUsdtAuthority: programUsdtAta,
          usdtMint: usdtAddress,
          payer: window?.solana?.publicKey,
          config: config,
          userInfo: userInfoPDA,
        },
      });

      if (signature) {
        const result = await connection.getSignatureStatus(signature, {
          searchTransactionHistory: true,
        });
        if (result.value?.err === null) {
          setIsLoadingClaim(false);
          setIsClaim(true);
          message.success({
            type: "success",
            content: "Claim Success",
            className: "custom-class",
            duration: 2,
          });
          // getData();
        }
      }
    } catch (error: any) {
      let startMes = error?.toString().indexOf("Error Message");
      if (startMes !== -1) {
        let showMes = error.toString().slice(startMes);
        message.error({
          type: "error",
          content: showMes,
          className: "custom-class",
          duration: 2,
        });
      } else {
        message.error({
          type: "error",
          content: error?.message,
          className: "custom-class",
          duration: 2,
        });
      }

      setIsLoadingClaim(false);
    } finally {
      // setdisableRF(false);
      setIsLoadingClaim(false);
    }
  };
  //----------------------------------
  //-----check data--------
  const getData = async () => {
    let [userInfo, y]: any = [];
    if (window?.solana?.publicKey) {
      [userInfo, y] = await getUserInfoPDA(window?.solana?.publicKey);
    }
    if (program && userInfo) {
      await program?.account?.userInfoAccount
        .fetch(userInfo)
        .then((res: any) => {
          if (res?.claimAmount.toNumber() === res?.claimedAmount.toNumber()) {
            // setAmountClaim(res?.claimedAmount.toNumber() / LAMPORTS_PER_SOL);
            setIsClaim(true);
          }

          if (res?.refundAmount.toNumber() === res?.refundedAmount.toNumber()) {
            // setAmountRefund(res?.refundedAmount.toNumber() / LAMPORTS_PER_SOL);
            setIsRefund(true);
          }
          if (res?.refundAmountFull.toNumber() > 0) {
            setIsShowRefundAll(true);
          }
          if (
            res?.refundAmountFull.toNumber() === res?.refundedAmount.toNumber()
          ) {
            // setAmountRefundAll(
            //   res?.refundAmountFull.toNumber() / LAMPORTS_PER_SOL
            // );
            setIsRefundAll(true);
          }
        })
        .catch((err: any) => {
          console.log(
            "%c [ err ]-522",
            "font-size:13px; background:pink; color:#bf2c9f;",
            err
          );
        });
    } else {
      // setIsWaiting(false);
      // setIsWaitingClaim(false);
    }
  };

  useEffect(() => {
    getData();
  }, [window?.solana, item]);

  return (
    <>
      <tr>
        <td>{item.claimRound}</td>
        <td>{item.claimTime}</td>
        <td>{item.percentage}</td>
        <td>
          {isRefundAll ? (
            ""
          ) : (
            <>
              {item.signToken !== null ? (
                <>
                  <button
                    type="button"
                    {...btnClaim()}
                    onClick={() => handleClaim()}
                  >
                    {isClaim ? (
                      "Claimed"
                    ) : (
                      <>
                        {isLoadingClaim ? (
                          <>
                            Waiting <Spin indicator={antIcon} />
                          </>
                        ) : (
                          "Claim"
                        )}
                      </>
                    )}
                  </button>
                </>
              ) : (
                <button {...btnClaim()} disabled>
                  Claim
                </button>
              )}

              {item.signBusd !== null ? (
                <>
                  <button {...btnRefund()} onClick={() => handleRefund()}>
                    {isRefund ? (
                      "Refunded"
                    ) : (
                      <>
                        {isLoadingRF ? (
                          <>
                            Waiting <Spin indicator={antIcon} />
                          </>
                        ) : (
                          "Refund"
                        )}
                      </>
                    )}
                  </button>
                </>
              ) : (
                <button {...btnRefund()} disabled>
                  Refund
                </button>
              )}
            </>
          )}

          {index === 0 && isShowRefundAll && !isRefund && !isClaim ? (
            <button {...btnRefundAll()} onClick={() => handleRefundAll()}>
              {isRefundAll ? (
                "Refunded All"
              ) : (
                <>
                  {isLoadingRF_All ? (
                    <>
                      Waiting <Spin indicator={antIcon} />
                    </>
                  ) : (
                    "Refund All"
                  )}
                </>
              )}
            </button>
          ) : (
            ""
          )}
        </td>
      </tr>
    </>
  );
};

const ModalClaim = memo((props: any) => {
  const [state, actions]: any = useHookSol();
  const [claimbles, setClaimbles]: any = useState([]);
  const isModalOpen = state.claimIdoId !== -1;
  // console.log("totalClaim", totalClaim);
  // actions.updateNumClaim(totalClaim);
  const handleOk = () => {
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    actions.openModalClaim(-1);
  };

  useEffect(() => {
    // for (let i = 0; i < claimbles?.length; i++) {
    if (props.idoId === state.claimIdoId) {
      setClaimbles(props.data);
    }
    // }
  }, [props]);

  return (
    <>
      {/* @ts-ignore this lib is incompatible with react18  */}
      <Modal
        className="modal-claimed-after"
        title="Claim Progress"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="bsc-p-launchpad_detail-allocations">
          <table>
            <tr>
              <th>Claimable</th>
              <th>Claim Date</th>
              <th>%</th>
              <th>Status</th>
            </tr>

            {claimbles !== null ? (
              claimbles.map((item: any, i: any) => (
                <>
                  <Item
                    // account={account}
                    item={item}
                    usdtAddress={props.usdtAddress}
                    index={i}
                    // isShowButton={showBtnClaim}
                    // tokenDecimals={objIdo.decimals}
                  />
                </>
              ))
            ) : (
              <div className="bsc-p-launchpad_detail-allocations-empty">
                <span>Empty</span>
              </div>
            )}
          </table>
        </div>
      </Modal>
    </>
  );
});

export default ModalClaim;
