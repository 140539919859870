/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Tabs, Radio, Popover, Input, Select, Spin, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Value from "./../../../components/Value";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgMainheader from "./images/banner-ino.png";
import { useHookNftMarket } from "./Store-market";
import { useContract } from "../../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS } from "../../../constants";
import abiBSCS from "../../../abi/abiBSCS.json";
import "./style.css";
import { useAccount } from "wagmi";

// const useMountEffect = (fun: any) => useEffect(fun, []);

const MarketPlace = () => {
  const [state, actions]: any = useHookNftMarket();
  const { marketInfo, totalVL } = state;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { address } = useAccount();
  let account = address
  const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);
  const [amountBscs, setAmountBscs] = useState(0);

  const { Search } = Input;
  const { Option } = Select;
  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const ItemSlider = (item: any) => {
    console.log('item', item);
    return (
      <div className="item">
        <div className="box-img">
          <a href={`${item?.linkUrl}`} target="_blank" rel="noreferrer">
            <img src={item?.imageUrl} alt="" />
          </a>
        </div>
      </div>
    );
  };
  useEffect(() => {
    actions.getListBanner();
  }, []);

  const gotoDetail = (id: any) => {
    navigate(`${"/nft-megamarket-detail"}/${id}`);
  };
  const gotoMintNft = () => {
    navigate("/mint-nft");
  };

  const Item = (data: any) => {
    // console.log("dataLog: ", data);
    const item = data.data;
    return (
      <>
        <div className="colum w-3">
          <div className="guide-market" onClick={() => gotoDetail(item.id)}>
            <div className="box-img">
              {/* <img src="./images/imgmarket/img-mk.svg" alt="" /> */}
              {item.fileType === 2 ? (
                <>
                  <div className="imgType">
                    <img src="/images/imgnft/typeVideo.png" alt="" />
                  </div>
                  <img src={item.thumbnail} alt="image" />
                </>
              ) : (
                <>
                  <div className="imgType">
                    <img src="/images/imgnft/typeImg.png" alt="" />
                  </div>
                  <img className="imgNft" src={item.fileName} alt="image" />
                </>
              )}
            </div>
            <div className="name-item">{item.name}</div>
            <div className="artis-item">{item.authorName}</div>
            <div className="price-item">
              <span className="t-left">Current price:</span>
              <div className="t-right">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number d-flex">
                  <Value decimals={0} value={item.price} /> BSCS
                </div>
              </div>
            </div>
            <div className="current-price">
              ~ {(item.price * marketInfo?.price).toFixed(2)}$
            </div>
          </div>
        </div>
      </>
    );
  };

  let params = {
    fileType: 0,
    keywork: "",
    pageNumber: 1,
    pageSize: 12,
    sortByPrice: "asc",
    sortType: 0,
    typeArtworks: "",
    category: 0,
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [valueCollection, setValueCollection] = useState("");
  const [valueSearch, setValueSearch] = useState("");
  const [valueFileType, setValueFileType] = useState(0);
  const [loadingOnSearch, setLoadingOnSearch] = useState(false);
  const [valueSort, setValueSort] = useState(1);
  const [tab, setTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(2);
  const [isLoadMore, setIsLoadMore] = useState(false);

  // fillter
  const onChangeRadioCollection = (e: any) => {
    setLoadingOnSearch(true);
    setValueCollection(e.target.value);
    params = {
      ...params,
      pageNumber: 1,
      keywork: valueSearch,
      fileType: valueFileType,
      sortType: valueSort,
      typeArtworks: e.target.value, // filter art collection
      category: tab,
    };
    actions.getProducts(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onChangeRadioType = (e: any) => {
    setLoadingOnSearch(true);
    setValueFileType(e.target.value);
    params = {
      ...params,
      pageNumber: 1,
      keywork: valueSearch,
      sortType: valueSort,
      typeArtworks: valueCollection,
      fileType: e.target.value,
      category: tab,
    };
    actions.getProducts(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onSearch = (value: string) => {
    setLoadingOnSearch(true);
    setValueSearch(value);
    params = {
      ...params,
      sortType: valueSort,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: value,
      category: tab,
    };
    actions.getProducts(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onChangeSearch = (value: any) => {
    if (value.target.value === "") {
      actions.getProducts();
      setValueSearch("");
    }
  };

  const handleChangeSort = (value: any) => {
    setLoadingOnSearch(true);
    setValueSort(value);
    params = {
      ...params,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: valueSearch,
      sortType: value,
      category: tab,
    };
    actions.getProducts(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const onChange = (key: string) => {
    setLoadingOnSearch(true);
    setTab(parseInt(key));
    params = {
      ...params,
      pageNumber: 1,
      typeArtworks: valueCollection,
      fileType: valueFileType,
      keywork: valueSearch,
      sortType: valueSort,
      category: parseInt(key),
    };
    actions.getProducts(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  };

  const handleLoadMore = () => {
    setIsLoadMore(true);
    params = {
      ...params,
      category: tab, // switch tab
      pageNumber, // next page
      pageSize: 12,
      sortByPrice: "asc",
      keywork: valueSearch, // search
      fileType: valueFileType, // filter art type
      sortType: valueSort, // sort
      typeArtworks: valueCollection, // filter art collection
    };
    actions.getProductsMore(params).then((res: any) => {
      if (res) {
        setIsLoadMore(false);
      }
    });
    setPageNumber(pageNumber + 1);
  };

  const initData = useCallback(async () => {
    try {
      setIsLoading(true);
      await actions.getMarketInfo();
      await actions.getTVL();
    } catch (err) {
      // console.log({ "[Nft -> Statistic] -> initData": err });
    } finally {
      setIsLoading(false);
    }
  }, [actions]);

  useEffect(() => {
    setLoadingOnSearch(true);
    initData();
    actions.getProducts(params).then((res: any) => {
      if (res) {
        setLoadingOnSearch(false);
      }
    });
  }, [initData]);

  const getBscs = async () => {
    if (bscsContract) {
      const amount = await bscsContract.balanceOf(account);
      setAmountBscs(amount.toString() / 1e18);
    }
  };

  useEffect(() => {
    document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, []);

  useEffect(() => {
    if (account) {
      getBscs();
    }
  }, [account]);

  const myRef = useRef<null | HTMLDivElement>(null);
  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const Mint_temp = marketInfo.minted_nft || "0";
  const Mint_NFT = parseInt(Mint_temp.replace(",", ""));

  const NFTlock_temp = marketInfo.bake_locked_by_nft || "0";
  const NFT_Lock = parseInt(NFTlock_temp.replace(",", ""));

  const volume_24h_temp = marketInfo.volume_24h || "0";
  const volume_24h = parseInt(volume_24h_temp.replace(",", ""));

  const market_cap_temp = marketInfo.market_cap || "0";
  const market_cap = parseInt(
    market_cap_temp.replace(",", "").replace(",", "")
  );

  const bscsBurn_temp = state.marketInfo?.burnedToDate || "0";
  const bscsBurn = parseInt(
    bscsBurn_temp.replace(",", "").replace(",", "")
  );

  const bscsCirculation_temp = state.marketInfo?.circulating_supply || "0";
  const bscsCirculation = parseInt(
    bscsCirculation_temp.replace(",", "").replace(",", "")
  );


  const Infomarket = () => {
    return (
      <>
        <div className="content-info">
          <div className="list-info dis-mb-768">
            <li>
              <div className="txt-top">Your BSCS balance</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={amountBscs} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Pending harvest</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? <Spin indicator={antIcon} /> : <>0.00</>}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS price</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value
                        unit="$"
                        decimals={4}
                        value={Number(marketInfo.price)}
                      />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS Market Cap</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value unit="$" value={market_cap} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Mint NFT</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={Mint_NFT} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">NFT Transations</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value
                        value={
                          marketInfo ? marketInfo.transactions_nft : "0.00"
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS in circulation</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={bscsCirculation} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS burned</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={bscsBurn} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">TVL (BSCS)</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value unit="$" value={totalVL?.data} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Volume(24hr)</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value unit="$" value={volume_24h} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">NFT Trading vol</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value
                        value={marketInfo ? marketInfo.trading_vol_nft : "0.00"}
                      />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS Lock by NFT</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={NFT_Lock} />
                    </>
                  )}
                </div>
              </div>
            </li>
          </div>
          <div className="list-info dis-desktop-768">
            <li>
              <div className="txt-top">Your BSCS balance</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={amountBscs} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Pending harvest</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? <Spin indicator={antIcon} /> : <>0.00</>}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS price</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value
                        unit="$"
                        decimals={4}
                        value={Number(marketInfo.price)}
                      />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS Market Cap</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value unit="$" value={market_cap} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS in circulation</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={bscsCirculation} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS burned</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={bscsBurn} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">TVL (BSCS)</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value unit="$" value={totalVL?.data} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">Volume(24hr)</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value unit="$" value={volume_24h} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <hr />
            <li>
              <div className="txt-top">Mint NFT</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={Mint_NFT} />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">NFT Transations</div>
              <div className="txt-bot">
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value
                        value={
                          marketInfo ? marketInfo.transactions_nft : "0.00"
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </li>

            <li>
              <div className="txt-top">NFT Trading vol</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value
                        value={marketInfo ? marketInfo.trading_vol_nft : "0.00"}
                      />
                    </>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="txt-top">BSCS Lock by NFT</div>
              <div className="txt-bot">
                <div className="icon">
                  <img src="./images/imgmarket/logoBSC.svg" alt="" />
                </div>
                <div className="number">
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      <Value value={NFT_Lock} />
                    </>
                  )}
                </div>
              </div>
            </li>
          </div>
        </div>
      </>
    );
  };

  const content = (
    <div className="content-popover">
      <div className="columns">
        <div className="colum w-6">
          <div className="round-popover">
            <div className="title">Collection</div>
            <div className="list-radio">
              <Radio.Group
                onChange={onChangeRadioCollection}
                value={valueCollection}
              >
                <Radio value="">All</Radio>
                <Radio value="Characters">Characters</Radio>
                <Radio value="Weapon">Weapon</Radio>
                <Radio value="Pet">Pet</Radio>
                <Radio value="My Neighbor Alice">My Neighbor Alice</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="colum w-6">
          <div className="round-popover">
            <div className="title">Artwork Type</div>
            <div className="list-radio">
              <Radio.Group onChange={onChangeRadioType} value={valueFileType}>
                <Radio value={0}>All</Radio>
                <Radio value={1}>Image</Radio>
                <Radio value={2}>Video</Radio>
                <Radio value={3}>Gif</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        className="main-market-launch"
        style={{
          background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
        }}
      >
        <div className="container">
          <div className="main-content-market">
            <h3 className="title">BSCS NFT Marketplace</h3>
            <div className="desc">The Leading Digital Artworks Marketplace</div>
            <div className="button-ido-stake button-ino">
              <button
                type="button"
                className="btn btn-stake w-140"
                onClick={executeScroll}
              >
                Explore
              </button>
              <button
                type="button"
                className="btn btn-stake-pool w-140"
                onClick={() => gotoMintNft()}
              >
                Create NFT
              </button>
            </div>
            <div className="slider-market-content">
              <Slider {...settings}>
                {state.listBanner.map((item: any, index: any) => (
                  <>{ItemSlider(item)}</>
                ))}
              </Slider>
            </div>
            <div className="info-market-content">
              <Infomarket />
            </div>
            <div className="explore-market-content" ref={myRef}>
              <div className="title">
                Explore BSCS
                <span className="main-color">NFT</span>
              </div>
              <div className="tabs-market">
                <div className="swap-filter">
                  <div className="target-project">
                    <Popover
                      content={content}
                      placement="bottom"
                      title="Filter Artwork"
                      trigger="click"
                    >
                      <button className="btn-filter" type="button">
                        <img src="../images/imgmarket/setting.png" alt="" />{" "}
                        Fillter
                      </button>
                    </Popover>
                  </div>
                  <div className="search-project">
                    <Search
                      placeholder="Search for project"
                      onSearch={onSearch}
                      onChange={onChangeSearch}
                    />
                    <div className="icon-search">
                      <img src="/images/imgido/search-normal.png" alt="" />
                    </div>
                  </div>
                  <div className="filter-project">
                    <Select
                      defaultValue="Sort by"
                      style={{ width: 125 }}
                      onChange={handleChangeSort}
                    >
                      <Option value={1}>Price low - high</Option>
                      <Option value={2}>Price high - low</Option>
                      <Option value={0}>Latest</Option>

                    </Select>
                  </div>
                </div>
                <Tabs defaultActiveKey="0" onChange={onChange}>
                  <Tabs.TabPane tab="All" key="0">
                    <div className="round-tabs">
                      <div className="columns">
                        {loadingOnSearch ? (
                          <div className="nft-loading">
                            <Spin indicator={antIconLoad} />
                          </div>
                        ) : state.nftList.length === 0 ? (
                          <div className="nft-empty">
                            <Empty />
                          </div>
                        ) : (
                          <>
                            {state.nftList &&
                              state.nftList.map((item: any, i: any) => (
                                <Item data={item} />
                              ))}
                            {state.nftListMore &&
                              state.nftListMore.map((item: any, i: any) => (
                                <Item data={item} />
                              ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="load-more-round">
                      {isLoadMore ? (
                        <Spin indicator={antIconLoad} />
                      ) : state.hideLoadmore ? (
                        ""
                      ) : state.nftList.length === 0 ? (
                        ""
                      ) : (
                        <button
                          className="btn-loadmore cus"
                          type="button"
                          onClick={() => handleLoadMore()}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Genesis Batch 1" key="1">
                    <div className="round-tabs">
                      <div className="columns">
                        {loadingOnSearch ? (
                          <div className="nft-loading">
                            <Spin indicator={antIconLoad} />
                          </div>
                        ) : state.nftList.length === 0 ? (
                          <div className="nft-empty">
                            <Empty />
                          </div>
                        ) : (
                          <>
                            {state.nftList &&
                              state.nftList.map((item: any, i: any) => (
                                <Item data={item} />
                              ))}
                            {state.nftListMore &&
                              state.nftListMore.map((item: any, i: any) => (
                                <Item data={item} />
                              ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="load-more-round">
                      {isLoadMore ? (
                        <Spin indicator={antIconLoad} />
                      ) : state.hideLoadmore ? (
                        ""
                      ) : state.nftList.length === 0 ? (
                        ""
                      ) : (
                        <button
                          className="btn-loadmore cus"
                          type="button"
                          onClick={() => handleLoadMore()}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Genesis Batch 2" key="2">
                    <div className="round-tabs">
                      <div className="columns">
                        {loadingOnSearch ? (
                          <div className="nft-loading">
                            <Spin indicator={antIconLoad} />
                          </div>
                        ) : state.nftList.length === 0 ? (
                          <div className="nft-empty">
                            <Empty />
                          </div>
                        ) : (
                          <>
                            {state.nftList &&
                              state.nftList.map((item: any, i: any) => (
                                <Item data={item} />
                              ))}
                            {state.nftListMore &&
                              state.nftListMore.map((item: any, i: any) => (
                                <Item data={item} />
                              ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="load-more-round">
                      {isLoadMore ? (
                        <Spin indicator={antIconLoad} />
                      ) : state.hideLoadmore ? (
                        ""
                      ) : state.nftList.length === 0 ? (
                        ""
                      ) : (
                        <button
                          className="btn-loadmore cus"
                          type="button"
                          onClick={() => handleLoadMore()}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MarketPlace;
