import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from "../../../constants";

const StoreAptos = createStore({
  initialState: {
    balanceAPT: 0,
    accAPT: "",
    netAPT: "",
  },
  actions: {
    updateAccAPT:
      (acc) =>
      ({ setState, getState }) => {
        setState({ accAPT: acc || "" });
      },
    updateNetAPT:
      (net) =>
      ({ setState, getState }) => {
        setState({ netAPT: net || "" });
      },
    getBalanceAPT:
      (account, type, chain) =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          fetch(`${chain}/accounts/${account}/resource/${type}`).then(
            (res: any) => {
              if (res.ok) {
                res.json().then((resValue: any) => {
                  setState({ balanceAPT: resValue.data.coin.value / 1e8 || 0 });
                  resolve(resValue.data.coin.value / 1e8);
                });
              } else {
                reject();
                // console.log("res: ", res);
              }
            }
          );
        });
      },
    getBalanceDeponsit_Token:
      (account, type, chain) =>
      ({ setState }) => {
        return new Promise((resolve, reject) => {
          fetch(`${chain}/accounts/${account}/resource/${type}`)
            .then((res: any) => {
              if (res.ok) {
                res.json().then((resValue: any) => {
                  setState({ balanceAPT: resValue.data.coin.value / 1e6 || 0 });
                  resolve(resValue.data.coin.value / 1e6);
                });
              } else {
                reject(false);
              }
            })
            .catch((err: any) => {
              // console.log("dataLog err: ", err);
            });
        });
      },
  },
  name: "StoreAptos",
});

export const useHookAptos = createHook(StoreAptos);
export const Container = createContainer(StoreAptos, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});

export const Subscriber = createSubscriber(StoreAptos);
