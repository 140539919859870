import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import {
  API_URL,
  BSCSCAN_API_MAINNET,
  TOKEN_BSCS,
  BSCS_BURNED_ADDRESS,
  BSCSCAN_APIKEY,
  FARM_CONTRACT_BSCS,
} from "../../../../constants";

const StoreNftDetail = createStore({
  initialState: {
    objData: {},
    bids: [],
    asks: [],
    artworkMenu: [],
    typeArtworkMenu: [],
    totalCount: 0,
    objListType: [],
    tokenId: "",
    marketInfo: {},
    social: {},
  },
  actions: {
    updateListBid:
      (data) =>
        ({ setState }) => {
          setState({ bids: data });
        },
    insertBuyToDB:
      (obj) =>
        ({ getState, setState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/Artworks/nft_buy`, obj)
              // axios.post(`https://api.bscstation.org/api/Artworks/nft_buy`, obj)
              .then((res) => {
                // const { isLoadData } = getState();
                // setState({ isLoadData: !isLoadData });
                resolve(res);
              });
          });
        },
    checkNftAvailable: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/nft_available`, {
            // .post(`https://api.bscstation.org/api/Artworks/nft_available`, {
            ownerAddress: obj.account,
            id: obj.id,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    getProductsDetail:
      (id) =>
        ({ setState }) => {
          setState({ objData: {} });
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Artworks/${id}`)
              // axios(`https://api.bscstation.org/api/Artworks/${id}`)
              .then((res) => {
                const { data } = res.data;

                setState({ objData: { ...data } }); // TODO
                resolve(data);
              });
          });
        },
    updateTokenId:
      (value) =>
        ({ setState }) => {
          setState({ tokenId: value });
        },
    insertBiding: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/Create_Auction`, {
            // .post(`https://api.bscstation.org/api/Artworks/Create_Auction`, {
            ownerAddress: obj.account,
            productCode: obj.code,
            price: parseFloat(obj.amount),
            txnHash: obj.hash,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    // updateListBid: (data) => ({ setState }) => {
    //   setState({ bids: data })
    // },
    updateListAsk:
      (data) =>
        ({ setState }) => {
          setState({ asks: data });
        },
    updatelBiding: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/Update_Auction`, {
            // .post(`https://api.bscstation.org/api/Artworks/Update_Auction`, {
            ownerAddress: obj.account,
            productCode: obj.code,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    cancelBiding: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/Update_Auction`, {
            // .post(`https://api.bscstation.org/api/Artworks/Update_Auction`, {
            ownerAddress: obj.account,
            productCode: obj.code,
            status: 0,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    getMarketInfo:
      () =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Market/api/get_market`).then(
              (res) => {
                const { data } = res.data;
                setState({ marketInfo: { ...data } }); // TODO
                resolve(data);
              }
            );
          });
        },
    getArtistsByWallet:
      (account) =>
        ({ getState, setState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/Artists/getbyOwnerwallet`, {
                ownerAddress: account,
              })
              .then((res) => {
                const { data } = res.data;
                const { objData } = getState();
                const { objData2 } = getState();
                if (data.length > 0) {
                  const newData = {
                    ...objData,
                    ...data[0],
                    author: data[0].id,
                    artistName: data[0].artistName,
                  };
                  const newData2 = {
                    author: data[0].id,
                  };
                  setState({ objData: newData });
                  setState({ objData2: newData2 });
                  resolve(data[0]);
                } else {
                  resolve(res.data);
                }
              });
          });
        },
  },
  name: "StoreNFt",
});

export const useHookNftDetail = createHook(StoreNftDetail);
export const Container = createContainer(StoreNftDetail, {
  onInit:
    () =>
      ({ setState }, props) => {
        setState({ ...props });
      },
});

export const Subscriber = createSubscriber(StoreNftDetail);
