import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from "../../../constants";

const StoreUpload = createStore({
  initialState: {
    objData: {
      ownerAddress: "",
      artistDescription: "",
      artistName: "",
      publicProfileLink: "",
      fileAvatar: "",
      fileBanner: "",
      social: {},
    },
  },
  actions: {
    submit:
      (data, account) =>
      ({ getState }) => {
        const { objData } = getState();
        const formData = new FormData();
        formData.append("file", data.fileAvatar);
        const formData1 = new FormData();
        formData1.append("file", data.fileBanner);

        return new Promise((resolve, reject) => {
          axios
            .post(`${API_URL}/v1/Media/api/upload_file`, formData)
            .then((res) => {
              formData.append("fileAvatar", res.data.filename);
              if (data.fileBanner !== "") {
                axios
                  .post(`${API_URL}/v1/Media/api/upload_file`, formData1)
                  .then((res1) => {
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    formData.append("fileBanner", res1.data.filename);
                    const newData = {
                      ...objData,
                      ownerAddress: account,
                      artistDescription: data.bio,
                      artistName: data.name,
                      publicProfileLink: data.social,
                      avatarName: res.data.data.filename,
                      banner: res1.data.data.filename,
                      social: {
                        medium: data.medium,
                        telegram: data.telegram,
                        twitter: data.twitter,
                        website: data.website,
                      },
                    };
                    axios
                      .post(`${API_URL}/Artists`, newData)
                      .then((resArtist) => {
                        resolve(resArtist);
                      });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              } else {
                const newData = {
                  ...objData,
                  ownerAddress: account,
                  artistDescription: data.bio,
                  artistName: data.name,
                  publicProfileLink: data.social,
                  avatarName: res.data.data.filename,
                  social: {
                    medium: data.medium,
                    telegram: data.telegram,
                    twitter: data.twitter,
                    website: data.website,
                  },
                };
                axios
                  .post(`${API_URL}/Artists`, newData)
                  .then((resArtist) => {
                    resolve(resArtist);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              }
            });
        });
      },
  },
  name: "StoreUpload",
});

export const useHookUpload = createHook(StoreUpload);
export const Container = createContainer(StoreUpload, {
  onInit:
    () =>
    ({ setState }, props) => {
      setState({ ...props });
    },
});
export const Subscriber = createSubscriber(StoreUpload);
