/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Input, Modal, Collapse } from "antd";
import { LoadingOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useHookIDO } from "../StoreIdo";
import { Empty } from "antd";
import Value from "./../../../components/Value";
import { useContract } from "../../../hooks/useContract";
import abiClaim from "../../../abi/abiClaim.json";
import { CHAIN } from "../index.d";
import { useHookAptos } from "../../APT/ConnectAPT/Store-aptos";
import { useHookSol } from "../../Solana/Store-sol";
import { useAccount } from "wagmi";

const ListIdoJoined = () => {
  const [state, actions]: any = useHookIDO();
  const [stateApt, actionsApt]: any = useHookAptos();
  const [stateSol, actionsSol]: any = useHookSol();
  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const { address } = useAccount();
  let account = address
  const { Search } = Input;
  const antIcon = <LoadingOutlined style={{ fontSize: 25 }} spin />;
  let network = localStorage.getItem("chain");
  useEffect(() => {
    if (account && network !== "apt" && network !== "sol") {
      actions.getListJoined({
        category: 0,
        symbol: "",
        address: account,
        lastTime: null,
      });
      setEnableLoadMore(true);
    } else if (stateApt.accAPT && network === "apt") {
      actions.getListJoined({
        category: 0,
        symbol: "",
        address: stateApt.accAPT,
        lastTime: null,
      });
      setEnableLoadMore(true);
    } else if (stateSol.accSOL && network === "sol") {
      actions.getListJoined({
        category: 0,
        symbol: "",
        address: stateSol.accSOL,
        lastTime: null,
      });
      setEnableLoadMore(true);
    } else {
      actions.resetData();
    }
  }, [account, network, stateApt.accAPT, stateSol.accSOL]);

  const onSearch = (value: string) => {
    setEnableLoadMore(false);
    if (network === "apt") {
      actions.getListJoined({
        category: 0,
        symbol: value,
        address: stateApt.accAPT,
        lastTime: null,
      });
    } else if (network === "sol") {
      actions.getListJoined({
        category: 0,
        symbol: value,
        address: stateSol.accSol,
        lastTime: null,
      });
    } else {
      actions.getListJoined({
        category: 0,
        symbol: value,
        address: account,
        lastTime: null,
      });
    }
  };
  const onChange = (value: any) => {
    if (value.target.value === "") {
      if (network === "apt") {
        actions.getListJoined({
          category: 0,
          symbol: value,
          address: stateApt.accAPT,
          lastTime: null,
        });
      } else if (network === "sol") {
        actions.getListJoined({
          category: 0,
          symbol: value,
          address: stateSol.accSol,
          lastTime: null,
        });
      } else {
        actions.getListJoined({
          category: 0,
          symbol: value,
          address: account,
          lastTime: null,
        });
      }
      setEnableLoadMore(true);
    }
  };

  const params = {
    address:
      network === "apt"
        ? stateApt.accAPT
        : network === "sol"
          ? stateSol.accSol
          : account,
    category: 0,
    lastTime:
      state.listJoined.length > 0
        ? state.listJoined[state.listJoined.length - 1].created
        : null,
  };

  const handleMore = () => {
    if (state.listJoinedMore.length > 0) {
      params.lastTime =
        state.listJoinedMore[state.listJoinedMore.length - 1].created;
    }
    actions.getListJoinedMore(params).then((data: any) => {
      if (data.length === 0) setEnableLoadMore(false);
    });
  };

  // const handleLoadMoreMobie = () => {
  //   if (state.listJoinedMore.length > 0) {
  //     params.lastTime =
  //       state.listJoinedMore[state.listJoinedMore.length - 1].created;
  //   }
  //   actions.getListJoinedMore(params).then((data: any) => {
  //     try {
  //       setLoadingSpin(true);
  //       setTimeout(() => {
  //         setLoadingSpin(false);
  //       }, 500);
  //     } catch (error) {
  //       setLoadingSpin(false);
  //     }
  //   });
  // };

  let navigate = useNavigate();
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const handleCallClickDetails = (network: any, symbol: any, isOldIDo: any, idoTrust: any) => {
    if (network === CHAIN.APTOS) {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);

    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else if (network === CHAIN.TON) {
      navigate(`${"/Ido/Detail"}/${network}?${symbol}`);
    }
    else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else if (idoTrust === 'Vanguard' && symbol !== "MEY") {
      navigate(`${"/Ido/details-vanguard"}?${symbol}`);
    } else {
      navigate(`${"/Ido/Details"}?${symbol}`);
    }
  };

  const { Panel } = Collapse;

  const onChangeCollapse = (key: string | string[]) => {
    // console.log(key);
  };

  const HeaderTopIdo = (props: any) => {
    const { ido } = props;
    return (
      <div className="content-header-panel">
        <div className="project-name-m">
          <button
            type="button"
            className="button-name"
            onClick={() =>
              handleCallClickDetails(ido.network, ido.symbol, ido.isOldIDo, ido?.idoTrust)
            }
          >
            {ido.name}
          </button>
        </div>
        <div className="token-name-m">
          <span className="icon-symbol">
            <img
              style={{ width: "24px", height: "24px" }}
              src={ido.logoUrl}
              alt=""
            />
          </span>
          {ido.symbol}
        </div>
      </div>
    );
  };

  const ItemMobile = ({ ido }: any) => {
    const claimContract = useContract(ido.contractAddress, abiClaim);
    const [claimed, setClaimed] = useState(0);
    const [claimedDaily, setClaimedDaily] = useState(0);

    useEffect(() => {
      if (claimContract && ido.contractAddress) {
        claimContract.totalClaimed(account).then((res: any) => {
          setClaimed(res.toString() / 1e18);
        });
      }
    }, [account, claimContract, ido.contractAddress]);

    useEffect(() => {
      if (account && claimContract && ido.contractDailyAddress) {
        claimContract.userInfo(account).then((res: any) => {
          setClaimedDaily(res.tokensClaimed / 1e18);
        });
      }
    }, [account, claimContract, ido.contractDailyAddress]);

    return (
      <div className="content-joined-mobile">
        <div className="colum-content">
          <div className="item-content">
            <div className="text-l">Next Claim :</div>
            <div className="text-r">{ido.nextClaim}</div>
          </div>
          <div className="item-content">
            <div className="text-l">Allocation :</div>
            <div className="text-r">{ido.allocation}</div>
          </div>
          <div className="item-content">
            <div className="text-l">Claimed:</div>
            <div className="text-r">
              {" "}
              {(claimed + claimedDaily + ido.claimed).toFixed(2)} {ido.symbol}
            </div>
          </div>
          <div className="item-content">
            <div className="text-l">Action :</div>
            <button
              className="text-r"
              onClick={() =>
                handleCallClickDetails(ido.network, ido.symbol, ido.isOldIDo, ido?.idoTrust)
              }
            >
              <span className="main-action">Details</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <>
        <div className="main-list-end mobile">
          <div className="box-search-round">
            <div className="search-project">
              <Search
                placeholder="Search for project"
                onSearch={onSearch}
                onChange={onChange}
                style={{ width: 200 }}
              />
              <div className="icon-search">
                <img src="/images/imgido/search-normal.png" alt="" />
              </div>
            </div>
            <div
              className="collapse-mobile-ido"
            // onTouchEnd={handleLoadMoreMobie}
            >
              <div className="table-mobile">
                <div className="title-rounds">Project Name</div>
                <div className="title-rounds">Token</div>
              </div>
              {/* @ts-ignore this lib is incompatible with react18  */}
              <Collapse
                defaultActiveKey={["index"]}
                onChange={onChangeCollapse}
              >
                {state.listJoined?.length > 0 &&
                  state.listJoined?.map((ido: any, index: any) => (
                    // @ts-ignore this lib is incompatible with react18
                    <Panel
                      header={<HeaderTopIdo ido={ido} />}
                      key={index.toString()}
                      className="round-ido-m"
                    >
                      <ItemMobile ido={ido} account={account} index={index} />
                    </Panel>
                  ))}
                {state.listJoinedMore?.length > 0 &&
                  state.listJoinedMore?.map((ido: any, index: any) => (
                    // @ts-ignore this lib is incompatible with react18
                    <Panel
                      header={<HeaderTopIdo ido={ido} />}
                      key={index.toString()}
                      className="round-ido-m"
                    >
                      <ItemMobile ido={ido} account={account} index={index} />
                    </Panel>
                  ))}
              </Collapse>
            </div>
          </div>

          {/* {loadingSpin ? (
            <div className="loading-idoMore">
              <Spin indicator={antIcon} delay={1} />
            </div>
          ) : (
            ""
          )} */}
        </div>
        {enableLoadMore && state.listJoined.length !== 0 ? (
          <button className="btn-loadmore" onClick={handleMore}>
            More
          </button>
        ) : (
          ""
        )}
      </>
    );
  }

  const Item = (ido: any) => {
    let item = ido.item;
    const claimContract = useContract(item.contractAddress, abiClaim);
    const [claimed, setClaimed] = useState(0);
    const [claimedDaily, setClaimedDaily] = useState(0);

    useEffect(() => {
      if (claimContract && item.contractAddress) {
        claimContract.totalClaimed(account).then((res: any) => {
          setClaimed(res.toString() / 1e18);
        });
      }
    }, [account, claimContract, item.contractAddress]);

    useEffect(() => {
      if (account && claimContract && item.contractDailyAddress) {
        claimContract.userInfo(account).then((res: any) => {
          setClaimedDaily(res.tokensClaimed / 1e18);
        });
      }
    }, [account, claimContract, item.contractDailyAddress]);

    return (
      <tr className="tr-bottom">
        <td className="name-project">
          <button
            type="button"
            className="button-name"
            onClick={() =>
              handleCallClickDetails(item.network, item.symbol, item.isOldIDo, item?.idoTrust)
            }
          >
            {item.name}
          </button>
        </td>
        <td>
          <span className="icon-symbol">
            <img
              style={{ width: "24px", height: "24px" }}
              src={item.logoUrl}
              alt=""
            />
          </span>
          {item.symbol}
        </td>
        <td>{item.nextClaim}</td>
        <td className="d-flex">
          <Value decimals={0} value={item.allocation} />
          {item.symbol}
        </td>

        <td className="claimedJoin">
          {(claimed + claimedDaily + item.claimed).toFixed(2)} {item.symbol}
        </td>
        <td>
          <span className="main-action">
            <button
              type="button"
              className="button-detail"
              onClick={() =>
                handleCallClickDetails(
                  item.network,
                  item.symbol,
                  item?.isOldIDo,
                  item?.idoTrust
                )
              }
            >
              Details
            </button>
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="main-list-end">
        <div className="box-search-round">
          <div className="search-project">
            <Search
              placeholder="Search for project"
              onSearch={onSearch}
              onChange={onChange}
              style={{ width: 200 }}
            />
            <div className="icon-search">
              <img src="/images/imgido/search-normal.png" alt="" />
            </div>
          </div>
        </div>
        {state.listJoined?.length === 0 ? (
          <>
            <table className="main-table">
              <tr className="tr-top">
                <th>Project Name</th>
                <th>Token</th>
                <th>Next Claim</th>
                <th>Allocation</th>
                <th>Claimed </th>
                <th>Action</th>
              </tr>
            </table>
            <p className="p-empty">
              <Empty />
            </p>
          </>
        ) : (
          <table className="main-table">
            <tr className="tr-top">
              <th>Project Name</th>
              <th>Token</th>
              <th>Next Claim</th>
              <th>Allocation</th>
              <th>Claimed </th>
              <th>Action</th>
            </tr>

            {state.listJoined &&
              state.listJoined.map((item: any, i: any) => <Item item={item} />)}

            {state.listJoinedMore &&
              state.listJoinedMore.map((item: any, i: any) => (
                <Item item={item} />
              ))}
          </table>
        )}
        {enableLoadMore && state.listJoined.length !== 0 ? (
          <button className="btn-loadmore" onClick={handleMore}>
            More
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ListIdoJoined;
