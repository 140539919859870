/* eslint-disable prefer-object-spread */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Input, Checkbox, Tooltip, Radio, message } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useContract } from "../../../hooks/useContract";
import abiStakePoolNFT from "../../../abi/abiStakePoolNFT.json";
// import { store } from 'react-notifications-component'
// import addNotify from 'components/Notify/addNotify'

import {
  _stakePeriodAPRs,
  _earning,
  _userStakeIds,
  _userStakeIndex,
  _stakeDetails,
  _unstake,
  _collectUnstakeFee,
} from "./utils";
import { useHookInoPoolDetails } from "./Store-Ino-Pool-Details";
import { useAccount } from "wagmi";


const ItemStake = ({
  setListFees,
  setPoolContract,
  listFees,
  totalFees,
  totalAmountStake,
  setTotalAmountStake,
  setTotalFees,
  setListCheckStaked,
  listCheckStaked,
  id,
  sc,
  item,
  index,
  isCheck,
  totalListScUstake,
  listIds,
}: any) => {
  const [state, actions] = useHookInoPoolDetails();
  const [status, setStatus] = useState(false);
  const [tip, setTip] = useState([]);
  const [earning, setEarning] = useState(0);
  const expiration = new Date(
    (parseInt(item && item[2].toString()) +
      parseInt(item && item[3].toString())) *
    1000
  );
  const listPoolFee = state.inoListPoolDetails.poolFees;

  const titleTooltipsNew = (arrText: any) => {
    // console.log('arrText: ', arrText)
    return (
      <>
        <div className="main-tooltips">
          <div className="title-tips">Unstaking fee:</div>
          <ul className="list-tips">
            {arrText?.length > 0
              ? arrText?.map((itemFee: any, i: any) => (
                <>
                  <li>
                    Within{" "}
                    <b>
                      {" "}
                      {(itemFee.unstakeDay / 86400).toFixed(0)} days:{" "}
                      {itemFee.fee}%{" "}
                    </b>
                  </li>
                </>
              ))
              : "TBA"}
          </ul>
        </div>
      </>
    );
  };

  const text = () => {
    listPoolFee.forEach((obj: any) => {
      if (obj.poolContract === item?.contractItem) {
        setTip(obj.fees);
      }
    });
  };

  const parseDate = `${expiration.getUTCDate()}-${(
    expiration.getUTCMonth() + 1
  ).toString()}-${expiration.getUTCFullYear().toString()} ${expiration
    .getHours()
    .toString()}:${expiration.getMinutes().toString()} `;

  const checkId = (
    idVal: any,
    value: any,
    amount: any,
    i: any,
    contractItem: any
  ) => {
    // console.log('idVal: ', idVal)
    // console.log('value: ', value)
    // console.log('amount: ', amount)
    // console.log('i: ', i)
    // console.log('contractItem: ', contractItem)

    let listCheckStakedTemp = listCheckStaked;
    listCheckStakedTemp = listCheckStakedTemp.map((x: any) => {
      if (x.id === idVal && x.sc === contractItem) {
        x.value = !status;
      }
      // console.log('xxx: ', x)
      return x;
    });

    setStatus(!status);
    setListCheckStaked(listCheckStakedTemp);
    if (!status) {
      setTotalAmountStake(totalAmountStake + parseFloat(amount));
      setTotalFees(totalFees + parseFloat(listFees[i].fee));
      // console.log('listFees[i]: ', listFees[i])
    } else {
      setTotalAmountStake(totalAmountStake - parseFloat(amount));
      setTotalFees(totalFees - parseFloat(listFees[i].fee));
      // console.log('listFees[i]: ', listFees[i])
    }
  };

  // console.log('listFees: ', listFees)

  useEffect(() => {
    text();
    item.earn.then((res: any) => {
      setEarning(res.vl.toString() / 1e18);
    });
  }, [item]);

  const dateNow = new Date();
  if (expiration.getTime() < dateNow.getTime()) {
    return <div>{ }</div>;
  }

  return (
    <div className="item-top">
      <Checkbox
        value={index}
        onChange={() =>
          checkId(
            id,
            isCheck,
            item && item[1].toString() / 1e18,
            index,
            item.contractItem
          )
        }
        checked={status}
      >
        <div className="text">{item && item[1].toString() / 1e18}</div>
        <div className="text">{earning.toFixed(2)}</div>
        <div className="text">{parseDate}</div>
        {/* <div className="text">{item?.contractItem}</div> */}
        <div className="textFee">
          <Tooltip placement="topRight" title={titleTooltipsNew(tip)}>
            <InfoCircleFilled />
          </Tooltip>
        </div>
      </Checkbox>
    </div>
  );
};
const ModalUnStaking = (data: any) => {
  const [state, actions] = useHookInoPoolDetails();
  const { address } = useAccount();
  let account = address
  const [totalFees, setTotalFees] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [listStaked, setListStaked]: any = useState([]);
  const [listCheckStaked, setListCheckStaked]: any = useState([]);
  const [listFees, setListFees]: any = useState([]);
  const [listIds, setListIds]: any = useState([]);
  const [totalAmountStake, setTotalAmountStake]: any = useState(0);
  const [isCheckRules, setIsCheckRules]: any = useState(false);
  const [listAdd, setListAdd]: any = useState([]);
  const [poolContract, setPoolContract]: any = useState();
  //   const contractStakePoolNFT = useContract(state.inoListPoolDetails?.poolContract, abiStakePoolNFT)
  // const contractStakePoolNFT = useContract('0xaD7Fc30F2be8A9e469DF5c5a1043c7FB7E42E61C', abiStakePoolNFT)
  // const contractStakePoolNFT = useContract('0x7d2CCBE938c26eAD556a7eEBAB5C0c32c000220F', abiStakePoolNFT)
  // const contractStakePoolNFT = useContract(poolContract, abiStakePoolNFT)

  // console.log('modal u stake: ', state.isShowModalUnStake)

  useEffect(() => {
    actions.updateShowUnStake(false);
  }, [account]);

  const totalListScUstake = data.totalListSc;
  // const handleCancel = useCallback(() => {
  //   actions.updateShowUnStake(false)
  // },[account])
  const handleCancel = () => {
    actions.updateShowUnStake(false);
  };

  // console.log('listAdd: ', listAdd)
  const unstake = async () => {
    setIsLoading(true);

    const listIdsTemp = listCheckStaked
      .filter((x: any) => x.value === true)
      .map((x: any) => {
        return { id: x.id, sc: x.sc };
      });

    const smartContractObj = listCheckStaked
      .filter((x: any) => x.value === true)
      .map((x: any) => {
        return x.contractObj;
      });

    let smartContractObjNoDup = smartContractObj.reduce(function (
      acc: any,
      elem: any
    ) {
      if (acc.indexOf(elem) === -1) {
        acc.push(elem);
      }
      return acc;
    },
      []);

    try {
      await smartContractObjNoDup.forEach((obj: any) => {
        listIdsTemp.forEach((objId: any) => {
          if (objId.sc === obj.address) {
            let temp: any = [];
            temp.push(objId.id);

            try {
              _unstake(obj, temp).then((res) => {
                // console.log('res: ', res)
                if (res.hash) {
                  setTimeout(() => {
                    message.success("Unstake successfully!!! ");
                    setIsLoading(false);
                    handleCancel();
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }, 20000);
                } else {
                  setIsLoading(false);
                  message.warning(res.data.message);
                }
              });
            } catch (error: any) {
              setIsLoading(false);
              message.warning(error);
            }
          }
        });
      });
    } catch (error: any) {
      message.warning(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let listAddSc: any = [];
    let listCheckStakedTemp: any = [];
    let listStakedTemp: any = [];
    let listFeesTemp: any = [];

    totalListScUstake.forEach((item: any) => {
      let listIdsTemp: any = [];
      let listFeesDataTemp: any = [];
      let listStake: any = [];
      let listDetails: any = [];
      let totalStakedTemp: any = 0;
      let totalFeesTemp: any = 0;

      listAddSc.push(item.address);

      if (account) {
        _userStakeIndex(item, account).then(async (res) => {
          const maxIndex = res.toString();
          if (maxIndex > 0) {
            // get all indexs staked
            if (maxIndex > 0) {
              for (let i = 0; i < maxIndex; i++) {
                listStake.push(_userStakeIds(item, account, i));
              }
            }
            // get alls ids staked
            await Promise.all(listStake).then((resAll: any) => {
              for (let i = 0; i < maxIndex; i++) {
                listIdsTemp.push(resAll[i].toString());
              }
            });
            // console.log('listIdsTemp: = ', listIdsTemp)
            listIdsTemp.forEach((id: any) => {
              listCheckStakedTemp.push({
                id,
                value: false,
                sc: item.address,
                contractObj: item,
              });

              listDetails.push(
                _stakeDetails(item, id).then((value) =>
                  Promise.resolve({ ...value, id })
                )
              );
              listFeesTemp.push(
                _collectUnstakeFee(item, id).then((value) =>
                  Promise.resolve({ ...value, id, sc: item.address })
                )
              );
              // console.log('listFeesTemp: ', listFeesTemp)
            });

            // get all details staked
            await Promise.all(listDetails).then((resAll: any) => {
              for (let i = 0; i < listDetails.length; i++) {
                const expiration = new Date(
                  (parseInt(resAll[i] && resAll[i][2]?.toString()) +
                    parseInt(resAll[i] && resAll[i][3].toString())) *
                  1000
                );
                const dateNow = new Date();
                if (expiration.getTime() >= dateNow.getTime()) {
                  totalStakedTemp =
                    resAll[i][1].toString() / 1e18 + totalStakedTemp;
                }

                const newObj = Object.assign(
                  { contractItem: item.address },
                  resAll[i],
                  {
                    earn: _earning(item, resAll[i].id).then((vl) =>
                      Promise.resolve({ vl })
                    ),
                  }
                );
                // listStakedTemp.push(_earning(item, resAll[i].id).then((vl)=>Promise.resolve({vl})))
                listStakedTemp.push(newObj);
              }
            });

            await Promise.all(listFeesTemp).then((resAll: any) => {
              for (let i = 0; i < listFeesTemp.length; i++) {
                // console.log('resAll[i]._hex= ', resAll[i])
                // console.log('Number(resAll[i]._hex)= ', Number(resAll[i]._hex)/1e18)
                const expiration = new Date(
                  (parseInt(
                    listStakedTemp[i] && listStakedTemp[i][2].toString()
                  ) +
                    parseInt(
                      listStakedTemp[i] && listStakedTemp[i][3].toString()
                    )) *
                  1000
                );
                const dateNow = new Date();
                if (expiration.getTime() >= dateNow.getTime()) {
                  totalFeesTemp =
                    parseFloat(resAll[i].toString()) + totalFeesTemp;
                }
                // console.log('resAll[i]: = ', resAll[i]._hex)
                listFeesDataTemp.push({
                  // fee: parseFloat(resAll[i]._hex.toString()),
                  fee: Number(resAll[i]._hex),
                  id: resAll[i].id,
                  sc: resAll[i].sc,
                });
              }
            });

            let listIdUnstake: any = [];
            listCheckStakedTemp.forEach((j: any) => {
              listIdUnstake.push({ id: j.id, sc: j.sc });
            });
            // setTotalAmountStake(totalStakedTemp)
            setListStaked(listStakedTemp);
            setListIds(listIdUnstake);
            setListCheckStaked(listCheckStakedTemp);
            // setTotalFees(totalFeesTemp)
            setListFees(listFeesDataTemp);
            setListAdd(listAddSc);
          }
        });
      }
    });
  }, [account, totalListScUstake]);

  const headerModal = (
    <>
      <div className="main-header-modal">
        <h3 className="title">Stake in pool</h3>
        <p className="desc">Stake {state.inoListPoolDetails?.earnSymbol}</p>
      </div>
    </>
  );
  // const titleTooltips = (
  //   <>
  //     <div className="main-tooltips">
  //       <div className="title-tips">Unstaking fee:</div>
  //       <ul className="list-tips">
  //         {state.inoListPoolDetails?.poolFees?.length > 0 &&
  //           state.inoListPoolDetails?.poolFees.map((item, i) => (
  //             <>
  //               <li>
  //                 Within{' '}
  //                 <b>
  //                   {' '}
  //                   {(item.unstakeDay / 86400).toFixed(4)} days: {item.fee}%{' '}
  //                 </b>
  //               </li>
  //             </>
  //           ))}
  //       </ul>
  //     </div>
  //   </>
  // )
  const contentModal = (
    <>
      <div className="main-body-modal">
        <div className="choose-earning">
          <div className="title-choose">Choose your earning</div>
          <div className="table-earning">
            <div className="item-top">
              <div className="text">Amount</div>
              <div className="text">Earnings</div>
              <div className="text">Expiration</div>
            </div>
            {listStaked.map((item: any, i: any) => {
              const id = listIds[i]?.id;
              const sc = listIds[i]?.sc;
              const isCheck =
                listCheckStaked.length > 0 &&
                listCheckStaked.find((x: any) => x.id === id && x.sc === sc)
                  ?.value;
              return (
                <ItemStake
                  listFees={listFees}
                  setListFees={setListFees}
                  totalFees={totalFees}
                  totalAmountStake={totalAmountStake}
                  setTotalAmountStake={setTotalAmountStake}
                  setTotalFees={setTotalFees}
                  setListCheckStaked={setListCheckStaked}
                  listCheckStaked={listCheckStaked}
                  id={id}
                  sc={sc}
                  listIds={listIds}
                  item={item}
                  index={i}
                  isCheck={isCheck}
                  setPoolContract={setPoolContract}
                  totalListScUstake={totalListScUstake}
                />
              );
            })}
          </div>
        </div>
        <div className="unstake-amount">
          <div className="item">
            <div className="txt">
              Unstake amount <span>{totalAmountStake}</span>
            </div>
            <div className="txt">
              Unstake fee <span>{totalFees && totalFees / 1e18}</span>
            </div>
          </div>
        </div>

        <div className="stake-rules">
          <ul className="list-rules">
            <li>
              During locking period,{" "}
              {/* <Tooltip title={titleTooltips}>
                <span className="tooltips">unstaking fee</span>
              </Tooltip>{' '} */}
              unstaking fee will be applied and you will withdraw without
              earnings.
            </li>
          </ul>
          <Checkbox
            onChange={() => setIsCheckRules(!isCheckRules)}
            checked={isCheckRules}
          >
            I understand the staking rules.
          </Checkbox>
        </div>

        <div className="button-select">
          <button
            onClick={() => unstake()}
            disabled={
              !(
                listCheckStaked.filter((x: any) => x.value === true).length >
                0 && isCheckRules
              ) || isLoading
            }
            type="button"
            className="btn-select"
          >
            UnStake
            {isLoading ? <i className="fa fa-spinner fa-spin" /> : ""}
          </button>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="modal-staking">
        {/* @ts-ignore this lib is incompatible with react18  */}
        <Modal
          title={headerModal}
          className="modal-staking-main"
          visible={state.isShowModalUnStake}
          onCancel={handleCancel}
        >
          <div className="modal-content-staking">{contentModal}</div>
        </Modal>
      </div>
    </>
  );
};
export default ModalUnStaking;
