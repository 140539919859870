/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { message, Dropdown, Modal, Skeleton } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHookSol } from "./Store-sol";
import * as anchor from "@project-serum/anchor";
import {
    SOLANA_HOST,
    API_KEY_MORALIS,
    NETWORK_SOL_MORALIS,
} from "../Ido/detailsSOL/const";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import Moralis from "moralis";

// import { useHookAptos } from "./Store-aptos";
// import { CHAIN_NETWORK_APTOS, TYPE_APT_TOKEN } from "../../../constants/index";
import "./style.css";
import { isMobile } from "react-device-detect";

const ConnectSolana = () => {
    const [state, actions]: any = useHookSol();
    const [curentAddress, setCurentAddress] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isBalanceAPT, setBalanceAccAPT] = useState(0);
    const [isNetWork, setIsNetWork] = useState("");
    const [prov, setProv]: any = useState();

    let navigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getProvider = () => {
        if ("phantom" in window) {
            const provider = window.phantom?.solana;

            if (provider?.isPhantom) {
                return provider;
            }
        } else {
            const provider = window.phantom?.solana;

            if (provider?.isPhantom) {
                return provider;
            }
        }
    };
    const provider = getProvider();

    const handleConnectSol = useCallback(async () => {

        const provider = getProvider();
        if (!provider) {
            if (isMobile) {
                window.open("https://phantom.app/", "_blank");
            } else {
                window.open(
                    "https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa?hl=en",
                    "_blank"
                );
            }
        }
        setIsModalOpen(false);
        try {
            const resp = await provider?.connect();
            console.log('%c [ resp ]-73', 'font-size:13px; background:pink; color:#bf2c9f;', resp)
            setCurentAddress(resp.publicKey.toString());
            actions.updateAccSOL(resp.publicKey.toString());
            localStorage.setItem("accSOL", resp.publicKey.toString());
            // localStorage.setItem("chain", 'sol');
        } catch (error: any) {
            console.log("error: ", error);
        }
    }, []);

    const handleDisconnectSol = useCallback(async () => {
        // if (!provider) {
        //   // setCurentAddress("");
        //   // actions.updateAccSOL("");
        //   // localStorage.setItem("accSOL", "");
        //   return;
        // }
        setCurentAddress("");
        actions.updateAccSOL("");
        localStorage.setItem("accSOL", "");
        const provider = getProvider();
        try {
            await provider.disconnect();
            // setCurentAddress("");
            // actions.updateAccSOL("");
            // localStorage.setItem("accSOL", "");
        } catch (error: any) {
            console.log("error: ", error);
        }
    }, []);

    setTimeout(() => {
        const provider = getProvider();
        setProv(provider);
    }, 1000);

    useEffect(() => {
        const provider = getProvider();
        if (!provider) return;

        if (localStorage.getItem("accSOL")) {
            provider
                .connect({ onlyIfTrusted: true })
                .then((res: any) => {
                    setCurentAddress(res.publicKey.toString());
                    actions.updateAccSOL(res.publicKey.toString());
                    localStorage.setItem("accSOL", res.publicKey.toString());
                })
                .catch((err: any) => {
                    console.log("err: ", err);
                });
        }

        provider.on("connect", (publicKey: any) => {
            setCurentAddress(publicKey.toBase58());
            actions.updateAccSOL(publicKey.toBase58());
            localStorage.setItem("accSOL", publicKey.toString());
        });

        provider.on("disconnect", () => {
            setCurentAddress("");
            actions.updateAccSOL("");
            localStorage.setItem("accSOL", "");
        });

        provider.on("accountChanged", (publicKey: any) => {
            if (publicKey) {
                setCurentAddress(publicKey.toBase58());
                actions.updateAccSOL(publicKey.toBase58());
                localStorage.setItem("accSOL", publicKey.toBase58());
            } else {
                provider.connect().then((res: any) => {
                    setCurentAddress(res.publicKey.toString());
                    actions.updateAccSOL(res.publicKey.toString());
                    localStorage.setItem("accSOL", res.publicKey.toString());
                });
            }
        });
    }, [curentAddress, provider, state.accSOL, prov]);

    const accountEllipsisRow = curentAddress
        ? `${curentAddress?.substring(0, 8)}...${curentAddress?.substring(
            curentAddress.length - 8
        )}`
        : "Connect Wallet ";

    const accountEllipsisSol = curentAddress
        ? `${curentAddress?.substring(0, 4)}...${curentAddress?.substring(
            curentAddress.length - 4
        )}`
        : "Connect Wallet ";

    const accountEllipsisRowMobie = curentAddress
        ? `${curentAddress?.substring(0, 8)}...${curentAddress?.substring(
            curentAddress.length - 8
        )}`
        : "Connect Wallet ";

    const accountEllipsisMobieSol = curentAddress
        ? `${curentAddress?.substring(0, 4)}...${curentAddress?.substring(
            curentAddress.length - 4
        )}`
        : "Connect Wallet ";

    const gotoIdoPool = () => {
        navigate("/IDOPools");
    };
    const gotoLaunchPool = () => {
        navigate("/LaunchPool");
    };
    const gotoMyNft = () => {
        navigate("/MyArtwork");
    };
    const gotoMyAccount = () => {
        navigate("/my-account");
        // message.info({
        //   type: "info",
        //   content: "Coming soon",
        //   className: "custom-class",
        //   duration: 2,
        // });
    };

    // let accRe = "5F1ufC3YWvfozn7X1xtQ5mVRcgHFv7ZVMEXYp5ysqygFAkex";

    // const transfer = async (from: any, to: any, amount: any) => {
    //   const provider = new WsProvider("wss://rpc.polkadot.io");
    //   const api = await ApiPromise.create({ provider });

    //   const injector = await web3FromAddress(from);

    //   api.setSigner(injector.signer);
    //   const h = api.tx.balances.transfer(to, amount).signAndSend(from);
    //   console.log("h: ", h);
    // };

    const menuSol = (
        <>
            <div className="main-dropdown-wl">
                <div className="top-dropdown">
                    <div className="box-address">
                        <div className="address-name">Wallet address :</div>
                        <div className="account-name">
                            {isMobile ? accountEllipsisRowMobie : accountEllipsisRow}

                            <CopyToClipboard
                                text={`${curentAddress}`}
                                onCopy={() => {
                                    message.success({
                                        type: "success",
                                        content: "Copied",
                                        className: "custom-class",
                                        duration: 0.5,
                                    });
                                }}
                            >
                                <span className="img-copy">
                                    <img src="/images/imgido/copy.png" alt="" />
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="bottom-dropdown">
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoMyAccount()}>
                            <img src="/images/award.png" alt="" />{" "}
                            <span className="name-rs">My Account</span>
                        </div>
                    </div>
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoIdoPool()}>
                            <img src="/images/menu-board.png" alt="" />{" "}
                            <span className="name-rs">IDO Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoLaunchPool()}>
                        <div className="icon-rs">
                            <img src="/images/rl.png" alt="" />{" "}
                            <span className="name-rs">Launch Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoMyNft()}>
                        <div className="icon-rs">
                            <img src="/images/rs.png" alt="" />{" "}
                            <span className="name-rs">My NFT</span>
                        </div>
                    </div>
                </div>
                <div className="footer-dropdown">
                    <button type="button" className="btn-dis" onClick={handleDisconnectSol}>
                        Disconnect Wallet
                    </button>
                </div>
            </div>
        </>
    );

    const connection = new anchor.web3.Connection(SOLANA_HOST);
    const [balance, setBalance] = useState(0);
    const [loadBalance, setIsBalance] = useState(true);

    const getBalanceSOL = async () => {
        if (connection && window?.solana?.publicKey) {
            // let balanceSol = await connection?.getBalance(window?.solana?.publicKey);
            await Moralis.start({
                apiKey: API_KEY_MORALIS,
            });

            Moralis.SolApi.account
                .getBalance({
                    network: NETWORK_SOL_MORALIS,
                    address: curentAddress,
                })
                .then((res: any) => {
                    // console.log("res", res?.raw?.solana);
                    setBalance(Number(res?.jsonResponse?.solana));
                    setIsBalance(false);


                });
        }
    };

    useEffect(() => {
        getBalanceSOL();
        actions.setBalaceSol(balance);
    }, [curentAddress, balance]);

    return {
        handleConnectSol,
        handleDisconnectSol,
        curentAddress,
        balance,
        loadBalance,
        accountEllipsisMobieSol,
        accountEllipsisSol,
        menuSol,
    };

    // return (
    //   <>
    //     {curentAddress && curentAddress !== "undefined" ? (
    //       <>
    //         {/* @ts-ignore this lib is incompatible with react18  */}
    //         <Dropdown overlay={menu} placement="bottom">
    //           <button className="btn-account" type="button">
    //             <div className="img-bnb">
    //               <img
    //                 src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/6d4d28c5bcad090ae08e30b8e993bd942b4b71bc15b2885b05.png"
    //                 alt=""
    //               />{" "}
    //               {loadBalance ? (
    //                 <Skeleton.Button active />
    //               ) : (
    //                 <div className="price">{balance.toFixed(2)} SOL</div>
    //               )}
    //             </div>
    //             <span className="account-style">
    //               <img src="/images/phantom-logo.png" alt="" />
    //               {isMobile ? accountEllipsisMobie : accountEllipsis}
    //             </span>
    //           </button>
    //         </Dropdown>
    //       </>
    //     ) : (
    //       <button
    //         className="btn-connect"
    //         type="button"
    //         onClick={() => showModal()}
    //       >
    //         Connect Wallet
    //       </button>
    //     )}

    //     {/* @ts-ignore this lib is incompatible with react18  */}
    //     <Modal
    //       title={titleModal}
    //       visible={isModalOpen}
    //       onCancel={handleCancel}
    //       wrapClassName="modal-apt-connect"
    //       footer={false}
    //     >
    //       <div className="apt-wallet pontem" onClick={() => handleConnect()}>
    //         <img src="/images/phantom.png" alt="" className="img-wallet" />
    //         <p className="text-wallet">Phantom Wallet</p>
    //       </div>
    //     </Modal>

    //     {/* @ts-ignore this lib is incompatible with react18  */}
    //     {/* <Modal
    //       title={titleModalSelect}
    //       visible={isModalOpenSelect}
    //       onCancel={handleCancelSelect}
    //       wrapClassName="modal-pk-select"
    //       footer={false}
    //       width={400}
    //     >
    //       <div className="list-address">
    //         {listAccounts.length !== 0
    //           ? listAccounts.map((item: any, i: any) => (
    //               <>
    //                 {Number(curentIndex) === i ? (
    //                   <div
    //                     className="name-address active"
    //                     onClick={() =>
    //                       handleSelect(item.meta?.name, item.address, i)
    //                     }
    //                   >
    //                     <div className="item-name">{item.meta?.name}</div>
    //                     <div className="item-address">
    //                       {item.address?.substring(0, 15)}...
    //                       {item.address?.substring(item.address.length - 8)}
    //                     </div>
    //                   </div>
    //                 ) : (
    //                   <div
    //                     className="name-address"
    //                     onClick={() =>
    //                       handleSelect(item.meta?.name, item.address, i)
    //                     }
    //                   >
    //                     <div className="item-name">{item.meta?.name}</div>
    //                     <div className="item-address">
    //                       {item.address?.substring(0, 15)}...
    //                       {item.address?.substring(item.address.length - 8)}
    //                     </div>
    //                   </div>
    //                 )}
    //               </>
    //             ))
    //           : ""}
    //       </div>
    //     </Modal> */}
    //   </>
    // );
};

export default ConnectSolana;
