/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import TopIdoDetails from "./TopIdoDetails";
import BottomIdoDetails from "./BottomIdoDetails";
import { useHookIDO } from "../StoreIdo";
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import ErrorPage from "pages/ErrorPage";
import { useHookPrice } from "components/common/Store";


const IdoDetailsTon = () => {
  const [state, actions]: any = useHookIDO();
  const userFriendlyAddress: any = useTonAddress();
  const [state1, actions1] = useHookPrice();
  const [objDetail, setObjDetail]: any = useState();
  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];


  const params = {
    address: userFriendlyAddress,
    symbol: pathHash,
  };
  const params1 = {
    address: '',
    symbol: pathHash,
  };


  useEffect(() => {
    if (userFriendlyAddress) {
      actions.getDetailIDO(params);
    }
    return () => {
      actions.resetDetail();
    };
  }, [userFriendlyAddress, pathHash]);

  useEffect(() => {
    document.title = "Launchpad - BSCS (BSCS)";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "BSCS Launchpad is the innovative decentralized IDO platform across all main blockchain networks."
      );
  }, []);

  const getObjDetail = async () => {
    try {
      await actions.getDetailIDO1(params1).then((res: any) => {
        setObjDetail(res)
      })
        .catch((err: any) => {
          setObjDetail()
        })
    } catch (error) {

    }
  }
  useEffect(() => {
    getObjDetail()
    return () => {
      setObjDetail()
    };
  }, [pathHash]);



  return (
    <>
      {objDetail?.network !== state1.chain &&
        objDetail?.network !== undefined ? (
        <ErrorPage text={'Ton Network'} />
      ) : (
        <div className="main-body">
          <div className="full-width">
            <div className="container">
              <div className="main-content-details">
                <div className="breadcrum-swap">
                  <ul className="list-breadcrum">
                    <li>
                      <Link to={"/Ido"}>Project</Link>
                    </li>
                    <li>{">"}</li>
                    <li>IDO Pool</li>
                  </ul>
                  <TopIdoDetails dataTop={objDetail} />
                  <BottomIdoDetails dataBody={state.objDetailIDO} objDetail={objDetail} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IdoDetailsTon;
