/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";

const StoreAcc = createStore({
  initialState: {
    listLinkAcc: [],
    accountApt: "",
    accountTON: "",
    accountSol: "",
    accountDot: "",
    accountSui: "",
    accountUNISAT: "",
    listKyc: {},
    listKycNew: {},
    listReferal: [],
    listLaunchpad: [],
    listDashboard: {},
    listStake: [],
    listLaunchpadMore: [],
    listReferalMore: [],
    listStakeMore: []
  },
  actions: {
    addAddress:
      (param) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpadv2/link_address_update`, {
                ...param,
              })
              .then((res) => {
                resolve(res);
              });
          });
        },
    getAddress:
      (ownerAddress) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpadv2/link_address`, {
                ownerAddress,
              })
              .then((res) => {
                setState({ listLinkAcc: res.data.data || [] });
                let x = res.data.data.find((i) => i.network === "apt");
                let y = res.data.data.find((i) => i.network === "sol");
                let z = res.data.data.find((i) => i.network === "dot");
                let s = res.data.data.find((i) => i.network === "sui");
                let b = res.data.data.find((i) => i.network === "brc");
                let t = res.data.data.find((i) => i.network === "ton");
                setState({ accountApt: x?.address });
                setState({ accountSol: y?.address });
                setState({ accountDot: z?.address });
                setState({ accountSui: s?.address });
                setState({ accountUNISAT: b?.address });
                setState({ accountTON: t?.address });
                resolve(res.data.data);
              });
          });
        },
    getListKyc:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/kyc/get_section`, params).then((res) => {
              setState({ listKyc: res.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListKycNew:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/kyc/new_kyc`, params).then((res) => {
              setState({ listKycNew: res.data.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    // referrals store
    getListReferal:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/referal`, params).then((res) => {
              setState({ listReferal: res.data.data || [] });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListLaunchpad:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/launchpad`, params).then((res) => {
              setState({ listLaunchpad: res.data.data || [] });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListLaunchpadMore:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/launchpad`, params).then((res) => {
              setState({
                listLaunchpadMore:
                  getState().getListLaunchpadMore.concat(res.data.data) || [],
              });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListDashboard:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/dashboard`, params).then((res) => {
              setState({ listDashboard: res.data.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListReferaldMore:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/referal`, params).then((res) => {
              setState({
                listReferalMore:
                  getState().getListReferalMore.concat(res.data.data) || [],
              });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListStake:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/stake_ido_pool`, params).then((res) => {
              setState({ listStake: res.data.data || [] });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListStakeMore:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/stake_ido_pool`, params).then((res) => {
              setState({
                listStakeMore:
                  getState().getListStakeMore.concat(res.data.data) || [],
              });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListSignref:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/signref`, params).then((res) => {
              setState({ listSignref: res.data.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
  },
  name: "IDO Store",
});

export const useHookACC = createHook(StoreAcc);
export const Container = createContainer(StoreAcc, {
  onInit:
    () =>
      ({ setState }, props) => {
        setState({ ...props });
      },
});
export const Subscriber = createSubscriber(StoreAcc);
