/* eslint-disable object-shorthand */
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { API_URL } from "./../../../constants/index";
//

export const PropertiesArtwork = {
  PAGE_SIZE: "pageSize",
  PAGE_NUMBER: "pageNumber",
  FILE_TYPE: "fileType",
  KEYWORK: "keywork",
  SORT_BY_PRICE: "sortByPrice",
  TYPE_ARTWORK: "typeArtworks",
  TOTAL_COUNT: "totalCount",
  STATUS: "status",
  SORT_TYPE: "sortType",
  AUTHOR: "author",
};

const getProducts =
  (params = {}) =>
    ({ setState, getState }) => {
      return new Promise((resolve, reject) => {
        const { objFilter } = getState();
        axios
          .post(`${API_URL}/Artworks/market_place`, {
            ...objFilter,
            ...params,
          })
          .then((res) => {
            const { data, totalCount, pageSize, pageNumber } = res.data;
            setState({
              nftList: data,
              totalCount,
              objFilter: {
                ...objFilter,
                ...params,
                pageSize,
                pageNumber,
              },
            });
            resolve(true);
          }, [])
          .catch((e) => {
            reject(e);
          });
      });
    };

const getMyArtwork =
  (params) =>
    ({ setState, getState }) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/my_artworks`, {
            ...params,
          })
          .then((res) => {
            const { data } = res.data;
            setState({
              nftList: data,
            });
            resolve(true);
          }, [])
          .catch((e) => {
            reject(e);
          });
      });
    };

const getMyArtworkMore =
  (params) =>
    ({ setState, getState }) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/my_artworks`, {
            ...params,
          })
          .then((res) => {
            if (res.data.data.length === 0) {
              setState({ hideLoadmore: true });
            }
            setState({
              nftListMore: getState().nftListMore.concat(res.data.data) || [],
            });
            resolve(true);
            resolve(true);
          }, [])
          .catch((e) => {
            reject(e);
          });
      });
    };

const getMyArtworkBiding =
  ({ ownerAddress, ...params }) =>
    ({ setState, getState }) => {
      return new Promise((resolve, reject) => {
        const { objFilter } = getState();
        axios
          .post(`${API_URL}/Artworks/Auction`, {
            ...objFilter,
            ownerAddress,
            ...params,
          })
          .then((res) => {
            const { data, totalCount, pageSize, pageNumber } = res.data;
            setState({
              nftList: data,
              totalCount,
              objFilter: {
                ...objFilter,
                ...params,
                pageSize,
                pageNumber,
              },
            });
            resolve(true);
          }, [])
          .catch((e) => {
            reject(e);
          });
      });
    };

const setIsFetching =
  (isFetching) =>
    ({ setState }) => {
      return new Promise((res) => {
        setState({ isFetching });
        res(true);
      });
    };

const resetObjFilter =
  () =>
    ({ setState }) => {
      setState({
        objFilter: {
          sortByPrice: "asc",
          keywork: "",
          fileType: 0,
          pageNumber: 1,
          pageSize: isMobile ? 10 : 24,
        },
      });
    };

const StoreArt = createStore({
  initialState: {
    objDataInfUser: {},
    objData: {
      ownerAddress: "",
      creatorAddress: "",
      tokenId: "",
      txHash: "",
      name: "",
      descriptions: "",
      author: "",
      nftType: "",
      fileType: "",
      fileName: "",
      fileNameImgVideo: "",
      social: {},
    },
    objData2: {
      ownerAddress: "",
      creatorAddress: "",
      tokenId: "",
      txHash: "",
      name: "",
      descriptions: "",
      author: "",
      nftType: "",
      fileType: "",
      fileName: "",
      fileNameImgVideo: "",
    },
    isFetching: false,
    nftList: [],
    nftListStake: [],
    additionalPoolWeight: 0,
    stakingBoost: 0,
    amount: "",
    tokenId: "",
    typeButon: "",
    addressReceive: "",
    marketInfo: {},
    totalCount: 0,
    typeNFT: [
      { id: 1, name: "Picture" },
      { id: 2, name: "Video" },
      { id: 3, name: "Gif" },

      // { id: 3, name: 'Audio' },
    ],
    sorts: [
      { id: "desc", name: "Descending" },
      { id: "asc", name: "Ascending" },
    ],
    objFilter: {
      sortByPrice: "asc",
      keywork: "",
      fileType: 0,
      pageNumber: 1,
      pageSize: isMobile ? 10 : 24,
      sortType: 0,
    },
    isWhitelist: true,
    yourBalanceBSCS: {},
    BSCSBurned: {},
    totalValueLock: {},
    totalVL: {},
    isCheckBuy: true,
    isLoadData: false,
    nftListMore: [],
    hideLoadmore: false,
  },
  actions: {
    getProducts,
    setIsFetching,
    getMyArtwork,
    getMyArtworkBiding,
    resetObjFilter,
    getMyArtworkMore,
    submit: (data) => () => {
      // console.log('data: ', data)
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("category", data.category);
      formData.append("channel", data.channel);
      formData.append("checkbox", data.checkbox);
      formData.append("description", data.description);
      formData.append("fileName", data.fileName);
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks`, formData)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    submitEditArtists:
      (data, account) =>
        ({ getState, setState }) => {
          const { objData } = getState();

          const formData = new FormData();
          formData.append("file", data.fileAvatar);
          return new Promise((resolve, reject) => {
            if (data.fileAvatar === "0") {
              const newData = {
                ...objData,
                ownerAddress: account,
                artistDescription: data.bio,
                artistName: data.name,
                publicProfileLink: data.social,
                social: {
                  medium: data.medium,
                  telegram: data.telegram,
                  twitter: data.twitter,
                  website: data.website,
                },
              };
              axios
                .post(`${API_URL}/Artists/update`, newData)
                .then(({ data: dataRes }) => {
                  if (dataRes?.data === objData.id) {
                    setState({ objData: newData });
                  }
                  resolve(true);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              axios
                .post(`${API_URL}/v1/Media/api/upload_file`, formData)
                .then((res) => {
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  formData.append("fileAvatar", res.data.filename);

                  const newData = {
                    ...objData,
                    ownerAddress: account,
                    artistDescription: data.bio,
                    artistName: data.name,
                    publicProfileLink: data.social,
                    avatarName: res.data.data.filename,
                    banner: res.data.data.filename,
                    social: {
                      medium: data.medium,
                      telegram: data.telegram,
                      twitter: data.twitter,
                      website: data.website,
                    },
                  };
                  axios
                    .post(`${API_URL}/Artists/update`, newData)
                    .then(({ data: dataRes }) => {
                      if (dataRes?.data === objData.id) {
                        setState({ objData: newData });
                      }
                      resolve(true);
                    });
                })
                .catch((err) => {
                  reject(err);
                });
            }
          });
        },
    onChangeInput:
      (obj) =>
        ({ getState, setState }) => {
          setState({
            objFilter: { ...getState().objFilter, [obj.id]: obj.value },
          });
        },
    onChangeSelect:
      (obj) =>
        ({ dispatch }) => {
          dispatch(
            getProducts({
              [obj.id]: obj.value,
            })
          );
        },
    /* eslint-disable  no-useless-catch, operator-assignment, object-shorthand */
    getMoreProducts:
      () =>
        async ({ getState, setState, dispatch }) => {
          try {
            const { objFilter, nftList } = getState();
            objFilter[PropertiesArtwork.PAGE_NUMBER] =
              objFilter[PropertiesArtwork.PAGE_NUMBER] + 1;
            const {
              data: { data, totalCount, pageSize, pageNumber },
            } = await axios.post(`${API_URL}/Artworks/market_place`, objFilter);
            setState({
              nftList: nftList.concat(data || []),
              totalCount: totalCount,
              objFilter: {
                ...objFilter,
                [PropertiesArtwork.PAGE_NUMBER]: pageNumber,
                [PropertiesArtwork.PAGE_SIZE]: pageSize,
              },
            });
          } catch (e) {
            console.error({ "[Store-Nft] -> getMoreProducts": e });
          }
        },

    getMoreMyArtwork:
      (ownerAddress) =>
        async ({ getState, setState }) => {
          try {
            const { objFilter, nftList } = getState();
            objFilter[PropertiesArtwork.PAGE_NUMBER] =
              objFilter[PropertiesArtwork.PAGE_NUMBER] + 1;
            const {
              data: { data, totalCount, pageSize, pageNumber },
            } = await axios.post(`${API_URL}/Artworks/my_artworks`, {
              ownerAddress,
              ...objFilter,
            });
            setState({
              nftList: nftList.concat(data || []),
              totalCount: totalCount,
              objFilter: {
                ...objFilter,
                [PropertiesArtwork.PAGE_NUMBER]: pageNumber,
                [PropertiesArtwork.PAGE_SIZE]: pageSize,
              },
            });
          } catch (e) {
            console.error({ "[Store-Nft] -> getMoreProducts": e });
          }
        },

    /* eslint-enable  no-useless-catch, operator-assignment, object-shorthand */
    updateInfo:
      (obj) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/Artworks/update`, obj).then((res) => {
              resolve(res);
            });
          });
        },
    checkBuy:
      (account) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Artworks/check_nft_buy/${account}`).then((res) => {
              setState({ isCheckBuy: res.data.succeeded });
              resolve(res.data.succeeded);
            });
          });
        },
    insertBuyToDB:
      (obj) =>
        ({ getState, setState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/Artworks/nft_buy`, obj).then((res) => {
              const { isLoadData } = getState();
              setState({ isLoadData: !isLoadData });
              resolve(res);
            });
          });
        },
    getMarketInfo:
      () =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Market/api/get_market`).then((res) => {
              const { data } = res.data;
              setState({ marketInfo: { ...data } }); // TODO
              resolve(data);
            });
          });
        },
    onChangeAmount:
      (value) =>
        ({ setState }) => {
          if (value < 0) {
            setState({ amount: "" });
          } else {
            setState({ amount: value });
          }
        },
    onChangeAddess:
      (value) =>
        ({ setState }) => {
          setState({ addressReceive: value });
        },
    updateTokenId:
      (value) =>
        ({ setState }) => {
          setState({ tokenId: value });
        },
    validateWhitelist:
      (value) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Artworks/CheckWhileList/${value}`).then((res) => {
              setState({ isWhitelist: res.data.succeeded });
              resolve("validateWhitelist");
            });
          });
        },
    insertBiding: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/Create_Auction`, {
            ownerAddress: obj.account,
            productCode: obj.code,
            price: parseFloat(obj.amount),
            txnHash: obj.hash,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    updatelBiding: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/Update_Auction`, {
            ownerAddress: obj.account,
            productCode: obj.code,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    cancelBiding: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/Update_Auction`, {
            ownerAddress: obj.account,
            productCode: obj.code,
            status: 0,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    checkNftAvailable: (obj) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/Artworks/nft_available`, {
            ownerAddress: obj.account,
            id: obj.id,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    gen_sig: (account, tokenId) => () => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}/artworks/gen_sign`, {
            ownerAddress: account,
            tokenId,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },

    getArtistsByWallet:
      (account) =>
        ({ getState, setState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/Artists/getbyOwnerwallet`, {
                ownerAddress: account,
              })
              .then((res) => {
                const { data } = res.data;
                const { objData } = getState();
                const { objData2 } = getState();
                if (data.length > 0) {
                  const newData = {
                    ...objData,
                    ...data[0],
                    author: data[0].id,
                    artistName: data[0].artistName,
                  };
                  const newData2 = {
                    author: data[0].id,
                  };
                  setState({ objData: newData });
                  setState({ objData2: newData2 });
                  resolve(data[0]);
                } else {
                  resolve(res.data);
                }
              });
          });
        },

    getProductsDetail:
      (id) =>
        ({ setState }) => {
          setState({ objData: {} });
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Artworks/${id}`).then((res) => {
              const { data } = res.data;
              setState({ objData: { ...data } }); // TODO
              resolve(data);
            });
          });
        },
    updateInfo:
      (obj) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/Artworks/update`, obj).then((res) => {
              resolve(res);
            });
          });
        },
    getProductsSearch:
      (keywork) =>
        ({ setState, getState }) => {
          return new Promise((resolve) => {
            axios
              .post(`${API_URL}/Artworks/market_place`, {
                keywork,
              })
              .then((res) => {
                setState({ nftList: res.data.data });
                resolve(true);
              });
          });
        },
    getProductsUserSort:
      (sortType) =>
        ({ setState, getState }) => {
          return new Promise((resolve) => {
            axios
              .post(`${API_URL}/Artworks/market_place`, {
                sortType,
              })
              .then((res) => {
                setState({ nftList: res.data.data });
                resolve(true);
              });
          });
        },
    getProductsUserType:
      (typeArtworks, fileType) =>
        ({ setState, getState }) => {
          return new Promise((resolve) => {
            axios
              .post(`${API_URL}/Artworks/market_place`, {
                typeArtworks,
                fileType,
              })
              .then((res) => {
                setState({ nftList: res.data.data });
                resolve(true);
              });
          });
        },
    myNFT:
      (params) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/unicorn/my_nft`, params).then((res) => {
              resolve(res?.data?.data);
            });
          });
        },
    yourOffer:
      (params) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/unicorn/your_offer`, params).then((res) => {
              resolve(res?.data?.data);
            });
          });
        },
    hisOffer:
      (params) =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/unicorn/history_offer`, params).then((res) => {
              resolve(res?.data?.data);
            });
          });
        },
  },
  name: "StoreArt",
});

export const useHookArt = createHook(StoreArt);
export const Container = createContainer(StoreArt, {
  onInit:
    () =>
      ({ setState }, props) => {
        setState({ ...props });
      },
});
export const Subscriber = createSubscriber(StoreArt);
