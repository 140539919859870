import Web3 from "web3";
import { MINT_NFT_CONTRACT } from "../constants/index";
import { convertFromWei, convertToWei } from "../utils/convertNumber";
import abiMintNFT from "../abi/abiMintNFT.json";

const web3 = new Web3(window.ethereum);

export const getInforContractMint = async () => {
  if (typeof window.ethereum !== "undefined") {
    const contract = new web3.eth.Contract(abiMintNFT, MINT_NFT_CONTRACT);
    const name = await contract.methods.name().call();
    const mint_Fee = await contract.methods.mintFee().call();
    const totalSupply = await contract.methods.totalSupply().call();
    const feeMint = await convertFromWei(mint_Fee, 18);
  
    return {
      name: name,
      mintFee: Number(feeMint),
      totalSupply: Number(totalSupply),
    };
  }
};
