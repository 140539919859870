import web3 from "web3";

const convertNumber = (value) => {
  return web3.utils.toWei(value.toString(), "ether");
};
export const _getReward = async (stakeContract, nftContract, nftId, price) => {
  try {
    const args = [nftContract, convertNumber(price), nftId];
    const result = await stakeContract.pendingReward(...args);
    return result;
  } catch (error) {
    return error;
  }
};
export const _checkStakes = async (stakeContract, nftAddress, nftId) => {
  try {
    const args = [nftAddress, nftId];
    const result = await stakeContract.stakes(...args);
    return result;
  } catch (error) {
    return error;
  }
};
export const _stake = async (stakeContract, nftId, nftAddress, price, sig) => {
  try {
    const args = [nftId, nftAddress, convertNumber(price), sig];
    // console.log('args>>',args)
    const gas = await stakeContract.estimateGas.deposit(...args);
    const result = await stakeContract.deposit(...args, { gasLimit: gas });
    return result;
  } catch (error) {
    return error;
  }
};
export const _unStake = async (
  stakeContract,
  nftId,
  nftAddress,
  price,
  sig
) => {
  try {
    const args = [nftId, nftAddress, convertNumber(price), true, sig];
    const gas = await stakeContract.estimateGas.withdraw(...args);
    const result = await stakeContract.withdraw(...args, { gasLimit: gas });
    return result;
  } catch (error) {
    return error;
  }
};
export const _havest = async (stakeContract, nftId, nftAddress, price, sig) => {
  try {
    const args = [nftId, nftAddress, convertNumber(price), false, sig];
    const gas = await stakeContract.estimateGas.withdraw(...args);
    const result = await stakeContract.withdraw(...args, { gasLimit: gas });
    return result;
  } catch (error) {
    return error;
  }
};
