/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from "bignumber.js";
import React, { useEffect, useState, useCallback } from "react";
import { Tabs, Collapse, Button as ButtonConnect, Modal } from "antd";
import Value from "../../componentsST/Value";
import "./style.css";
import useSousApprove from "../../hooksST/useSousApprove";
import useSousHarvest from "../../hooksST/useSousHarvest";
import useSousHarvestV3 from "../../hooksST/useSousHarvestV3";
import useSousHarvestUserLock from "../../hooksST/useSousHarvestUserLock";
import { fetchPoolsUserDataAsync } from "../../store/pools/index";
import useModal from "../../hooksST/useModal";
import DepositModal from "./DepositModal";
import UnstakingFeeCountdownRow from "./UnstakingFeeCountdownRow";
import { getBalanceNumber } from "../../utilsST/formatBalance";
import { BIG_ZERO } from "../../utilsST/bigNumber";
import { formatNumber } from "../../utilsST/formatBalance";
import Button from "../../componentsST/ButtonV2";
import { useDispatch } from "react-redux";
import WithdrawModal from "./WithdrawModal";
import ApyCalculatorModal from "../../componentsST/ApyCalculatorModal";
import WithdrawUserLockModal from "./WithdrawUserLockModal";
import BlockCountdown from "./BlockCountdown";
import BlockCountdownDetail from "./BlockCountdownDetail";
import sousChefV3ABI from "../../config/abi/sousChefV3.json";
import { getContract } from "../../utilsST/contractHelpers";
import ConnectWallet from "./../../components/Connect-wallet/ConnectWallet";
import { getTokenStake, getRewards } from "../../utilsST/callHelpers";
import sousChefAbi from "../../abi/stakeFinal.json";
import { useContract } from "../../hooks/useContract";
import { useAccount } from "wagmi";

const FarmList = ({ pool, userDataLoaded }) => {
  const { address } = useAccount();
  let account = address
  const dispatch = useDispatch();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const { userData } = pool;
  const { onReward } = useSousHarvest(pool.sousId);
  const { onRewardV3 } = useSousHarvestV3(pool.sousId);
  const { onRewardUserLock } = useSousHarvestUserLock(pool.sousId);
  const [isBalanceStake, setBalanceStake] = useState(0);
  const [isPendingRewards, setPendingRewards] = useState(0);
  const [pendingTx, setPendingTx] = useState(false);
  const [listSchedule, setListSchedule] = useState([]);
  const stakings = userData?.stakedBalance
    ? new BigNumber(userData.stakedBalance)
    : BIG_ZERO;
  // These will be reassigned later if its Auto CAKE vault
  let stakingTokenBalance = getBalanceNumber(
    stakings,
    pool.stakingToken.decimals
  );
  let stakingTokenDollarBalance = getBalanceNumber(
    stakings.multipliedBy(pool.stakingTokenPrice),
    pool.stakingToken.decimals
  );

  const earnings = userData?.pendingReward
    ? new BigNumber(userData.pendingReward)
    : BIG_ZERO;
  let earningTokenDollarBalance = getBalanceNumber(
    earnings.multipliedBy(pool.earningTokenPrice),
    pool.earningToken.decimals
  );

  const allowance = userData.allowance;
  const { onApprove } = useSousApprove(
    pool.stakingToken.address,
    pool.contractAddress
  );
  const isApproved = account && allowance && allowance.isGreaterThan(0);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove();
      dispatch(fetchPoolsUserDataAsync(account));
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
    }
  }, [onApprove, dispatch, account, pool]);


  const handleReward = useCallback(async () => {
    try {
      setPendingTx(true);
      await onReward().then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
          setPendingTx(false);
        });
      })
        .catch((error) => {
          setPendingTx(false);
        });
      dispatch(fetchPoolsUserDataAsync(account));
    } catch (e) {
      setPendingTx(false);
    }
  }, [account, dispatch, onReward, pool]);

  const handleRewardV3 = useCallback(async () => {
    try {
      setPendingTx(true);
      await onRewardV3().then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
          setPendingTx(false);
        });
      })
        .catch((error) => {
          setPendingTx(false);
        });
      dispatch(fetchPoolsUserDataAsync(account));
    } catch (e) {
      setPendingTx(false);
    }
  }, [account, dispatch, onRewardV3, pool]);

  const handleRewardUserLock = useCallback(async () => {
    try {
      setPendingTx(true);
      await onRewardUserLock().then((res) => {
        setPendingTx(true);
        res.wait().then((res2) => {
          setPendingTx(false);
        });
      })
        .catch((error) => {
          setPendingTx(false);
        });
      dispatch(fetchPoolsUserDataAsync(account));
    } catch (e) {
      setPendingTx(false);
    }
  }, [account, dispatch, onRewardUserLock, pool]);

  const [onPresentStakeInPoolModal] = useModal(
    <DepositModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      stakingToken={pool.stakingToken}
      addressToken={pool.stakingToken.address}
      max={userData.stakingTokenBalance}
      // onConfirm={handleStake}
      isModalOpen={true}
    />
  );


  const [onPresentWithdrawModal] = useModal(
    <WithdrawModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.stakedBalance}
    // onConfirm={handleUnstake}
    />
  );

  const [onPresentWithdrawUserLockModal] = useModal(
    <WithdrawUserLockModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      versionSC={pool.versionSC}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.unstakeAmount}
      balance={userData.stakedBalance}
    // onConfirm={handleUnstakeUserLock}
    />
  );
  const [onPresentWithdrawUserLockModalV3] = useModal(
    <WithdrawUserLockModal
      sousId={pool.sousId}
      decimals={pool.stakingToken.decimals}
      versionSC={pool.versionSC}
      fees={pool.fees}
      lastStakingBlock={userData.lastStakingBlock}
      blockPeriod={pool.blockPeriod}
      stakingToken={pool.stakingToken}
      max={userData.unstakeAmount}
      balance={userData.stakedBalance}
    // onConfirm={handleUnstakeV3}
    />
  );
  const { Panel } = Collapse;

  const onChangeCollapse = (key) => {
    console.log(key);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const contractAddressPool = getContract(sousChefV3ABI, pool.contractAddress);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const getBalance = async () => {
    if (contract) {
      const balance = await getTokenStake(
        contract,
        pool.sousId,
        account
      );
      setBalanceStake(balance.amount / 1e18);
    }
  };
  const getPendingRewards = async () => {
    if (contract) {
      const rewards = await getRewards(
        contract,
        pool.sousId,
        account
      );
      setPendingRewards(rewards[0] / 1e18);
    }
  };

  useEffect(() => {
    getBalance();
    getPendingRewards();
  });
  useEffect(() => {
    if (account && contractAddressPool) {
      contractAddressPool.methods
        .getUserStakedCount(account)
        .call()
        .then(async (resStakedCount) => {
          if (resStakedCount !== "0") {
            let arrData = [];
            for (let i = 0; i < parseInt(resStakedCount); i++) {
              arrData.push(
                contractAddressPool.methods.stakeDetails(account, i).call()
              );
            }
            let listData = [];
            // get result
            await Promise.all([arrData]).then((resAll) => {
              for (let i = 0; i < parseInt(resStakedCount); i++) {
                resAll[0][i].then((data) => {
                  listData.push(data);
                });
              }
            });
            setTimeout(() => {
              setListSchedule(listData);
            }, 1000);
          }
        });
    }
  }, [account]);
  const contentModalClick = (
    <>
      <table className="table-modal">
        <tr>
          <th>Amount</th>
          <th>Stake block</th>
          <th>Remaining unlock</th>
        </tr>
        {listSchedule
          .filter((x) => x.amount > 0)
          .map((item, i) => {
            return (
              <tr key={`schedule_${i}`}>
                <td>{item.amount / 1e18}</td>
                <td>{item.startStakeBlock}</td>
                <td>
                  {item.endStakeBlock}{" "}
                  <a
                    href={`https://bscscan.com/block/countdown/${item.endStakeBlock}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                  >
                    <span className="schedules-p-time">
                      <img src="/images/pool/p-time.png" alt="" />
                    </span>
                  </a>
                </td>
              </tr>
            );
          })}
      </table>
    </>
  );
  const headerPool = (
    <>
      <div className="guide-swap LP">
        <div className="item-round">
          <div className="name-project">
            <div className="box-img">
              <div className="icon-big">
                <img
                  src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=5`}
                  alt=""
                />
              </div>
              <div className="icon-small">
                <img
                  src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=5`}
                  alt=""
                />
              </div>
            </div>
            <div className="box-name">
              <span className="title-earn">
                Earn {pool.earningToken.symbol}
              </span>
              {pool.lockup > 0 ? (
                <span className="title-stake">Lock-up {pool.lockup} days</span>
              ) : (
                ""
              )}

            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                {pool.apr ? <>{formatNumber(pool.apr)}%</> : "0%"}
              </span>{" "}
              {/* <span className="img-cul">
                <img src="/images/pool/cul.png" alt="" />
              </span> */}
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <Value
                  size={16}
                  unit="$"
                  value={pool.stakedTvl ? +pool.stakedTvl : 0}
                />{" "}
                <span className="symbol">{pool.stakingToken.symbol}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt">
                <BlockCountdown pool={pool} />
              </span>
            </div>
          </div>
        </div>
        <div className="item-round">
          <div className="name-project">
            <div className="number">
              <span className="txt main-color-pool">
                <Value
                  size={16}
                  className='txt main-color-pool'
                  value={
                    account
                      ? isPendingRewards
                      : 0.0
                  }
                />
              </span>
              <span className="symbol">{pool.earningToken.symbol}</span>
            </div>
          </div>
        </div>
        <Modal
          className="modal-schedule"
          title="Schedule"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {contentModalClick}
        </Modal>
      </div>
    </>
  );
  const contentBottom = (
    <>
      <div className="main-swap-pool">
        <div className="item-block">
          <div className="content-block">
            <div className="reward-in">
              <span className="txt-l">Rewards end in:</span>
              <div className="txt-r">
                <BlockCountdownDetail pool={pool} />
              </div>
            </div>
            <div className="view-port">
              <UnstakingFeeCountdownRow
                fees={pool.fees}
                lastStakingBlock={userData.lastStakingBlock}
                blockPeriod={pool.blockPeriod}
              />
            </div>
            <div className="view-port">
              <div className="text details">
                View contract pool
                <span>
                  <a
                    href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/pool/p-info.svg" alt="" />
                  </a>
                </span>
              </div>
            </div>
            <div className="view-port">
              <div className="text details">
                View contract LP{" "}
                <span>
                  <a
                    href={`https://bscscan.com/address/${pool.stakingToken.address.toLowerCase()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/pool/p-info.svg" alt="" />
                  </a>
                </span>
              </div>
            </div>


            {/* <div className="view-port">
            <div className="text details">
              Get {pool.stakingToken.symbol}
              {pool.cakeLP ? (
            <>
              {pool.typeLP === 3 ? (
                <>
                  <span>
                    <a
                      href={`${pool.linkLP}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/images/pool/p-info.svg" alt="" />
                    </a>
                  </span>
                </>
              ) : (
                <>
                  {pool.typeLP === 2 ? (
                    <>
                      <span>
                        <a
                          href={`https://pancakeswap.finance/add/BNB/${pool.earningToken.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/images/pool/p-info.svg" alt="" />
                        </a>
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <a
                          href={`https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${pool.earningToken.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/images/pool/p-info.svg" alt="" />
                        </a>
                      </span>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {pool.typeLP === 1 ? (
                <>
                  <span>
                    <a
                      href={`https://bscstation.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${pool.earningToken.address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/images/pool/p-info.svg" alt="" />
                    </a>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <a
                      href={`https://bscstation.finance/#/add/ETH/${pool.earningToken.address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/images/pool/p-info.svg" alt="" />
                    </a>
                  </span>
                </>
              )}
            </>
          )}
            </div>
          </div> */}
            <div className="view-port">
              <div className="text details">
                Remove Liquidity
                <span>
                  <a
                    href={`https://v1.bscstation.finance/#/pool`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/pool/p-info.svg" alt="" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="item-block">
          <div className="content-block">
            <div className="box-stake">
              <div className="title">{pool.earningToken.symbol} Earned</div>
              <div className="box-bottom">
                <div className="round-l">
                  <div className="number-stake">
                    <Value
                      size={18}
                      value={
                        account
                          ? isPendingRewards
                          : 0
                      }
                    />{" "}
                    <span className="symbol">{pool.earningToken.symbol}</span>
                  </div>
                  <div className="number-rw">
                    <Value
                      size={14}
                      unit={"$"}
                      value={earningTokenDollarBalance}
                    />
                  </div>
                </div>
                <div className="round-r">
                  {pool.userLock ? (
                    <>
                      {pool.versionSC === 3 ? (
                        <Button
                          primary
                          disabled={
                            !account ||
                            pendingTx ||
                            userData.pendingReward.eq(new BigNumber(0)) ||
                            !isApproved ||
                            pool.disHarvest === true
                          }
                          loading={pendingTx}
                          text="Harvest"
                          click={handleRewardV3}
                        />
                      ) : (
                        <Button
                          primary
                          disabled={
                            !account ||
                            pendingTx ||
                            userData.pendingReward.eq(new BigNumber(0)) ||
                            !isApproved ||
                            pool.disHarvest === true
                          }
                          loading={pendingTx}
                          text="Harvest"
                          click={handleRewardUserLock}
                        />
                      )}
                    </>
                  ) : (
                    <Button
                      primary
                      disabled={
                        !account ||
                        pendingTx ||
                        userData.pendingReward.eq(new BigNumber(0)) ||
                        !isApproved ||
                        pool.disHarvest === true
                      }
                      loading={pendingTx}
                      text="Harvest"
                      click={handleReward}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-block">
          <div className="content-block">
            <div className="box-stake">
              <div className="title res">{pool.stakingToken.symbol} Staked</div>
              <div className="box-bottom">
                <div className="round-l">
                  <div className="number-stake">
                    <Value
                      size={20}
                      value={
                        account
                          ? isBalanceStake
                          : 0
                      }
                    />
                    {pool.versionSC === 3 ? (
                      <button
                        type="button"
                        className="btn-schedule mg-left-5"
                        onClick={showModal}
                      >
                        <span>
                          <img src="/images/pool/calendar.svg" alt="" />
                        </span>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="number-rw">
                    <Value
                      size={15}
                      unit={"$"}
                      value={account ? stakingTokenDollarBalance : '$0.00'}
                    />
                  </div>
                </div>
                <div className="round-r">
                  {pool.userLock ? (
                    <>
                      {pool.versionSC === 3 ? (
                        <Button
                          ghost
                          disabled={
                            !account ||
                            userData.stakedBalance.eq(new BigNumber(0)) ||
                            !isApproved ||
                            pool.disUnstake === true ||
                            userData.unstakeAmount <= 0
                          }
                          text="Unstake"
                          click={onPresentWithdrawUserLockModalV3}
                        />
                      ) : (
                        <Button
                          ghost
                          disabled={
                            !account ||
                            userData.stakedBalance.eq(new BigNumber(0)) ||
                            !isApproved ||
                            pool.disUnstake === true ||
                            userData.unstakeAmount <= 0
                          }
                          text="Unstake"
                          click={onPresentWithdrawUserLockModal}
                        />
                      )}
                    </>
                  ) : (
                    <Button
                      ghost
                      disabled={
                        !account ||
                        userData.stakedBalance.eq(new BigNumber(0)) ||
                        !isApproved ||
                        pool.disUnstake === true
                      }
                      text="Unstake"
                      click={onPresentWithdrawModal}
                    />
                  )}
                  <Button
                    className="btn-plus"
                    primary
                    disabled={
                      !account ||
                      !isApproved ||
                      pool.isFinished === true ||
                      pool.disStake === true
                    }
                    text="+"
                    click={onPresentStakeInPoolModal}
                  />
                </div>
              </div>
              <div className="round-connect">
                {account ? (
                  <>
                    {userDataLoaded ? (
                      <>
                        {!isApproved && (
                          <Button
                            loading={requestedApproval}
                            className="btn-w-full"
                            text="Approve Contract"
                            disabled={pool.isFinished === true}
                            click={handleApprove}
                            primary
                          />
                        )}
                      </>
                    ) : (
                      <Button
                        primary
                        className="btn-w-full Loading"
                        text="Loading..."
                      />
                    )}
                  </>
                ) : (
                  <div className="connect-cus-start-pool">
                    <ConnectWallet />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );

  return (
    <>
      <div className="main-round-pool">
        <div className="round-pool">
          <Collapse onChange={onChangeCollapse}>
            <Panel header={headerPool}>{contentBottom}</Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};
export default FarmList;
