const Icon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="#687688"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.4164 8.70833L17.9081 16.95C17.9247 17.175 17.8164 17.325 17.7581 17.3917C17.6914 17.4667 17.5497 17.5833 17.3164 17.5833H15.0414L16.8414 8.70833H17.4164ZM18.3331 5.5L18.3247 5.51667C18.3414 5.71667 18.3247 5.925 18.2747 6.13333L12.1331 17.4083C11.9331 18.25 11.1831 18.8333 10.3164 18.8333H17.3164C18.3914 18.8333 19.2414 17.925 19.1581 16.85L18.3331 5.5Z" />
    <path d="M9.54154 2.36671C9.62487 2.03337 9.41654 1.69171 9.0832 1.60837C8.74987 1.53337 8.4082 1.73337 8.32487 2.06671L7.9082 3.79171H9.19154L9.54154 2.36671Z" />
    <path d="M15.0414 2.34171C15.1164 2.00004 14.8997 1.67504 14.5581 1.60004C14.2247 1.52504 13.8914 1.74171 13.8164 2.08338L13.4414 3.80838H14.7247L15.0414 2.34171Z" />
    <path d="M18.1838 4.94166C17.9088 4.27499 17.2588 3.79999 16.4588 3.79999H14.7254L14.2588 5.95833C14.1921 6.24999 13.9338 6.44999 13.6504 6.44999C13.6088 6.44999 13.5588 6.44999 13.5171 6.43333C13.1838 6.35833 12.9671 6.02499 13.0338 5.69166L13.4421 3.79166H9.19211L8.66711 5.95833C8.60045 6.24166 8.34211 6.43333 8.05878 6.43333C8.00878 6.43333 7.95878 6.42499 7.90878 6.41666C7.57545 6.33333 7.36711 5.99999 7.45045 5.65833L7.90045 3.78333H6.20878C5.39211 3.78333 4.66711 4.31666 4.42545 5.09999L0.917113 16.3917C0.550446 17.6 1.44211 18.8333 2.70045 18.8333H13.6504C14.5171 18.8333 15.2671 18.25 15.4671 17.4083L18.2754 6.13333C18.3254 5.92499 18.3421 5.71666 18.3254 5.51666C18.3088 5.31666 18.2671 5.11666 18.1838 4.94166ZM12.2504 14.4583H5.58378C5.24211 14.4583 4.95878 14.175 4.95878 13.8333C4.95878 13.4917 5.24211 13.2083 5.58378 13.2083H12.2504C12.5921 13.2083 12.8754 13.4917 12.8754 13.8333C12.8754 14.175 12.5921 14.4583 12.2504 14.4583ZM13.0838 11.125H6.41711C6.07545 11.125 5.79211 10.8417 5.79211 10.5C5.79211 10.1583 6.07545 9.87499 6.41711 9.87499H13.0838C13.4254 9.87499 13.7088 10.1583 13.7088 10.5C13.7088 10.8417 13.4254 11.125 13.0838 11.125Z" />
  </svg>
);
export default Icon;
