import { isMobile } from "react-device-detect";
import "./style.css";

const Banner = () => {
  const gotoApply = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    );
  };
  return (
    <>
      <div className={isMobile ? "banner-apply-mobie" : "banner-apply"}>
        <div className="banner-text-first">
          <span className="first-sub">Apply to Launch with</span> &ensp;
          <span className="sec-sub">BSCS</span>
        </div>
        <div className="banner-text-sec">
          An all-in-one Incubation Hub with a full stack Defi platform
        </div>
        <div className="banner-text-last">
          across all main blockchain networks
        </div>
        <div className="btn-all">
          <div className="banner-button" onClick={gotoApply}>
            Apply to Launch
          </div>
        </div>
        {isMobile ? (
          <>
            <div className="item-dragon ">
              <div>
                <img src="./images/apply/dragon.png" alt="" />
              </div>
              <div className="item-text">Dragon Kart</div>
            </div>
            <div className="item-9d ">
              <div className="item-icon">
                <img src="./images/apply/9d.png" alt="" />
              </div>
              <div className="item-text">9D NFT</div>
            </div>
            <div className="item-mst ">
              <div className="item-icon">
                <img src="./images/apply/mst.png" alt="" />
              </div>
              <div className="item-text">MStation</div>
            </div>
            <div className="item-dare ">
              <div className="item-icon">
                <img src="./images/apply/dare.png" alt="" />
              </div>
              <div className="item-text">Dare NFT</div>
            </div>
            <div className="item-icon mcity rotate-animation-banner">
              <img src="./images/apply/mcity.png" alt="" />
            </div>
            <div className="item-icon real rotate-animation-banner">
              <img src="./images/apply/real.png" alt="" />
            </div>
            <div className="item-icon d rotate-animation-banner">
              <img src="./images/apply/d.png" alt="" />
            </div>
            <div className="item-grbe ">
              <div className="item-icon">
                <img src="./images/apply/green.png" alt="" />
              </div>
              <div className="item-text">Greenbeli</div>
            </div>
            <div className="item-icon zwz rotate-animation-banner">
              <img src="./images/apply/zwz.png" alt="" />
            </div>
            <div className="item-runt ">
              <div className="item-icon">
                <img src="./images/apply/runt.png" alt="" />
              </div>
              <div className="item-text">Run together</div>
            </div>
          </>
        ) : (
          <div className="s">
            <div className="item-dragon ">
              <div>
                <img src="./images/apply/dragon.png" alt="" />
              </div>
              <div className="item-text">Dragon Kart</div>
            </div>
            <div className="item-lfw ">
              <div className="item-icon">
                <img src="./images/apply/lfw.png" alt="" />
              </div>
              <div className="item-text">Legend of Fantasy War</div>
            </div>
            <div className="item-icon zuki rotate-animation-banner">
              <img src="./images/apply/zuki.png" alt="" />
            </div>
            <div className="item-grbe ">
              <div className="item-icon">
                <img src="./images/apply/green.png" alt="" />
              </div>
              <div className="item-text">Greenbeli</div>
            </div>
            <div className="item-icon zwz rotate-animation-banner">
              <img src="./images/apply/zwz.png" alt="" />
            </div>
            <div className="item-icon run rotate-animation-banner">
              <img src="./images/apply/run.png" alt="" />
            </div>
            <div className="item-dare ">
              <div className="item-icon">
                <img src="./images/apply/dare.png" alt="" />
              </div>
              <div className="item-text">Dare NFT</div>
            </div>
            <div className="item-9d ">
              <div className="item-icon">
                <img src="./images/apply/9d.png" alt="" />
              </div>
              <div className="item-text">9D NFT</div>
            </div>
            <div className="item-mst ">
              <div className="item-icon">
                <img src="./images/apply/mst.png" alt="" />
              </div>
              <div className="item-text">MStation</div>
            </div>
            <div className="item-runt ">
              <div className="item-icon">
                <img src="./images/apply/runt.png" alt="" />
              </div>
              <div className="item-text">Run together</div>
            </div>
            <div className="item-icon hug rotate-animation-banner">
              <img src="./images/apply/hug.png" alt="" />
            </div>
            <div className="item-icon mcity rotate-animation-banner">
              <img src="./images/apply/mcity.png" alt="" />
            </div>
            <div className="item-icon real rotate-animation-banner">
              <img src="./images/apply/real.png" alt="" />
            </div>
            <div className="item-icon d rotate-animation-banner">
              <img src="./images/apply/d.png" alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Banner;
