/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Tabs, Collapse, Input, Switch, Select } from "antd";
import PoolList from "./PoolList";
import bgMainheader from "./images/pools.png";
import { useFetchPublicPoolsData, usePollBlockNumber } from "../../store/hooks";
import ErrorPage from "../ErrorPage";
import { usePools } from "../../store/hooks";
import "./style.css";
import { useHookPrice } from "../../components/common/Store";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";

const StartPools = () => {
  const [state1, actions1] = useHookPrice();
  const { address } = useAccount();

  let account = address
  const { pools, userDataLoaded } = usePools(account);
  const { Search } = Input;
  usePollBlockNumber();
  useFetchPublicPoolsData();
  let curentChain = localStorage.getItem("chain");
  const { TabPane } = Tabs;

  const { Option } = Select;
  const { Panel } = Collapse;
  const onChangeCollapse = (key) => {
    console.log(key);
  };

  const [checked, setchecked] = useState(false);
  const [defaultChecked, setdefaultChecked] = useState(false);
  function onChange(checked) {
    if (checked === true) {
      setchecked(true);
      setdefaultChecked(true);
    } else {
      setchecked(false);
      setdefaultChecked(false);
    }
  }
  const [searchQuery, setSearchQuery] = useState("");
  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };
  const tableWrapperEl = useRef(null);
  const scrollToTop = () => {
    tableWrapperEl.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  const [searchChain, setSearchChain] = useState(0);
  const handleChangeSort = (value) => {
    setSearchChain(value);
  };
  const contentTop = (
    <>
      <div className="main-round-pool">
        <div className="round-slide">
          <div className="item">
            <span className="name">Project Token</span>
          </div>
          <div className="item">
            <span className="name">APR</span>
          </div>
          <div className="item">
            <span className="name">Lock-up</span>
          </div>
          <div className="item">
            <span className="name">Total staked</span>
          </div>
          <div className="item">
            <span className="name">Rewards end in</span>
          </div>
          <div className="item">
            <span className="name">Earned</span>
          </div>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    document.title = "Stake token to earn - BSCS (BSCS)";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
      );
  }, [state1.chain]);
  const handleTabChange = (key) => {
    if (key === '2') {
      window.open('https://stake.bscstation.finance/', '_blank');

    }
  };
  const chainId = useChainId()
  return (
    <>
      {chainHeader(chainId) === "bep" ? (
        <>
          <div
            className="main-startpool"
            style={{
              background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
            }}
          >
            <div className="container">
              <div className="content-startpool">
                <div className="top-startpool">
                  <div className="title">Start Pools</div>
                  <p className="desc">
                    Stake token to earn. Rewards are calculated per block.
                    <br />
                    High return, low risk.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-startpool">
              <div
                className="bottom-startpool"
                role="table"
                ref={tableWrapperEl}
              >
                <div className="tabs-startpool">
                  <div className="filter-swap">
                    <div className="switch-filter">
                      <Switch onChange={onChange} /> <span>Staked only</span>
                    </div>
                    <div className="box-search-round">
                      <div className="search-project earn">
                        <Search
                          placeholder="Search Staking"
                          type="text"
                          onChange={handleChangeSearchQuery}
                        />
                        <div className="icon-search">
                          <img src="/images/imgido/search-normal.png" alt="" />
                        </div>
                      </div>
                      {/* <div className="filter-project dis-991">
                        <Select
                          defaultValue="Sort by"
                          style={{ width: 125 }}
                          onChange={handleChangeSort}
                        >
                          <Option value={0}>BNB</Option>
                          <Option value={1}>ETH</Option>
                          <Option value={2}>POLY</Option>
                        </Select>
                      </div> */}
                    </div>
                  </div>
                  <Tabs
                    className="tabs-round"
                    defaultActiveKey="1"
                    // onChange={onChange}
                    onChange={handleTabChange}
                  >
                    <TabPane tab="Live" key="1">
                      <>
                        {contentTop}
                        {defaultChecked === true ? (
                          <>
                            {pools
                              .filter(
                                (pool) =>
                                  pool.userData.stakedBalance > 0 &&
                                  pool.isFinished === false &&
                                  // pool.chain === searchChain &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <PoolList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            {pools
                              .filter(
                                (pool) =>
                                  pool.isFinished === false &&
                                  // pool.chain === searchChain &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <PoolList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))}
                          </>
                        )}
                      </>
                    </TabPane>
                    <TabPane tab="Finished" key="2">
                      <>
                        {contentTop}
                        {/* {defaultChecked === true ? (
                          <>
                            {pools
                              .filter(
                                (pool) =>
                                  pool.userData.stakedBalance > 0 &&
                                  pool.isFinished === true &&
                                  // pool.chain === searchChain &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <PoolList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            {pools
                              .filter(
                                (pool) =>
                                  pool.isFinished === true &&
                                  // pool.chain === searchChain &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <PoolList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))}
                          </>
                        )} */}
                      </>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorPage />
        </>
      )}
    </>
  );
};
export default StartPools;
