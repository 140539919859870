import web3 from "web3";
import BigNumber from "bignumber.js";

export const convertNumber = (value) => {
  return web3.utils.toHex(web3.utils.toWei(value, "ether"));
};
export const convertTokenToWei = (value, decimal) => {
    let amount = web3.utils.toWei(value.toString(), 'ether')
    if (decimal !== 18)
      amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
    return amount
  }
export const _approveBEP20 = async (contract, address, amount) => {
  const result = await contract.approve(
    address,
    "100000000000000000000000000000000"
  );
  return result;
};

export const _isClaimed = async (poolContract, account) => {
  return poolContract && poolContract.isClaimed(account);
};

export const _claimTokensOld = async (claimContract, item, tokenDecimals) => {
  const amountTemp = convertTokenToWei(item.reward, tokenDecimals);
  const args = [amountTemp, item.rewardSign];
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
};

export const _claimTokensNew = async (claimContract, reward , keyId , sign , tokenDecimals) => {
  const amount = convertTokenToWei(reward, tokenDecimals)
 let args = [amount, keyId, sign]
 const estimatedGas = await claimContract.estimateGas.claim(...args)
 return claimContract.claim(...args, {
   gasLimit: estimatedGas,
 })
}

export const _userClaims = async (claimContractNew, account, keyId) => {
  if(keyId === 100) {
    const result = await claimContractNew.claimAble(account)
    return  (result.toString() / 1e18) > 0
  }
  return claimContractNew.userClaims(...[account, keyId])
}

export const _userClaimId = async (claimContract, account, keyId) => {
  if(keyId === 100) {
    const result = await claimContract.claimAble(account)
    return  (result.toString() / 1e18) > 0
  }
  return claimContract.userClaim(...[account, keyId])
}