import web3 from "web3";
import BigNumber from "bignumber.js";

const convertNumber = (value) => {
  return web3.utils.toHex(web3.utils.toWei(value, "ether"));
};

export const _getBoughtBoxes = async (
  boxContract,
  userAddress,
  campaignId,
  boxId
) => {
  try {
    return (
      boxContract && boxContract.getBoughtBoxes(userAddress, campaignId, boxId)
    );
  } catch (error) {
    return undefined;
  }
};

export const _maxBuyBox = async (boxContract) => {
  try {
    return boxContract && boxContract.maxBuyBox();
  } catch (error) {
    return undefined;
  }
};

export const _getCampaignInfo = async (boxContract, _campaignId) => {
  if (_campaignId === undefined) {
    return {};
  }
  try {
    const result =
      (await boxContract) && boxContract.getCampaignInfo(...[_campaignId]);
    return result;
  } catch (error) {
    return {};
  }
};

export const _getCampaigns = async (boxContract, _campaignId) => {
  if (_campaignId === undefined) {
    return {};
  }
  try {
    console.log('_campaignId', _campaignId)
    const result =
      // (await boxContract) && boxContract.campaigns(...[_campaignId]);
      (await boxContract) && boxContract.campaigns(_campaignId);
    return result;
  } catch (error) {
    return {};
  }
};

export const _buyMysteryBox = async (
  boxContract,
  boxPrice,
  _campaignId,
  _mysteryBoxId,
  _quantity,
  sig,
  timelineBuyType,
  type
) => {
  let sigTemp = "0x00";
  if (timelineBuyType === 2) {
    sigTemp = sig;
  }
  const value = web3.utils.toWei((_quantity * boxPrice).toString());
  const args = [_campaignId, _mysteryBoxId, _quantity, sigTemp];
  try {
    // if currency is BNB
    if (type === 2) {
      const estimatedGas = await boxContract.estimateGas.buyMysteryBox(
        ...args,
        { value }
      );
      return boxContract.buyMysteryBox(...args, {
        gasLimit: estimatedGas,
        value,
      });
    }
    // console.log('args>>',args)
    // if currency is token BEP20
    const estimatedGas = await boxContract.estimateGas.buyMysteryBox(...args);
    return boxContract.buyMysteryBox(...args, {
      gasLimit: estimatedGas,
    });
  } catch (error) {
    return error;
  }
};

export const _mysteryBoxInfo = async (boxContract, _campaignId, id) => {
  try {
    return boxContract && boxContract.getSoldBox(_campaignId, id);
  } catch (error) {
    // console.log('error>>', error)
    return undefined;
  }
};

export const _myBoxInfo = async (boxContract, address, boxid) => {
  try {
    return boxContract && boxContract.balanceOf(address, boxid);
  } catch (error) {
    // console.log('error>>', error)
    return undefined;
  }
};

export const _approveBEP20 = async (contract, address, amount) => {
  const result = await contract.approve(
    address,
    "100000000000000000000000000000000"
  );
  return result;
};
export const _openBox = async (
  openBoxContract,
  boxSC,
  nftContract,
  _boxID,
  _tokenId,
  mintable,
  _uri,
  _attrs,
  sig
) => {
  try {
    const args = [
      boxSC,
      nftContract,
      _boxID,
      _tokenId,
      mintable,
      _uri,
      _attrs,
      sig,
    ];
    const estimatedGas = await openBoxContract.estimateGas.OpenBox(...args);
    return openBoxContract.OpenBox(...args, {
      gasLimit: estimatedGas,
    });
  } catch (error) {
    // console.log('error>>', error)
    return error;
  }
};
export const getNfts = async (openBoxContract, address) => {
  try {
    return openBoxContract && openBoxContract.getNtfs(address);
  } catch (error) {
    // console.log('error>>', error)
    return undefined;
  }
};
