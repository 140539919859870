/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Input, Select, Spin } from "antd";
import { useHookAirdrop } from "./StoreAirdrop";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.css";
import { STATUS, NETWORK } from "./index.d";

import { useAccount } from "wagmi";

const ListActive = (props: any) => {
  const [state, actions]: any = useHookAirdrop();
  const { address } = useAccount();
  let account = address
  let accountAddress: any = account;
  if (accountAddress === undefined) {
    accountAddress = localStorage.getItem("currentAddress");
  }

  const [isLoadMore, setIsLoadMore] = useState(false);

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let chain = localStorage.getItem("chain");
  const params = {
    ownerAddress: accountAddress,
    lastDate: null,
  };

  const handleLoadMore = () => {
    setIsLoadMore(true);
    actions.getInfoAirdrop(params).then((res: any) => {
      if (res) {
        setIsLoadMore(false);
      } else {
        setIsLoadMore(false);
      }
    });
  };

  const paramsAvtive = {
    ownerAddress: accountAddress,
    lastDate: null,
    tabId: 1,
    network: chain,
  };

  useEffect(() => {
    actions.getInfoAirdropActive(paramsAvtive);
  }, [accountAddress, chain]);

  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );
  const ether = (
    <>
      <img src="/images/ether.png" alt="" />
    </>
  );
  const poly = (
    <>
      <img src="/images/poly.png" alt="" />
    </>
  );
  const bsc = (
    <>
      <img src="/images/bnb-bnb-logo.png" alt="" />
    </>
  );
  const avax = (
    <>
      <img src="/images/avax.png" alt="" />
    </>
  );

  const fillIconChain = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return bsc;
        break;
      case NETWORK.Poly:
        return poly;
        break;
      case NETWORK.Ethereum:
        return ether;
        break;
      case NETWORK.AVAX:
        return avax;
        break;
    }
  };

  const fillNetwork = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return "Binance Smart Chain";
        break;
      case NETWORK.Poly:
        return "Polygon";
        break;
      case NETWORK.Ethereum:
        return "Ethereum";
        break;
      case NETWORK.AVAX:
        return "Avalanche C Chain";
        break;
    }
  };

  const ListActive = ({ item }: any) => {
    return (
      <>
        <div className="colum w-33 airdrop-page">
          <div className="content-up">
            <div className="box-content-top-air">
              <div className="box-img">
                <img src={item?.bannerUrl} alt="" />
              </div>
            </div>
            <div className="box-content-bottom">
              <div className="guide-swap">
                <div className="name-token">{item.name}</div>
                <p className="desc airdrop">{item.decs}</p>
                {item.reward !== null && item.reward !== "" ? (
                  <ul className="list-swap airdrop">
                    <li>
                      <span className="text-l center">Reward </span>
                    </li>
                    <li>
                      <span className="text-r center"> {item.reward}</span>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                <div className="list-socail">
                  <div className="text-item">
                    <div className="text-l">Duration start:</div>
                    <div className="text-r">{item.openDate}</div>
                  </div>
                  <div className="text-item">
                    <div className="text-l">Duration ended:</div>
                    <div className="text-r">{item?.endDate}</div>
                  </div>
                  <div className="text-item">
                    <div className="text-l">Network:</div>

                    <div className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(item?.network)}
                      </span>
                      {fillNetwork(item?.network)}
                    </div>
                  </div>
                </div>

                {!accountAddress ? (
                  <>
                    <div className="button-view">
                      <button
                        className="btn btn-view air-drop disable"
                        type="button"
                      >
                        Please connect wallet
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button-view">
                      <a
                        className="btn btn-view air-drop"
                        type="button"
                        href={item.detailLink}
                        target="_blank"
                      >
                        Join now
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="table-airdrop">
        <div className="columns">
          {state.airdropListActive.length === 0 || state.airdropListActive === false ? (
            <div className="empty pd-top-30">{noData}</div>
          ) : (
            state.airdropListActive.map((item: any, i: any) => (
              <ListActive item={item} />
            ))
          )}
        </div>

        {state.airdropListEnded.length >= 10 && (
          <div className="load-more-round">
            {isLoadMore ? (
              <Spin indicator={antIconLoad} />
            ) : (
              <button
                className="btn-loadmore cus"
                type="button"
                onClick={() => handleLoadMore()}
              >
                Load More
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ListActive;
